import React, { useContext, useState } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import { withGlobalSetting } from '../../../hooks/withGlobalSettingEnabled'

import CouponModal from '../../../components/Store/Coupon/CouponModal'

const EVENT_CATEGORY = 'TrafficOffer'

/* ----------------------------------------------- */
const TrafficOfferContainer = props => {
  const plugin = useContext(PluginContext)

  const trafficOfferStorage = plugin.$storage.create('_traffic_offer', { strategy: 'SESSION' })

  const couponOffer = props[props.offerCode]

  const [hideCouponModal, setHideCouponModal] = useState(false)

  const showCouponOffer = !!couponOffer && !hideCouponModal

  const handleCancel = () => {
    plugin.$track.event(EVENT_CATEGORY, `cancel_${props.offerCode}`)

    takeCoupon()
    trafficOfferStorage.setItem(true)

    setHideCouponModal(true)
  }

  const handleConfirm = () => {
    plugin.$track.event(EVENT_CATEGORY, `take_${props.offerCode}`)

    takeCoupon()
    trafficOfferStorage.setItem(true)

    setHideCouponModal(true)
  }

  const takeCoupon = () => {
    const couponHub = plugin.$store && plugin.$store.couponHub

    if (couponOffer.couponIds) {
      couponHub.highlight(couponOffer.couponIds.map(couponId => ({
        id: couponId
      })))
    } else {
      couponHub.highlight([{
        id: couponOffer.couponId
      }])
    }
  }

  if (trafficOfferStorage.getItem(false) || !showCouponOffer) {
    return null
  }

  plugin.$track.event(EVENT_CATEGORY, `view_${props.offerCode}`)

  return (
    <CouponModal
      hide={!showCouponOffer}
      onConfirm={handleConfirm}
      onClose={handleCancel}
      {...couponOffer.couponModal}
    />
  )
}

export default withGlobalSetting(TrafficOfferContainer, 'trafficOffer')
