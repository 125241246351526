import React, { useState } from 'react'

import withQuickAdd from '../../../hooks/withQuickAdd'

import Image from '../../common/Image/Image'
import styles from './QuickAddHoverBtnCard.module.css'

import { withPlugin } from '@flamingo_tech/funkgo'

const QuickAddHoverBtnCard = withQuickAdd(({ onQuickAdd, selectedVariants, onClickAddCart, product, imageProps, $i18n, $detector }) => {
  const [active, setActive] = useState(false)
  const isDesktop = $detector.isDesktop()

  const injectMethods = {}
  const btnMethods = {}

  if (isDesktop) {
    injectMethods.onMouseOver = () => setActive(true)
    injectMethods.onMouseLeave = () => setActive(false)
  }

  return (
    <div {...injectMethods} className={styles.quickAddCard}>
      {
        active && (
          <div
            {...btnMethods}
            className={styles.quickAddBtn}
            onClick={() => {
              onQuickAdd({
                product,
                selectedVariantInfo: selectedVariants
              })
              onClickAddCart(product)
            }}
          >
            {$i18n.transl('core.product.addToCart')}
          </div>
        )
      }
      <Image {...imageProps}></Image>
    </div>
  )
})

export default withPlugin(QuickAddHoverBtnCard)