import React, { useContext }  from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import styles from './GuidePageFloatWidget.module.css'
import { rem as px2rem } from '../../../utils/remUtils'
import cx from '../../../utils/className'

const GuidePageFloatWidget = props => {
  const plugins = useContext(PluginContext)
  const isDesktop = plugins.$detector.isDesktop()
  const style = {
    marginBottom: px2rem(props.bottomHeight || 0)
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)} style={style}>
      {props.children}
    </div>
  )
}

export default GuidePageFloatWidget