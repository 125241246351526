export const SORT_DATA = {
  filterKey: 'sort',
  filterName: 'Sort',
  displayType: 'singleChoice',
  filters: [
    {
        'image':'',
        'displayKey': 'Recommend',
        'displayValue':'Recommend',
        'i18nDisplayKey': 'core.collection.sort.recommend',
        'value':''
    },
    {
        'image':'',
        'displayKey': 'Best Selling',
        'displayValue':'Best Selling',
        'i18nDisplayKey': 'core.collection.sort.bestSelling',
        'value':'best-selling'
    },
    {
        'image':'',
        'displayKey': 'New Arrivals',
        'displayValue':'New Arrivals',
        'i18nDisplayKey': 'core.collection.sort.newArrivals',
        'value':'created-desc'
    },
    {
      'image':'',
      'displayKey': 'Price Low To High',
      'displayValue':'Price Low To High',
      'i18nDisplayKey': 'core.collection.sort.priceAsc',
      'value':'price-asc'
    },
    {
      'image':'',
      'displayKey': 'Price High To Low',
      'displayValue':'Price High To Low',
      'i18nDisplayKey': 'core.collection.sort.priceDesc',
      'value':'price-desc'
    }
  ]
}


export const getCurrentSortTitle = (queryOptions) => {
  let currentSort = SORT_DATA.filters[0].i18nDisplayKey
  if (queryOptions && queryOptions[SORT_DATA.filterKey]) {
    SORT_DATA.filters.forEach(item => {
      if (item.value === queryOptions.sort) {
        return currentSort = item.i18nDisplayKey
      }
    })
    return currentSort
  } else {
    return SORT_DATA.filters[0].i18nDisplayKey
  }
}

export const getFilterNumByOptions = (selectedOptions) => {
  let num = 0
  Object.keys(selectedOptions).forEach(key => {
    const value = selectedOptions[key]
    if (key !== 'sort' && Array.isArray(value)) {
      num = value.length + num
    }
  })
  return num
}

export const FILTER_KEY_LIST = ['sort', 'color', 'size', 'price']

export const getFilterOptions = queryOptions => {
  const filterOptions = {}

  FILTER_KEY_LIST.forEach(item => {
    if(queryOptions[item]) {
      filterOptions[item] = queryOptions[item]
    }
  })

  return filterOptions
}


export const attachFilterQuery = (args, queryString, collectionHandle) => {
  const originalQueryArgs = composeSearchParams(args)
  const filterKey = FILTER_KEY_LIST
  const queryArgs = {}

  filterKey.forEach(item => {
    if(originalQueryArgs[item]) {
      queryArgs[item] = originalQueryArgs[item]
    }
  })

  if (typeof window !== 'undefined') {
    const existsArgs = queryString.parse(window.location.pathname.split(`${collectionHandle}~`)[1] || '')
    const argsLength = Object.keys(queryArgs).length

    let pathname, url
    if (argsLength > 0) {
      if(argsLength === 1 && 'sort' in queryArgs) {
        pathname = window.location.pathname.split('+')[0] + '+' + queryString.stringify(queryArgs).replace(/%2C/g, '.')
      } else {
        pathname = window.location.pathname.split('+')[0] + '+' + queryString.stringify({
          ...existsArgs,
          ...queryArgs
        }).replace(/%2C/g, '.')
      }
    } else {
      pathname = window.location.pathname.split('+')[0]
    }
    url = window.location.origin + pathname + window.location.search + window.location.hash
    window.history.pushState({url: url}, '', url)
  }
}

export const composeSearchParams = (queryOptions) => {
  const map ={}

  Object.keys(queryOptions).forEach(key => {
    const value = queryOptions[key]
    if (value) {
      if (Array.isArray(value) && value.length > 0) {
        map[key] = value.join(',')
      } else {
        map[key] = value
      }
    }
  })
  return map
}