import React, { PureComponent } from 'react'

import { hoistHocStatic, withPlugin } from '@flamingo_tech/funkgo'
import { getCartLineItemCount, getCartCheckoutItemCount } from '../utils/cartUtils'
import withAppState from '../hooks/withAppState'
import UserService from '../services/UserService'

/* ---------------------------------- */

const withCartAndPlugins = (
  Component,
  { withClient = true } = {},
  staticProps
) => {
  const displayName = `withCart(${Component.displayName || Component.name})`

  class WrappedComponent extends PureComponent {
    _isMounted = false

    state = {
      cart: null,
      cartOperation: {}, // track operation([ADD|REMOVE|UPDATE]) of cart each time,
      availableCoupons: [],
    }

    updateState = (({
      cart = this.state.cart,
      cartOperation = this.state.cartOperation,
      availableCoupons = this.state.availableCoupons,
    }) => {
      if (this._isMounted) {
        this.setState({
          cart,
          cartOperation,
          availableCoupons,
        })
      }
    })

    /* ----------------------------------------------- */

    componentDidMount() {
      this._isMounted = true

      this.connectClient().catch(err => {
        this.props.$logger.errorWithAction(err, 'connectClient', 'withCart')
        // once user trigger any cart related methods, it will try to auto re-connect
        // , so do not need to toast user to reload page
        // this.props.$toastError('Oops, failed to connect cart, please reload this page')
      })
    }

    componentWillUnmount() {
      this._isMounted = false
      this.props.$store.unsubscribeCart(this.updateState)
    }

    /* ------------------------------------------------ */

    connectClient = () => {
      this.props.$store.unsubscribeCart(this.updateState)
      return this.props.$store.initCart().then(cartModel => {
        this.updateState(cartModel)
        this.props.$store.subscribeCart(this.updateState)
      }, err => {
        // subscribe cart update even if connect failed, since the connect process may be trigger again from other component
        this.props.$store.subscribeCart(this.updateState)
        throw err
      })
    }

    wrapClient = method => (...args) => {
      return this.props.$store.execCart(method, args).catch(err => {
        throw err
      })
    }

    /* ------------------------------------------------ */
    createDraftOrder = this.wrapClient('createDraftOrder')
    createHalfDraftOrder = this.wrapClient('createHalfDraftOrder')
    createAnonymousDraftOrder = this.wrapClient('createAnonymousDraftOrder')

    checkoutViaShopify = () => {
      return this.createDraftOrder({ flamingoCheckout: false }).then(payload => {
        this.props.$router.navigate(payload.webUrl)
      })
    }

    checkViaFlamingo = ({ accessToken, isSubmitAll = false }) => {
      if (!this.props.$detector.isDesktop()) {
        const userService = new UserService(this.props.$http)
          return userService.getUserAddresses().then((address) => {
            if (address.userAddresses && address.userAddresses.length > 0) {

              return this.createDraftOrder({ flamingoCheckout: true, isSubmitAll, shippingAddress: address.userAddresses[0] })
              .then(payload => {

                  this.props.$router.navigate({
                    name: 'Checkout',
                    search: {
                      id: payload.orderId,
                      type: 'draft',
                      _ak: accessToken
                    }
                  })
              }).catch(err => {
                // incase if token just expired, then let logout user, so that when user checkout again
                // , will redirect to login page
                if (err.errorCode === 401) {
                  this.props.$user.logout()
                } else {
                  this.props.$toastError(err.message)
                }
                throw err
              })
            } else {
              return this.createHalfDraftOrder({ isSubmitAll }).then(
                payload => {
                  return {
                    payload
                  }
                }
              ).then(({ payload } = {}) => {
                const args = {
                  name: 'ShippingConfirmation',
                  search: {
                    id: payload.orderId
                  }
                }
                this.props.$router.navigate(args)
              }).catch(err => {
                this.props.$logger.errorWithAction(err, 'checkoutViaAnonymous', 'withCartAndPlugins')
                this.props.$toastError(err.message)
                return err
              })
            }
          })
      } else {
        const userService = new UserService(this.props.$http)

        return userService.getUserAddresses().then((address) => {
          return this.createDraftOrder({ flamingoCheckout: true, isSubmitAll, shippingAddress: address.userAddresses[0] })
          .then(payload => {

              this.props.$router.navigate({
                name: 'Checkout',
                search: {
                  id: payload.orderId,
                  type: 'draft',
                  _ak: accessToken
                }
              })
          }).catch(err => {
            // incase if token just expired, then let logout user, so that when user checkout again
            // , will redirect to login page
            if (err.errorCode === 401) {
              this.props.$user.logout()
            } else {
              this.props.$toastError(err.message)
            }
            throw err
          })
        })
      }
    }

    checkoutViaAnonymous = ({ isSubmitAll }) => {
      if (!this.props.$detector.isDesktop()) {
        return this.createHalfDraftOrder({ isSubmitAll }).then(
          payload => {
            return {
              payload
            }
          }
        ).then(({ payload } = {}) => {
          const args = {
            name: 'ShippingConfirmation',
            search: {
              id: payload.orderId
            }
          }
          this.props.$router.navigate(args)
        }).catch(err => {
          this.props.$logger.errorWithAction(err, 'checkoutViaAnonymous', 'withCartAndPlugins')
          this.props.$toastError(err.message)
          return err
        })
      } else {
        return this.props.$user.loginAnonymous().then(
          user => {
            const anonymousAddressStorage = this.props.$storage.create('an_address')

            return this.createAnonymousDraftOrder(user.accessToken, { isSubmitAll, shippingAddress: anonymousAddressStorage.getItem() }).then(
              payload => {
                return {
                  payload,
                  user
                }
              }
            )
          }
        ).then(({ payload, user } = {}) => {
          const args = {
            name: 'Checkout',
            search: {
              id: payload.orderId,
              type: 'anonymous',
              _ak: user.accessToken
            }
          }
          this.props.$router.navigate(args)
        }).catch(err => {
          this.props.$logger.errorWithAction(err, 'checkoutViaAnonymous', 'withCartAndPlugins')
          this.props.$toastError(err.message)
          return err
        })
      }

    }

    isStandaloneApp = () => {
      return this.props.$detector.isStandaloneApp()
    }

    checkout = (arg = {}) => {
      return this.ensureCouponCenter().then(() => {
        return this.props.$user.getUserInfo().then(
          data => { // user is login
            return this.checkViaFlamingo({ accessToken: data.accessToken, isSubmitAll: arg.isSubmitAll })
          },
          () => {
            return this.checkoutViaAnonymous({ isSubmitAll: arg.isSubmitAll })
          }
        )
      })
    }

    ensureCouponCenter = () => {
      if (this.props.$store.couponHub.getCouponCenterId()) {
        return Promise.resolve()
      } else {
        return this.props.$store.couponHub.connectCouponCenter()
      }
    }

    /* ------------------------------------------------ */

    clientMethods = withClient
    ? {
      $checkout: this.checkout,
      $createHalfDraftOrder: this.createHalfDraftOrder,
      $createAnonymousDraftOrder: this.createAnonymousDraftOrder,
      $addVariant: this.wrapClient('addVariant'),
      $updateVariant: this.wrapClient('updateVariant'),
      $removeVariants: this.wrapClient('removeVariants'),
      $applyCoupon: this.wrapClient('applyCoupon'),
      $touchCart: this.wrapClient('touchCart'),
      $refreshCart: this.wrapClient('refresh'),
      $fetchFullCart: this.wrapClient('fetchFullCart'),
      $selectCart: this.wrapClient('selectCart'),
      $ensureCouponCenter: this.ensureCouponCenter,
      $replaceVariant: this.wrapClient('replaceVariant'),
    }
    : undefined

    /* ------------------------------------------------ */

    render() {
      return (
        <Component
          {...this.props}
          {...this.clientMethods}
          $connectCart={this.connectClient}
          cart={this.state.cart}
          cartLineItemCount={getCartLineItemCount(this.state.cart)}
          checkoutItemCount={getCartCheckoutItemCount(this.state.cart)}
          availableCoupons={this.state.availableCoupons}
          cartOperation={this.state.cartOperation}
        ></Component>
      )
    }
  }

  WrappedComponent.displayName = displayName
  hoistHocStatic(WrappedComponent, Component, staticProps)
  return withPlugin(withAppState(WrappedComponent, undefined, staticProps))
}

export default withCartAndPlugins
