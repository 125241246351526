import React, { useState, useEffect } from 'react'
import ProductSection from './ProductSection'

import { withPlugin } from '@flamingo_tech/funkgo'
import ProductService from '../../../services/ProductService'

import styles from './ProductRelatedSearches.module.css'
import cx from '../../../utils/className'

const ProductRelatedSearches = props => {
  const [relatedTags, setRelatedTags] = useState([])
  const isDesktop = props.$detector.isDesktop()

  const productService = new ProductService(props.$http)

  useEffect(() => {
    fetchRelatedTags()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchRelatedTags = () => {
    return productService.fetchProductRelatedTags(props.productHandle)
    .then(res => {
      setRelatedTags(res)
    })
    .catch(() => null)
  }

  const handleTagClick = (item, index)=> {
    props.$router.navigate(item.link)
    props.$track.clickSubResourceLocator(`Product:RelatedSearch_${item.name}`, {
      index,
      url: item.link,
      category: props.productCategory
    })
  }

  return (
    <>
      {
        relatedTags?.length > 0 &&
        <ProductSection sectionTitle={isDesktop && props.$i18n.transl('core.product.relatedSearched')} className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
          {
            !isDesktop &&
            <div className={styles.header}>
              <h2 className={styles.title}>{props.$i18n.transl('core.product.relatedSearched')}</h2>
            </div>
          }
          <div className={cx(styles.relatedSearches, isDesktop && styles.isDesktop)}>
            {relatedTags.map((i, index) =>
              <div key={index} onClick={() => handleTagClick(i, index)}>{i.name}</div>
            )}
          </div>
        </ProductSection>
      }
    </>
  )
}

export default withPlugin(ProductRelatedSearches)
