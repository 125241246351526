import { render } from '@flamingo_tech/funkgo'
import app from './App'
import router from './router'

const rootDom = document.getElementById('root')

if (!rootDom.hasAttribute('data-hydrate')) {
  render(app, router, { isServer: false })
}
window.main = () => {
  render(app, router, { isServer: true })
}