import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Tooltip from '../../common/Tooltip/Tooltip'

import styles from './ProductSizeGuideTips.module.css'
import cx from '../../../utils/className'

const ProductSizeGuideCard = withPlugin(props => {
  const { countryCode } = props.$site.getSiteInfo()
  const { sizeGuideMeta, selectedSize } = props

  if (!sizeGuideMeta) {
    return null
  }

  const sizeInfo = sizeGuideMeta.filter(i => i.name === selectedSize)[0] || {}
  const sizeInfoByUnit = sizeInfo[countryCode === 'US' ? 'inch' : 'cm'] || []

  if (sizeInfoByUnit.length <= 0) {
    return null
  }

  return (
    <div className={styles.ProductSizeGuideCard}>
      <div className={styles.title}>{props.$i18n.transl('core.product.sizeGuideInfo.bodyMeasurements')}</div>
      <div className={styles.info}>
        {
          sizeInfoByUnit.map((i, index) => {
            return (
              <div key={index} className={styles.infoItem}>
                <span className={styles.name}>{i.name}: </span>
                <span className={styles.value}>{i.value}</span>
                <span className={styles.unit}>{i.unit}{index !== sizeInfoByUnit.length - 1 && ','}</span>
              </div>
            )
          })
        }
      </div>
      <div className={styles.viewMore} onClick={props.onShowSizeChartModal}>
        <span>{props.$i18n.transl('core.product.sizeGuideInfo.viewMoreSizeInformation')}</span>
      </div>
    </div>
  )
})

const ProductSizeGuidePop = withPlugin(props => {
  const { countryCode } = props.$site.getSiteInfo()
  const { sizeGuideMeta, selectedSize } = props

  if (!sizeGuideMeta) {
    return null
  }

  const sizeInfo = sizeGuideMeta.filter(i => i.name === selectedSize)[0] || {}
  const sizeInfoByUnit = sizeInfo[countryCode === 'US' ? 'inch' : 'cm']

  if (!sizeInfoByUnit || sizeInfoByUnit.length <= 0) {
    return null
  }

  let offset = 0

  if (window && window.event) {
    offset = window.innerWidth - window.event.clientX
  }


  return (
    <Tooltip
      className={cx(styles.ProductSizeGuideToolTip, styles[offset > 321 ? 'right' : 'left'])}
      contentClassName={styles.contentClassName}
      pointerClassName={styles.pointerClassName}
      direction={Tooltip.DIRECTION.DOWN}
      pointer={Tooltip.POINTER.ARROW}
      bodyStyle={Tooltip.BODY_STYLE.WHITE}
    >
      <div className={styles.ProductSizeGuidePop}>
        <div className={styles.title}>{props.$i18n.transl('core.product.sizeGuideInfo.bodyMeasurements')}</div>
        <div className={styles.info}>
          {
            sizeInfoByUnit.map((i, index) => {
              return (
                <div key={index} className={styles.infoItem}>
                  <span className={styles.name}>{i.name}: </span>
                  <span className={styles.value}>{i.value}</span>
                  <span className={styles.unit}>{i.unit}{index !== sizeInfoByUnit.length - 1 && ','}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    </Tooltip>
  )
})

export {
  ProductSizeGuideCard,
  ProductSizeGuidePop
}