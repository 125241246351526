import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Button from '../common/Button/PlainButton'
import styles from './LoginFallbackPage.module.css'
import cx from '../../utils/className'

const LoginFallbackPage = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin && plugin.$detector.isDesktop()

  const { logo } = plugin.$site.getSiteInfo() || {}
  const logoStyle = {}

  if (logo) {
    logoStyle["backgroundImage"] = `url(${logo})`
  }

  return (
    <section className={cx(isDesktop ? styles.pc : null, styles.wrapper)}>
      <div className={styles.logo} style={logoStyle}></div>
      <div className={styles.btnWrapper}>
        <Button className={cx(styles.button, styles.secondary)} onClick={props.onSkipLogin}>
          {plugin.$i18n.transl('core.login.goShopping')}
        </Button>

        <Button className={cx(styles.button, styles.fbBtn)} onClick={props.onLogin}>
          {plugin.$i18n.transl('core.login.loginTo')}
        </Button>
      </div>

    </section>
  )
}

export default LoginFallbackPage