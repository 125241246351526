import React from 'react'

import Image from '../../common/Image/Image'

import withQuickAdd from '../../../hooks/withQuickAdd'
import { AddToCartBtn } from '../../common/AddToCartIcon/AddToCartIcon'
import styles from './RecommendCard.module.css'
import cx from '../../../utils/className'
import { getDiscountText } from '../../../utils/priceUtils'

const EVENT_CATEGORY = 'RecommendCard'

const RecommendCard = props => {
  const { mainImage = {}, mainVariation = {} } = props
  const { price, msrp, displayPrice, displayMsrp } = mainVariation
  const hasOriginalPrice = msrp && parseFloat(msrp) > parseFloat(price)

  const imageProps = {
    ...mainImage,
    className: styles.image,
    middle: true,
    lazy: true,
    crop: { width: 2, height: 3 },
    placeholder: { width: 2, height: 3 },
    quality: 60,
    title: mainImage && mainImage.title
      ? mainImage.title
      : props.title
  }

  const handleClickQuickAdd = () => {
    props.$track.clickProduct({
      id: props.id,
      handle: props.handle
    }, props.index, true)
    props.$track.clickSubResourceLocator(`Cart:YouMayAlsoLike_${props.currentTabTitle}-${props.handle}`, {
      id: props.id,
      index: props.index,
      handle: props.handle,
      sourceHandle: props.sourceHandle
    })

    props.onQuickAdd({
      product: props
    })
  }

  return (
    <div className={styles.mobileCard} onClick={handleClickQuickAdd}>
      <div className={styles.imageWrapper}>
        <Image {...imageProps} />
          <div className={styles.addIcon}>
          <AddToCartBtn className={styles.icon}/>
        </div>
      </div>
      {
        hasOriginalPrice &&
        <div className={styles.floatDiscount}>{getDiscountText(price, msrp)}</div>
      }
      <div className={styles.priceBox}>
        <span className={cx(styles.salePrice, hasOriginalPrice && styles.highlighted)}>{displayPrice}</span>
        {

          hasOriginalPrice
            ? <span className={styles.originalPrice}>{displayMsrp}</span>
            : null
        }
      </div>

    </div>
  )
}

export default withQuickAdd(RecommendCard, { eventCategory: EVENT_CATEGORY })