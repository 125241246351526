import React, { PureComponent, createRef } from 'react'
import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import Slider from '../../common/Slider/Slider'
import NullComponent from '../../Layout/NullComponent'
import HorizontalProductList from '../../Store/ProductList/HorizontalProductList'

import { withPlugin } from '@flamingo_tech/funkgo'
import { makeSearch } from '../../../utils/breadcrumbUtils'
import cx from '../../../utils/className'
import styles from './ProductCardPanel.module.css'
import ProductCard from '../ProductCard/BriefProductCard'

const productListComponent = {
  HorizontalProductList
}

const mapItem = is => {
  let Component
  if (!is) {
    Component = HorizontalProductList
  } else if (productListComponent[is]) {
    Component = productListComponent[is]
  } else {
    Component = NullComponent
    this.props.$logger(`ProductCardPanel] non-supported component type: "${is}"`)
  }

  return Component
}

class ProductCardPanel extends PureComponent {

  sliderEl = createRef()

  renderImage() {
    if (this.props.image) {
      return (
        <Image
          src={this.props.image}
          title={this.props.title}
          link={this.props.link}
        ></Image>
      )
    }

    return null
  }

  renderTitle() {
    let titleElement = null

    if (this.props.title) {
      let ribbon = null

      if (typeof this.props.renderRibbon === 'function') {
        ribbon = this.props.renderRibbon()
      }

      let className = cx(styles.titleSection, {
        [styles.hasRibbon]: ribbon
      })

      let element

      if (this.props.link) {
        element = (
          <Link to={this.props.link} className={className}>
            <div>
              {this.props.title}
              <span className={styles.subtitle}>{this.props.subtitle}</span>
            </div>
            {ribbon}
          </Link>
        )
      } else {
        element = (
          <div className={className}>
            <div>
              {this.props.title}
              <span className={styles.subtitle}>{this.props.subtitle}</span>
            </div>
            {ribbon}
          </div>
        )
      }

      titleElement = (
        <h3>{element}</h3>
      )
    } else if (typeof this.props.renderTitle === 'function') {
      titleElement = this.props.renderTitle()
    }

    return titleElement
  }

  handleLeftSwipe = () => {
    this.sliderEl.current.slickPrev()
  }

  handleRightSwipe = () => {
    this.sliderEl.current.slickNext()
  }

  generateToExtraInfo = () => {
    const childOptions = this.props.childOptions || {}
    const { definition } = childOptions
    const productCardOptions = childOptions.childOptions || {}
    const toExtraInfo = productCardOptions.toExtraInfo || {}

    const searchOptions = (definition && definition.collectionSource && definition.collectionSource.handle)
      ?
      makeSearch({
        title: childOptions.title,
        routeName: 'Collection',
        routeHandle: definition.collectionSource.handle
      })
      : {}

    return {
      ...toExtraInfo,
      search: {
        ...(toExtraInfo.search || {}),
        ...searchOptions
      }
    }
  }

  handleProductCardClick = (product, ev) => {
    ev.preventDefault()
    this.props.$router.navigate({
      name: 'Product',
      params: {
        handle: product.handle
      },
      ...this.generateToExtraInfo()
    })
  }

  render() {
    const ProductListComponent = mapItem(this.props.childIs)
    const childOptions = {
      ...(this.props.childOptions || {}),
      childOptions: {
        ...((this.props.childOptions || {}).childOptions || {}),
        toExtraInfo: this.generateToExtraInfo()
      }
    }


    const products = this.props.products || childOptions.products
    const isDesktop = this.props.$detector.isDesktop()

    return (
      <section className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
        {this.renderImage()}
        {this.renderTitle()}
        {
          isDesktop
          ?
          <div className={styles.cardWrapper}>
            <div className={styles.leftButton} onClick={this.handleLeftSwipe} />
            <div className={styles.rightButton} onClick={this.handleRightSwipe} />
            <Slider slidesToShow={5} slidesToScroll={5} ref={this.sliderEl} className={styles.productSection}>
              {
                products.map((data, index) => {
                  return <div key={index}>
                    <ProductCard
                      onClick={this.handleProductCardClick}
                      {...data.product}
                    />
                  </div>
                })
              }
            </Slider>
          </div>
          :
          <ProductListComponent
            {...childOptions}
            products={products}
            className={styles.productSection}
          />
        }
      </section>
    )
  }
}

export default withPlugin(ProductCardPanel)
