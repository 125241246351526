import { BaseService } from '@flamingo_tech/funkgo'

import ProductDetailModel from '../npm/FunkGoModel/ProductDetailModel'
import ProductQueueModel from '../npm/FunkGoModel/ProductQueueModel'
import ESProductQueueModel from '../npm/FunkGoModel/ESProductQueueModel'
import { ProductFeed, ESProductFeed, ProductSizeInfo } from '../npm/FunkGoModel/ProductModel'

import {
  retryIfTimeout,
} from '../utils/requestUtils'

import { SEARCH_CODE } from '../utils/Store/searchUtils'

import createStorage from '../utils/cacheUtils'
import { v4 as uuidv4 } from 'uuid'

/* --------------------------------- */

class ProductService extends BaseService {

  uniqueTrace = createStorage('unique_track')

  /* ------------------------------------------------- */
  fetchCollectionFromBackend(handle, options) {
    const sortedQuery = ['pageSize', 'fromCursor', 'tph', 'mip', 'sort', 'size', 'color', 'price']

    let sortedQueryStr = ''

    sortedQuery.forEach((queryItem, index) => {
      if (options && options[queryItem] !== undefined && options[queryItem] !== null && options[queryItem] !== '') {
        if (Array.isArray(options[queryItem])) {
          sortedQueryStr += `${index !== 0 ? '&' : ''}${queryItem}=${options[queryItem].join(',')}`
        } else {
          sortedQueryStr += `${index !== 0 ? '&' : ''}${queryItem}=${options[queryItem]}`
        }
      }
    })

    const apiPath = sortedQueryStr ? `/client/api/v6/collections/${handle}?${sortedQueryStr}` : `/client/api/v6/collections/${handle}`

    return this.get(apiPath).then(
      data => new ProductQueueModel(data)
    )
  }

  fetchCollection(handle, options) {
    return retryIfTimeout(
      () => this.fetchCollectionFromBackend(handle, options)
    )
  }


  fetchProductYouMayAlsoLikeFromBackend(handle, options) {
    let newOptions = {}
    options && Object.keys(options).forEach(key => {
      if (Array.isArray(options[key])) {
        newOptions[key] = options[key].join(',')
      } else {
        newOptions[key] = options[key]
      }
    })

    return this.get(`/marketing/noCache/api/v1/product/${handle}/youMayAlsoLike`, {
      headers: {
        'X-client-device-key': this.uniqueTrace.getItem(uuidv4())
      },
      params: newOptions
    }).then(
      data => new ESProductQueueModel(data)
    )
  }

  fetchProductYouMayAlsoLike(handle, options) {
    return retryIfTimeout(
      () => this.fetchProductYouMayAlsoLikeFromBackend(handle, options)
    )
  }

  fetchAddOnProductsBackend(handle, options) {
    let newOptions = {}
    options && Object.keys(options).forEach(key => {
      if (Array.isArray(options[key])) {
        newOptions[key] = options[key].join(',')
      } else {
        newOptions[key] = options[key]
      }
    })

    const params = {
      handle,
      ...newOptions,
      code: newOptions.code || SEARCH_CODE.COLLECTION,
      page: newOptions.page,
      pageSize: newOptions.pageSize || 20
    }

    if (options.price && Array.isArray(options.price)) {
      params.minPrice = options.price[0]
      params.maxPrice = options.price[1]
    }

    return this.post(`/marketing/api/search/get`, params).then(
      data => new ESProductQueueModel(data)
    )
  }

  fetchAddOnProducts(handle, options) {
    return retryIfTimeout(
      () => this.fetchAddOnProductsBackend(handle, options)
    )
  }

  fetchESCollectionFromBackend(handle, options = {}) {
    const params = {
      ...options,
      code: options.code || SEARCH_CODE.COLLECTION,
      page: options.page || 1,
      pageSize: options.pageSize || 20
    }

    if (options.price && Array.isArray(options.price)) {
      params.minPrice = options.price[0]
      params.maxPrice = options.price[1]
    }

    const sortedQuery = ['code', 'pageSize', 'page', 'tph', 'sort', 'size', 'color', 'minPrice', 'maxPrice', 'extraCursor']

    let sortedQueryStr = ''

    sortedQuery.forEach((queryItem, index) => {
      if (params && params[queryItem] !== undefined && params[queryItem] !== null && params[queryItem] !== '') {
        if (Array.isArray(params[queryItem])) {
          sortedQueryStr += `${index !== 0 ? '&' : ''}${queryItem}=${params[queryItem].join(',')}`
        } else {
          sortedQueryStr += `${index !== 0 ? '&' : ''}${queryItem}=${params[queryItem]}`
        }
      }
    })

    const apiPath = sortedQueryStr ? `/marketing/api/v1/collection/${handle}?${sortedQueryStr}` : `/marketing/api/v1/collection/${handle}`

    return this.get(apiPath).then(
      data => new ESProductQueueModel(data)
    )
  }

  fetchHighlightProductsFromBackend(params) {
    return this.get(`/marketing/api/collection/product`, {
      params
    }).then(
      data => new ESProductQueueModel(data)
    )
  }

  fetchESCollection(handle, options) {
    return retryIfTimeout(
      () => this.fetchESCollectionFromBackend(handle, options)
    )
  }

  fetchHighlightProducts(params) {
    return retryIfTimeout(
      () => this.fetchHighlightProductsFromBackend(params)
    )
  }

  fetchESCollectionFilter(handle) {
    return retryIfTimeout(
      () => this.get('/marketing/api/v2/search/filter', {
        params: {
          handle
        },
      })
    )
  }

  fetchCollectionDetail(handle) {
    return this.get(`/client/api/v2/collection/detail?handle=${handle}`)
  }

  fetchCollectionFilter(handle) {
    return retryIfTimeout(
      () => this.get(`/client/api/v3/collections/filter?handle=${handle}`)
    )
  }
  /* -------------------------------------------------- */
  fetchProductSizeInfo(handle) {
    return retryIfTimeout(
      () => this.get(`/client/api/v4/products/sizeChart/${handle}`).then(data => new ProductSizeInfo(data))
    )
  }


  /* -------------------------------------------------- */
  fetchProductFromBackend(handle, eventID) {
    return this.get(`/client/api/v4/products/${handle}`, {
      params: {
        videoEnabled: true
      },
      headers: {
        'X-fb-event-id': eventID || '',
        'X-api-version': '1.0'
      }
    }).then(
      data => new ProductDetailModel(data)
    )
  }

  fetchProduct(handle, eventID) {
    return retryIfTimeout(
      () => this.fetchProductFromBackend(handle, eventID)
    )
  }

  fetchDynamicProduct(handle) {
    return retryIfTimeout(
      () => this.get(`/client/api/v2/products/dynamic/${handle}`)
    )
  }

  searchProducts(...args) {
    return retryIfTimeout(
      () => this.searchProductsFromBackend(...args)
    )
  }

  searchProductsFromBackend(query, options = {}) {
    return this.get(`/marketing/api/v2/search/keyword`, {
      params: {
        keyword: query,
        page: options.pageNum || 1,
      },
    }).then(
      data => {
        const newData = new ESProductQueueModel(data)
        newData.products = newData.products.map(item => item.product)
        return newData
      }
    )
  }

  fetchESCollectionTabs = (handle) => {
    return this.get(`/marketing/api/collection/${handle}/tag`)
  }

  fetchCollectionTabs = (handle) => {
    return this.get(`/client/api/v3/collections/tag?handle=${handle}`)
  }

  fetchBoughtData = (handle, options) => {
    return this.get(`/marketing/api/product/${handle}/oftenBoughtWith`, {
      params: options
    }).then(
      data => new ESProductQueueModel(data)
    )
  }

  fetchProductRelatedTags = (handle) => {
    return this.get(`/client/api/v2/product/${handle}/tags`)
  }

  fetchShopTheStyle = (handle) => {
    return this.get(`/client/api/v2/product/${handle}/shopTheStyle`)
    .then(res => {
      return res.map(item => {
        return {
          ...item,
          products: item.products.map(i => new ProductFeed(i))
        }
      })
    })
  }

  fetchPicksData = (handle, options) => {
    return this.get(`/marketing/api/product/${handle}/topPicksForYou`, {
      params: options
    }).then(
      data => new ESProductQueueModel(data)
    )
  }

  fetchFlashSalesActivity = () => {
    return retryIfTimeout(
      () => this.get(`/marketing/api/activity/flashSales`)
    )
  }

  fetchFlashSalesActivityList = batchId => {
    return retryIfTimeout(
      () => this.get(`/marketing/api/activity/flashSale/${batchId}`)
        .then(res => ({
          ...res,
          items: res.items.map(product => new ESProductFeed(product))
        }))
    )
  }

  fetchFlashSalesSubscribedIds = batchId => {
    return retryIfTimeout(
      () => this.get(`/game/api/user/subscribed/list?batchId=${batchId}`)
    )
  }

  execSubscribe = ({ batchId, productId, enabled }) => {
    return retryIfTimeout(
      () => this.post(`/game/api/user/batch/subscription`, {
        batchId,
        productId,
        enabled
      })
    )
  }

  fetchCollectionSEOContent(handle, { isESCollection }) {
    return this.get(`/marketing/api/collection/seo/content/${handle}/${isESCollection ? '1' : '2'}`)
  }

  fetchGiftGalaxy() {
    return this.get('/client/api/v2/product/freeGift/list')
  }
}

export default ProductService
