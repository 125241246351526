import querystring from 'query-string'

export const makeSearch = ({ title, routeName, routeHandle }) => {
  return {
    _bdt: title,
    _bdr: routeName,
    _bdh: routeHandle,
  }
}

export const convertSearch = (searchObj = {}) => {
  return {
    title: searchObj._bdt,
    routeName: searchObj._bdr,
    routeHandle: searchObj._bdh,
  }
}

export const extractSearch = searchString => {
  const search = querystring.parse(searchString)

  return {
    title: search._bdt,
    routeName: search._bdr,
    routeHandle: search._bdh,
  }
}