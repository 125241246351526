import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Button from '../../common/Button/Button'
import BraintreePaypalButton from '../CheckOut/BraintreePaypalButton'
import { getPriceList } from '../../../utils/Store/productUtils'

import styles from './CartPageCounts.module.css'
import cx from '../../../utils/className'

const CartPageCounts = props => {
  const priceList = getPriceList({
    $i18n: props.$i18n
  })({
    ...props,
    freight: props.shippingFee,
    discountTag: props.$i18n.transl('core.cart.bestCouponApplied')
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>{props.$i18n.transl('core.cart.orderSummary')}</div>
      <div className={styles.priceSection}>
        {
          priceList.map(({ title, value, negative, subTitle, classKey, tag }) => (
            <div
              key={title}
              className={cx(styles.priceRow, styles[classKey])}>
              <div className={styles.titleSection}>
                <span className={styles.title}>
                  {title}{subTitle && <span className={styles.subTitle}>{subTitle}</span>}
                </span>
                {tag && <span className={styles.tag} onClick={props.onClickCouponEntrance}>
                  {tag}<span className={styles.arrow}>&#xe7b2;</span>
                </span>
                }
              </div>
              <div className={cx(styles.price, negative && styles.negative)}>{value}</div>
            </div>
          ))
        }
      </div>

      <div className={styles.btnBox}>
        {
          props.showPaypal && props.checkoutItemCount !== 0 &&
          <div className={styles.payBtnBox}>
            <BraintreePaypalButton
              className={styles.payBtn}
              onPay={props.onPayWithPaypal}
              style={{
                height: 45
              }}
              onTrack={props.onTrack}
              totalFee={props.paypalTotalFee}
              {...props}
            ></BraintreePaypalButton>
          </div>
        }
        <Button className={styles.checkoutBtn} onClick={props.onCheckout}>{props.$i18n.transl('core.cart.checkout')}{`(${props.checkoutItemCount})`}</Button>
      </div>
    </div>
  )
}

export default withPlugin(CartPageCounts)
