import React, { PureComponent } from 'react'
import withUserAndPlugins from './withUserAndPlugins'
import LoadingPage from '../components/common/LoadingPage/LoadingPage'
import ReactDOM from 'react-dom'

import styles from './withLoginCheck.module.css'

const withLoginCheck = (Component, { loginCallback = () => { }, clickLogin = () => {} } = {}, className) => {
  const displayName = `withLoginCheck(${Component.displayName || Component.name})`

  class WrappedComponent extends PureComponent {
    removeShade() {
      if (this.shade) {
        ReactDOM.unmountComponentAtNode(this.shade)
        this.shade.parentNode.removeChild(this.shade)
        this.shade = null
      }
    }

    renderShade() {
      this.shade = document.createElement('div')
      document.body.appendChild(this.shade)
      ReactDOM.render(<LoadingPage className={styles.loading} />, this.shade)
    }

    render() {
      return (
        <div style={{ position: 'relative' }} className={className}>
          <Component
            {...this.props}
          />
          {
            !this.props.user && <div className={styles.cover} onClick={() => {
              this.renderShade()
              clickLogin()
              this.props.$login()
                .then(() => {
                  this.removeShade()
                  loginCallback()
                })
                .catch(() => {
                  this.removeShade()
                  return Promise.resolve()
                })
            }} />
          }
        </div>
      )
    }
  }

  WrappedComponent.displayName = displayName

  return withUserAndPlugins(WrappedComponent)
}

export const LoginCheckWrapper = withUserAndPlugins(props => {
  return (
    <div style={{ position: 'relative' }}>
      {props.children}
      {
        !props.user && <div className={styles.cover} onClick={() => {
          props.$login()
            .then(() => {
              props.loginCallback && props.loginCallback()
            })
            .catch(() => {
              return Promise.resolve()
            })
        }} />
      }
    </div>
  )
})

export default withLoginCheck