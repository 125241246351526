import { ProductFeed } from './ProductModel.js'

class PageInfo {
  constructor(data) {
    this.hasNextPage = data.hasNextPage
  }
}

class ProductQueue {
  constructor({ handle, pageInfo, count, products, title, description, readMore, definition }) {
    this.title = title
    this.description = description
    this.readMore = readMore
    this.handle = handle
    this.count = count
    this.pageInfo = new PageInfo(pageInfo)
    this.products = products.map(({ product, cursor }) => ({
      cursor,
      product: new ProductFeed(product)
    }))

    this.definition = definition
  }

  unshiftProducts(products) {
    products.forEach(item => {
      if (item && item.cursor && item.product) {
        this.products.unshift(item)
      } else if (item) {
        this.products.unshift({
          cursor: null,
          product: item
        })
      }
    })

    return this
  }
}

export default ProductQueue
