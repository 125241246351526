import React from 'react'

import styles from './IframeVideo.module.css'

const IframeVideo = (props) => {

  return (
    <iframe className={styles.body} src={props.src} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  )
}

export default IframeVideo