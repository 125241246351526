import React from 'react'

import styles from './AtcBall.module.css'

const AtcBall = () => {
  return (
    <div className={styles.wrapper} id="atc_ball" />
  )
}


export default AtcBall
