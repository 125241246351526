import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import cx from '../../../utils/className'
import styles from './BriefProductCard.module.css'
import { getDiscountText } from '../../../utils/priceUtils'

const Wrapper = props => {
  const product = props.product

  const defaultHandleClick = ev => {
    if (typeof props.onClick === 'function') {
      props.onClick(product, ev)
    }
  }

  if (props.routerName || props.to || props.link) {
    let to = props.to || props.link

    if (!to) {
      to = Object.assign({
        name: props.routerName,
        params: {
          handle: product.handle
        }
      }, props.toExtraInfo)
    }

    const handleClick = ev => {
      ev.stopPropagation()
      defaultHandleClick(ev)
    }

    return (
      <Link to={to} className={props.className} onClick={handleClick}>
        {props.children}
      </Link>
    )
  }

  return (
    <div className={props.className}  onClick={defaultHandleClick}>
      {props.children}
    </div>
  )
}

const BriefProductCard = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  //  display empty card when there is no data
  if (!props || !props.mainVariation || props.empty) {
    return <div className={styles.wrapper}/>
  }

  const {
    empty,
    to, // for direct usage
    link, // for direct usage
    routerName, // for dsl usage
    onClick,
    showDiscount,
    toExtraInfo,
    ...product
  } = props

  const interactiveProps = {
    to,
    link,
    routerName,
    onClick,
    toExtraInfo
  }

  const { price, msrp, displayPrice, displayMsrp } = product.mainVariation
  const hasOriginalPrice = msrp && msrp > price
  const imageSize = isDesktop ? { middle: true } : { small: true }

  return (
    <Wrapper
      className={cx(styles.wrapper, isDesktop && styles.inDesktop)}
      {...interactiveProps}
      product={product}
    >
      <div className={styles.imageWrapper}>
        <Image
          {...imageSize}
          lazy
          objectFit='cover'
          {...product.mainImage}
          className={cx(styles.cardImage, isDesktop && styles.inDesktop)}
          title={product.mainImage.title || product.title} />
        {
          showDiscount && hasOriginalPrice && (
            <div className={styles.discount}>{getDiscountText(price, msrp)}</div>
          )
        }
      </div>
      <div className={styles.price}>
        <div className={cx(styles.salePrice, isDesktop && styles.inDesktop)}>{displayPrice}</div>
        {
          hasOriginalPrice && <div className={cx(styles.originalPrice, isDesktop && styles.inDesktop)}>{displayMsrp}</div>
        }
      </div>
    </Wrapper>
  )
}

export default BriefProductCard
