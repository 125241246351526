import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'

import { InStandaloneApp } from '../../hooks/InApp'
import SearchStickyHeader from '../../components/Store/Search/SearchStickyHeader'
import CartEntry from '../../components/Store/Cart/CartEntry'

import Layout from '../../components/Layout/Layout'
import LoadingPage from '../../components/common/LoadingPage/LoadingPage'

import SalesEventsService from '../../services/SalesEventsService'
import ProductService from '../../services/ProductService'

const NEW_ARRIVAL_HANDLE = 'new-tab'

/* --------------------------------------------------------- */

class NewContainer extends PureComponent {

  state = {
    data: null
  }

  productService = new ProductService(this.props.$http)
  salesEventService = new SalesEventsService(this.props.$http)

  componentDidMount() {
    this.salesEventService.fetchPageData(
      SalesEventsService.makePageDataArgs({ $router: this.props.$router, handle: NEW_ARRIVAL_HANDLE, $detector: this.props.$detector })
    ).then(data => {
      this.setState({ data })
    })
  }

  render() {
    // for ssr, need to pass data to sub component
    return (
      <>
        {
          !this.props.$detector.isUseAppHomeApp() &&
          <InStandaloneApp>
            <SearchStickyHeader>
              <CartEntry />
            </SearchStickyHeader>
          </InStandaloneApp>
        }
        {
          this.state.data ?
          <Layout {...this.state.data} productService={this.productService}></Layout>
          :
          <LoadingPage></LoadingPage>
        }

      </>
    )
  }
}

export default withPlugin(NewContainer)
