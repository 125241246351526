import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import cx from '../../../utils/className'
import FlashDiscount, { FlashIcon } from './FlashDiscount'

import styles from './FlamingDealsCard.module.css'

const Wrapper = props => {
  const product = props.product

  const defaultHandleClick = ev => {
    if (typeof props.onClick === 'function') {
      props.onClick(product, ev)
    }
  }

  if (props.routerName || props.to || props.link) {
    let to = props.to || props.link

    if (!to) {
      to = Object.assign({
        name: props.routerName,
        params: {
          handle: product.handle
        }
      }, props.toExtraInfo)
    }

    const handleClick = ev => {
      props.disableClick && ev.preventDefault()
      ev.stopPropagation()
      defaultHandleClick(ev)
    }

    return (
      <Link to={to} className={props.className} onClick={handleClick}>
        {props.children}
      </Link>
    )
  }

  return (
    <div className={props.className} onClick={defaultHandleClick}>
      {props.children}
    </div>
  )
}

const FlamingDealsCard = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const site = plugin.$site.getSiteInfo()

  //  display empty card when there is no data
  if (!props || !props.mainVariation || props.empty) {
    return <div className={styles.wrapper} />
  }

  const {
    empty,
    to, // for direct usage
    link, // for direct usage
    routerName, // for dsl usage
    onClick,
    toExtraInfo,
    style,
    discountBackgroundColor,
    ...product
  } = props

  const interactiveProps = {
    to,
    link,
    routerName,
    onClick,
    toExtraInfo
  }

  const { price, msrp, displayPrice, displayMsrp } = product.mainVariation
  const hasOriginalPrice = msrp && msrp > price
  const imageSize = isDesktop ? { middle: true } : { small: true }

  const discountStyle = discountBackgroundColor ? {
    // "backgroundColor": `${discountBackgroundColor}`
    backgroundColor: '#FFE14D',
  } : null

  const discountTriangleStyle = discountBackgroundColor ? {
    // "borderTopColor": `${discountBackgroundColor}`
    borderTopColor: '#FFE14D',
  } : null

  const handleCardClick = () => {
    if (props && props.handle) {
      plugin.$track.event('FlamingDealsCard', `click_${props.handle}`)
    }
  }

  const onFlamingDealsItemClick = product => {
    if (props.sourceLocator && props.trackName) {
      plugin.$track.clickMainResourceLocator(`${props.sourceLocator}:${props.trackName}_${product.handle}`)
    }
  }

  return (
    <Wrapper
      className={cx(styles.wrapper, isDesktop && styles.inDesktop)}
      {...interactiveProps}
      product={product}
      disableClick={props.disableClick}
      onClick={onFlamingDealsItemClick}
    >
      <div className={styles.cardWrapper}>
        {
          hasOriginalPrice && (
            <FlashDiscount
              small
              discountStyle={discountStyle}
              isDesktop={isDesktop}
              price={price}
              msrp={msrp}
              discountTriangleStyle={discountTriangleStyle}
            ></FlashDiscount>
          )
        }
        <div className={styles.imageWrapper}>
          <Image
            {...imageSize}
            lazy
            objectFit='cover'
            {...product.mainImage}
            className={cx(styles.cardImage, isDesktop && styles.inDesktop)}
            title={product.mainImage.title || `${product.title} | ${site.fullSiteName}`}
            onClick={handleCardClick}
            placeholder={{
              width: 2,
              height: 3
            }}
            disableClick={props.disableClick}
            crop={{ width: 2, height: 3 }}
          />
        </div>
        <div className={styles.price}>
          <div className={cx(styles.salePrice, hasOriginalPrice && styles.highLight)}>
            <FlashIcon isDesktop={isDesktop} className={styles.dealCardFlashIcon} />
            {displayPrice}
          </div>
          {
            hasOriginalPrice && <div className={cx(styles.originalPrice, isDesktop && styles.inDesktop)}>{displayMsrp}</div>
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default FlamingDealsCard
