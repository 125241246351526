import React, { useState, useEffect } from 'react'
import ProductBasicInfoSection from '../Product/ProductBasicInfoSection'
import ProductFooterBar from '../Product/ProductFooterBar'
import { SizeChartModal } from '../Product/ProductSizeChartSection'
import { withDrawer } from '../../common/Drawer/Drawer'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './ProductDrawer.module.css'

const ProductDrawer = props => {
  const [showSizeChart, setShowSizeChart] = useState()
  const showSizeChartModal = () => {
    const { $track } = props

    $track.event('ProductPage', 'click_size_chart', props.handle)
    setShowSizeChart(true)
  }

  useEffect(() => {
    if (props.scrollRef && props.scrollRef.current && typeof props.scrollRef.current.scrollTo === 'function' && props.selectedVariants && props.selectedVariants.Size && props.scrollToSelectedSize) {
      props.scrollRef.current.scrollTo({
        top: (props.scrollRef.current.scrollTop || 0) + 200,
        behavior: 'smooth'
      })
    }
  }, [props.selectedVariants, props.scrollRef, props.scrollToSelectedSize])

  const closeSizeChartModal = () => setShowSizeChart(false)

  const {
    sizeChart = {},
    feature = {}
  } = props.meta || {}

  const { sizeChartTemplate } = props.product || {}

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
        <ProductBasicInfoSection
          {...props}
          showSizeChartModal={showSizeChartModal}
          sizeChartTemplate={sizeChartTemplate}
          renderSizeLink={true}
          hideVideo={true}
        />
        {
          showSizeChart &&
          <SizeChartModal
            $i18n={props.$i18n}
            productTitle={props.title}
            sizeChart={sizeChart}
            sizeChartTemplate={sizeChartTemplate}
            feature={feature}
            onClose={closeSizeChartModal}
            className={styles.sizeChartPadding}
          />
        }
        <ProductFooterBar
          className={styles.fixedBar}
          {...props}
        />
      </div>
    </div>
  )
}

export default withDrawer(withPlugin(ProductDrawer))