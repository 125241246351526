import React, { useContext, useState } from 'react'
import ProductCardPrice from './ProductCardPrice'
import { ColorSwatchSlider, ColorSwatch } from './ColorSwatch'
import QuickAddHoverBtnCard from '../Product/QuickAddHoverBtnCard'
import QuickAddBtn from '../Product/QuickAddBtn'

import FlashDiscount, { FlashIcon } from './FlashDiscount'
import Image from '../../common/Image/Image'

import { PluginContext } from '@flamingo_tech/funkgo'

import FixBottomInfoImage from '../../GlobalPromotion/FixBottomInfoImage'
import { getDiscountText } from '../../../utils/priceUtils'
import cx from '../../../utils/className'

import styles from './ProductCard.module.css'

/* ------------------------- */

const trackClickProduct = (product, index, { plugin, resourceLocatorConfig, isQuickAdd }) => {
  if (plugin.$track) {
    plugin.$track.clickProduct(product, index, isQuickAdd)
    if (resourceLocatorConfig) {
      const { resourceLocator, params, type } = resourceLocatorConfig

      if (type === 'main') {
        plugin.$track.clickMainResourceLocator(`${resourceLocator}_${product.handle}`, {
          ...params,
          index,
          handle: product.handle,
          id: product.id
        })
      } else if (type === 'sub'){
        plugin.$track.clickSubResourceLocator(`${resourceLocator}_${product.handle}`, {
          ...params,
          index,
          handle: product.handle,
          id: product.id
        })
      }
    }
  }
}

/* ------------------------- */
export const makeInteractiveProps = (product, interactiveOpts, plugin) => {
  if (plugin.$detector.compareAppVersion({ targetVersion: '6.2.0' })) {
    return {
      link: interactiveOpts.link,
      to: interactiveOpts.to,
      onClick: ev => {
        ev.preventDefault()
        if (interactiveOpts.onClick && typeof interactiveOpts.onClick === 'function') {
          interactiveOpts.onClick(ev)
        } else {
          const fromCollection = product.fromCollection
          const subChannel = product.getSubChannel && product.getSubChannel(product.handle)
          var scheme = `/product?handle=${product.handle}`
          if (fromCollection) {
            scheme = scheme + `&fromCollection=${fromCollection}`
          }
          if (subChannel) {
            scheme = scheme + `&subChannel=${subChannel}`
          }
          plugin.$router.navigateToApp({
            scheme: scheme
          })
        }
      }
    }
  }

  if (interactiveOpts.disabled) {
    return {}
  } else if (interactiveOpts.onClick) {
    return {
      onClick: ev => {
        trackClickProduct(product, interactiveOpts.index, { plugin, resourceLocatorConfig: interactiveOpts.resourceLocatorConfig })
        interactiveOpts.onClick(product, ev, interactiveOpts.index)
      }
    }
  } else {
    if (interactiveOpts.link || interactiveOpts.to) {
      return {
        link: interactiveOpts.link,
        to: interactiveOpts.to,
        onClick: () => {
          trackClickProduct(product, interactiveOpts.index, { plugin, resourceLocatorConfig: interactiveOpts.resourceLocatorConfig })
        }
      }
    } else {
      return {
        to: Object.assign({
          name: 'Product',
          params: {
            handle: product.handle
          },
        }, interactiveOpts.toExtraInfo),
        onClick: ev => {
          ev.preventDefault()
          trackClickProduct(product, interactiveOpts.index, { plugin, resourceLocatorConfig: interactiveOpts.resourceLocatorConfig })
          plugin.$router.navigateToProductPage(product, interactiveOpts.toExtraInfo)
        }
      }
    }
  }
}

const TYPE = {
  BUTTON: 'button',
  ICON: 'icon'
}

const ProductCard = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const site = plugin.$site.getSiteInfo()
  const [currentImg, setCurrentImg] = useState('')

  const {
    renderActionBar,
    renderTag,
    renderTitle,
    renderPrice,
    renderImage,
    renderProgressBar,
    showFloatDiscount,
    renderFloatDiscount,
    renderAvailableForSale,
    renderFlashSaleInfo,
    renderDiscount,
    onClick,
    onClickQuickAdd = () => {},
    link,
    to,
    toExtraInfo,
    disabled,
    index,
    renderColorSwatch,
    promotionTag,
    renderPromotion,
    type = isDesktop ? TYPE.BUTTON : TYPE.ICON,
    hideQuickAdd = false,
    selectedVariants,
    disableClick,
    resourceLocatorConfig,
    lazy,
    needFetchFullAfterAddCart,
    isNew,
    ...product
  } = props

  const interactiveProps = makeInteractiveProps(product, {
    index,
    disabled,
    onClick,
    link,
    to,
    toExtraInfo,
    resourceLocatorConfig
  }, plugin)

  const mainImage = product.mainImage || {}

  const imageProps = {
    ...mainImage,
    ...interactiveProps,
    className: cx(styles.image, props.className),
    middle: true,
    placeholder: {
      width: 2,
      height: 3
    },
    lazy: lazy || index > 4,
    title: mainImage && mainImage.title
      ? mainImage.title
      : `${props.title} | ${site.fullSiteName}`,
    objectFit: 'cover',
    crop: { width: 2, height: 3 },
    quality: 60,
    disableClick
  }

  if (currentImg) {
    imageProps.src = currentImg
  }

  const renderTitleElement = (render, title) => {
    if (typeof render === 'function') {
      return render(title)
    }

    return (
      <div className={styles.titleWrapper}>
        {isNew && <div className={styles.newTag}>{plugin.$i18n.transl('core.product.new')}</div>}
        <div className={styles.title}>{title}</div>
      </div>
    )
  }

  const renderColorSwatchElement = (render, product) => {
    if (typeof render === 'function') {
      return render(product)
    }

    const ColorSwatchComponent = isDesktop ? ColorSwatch : ColorSwatchSlider

    return (
      <ColorSwatchComponent className={styles.colorSwatchBox} product={product} handleChangePic={url => setCurrentImg(url)} />
    )
  }

  const renderPromotionTagElement = (render, promotionTag) => {
    if (typeof render === 'function') {
      return render(promotionTag)
    }

    return null
  }

  const renderActions = (render) => {
    if (typeof render === 'function') {
      return render(product)
    }

    return renderColorSwatchElement(renderColorSwatch, product)
  }

  const handleClickAddCart = () => {
    trackClickProduct(product, index, { plugin, resourceLocatorConfig, isQuickAdd: true })
    onClickQuickAdd(product, index)
  }

  const renderAvailableForSaleElement = (render, product) => {
    if (typeof render === 'function') {
      return render(product)
    }

    return null
  }

  const renderImageElement = (render, product) => {
    if (typeof render === 'function') {
      return render(product, imageProps)
    }

    return (
      <FixBottomInfoImage
        product={product}
      >
        {
          hideQuickAdd || !isDesktop
            ? <Image {...imageProps}></Image>
            : (
              <QuickAddComponent
                product={product}
                selectedVariants={selectedVariants}
                imageProps={imageProps}
                onClickAddCart={handleClickAddCart}
                needFetchFullAfterAddCart={needFetchFullAfterAddCart}
              />
            )
        }
        {renderAvailableForSaleElement(renderAvailableForSale, product)}
      </FixBottomInfoImage>
    )
  }

  const renderFloatDiscountElement = (render, product) => {
    if (typeof render === 'function') {
      return render(product)
    }
    const { price, msrp } = product.mainVariation || {}

    if (props.inFlashSale || product.isFlashSale) {
      return <FlashDiscount isDesktop={isDesktop} price={price} msrp={msrp} />
    } else if (msrp && msrp > price) {
      return (
        <div className={styles.floatDiscount}>{getDiscountText(price, msrp)}</div>
      )
    } else {
      return null
    }
  }

  const renderPriceElement = (render, product) => {
    if (typeof render === 'function') {
      return render(product)
    }

    return (
      <div className={styles.price}>
        <div>
          { (props.inFlashSale || product.isFlashSale) && <FlashIcon isDesktop={isDesktop} className={cx(styles.productCardFlashIcon, isDesktop && styles.inDesktop)} /> }
          <ProductCardPrice {...product} hideOriginalPrice={showFloatDiscount}></ProductCardPrice>
        </div>
        {
          !isDesktop && <QuickAddComponent
          product={product}
          selectedVariants={selectedVariants}
          imageProps={imageProps}
          onClickAddCart={handleClickAddCart}
          needFetchFullAfterAddCart={needFetchFullAfterAddCart}
          />
        }

      </div>
    )
  }

  const renderColorsOrTitle = product => {
    if (typeof renderFlashSaleInfo !== 'undefined') {
      return !isDesktop && <h3 className={cx(styles.title, styles.nowrap)}>{props.title}</h3>
    }

    if (isDesktop) {
      return product.colorSwatchList && product.colorSwatchList.length >= 1 &&
      renderActions(renderColorSwatch)
    } else {
      return null
    }
  }

  const QuickAddComponent = type === TYPE.BUTTON ? QuickAddHoverBtnCard : QuickAddBtn

  return (
    <div className={cx(styles.card, isDesktop && styles.inDesktop)} key={product.id}>
      {renderImageElement(renderImage, product)}
      {renderFloatDiscountElement(renderFloatDiscount, product)}
      {
        isDesktop ?
          <div className={styles.contentWrapper}>
            {renderTitleElement(renderTitle, props.title)}
            {renderPriceElement(renderPrice, product)}
            {renderColorsOrTitle(product)}
            {renderPromotionTagElement(renderPromotion, promotionTag)}
          </div>
          :
          <div className={styles.contentWrapper}>
            {renderTitleElement(renderTitle, props.title)}
            {renderPriceElement(renderPrice, product)}
            {renderColorsOrTitle(product)}
            {renderPromotionTagElement(renderPromotion, promotionTag)}
          </div>
      }
    </div>
  )
}

ProductCard.TYPE = TYPE

export default ProductCard
