import React, { useContext, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import AppStateContext from '../../hooks/AppStateContext'

import AppLayoutContainer from './AppLayout/AppLayoutContainer'
import StoreLayoutContainer from './StoreLayout/StoreLayoutContainer'
import DesktopLayoutContainer from './DesktopLayout/DesktopLayoutContainer'
import GlobalWidgetsContainer from './GlobalWidgets/GlobalWidgetsContainer'
import DownloadBannerContainer from './DownloadBannerContainer'

import StoreService from '../../services/StoreService'

const PageShellContainer = props => {
  const appState = useContext(AppStateContext)
  const { site } = appState

  const { $detector } = useContext(PluginContext)
  const isApp = $detector.isApp()
  const isStandaloneApp = $detector.isStandaloneApp()
  const isDesktop = $detector.isDesktop()

  useEffect(() => {
    if (site.theme && typeof window !== undefined) {
      window.document.documentElement.style.setProperty('--primary', site.theme)
    }
  })

  const LayoutContainer = isDesktop ? DesktopLayoutContainer
    : (
      isStandaloneApp
        ? AppLayoutContainer
        : StoreLayoutContainer
    )

  // for legacy app v1 ~ v4, hide all pagination on h5 page
  if (isApp && !isStandaloneApp) {
    return props.children
  }

  const renderBanner = () => {
    if (typeof window !== 'undefined') {
      return <DownloadBannerContainer {...props}></DownloadBannerContainer>
    }

    return null
  }

  return (
    <>
      {renderBanner()}
      <LayoutContainer {...props}></LayoutContainer>
      <GlobalWidgetsContainer {...props}></GlobalWidgetsContainer>
    </>
  )
}

PageShellContainer.fetchStaticShellProps = ({ $http }) => {
  return new StoreService($http).fetchFeaturedCategories().then(naviItems => ({ naviItems })).catch(() => undefined)
}

export default PageShellContainer
