import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import ProductCard from '../ProductCard/ProductCard'
import FlexList from '../../common/List/FlexList'
import MultiColumns from '../../common/List/MultiColumns'
import ViewMoreButton from '../Product/ViewMoreButton'

import {
  getProductFetchFlag
} from './utils/productListUtils'

import {
  findFromArray
} from '../../../utils/arrayUtils'

import {
  RECOMMENDATIONS_COLLECTION_HANDLE
} from '../../../utils/Store/productUtils'

import {
  convertSearch
} from '../../../utils/breadcrumbUtils'

import callAdapter from './utils/productListAdapter'
import cx from '../../../utils/className'
import styles from './ESProductList.module.css'

const PRELOAD_SLICE_COUNT = 2

const FIRST_SLICE_KEY = 1 // fetch from page:1

class ProductList extends PureComponent {

  static makeSlice(key, products, pageInfo, extraCursor) {

    return {
      key,
      nextKey: key + 1,
      products,
      hasNextPage: pageInfo.hasNextPage,
      extraCursor
    }
  }

  static initState(props) {
    let slices = []

    // make first slice
    if (props.products && props.products.length) {
      const products = props.topProduct
        ? [
          props.topProduct,
          ...props.products
        ]
        : props.products

      const pageInfo = props.pageInfo

      slices.push(
        ProductList.makeSlice(FIRST_SLICE_KEY, products, pageInfo, props.extraCursor)
      )
    }

    const state = {
      lastScreenSliceKey: FIRST_SLICE_KEY,
      initProducts: props.products,
      initPageInfo: props.pageInfo,
      initProductFetchFlag: getProductFetchFlag(props),
      slices: slices
    }

    return state
  }

  static initEnsureProcess() {
    return {
      fetching: false,
      error: 0
    }
  }

  state = ProductList.initState(this.props)
  ensureProcess = ProductList.initEnsureProcess()

  _isMounted = false

  static getDerivedStateFromProps(nextProps, prevState) {
    // if input product has been changed, then return init state to re-fetch
    if (nextProps.products !== prevState.initProducts) {
      return ProductList.initState(nextProps)
    } else if (nextProps.products === undefined) {
      const nextProductFetchFlag = getProductFetchFlag(nextProps)

      // if has no init products, and fetch flag changed, als return init state to re-fetch
      if (nextProductFetchFlag !== prevState.initProductFetchFlag) {
        return ProductList.initState(nextProps)
      }
    }
    return null
  }

  /* ----------------------------------------- */
  callAdapter(name, ...args) {
    return callAdapter(this.props, name, args)
  }
  /* ----------------------------------------- */

  resetCachedProps() {
    this.ensureProcess = ProductList.initEnsureProcess()
  }

  componentDidMount() {
    this._isMounted = true
    this.ensureSlices()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    // if initProducts hs been changed via getDerivedStateFromProps, then re-init
    if (this.state.initProducts !== prevState.initProducts
      || this.state.initProductFetchFlag !== prevState.initProductFetchFlag) {
      this.resetCachedProps()
      this.ensureSlices()
    } else if (this.readyToFetch(this.props) && !this.readyToFetch(prevProps)) {
      this.ensureSlices()
    }
  }

  /* ----------------------------------------- */
  readyToFetch({readyToFetch}) {
    return readyToFetch !== false
  }

  // load slice from server side
  fetchSlice(page, extraCursor) {
    const pagination = {
      page,
      pageSize: this.props.pageSize
    }

    if (this.props.collectionHandle) {
      return this.props.productService.fetchESCollection(
        this.props.collectionHandle,
        {
          ...pagination,
          ...this.props.queryOptions,
          extraCursor
        }
      ).then(
        ({ pageInfo, products, extraCursor }) => this.pushSlice(page, products, pageInfo, extraCursor)
      )
      .catch(() => this.pushSlice(page, [], {})) // service 接口挂掉之后，填充空的 slice，不要反复再去请求
    }
  }

  pushSlice(key, product, pageInfo, extraCursor) {
    if (!this._isMounted) {
      return Promise.resolve()
    }
    return new Promise(resolve => {
      const slice = ProductList.makeSlice(key, product, pageInfo, extraCursor)
      this.setState({
        slices: [
          ...this.state.slices,
          slice
        ]
      }, resolve)
    })
  }

  /* ----------------------------------------- */

  handleReachEnd = () => {
    // if there is no slices, then ensure it first
    let process
    if (!this.state.slices.length) {
      process = true
    } else {
      const { slice } = this.getLastScreenSlice()
      process = this.updateScreenSliceToNext(slice)
    }

    Promise.resolve(process).then(() => this.ensureSlices())

    if (typeof this.props.onReachEnd === 'function') {
      this.props.onReachEnd()
    }
  }

  handleViewMore = () => {
    this.handleReachEnd()
  }

  /* ----------------------------------------- */
  getSliceByKey(key) {
    return findFromArray(this.state.slices
      , slice => slice.key === key
    )
  }

  updateScreenSliceToNext(slice) {
    if (this._isMounted && slice.hasNextPage) {
      return new Promise(resolve => {
        this.setState({
          lastScreenSliceKey: slice.nextKey
        }, resolve)
      })
    }
    return Promise.resolve()
  }

  getLastSlice() {
    const slices = this.state.slices
    const index = slices.length - 1
    const slice = slices[slices.length - 1]
    return {
      key: slice ? slice.key : FIRST_SLICE_KEY,
      index,
      slice
    }
  }

  getLastScreenSlice() {
    const key = this.state.lastScreenSliceKey

    let slice = this.getSliceByKey(key)

    if (!slice) {
      return this.getLastSlice()
    }

    return {
      key,
      slice,
      index: this.state.slices.indexOf(slice)
    }
  }

  ensureFetch(page, extraCursor) {
    if (!this.props.productService) {
      this.props.$logger.error(`[ProductList] no init products and not specific product service`)
      return
    } else if (this.ensureProcess.fetching) {
      return
    }

    this.ensureProcess.fetching = true
    this.fetchSlice(page, extraCursor).then(() => {
      this.ensureProcess.error = 0
    }, () => {
      this.ensureProcess.error += 1
    }).then(() => {
      this.ensureProcess.fetching = false
      this.ensureSlices()
    })
  }

  ensureSlices = () => {
    if (!this.readyToFetch(this.props)) {
      return
    }

    if (!this._isMounted) {
      return
    }

    const {
      key,
      slice
    } = this.getLastSlice()

    const {
      index: lastScreenIndex
    } = this.getLastScreenSlice()

    // if no slice, then fetch directly
    if (!slice) {
      this.ensureFetch(key)
    } else if (slice.hasNextPage) {
      const slicesCount = this.state.slices.length
      const preferredCount = lastScreenIndex + PRELOAD_SLICE_COUNT

      if (slicesCount <= preferredCount) {
        this.ensureFetch(key + 1, slice && slice.extraCursor)
      }
    }
  }

  getRenderItems() {
    const screenProducts = []

    const { index } = this.getLastScreenSlice()

    this.state.slices.every(({ products }, i) => {
      if (i <= index) {
        screenProducts.push(...products)
        return true
      } else {
        return false
      }
    })

    const products = this.callAdapter('extractProducts', screenProducts)
    return this.callAdapter('uniqueProducts', products)
  }

  makeToExtraInfo() {
    const originalToExtraInfo = this.props.toExtraInfo || {}
    const definition = this.props.definition

    const toExtraInfo = {
      ...originalToExtraInfo,
      search: {
        ...originalToExtraInfo.search
      }
    }


    const existsBreadcrumb = convertSearch(toExtraInfo.search)
    const collectionHandle = definition && definition.collectionSource && definition.collectionSource.handle

    if (
      collectionHandle
      && existsBreadcrumb.routeName !== 'Collection'
      && existsBreadcrumb.routeHandle !== collectionHandle
    ) {
      // if data is from collection, specific recommendation collection as that
      // , but skip if the breadcrumb is same
      toExtraInfo.search[RECOMMENDATIONS_COLLECTION_HANDLE] = definition.collectionSource.handle
    }

    return toExtraInfo
  }

  render() {
    const props = this.props

    if (!this.readyToFetch(props)) {
      return null
    }

    const CardIs = props.cardIs || ProductCard

    const isDesktop = this.props.$detector.isDesktop()
    const ListComponent = props.listIs || (isDesktop ? FlexList : MultiColumns)

    const cardOptions = {
      promotionTags: props.promotionTags,
      onClick: props.onClick,
      renderActionBar: props.renderActionBar,
      renderTag: props.renderTag,
      renderPrice: props.renderPrice,
      toExtraInfo: this.makeToExtraInfo(),
      style: props.style,
      ...props.cardOptions,
    }

    const { slice } = this.getLastScreenSlice()

    return (
      <>
        <ListComponent
          childIs={CardIs}
          columns={props.columns}
          mobileColumns={props.mobileColumns}
          childItems={this.getRenderItems()}
          childOptions={cardOptions}
          className={cx(styles.listComponent, isDesktop && styles.pcListComponent,props.className)}
          columnClassName={cx(styles.listColumn, isDesktop && styles.pcListColumn, props.columnClassName)}
          onReachEnd={isDesktop ? null : this.handleReachEnd}
          style={props.style}
        ></ListComponent>
        {
          isDesktop && slice && slice.hasNextPage && <ViewMoreButton onClick={this.handleViewMore} />
        }
      </>
    )
  }
}

export default withPlugin(ProductList)
