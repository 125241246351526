import React, { useContext, useRef } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import cx from '../../../utils/className'
import Link from '../../common/Link/Link'
import { FlashIcon } from '../ProductCard/FlashDiscount'
import Countdown from '../../Widget/Countdown/DetailCountdown'

import styles from './FlashPromotionBar.module.css'


const FlashPromotionBar = (props) => {
  const { wrapperClassName } = props
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const countDownRef = useRef(null)

  if (!props.product.promotionActivities && !props.product.promotions) {
    return null
  }

  const promotions = props.product.promotionActivities || props.product.promotions

  const getCountDownInfo = () => {
    const result = {}
    for (let i of promotions) {
      if (i.name === 'FlashSale') {
        result.time = i.endTime - Date.now()
        break
      }
    }
    return result
  }

  const renderCountDown = () => {
    const countdownInfo = getCountDownInfo()
    if (countdownInfo && countdownInfo.time > 0) {
      const extraRenderOptions = [
        { appendText: 'h', extraTimeClass: styles.timeSpan },
        { appendText: 'm', extraTimeClass: styles.timeSpan },
        { appendText: 's', extraClockSeparatorStyle: { display: 'none' }, extraTimeClass: cx(styles.sTime, styles.timeSpan) },
        { appendText: '', extraClockSpanStyle: { background: '', color: 'white' }, extraClockSeparatorStyle: {}, extraTimeClass: cx(styles.timeSpan, styles.msTime) },
      ]
      const trackClickFlashSale = () => {
        plugin.$track.event('Product', 'click_flash_sale')
      }
      return (
        <div className={cx(styles.countDown, isDesktop && styles.inDesktop)}>
          <span className={styles.text}>{plugin.$i18n.transl('core.promotion.endsIn').toLowerCase()}</span>
          <Countdown
            ref={countDownRef}
            color='#000000'
            background='transparent'
            separatorColor='#000000'
            timestamp={countdownInfo.time}
            className={styles.counts}
            extraRenderOptions={extraRenderOptions}
          ></Countdown>
           <Link to="FlashSale" onClick={trackClickFlashSale}><div className={cx(styles.rightArrow)}>&#xe608;</div></Link>
        </div>
      )
    }
    return null
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, wrapperClassName)}>
      <div className={styles.leftWrapper}>
        <FlashIcon isDesktop={isDesktop} className={styles.flashIcon} />
        <div className={styles.text}>{plugin.$i18n.transl('core.promotion.flashSale.title')}</div>
      </div>
      <div className={styles.rightWrapper}>{renderCountDown()}</div>
    </div>
  )
}
  
export default FlashPromotionBar