import React, { useState, useContext, useEffect } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import cx from '../../utils/className'
import styles from './WishButton.module.css'

export const TYPE = {
  NO_BACKGROUND: 'NO_BACKGROUND',
  WITH_BACKGROUND: 'WITH_BACKGROUND',
}


const WishButton = props => {
  const plugin = useContext(PluginContext)
  const [isWished, setIsWished] = useState(props.isWished)
  const isDesktop = plugin.$detector.isDesktop()

  useEffect(() => {
    setIsWished(props.isWished)
  }, [props.isWished])

  const handleClickWish = () => {
    setIsWished(x => !x)
    props.onWishChanged()
  }

  return (
    <div
      className={cx(props.type === TYPE.NO_BACKGROUND ? styles.noBackground : null, styles.wish, isWished && styles.wished, isDesktop && styles.inDesktop, props.className)}
      onClick={handleClickWish}
    />
  )
}

export default WishButton