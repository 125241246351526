import React, { useContext, useMemo } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import PageHeader from '../../common/PageHeader/PageHeader'
import { getAllPaymentMethodsAllPlatform } from '../Cart/Acceptions'
import Image from '../../common/Image/Image'

import styles from './PaymentMethod.module.css'
import cx from '../../../utils/className'

const PaymentMethod = () => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const isApp = plugin.$detector.isApp()
  const { countryCode } = plugin.$site.getSiteInfo()

  const handleBack = () => {
    plugin.$track.event('DesktopWishPage', 'goBack')
    plugin.$router.goBack()
  }

  const payDictionary = useMemo(() => {
    return getAllPaymentMethodsAllPlatform(countryCode).reduce((t, c) => {
      return {
        ...t,
        [c.key]: c
      }
    }, {})
  }, [countryCode])

  const {
    paypal,
    afterpay,
    klarna,
    google,
    apple,
    ...restPayDictionary
  } = payDictionary

  return (
    <>
      {
        !isDesktop && !isApp && (
          <PageHeader
            title={plugin.$i18n.transl('core.security.paymentMethod.title')}
            onHistoryBack={handleBack}
          />
        )
      }

      <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
        {
          isDesktop && <div className={styles.mobileTitle}>{plugin.$i18n.transl('core.security.paymentMethod.title')}</div>
        }
        <div className={styles.title}>{plugin.$i18n.transl('core.security.paymentMethod.payWithCard')}</div>
        <div className={styles.payMethodCardGroup}>
          {
            getAllPaymentMethodsAllPlatform(countryCode).filter(i => restPayDictionary[i.key]).map(iconUrl => {
              return <Image src={iconUrl.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' key={iconUrl.key} small />
            })
          }
        </div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.paymentMethod.payWithCardDesc')}</div>
        <div className={styles.title}>{plugin.$i18n.transl('core.security.paymentMethod.payWithPaypal')}</div>
        <div className={styles.cardImage}><Image src={paypal.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' small/></div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.paymentMethod.payWithPaypalDesc')}</div>

        {
         afterpay && afterpay.imageUrl &&
          <>
            <div className={styles.title}>{plugin.$i18n.transl('core.security.paymentMethod.payWithAfterPay')}</div>
            <div className={styles.cardImage}><Image src={afterpay.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' small/></div>
            <div className={styles.desc}>{plugin.$i18n.transl('core.security.paymentMethod.payWithAfterPayDesc')}</div>
          </>
        }

        <div className={styles.title}>{plugin.$i18n.transl('core.security.paymentMethod.payWithKlarna')}</div>
        <div className={styles.cardImage}><Image src={klarna.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' small/></div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.paymentMethod.payWithKlarnaDesc')}</div>

        <div className={styles.title}>{plugin.$i18n.transl('core.security.paymentMethod.payWithGooglePay')}</div>
        <div className={styles.cardImage}><Image src={google.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' small/></div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.paymentMethod.payWithGooglePayDesc')}</div>

        <div className={styles.title}>{plugin.$i18n.transl('core.security.paymentMethod.payWithApplePay')}</div>
        <div className={styles.cardImage}><Image src={apple.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' small/></div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.paymentMethod.payWithApplePayDesc')}</div>
      </div>
    </>
  )
}

export default PaymentMethod