import React, { useEffect } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import { withModal } from '../../common/Modal/Modal'
import Image from '@components/common/Image/Image'


import styles from './BigSaleModal.module.css'

const BigSaleModal = withModal(props => {
  const { src } = props.popup

  useEffect(() => {
    props.$track.event('BigSaleModal', 'view')
  }, [props.$track])

  return (
    <div className={styles.wrapper} onClick={props.onClose}>
      <Image src={src} original/>
    </div>
  )
}, { showCloseButton: false })

export default withPlugin(BigSaleModal)