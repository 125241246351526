import React, { useState, useEffect, Fragment } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import Image from '../Image/Image'

import cx from '../../../utils/className'
import styles from './SpecList.module.css'

// 指标类目（COLOR|SIZE走接口，RATING|PICTURE前端定义）
const CATEGORY = {
  COLOR: {
    name: 'Color',
    displayName: 'Color'
  },
  SIZE: {
    name: 'Size',
    displayName: 'Size'
  },
  RATING: {
    name: 'Rating',
    displayName: 'core.product.rating'
  },
  PICTURE: {
    name: 'Picture',
    displayName: 'core.product.picture'
  }
}

// UI侧-长按钮类目
const lengBtnCategorys = [CATEGORY.COLOR.name]
// 需要前端翻译的类目
const needTranslateCategoryNames = [CATEGORY.RATING.name, CATEGORY.PICTURE.name]

const SpecList = (props) => {
  const { optionData, onClearAllByMultiple, onConfirm, hasFooter, $i18n } = props

  // 清空按钮展示
  const [showClearBtn, setShowClearBtn] = useState(false)
  useEffect(() => {
    const hasSelectedItem = optionData.some((specItem) =>
      specItem.values.some((v) => !!v.selected)
    )
    setShowClearBtn(hasSelectedItem ? true : false)
  }, [optionData])

  // 点击option
  const handleClickOption = (index, item) => {
    props.onChange({ index, item })
  }
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={styles.wrapper}>
      <div className={styles.specBox}>
        {optionData?.length &&
          optionData.map((specItem, specIndex) => {
            return (
              <Fragment key={specItem.name}>
                <div
                  className={cx(
                    styles.specLabel,
                    isDesktop && styles.inDesktop
                  )}
                >
                  {needTranslateCategoryNames.includes(specItem.name)
                    ? $i18n.transl(specItem.displayName)
                    : specItem.displayName}
                </div>
                <ul className={styles.specItemBox}>
                  {specItem?.values?.length &&
                    specItem.values.map((v) => {
                      return (
                        <li
                          className={cx(
                            styles.specItemBtn,
                            v.selected && styles.active,
                            isDesktop && styles.inDesktop,
                            lengBtnCategorys.includes(specItem.name) &&
                              styles.leng
                          )}
                          key={v.code}
                          onClick={() => handleClickOption(specIndex, v)}
                        >
                          {specItem.name === CATEGORY.COLOR.name && (v.color || v.colorImageLink) ? (
                            v.color ?
                            <span
                              className={styles.dot}
                              style={{ background: v.color }}
                            ></span> :
                            <Image className={styles.dot} src={v.colorImageLink} small crop={{ width: 1, height: 1}}/>
                          ) : null}
                          {v.name}
                        </li>
                      )
                    })}
                </ul>
              </Fragment>
            )
          })}
      </div>
      {hasFooter && (
        <div className={styles.specFooter}>
          {showClearBtn && (
            <div
              className={cx(styles.button, styles.clear)}
              onClick={onClearAllByMultiple}
            >
              {$i18n.transl('common.clear')}
            </div>
          )}
          <div className={cx(styles.button, styles.done)} onClick={onConfirm}>
            {$i18n.transl('common.done')}
          </div>
        </div>
      )}
    </div>
  )
}

export { CATEGORY }
export default withPlugin(SpecList)
