let youtubeAPILoaded = false
const youtubeAPIReadyCallbacks = []

const installYoutubeScript = () => {
  if (!window.YT) {
    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    document.body.appendChild(script)
  }
}

export const loadYouTubeIframeAPI = () => {
  if (youtubeAPILoaded) {
    // If API is already loaded, trigger all pending callbacks immediately
    youtubeAPIReadyCallbacks.forEach((callback) => callback())
    youtubeAPIReadyCallbacks.length = 0 // Clear callbacks after execution
    return
  }

  // Dynamically load the YouTube Iframe API script
  installYoutubeScript()

  // Setup the global onYouTubeIframeAPIReady function
  window.onYouTubeIframeAPIReady = () => {
    youtubeAPILoaded = true
    youtubeAPIReadyCallbacks.forEach((callback) => callback())
    youtubeAPIReadyCallbacks.length = 0 // Clear callbacks after execution
  }
}

// Register a callback to be executed when the API is ready
export const onYouTubeIframeAPIReady = (callback) => {
  if (youtubeAPILoaded) {
    callback() // If already loaded, execute callback immediately
  } else {
    youtubeAPIReadyCallbacks.push(callback) // Otherwise, add to pending list
  }
}
