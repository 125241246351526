import React from 'react'

import cx from '../../../utils/className'
import styles from './SelectIcon.module.css'

const TYPE = {
  RADIO: styles.radio,
  CHECKBOX: styles.checkbox
}

const SelectIcon = (props) => {
  const typeClassName = props.type || TYPE.RADIO

  return (
    <div onClick={props.onClick || (() => null)} className={cx(styles.icon, typeClassName, (props.selected && !props.disabled) ? styles.selected : null, props.className || null)}>
    </div>
  )
}

SelectIcon.TYPE = TYPE

export default SelectIcon
