import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import StarRating from './StarRating'

import styles from './ProductCommentSectionHeaderSummary.module.css'
import cx from '../../../utils/className'

// 评分头部总览组件-pc|h5共用
const ProductCommentSectionHeaderSummary = props => {

  const { overallCount, star } = props.comment

  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
      <span className={styles.starNum}>{star}</span>
      <div className={styles.reviewRating}>
        <StarRating star={star}/>
      </div>
      <span className={styles.reviewsText}>{`(${overallCount} ${plugin.$i18n.transl('core.product.reviewsText')})`}</span>
    </div>
  )
}

export default ProductCommentSectionHeaderSummary