import React, { useState, useEffect } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './ShippingInfoPage.module.css'
import cx from '../../../utils/className'

// 抽屉内容
const ShippingInfoPage = withPlugin((props) => {
  const { Trans } = props.$i18n
  const isDesktop = props.$detector.isDesktop()
  const [current, setCurrent] = useState(0)
  const shippingInfo = props.shippingInfo || []
  const {
    displayShippingFee,
    displayFreeShippingFee,
    ProcessingMinTime,
    ProcessingMaxtTime,
    transitMinTime,
    transitMaxTime
  } = shippingInfo[current] || {}

  useEffect(() => {
    if (props.currentIndex) {
      setCurrent(props.currentIndex)
    }
  }, [props.currentIndex])

  const trans = core => props.$i18n.transl(`core.shippingAddress.${core}`)

  return (
    <div className={cx(styles.shippingTable, isDesktop && styles.isDesktop)} ref={props.scrollAreaRef}>
      <div className={styles.contents}>
        <div className={styles.shippingTab}>
          {
            shippingInfo.map((tab, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setCurrent(index)}
                  className={cx(styles.shippingTabItem, current === index && styles.active)}
                >{props.$i18n.transl(tab.i18nKey)}</div>
              )
            })
          }
        </div>
        <div className={styles.shippingStandards}>
          <table className={styles.shippingTables}>
            <tbody>
              <tr>
                <td><Trans i18nKey={`core.shippingAddress.OrderUnder`}>Order Under <span>{{ displayFreeShippingFee }}</span></Trans></td>
                <td>{displayShippingFee}</td>
                <td>{parseInt(ProcessingMinTime + transitMinTime)}-{parseInt(ProcessingMaxtTime + transitMaxTime)} {trans('days')}*</td>
              </tr>
              <tr>
                <td><Trans i18nKey={`core.shippingAddress.OrderOver`}>Order Under <span>{{ displayFreeShippingFee }}</span></Trans></td>
                <td>{trans('free')}</td>
                <td>{parseInt(ProcessingMinTime + transitMinTime)}-{parseInt(ProcessingMaxtTime + transitMaxTime)} {trans('days')}*</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.hint}>{trans('shippingTips')}</div>
      </div>
    </div>
  )
})

export default ShippingInfoPage