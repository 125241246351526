import React, {PureComponent} from 'react'
import WishListService from '../../services/WishListService'

import { debounce } from 'debounce'
import withUserAndPlugins from '../../hooks/withUserAndPlugins'
import PushNotificationModal from '../../components/Widget/PushNotificationModal/PushNotificationModal'
import WishButton, { TYPE } from '../../components/User/WishButton'
import withLoginCheck from '../../hooks/withLoginCheck'
import withWishAndPlugin from '../../hooks/withWishAndPlugin'

export const WISH_BUTTON_TYPE = TYPE

class WishButtonContainer extends PureComponent {

  wishService = new WishListService(this.props.$http)

  state = {
    isWished: false,
    showPushModal: false
  }

  componentDidUpdate(prevProps) {
    if ((!this.props.isFetchingUser && prevProps.isFetchingUser) || prevProps.handle !== this.props.handle) {
      this.checkButtonStatus()
    }
  }

  checkButtonStatus = () => {
    const hasLogin = this.props.$user.hasLogin()

    if (hasLogin) {
      this.wishService.checkProductWishStatus(this.props.productId).then(({ favoriteStatus }) => {
        this.setState({
          isWished: favoriteStatus
        })
      }).catch(err => null)
    }

  }


  handleClickWish = () => {
    if (this.props.$detector.isDesktop()) {
      this.props.$login().then(()=>{
        this.handleBtnClick()
      }).catch(() => null)
    } else {
      this.handleBtnClick()
    }
  }

  handleBtnClick = debounce(() => {
    const isWishedNow = this.state.isWished
    const handle = this.props.handle
    const productId = this.props.productId

    let selectedOptions = []

    Object.keys(this.props.selectedVariants).forEach(key => {
      selectedOptions.push({
        name: key,
        value: this.props.selectedVariants[key]
      })
    })

    this.props.toggleItem({
      handle,
      productId,
      selectedOptions: selectedOptions
    })
     .then(() => {

      if (isWishedNow) {
        this.props.$track.event('WishButtonContainer', 'remove_wish_product', handle)

        this.setState({
          isWished: !this.state.isWished
        })
      } else {
        this.props.$track.event('WishButtonContainer', 'add_wish_product', handle)

        this.setState({
          isWished: !this.state.isWished,
          showPushModal: true
        })
        this.props.$toastSuccess(this.props.$i18n.transl('core.wishlist.added'))
      }

    }).catch(err => {
      this.handleError()
    })
  },300)

  handleError = () => {
    this.props.$toastError(this.props.$i18n.transl('error.default'))
  }

  handleLogin = () => {
    this.props.$login().then(() => {

      this.wishService.syncLocalWishListToRemote(this.props.handle)

      this.setState({
        isWished: true
      })
      this.props.$toastSuccess(this.props.$i18n.transl('core.wishlist.added'))
    }).catch(() => null)
  }

  renderWishButton() {
    const LoginCheckBtn = withLoginCheck(props => {
      return (
        <WishButton
          type={props.type}
          isWished={props.isWished}
          onWishChanged={props.onWishChanged}
          renderWishIcon={props.renderWishIcon}
          className={props.className}
        />
      )
    }, { loginCallback: this.handleClickWish })

    return (
      <LoginCheckBtn
        key='wishButton'
        type={this.props.wishButtonType}
        isWished={this.state.isWished}
        onWishChanged={this.handleClickWish}
        renderWishIcon={this.props.renderWishIcon}
        className={this.props.className}
      />
    )
  }

  renderPushModal() {
    return (
      <PushNotificationModal
        key='pushNotificationModal'
        scene={PushNotificationModal.SCENE.WISH}
        hide={!this.state.showPushModal}
        onClose={() => {
          this.setState({
            showPushModal: false
          })
        }}
      />
    )
  }

  render(){
    const elements = [
      this.renderWishButton(),
      this.renderPushModal()
    ]
    return elements
  }
}

export default withUserAndPlugins(withWishAndPlugin(WishButtonContainer))