import React from 'react'

import Image from '../../common/Image/Image'

import withQuickAdd from '../../../hooks/withQuickAdd'
import { AddToCartBtn } from '../../common/AddToCartIcon/AddToCartIcon'
import styles from './RecommendSmallCard.module.css'
import cx from '../../../utils/className'
import { getDiscountText } from '../../../utils/priceUtils'

const EVENT_CATEGORY = 'RecommendSmallCard'

const RecommendSmallCard = props => {
  const { mainImage = {}, mainVariation = {} } = props
  const { price, msrp, displayPrice, displayMsrp } = mainVariation
  const hasOriginalPrice = msrp && parseFloat(msrp) > parseFloat(price)

  const imageProps = {
    ...mainImage,
    className: styles.image,
    middle: true,
    lazy: true,
    crop: { width: 2, height: 3 },
    placeholder: { width: 2, height: 3 },
    quality: 60,
    title: mainImage && mainImage.title
      ? mainImage.title
      : props.title
  }

  const handleClickQuickAdd = ev => {
    ev.stopPropagation()
    props.onQuickAdd({
      product: props
    })
    props.onClickQuickAdd(props, props.index)
  }

  const handleClick = () => {
    props.onClick(props, props.index)
  }

  return (
    <div className={styles.mobileCard} >
      <div className={styles.imageWrapper} onClick={handleClick}>
        <Image {...imageProps} />
          <div className={styles.addIcon}  onClick={handleClickQuickAdd}>
            <AddToCartBtn className={styles.icon}/>
          </div>
      </div>
      {
        hasOriginalPrice &&
        <div className={styles.floatDiscount}>{getDiscountText(price, msrp)}</div>
      }
      <div className={styles.priceBox}>
        <span className={cx(styles.salePrice, hasOriginalPrice && styles.highlighted)}>{displayPrice}</span>
        {

          hasOriginalPrice
            ? <span className={styles.originalPrice}>{displayMsrp}</span>
            : null
        }
      </div>

    </div>
  )
}

export default withQuickAdd(RecommendSmallCard, { eventCategory: EVENT_CATEGORY })