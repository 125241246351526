import React from 'react'

import GuidePageFloatWidget from '../../../components/Shell/GuidePageFloatWidget/GuidePageFloatWidget'

const GuidePageFloatWidgetContainer = props => {

  return (
    <GuidePageFloatWidget bottomHeight={props.bottomHeight}>
    </GuidePageFloatWidget>
  )
}

export default GuidePageFloatWidgetContainer
