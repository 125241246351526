import React, { useContext, useState, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'
import StarRating from '../../../components/Store/Product/StarRating'

import withGlobalSettingEnabled from '../../../hooks/withGlobalSettingEnabled'

import styles from './DownloadBanner.module.css'
import cx from '../../../utils/className'

const DEFAULT_PAGE = 'download'
const DOWNLOAD_LINK = 'https://flamingoapp.onelink.me/0qtU/mw49xvkw'
const JourneyWithDeepLinkPages = [
  { name: 'Collection', pageId: 'collection' },
  { name: 'ESCollection', pageId: 'collections' },
  { name: 'Product', pageId: 'product' }
]
const ICON_SRC = 'https://img.flamingo.shop/filters:strip_exif()/fit-in/100x100/p/show/0d81573f55d544ca9ba19561fcd37a32.png'

const DownloadBanner = props => {
  const plugin = useContext(PluginContext)
  const { $track, $router }  = plugin

  const dismissDownloadBannerStorage = plugin.$storage.create('dismiss_download_banner', { expire: 1000 * 60 * 60 * 24 * 7 }) // 7 day
  const dismissDownloadBannerItem = dismissDownloadBannerStorage.getItem(false)

  const downloadTrackViewStorage = plugin.$storage.create('download_track_view', { strategy: 'SESSION' })
  const isTrackView = downloadTrackViewStorage.getItem(false)

  const downloadPage = props.page || DEFAULT_PAGE

  const [show, setShow] = useState(!dismissDownloadBannerItem)

  useEffect(() => {
    if ($track && !isTrackView) {
      $track.event('Download', 'view_download_banner')
      downloadTrackViewStorage.setItem(true)
    }
  })

  // open: global switch
  if (!props.open || !show) {
    return null
  }

  const handleDownload = () => {
    const deepLink = getAppDeepLink(props)
    const oneLink = DOWNLOAD_LINK + `?deep_link_value=${encodeURIComponent(deepLink)}`
    document.location.href = oneLink
    if ($track) {
      $track.event('Download', 'click_download_banner', downloadPage)
    }
  }

  const getAppDeepLink = () => {
    if (props.deepLink) {
      return props.deepLink
    }

    const routes = $router.getRouteByPages && $router.getRouteByPages(JourneyWithDeepLinkPages)
    if (Array.isArray(routes)) {
      const route = routes.filter(route => route.path === $router.match.path)[0]
      const pageId = route && route.pageId
      const handle = $router && $router.match && $router.match.params && $router.match.params.handle
      if (handle && pageId) {
        return `flamingo://flamingo.shop/${pageId}?handle=${handle}&utm_source=Web&utm_campaign=download_banner`
      }
    }
    return 'flamingo://flamingo.shop/shop?utm_source=Web&utm_campaign=download_banner'
  }

  const handleClose = ev => {
    ev.stopPropagation()
    dismissDownloadBannerStorage.setItem(true)
    setShow(false)
    if (props.onClose && typeof props.onClose === 'function') {
      props.onClose()
    }
    if ($track) {
      $track.event('Download', 'close_download_banner', downloadPage)
    }
  }

  const icon = props.icon || ICON_SRC
  const isHideReview = props.isHideReview || false

  return (
    <div id='download-banner' className={cx(styles.wrapper, props.className)} onClick={handleDownload}>
      <div className={styles.content}>
        <div className={styles.close} onClick={handleClose}></div>
        <Image className={styles.icon} src={icon} original></Image>
        <div className={styles.details}>
          <div className={styles.title}>{props.title}</div>
          <div className={styles.description}>{props.description}</div>
          {
            !isHideReview &&
            <div className={styles.reviewLine}>
              <div className={styles.stars}><StarRating star={5} /></div>
              <div className={styles.reviews}>(16,013)</div>
            </div>
          }
        </div>
        <div className={styles.btn}>{props.button}</div>
      </div>
    </div>
  )
}

export default withGlobalSettingEnabled(DownloadBanner, 'downloadBanner')
