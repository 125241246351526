import WishListService from "../../services/WishListService"
import createListener from '@flamingo_tech/funkgo/src/utils/createListener'

export default class Wish {
  constructor({ $http, $user }) {
    this.$http = $http
    this.$user = $user
    this.wishListService = new WishListService($http)
    this.initListener()
  }

  initListener() {
    const { subscribe, unsubscribe, notify } = createListener()

    this.subscribe = subscribe
    this.unsubscribe = unsubscribe
    this.notify = notify
  }

  getModel = () => {
    return this.model
  }

  updateModel = params => {
    this.model = params
    this.notify(this.model)
  }

  fetchWishList = () => {
    const hasLogin = this.$user.hasLogin()

    if (!hasLogin) {
      return Promise.resolve(this)
    }

    const pageSize = 20
    const fromCursor = null

    return this.wishListService.getRemoteWishList(pageSize, fromCursor)
      .then(res => {
        this.updateModel({
          wishTotal: res.total
        })
        return this
      })
  }

  connect() {
    return this.fetchWishList()
  }

  toggleItem({ handle, productId, selectedOptions }) {
    const hasLogin = this.$user.hasLogin()

    if (!hasLogin) {
      return Promise.resolve(this)
    }

    return this.wishListService.toggleWishProductByHandle({ handle, productId, selectedOptions })
      // .then(() => {
      //   return this.fetchWishList()
      // })
  }
}