import React, { useContext, useEffect, useState } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import { isNumber } from '../../../utils/numberUtils'
import styles from './NewProgressBar.module.css'

const NewProgressBar = props => {
  const plugin = useContext(PluginContext)

  const [value, setValue] = useState(0)

  useEffect(() => {
    if (props.needProgressing) {
      setTimeout(() => {
        setValue(props.value)
      }, 0)
    } else {
      setValue(props.value)
    }
  }, [props.needProgressing, props.value, value])


  if (!isNumber(props.value)) {
    plugin.$track.event('ERROR_UNCATCHED_ERROR', `[ProgressBar] ${props.value} is not a number`)
    return null
  }


  const progressBarStyle = {
    width: `${(value > 1 ? 1 : value ) * 100}%`,
  }

  return (
    <div className={cx(styles.wrapper, props.outerBarClassName)}>
      <div className={cx(styles.innerProgressBar, props.needProgressing && styles.progressing, props.innerBarClassName)} style={progressBarStyle}/>
      <div className={cx(styles.children, props.childrenClassName)}>{ props.children }</div>
    </div>
  )
}

export default NewProgressBar
