import React from 'react'
import ReactDOM from 'react-dom'
import ClientOnlyPlugin from '@flamingo_tech/funkgo/src/base/ClientOnlyPlugin'

export default class DialogPlugin extends ClientOnlyPlugin {
  displayName = '$Dialog'

  renderToBody(Component, props) {
    if (!Component) {
      throw new Error('must provide Component to Dialog render')
    }

    if (props.dialogId && document.getElementById(props.dialogId)) {
      return
    }

    let root = document.createElement('div')
    if (props.dialogId) {
      root.id = props.dialogId
    }

    document.body.appendChild(root)

    const handleClose = (result) => {
      if (root) {
        ReactDOM.unmountComponentAtNode(root)
        if (root.parentNode.contains(root)) {
          root.parentNode.removeChild(root)
        }
        root = null

        if (typeof props.onClose === 'function') {
          props.onClose(result)
        }
      }
    }

    const $detector = this.pluginHub && this.pluginHub.getDetector()

    ReactDOM.render(
      <Component {...props} onClose={handleClose} $detector={$detector}/>,
      root
    )

    return handleClose
  }

  /* ------------------------------ */

  renderAlert(Component, props) {
    this.renderToBody(Component, props)
  }

  renderConfirm(Component, props) {
    this.renderToBody(Component, props)
  }

  renderToast(Component, props) {
    const close = this.renderToBody(Component, props)

    if (props.duration !== undefined) {
      if (typeof props.duration !== 'number') {
        throw new Error('toast duration is not a number')
      }
      setTimeout(close, props.duration)
    } else {
      setTimeout(close, 3000)
    }
  }

  /* ------------------------------ */
  parseProps(props, extProps = {}) {
    if (typeof props === 'string') {
      return {
        content: props,
        ...extProps
      }
    }
    return {
      ...props,
      ...extProps
    }
  }

  bridgeToRender(props, Component, render, fallback) {
    return new Promise((resolve, reject) => {
      if (Component) {
        render.call(this, Component, {
          ...props,
          onClose: resolve
        })
      } else if (typeof fallback === 'function') {
        Promise.resolve(fallback(props.content)).then(
          resolve,
          reject
        )
      } else {
        reject(new Error('must provide Component or fallback to render dialog'))
      }
    })
  }

  /* ------------------------------ */

  alert = (props, Component = this.options.Dialog) => {
    return this.bridgeToRender(
      this.parseProps(props, { type: 'alert' }),
      Component,
      this.renderAlert,

      // fallback to native alert
      content => {
        window.alert(content)
        return true
      }
    )
  }

  confirm = (props, Component = this.options.Dialog) => {
    return this.bridgeToRender(
      this.parseProps(props, { type: 'confirm' }),
      Component,
      this.renderConfirm,
      content => {
        const result = window.confirm(content)
        return result
      }
    )
  }

  /* ------------------------------ */

  toast = (props, Component = this.options.Toast) => {
    return this.bridgeToRender(
      this.parseProps(props),
      Component,
      this.renderToast
    )
  }

  toastError = (props) => {
    return this.toast(
      this.parseProps(props, { error: true })
    )
  }

  toastSuccess = (props) => {
    return this.toast(
      this.parseProps(props, { success: true })
    )
  }

  toastWarning = (props) => {
    return this.toast(
      this.parseProps(props, { warning: true })
    )
  }

  /* ------------------------------------------- */

  createLoading = (props, Component = this.options.Loading) => {
    return this.renderToBody(Component, this.parseProps(props))
  }

  /* ------------------------------ */
  injectProps = {
    $alert: this.alert,
    $confirm: this.confirm,
    $toast: this.toast,
    $toastError: this.toastError,
    $toastSuccess: this.toastSuccess,
    $toastWarning: this.toastWarning,
    $createLoading: this.createLoading,
  }
}
