import React from 'react'
import ReactDOM from 'react-dom'
import jwt_decode from 'jwt-decode'

import ClientOnlyPlugin from '@flamingo_tech/funkgo/src/base/ClientOnlyPlugin'

import { UserModel, FBUserModel, GoogleUserModel, AppleUserModel } from './UserPlugin/UserModel'

import LoginService, { FACEBOOK_LOGIN_WAY, GOOGLE_LOGIN_WAY, APPLE_LOGIN_WAY } from './UserPlugin/LoginService'
import UserService from '../services/UserService'

import createListener from '@flamingo_tech/funkgo/src/utils/createListener'
import { makeUrl, getCurrentOrigin } from '@flamingo_tech/funkgo/src/utils/url'
import { debouncePromise } from '@flamingo_tech/funkgo-utils/promiseUtils'

import * as googleLoginSdk from '@flamingo_tech/funkgo/src/sdk/googleLoginSdk'
import * as appleSdk from '@flamingo_tech/funkgo/src/sdk/appleSdk'
import { MyLoadableComponent } from '@flamingo_tech/funkgo/src/MyLoadable'
import { formateTelNumber } from '../utils/Checkout/checkoutUtils'

import {
  LOGIN_CANCELLATION,
  LOGIN_NO_TOKEN,
  LOGIN_USER_DENY,
  LOGIN_USER_DENY_MESSAGE,
  createError,
} from '@flamingo_tech/funkgo/src/utils/errorCode'

const DefaultEnsureEmailContainer = MyLoadableComponent({
  loader: () => import('./UserPlugin/components/subComponents/EnsureEmailContainer')
})
const LoginPanelContainer = MyLoadableComponent({
  loader: () => import('./UserPlugin/components/LoginPanelContainer')
})
const LoginAddEmailPlanelContainer = MyLoadableComponent({
  loader: () => import('./UserPlugin/components/LoginAddEmailPlanelContainer')
})
const LoginAnonymousPanelContainer = MyLoadableComponent({
  loader: () => import('./UserPlugin/components/LoginAnonymousPanelContainer')
})

/* ---------------------------------- */

const USER_AUTH_STORAGE = 'ua'
const USER_TOKEN_RENEWED_STORAGE = 'utr'
const RENEW_DURATION = 1000 * 60 * 60 * 24 // renew per day
const USER_INFO_FORCE_UPDATED_STORAGE = 'uifu'
const ANONYMOUS_LOGIN_STORAGE = 'an_lg_email'
const ANONYMOUS_ADDRESS_STORAGE = 'an_address'
const USER_ADDRESS_STORAGE = 'user_address'

export default class UserPlugin extends ClientOnlyPlugin {
  displayName = '$Login'
  googleLoginSdk = googleLoginSdk
  appleSdk = appleSdk
  $tracker = this.pluginHub.getPlugin('tracker')

  start() {
    const $storage = this.pluginHub.getStorage()
    const $http = this.pluginHub.getHttpClient()

    this.runtimeUser = null
    this.authStorage = $storage.create(USER_AUTH_STORAGE)
    this.anonymousLoginStorage = $storage.create(ANONYMOUS_LOGIN_STORAGE)
    this.anonymousAddressStorage = $storage.create(ANONYMOUS_ADDRESS_STORAGE)
    this.userAddressStorage = $storage.create(USER_ADDRESS_STORAGE, { strategy: 'SESSION' })
    this.loginService = new LoginService($http)
    this.userService = new UserService($http)
    this.listener = createListener()

  }

  /* ---------------------------------- */

  $track(action, label) {
    if (this.$tracker) {
      this.$tracker.event('Login', action, label)
    }
  }

  /* ---------------------------------- */

  saveRuntimeUser(user) {
    this.runtimeUser = user
  }

  getRuntimeUser() {
    return this.runtimeUser
  }

  clearRuntimeUser() {
    this.runtimeUser = null
  }

  /* ---------------------------------- */

  saveToken(user) {
    this.authStorage.setItem({
      fi: user.fbUserId,
      fk: user.fbToken,
      ai: user.id,
      ak: user.accessToken,
      rk: user.refreshToken,
      el: user.email,
    })
  }

  getToken() {
    const storage = this.authStorage.getItem()
    if (!storage) {
      return null
    }

    return {
      id: storage.ai,
      accessToken: storage.ak,
      fbUserId: storage.fi,
      fbToken: storage.fk,
      refreshToken: storage.rk,
      email: storage.el,
    }
  }

  clearToken() {
    this.authStorage.clearItem()

    // clear accessToken but remain email
    const { email } = this.anonymousLoginStorage.getItem({})

    this.anonymousLoginStorage.clearItem()

    this.anonymousLoginStorage.setItem({
      email
    })
  }

  refreshToken() {
    this.authStorage.refreshItem()
    return this.getToken()
  }
  /* ---------------------------------- */

  getAccessToken = () => {
    const token = this.getToken()
    return token ? token.accessToken : ((this.anonymousLoginStorage.getItem({}) || {}).accessToken || null)
  }

  /* ---------------------------------- */
  clearUser = () => {
    this.clearToken()
    this.clearRuntimeUser()
  }

  saveUser(user) {
    this.saveToken(user)
    this.saveRuntimeUser(user)
    return user
  }

  /* ---------------------------------- */

  ensureBridge = () => {
    const $bridge = this.pluginHub.getPlugin('bridge')

    if (!$bridge) {
      return Promise.reject(new Error('[UserPlugin] callAppSdk cannot get bridge plugin'))
    }

    return $bridge
  }

  callFbSdk = (methodName, ...args) => {
    const $bridge = this.ensureBridge()
    return $bridge.callFbSdk(methodName, ...args)
  }

  /* ---------------------------------- */
  dispatchAction({ bridge, service, payload, fallback }) {
    const $bridge = this.ensureBridge()

    if ($bridge.isAppBridgesEnabled() && bridge) {
      return $bridge.callAppSdk(bridge, payload)
    }

    if (service) {
      if (typeof this.loginService[service] === 'function') {
        return this.loginService[service](payload)
      }
      return Promise.reject(`${service} is not a valid service`)
    }

    if (fallback) {
      return fallback()
    }

    return Promise.resolve()
  }

  /* ---------------------------------- */

  renderEnsureEmailPanel(user, EnsureEmailContainer = DefaultEnsureEmailContainer) {
    return new Promise((resolve, reject) => {
      let root = document.createElement('div')
      document.body.appendChild(root)

      const unmountComponent = () => {
        if (root) {
          ReactDOM.unmountComponentAtNode(root)
          root.parentNode.removeChild(root)
          root = null
        }
      }

      const handleResolveEmail = ({ email, verifiedEmail }) => {
        unmountComponent()

        resolve({
          user: {
            ...user,
            email
          },
          verifiedEmail
        })
      }

      const handleRejectEmail = () => {
        unmountComponent()

        reject(new Error('user denied to provide email'))
      }

      ReactDOM.render((
        <EnsureEmailContainer
          onResolveEmail={handleResolveEmail}
          onRejectEmail={handleRejectEmail}
        ></EnsureEmailContainer>
      ), root)
    })
  }

  /* internal fb bridges */
  /* ---------------------------------- */
  getFbUserInfoByFbToken = fbToken => {
    return this.callFbSdk('api', '/me', {
      fields: 'id,email,name,picture,first_name,last_name',
      access_token: fbToken
    }).then(
      user => new FBUserModel(user, fbToken)
    )
  }

  getFbUserInfoByOauthLogin = () => {
    const appInfo = this.pluginHub.getAppInfo()
    const fbAppId = appInfo.fbAppId

    if (!fbAppId) {
      return Promise.reject(`invalid fbAppId: ${fbAppId}`)
    }

    // instagram use oauth login because instagram app will redirect page infinitely
    const baseUrl = 'https://www.facebook.com/v3.3/dialog/oauth'
    const currentOrigin = getCurrentOrigin() || `https://${appInfo.domain}`

    const search = {
      client_id: fbAppId,
      redirect_uri: `${currentOrigin}/store/fb_redirect`,
      state: `{"url":"${window.location.href}","ts":${Date.now()}}`,
      response_type: 'token',
      scope: 'email'
    }

    window.location.href = makeUrl(baseUrl, search)

    return new Promise(() => {})
  }

  getFbUserInfoBySdkLogin = () => {
    return this.callFbSdk('login', { scope: 'public_profile,email' })
      .then(({ status, authResponse }) => {
        if (status === 'connected') {
          return this.getFbUserInfoByFbToken(authResponse.accessToken)
        }
        if (status === 'unknown') {
          throw createError(LOGIN_CANCELLATION)
        }

        throw new Error(status)
      })
  }

  /* ---------------------------------- */
  handleFbLoginWithNewUser = fbUser => {
    if (fbUser.email) {
      return this.loginService.registerByFb(fbUser, true)
    }
    this.$track('unauthorized_fb_email')
    return this.renderLoginAddEmailPlanel({ user: fbUser, loginWay: FACEBOOK_LOGIN_WAY })
  }

  handleFbLoginWithExistsUser = fbUser => {
    if (fbUser.email) {
      return this.loginService.loginByFb(fbUser)
    }
    this.$track('unauthorized_fb_email')
    return this.renderLoginAddEmailPlanel({ user: fbUser, loginWay: FACEBOOK_LOGIN_WAY })
  }

  handleFbLoginSucceed = fbUser => {
    return this.loginService.getUserInfoByFbUser(fbUser).then(({ userInfo }) => {
      if (userInfo) {
        return this.handleFbLoginWithExistsUser({ ...fbUser, email: fbUser.email || userInfo.email })
      }
      return this.handleFbLoginWithNewUser(fbUser)
    })
  }


  /* google login */
  /* ---------------------------------- */

  getGoogleUserInfoByOauthLogin = () => {

    const appInfo = this.pluginHub.getAppInfo()
    const googleClientId = appInfo.googleClientId

    if (!googleClientId) {
      return Promise.reject(new Error(`invalid googleClientId: ${googleClientId}`))
    }

    return new Promise((resolve, reject) => {
      return this.googleLoginSdk.init().then(() => {
        window.google.accounts.id.initialize({
          client_id: googleClientId,
          cancel_on_tap_outside: false,
          callback: (credentialResponse) => {
            this.getGoogleUserInfoByGoogleToken(credentialResponse.credential).then(res => {
              resolve(res)
            })
          },
        });
        window.google.accounts.id.prompt((notification) => {
          if (notification.isSkippedMoment()) {
            const $i18n = this.pluginHub.getPlugin('i18n')
            reject(new Error($i18n.transl('core.login.googleLoginCancelled')))
            setTimeout(() => {
              document.cookie = `g_state=; expires=${new Date().toUTCString()}; path=/; secure`
            }, 500)
          }
        });
      })
    })

  }

  getGoogleUserInfoByGoogleToken = googleToken => {

    const decoded = jwt_decode(googleToken)

    const basicProfile = {
      userId: decoded.sub,
      email: decoded.email,
      name: decoded.name,
      givenName: decoded.given_name,
      familyName: decoded.family_name,
      picture: decoded.picture,
    }

    const authResponse = {
      id_token: googleToken
    }

    return Promise.resolve({ basicProfile, authResponse })
  }

  handleGoogleLoginSucceed = ({ basicProfile, authResponse }) => {

    const googleUser = new GoogleUserModel(basicProfile, authResponse)

    return this.loginService.getUserInfoByGoogleUser(googleUser).then(res => {
      if (res && res.userInfo) {
        return this.handleGoogleLoginWithExistsUser({ ...googleUser, email: googleUser.email || res.userInfo.email })
      }
      return this.handleGoogleLoginWithNewUser(googleUser)
    })

  }

  handleGoogleLoginWithExistsUser = googleUser => {
    if (googleUser.email) {
      return this.loginService.loginByGoogle(googleUser)
    }
    this.$track('unauthorized_google_email')
    return this.renderLoginAddEmailPlanel({ user: googleUser, loginWay: GOOGLE_LOGIN_WAY })
  }

  handleGoogleLoginWithNewUser = googleUser => {
    if (googleUser.email) {
      return this.loginService.registerByGoogle(googleUser)
    }

    this.$track('unauthorized_google_email')
    return this.renderLoginAddEmailPlanel({ user: googleUser, loginWay: GOOGLE_LOGIN_WAY })
  }

  /* apple login */
  /* ---------------------------------- */

  getAppleUserInfoByOauthLogin = () => {

    const appInfo = this.pluginHub.getAppInfo()
    const appleClientId = appInfo.appleClientId
    if (!appleClientId) {
      return Promise.reject(new Error(`invalid appleClientId: ${appleClientId}`))
    }

    const currentOrigin = getCurrentOrigin() || `https://${appInfo.domain}`

    const params = {
      clientId : appleClientId,
      scope : 'name email',
      redirectURI : `${currentOrigin}/store/apple_redirect`,
      state : `{"url":"${window.location.href}","ts":${Date.now()}}`,
      usePopup : true //or false defaults to false
    }

    return new Promise((resolve, reject) => {
      return this.appleSdk.init().then(() => {
        window.AppleID.auth.init(params)
        return window.AppleID.auth.signIn().then(
          res => resolve(res),
          fail => reject(new Error(fail.error))
        )
      })
    })
  }

  handleAppleLoginSucceed = (res) => {

    const idToken = res.authorization.id_token
    const user = res.user
    const decoded = jwt_decode(idToken)

    const appleUser = new AppleUserModel(user, decoded, idToken)

    return this.loginService.getUserInfoByAppleUser(appleUser).then(res => {
      if (res && res.userInfo) {
        return this.handleAppleLoginWithAppleUser({ ...appleUser, email: appleUser.email || res.userInfo.email })
      }
      return this.handleAppleLoginWithNewUser(appleUser)
    })
  }

  handleAppleLoginWithAppleUser = appleUser => {
    if (appleUser.email) {
      return this.loginService.loginByApple(appleUser)
    }
    this.$track('unauthorized_apple_email')
    return this.renderLoginAddEmailPlanel({ user: appleUser, loginWay: APPLE_LOGIN_WAY })
  }

  handleAppleLoginWithNewUser = appleUser => {
    if (appleUser.email) {
      return this.loginService.registerByApple(appleUser)
    }
    this.$track('unauthorized_apple_email')
    return this.renderLoginAddEmailPlanel({ user: appleUser, loginWay: APPLE_LOGIN_WAY })
  }

  /* ---------------------------------- */

  saveUserInfoFromData = data => {
    const user = new UserModel(data)
    this.saveUser(user)
    return user
  }

  notifyUserChangeIfNotEqual = existsUser => {
    const currentUser = this.getRuntimeUser() // could be null
    if (!existsUser) {
      if (currentUser) {
        this.notifyUserChange(currentUser)
      }
    } else {
      if (!currentUser) {
        this.notifyUserChange(null)
      } else if (currentUser.id !== existsUser.id) {
        this.notifyUserChange(currentUser)
      }
    }
  }

  /* ---------------------------------- */
  handleGetUserInfoSucceed = data => {
    return this.saveUserInfoFromData(data)
  }

  handleLoginSucceed = data => {
    const existsUser = this.getRuntimeUser()
    const currentUser = this.saveUserInfoFromData(data)

    // workaround to fix a bug:
    //  if call login() at web side, and login will render a loginContainer
    //  , when user do login at loginContainer, the container may call other bridge such as loginByFb()
    //  , and resolve loginByFb() and original login() at same time
    //  , both bridge will trigger handleLoginSucceed() at same time
    //  , it will cause the notifyUserChange call twice with same user

    this.notifyUserChangeIfNotEqual(existsUser)

    // sync coupon after login
    const $store = this.pluginHub.getPlugin('store')
    $store && $store.syncAssets()
    $store && $store.syncUserDeviceKey()

    return currentUser
  }

  handleLoginFailed = err => {
    if (err.message && err.message.indexOf('isNotLogin') > -1) {
      throw createError(
        LOGIN_USER_DENY,
        LOGIN_USER_DENY_MESSAGE
      )
    }
    throw err
  }

  clearData = () => {
    const $storage = this.pluginHub.getStorage()
    const unpaidInfoStorage = $storage.create('unpaid_info', { strategy: 'SESSION' })
    unpaidInfoStorage.setItem({
      ...unpaidInfoStorage.getItem({}),
      unpaid_buoy_closed: true
    })
  }

  handleLogoutSucceed = () => {
    const existsUser = this.getRuntimeUser()
    this.clearUser()
    this.clearData()
    this.notifyUserChangeIfNotEqual(existsUser)
  }

  /* ---------------------------------- */
  renewAccessToken = user => {
    if (!user || !user.refreshToken) {
      return Promise.resolve()
    }

    const $detector = this.pluginHub.getDetector()

    // app no need to renew access token
    if ($detector.isApp()) {
      return Promise.resolve()
    }

    const isRenewedStorage = this.pluginHub.getStorage().create(
      USER_TOKEN_RENEWED_STORAGE,
      { expire: RENEW_DURATION }
    )

    if (isRenewedStorage.getItem()) {
      return Promise.resolve()
    }

    return this.loginService.refreshAccessToken(user.refreshToken).then(payload => {
      isRenewedStorage.setItem(true)
      return payload
    })
  }
  /* ---------------------------------- */

  getUserInfoForce = debouncePromise(() => {
    return this.dispatchAction({
      bridge: 'getUserInfo',
      fallback: () => {
        const token = this.getToken()

        if (!token || !token.accessToken) {
          return Promise.reject(createError(LOGIN_NO_TOKEN))
        }

        return this.loginService.getUserInfo(token)
      }
    }).then(
      data => this.handleGetUserInfoSucceed(data)
    ).then(
      () => {
        const user = this.getRuntimeUser()
        this.renewAccessToken(user).catch(() => undefined)
        return user
      }
    ).catch(
      err => {
        if (Boolean(this.getToken()) && (err.code === 401 || err.errorCode === 401)) {
          this.logout().catch(() => undefined)
        }
        throw err
      }
    )
  })

  getUserInfo = ({ force = false } = {}) => {
    if (this.getRuntimeUser() && !force) {
      return Promise.resolve(this.getRuntimeUser())
    }

    return this.getUserInfoForce()
  }

  hasLogin = () => {
    const token = this.getToken()
    const accessToken = token ? token.accessToken : null
    return !!accessToken
  }

  refresh = () => {
    const existsToken = this.getToken()
    const existsUser = this.getRuntimeUser()
    const currentToken = this.refreshToken()

    /* ----------------------- */
    const forceRefreshUser = () => this.getUserInfo({ force: true }).then(user => {
      this.notifyUserChange(user)
    })

    const refreshUser = () => this.getUserInfo().then(() => {
      this.notifyUserChangeIfNotEqual(existsUser)
    })
    const logoutUser = () => this.logout()

    const doNothing = () => Promise.resolve()

    /* ----------------------- */

    if (!existsUser && currentToken) {
      return refreshUser()
    // before: not login, current: not login -> do nothing
    } else if (!existsToken && !currentToken) {
      return doNothing()
    // before login, current not login -> logout
    } else if (existsToken && !currentToken) {
      return logoutUser()
    // before not login, current login
    } else if (!existsToken && currentToken) {
      return refreshUser()
    } else {
      if (existsToken.id !== currentToken.id) {
        return forceRefreshUser()
      } else {
        const $storage = this.pluginHub.getStorage()
        const isForceUpdatedStorage = $storage.create(USER_INFO_FORCE_UPDATED_STORAGE)
        const isForceUpdated = isForceUpdatedStorage.getItem()

        if (isForceUpdated) {
          return forceRefreshUser().then(() => {
            isForceUpdatedStorage.clearItem()
          })
        }
      }
    }

    return doNothing()
  }

  forceRefreshUser = () => this.getUserInfo({ force: true }).then(user => {
    this.notifyUserChange(user)
  })

  /* ---------------------------------- */

  // if user info has been updated, e.g. ProfileEditContainer
  //  , which need to call this bridge to refresh user info at bridge
  //  , otherwise bridge will always return the old user dict which cached at app local
  forceRefresh = () => this.getUserInfo({ force: true }).then((
    () => {
      // once updated, mark as local storage, so that other webview will refresh it self
      const $storage = this.pluginHub.getStorage()
      $storage.create(USER_INFO_FORCE_UPDATED_STORAGE).setItem(true)
    }
  ))

  // check token is still available, since in case the token will be expired at server side
  //  , and app will always return their local own dict
  //  , so check it, if token is expired, then call logout manually to notify app to refresh data
  ensureUserToken = () => {
    const token = this.getToken()
    if (!token) {
      return Promise.resolve()
    }

    return this.loginService.getUserInfo(token).catch(
      err => {
        if (err.errorCode === 401) {
          this.logout()
        }
      }
    )
  }

  /* ---------------------------------- */

  logout = () => {
    return this.dispatchAction({
      bridge: null,
      fallback: () => {
        const userToken = this.getToken()

        if (!userToken) {
          return Promise.resolve()
        }

        const $store = this.pluginHub.getPlugin('store')

        return this.loginService.logout({
          ...userToken,
          shoppingCartId: $store.getCurrentCartId()
        })
        .then(() => {
          $store.execCart('refresh')
        })
      }
    }).then(
      this.handleLogoutSucceed
    )
  }
  /* ---------------------------------- */
  withLogin = fn => debouncePromise(payload => {
    return fn(payload).then(
      this.handleLoginSucceed,
      this.handleLoginFailed
    )
  })

  /* public methods for login*/
  /* ---------------------------------- */

  registerByEmail = this.withLogin((payload = {}) => {
    return this.dispatchAction({
      bridge: null,
      service: 'registerByEmail',
      payload
    })
  })

  registerByEmail1 = this.withLogin((payload = {}) => {
    return this.dispatchAction({
      bridge: null,
      service: 'registerByEmail1',
      payload
    })
  })

  registerByOrder = this.withLogin((payload = {}) => {
    return this.dispatchAction({
      bridge: null,
      service: 'registerByOrder',
      payload
    })
  })

  loginByEmail = this.withLogin((payload = {}) => {
    payload.account = payload.email
    return this.dispatchAction({
      bridge: null,
      service: 'loginByEmail',
      payload
    })
  })

  loginByFbOAuth = this.withLogin(({ fbToken } = {}) => {
    return this.getFbUserInfoByFbToken(fbToken).then(
      this.handleFbLoginSucceed
    )
  })

  loginByFb = this.withLogin(() => {
    return this.dispatchAction({
      bridge: null,
      fallback: () => {
        // since there are some issues will caused fb sdk login failed , especially most of app webviews or pwa
        // so use oAuth login unified
        const oAuthEnabled = true

        const login = oAuthEnabled
          ? this.getFbUserInfoByOauthLogin()
          : this.getFbUserInfoBySdkLogin()

        return login.then(
          this.handleFbLoginSucceed
        )
      }
    })
  })

  loginByGoogleToken = this.withLogin(({ googleToken } = {}) => {
    return this.getGoogleUserInfoByGoogleToken(googleToken).then(res => this.handleGoogleLoginSucceed(res))
  })

  loginByGoogle = this.withLogin(() => {
    return this.dispatchAction({
      bridge: null,
      fallback: () => {
        return this.getGoogleUserInfoByOauthLogin().then(res => this.handleGoogleLoginSucceed(res))
      }
    })
  })

  loginByApple = this.withLogin(() => {

    const $dialog = this.pluginHub.getPlugin('dialog')
    return $dialog.alert({
      title: 'Share your email',
      content: `Please choose "Share My Email", because we need to contact you by email.`
    }).then(() => {
      return this.dispatchAction({
        bridge: null,
        fallback: () => {
          return this.getAppleUserInfoByOauthLogin().then(res => this.handleAppleLoginSucceed(res))
        }
      })
    })

  })

  saveAnonymousLoginEmail = email => {
    const storageItem = this.anonymousLoginStorage.getItem({})

    this.anonymousLoginStorage.setItem({
      ...storageItem,
      email
    })
  }

  getAnonymousLoginInfo = () => {
    return this.anonymousLoginStorage.getItem({})
  }

  saveAnonymousLoginInfo = (email, activeFlag, accessToken) => {
    this.anonymousLoginStorage.setItem({
      email,
      activeFlag,
      accessToken
    })
  }

  saveAnonymousLoginToken = (accessToken) => {
    const storageItem = this.anonymousLoginStorage.getItem({})

    this.anonymousLoginStorage.setItem({
      ...storageItem,
      accessToken
    })
  }


  hasAnonymousLoginEmail = () => {
    return (this.anonymousLoginStorage.getItem({}) || {}).email !== undefined
  }

  getAnonymousLoginEmail = () => {
    return (this.anonymousLoginStorage.getItem({}) || {}).email
  }

  fetchAddressInfo = () => {
    if (this.userAddressStorage.getItem()) {
      return Promise.resolve(this.userAddressStorage.getItem())
    }

    return this.userService.getUserAddresses((this.getToken() || {}).accessToken).then((address) => {
      const defaultAddress = address.userAddresses.filter(i => i.hasDefault)[0]

      if (defaultAddress) {
        return defaultAddress
      }

      this.userAddressStorage.setItem(address.userAddresses[0])
      return address.userAddresses[0]
    })
  }

  getUserEmail = () => {
    if (this.hasLogin()) {
      return (this.getToken() || {}).email
    } else {
      return this.getAnonymousLoginEmail()
    }
  }

  getUserAddress = () => {
    if (this.hasLogin()) {
      return this.fetchAddressInfo()
    } else {
      return Promise.resolve(this.anonymousAddressStorage.getItem() || {})
    }
  }

  getUserPhoneNumber = () => {
    return this.getUserAddress()
      .then(address => {
        return formateTelNumber({
          countryCode: address.countryCode,
          telNumber: address.telNumber
        })
      })
      .catch(() => "")
  }

  login = () => {
    return this.getUserInfo().catch(err => {
      return this.renderLoginPanel().then((data) => {
        if (data) {
          this.saveAnonymousLoginInfo(data.email, false, data.accessToken)
          this.$tracker && this.$tracker.trackEmail(data.email, 'login')
          return data
        } else {
          return Promise.reject(new Error(`login no data`))
        }
      })
    })
  }

  appLogin = this.withLogin((options = {}) => {
    return this.getUserInfo().catch(() => (
      this.dispatchAction({
        bridge: 'login',
        fallback: this.renderLoginPanel
      }).then((data) => {
        if (data) {
          this.saveAnonymousLoginInfo(data.email, false, data.accessToken)
          return data
        } else {
          return Promise.reject(new Error(`login no data`))
        }
      })
    ))
  })

  loginAnonymous = (options = {}) => {
    const storageItem = this.anonymousLoginStorage.getItem({})
    const needInputEmail = !storageItem.email || !options.skipInputEmail
    const req = needInputEmail
    ? this.renderLoginPanel(this.getLoginAnonymousPanel())
    : this.loginService.loginByAnonymous({
      email: options.email || storageItem.email,
      firstName: options.firstName,
      lastName: options.lastName
    })
    return req.then(data => {
      this.saveAnonymousLoginInfo(data.email, data.activeFlag, data.accessToken)
      this.$tracker && this.$tracker.trackEmail(data.email, 'checkout')
      // sync coupon after login
      const $store = this.pluginHub.getPlugin('store')
      $store && $store.syncAssets({
        syncCartFlag: false
      })
      $store && $store.syncUserDeviceKey()
      return data
    })
  }

  /* ---------------------------------- */
  getLoginPanel = args => {
    const storageItem = this.anonymousLoginStorage.getItem({})
    return props => (
      <LoginPanelContainer
        {...props}
        {...args}
        anonymousEmail={storageItem.email}
        anonymousActiveFlag={storageItem.activeFlag}
        pluginHub={this.pluginHub}
      ></LoginPanelContainer>
    )
  }

  getLoginAddEmailPlanel = args => {
    const storageItem = this.anonymousLoginStorage.getItem({})
    return props => (
      <LoginAddEmailPlanelContainer
        {...props}
        {...args}
        anonymousEmail={storageItem.email}
        anonymousActiveFlag={storageItem.activeFlag}
        pluginHub={this.pluginHub}
      ></LoginAddEmailPlanelContainer>
    )
  }

  getLoginAnonymousPanel = () => {
    const storageItem = this.anonymousLoginStorage.getItem({})
    return props => (
      <LoginAnonymousPanelContainer
        {...props}
        anonymousEmail={storageItem.email}
        pluginHub={this.pluginHub}
      >
      </LoginAnonymousPanelContainer>
    )
  }

  renderLoginPanel = (LoginPanel = this.getLoginPanel()) => {
    return new Promise((resolve, reject) => {
      let root = document.createElement('div')
      document.body.appendChild(root)

      const unmountComponent = () => {
        if (root) {
          ReactDOM.unmountComponentAtNode(root)
          root.parentNode.removeChild(root)
          root = null
        }
      }

      const handleResolveLogin = (user) => {
        unmountComponent()
        resolve(user)
      }

      const handleRejectLogin = err => {
        unmountComponent()
        const finalError = err
          ? err
          : createError(LOGIN_USER_DENY, LOGIN_USER_DENY_MESSAGE)
        reject(finalError)
      }

      ReactDOM.render((
        <LoginPanel
          onResolveLogin={handleResolveLogin}
          onRejectLogin={handleRejectLogin}
        >
        </LoginPanel>
      ), root)
    })
  }

  renderLoginAddEmailPlanel = ({ user: originUser, loginWay, }) => {
    const LoginAddEmailPlanel = this.getLoginAddEmailPlanel()

    return new Promise((resolve, reject) => {
      let root = document.createElement('div')
      document.body.appendChild(root)

      const unmountComponent = () => {
        if (root) {
          ReactDOM.unmountComponentAtNode(root)
          root.parentNode.removeChild(root)
          root = null
        }
      }

      const handleResolveAddEmail = (user) => {
        unmountComponent()
        resolve(user)
      }

      const handleRejectAddEmail = err => {
        unmountComponent()
        reject(err || null)
      }

      ReactDOM.render((
        <LoginAddEmailPlanel
          onResolveAddEmail={handleResolveAddEmail}
          onRejectAddEmail={handleRejectAddEmail}
          loginWay={loginWay}
          originUser={originUser}
        >
        </LoginAddEmailPlanel>
      ), root)
    })
  }

  /* ---------------------------------- */
  observeUser = fn => this.listener.subscribe(fn)
  unobserveUser = fn => this.listener.unsubscribe(fn)
  notifyUserChange = user => this.listener.notify(user)

  /* ---------------------------------- */
  injectProps = {
    $user: {
      login: this.login,
      appLogin: this.appLogin,
      loginAnonymous: this.loginAnonymous,
      loginByFbOAuth: this.loginByFbOAuth,
      loginByGoogleToken: this.loginByGoogleToken,
      getUserInfo: this.getUserInfo,
      hasLogin: this.hasLogin,
      logout: this.logout,
      refresh: this.refresh,
      getLoginPanel: this.getLoginPanel,
      observeUser: this.observeUser,
      unobserveUser: this.unobserveUser,
      saveAnonymousLoginEmail: this.saveAnonymousLoginEmail,
      hasAnonymousLoginEmail: this.hasAnonymousLoginEmail,
      getAnonymousLoginEmail: this.getAnonymousLoginEmail,
      getUserEmail: this.getUserEmail,
      getUserPhoneNumber: this.getUserPhoneNumber,

      // app will cache user info, force refresh it
      forceRefresh: this.forceRefresh,
      ensureUserToken: this.ensureUserToken,
      getAccessToken: this.getAccessToken,
      forceRefreshUser: this.forceRefreshUser,
      saveAnonymousLoginToken: this.saveAnonymousLoginToken,
      getToken: this.getToken,
      getAnonymousLoginInfo: this.getAnonymousLoginInfo,
      clearUser: this.clearUser
    }
  }
}
