import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import StarRating from './StarRating'
import TranslateSection from './ProductCommentTranslate'
import cx from '../../../utils/className'
import { formatTime } from '../../../utils/dateUtils'
import styles from './ProductCommentCard.module.css'


const DISPLAY_MODE = {
  FULL: styles.full,
  THUMBNAIL: styles.thumbnail
}

const PCProductCommentCard = withPlugin((props) => {
  let { user, star, content, images, sku, circumferences, overallFit, publishTime, showAvaterName, onClickCommentImage } = props

  const userName = user && user.name
    let avaterName = userName ? userName[0] + (userName[userName.length - 1] || '') : ''
    avaterName = avaterName.toUpperCase()

  return (
    <div className={styles.pcWrapper}>
      <div className={styles.leftWrapper}>
        <div className={styles.avatarWrapper}>
          {
            showAvaterName && !user.image && avaterName ?
            <div className={cx(styles.avatar, styles.avaterName)}>{avaterName}</div>
            :
            <Image title="avatar" objectFit="cover" src={user.image} className={styles.avatar} small/>
          }
        </div>
        <div className={styles.userInfo}>
          <div className={styles.nickname}>{user.name}</div>
          {
            circumferences.length > 0 &&
            <div className={styles.circumference}>
              {
                circumferences.map((item, index)=> (
                  <div className={styles.circumferenceItem} key={index}>
                    <span className={styles.name}>{item.name}: </span>
                    <span className={styles.value}>{item.value}</span>
                    {
                      index !== circumferences.length - 1 &&
                      <span>, </span>
                    }
                  </div>
                ))
              }
            </div>
          }
        </div>
      </div>
      <div className={styles.rightWrapper}>
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.reviewRating}>
              <StarRating star={star}/>
            </div>
            <div className={styles.desc}>{formatTime(publishTime)}</div>
          </div>
          {
            (overallFit || sku) &&
            <div className={styles.overallFitAndSku}>
              {
                overallFit &&
                <div className={styles.overallFit}>
                  <span className={styles.name}>{overallFit.name}: </span><span className={styles.value}>{overallFit.value}</span>
                </div>
              }
              {
                sku && <div className={styles.sku}>{sku}</div>
              }
            </div>
          }
          <div className={styles.text}>{content}</div>
          <TranslateSection {...props} />
        </div>
        <div className={styles.images}>
          <div className={styles.imageSection}>
            {
              images.length > 0 &&
              <Image className={styles.image}
                objectFit='cover'
                small
                crop={{ width: 1, height: 1 }}
                placeholder={{ width: 1, height: 1 }}
                src={images[0]}
                onClick={() => onClickCommentImage(images[0])}
              />
            }
            {
              images.length > 1 && <div className={styles.moreImages}>+{images.length - 1}</div>
            }
          </div>
        </div>
      </div>
    </div>
  )
})

class ProductCommentCardElement extends PureComponent {
  constructor(props) {
     super(props)
     this.displayMode = props.displayMode || DISPLAY_MODE.FULL
  }

  state = {
    hideCircumference: true
  }

  handleFoldIconClick = () => {
    this.setState({
      hideCircumference: false
    })
  }

  render() {
    const userName = this.props.user && this.props.user.name
    let avaterName = userName ? userName[0] + (userName[userName.length - 1] || '') : ''
    avaterName = avaterName.toUpperCase()

    const circumferences = this.props.overallFit ? [this.props.overallFit, ...this.props.circumferences] : this.props.circumferences

    const hideCircumference = this.state.hideCircumference && circumferences.length > 2

    return (
      <div className={cx(styles.wrapper, this.props.className, this.displayMode)}>
        <div className={styles.titleWrapper}>
          { /* user image may be null, Image component will display default image if no specific src */ }
          {
            this.props.showAvaterName && !this.props.user.image && avaterName ?
            <div className={cx(styles.avatar, styles.avaterName)}>{avaterName}</div>
            :
            <Image title="avatar" src={this.props.user.image} className={styles.avatar} small/>
          }
          <div className={styles.rightWrapper}>
              <div className={styles.left}>
                <div className={cx(styles.nickname, this.props.nicknameClass)}>{this.props.user.name}</div>
                {
                  this.props.sku && <div className={styles.sku}>{this.props.sku}</div>
                }
              </div>
              <div className={styles.right}>
                {
                  !this.props.hideStarRating &&
                  <div className={styles.reviewRating}>
                    <StarRating star={this.props.star}/>
                  </div>
                }
                <div className={styles.desc}>{formatTime(this.props.publishTime)}</div>
              </div>
          </div>
        </div>
        <div className={cx(styles.contentWrapper, this.props.contentWrapperClass)}>
          <div className={cx(styles.content, this.props.contentClass)}>{this.props.content}</div>
        </div>
        <TranslateSection {...this.props} />
        {
          this.props.images.length > 0 &&
            <div className={styles.images}>
            {
              this.props.images.map(image => (
                <Image className={styles.image}
                  objectFit='cover'
                  small
                  crop={{ width: 1, height: 1 }}
                  placeholder={{ width: 1, height: 1 }}
                  src={image}
                  key={image}
                  onClick={() => this.props.onClickCommentImage(image)}
                />
              ))
            }
          </div>
        }

        {
          circumferences.length > 0 &&
          <div className={cx(styles.circumference, !hideCircumference && styles.expanded)}>
            {
              circumferences.map((item, index)=> (
                <div className={styles.circumferenceItem} key={index}>
                  {
                    index % 2 === 1 &&
                    <span>, </span>
                  }
                  <span className={styles.name}>{item.name}: </span>
                  <span className={styles.value}>{item.value}</span>
                  {
                    hideCircumference && index === 1 && <div className={styles.foldIcon} onClick={this.handleFoldIconClick}/>
                  }
                </div>
              ))
            }
          </div>
        }
      </div>
    )
  }
}

const ProductCommentCard = withPlugin(ProductCommentCardElement)

ProductCommentCard.DISPLAY_MODE = DISPLAY_MODE

export default ProductCommentCard

export { PCProductCommentCard }
