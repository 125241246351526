import React, { useState, useEffect, Fragment, useRef } from 'react'

import ShadowCard from '../ShadowCard/ShadowCard'
import { withPlugin } from '@flamingo_tech/funkgo'
import { debounce } from 'debounce'

import cx from '../../../utils/className'
import styles from './Select.module.css'

const OptionList = withPlugin((props) => {
  const isDesktop = props.$detector.isDesktop()

  const handleClickOption = (e, v) => {
    e.stopPropagation()
    props.onChange(v)
  }

  return (
    <ul>
      {props.optionData.map((v) => (
        <li
          className={cx(
            styles.itemWrapper,
            isDesktop && styles.isDesktop,
            props.itemWrapperClass
          )}
          key={v.code}
          onClick={(e) => handleClickOption(e, v)}
        >
          <div
            className={cx(
              styles.itemName,
              v.selected && styles.selectedItemName
            )}
          >
            {v.name}
          </div>
          {v.selected && (
            <i
              className={cx(styles.selectedIcon, isDesktop && styles.isDesktop)}
            >
              &#xe7fc;
            </i>
          )}
        </li>
      ))}
    </ul>
  )
})

const SELECT_TYPE = {
  SINGLE: 'SINGLE', // 单选
  MULTIPLE: 'MULTIPLE', // 多选
  DIMENSION_MULTIPLE: 'DIMENSION_MULTIPLE' // 多维多选
}

const Select = withPlugin((props) => {
  const {
    label,
    type,
    onChange,
    selectLabelClass,
    selectItemClass,
    selectPannelClass,
    $i18n
  } = props

  const [value, setValue] = useState(null)
  const [optionData, setOptionData] = useState(props.optionData)
  const [showSelectPannel, setShowSelectPannel] = useState(false)

  // 初始化select值
  useEffect(() => {
    props.initValue &&
      optionData.forEach((v) => {
        if (v.code === props.initValue?.code) {
          v.selected = true
          setValue(v)
        } else {
          v.selected = false
        }
      })
  }, [optionData, props.initValue])

  // select展示
  const [showValue, setShowValue] = useState()
  useEffect(() => {
    if (type === SELECT_TYPE.SINGLE) {
      setShowValue(value?.name ? value.name : $i18n.transl('core.product.all'))
    }
    if (type === SELECT_TYPE.MULTIPLE) {
      setShowValue(value?.length ? value : $i18n.transl('core.product.all'))
    }
    if (type === SELECT_TYPE.DIMENSION_MULTIPLE) {
      const mergeValue = value?.reduce((pre, cur) => {
        return pre.concat(cur.values)
      }, [])
      setShowValue(mergeValue?.length ? mergeValue : $i18n.transl('core.product.all'))
    }
  }, [$i18n, type, value])

  // 保持debounce返回函数的唯一性
  const debounceCbRef = useRef()
  useEffect(() => {
    debounceCbRef.current = debounce(onChange, 1000)
  }, [onChange])

  // 点击select-option
  const handleClickOption = (val) => {
    if (type === SELECT_TYPE.SINGLE) {
      if (val.code === value?.code) return
      optionData.forEach((v) => {
        v.selected = v.code === val.code ? true : false
      })
      setValue(val)
      onChange(val)
      setShowSelectPannel(false)
    }

    if (type === SELECT_TYPE.MULTIPLE) {
      optionData.forEach((v) => {
        if (v.code === val.code) v.selected = !v.selected
      })
      const selectedArr = optionData.filter((v) => v.selected)
      setValue(selectedArr)
      debounceCbRef.current(selectedArr)
    }

    if (type === SELECT_TYPE.DIMENSION_MULTIPLE) {
      const { index, item } = val
      optionData[index].values.forEach((v) => {
        if (v.code === item.code) v.selected = !v.selected
      })
      const dimensionSelectedArr = optionData.map((v1) => ({
        ...v1,
        values: v1.values.filter((v2) => v2.selected)
      }))
      setValue(dimensionSelectedArr)
      debounceCbRef.current(dimensionSelectedArr)
    }

    setOptionData([...optionData])
  }

  // 自定义 or 默认OptionList
  const PannelOptionList = props.children ? props.children : OptionList

  return (
    <div
      className={styles.selectWrapper}
      onClick={() => setShowSelectPannel(true)}
      onMouseLeave={() => setShowSelectPannel(false)}
    >
      <div className={cx(styles.selectLabel, selectLabelClass)}>{label}</div>
      <div
        className={cx(
          styles.selectItem,
          showSelectPannel && styles.active,
          selectItemClass
        )}
      >
        <span className={styles.selectValue}>
          {Array.isArray(showValue)
            ? showValue.map((v, i) => (
                <Fragment key={v.code}>
                  {v.name}
                  {i !== showValue.length - 1 && ', '}
                </Fragment>
              ))
            : showValue}
        </span>
        {showSelectPannel && (
          <ShadowCard shadowCardWrapper={selectPannelClass}>
            <PannelOptionList
              optionData={optionData}
              onChange={handleClickOption}
            />
          </ShadowCard>
        )}
      </div>
    </div>
  )
})

export { SELECT_TYPE, OptionList }
export default Select
