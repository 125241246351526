import React, { useContext, useRef } from 'react'
import { FunkGoContext } from '@flamingo_tech/funkgo'
import StoreEntryContainer from './StoreEntryContainer'
import AppEntryContainer from './AppEntryContainer'

const EntryContainer = props => {
  const { _createPage } = useContext(FunkGoContext)
  const AppEntryPage = useRef(_createPage(AppEntryContainer))
  const StoreEntryPage = useRef(_createPage(StoreEntryContainer))

  const EntryComponent = props.$detector.isStandaloneApp()
    ? AppEntryPage.current
    : StoreEntryPage.current

  // if it has data from props, the data may be come from SSR
  //  , so pass it to sub-component to avoid the re-render
  // otherwise keep the data to be undefined
  const hasData = Object.keys(props.data).length > 1
  const data = hasData ? props.data : undefined
  const ogInfo = hasData ? props.ogInfo : undefined

  return (
    <EntryComponent data={data} ogInfo={ogInfo}></EntryComponent>
  )
}

EntryContainer.fetchPageDataSSR = StoreEntryContainer.fetchPageData
EntryContainer.fetchOpenGraphInfo = StoreEntryContainer.fetchOpenGraphInfo
EntryContainer.fetchStaticShellProps = StoreEntryContainer.fetchStaticShellProps

export default EntryContainer