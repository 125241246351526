import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'

import Shell from '../Shell/PageShellContainer'

import UserReviewPage from '../../components/Store/Product/UserReviewPage'

import StoreService from '../../services/StoreService'

class UserReviewContainer extends PureComponent {
  static Shell = Shell

  state = {
    reviews: []
  }

  componentDidMount() {
    this._isMounted = true

    this.props.$storage.create('_ur', {
      expire: 60 * 1000 * 10
    })
      .getItemAsync(() => {
        const service = new StoreService(this.props.$http)
        return service.fetchUserReviews()
      })
      .then(data => {
        if (this._isMounted) {
          this.setState({
            reviews: data
          })
        }
      }).catch(() => {})
  }

  componentWillUnmount() {
    this._isMounted = false
  }


  render() {
    return (
      <UserReviewPage {...this.state} />
    )
  }
}

export default withPlugin(UserReviewContainer)
