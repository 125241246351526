import React, { useState } from 'react'

import { withDrawer } from '../../common/Drawer/Drawer'
import { OptionList } from '../../common/Select/Select'
import SpecList from '../../common/SpecList/SpecList'

import { FILTER_TYPE } from './config/ProductCommentSectionHeaderConfig'

import styles from './ProductCommentSectionHeaderModal.module.css'

const SelectPannelModal = (props) => {
  const { selectedTab, onChange, onConfirm } = props
  const [optionData, setOptionData] = useState(props.selectedTab.optionData)

  // 点击option
  const handleClickOption = (val) => {
    if (selectedTab.type === FILTER_TYPE.SORTBY) {
      if (val.code === selectedTab.value?.code) return
      optionData.forEach((v) => {
        v.selected = v.code === val.code ? true : false
      })
      onChange(selectedTab.type, val)
    }

    if (
      selectedTab.type === FILTER_TYPE.COLOR_SIZE ||
      selectedTab.type === FILTER_TYPE.MOBILE_FILTER
    ) {
      const { index, item } = val
      optionData[index].values.forEach((v) => {
        if (v.code === item.code) v.selected = !v.selected
      })
      const dimensionSelectedArr = optionData.map((v1) => ({
        ...v1,
        values: v1.values.filter((v2) => v2.selected)
      }))

      onChange(selectedTab.type, dimensionSelectedArr)
    }

    setOptionData([...optionData])
  }
  // H5多维清空
  const onClearAllByMultiple = () => {
    optionData.forEach((specItem) => {
      specItem.values.forEach((v) => (v.selected = false))
    })
    setOptionData([...optionData])
    onChange(selectedTab.type, [])
  }

  const SelectPannel =
    selectedTab.type === FILTER_TYPE.SORTBY ? OptionList : SpecList

  return (
    <SelectPannel
      optionData={optionData}
      onChange={handleClickOption}
      onClearAllByMultiple={onClearAllByMultiple}
      onConfirm={onConfirm}
      hasFooter={selectedTab.type !== FILTER_TYPE.SORTBY}
    />
  )
}

const withDrawerClass = {
  position: styles.navTabBoxDrawerPosition,
  coverClass: styles.coverClass,
  bodyClass: styles.drawerBodyClass,
  contentClass: styles.drawerContentClass
}

const ColorOrSizeSelectPannelElement = withDrawer(SelectPannelModal, {
  ...withDrawerClass,
  position: styles.colorOrSizeTabDrawerPosition,
})
const ColorOrSizeSelectPannelModal = (props) => {
  return <ColorOrSizeSelectPannelElement {...props} />
}

const SortSelectPannelElement = withDrawer(SelectPannelModal, {
  ...withDrawerClass,
  bodyClass: styles.sortDrawerBodyClass,
})
const SortSelectPannelModal = (props) => {
  return <SortSelectPannelElement {...props}/>
}

const FilterSelectPannelElement = withDrawer(SelectPannelModal,withDrawerClass)
const FilterSelectPannelModal = (props) => {
  return <FilterSelectPannelElement {...props}/>
}

export {
  ColorOrSizeSelectPannelModal,
  SortSelectPannelModal,
  FilterSelectPannelModal
}