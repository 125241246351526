import React from 'react'

import { OptionList } from '../Select/Select'
import { withDrawer } from '../Drawer/Drawer'
import { getValByList } from '../../../utils/arrayUtils'
import cx from '../../../utils/className'
import styles from './SelectLangModal.module.css'

const SelectLang = (props) => {
  let { langList } = props
  const siteLang = props.$site.getSiteInfo()?.languages[0]?.lang
  const sitedIndex = getValByList(langList, siteLang, 'index', 'code')

  // 业务侧-调整语言列表顺序
  const siteArr = langList.splice(sitedIndex, 1)
  langList = [...langList, ...siteArr]
  // 选中处理
  props.translatedContentObj &&
    langList.forEach((langObj) => {
      langObj.selected = langObj.code === props.translatedContentObj?.lang
    })

  const isDesktop = props.$detector.isDesktop()
  return (
    <div
      className={cx(
        styles.wrapper,
        isDesktop && styles.isDesktop,
        props.translatedContentObj && styles.translated
      )}
      onMouseLeave={isDesktop ? props.onClose : null}
    >
      <OptionList
        optionData={langList}
        onChange={props.handleSelectLang}
        itemWrapperClass={!isDesktop && styles.itemWrapperClass}
      />
    </div>
  )
}

const SelectLangModalElement = withDrawer(SelectLang)

const SelectLangModal = (props) => {
  return (
    <SelectLangModalElement
      headerTitle={props.$i18n.transl('core.product.translateTo')}
      {...props}
    />
  )
}
export { SelectLangModal, SelectLang }
