import React from 'react'

import styles from './NewUserCouponCard.module.css'
import cx from '../../../utils/className'

const TYPE = {
  LARGE: styles.large,
  MEDIUM: styles.medium,
  SMALL: styles.small
}

const COUPON_TYPE = {
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'FIXED_AMOUNT'
}

const NewUserCouponCard = props => {
  const {
    type,
    amount,
    subTitle,
    cardType
  } = props

  const className = cardType || TYPE.LARGE

  return (
    <div className={cx(styles.couponWrapper, className)}>
      <div className={styles.title}>{type === COUPON_TYPE.AMOUNT ? '$' : null}<h2>{amount}</h2>{type === COUPON_TYPE.PERCENTAGE ? '%' : null} OFF</div>
      <div className={styles.subTitle}>{subTitle}</div>
    </div>
  )
}

NewUserCouponCard.TYPE = TYPE

export default NewUserCouponCard
