import React from 'react'

import cx from '../../../utils/className'
import styles from './ShadowCard.module.css'

const ShadowCard = props => {
  return (
    <div className={cx(styles.shadowCardWrapper, props.shadowCardWrapper)} onMouseLeave={props.onMouseLeave}>
      {props.children}
    </div>
  )
}

export default ShadowCard