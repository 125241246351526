const PROMOTION_TYPE = {
  STANDARD_OFF: 'STANDARD_OFF',
  BUY_X_GET_Y: 'BUY_X_GET_Y',
  FIXED_AMOUNT_SELECT_Y: 'FIXED_AMOUNT_SELECT_Y', // NOT YET SUPPORTED ON SHOPIFY
}

const TARGET_TYPE = {
  ALL: 'ALL',
  SCENE: 'COLLECTION',
  CATEGORY: 'CATEGORY',
  VENDOR: 'VENDOR',
  PRODUCT: 'PRODUCT',

  COLLECTIONS: 'COLLECTION', // for legacy dsl config
  COLLECTION: 'COLLECTION'
}

const ensureDate = val => {
  if (val) {
    return new Date(val)
  }
  return undefined
}

const formatPromotionType = data => {
  const type = data.couponType || data.type

  if (type) {
    return PROMOTION_TYPE[type.toUpperCase()]
  }
  return PROMOTION_TYPE.STANDARD_OFF
}

const formatTargetType = data => {
  const targetType = data.scopeType || data.targetType

  if (targetType) {
    return TARGET_TYPE[targetType.toUpperCase()]
  }

  return TARGET_TYPE.ALL
}

const formatTargetSelection = data => {
  const targetSelection = []

  // for client-side definition
  if (Array.isArray(data.targetSelection)) {
    data.targetSelection.forEach(item => {
      // for collection
      if (item.handle) {
        targetSelection.push({
          title: item.title || item.handle,
          handle: item.handle
        })
      }

      if (item.category) {
        targetSelection.push({
          title: item.title || item.category,
          category: item.category
        })
      }
    })

  // for server return definition
  } else if (Array.isArray(data.scopeList)) {
    data.scopeList.forEach(item => {
      targetSelection.push({
        title: item.bizName,
        category: item.bizName
      })

      if (item.aliasBizName) {
        targetSelection.push({
          title: item.bizName,
          category: item.aliasBizName
        })
      }
    })
  }

  if (targetSelection.length) {
    return targetSelection
  }
  return undefined
}

const formatDiscount = data => {
  let discount, requirement, X, Y, fixedAmount

  const rule = data.rule || {}

  discount = data.discount || rule.discount

  if (!discount.code && data.code) {
    discount.code = data.code
  }

  requirement = data.requirement || data.target || rule.target

  X = rule.x || data.x || data.X
  Y = rule.y || data.y || data.Y
  fixedAmount = rule.fixedAmount || data.fixedAmount

  return {
    discount,
    requirement,
    X,
    Y,
    fixedAmount
  }
}

export {
  PROMOTION_TYPE,
  TARGET_TYPE,

  ensureDate,
  formatPromotionType,
  formatTargetType,
  formatTargetSelection,
  formatDiscount,
}
