import React, { PureComponent } from 'react'

import StoreHomeContainer, { PlainStoreHomeContainer } from '../Store/StoreHomeContainer'
import { PlainProductCategoryContainer } from '../Store/ProductCategoryContainer'
import { PlainGirlsShowHomeContainer } from '../GirlsShow/GirlsShowHomeContainer'
import { PlainProfileContainer } from '../User/ProfileContainer'
import NewContainer from '../Store/NewContainer'

import withCartAndPlugins from '../../hooks/withCartAndPlugins'
import Shell from '../Shell/GuidePageShellContainer'
import AppIndexPage, { getDefaultTab } from '../../components/Shell/Page/AppIndexPage'

import { getCartLineItemCount } from '../../utils/cartUtils'
import { attachTabKeyToSearch } from '../../components/common/Tab/utils/tabUtils'

const TAB_KEY = {
  STORE_HOME: 'Home',
  CATEGORY: 'Category',
  NEW: 'New',
  GIRLS: 'Girls',
  MY: 'My',
}

const TABS = [{
  key: TAB_KEY.STORE_HOME,
  image: '\ue631',
  title: 'shell.footer.shop',
  link: 'https://m.flamingo.shop/store/'
}, {
  key: TAB_KEY.CATEGORY,
  image: '\ue62f',
  title: 'shell.footer.category',
  link: 'https://m.flamingo.shop/store/category'
}, {
  key: TAB_KEY.NEW,
  image: '\ue632',
  title: 'shell.footer.new',
  link: 'https://m.flamingo.shop/store/new'
},
{
  key: TAB_KEY.GIRLS,
  image: '\ue630',
  title: 'shell.footer.girls',
  link: 'https://m.flamingo.shop/store/girls_show'
}, {
  key: TAB_KEY.MY,
  image: '\ue62e',
  title: 'shell.footer.me',
  link: 'https://m.flamingo.shop/store/user'
}]

const TAB_PARAM_NAME = 'tab'

const PageComponentMap = {
  [TAB_KEY.STORE_HOME]: PlainStoreHomeContainer,
  [TAB_KEY.CATEGORY]: PlainProductCategoryContainer,
  [TAB_KEY.NEW]: NewContainer,
  [TAB_KEY.GIRLS]: PlainGirlsShowHomeContainer,
  [TAB_KEY.MY]: PlainProfileContainer,
}

class StoreEntryContainer extends PureComponent {
  static fetchPageData = StoreHomeContainer.fetchPageData
  static fetchOpenGraphInfo = StoreHomeContainer.fetchOpenGraphInfo
  static fetchStaticShellProps = StoreHomeContainer.fetchStaticShellProps

  state = {
    currentTab: getDefaultTab({
      tabs: TABS,
      tabParamName: TAB_PARAM_NAME,
      location: this.props.$router.location,
    }),
  }

  handleTabChange = tab => {
    this.props.$track.event('FooterTab', `click_${tab.key}`)
    attachTabKeyToSearch(tab, TAB_PARAM_NAME)
    this.setState({
      currentTab: tab
    })
  }

  getCurrentShellProps() {

    const shellPropsFromServer = this.props.staticContext ? this.props.staticContext.shellPropsFromServer : (this.props.data ? this.props.data.shellPropsFromServer : {})

    return {
      promotion: this.state.currentTab.key !== TAB_KEY.GIRLS,
      displaySearch: true,
      displayBack: false,
      supportDesktop: true,
      showShippingBar: this.state.currentTab.key === TAB_KEY.STORE_HOME,
      desktopFullScreen: true,
      showDownloadBanner: true,
      downloadPage: 'home',
      footHintHeight: 50,
      hideGlobalCart: this.state.currentTab.key === TAB_KEY.GIRLS,
      ...shellPropsFromServer
    }
  }

  render() {
    const CurrentPanel = PageComponentMap[this.state.currentTab.key]
    const panelProps = this.state.currentTab.key === TAB_KEY.STORE_HOME ? { data: this.props.data } : {}
    const tabsRibbon = {
      [TAB_KEY.CART]: (this.props.cart && getCartLineItemCount(this.props.cart) !== 0) ? getCartLineItemCount(this.props.cart) : undefined
    }
    return (
      <Shell
        {...this.getCurrentShellProps()}
      >
        {
          this.props.$detector.isDesktop() ?
          <PlainStoreHomeContainer {...this.props}></PlainStoreHomeContainer>
          :
          <AppIndexPage
            tabs={TABS}
            tabParamName={TAB_PARAM_NAME}
            tabsRibbon={tabsRibbon}
            currentTab={this.state.currentTab}
            onTabChange={this.handleTabChange}
          >
            <CurrentPanel
              {...panelProps}
            ></CurrentPanel>
          </AppIndexPage>
        }
      </Shell>
    )
  }
}

export default withCartAndPlugins(StoreEntryContainer, { withClient: false })