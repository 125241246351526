import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import { transferBraintreePayPal2APPShippingAddress } from '../../../utils/Checkout/checkoutUtils'

class PayPalButton extends PureComponent {

  renderDomId = `paypal-button-container`
  buttons = null
  hasRendered = false

  componentDidMount() {
    this.renderPayPalBtn()
  }

  componentWillUnmount() {
    if (this.buttons && this.buttons.close && this.hasRendered) {
      this.buttons.close()
      this.hasRendered = false
    }
  }

  renderPayPalBtn() {
    const { isPaylater } = this.props

    const paypalType = isPaylater ? 'paypal_paylater' : 'paypal'

    this.buttons = window.paypal && typeof window.paypal.Buttons === 'function' && window.paypal.Buttons({
      fundingSource: isPaylater ? window.paypal.FUNDING.PAYLATER : window.paypal.FUNDING.PAYPAL,

      style: {
        tagline: false,
        label: 'pay',
        layout: 'horizontal',
        color: 'gold',
        ...this.props.style
      },
      onClick: () => {
        this.props.onTrack(`click_pay_with_${paypalType}`)
        if (this.props.onClickPaypalButton) {
          this.props.onClickPaypalButton(paypalType)
        }
      },
      onCancel: () => {
        this.props.onTrack(`cancel_pay_with_${paypalType}`)
      },
      createOrder: () => {
        this.props.onTrack(`open_${paypalType}_dialog`)

        const { currency } = this.props.$site.getSiteInfo()

        return this.props.paypalCheckoutInstance.createPayment({
          flow: 'checkout', // Required
          amount: this.props.totalFee, // Required
          currency: this.props.currencyCode || currency, // Required, must match the currency passed in with loadPayPalSDK

          intent: 'capture', // Must match the intent passed in with loadPayPalSDK

          enableShippingAddress: true,
          shippingAddressEditable: false
          // shippingAddressOverride: this.props.selectedAddress ? transferAPP2BraintreePayPalShippingAddress(this.props.selectedAddress) : {}
        }).catch(err => {
          this.props.$toastError({
            content:  this.props.$i18n.transl('core.checkout.validations.invalidPayment'),
            duration: 2000
          })
          this.props.onTrack('paypal_error', err.message)
        })

      },
      onApprove: (data) => {
        this.props.onTrack(`approve_${paypalType}_order`)

        return this.props.paypalCheckoutInstance.tokenizePayment(data).then(payload => {
          return this.props.onPay(payload.nonce,  transferBraintreePayPal2APPShippingAddress(payload.details), paypalType)
        })
      },
      onError: err => {
        this.props.onTrack(`${paypalType}_error`, err.message)
      }
    })

    if (!this.buttons.isEligible()) {
      // Skip rendering if not eligible
      this.props.onIsNotEligible && this.props.onIsNotEligible()
      return
    }

    const domId = `#${this.props.isPaylater ? 'paylater-' : ''}${this.renderDomId}`

    this.buttons.render(domId).then(() => {
      this.hasRendered = true
    }).catch(err => {
      let selector = document.querySelector(`#${this.renderDomId}`)

        // button failed to render, possibly because it was closed or destroyed.
        if (selector && selector.children.length > 0) {
            // still mounted so throw an error
            throw new Error(err)
        }

       // not mounted anymore, we can safely ignore the error
       return
    })
  }

  render() {
    const domId = `${this.props.isPaylater ? 'paylater-' : ''}${this.renderDomId}`

    return <div id={domId} className={this.props.className}></div>

  }
}

export default withPlugin(PayPalButton)
