import React, { useEffect } from 'react'

import { withModal } from '../../common/Modal/Modal'

import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './WheelOverlay.module.css'
import WheelOverlayMobileCard from './WheelOverlayMobileCard'
import WheelOverlayPcCard from './WheelOverlayPcCard'
import useFn from '../../../hooks/useFn'

const WheelOverlay = withPlugin(props => {
  const isDesktop = props.$detector.isDesktop()
  const SubmitPanelComponent = isDesktop ? WheelOverlayPcCard : WheelOverlayMobileCard

  const trackView = useFn(() => {
    props.$track.event(props.eventCategory || 'Wheel', 'view_wheel')
  })

  useEffect(() => {
    trackView()
  }, [trackView])

  return (
    <aside>
      <SubmitPanelComponent
        {...props.wheelOverlayProps}
        onClose={props.onClose}
      />
    </aside>
  )
})

export default withModal(WheelOverlay, { closeBtnClassName: styles.closeIcon, maskClassName: styles.mask, disableCoverClick: true, showCloseButton: false })