import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import LayoutCommon from './LayoutCommon'
import LayoutTab from './LayoutTab'

/* ----------------------------------------------- */

import NullComponent from './NullComponent'
import Banner from '../common/Banner/Banner'
import Grid from '../common/Grid/Grid'
import CouponCard from '../Store/Coupon/CouponCard'
import ProductCardPanel from '../Store/ProductCardPanel/ProductCardPanel'
import ProductDealsPanel from '../Store/ProductDeals/ProductDealsPanel'
import FlamingDealsPanel from '../Store/FlamingDealsPanel/FlamingDealsPanel'
import ProductTabList from '../Store/ProductList/ProductTabList'
import PageTitle from './subComponents/PageTitle' // as <h1>
import Paragraph from './subComponents/Paragraph' // as <p>
import List from './subComponents/List' // as <ul>
import VideoBanner from '../common/Video/VideoBanner'
import YouTubeIframeVideo from '../common/Video/YouTubeIframeVideo'

import CategorySegmentColumn from '../Store/ProductCategory/CategorySegmentsColumn'
import CategorySegment from '../Store/ProductCategory/CategorySegment'
import HorizontalImageText from '../common/ImageText/HorizontalImageText'
import CountdownBar from '../Widget/Countdown/CountdownBar'
import DetailCountdownBar from '../Widget/Countdown/DetailCountdownBar'

import {
  isProductList,
  ProductList,
  MultiColumns,
  HorizontalColumns
} from './ListComponents'


/* ----------------------------------------------- */

const staticComponents = {
  StoreHeader: NullComponent,
  PageTitle,
  Paragraph,
  List,
  Banner,
  Grid,
  HorizontalImageText,
  CategorySegmentColumn,
  CategorySegment,
  CountdownBar,
  DetailCountdownBar,
  VideoBanner,
  YouTubeVideo: YouTubeIframeVideo,
  // TODO: implement
  Image: NullComponent,
  Video: NullComponent,
}

const productComponents = {
  DualColumns: MultiColumns,
  HorizontalColumns,
  ProductCardPanel,
  ProductDealsPanel,
  FlamingDealsPanel,
  ProductTabList
}

const promotionComponents = {
  Coupon: CouponCard
}

/* ----------------------------------------------- */

const adaptOptions = (options = {}) => {
  if (options.children) { // fallback for legacy options
    options.childItems = options.children
    delete options.children
  }

  return options
}

/* ----------------------------------------------- */

const adaptStaticComponentProps = options => {
  const props = adaptOptions(options)

  if (props.childOptions) {
    props.childOptions = adaptStaticComponentProps(props.childOptions)
  }

  return props
}

const adaptCommonProps = (props = {}, commonProps = {}) => {
  return {
    ...props,
    ...commonProps
  }
}

const adaptProductComponentProps = (options, productComponentProps) => {
  const { commonProductProps, collections } = productComponentProps
  const props = {
    ...commonProductProps,
    ...adaptOptions(options)
  }

  // preload first page products, inject if found
  if (props.productQueue && props.productQueue.collectionSource && props.productQueue.collectionSource.handle) {
    const collection = collections[props.productQueue.collectionSource.handle]
    if (typeof collection === 'object') {
      Object.assign(props, collection, {
        title: props.title || collection.title,
        collectionHandle: props.productQueue.collectionSource.handle
      })
    }
  }

  if (collections && props.items && props.items.length > 0 && props.items[0].collectionHandle) {
    const collection = collections[props.items[0].collectionHandle] || {}

    Object.assign(props, collection, {
      title: props.title || collection.title,
      collectionHandle: props.items[0].collectionHandle,
      options: props.items[0]
    })
  }

  if (props.childOptions) {
    props.childOptions = adaptProductComponentProps(props.childOptions, productComponentProps, collections)
  }

  return props
}

const adaptPromotionComponentProps = (options, promotionComponentProps) => {
  return {
    ...adaptOptions(options),
    ...promotionComponentProps
  }
}

const mapItems = ({ is, options }, { productComponentProps, promotionComponentProps, commonProps }, plugin) => {
  let Component, props
  const meta = {}

  if (staticComponents[is]) {
    Component = staticComponents[is]
    props = adaptStaticComponentProps(options)
  } else if (isProductList(is, options)) {
    Component = ProductList
    props = adaptProductComponentProps(options, productComponentProps)
    props.topProduct = productComponentProps.getTopProduct(props)
    meta.isProductList = true
  } else if (productComponents[is]) {
    Component = productComponents[is]
    props = adaptProductComponentProps(options, productComponentProps)
  } else if (promotionComponents[is]) {
    Component = promotionComponents[is]
    props = adaptPromotionComponentProps(options, promotionComponentProps)
  } else {
    Component = NullComponent
    props = {}
    plugin.$logger.error(`[Layout] non-supported component type: "${is}"`)
  }

  props = adaptCommonProps(props, commonProps)

  return {
    Component,
    props,
    meta
  }
}

const makeGetTopProductStrategy = props => {
  let injected = false

  return componentProps => {
    if (props.topProduct && !injected) {
      injected = true
      return props.topProduct
    }

    return null
  }
}


/* ------------------------------------------------ */

const Layout = props => {
  const plugin = useContext(PluginContext)

  const commonProductProps = {
    productService: props.productService,
    toExtraInfo: props.productListToExtraInfo
  }

  const productComponentProps = {
    commonProductProps,
    getTopProduct: makeGetTopProductStrategy(props),
    collections: props.collections
  }

  const promotionComponentProps = {
    ...props.selectedPromotion
  }

  const layoutItems = (props.layout || []).map(
    options => mapItems(options, {
      productComponentProps,
      promotionComponentProps,
      commonProps: props.commonProps
    }, plugin)
  )

  const layoutProps = { layoutItems, root: props.root, meta: props.meta }

  if (props.meta.tab) {
    return (
      <LayoutTab {...layoutProps}></LayoutTab>
    )
  }

  return (
    <LayoutCommon {...layoutProps}></LayoutCommon>
  )
}

export default Layout
