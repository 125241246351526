import React from 'react'

import cx from '../../utils/className'
import styles from './Skeleton.module.css'

/* ------------------------- */
const TYPE = {
  LARGE: styles.large,
  MEDIUM: styles.medium,
  SMALL: styles.small
}

const Skeleton = props => {
  const className = props.type || TYPE.LARGE

  return (
    <div
      className={cx(styles.wrapper, className, props.className)}
    />
  )
}

Skeleton.TYPE = TYPE

export default Skeleton
