import React from 'react'
import { withDrawer } from '../../common/Drawer/Drawer'
import DefaultCouponCard from '../Coupon/DefaultCouponCard'
import { withPlugin } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import styles from './CouponCenterList.module.css'

const CouponCenterList = withDrawer(props => {

  const availableCouponList = [...props.availableCouponList]

  for (let i = 0; i < availableCouponList.length; i++) {
    const coupon = availableCouponList[i]

    if (props.selectedCoupon && props.selectedCoupon.id === coupon.id) {
      availableCouponList.splice(i, 1)
      availableCouponList.unshift(coupon)
      break
    }
  }

  return (
    <div className={cx(styles.wrapper, styles.isDesktop)}>
      <div className={styles.content}>
        <div className={styles.term}>{props.$i18n.transl('core.checkout.couponApplyTerms')}</div>
        <div className={styles.couponWrapper}>
          {
            availableCouponList.map((coupon, index) => (
              <DefaultCouponCard
                key={index}
                discountInfo={coupon.discountInfo}
                {...coupon.meta}
                onClick={() => { props.onChoose(coupon) }}
                selected={props.selectedCoupon && props.selectedCoupon.id === coupon.id}
                className={styles.card}
              ></DefaultCouponCard>
            ))
          }
        </div>
      </div>
    </div>
  )
})

const CouponCenterListComponent = props => {

  return (
    <CouponCenterList
      headerTitle={props.$i18n.transl('core.checkout.myCoupons')}
      {...props}
    />
  )
}

export default withPlugin(CouponCenterListComponent)