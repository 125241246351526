import React　from 'react'
import queryString from 'query-string'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import {
  useShare,
  useHeaderProps,
} from './hooks/pageShellHooks'

import AppHeader from '../Header/AppHeader'
import FreeShippingBanner from '../../Widget/FreeShippingBanner/FreeShippingBanner'
import styles from './AppPageShell.module.css'

const AppPageShell = props => {
  const headerProps = useHeaderProps(props)
  const share = useShare(props)

  const {
    $router,
    children,

    showShippingBar,
  } = props

  const handleShareClick = share
  const handleCartClick =  () => {
    $router.navigateToApp({
      scheme: '/cart'
    })
  }

  const handleBackClick = () => $router.goBack()
  const handleHomeClick = () => $router.navigateToHomePage()

  if (typeof window !== 'undefined') {
    const query = queryString.parse(window.location.search)
    if (query.displayHeader === 'false') {
      headerProps.displayHeader = false
    }
  }

  return (
    <section className={styles.wrapper}>
      <section className={styles.page}>
        <AppHeader
          {...headerProps}
          onCartClick={handleCartClick}
          onShareClick={handleShareClick}
          onHomeClick={handleHomeClick}
          onBackClick={handleBackClick}
        ></AppHeader>
        {
          showShippingBar && <FreeShippingBanner />
        }
        <main className={styles.content}>
          {children}
        </main>
      </section>
    </section>
  )
}

export default withCartAndPlugins(AppPageShell)
