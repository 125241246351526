import React, { Component } from 'react'
import withCartAndPlugins from '../../../../hooks/withCartAndPlugins'

import UnpaidCartComponent from '../../../../components/Store/CheckOut/UnpaidCart'
import withUnpaidCartEnabled from '../../../../hooks/withUnpaidCartEnabled'

// 未支付购物车
class UnpaidCartContainer extends Component {
  _storage = this.props.$storage.create('unpaid_cart_operated', { expire: 1000 * 60 * 60 * 24 * 2 }) // 未支付购物车

  state = {
    ready: false,
    closed: (this._storage.getItem({}) || {}).unpaid_cart_closed
  }


  componentDidMount() {
    if (this.props.cart) {
      this.setState({
        ready: true
      })
      if (this.props.mounted || !this.props.cart.id || this.state.closed || this.props.cart.itemQuantity === 0) {
        this.props.toNext()
      } else {
        // 购物车生效，flow 停止
        this.props.entryStorage.setItem(true)
      }
    } else {
      this.props.toNext()
    }
  }

  handleClose = () => {
    this.props.$track.event({
      category: 'UnpaidCart',
      action: 'close',
      nonInteraction: false
    })

    this.setState({
      closed: true
    })
    this._storage.setItem({
      ...this._storage.getItem({}),
      unpaid_cart_closed: true
    })
  }

  handleConfirm = () => {
    this.props.$track.event({
      category: 'UnpaidCart',
      action: 'continue_to_cart',
      nonInteraction: false
    })

    const coupons =  this.getCoupons()

    const couponHub = this.props.$store.couponHub
    couponHub.batchTake(coupons)
      .then(() => {
        this.setState({
          closed: true
        })
        this._storage.setItem({
          ...this._storage.getItem({}),
          unpaid_cart_closed: true
        })

        if (!this.props.isForAds) {
          this.props.$router.navigate({ name: 'Cart' })
        }
        setTimeout(() => {
          this.props.$toastSuccess(this.props.$i18n.transl('core.promotion.takenCouponSuccess'))
        }, 100)

        this.props.$track.event({
          category: 'UnpaidCart',
          action: 'take_coupon',
          label: coupons.map(item => item.id).join(','),
          nonInteraction: false
        })
      })
  }

  getCoupons = () => {
    const { regularCouponMeta: { abandonCartCoupons = [],  abandonCartCouponsForAds = [] } = {} } = this.props

    const coupons =  (this.props.isForAds && abandonCartCouponsForAds.length > 0) ? abandonCartCouponsForAds : abandonCartCoupons

    return coupons
  }

  render() {

   const coupons = this.getCoupons()

    if (
      !this.props.mounted &&
      coupons.length > 0 &&
      this.props.cart && this.props.cart.itemQuantity > 0 &&
      this.state.ready &&
      !this.state.closed
    ) {
      return (
        <UnpaidCartComponent
          coupons={coupons}
          cart={this.props.cart}
          onConfirm={this.handleConfirm}
          onClose={this.handleClose}
        />
      )
    }

    return null
  }
}

export default withCartAndPlugins(withUnpaidCartEnabled(UnpaidCartContainer))
