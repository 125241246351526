import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Countdown from './Countdown'

import styles from './CountdownBar.module.css'

const DEFAULT_COUNTDOWN_THEME = {
  background: "#FFF",
  color: "#ff8b8c"
}

const CountdownBar = ({ clientStartDate, clientEndDate, background, textColor, countdownTheme }) => {
  const plugin = useContext(PluginContext)

  let text
  let countdown = 0

  const now = new Date()

  const startDate = new Date(clientStartDate || now)
  const endDate = new Date(clientEndDate || now)

  if (now - startDate < 0) {
    text = 'STARTS IN'
    countdown = startDate - now
  } else if (now - endDate < 0) {
    text = plugin.$i18n.transl('core.promotion.endsIn')
    countdown = endDate - now
  }

  if (countdown <= 0) {
    return null
  }

  const theme = countdownTheme || DEFAULT_COUNTDOWN_THEME

  return (
    <div className={styles.countdownRow} style={{ background: background }}>
      <div className={styles.text} style={{ color: textColor }}>{text}</div>
      <Countdown
        timestamp={countdown}
        type={Countdown.TYPE.CLOCK}
        className={styles.countdown}
        {...theme}
      />
    </div>
  )
}

export default CountdownBar
