export const generateCommentImages = comments => {
  const result = []

  comments.forEach(comment => {
    const { overallFit, sku, star, content, user, publishTime} = comment

    comment.images.forEach(image => {
      result.push({
        src: image,
        key: image,
        overallFit,
        sku,
        star,
        content,
        user,
        publishTime
      })
    })
  })

  return result
}