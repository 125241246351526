import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import { createPortal } from 'react-dom'
import withTouchListen from '../../../hooks/withTouchListen'

import cx from '../../../utils/className'
import styles from './Modal.module.css'

const withModal = (Component, { shadow = true, position, showCloseButton = false, closeBtnClassName, className, maskClassName, disableCoverClick } = {}) => {
  const WrappedComponent = withTouchListen(props => {
    const plugin = useContext(PluginContext)
    const isDesktop = plugin && plugin.$detector.isDesktop()


    let modal = React.createRef()
    let closeBtn = React.createRef()


    if (props.hide && !props.keepAlive) {
      return null
    }

    if (typeof props.onClose !== 'function') {
      throw new Error('must provide onClose callback to a modal')
    }

    const handleClose = ev => {
      if(props.withoutClose) {
        return
      }
      if (ev.target === modal.current || ev.target === closeBtn.current) {
        props.onClose()
        ev.stopPropagation()
      }
    }

    if (typeof window === 'undefined' || typeof window.document === 'undefined') {
      return null
    }

    return createPortal((
      <aside style={{display: props.hide && props.keepAlive ? 'none' : 'block'}}>
        { shadow && (<div className={cx(styles.cover, maskClassName)}></div>) }
        <div className={cx(styles.body, className)} position={!isDesktop ? position : ''} onClick={disableCoverClick ? () => null :handleClose} ref={modal}>
          <div className={styles.content}>
            <Component
              {...props}
              ref={props.forwardedRef}
              scrollAreaRef={props.scrollAreaRef} />
              { showCloseButton && (<div className={cx(styles.closeBtn, closeBtnClassName)} onClick={handleClose} ref={closeBtn}></div>) }
          </div>
        </div>
      </aside>
    ), document.body)
  })

  WrappedComponent.displayName = `withModal(${Component.displayName || Component.name})`

  return React.forwardRef((props, ref) => {
    return <WrappedComponent {...props} forwardedRef={ref} />
  })
}

export {
  withModal
}
