import React from 'react'
import { withDrawer } from '../Drawer/Drawer'
import PurchaseProtection from '../../Store/PurchaseProtection/PurchaseProtection'

const PurchaseProtectionModal = withDrawer(() => {
  return (
    <PurchaseProtection />
  )
})

export default PurchaseProtectionModal