import React,{ useContext, useState } from 'react'

import NotificationSettingCard from '../../components/User/NotificationSettingCard'
import Image from '../common/Image/Image'
import Link from '../common/Link/Link'
import { PluginContext } from '@flamingo_tech/funkgo'
import withLoginCheck from '../../hooks/withLoginCheck'
import { withGlobalSetting } from '../../hooks/withGlobalSettingEnabled'
import cx from '../../utils/className'
import { FEEDBACK_MENU_KEY } from '../../containers/User/ProfileContainer'

import styles from './ProfilePage.module.css'

const PROFILE_APP_DOWNLOAD_LINK = `https://flamingoapp.onelink.me/0qtU/xdy9hxtb?deep_link_value=${encodeURIComponent('flamingo://flamingo.shop/basewebview?urlString=https://flamingo.shop/store/activity/piggy_bank&utm_source=Web&utm_campaign=me_hint')}`

const PersonalCard = props => {
  const plugin = useContext(PluginContext)

  const hasLogin = plugin.$user.hasLogin()
  if (!hasLogin) {
    return (
      <div className={styles.noLoginCard}>
        <Image
          middle
          lazy
          {...props.image}
          className={styles.avatar}
          title={props.name}
        />
        <span className={styles.noLoginCardTitle}>{plugin.$i18n.transl('core.login.signInAndRegister')}</span>
      </div>
    )
  }

  const handleGoToVip = ev => {
    ev.preventDefault()
    plugin.$router.navigate({
      name: 'Vip'
    })
  }

  return (
    <Link className={styles.card} to="ProfileEdit">
      <Image
        middle
        lazy
        {...props.image}
        className={styles.avatar}
        title={props.name}
      />
      <div className={cx(styles.rightWrapper, !props.name && styles.noName)}>
        <div className={styles.user}>
          { props.name && <h4 className={styles.name}>{props.name}</h4> }
          { props.showVipEntrance && props.vipIcon && <Image small src={props.vipIcon} className={styles.vip} onClick={handleGoToVip}/> }
        </div>
        <h5 className={styles.desc}>{plugin.$i18n.transl('core.user.editProfile')}</h5>
      </div>
    </Link>
  )
}

const DownloadGuideCard = props => {
  const plugin = useContext(PluginContext)
  const { Trans } = plugin.$i18n

  const handleDownload = () => {
    document.location.href = props.link
    plugin.$track.event('AppDownload', 'click', 'me_hint')
  }

  return (
    <div className={styles.downloadGuideCard}>
      <div className={styles.content}>
        <p>
          <Trans i18nKey='core.login.signInAtApp'>
            Sign in at app and earn up to <span className={styles.highlighted}>10,000 coins</span> per day
          </Trans>
        </p>
        <div className={styles.btn} onClick={handleDownload}>{plugin.$i18n.transl('common.get')}</div>
        <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
      </div>
    </div>
  )
}

const SectionItem = props => {
  const { title, image, link, count, onClick } = props

  const plugin = useContext(PluginContext)

  const track = plugin.$track

  const clickSectionItem = (e, itemTitle) => {
    track && track.event('ProfileContainer', 'click_section_item', itemTitle)
    onClick && onClick(e)
  }

  return (
    <Link
      to={link}
      onClick={(e)=>clickSectionItem(e, title)}
      className={styles.sectionItem}
    >
      <div className={styles.imageWrapper}>
        <Image
          className={styles.image}
          src={image}
          small
        />
        {
          !!count && <span className={styles.count}>{count}</span>
        }
      </div>
      <div className={styles.title}>{title}</div>
    </Link>
  )
}

const Section = props => {
  const plugin = useContext(PluginContext)

  const { title, link, items } = props

  const WithCheckLink = withLoginCheck(() => (
    <Link
      to={link}
      block
      className={styles.header}
    >
      {title}
      <span className={styles.viewMore}>{plugin.$i18n.transl('common.viewMore')}</span>
    </Link>
  ), { loginCallback: () => props.onNavigateTo(link) })

  return (
    <div className={styles.section}>
      {
        link
        ? <WithCheckLink />
        : <h4 className={styles.header}>{title}</h4>
      }
      {
        items &&
        <div className={styles.content}>
          {
            items.map(item => {
              const WithCheckSectionItem = withLoginCheck(() => <SectionItem {...item}/>, { loginCallback: () => props.onNavigateTo(item.link) })
              return <WithCheckSectionItem key={item.title} />
            })
          }
        </div>
      }
    </div>
  )
}

const SectionCard = ({ sections, onNavigateTo }) => {
  return sections.map(section => {
    return (
      <Section key={section.title} {...section} onNavigateTo={onNavigateTo} />
    )
  })
}

const ProfilePage = props => {
  const { sections, plainItems, vipIcon, $storage } = props

  const storage = $storage.create('profile_download_guide')
  const [showGuide, setShowGuide] = useState(storage.getItem(true))

  const navgateTo = params => {
    props.$router.navigate(params)
  }

  const handleClose = () => {
    setShowGuide(false)
    storage.setItem(false)
    props.$track.event('AppDownload', 'close', 'me_hint')
  }

  const WithCheckPersonalCard = withLoginCheck(PersonalCard)

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.personCenterWrapper, showGuide && styles.noMargin)}>
        <WithCheckPersonalCard {...props.user} showVipEntrance={props.showVipEntrance} vipIcon={vipIcon}/>
        {
          showGuide
          &&
          <DownloadGuideCard
            link={PROFILE_APP_DOWNLOAD_LINK}
            onClose={handleClose}
          ></DownloadGuideCard>
        }
      </div>
      <NotificationSettingCard />
      <SectionCard sections={sections} onNavigateTo={navgateTo} />
      {
        plainItems.map(item => {
          let renderLink = (
            <Link
              key={item.title}
              className={styles.plainItem}
              {...item}
            >
              <h5 className={styles.title}>{item.title}</h5>
            </Link>
          )

          if (item.key === FEEDBACK_MENU_KEY) {
            renderLink = (
              <div
                key={item.title}
                className={styles.plainItem}
                onClick={props.onSupport}
              >
                <h5 className={styles.title}>{item.title}</h5>
              </div>
            )
          }

          if (item.needLogin) {
            const WithLoginLink = withLoginCheck(() => renderLink, { loginCallback: () => navgateTo(item.link) })
            return <WithLoginLink key={item.title} />
          }

          return renderLink
        })
      }
      {/* <SelectedCountrySite
        className={styles.countryIcon}
        liStyle={styles.liStyle}
      ></SelectedCountrySite> */}
    </div>
  )
}

export default withGlobalSetting(ProfilePage, 'vip')
