import { BaseService } from '@flamingo_tech/funkgo'
import ProductService from './ProductService'

import { createStorage } from '@flamingo_tech/funkgo'
import WishProductModel from '../npm/FunkGoModel/WishProductModel'


class WishListService extends BaseService {
  constructor($http) {
    super($http)
    this.productService = new ProductService($http)
  }

  wishListStorage = createStorage("wish_list")

  fetchWishList = ({ pageSize = 20, fromCursor = null}) => {
    const localHandles = this.getLocalWishList()

    if (localHandles.length > 0 && Array.isArray(localHandles)){
      //如果本地wishList不为空就去同步
      return this.syncLocalWishListToRemote().then(() => {
        return this.getRemoteWishList(pageSize, fromCursor)
      })
    } else {
      return this.getRemoteWishList(pageSize, fromCursor)
    }
  }

  getRemoteWishList = (pageSize, fromCursor) => {
    return this.get(
      `/client/api/v3/favorites`,
      {
        params: {
          limit: pageSize,
          cursor: fromCursor
        }
      }
    ).then(data => {
      return {
        pageInfo: data.pageInfo,
        products: data.list.map(model => ({
          cursor: model.cursor,
          product: new WishProductModel(model.data.product)
        })),
        total: data.total
      }
    })
  }

  addRemoteWishProduct = handles => {
    return this.post(`/client/api/v2/favorites/`, { handles })
  }

  syncLocalWishListToRemote = (handle = null) => {
    const localWishList = this.getLocalWishList()

    if (localWishList.length > 0 && Array.isArray(localWishList)) {
      const syncHandles = [handle, ...localWishList]
      //如果本地wishList不为空就去同步
      return this.addRemoteWishProduct(syncHandles).then(() => {
        this.clearLocalWishList()
      })
    } else {
      return Promise.resolve()
    }
  }

  toggleWishProductByHandle = ({ handle, productId, selectedOptions = {} }) => {
    return this.post(`/client/api/v3/favorites/createOrCancelFavorites`, {
      productId,
      handle,
      selectedOptions
    })
  }

  checkLocalWishStatus = handle => {
    return this.getLocalWishList().indexOf(handle) > -1
  }

  clearLocalWishList = () => {
    this.setLocalWishList([])
  }

  getLocalWishList = () => {
    return this.wishListStorage.getItem([])
  }

  setLocalWishList = newWishList => {
    if (Array.isArray(newWishList)){
      this.wishListStorage.setItem(newWishList)
    }
  }

  checkProductWishStatus = productId => {
    return this.post(
      `/client/api/v3/favorites/check`, {
        productId
      }
    )
  }

  addWishProductList = handles => {
    return this.post(`/client/api/v3/favorites/bath/collection`, { handles })
  }
}

export default WishListService