import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { parseCountdown } from './countdownUtils'
import cx from '../../../utils/className'

import styles from './Countdown.module.css'

const TYPE = {
  CLOCK: styles.clock,
  TEXT: styles.text
}

const DetailCountdown = forwardRef(({ timestamp, type, children, onFinished, className, timeClass, background, color, displayHour, isPaused = false, extraRenderOptions = [] }, ref) => {
  const [countdown, setCountdown] = useState(timestamp)
  const [originalTimestamp, setOriginalTimestamp] = useState(timestamp)

  if (timestamp && originalTimestamp && timestamp !== originalTimestamp) {
    setCountdown(timestamp)
    setOriginalTimestamp(timestamp)
  }

  useImperativeHandle(ref, () => ({
    reset: (time) => {
      setCountdown(time)
    }
  }))

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isPaused) {
        setCountdown(countdown > 100 ? countdown - 100 : 0)
        if (countdown <= 0 && typeof onFinished === 'function') {
          onFinished()
        }
      }
    }, 100)

    return () => clearTimeout(timer)
  }, [countdown, onFinished, isPaused])

  const { hh, mm, ss, ms } = parseCountdown(countdown)
  let oneTenths = Math.floor(parseInt(ms) % 1000 / 100) > 0 ? Math.floor(parseInt(ms) % 1000 / 100).toString() : "0"

  if (typeof children === 'function') {
    return children({
      hh, mm, ss, oneTenths
    })
  }

  const clockSpanStyle = {}
  const clockSeparatorStyle = {}

  if (background) {
    clockSpanStyle.backgroundColor = background
  }

  if (color) {
    clockSpanStyle.color = color
    clockSeparatorStyle.color = color
  }

  const countdownList = displayHour ===  false ? [mm, ss, oneTenths] : [hh, mm, ss, oneTenths]

  return (
    <div className={cx(type || TYPE.CLOCK, className)}>
      {
        countdownList.map((item, index) => {
          const extraOption = extraRenderOptions[index] || {}
          const { appendText = '', extraClockSpanStyle = {}, extraClockSeparatorStyle = {}, extraTimeClass } = extraOption
          return (
            <div className={styles.spanWrapper} key={index}>
              <span className={cx(styles.time, timeClass, extraTimeClass)} style={{ ...clockSpanStyle, ...extraClockSpanStyle }}>{item}{ appendText && appendText }</span>
              {
                index !== countdownList.length - 1 &&
                <label
                  className={styles.separator}
                  style={{ ...clockSeparatorStyle, ...extraClockSeparatorStyle }}
                >{
                  index === countdownList.length - 2
                  ? '.'
                  : ':'
                }</label>
              }
            </div>
          )
        })
      }
    </div>
  )
})

DetailCountdown.TYPE = TYPE

const CountdownWithMemo = React.memo(DetailCountdown)
CountdownWithMemo.TYPE = DetailCountdown.TYPE

export default CountdownWithMemo
