import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import PlainButton from '../../common/Button/PlainButton'
import styles from './ViewMoreButton.module.css'
import cx from '../../../utils/className'


const ViewMoreButton = props => {
  const plugin = useContext(PluginContext)

  return (
    <div className={cx(styles.wrapper, props.className)}>
      <PlainButton className={styles.btn} onClick={props.onClick}>{plugin.$i18n.transl('common.viewMore')}</PlainButton>
    </div>
  )
}

export default ViewMoreButton
