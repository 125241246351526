import BaseService from '@flamingo_tech/funkgo/src/base/BaseService'

const DEVICE_KEY_ENDPOINT = 'https://callback.flamingo.shop/app-push/api/device-key'
const ANALYTICS_BASE_URI = 'https://callback.flamingo.shop/data/process/event/track'

const TIME_OUT = 10000

export default class CollectService extends BaseService {
  constructor(...args) {
    super(...args)
    this.setTimeout(TIME_OUT)
  }

  requestDeviceKey({ deviceId, mac }) {
    return this.post(DEVICE_KEY_ENDPOINT, {
      deviceId,
      mac,
    })
  }

  updateDeviceInfo(deviceKey, payload) {
    return this.put(DEVICE_KEY_ENDPOINT, {
      ...payload,
      deviceKey,
    })
  }

  collect(payload, context) {
    const dataArray = Array.isArray(payload)
    ? payload
    : [payload]

    // collect has a separate domain
    return this.post(ANALYTICS_BASE_URI, {
      context,
      data: dataArray,
    })
  }

  notifyFBTrack(action, params, eventID) {
    return this.post('https://callback.flamingo.shop/data/process/facebook/track', {
      eventName: action,
      eventData: params
    },
    {
      headers: {
        'X-fb-event-id': eventID || ''
      }
    })
  }
}