import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'

import cx from '../../../utils/className'
import styles from './TrafficDistributionSection.module.css'

const TrafficDistributionSection = props => {
  const plugin = useContext(PluginContext)

  const { productDetail } = props.trafficDistributionMeta || {}

  if (!productDetail) {
    return (<div className={styles.wrapper} />)
  }

  const handleClick = (e) => {
    plugin.$track.clickSubResourceLocator(`Product:DistributionSection`, {
      category: props.productCategory,
      url: productDetail.link
    })
  }
  const isDesktop = plugin.$detector.isDesktop()


  const imageProps = {
    ...productDetail,
    objectFit: 'cover'
  }

  return (
      <div className={cx(styles.wrapper, isDesktop && styles.desktop)}>
      <Image
        lazy
        className={styles.image}
        {...imageProps}
        onClick={handleClick}
        middle
        title={productDetail.description}
      />
    </div>
  )
}

export default TrafficDistributionSection