import React, { useState, useEffect } from 'react'
import DrawModal from '../../../components/Store/Coupon/DrawModal'
import DrawBuoy from '../../../components/Store/Coupon/DrawBuoy'
import { withPlugin } from '@flamingo_tech/funkgo'

export const DRAW_CARD = 'DrawCard'
export const DRAW_BUOY = 'DrawBuoy'

const EVENT_CATEGORY = 'DrawFlow'
const MAX_CHANCES_COUNT = 3

const initConfig = () => {
  return {
    is: [DRAW_CARD],
    showFeature: true
  }
}

const DrawContainer = props => {
  const drawFlowTriedTimesStorage = props.$storage.create('draw_flow_tried_times')
  const _storage = props.$storage.create('draw_passed_arr', { strategy: 'SESSION' })

  const [drawFlow, setDrawFlow] = useState(_storage.getItem(initConfig()))

  const triedTimes = drawFlowTriedTimesStorage.getItem(0)

  const showDrawFlow =  triedTimes < 3 && drawFlow.showFeature

  useEffect(() => {
    if (showDrawFlow && drawFlow.is && drawFlow.is.includes(DRAW_CARD)) {
      props.$track.event(EVENT_CATEGORY, 'view',  triedTimes)
    }
  }, [showDrawFlow, props.$track, drawFlow.is, triedTimes])

  const setSupplementStateAndStorage = params => {
    setDrawFlow(params)
    _storage.setItem(params)
    props.setRuntimeData('draw_passed', params)
  }

  const handleClose = ({ isFinished }) => {
    props.onClose()
    setSupplementStateAndStorage({ ...drawFlow, is: !isFinished ? [DRAW_BUOY] : [] })
  }

  const handleTakeBuoy = () => {
    props.$track.event({
      category: EVENT_CATEGORY,
      action: 'click_buoy',
      nonInteraction: false,
    })
    setSupplementStateAndStorage({ ...drawFlow, is: [DRAW_BUOY, DRAW_CARD] })
  }


  const handleCloseBuoy = () => {
    props.$track.event({
      category: EVENT_CATEGORY,
      action: 'cancel_buoy',
      nonInteraction: false,
    })
    setSupplementStateAndStorage({})
  }

  if (!showDrawFlow) {
    return null
  }

  const handleOnTry = (triedTimes) => {
    drawFlowTriedTimesStorage.setItem(triedTimes)
  }

  return (
    <>
      {
        drawFlow.is && drawFlow.is.includes(DRAW_BUOY) && (
          <DrawBuoy
            {...props}
            chances={MAX_CHANCES_COUNT - triedTimes}
            onConfirm={handleTakeBuoy}
            onClose={handleCloseBuoy}
          />
        )
      }
      {
        drawFlow.is && drawFlow.is.includes(DRAW_CARD) && (
          <DrawModal
            {...props}
            maxChancesCount={MAX_CHANCES_COUNT}
            chances={MAX_CHANCES_COUNT - triedTimes}
            onTry={handleOnTry}
            onClose={handleClose}
            isAutoStart={true}
          />
        )
      }
    </>
  )
}

export default withPlugin(DrawContainer)