import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'

import styles from './withHandGuide.module.css'
import { hoistHocStatic } from '@flamingo_tech/funkgo'
import { rem as px2rem } from '../utils/remUtils'
import cx from '../utils/className'

const withHandGuide = (Component) => {
  const displayName = `withHandGuide(${
    Component.displayName || Component.name
  })`
  const WrappedComponent = class extends PureComponent {

    shade = null

    componentDidMount() {
      if (this.props.showHand && !this.props.withoutShade) {
        this.renderShade()
      }
    }

    componentWillUnmount() {
      this.removeShade()
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        this.props.showHand &&
        this.props.showHand !== prevProps.showHand
      ) {
        this.renderShade()
      } else if (
        !this.props.showHand &&
        this.props.showHand !== prevProps.showHand
      ) {
        this.removeShade()
      }
    }

    renderShade() {
      this.removeShade()
      this.shade = document.createElement('div')
      document.body.appendChild(this.shade)
      ReactDOM.render(
        <div className={styles.shade}></div>,
        this.shade
      )
    }

    removeShade() {
      if (this.shade) {
        ReactDOM.unmountComponentAtNode(this.shade)
        this.shade.parentNode.removeChild(this.shade)
        this.shade = null
      }
    }

    render() {

      return (
        <div className={cx(styles.wrapper, this.props.showHand ? styles.highlight : null)}>
          <Component {...this.props}></Component>
          {this.props.showHand && (
            <div className={styles.handWrapper}>
              <div
                className={styles.hand}
                style={{
                  width: this.props.size || px2rem(38),
                  height: this.props.size || px2rem(38),
                  right: 0,
                  bottom: 0,
                  ...this.props.handStyle
                }}
              ></div>
              {
                this.props.tip && <div className={styles.tip}>{this.props.tip}</div>
              }
            </div>
          )}
        </div>
      )
    }
  }

  hoistHocStatic(WrappedComponent, Component)
  WrappedComponent.displayName = displayName
  return WrappedComponent
}

export default withHandGuide
