import { BaseService } from '@flamingo_tech/funkgo'
import createStorage from '../utils/cacheUtils'

import UserAddressModel from '../models/common/UserAddressModel'

const TIME_OUT = 6000

const COMMON_REQ_CONFIG = {
  timeout: TIME_OUT
}

const withAnonymousUserTokenHeader = (anonymousUserToken) => ({
  timeout: TIME_OUT,
  headers: {
    'X-access-token': anonymousUserToken
  }
})

class UserService extends BaseService {

  statesCache = createStorage('stateList', {
    expire: 1000 * 60 * 60 * 24
  })


  getUserAddresses = (anonymousUserToken) => {
    return this.get('/client/api/v1/addresses', anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken) : COMMON_REQ_CONFIG).then(result => ({
      userAddresses: UserAddressModel.createList(result.list)
    }))
  }

  destructPayload(payload) {
    return {
      addressLine1: payload.addressLine1, //地址line1
      addressLine2: payload.addressLine2, //地址line2
      cityName: payload.cityName,//城市名称 ,
      countryCode: payload.countryCode,//国家编码 ,
      countryName: payload.countryName,//国家名称 ,
      firstName: payload.firstName,//firstName ,
      hasDefault: payload.hasDefault,//是否默认地址 ,
      lastName: payload.lastName,//lastName ,
      stateCode: payload.stateCode,//州/省编码 ,
      stateName: payload.stateName,//州/省名称 ,
      telNumber: payload.telNumber,//手机号 ,
      zipCode: payload.zipCode,//邮编
      taxNo: payload.taxNo, // 税号，根据当地政策非必填
      houseNo: payload.houseNo || ''
    }
  }

  postUserAddress = (payload, anonymousUserToken) => {
    return this.post('/client/api/v1/addresses',
      this.destructPayload(payload),
      anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken) : COMMON_REQ_CONFIG)
  }

  deleteUserAddress = (addressId, anonymousUserToken) => {
    return this.del(`/client/api/v1/addresses/${addressId}`, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken) : COMMON_REQ_CONFIG)
  }

  postPayPalAddress = (payload, anonymousUserToken) => {
    return this.post(
      '/client/api/v1/addresses',
      {
        ...this.destructPayload(payload),
        target: 'paypal'
      },
      anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken) : COMMON_REQ_CONFIG
    ).then((data) => new UserAddressModel(data))
  }

  putUserAddress = (payload, anonymousUserToken) => {
    return this.put(
      `/client/api/v1/addresses/${payload.id}`,
      this.destructPayload(payload),
      anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken) : COMMON_REQ_CONFIG
    ).then((data) => new UserAddressModel(data))
  }

  getAddressStateListByCountry = countryCode => {
    const states = this.statesCache.getItem({})

    if(states[countryCode]) {
      return Promise.resolve(states[countryCode])
    } else {
      return this.get(`/client/api/v1/base/countries/${countryCode}/states`, COMMON_REQ_CONFIG)
      .then(
        result => {
          this.statesCache.setItem(Object.assign({}, states, {
            [countryCode]: result.list
          }))
          return result.list
        }

      )
    }
  }

  getAddressCityListByState = (countryCode, stateCode) => {
    return this.get(`/client/api/v1/base/countries/${countryCode}/states/${stateCode}/cities`, COMMON_REQ_CONFIG)
      .then(result => result.list)
  }

  updateUserInfo = payload => {
    if (payload.birthday) {
      return this.put(`/client/api/v2/users/update`, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        headImageUrl: payload.headImageUrl,
        birthday: payload.birthday
      }, COMMON_REQ_CONFIG)
    } else {
      return this.put(`/client/api/v2/users/update`, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        headImageUrl: payload.headImageUrl
      }, COMMON_REQ_CONFIG)
    }
  }

  updateFavoriteCategories = (categoryIds) => {
    return this.post('/nova/api/user/v1/user-category', { categoryIds })
  }

  unsubscribeAthenaEmail = (email) => {
    return this.post('/content/api/email/unsubscribe', { email })
  }

  getUserVipLevel = () => {
    return this.get('/client/api/v1/user/member/info')
  }

  getUserMonthlyCouponInfo = () => {
    return this.get('/client/api/v1/user/member/check')
  }

  syncAssets({ couponCenterId, shoppingCartId, syncCartFlag }) {
    return this.post('/client/api/v1/user/syncAssets', {
      couponCenterId,
      shoppingCartId,
      syncCartFlag
    })
  }

  syncUserDeviceKey(params) {
    return this.post(`https://callback.flamingo.shop/app-push/api/update/device`, {
      deviceDTOList: [params]
    })
  }

  sendDeleteAccountCode(email) {
    return this.post(`/client/api/v1/user/email/deleteAccountCode`, {
      email
    })
  }

  deleteAccount(email, code) {
    return this.post(`/client/api/v1/user/deleteAccount`, {
      email,
      code
    })
  }

}

export default UserService
