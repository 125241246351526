import React, { useState } from 'react'

import LikeButton from './LikeButton'
import Image from '../common/Image/Image'

import cx from '../../utils/className'
import styles from './GirlsShowCard.module.css'
import { withPlugin } from '@flamingo_tech/funkgo'
import { GirlsShowDetailModal } from './GirlsShowDetailModal'
import PostService from '../../services/PostService'

const GirlsShowCard = props => {
  const isDesktop = props.$detector.isDesktop()

  const postService = new PostService(props.$http)

  const [showDetailModal, setShowDetailModal] = useState(false)
  const [selectedPost, setSelectedPost] = useState({})

  const handleImageClick = ev => {
    props.$track.event('GirlsShowCard', 'click', props.id)
    if (props.onCardClick) {
      props.onCardClick(props.id)
      ev.preventDefault()
    } else {
      if (isDesktop) { // direct popup
        ev.preventDefault()
        setShowDetailModal(true)
        setSelectedPost(props)
        postService.fetchPost(props.id).then(post => {
          setSelectedPost(post)
        })
      }
    }
  }

  return (
    <div className={cx(styles.wrapper, (props.mediaType === 'VIDEO' || props.mediaType === 'IFRAME VIDEO') && styles.withVideoIcon, isDesktop && styles.inDesktop)}>
      <div className={styles.imageWrapper}>
        <Image
          middle
          lazy
          src={props.mainImage}
          className={styles.image}
          placeholder={{ width: props.width,  height: props.height }}
          title={`@${props.userInfo.name} - post`}
          to={{ name: 'GirlsShowDetail', params: { handle: props.id }}}
          onClick={handleImageClick}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{props.desc}</div>
        <div className={styles.bottomBar}>
          <div className={styles.userInfo}>
            <Image
              small
              src={props.userInfo.image}
              className={styles.userIcon}
              title={`avatar - ${props.userInfo.name}`}
              objectFit="cover"
              />
            <h4 className={styles.username}>{props.userInfo.name}</h4>
          </div>
          <LikeButton
            className={styles.likeButton}
            likeNum={props.likeNum}
            hasLiked={props.hasLiked}
            onClick={isLiked => props.onClickLike(props.id, isLiked)}
            />
        </div>
      </div>
      <GirlsShowDetailModal
        hide={!showDetailModal}
        onClose={() => setShowDetailModal(false)}
        {...selectedPost}
      />
    </div>
  )
}

export default withPlugin(GirlsShowCard)
