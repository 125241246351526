import React, { useState, useEffect } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import ProductService from '../../../services/ProductService'
import ProductSection from '../Product/ProductSection'

import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'
import Button from '../../common/Button/Button'

import MultiColumns from '../../common/List/MultiColumns'

import RecommendSmallCard from '../ProductCard/RecommendSmallCard'
import styles from './ProductTPFU.module.css'
import cx from '../../../utils/className'


const WrappedProductCard = props => {
  const { impressionConfig = {} } = props

  const { eventParams = {}, eventName } = impressionConfig

  return (
    <ImpressionObserver eventParams={{ index: props.index, handle: props.handle, ...eventParams  }} eventName={eventName} eventId={props.id}>
      <RecommendSmallCard {...props} ></RecommendSmallCard>
    </ImpressionObserver>
  )
}

const ProductTPFU = props => {
  const { eventCategory, fetchFn, galleryTitle } = props
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [sourceHandle, setSourceHandle] = useState('')


  const productService = new ProductService(props.$http)


  useEffect(() => {
    fetchListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleClickViewMore = () => {
    props.$track.event('Product', 'click_more', eventCategory)
    return fetchListData()
  }

  const fetchListData = () => {
    if (!isFetching) {
      setIsFetching(true)
      return productService[fetchFn](props.productHandle, {
        pageSize: 6,
        page,
      }).then(data => {
        setIsFetching(false)
        const { pageInfo, products, handle } = data
        setPage(page + 1)
        setList([...list, ...products])
        setHasNextPage(pageInfo.hasNextPage)
        setSourceHandle(handle)
      }).catch(() => {
        setIsFetching(false)
        setList(list)
        setSourceHandle('')
      })
    }
    return Promise.resolve()
  }


  const renderListComponent = () => {
    return (
      <div className={styles.listBox}>
        <MultiColumns
          columns={3}
          className={styles.list}
          columnClassName={styles.column}
          childIs={WrappedProductCard}
          childItems={list.map(i => i.product)}
          onReachEnd={hasNextPage && props.onReachEnd}
          childOptions={{
            lazy: true,
            onClick: (product, index) => {
              props.$track.clickProduct(product, index)
              props.$router.navigateToProductPage(product)
              props.$track.clickSubResourceLocator(`Product:${eventCategory}_${product.handle}`, {
                category: props.productCategory,
                index,
                id: product.id,
                handle: product.handle,
                sourceHandle
              })
            },
            eventCategory,
            productCategory: props.productCategory,
            impressionConfig: {
              eventName: `Product:${eventCategory}`,
              eventParams: {
                sourceHandle,
                category: props.productCategory
              }
            },
            onClickQuickAdd: (product, index) => {
              props.$track.clickProduct(product, index, true)
              props.$track.clickSubResourceLocator(`Product:${eventCategory}_${product.handle}`, {
                category: props.productCategory,
                index,
                id: product.id,
                handle: product.handle,
                sourceHandle
              })
            }
          }}
        />

        {
          hasNextPage &&
          <Button onClick={handleClickViewMore} className={styles.viewMore} small>
            {props.$i18n.transl('common.viewMore')}<span className={styles.arrow}>&#xe7b2;</span>
          </Button>
        }
      </div>
    )
  }

  return (
    list.length > 0
    &&
    <ProductSection boxClassName={cx(styles.wrapper, props.className)}>
      <div className={styles.header}>
        <h2 className={styles.title}>{galleryTitle}</h2>
      </div>
      <div className={styles.sliderBox}>
        {renderListComponent()}
      </div>
    </ProductSection>
  )

}

export default withPlugin(ProductTPFU)