import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './BreadCrumbs.module.css'

const BreadCrumbs = withPlugin(props => {

  let subTitle = ''

  navigationTabs.forEach(accordionItem => {
    if (accordionItem.link && activeLink(accordionItem, props.$router)) {
      subTitle = accordionItem.title
      return
    }

    accordionItem.items && accordionItem.items.forEach(tab => {
      if (activeLink(tab, props.$router)) {
        subTitle = accordionItem.title
      }
    })
  })

  return (
    <div className={styles.wrapper}>
      <span onClick={() => props.$router.navigateToHomePage()}>
        {props.$i18n.transl('shell.footer.home')}
      </span>
      {
        subTitle && <span> / {props.$i18n.transl(subTitle)}</span>
      }
    </div>
  )
})

export const activeLink = ({ link, linkMatchedReg }, $router) => {
  if (!$router) {
    return false
  }

  const fullUrl = `${$router.location.pathname}${$router.location.search}`

  if (fullUrl === link) {
    return true
  } else if (linkMatchedReg) {
    return linkMatchedReg.test(fullUrl)
  }

  return false
}

export const navigationTabs = [
  {
    title: 'core.user.myOrders',
    key: 'Orders',
    icon: '\ue64f',
    items: [{
      title: 'core.order.statusDesc.all.tab',
      key: '-1',
      link: '/store/order?tab=-1',
      needLogin: true,
      linkMatchedReg: /(store\/order$)|(store\/order\/)/
    },
    {
      title: 'core.order.statusDesc.pending.tab',
      key: '10',
      link: '/store/order?tab=10',
      needLogin: true
    },
    {
      title: 'core.order.statusDesc.confirmed.tab',
      key: '20',
      link: '/store/order?tab=20',
      needLogin: true
    },
    {
      title: 'core.order.statusDesc.shipped.tab',
      key: '30',
      link: '/store/order?tab=30',
      needLogin: true
    },
    {
      title: 'core.order.statusDesc.review.tab',
      key: '40',
      link: '/store/order?tab=40',
      needLogin: true
    }]
  },
  {
    title: 'terms.policy',
    key: 'Policy',
    icon: '\ue64e',
    items: [{
      title: 'terms.returnAndRefund',
      key: '1',
      container: 'returnAndRefundContainer',
      link: '/store/policy?handle=returns-and-refunds'
    },
    {
      title: 'terms.shippingInfo',
      key: '2',
      container: 'shippingInfoContainer',
      link: '/store/policy?handle=shipping'
    },
    {
      title: 'terms.termsOfService',
      key: '3',
      container: 'termsOfServiceContainer',
      link: '/store/policy?handle=term-of-service'
    },
    {
      title: 'terms.privacy',
      key: '4',
      container: 'privacyContainer',
      link: '/store/policy?handle=privacy-policy'
    }]
  },
  {
    title: 'core.user.myDeals.wishlist',
    key: 'WishList',
    icon: '\ue655',
    link: '/store/wish_list',
    needLogin: true
  }
]

export default BreadCrumbs