import React, { useEffect, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import SupplementCard from './SupplementCard'
import useFn from '../../../hooks/useFn'
import CouponService from '../../../plugins/StorePlugin/services/CouponService'
import queryString from 'query-string'

export const SUPPLEMENT_CARD = 'SupplementCard'
export const SUPPLEMENT_BUOY = 'SupplementBuoy'

const EVENT_CATEGORY = 'Wheel'
const initConfig = () => {
  return {
    is: [SUPPLEMENT_CARD],
    showFeature: false
  }
}

const SupplementWheel = props => {
  const collectionCouponStorage = props.$storage.create('collection_coupons', { strategy: 'SESSION' })
  const beginSupplementStorage = props.$storage.create('begin_supplement_wheel', { strategy: 'SESSION' }) // 是否关闭了10%
  const _storage = props.$storage.create('supplement_wheel_passed_arr', { strategy: 'SESSION' })
  const [supplementWheel, setSupplementWheel] = useState(_storage.getItem(initConfig()))
  const atcTakeFlagStorage = props.$storage.create('atc_take_flag') // atc coupon 领取的flag
  const atcTakeFlag = atcTakeFlagStorage.getItem(false)
  const [loaded, setLoaded] = useState(false)
  const queryAtcCouponListStorage = props.$storage.create('ads_atc_coupon_list', { strategy: 'SESSION' })
  const sourceLocationStorage = props.$storage.create('source_location', { strategy: 'SESSION'})

  const couponHub = props.$store.couponHub

  const setSupplementStateAndStorage = params => {
    setSupplementWheel(params)
    _storage.setItem(params)
    props.setRuntimeData('supplement_wheel_passed', params)
  }

  const handleCartChange = useFn(result => { // 发生加车行为 & 用户没有email 就会弹
    if (!atcTakeFlag  && result.cart && result.cart.currentAddedProductId) {
      setTimeout(() => {
        setSupplementStateAndStorage({ ...supplementWheel, showFeature: true })
      }, 1500)
    }
  })

  const fetchCouponIfHas = useFn(() => {
    const sourceLocation = queryString.parseUrl(sourceLocationStorage.getItem(window.location.href))

    if (sourceLocation.query && sourceLocation.query.atc_ci) {

      const atcCouponList = queryAtcCouponListStorage.getItem()

      if (!atcCouponList) {
        const couponService = new CouponService(props.$http)

        couponService.fetchCoupons(sourceLocation.query.atc_ci.split(','))
        .then(res => {
          const couponList = (res || []).map(coupon => ({
            id: coupon.id,
            title: coupon.meta.name,
            subtitle: coupon.meta.subtitle,
            rule: coupon.meta.displayScope,
            maxDiscountAmount: coupon.meta.maxDiscountAmount,
            effectiveDay: coupon.meta.effectiveDay,
            clientStartDate: coupon.meta.clientStartDate,
            clientEndDate: coupon.meta.clientEndDate
          }))

          queryAtcCouponListStorage.setItem(couponList)
          setLoaded(true)
        })
        .catch(() => {
          setLoaded(true)
        })
      }
    } else {
      setLoaded(true)
    }
  })

  const showSupplement = beginSupplementStorage.getItem(false) &&
  supplementWheel.showFeature &&
  props.addedToCartCoupons.length > 0

  useEffect(() => {
    if (showSupplement && supplementWheel.is && supplementWheel.is.includes(SUPPLEMENT_CARD) && !loaded) {
      fetchCouponIfHas()
      props.$track.event(EVENT_CATEGORY, 'view_atc_coupon')
    }
  }, [showSupplement, props.$track, supplementWheel.is, fetchCouponIfHas, loaded])

  useEffect(() => {
    props.$store.subscribeCart(handleCartChange)

    return () => {
      props.$store.unsubscribeCart(handleCartChange)
    }
  }, [props, handleCartChange])

  if (!showSupplement) {
    return null
  }

  const handleTakeCard = () => {
    props.$track.event({
      category: EVENT_CATEGORY,
      action: 'take_atc_coupon',
      nonInteraction: false,
    })

    couponHub.batchTake(getCoupons()) // 领券
    setSupplementStateAndStorage({})
    atcTakeFlagStorage.setItem(true)
    beginSupplementStorage.setItem(false) // 关闭加车弹弹弹的口子
  }

  const handleCloseCard = () => {
    props.$track.event({
      category: EVENT_CATEGORY,
      action: 'cancel_atc_coupon',
      nonInteraction: false,
    })
    setSupplementStateAndStorage({ ...supplementWheel, is: [SUPPLEMENT_BUOY] })

    const params = {
      type: 'supply'
    }
    props.setRuntimeData('collection_coupons', params)
    collectionCouponStorage.setItem(params)
  }

  const getCoupons = () => {
    const adsCouponList = queryAtcCouponListStorage.getItem()

    if (adsCouponList && adsCouponList.length > 0) {

      return adsCouponList
    } else {
      return props.addedToCartCoupons
    }
  }


  return (
    <>
      {
        loaded && supplementWheel.is && supplementWheel.is.includes(SUPPLEMENT_CARD) && (
          <SupplementCard
            {...supplementWheel}
            setRuntimeData={props.setRuntimeData}
            couponList={[...getCoupons()]}
            onConfirm={handleTakeCard}
            onClose={handleCloseCard}
            enableEmailCollect={props.enableEmailCollect}
          />
        )
      }
    </>
  )
}

export default withPlugin(SupplementWheel)
