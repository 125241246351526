import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './Acceptions.module.css'

import cx from '../../../utils/className'

const PAYMENT_ACCEPT_CARDS = [{
  key: 'paypal',
  className: styles.paypal,
  imageUrl: 'https://img.flamingo.shop/p/show/004a6243a7c84eeaaa3fd7de5147d9ee.png',
  isNonCardPayment: true
},{
  key: 'visa',
  className: styles.visa,
  imageUrl: 'https://img.flamingo.shop/p/show/1240b585d554487f976e1ba4a6314cf0.png'
}, {
  key: 'mastercard',
  className: styles.mastercard,
  imageUrl: 'https://img.flamingo.shop/p/show/bebaaadf744a40dda91f488c181cab70.png'
}, {
  key: 'maestro',
  className: styles.maestro,
  imageUrl: 'https://img.flamingo.shop/p/show/132b3162619c4059ab3e52ef729ac921.png'
}, {
  key: 'ae',
  className: styles.ae,
  imageUrl: 'https://img.flamingo.shop/p/show/87e5039b176942f188fcabfd7d8e6d53.png'
}, {
  key: 'cb',
  className: styles.cb,
  imageUrl: 'https://img.flamingo.shop/p/show/9410b15cb55845398a2b63ba7b5796f6.png'
}, {
  key: 'discover',
  className: styles.discover,
  imageUrl: 'https://img.flamingo.shop/p/show/1feaea1192b64088b17313d264c55e96.png'
}, {
  key: 'dc',
  className: styles.dc,
  imageUrl: 'https://img.flamingo.shop/p/show/a5c650c247dc416d99e1a5e9c5ab6f88.png'
}, {
  key: 'jcb',
  className: styles.jcb,
  imageUrl: 'https://img.flamingo.shop/p/show/4d7dd3555e0d4613a739c506e3b5dd75.png'
}, {
  key: 'klarna',
  className: styles.klarna,
  imageUrl: 'https://img.flamingo.shop/p/show/719179ce47d04d64b1c60e6c8182e797.png',
  isNonCardPayment: true
},{
  key: 'apple',
  className: styles.apple,
  imageUrl: 'https://img.flamingo.shop/p/show/8a600eb05dea424bb3b0be8ee783c76a.png',
  isNonCardPayment: true
}]

const PAYMENT_AFTERPAY = {
  key: 'afterpay',
  className: styles.afterpay,
  imageUrl: 'https://img.flamingo.shop/p/show/850ce08d6ade406f89d83dca95d6e1ed.png',
  isNonCardPayment: true
}

const PAYMENT_GOOGLE =  {
  key: 'google',
  className: styles.google,
  imageUrl: 'https://img.flamingo.shop/p/show/d2d83720d34a4e578e995d33ccc15429.png',
  isNonCardPayment: true
}


export const getPaymentMethods = (countryCode = 'US') => {
  if (countryCode === 'US') {
    const payments = [...PAYMENT_ACCEPT_CARDS]

    for (let i = 0; i < payments.length; i++) {
      if (payments[i].key === 'klarna') {
        payments.splice(i, 0, PAYMENT_AFTERPAY)

        return payments
      }
    }
  } else {
    return PAYMENT_ACCEPT_CARDS
  }
}

export const getAllPaymentMethodsAllPlatform = (countryCode = 'US') => { // for payment method page including app google pay
  const result = [...getPaymentMethods(countryCode), PAYMENT_GOOGLE]
  return result
}


export const SECURITY_CERTIFICATION = [{
  key: 'visa_s',
  width: 45,
  height: 40,
  imageUrl: 'https://img.flamingo.shop/p/show/e60b4a5f656f4a6185eca8ee0b3747f9.png'
},
{
  key: 'mastercard_s',
  width: 99,
  height: 40,
  imageUrl: 'https://img.flamingo.shop/p/show/84fc2f91f74e460c91ae26c8a996fa26.png'
},
{
  key: 'ae_s',
  width: 98,
  height: 40,
  imageUrl: 'https://img.flamingo.shop/p/show/965d451144bd4aa99683e489a834212d.png'
},
{
  key: 'discover_s',
  width: 63,
  height: 40,
  imageUrl: 'https://img.flamingo.shop/p/show/e4adabc0c68c4c6baef01a267332178f.png'
},
{
  key: 'jcb_s',
  width: 63,
  height: 40,
  imageUrl: 'https://img.flamingo.shop/p/show/3023c64c02aa4bb8ba9bca297c3beaf1.png'
},
{
  key: 'dss_s',
  width: 63,
  height: 40,
  imageUrl: 'https://img.flamingo.shop/p/show/29afbe4a90144cbdb234f2d6ce7b13f3.png'
}]

const Acceptions = () => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const { countryCode } = plugin.$site.getSiteInfo()

  if (isDesktop) {
    return (
      <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
        <div className={styles.weAccept}>{plugin.$i18n.transl('core.cart.weAccept')}</div>
        <div className={styles.title}><div className={styles.payIcon} />{plugin.$i18n.transl('core.cart.paymentProtected')}<div className={styles.verification}/></div>
        <div className={styles.imageBox}>
          {
            getPaymentMethods(countryCode).map(item => (
              <div key={item.key} className={cx(styles.card, item.className)} />
            ))
          }
        </div>
      </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, styles.mobile)}>
      <div className={styles.weAccept}>{plugin.$i18n.transl('core.cart.weAccept')}</div>
      <div className={styles.title}><div className={styles.payIcon} />{plugin.$i18n.transl('core.cart.paymentProtected')}<div className={styles.verification}/></div>
      <div className={styles.imageBox}>
        {
          getPaymentMethods(countryCode).map(item => (
            <div key={item.key} className={cx(styles.card, item.className)} />
          ))
        }
      </div>
    </div>
  )
}

export default Acceptions