import React, { useState, useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Search from '../../common/Search/Search'
import SearchPanel from './SearchPanel'

import cx from '../../../utils/className'

import styles from './SearchSelect.module.css'



const SearchSelect = props => {
  const plugin = useContext(PluginContext)
  const [visible, setVisible] = useState(false)
  const [timer, setTimer] = useState(null)

  const [searchText, setSearchText] = useState('')
  const { defaultSearch, historySearches, hotSearches } = props

  const handleSearch = (searchText, ev) => {
    setVisible(false)
    setSearchText(searchText)
    props.onSearch(searchText, ev)
  }

  const handleHotSearchClick = (searchItem, index) => {
    setVisible(false)
    if (searchItem.search) {
      setSearchText(searchItem.search)
    }

    props.onSearchHot(searchItem, index)
  }

  const handleVisible = _visible => {
    clearTimeout(timer)
    if (!_visible) {
      setTimer(setTimeout(() => {
        setVisible(_visible)
      }, 600))
    } else {
      setVisible(_visible)
    }
  }

  const isApp = plugin.$detector.isApp()
  return (
    <div className={cx(styles.root, isApp && styles.wrapper)} onMouseLeave={() => handleVisible(false)} onMouseOver={() => clearTimeout(timer)}>
      <div onClick={() => handleVisible(true)} style={{ width: '100%' }}>
        <Search
          value={searchText}
          onSearch={props.onSearch}
          placeholder={defaultSearch}
        />
      </div>
      {
        visible &&
        <SearchPanel
          className={styles.SearchPanel}
          plugin={plugin}
          onClearSearchHistory={props.onClearSearchHistory}
          historySearches={historySearches}
          hotSearches={hotSearches}
          onHistoryItemClick={handleSearch}
          onHotItemClick={handleHotSearchClick}
        />
      }
    </div>
  )
}

export default SearchSelect
