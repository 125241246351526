import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'

import styles from './PurchaseProtection.module.css'
import cx from '../../../utils/className'

const PurchaseProtectionSection = () => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      <div className={styles.banner}>
        <div className={styles.bannerInfo}>
          <div className={styles.title}><span>{plugin.$i18n.transl('core.security.purchaseProtection.pageCardTitle')}</span></div>
          <div className={styles.subTitle}>{plugin.$i18n.transl('core.security.purchaseProtection.purchaseProtectionDesc')}</div>
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.title}>{plugin.$i18n.transl('core.security.purchaseProtection.pageTitle')}</div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.purchaseProtection.pageDesc')}</div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.purchaseProtection.pageDesc2')}</div>
        <div className={styles.desc}>{plugin.$i18n.transl('core.security.purchaseProtection.pageDesc3')}</div>
      </div>
      <div className={styles.image}><Image src="https://img.flamingo.shop/p/show/fc5475a2a5c3442e8eaa1b7c91e783da.png" /></div>
    </div>
  )
}

export default PurchaseProtectionSection