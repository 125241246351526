import { BaseService } from '@flamingo_tech/funkgo'

import ProductCommentBasicInfoModel from '../models/ProductDetail/ProductCommentBasicInfoModel'
import ProductCommentQueueModel from '../models/ProductDetail/ProductCommentQueueModel'

import { getApiPathWithSortedQuery } from '../utils/requestUtils'
/* --------------------------------- */

class CommentService extends BaseService {
  static getFallbackPreviewCommentInfo() {
    return {
      count: 0,
      comments: [],
      star: 5,
      fallback: true,
    }
  }

  /* ------------------------------------------------- */
  fetchProductCommentInfo(handle) {
    return this.get(`/content/api/v2/product/comment/info/${handle}`).then(data => {
      return new ProductCommentBasicInfoModel(data || {})
    })
  }

  fetchProductCommentQueue(handle, { limit = 10, pageNum = 0, needCount = true, sortType, withPicture, starList, colorList, sizeList }) {
    const basePath = `/content/api/v2/product/comment/${handle}`
    const sortedQuery = ['offset', 'needCount', 'limit', 'sortType', 'withPicture', 'starList', 'colorList', 'sizeList']
    const queryParams = {
      offset: limit * pageNum,
      needCount,
      limit,
      sortType,
      withPicture,
      starList,
      colorList,
      sizeList
    }
    let apiPath = getApiPathWithSortedQuery(basePath, sortedQuery, queryParams)

    return this.get(apiPath).then(
      data => new ProductCommentQueueModel(data || {})
    )
  }

  fetchProductPreviewCommentInfo(handle, { limit }) {
    return Promise.all([
      this.fetchProductCommentInfo(handle),
      this.fetchProductCommentQueue(handle, { limit })
    ]).then(([commentBasicInfo, commentQueue]) => {
      return {
        ...commentBasicInfo,
        comments: commentQueue.comments || [],
      }
    })
  }

  commitProductComment(handle, { star, content, images}) {
    return this.post('/content/api/v2/comments', {
      handle,
      star,
      content,
      images: images.map(image => image.src ? image.src : image)
    })
  }

  fetchTranslateInfo(commentId) {
    return this.get(`/content/api/v2/product/comment/translate/${commentId}`)
  }
}

export default CommentService
