import React　from 'react'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import {
  useShare,
  useHeaderProps,
} from './hooks/pageShellHooks'

import AppHeader from '../Header/AppHeader'
import FreeShippingBanner from '../../Widget/FreeShippingBanner/FreeShippingBanner'
import styles from './AppPageShell.module.css'

const AppPageShell = props => {
  const headerProps = useHeaderProps(props)
  const share = useShare(props)

  const {
    $router,
    children,
    showShippingBar,
  } = props

  const handleShareClick = share
  const handleCartClick = () => $router.navigate({
    name: 'Cart'
  })

  const handleBackClick = () => $router.goBack()
  const handleHomeClick = () => $router.navigateToHomePage()


  return (
    <section className={styles.wrapper}>
      <section className={styles.page}>
        <AppHeader
          {...headerProps}
          onCartClick={handleCartClick}
          onShareClick={handleShareClick}
          onHomeClick={handleHomeClick}
          onBackClick={handleBackClick}
        ></AppHeader>
        {
          showShippingBar && <FreeShippingBanner />
        }
        <main className={styles.content}>
          {children}
        </main>
      </section>
    </section>
  )
}

export default withCartAndPlugins(AppPageShell)
