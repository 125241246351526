import React from 'react'

import LoadingPage from '../../../common/LoadingPage/LoadingPage'
import styles from './Loading.module.css'

const Loading = (props) => {
  return (
    <>
    {
      props.isListFetching
        ?　<LoadingPage className={styles.loadingList}></LoadingPage>
        :　<LoadingPage className={styles.loadingPage}></LoadingPage>
    }
    </>
  )
}

export default Loading
