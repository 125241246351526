import React, { useState, useRef, useEffect } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import { createPortal } from 'react-dom'

import styles from './ColorSwatch.module.css'
import cx from '../../../utils/className'
import ColorPicker from '../../common/ColorPicker/ColorPicker'
import Slider from '../../common/Slider/Slider'
import useFn from '../../../hooks/useFn'

const COLOR_ITEM_SIZE_PC = 22
const COLOR_ITEM_RIGHT_PC = 18

const ColorSwatchMore = props => {
  const targetRef = props.targetRef

  if (!targetRef) {
    return null
  }

  const boundingClientRect = targetRef.current && targetRef.current.getBoundingClientRect()
  const { x, y } = boundingClientRect

  let classNames = [styles.colorPopover, props.columns > 5 && styles.wrap]

  if (window.innerWidth - x < 200) {
    classNames.push(styles.toLeft)
  } else if (x < 200) {
    classNames.push(styles.toRight)
  }

  return (
    createPortal((
      <div className={cx(...classNames)} style={{ top: y + window.scrollY, left: x }}>
        <div className={styles.content}>{props.children}</div>
        <div className={styles.arrow} />
      </div>
    ), document.body)
  )
}

export const ColorSwatchSlider = withPlugin(props => {
  const [current, setCurrent] = useState(0)
  const [sliderIndex, setSliderIndex] = useState(0)
  const sliderRef = useRef()
  const { colorSwatchList = [] } = props.product || {}
  const size = 4

  const handleClick = (index) => {
    props.handleChangePic(colorSwatchList[index].productImageUrl)
    setCurrent(index)
  }

  const renderColorList = list => {
    return list.map((i, index) => {
      return (
        <div className={cx(styles.colorPickerBox, styles.smallPicker)} key={i.optionValue} onClick={() => handleClick(index)}>
          <ColorPicker colorSwatchItem={i} active={current === index} className={cx(styles.colorPicker)} />
        </div>
      )
    })
  }

  const afterChange = (index) => setSliderIndex(index)

  const sliderSettings = {
    slidesToShow: size,
    slidesToScroll: size,
    afterChange: afterChange
  }

  return (
    <div style={{ maxWidth: 130 }} className={cx(styles.colorSlider, props.className)}>
      <Slider {...sliderSettings} ref={sliderRef} className={styles.sliderComponent}>
        {renderColorList(colorSwatchList)}
      </Slider>
      {
        colorSwatchList.length > size && (sliderIndex + size < colorSwatchList.length) && <span className={styles.next} onClick={() => sliderRef.current.slickNext()}>&#xe612;</span>
      }
    </div>
  )
})

export const ColorSwatch = withPlugin(props => {
  const [current, setCurrent] = useState(0)
  const colorRef = useRef(null)
  const [size, setSize] = useState(null)
  const [more, setMore] = useState(false)
  const timerRef = useRef()
  const timerRef2 = useRef()
  const isDesktop = props.$detector.isDesktop()
  const moreRef = useRef()

  const { colorSwatchList = [] } = props.product || {}

  const renderColorList = (beginIndex, list) => {
    const handleClick = (index) => {
      props.handleChangePic(colorSwatchList[index].productImageUrl)
      setCurrent(index)
    }

    return list.map((i, index) => {
      return (
        <ColorPicker
          key={i.optionValue}
          onClick={() => handleClick(beginIndex + index)}
          colorSwatchItem={i}
          active={current === (beginIndex + index)}
          className={cx(styles.colorPickerBox, styles.colorPicker, isDesktop && styles.isDesktop)}
        />
      )
    })
  }

  const resizeHandler = useFn(() => {
    clearTimeout(timerRef2.current)
    if (colorRef && colorRef.current && colorRef.current.clientWidth) {
      const clientWidth = colorRef.current.clientWidth
      timerRef2.current = setTimeout(() => {
        setSize(Math.floor(clientWidth / (COLOR_ITEM_SIZE_PC + COLOR_ITEM_RIGHT_PC)))
      }, 500)
    } else {
      setSize(0)
    }
  })

  useEffect(() => {
    resizeHandler()
  }, [resizeHandler])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', resizeHandler)
      }
    }
  }, [resizeHandler])

  const handleToggle = more => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setMore(more)
    }, 200)
  }

  const clearHideTimer = () => {
    clearTimeout(timerRef.current)
  }

  const renderMore = () => {
    if (size > 0 && colorSwatchList.length && colorSwatchList.length > size) {
      return (
        <span className={styles.more}>
          <span
            ref={moreRef}
            className={styles.moreCount}
            onMouseOver={() => handleToggle(true)}
            onMouseLeave={() => handleToggle(false)}
          >
            +{colorSwatchList.length - size}
          </span>
          {
            more && (
              <div onMouseOver={clearHideTimer} onMouseLeave={() => handleToggle(false)}>
                <ColorSwatchMore targetRef={moreRef} columns={colorSwatchList.length - size}>
                  {renderColorList(size, colorSwatchList.slice(size, colorSwatchList.length))}
                </ColorSwatchMore>
              </div>
            )
          }
        </span>
      )
    }
  }


  return (
    <div ref={colorRef} className={cx(styles.colors, props.className)}>
      {renderColorList(0, colorSwatchList.slice(0, size))}
      {renderMore()}
    </div>
  )
})
