import React, { useState, useEffect, useCallback } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import { withModal } from '../../common/Modal/Modal'

import Button from '../../common/Button/Button'
import {
  getPromotionDiscountText
} from '../../../utils/Store/promotionUtils'

import styles from './CiModal.module.css'


const CiModal = props => {
  const ciPopupStorage = props.$storage.create(`ci_popup_${props.ci}`, { expire: 1000 * 60 * 60 * 2 }) // 2h

  const [show, setShow] = useState(false)
  const [coupon, setCoupon] = useState(null)

  const handleCouponChanged = useCallback(() => {
    const coupon = props.$store.couponHub.getAvailableCoupon(props.ci)
    setCoupon(coupon)
  }, [props.$store.couponHub, props.ci])

  useEffect(() => {
    const couponHub = props.$store.couponHub

    couponHub.subscribe(handleCouponChanged)

    return () => {
      couponHub.unsubscribe(handleCouponChanged)
    }

  }, [props.$store.couponHub, handleCouponChanged])


  if (!ciPopupStorage.getItem(false)) { // first popup
    ciPopupStorage.setItem(true)
    setShow(true)

    const coupon = props.$store.couponHub.getAvailableCoupon(props.ci) // 此时可能已经拿到
    setCoupon(coupon)
  }

  return (
    <Modal
      hide={!show || !coupon}
      coupon={coupon}
      $i18n={props.$i18n}
      $site={props.$site}
      $track={props.$track}
      onClose={() => setShow(false)}
    />
  )
}


// 10% 优惠券卡片
const Modal = withModal(props => {

  const handleSubmit = () => {
    props.$track.event('CiModal', 'confirm', props.coupon.id)
    props.onClose()
  }

  const handleClose = () => {
    props.$track.event('CiModal', 'close', props.coupon.id)
    props.onClose()
  }

  useEffect(() => {
    props.$track.event('CiModal', 'view', props.coupon.id)
  }, [props.$track, props.coupon.id])

  return (
    <div className={styles.wrapper}>
      <div className={styles.closeBtn} onClick={handleClose}>&#xe60e;</div>
      <div className={styles.content}>
        <div className={styles.title}>Your Coupon Is About To Expire!</div>
        <div className={styles.discount}>{getPromotionDiscountText(props.coupon.meta, props.$i18n, props.$site)}</div>
        <div className={styles.subtitle}>Hurry! This offer ends in 48 hours!</div>
      </div>
      <Button
        className={styles.submitBtn}
        onClick={handleSubmit}>
        USE COUPON NOW
      </Button>
    </div>
  )
}, { showCloseButton: false, disableCoverClick: true })

export default withPlugin(CiModal)