import React, { PureComponent, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import { isVariantsDisabledByVariantOptions } from '../../../utils/Store/productUtils'
import cx from '../../../utils/className'
import styles from './ProductSpecSection.module.css'
import Button from '../../common/Button/Button'
import ColorPicker from '../../common/ColorPicker/ColorPicker'
import { ProductSizeGuideCard, ProductSizeGuidePop } from './ProductSizeGuideTips'

const DetailColorSwatch = (props) => {
  return (
    <div
      onClick={props.disabled  ? () => {} : () => props.onClick(props)}
    >
      <ColorPicker colorSwatchItem={props.colorSwatchItem} active={props.selected} className={cx(props.isDesktop ? styles.colorImg : styles.smallColorImg, props.disabled && styles.disabled)} />
    </div>
  )
}

const getMappedSize = (data, country) => {
  let result = {}
  const sizeCountries = ['US', 'UK', 'EU']
  const code = sizeCountries.indexOf(country) > -1 ? country : 'EU'
  const info = code === 'US' ?  data.sizeInchInfo : data.sizeCmInfo

  if (info) {
    const sizeIndex = info[0] && info[0].indexOf(code)

    if (sizeIndex && sizeIndex !== -1) {
      result = info.reduce((total, current) => {
        return {
          ...total,
          [current[0]]: current[sizeIndex].replace('-', '/').trim()
        }
      }, {})
    }
  }

  return result
}

const renderSize = (data, originValue, country, category) => {
  if (data) {
    const mappedSize = getMappedSize(data, country)

    if (category !== 'Two Piece Sets' && mappedSize[originValue]) {
      return `${originValue}(${mappedSize[originValue]})`
    }

    return originValue
  }

  return originValue
}

const VariantOptionBtn = props => {
  const [active, setActive] = useState()
  const { country } = props.$site.getSiteInfo()

  const handleSizeMouseOver = () => {
    if (!active) {
      setActive(props.value)
    }
  }

  const handleSizeMouseLeave = () => {
    setActive('')
  }

  const hoverSettings = {}

  if (!props.disabled) {
    hoverSettings.onMouseOver = handleSizeMouseOver
    hoverSettings.onMouseLeave = handleSizeMouseLeave
  }

  return (
    <div
      className={cx(styles.variantOption, props.selected ? styles.selected : '', props.disabled && styles.disabled)}
      {...hoverSettings}
    >
      <Button
        onClick={() => props.onClick(props)}
        className={cx(styles.optionBtn, props.disabled && !props.isVariantReady ? styles.uiEnabled : '')}
        disabled={props.disabled}
        plain
      >{renderSize(props.sizeChartTemplate, props.value, country, props.category)}</Button>
      {
        active && (
          <ProductSizeGuidePop sizeGuideMeta={props.sizeGuideMeta} selectedSize={active} />
        )
      }
    </div>
  )
}

const RenderVariantBtn = props => {
  const { name = {}, variantGroup, colorSwatchList, category } = props
  const list = Object.keys(variantGroup)

  const renderItems = () => {
    return list.map((value, j) => {
      const colorSwatchItem = getColorSwatchItem(colorSwatchList, value)
      const { selected, disabled } = variantGroup[value]
      const { country } = props.$site.getSiteInfo()

      return (
        <div
        key={j}
        className={cx(styles.radioItem, selected && styles.selected, disabled && styles.disabled)}
        onClick={disabled ? () => {} : () => props.onClick({
          name,
          value
        })}>
          {
            name === 'Color'
              ? <ColorPicker colorSwatchItem={colorSwatchItem} active={selected} className={props.isDesktop ? styles.colorImg : styles.smallColorImg} />
              : <div className={styles.radioSize}>{renderSize(props.sizeChartTemplate, value, country, category)}</div>
          }
        </div>
      )
    })
  }

  return (
    <div className={styles.radioList}>
      {renderItems()}
    </div>
  )
}

const generateVariantListMap = (variantOptions, selectedVariants, variants) => {
  const map = {}
  variantOptions.forEach(variantOption => {
    const variantOptionMap = map[variantOption.name] = {}
    variantOption.values.forEach(value => {
      // filter out option value whose related variants neither are available for sale
      const disabled = isVariantsDisabledByVariantOptions({
        [variantOption.name]: value
      }, variants)
      if (!disabled) {
        variantOptionMap[value] = {
          selected: selectedVariants[variantOption.name] === value,
          disabled: isVariantsDisabledByVariantOptions({
            ...selectedVariants,
            [variantOption.name]: value
          }, variants)
        }
      }
    })
  })
  return map
}

const getColorSwatchItem = (colorSwatchList, variantOption) => {
  return colorSwatchList.filter(i => i.optionValue === variantOption)[0]
}

class ProductSpecSection extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      selectedVariants: props.selectedVariants
    }
  }

  componentDidUpdate(prevProps) {
    if (Object.keys(prevProps.selectedVariants).length === 0 && Object.keys(this.props.selectedVariants).length !== 0) {
      this.setState({
        selectedVariants: this.props.selectedVariants
      })
    }
  }


  isUncheckSupportedByCurrentOption = (optionName, product) => {
    const { options } = product

    const optionNameList = options.map(option => option.name)


    if (options.length > 1 && optionNameList.indexOf(optionName) > 0) { // multi options and not the first option
      return true
    } else {
      return false
    }
  }

  handleVariantOptionClick = (option) => {
    const { name, value } = option
    const selectedVariants = Object.assign({}, this.state.selectedVariants)

    if (selectedVariants[name] && selectedVariants[name] === value) {
      if (this.isUncheckSupportedByCurrentOption(name, this.props.product)) {
        delete selectedVariants[name]
      } else {
        return
      }
    } else {
      selectedVariants[name] = value
    }

    this.setState({
      selectedVariants: selectedVariants
    })

    this.props.onVariantChanged(selectedVariants)
  }

  render() {
    if (!this.props.selectedVariants) {
      return null
    }
    const { product } = this.props
    const variantListMap = generateVariantListMap(product.options, this.state.selectedVariants, product.variations)
    const isDesktop = this.props.$detector.isDesktop()

    const {
      sizeChart = {}
    } = product.meta
    const { colorSwatchList, category } = product

    return (
      <div className={cx(styles.specWrapper, isDesktop && styles.inDesktop, this.props.className)}>
        {
          this.props.renderLink &&
          <div className={styles.toDetail}>
            {
              this.props.renderLink()
            }
          </div>
        }
        {
          Object.keys(variantListMap).map((variantName, i) =>
            <div key={i} className={cx(styles.optionsWrapper, isDesktop ? styles.inDesktop : null)}>
              <div className={styles.variantName}>
                {product.options.filter(({ name }) => name === variantName)[0].displayName}: <span className={styles.selectedValue}>{this.state.selectedVariants[variantName]}</span>
                {variantName === 'Size' && this.props.renderSizeLink && ((sizeChart && sizeChart.imgUrl) || (sizeChart && sizeChart.template)) && <div className={styles.sizeGuide} onClick={this.props.showSizeChartModal}>
                  <span>{this.props.$i18n.transl('core.product.sizeGuide')}</span>
                </div>}
              </div>
              {
                isDesktop
                  ? (
                      i === 0 ?
                      <div className={styles.variantColorOptions}>
                      {
                        Object.keys(variantListMap[variantName]).map((variantOption, j) => {
                          return <DetailColorSwatch colorSwatchItem={getColorSwatchItem(colorSwatchList, variantOption)} key={j} isDesktop={isDesktop} name={variantName} value={variantOption} {...variantListMap[variantName][variantOption]} isVariantReady={true} onClick={this.handleVariantOptionClick} />
                        })
                      }
                    </div>
                      :
                      <div className={styles.variantOptions}>
                        {
                          Object.keys(variantListMap[variantName]).map((variantOption, j) => {
                            return <VariantOptionBtn $site={this.props.$site} sizeChartTemplate={this.props.sizeChartTemplate} key={j} sizeGuideMeta={product.sizeGuideMeta} isDesktop={isDesktop} name={variantName} value={variantOption} {...variantListMap[variantName][variantOption]} isVariantReady={true} category={category} onClick={this.handleVariantOptionClick} />
                          })
                        }
                      </div>
                  )
                  :
                  <>
                    <RenderVariantBtn
                    colorSwatchList={colorSwatchList}
                    isDesktop={isDesktop}
                    variantGroup={variantListMap[variantName]}
                    name={variantName}
                    $site={this.props.$site}
                    $track={this.props.$track}
                    sizeChartTemplate={this.props.sizeChartTemplate}
                    category={category}
                    onClick={this.handleVariantOptionClick} />
                    {
                      variantName === 'Size' && !isDesktop && this.props.selectedVariants && this.props.selectedVariants.Size && (
                        <ProductSizeGuideCard sizeGuideMeta={product.sizeGuideMeta} selectedSize={this.props.selectedVariants.Size} onShowSizeChartModal={this.props.showSizeChartModal} />
                      )
                    }
                  </>

              }

            </div>
          )
        }
        {
          product.meta && product.meta.feature && product.meta.feature.length &&
          <div className={cx(styles.optionsWrapper, isDesktop ? styles.inDesktop : null)}>
            <div className={styles.variantName}>{this.props.$i18n.transl('core.product.length')}: <span className={styles.selectedValue}>{product.meta.feature.length}</span></div>
          </div>
        }

      </div>
    )
  }
}

export default withPlugin(ProductSpecSection)
