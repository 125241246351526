import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import Link from '../../common/Link/Link'
import styles from './ProductSection.module.css'
import cx from '../../../utils/className'


const ProductSectionHeader = ({ title, link, isDesktop, $i18n, className, showSeparator = false, separatorClassName = null }) => {
  return (
    <div className={cx(styles.header, className)}>
      {
        (showSeparator || !isDesktop) && <div className={cx(styles.separator, separatorClassName)}/>
      }
      {
        link
        ? (<Link className={cx(styles.title, isDesktop && styles.isDesktop)} to={link}><h2 className={styles.heading}>{$i18n.transl(title)}</h2></Link>)
        : (<span className={cx(styles.title, isDesktop && styles.isDesktop)} to={link}>{$i18n.transl(title)}</span>)
      }
      {
        (showSeparator || !isDesktop) && <div className={cx(styles.separator, separatorClassName)}/>
      }
    </div>
  )
}

const ProductSection = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <section className={cx(styles.wrapper, !props.sectionTitle && !props.minSliderWrapper ? styles.hasMargin : '', isDesktop && styles.inDesktop, props.minSliderWrapper && styles.smallSize, props.boxClassName || null )}>
      { props.sectionTitle &&  (
        <ProductSectionHeader
          title={props.sectionTitle}
          link={props.sectionLink}
          isDesktop={isDesktop}
          $i18n={plugin.$i18n}
          className={props.className}
          showSeparator={props.showSeparator}
          separatorClassName={props.separatorClassName}
        />
      ) }
      {props.children}
    </section>
  )
}

export default ProductSection
