import React, { useState } from 'react'
import CartAddOnAndCoupon from './CartAddOnAndCoupon'

import Button from '../../common/Button/Button'
import CartItem from './CartItem'
import { withPlugin } from '@flamingo_tech/funkgo'
import SelectIcon from '../CheckOut/SelectIcon'
import Loading from '../../Game/GameList/subComponents/Loading'
import PriceSummaryList from './PriceSummaryList'
import BraintreePaypalButton from '../CheckOut/BraintreePaypalButton'
import AtcCouponList from './AtcCouponList'
import NumChanger from '../../common/NumChanger/NumChanger'
import { CheckoutSecure, PurchaseProtection } from '../../common/ShoppingSecurity/ShoppingSecurity'
// import withLoginCheck from '../../../hooks/withLoginCheck'
// import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'
import CartPageSoldOutList from './CartPageSoldOutList'

import cx from '../../../utils/className'

import styles from './CartPageMobileContent.module.css'
const EVENT_CATEGORY = 'Cart'

/* -------------------------------------------- */

const CartPageMobileContent = props => {
  const [numberChangeDisabled, setNumberChangeDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const {
    lineItems,
    onCartItemQuantityChange,
    onCheckout,
    onClearExpireItems,
    cartRecommendationRef = null,
  } = props

  const saleableLineItems = []
  const unsaleableLineItems = []

  lineItems.forEach(lineItem => {
    if (lineItem.saleable) {
      saleableLineItems.push(lineItem)
    } else {
      unsaleableLineItems.push(lineItem)
    }
  })

  const selectCart = (lineItem, isSelectAll) => {
    if (loading) {
      return
    }

    setLoading(true)
    props.onCartItemSelect(lineItem, isSelectAll)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  const handleTakeCoupon = () => {
    setNumberChangeDisabled(false)
  }

  const handleNumChangeFinish = () => {
    setNumberChangeDisabled(true)
  }

  // const WithCheckSyncBanner = withLoginCheck(params => params.render(), {
  //   clickLogin: () => props.$track.event(EVENT_CATEGORY, `click_sync_cart`, 'top'),
  //   loginCallback: () =>  props.$track.event(EVENT_CATEGORY, `login_success`, 'top')
  // })

  // const WithCheckSync = withLoginCheck(params => params.render(), {
  //   clickLogin: () => props.$track.event(EVENT_CATEGORY, `click_sync_cart`, 'bottom'),
  //   loginCallback: () =>  props.$track.event(EVENT_CATEGORY, `login_success`, 'bottom')
  // })

  const isSelectAll = saleableLineItems.filter(i => i.selectedFlag).length === saleableLineItems.length
  // const hasLogin = props.$user.hasLogin()

  return (
    <div className={styles.wrapper}>
      {
        loading && <div className={styles.loading}><Loading /></div>
      }
      {/* {
        !hasLogin && (
          <ImpressionObserver eventName='cart_login' eventId='cart_login'>
            <div className={styles.syncTip}>{props.$i18n.transl('core.cart.notLoseYourItems')}
              <div className={styles.signBtn}><WithCheckSyncBanner render={() => <div>{props.$i18n.transl('core.login.signIn')}</div>} /></div>
            </div>
          </ImpressionObserver>
        )
      } */}
      <CartAddOnAndCoupon
        showAddOn={props.onClickAddOnCard}
        addOnsForFreeShippingAmount={props.addOnsForFreeShippingAmount}
        appliedCoupon={props.appliedCoupon}
      />
      <AtcCouponList {...props} onTakeCard={handleTakeCoupon} eventCategory={EVENT_CATEGORY} />
      <div className={styles.cartItemTitle}>{props.$i18n.transl('core.checkout.items')}{`(${props.cartLineItemCount})`}</div>
      <div className={styles.cartItems}>
        {
          saleableLineItems.map((lineItem, i) =>
            <div className={styles.cartItem} key={i}>
              <SelectIcon onClick={() => selectCart(lineItem)} selected={lineItem.selectedFlag} type={SelectIcon.TYPE.CHECKBOX} className={styles.selectIcon}/>
              <CartItem
                {...lineItem}
                onChange={quantity => onCartItemQuantityChange({ lineItem, quantity })}
                className={styles.cartInnerItem}
              />
            </div>
          )
        }
      </div>
      <CartPageSoldOutList
        $i18n={props.$i18n}
        onClearExpireItems={onClearExpireItems}
        unsaleableLineItems={unsaleableLineItems}
      />
      {/* {
        !hasLogin && (
          <div className={styles.syncItemTip}>
            <div>{props.$i18n.transl('core.cart.cannotFindYourName')}</div>
            <WithCheckSync render={() => <span>{props.$i18n.transl('core.cart.signInToCheckYourCart')}</span>} />
          </div>
        )
      } */}
      {
        !!props.checkoutItemCount && <div className={styles.priceBox}>
          <PriceSummaryList
            {...props}
            mode={PriceSummaryList.MODE.CART}
            freight={props.shippingFee}
            $i18n={props.$i18n}
            showDiscountSelect={true}
            onClickCouponEntrance={props.onClickCouponEntrance}
          />
        </div>
      }
      <CheckoutSecure
        className={styles.securityCertification}
        titleClassName={styles.cardTitle}
        groupClassName={styles.cardGroup}
      />
      <PurchaseProtection
        className={styles.purchaseProtection}
        titleClassName={styles.cardTitle}
        descClassName={styles.safeDesc}
      />
      <div ref={cartRecommendationRef}>{props.renderCartRecommendations()}</div>
      <div className={styles.buttonGroup}>
        <div className={cx(styles.priceRow, styles.orderTotal)}>
          <div className={styles.selectAll}>
            <SelectIcon selected={isSelectAll} onClick={() => selectCart('', !isSelectAll)} type={SelectIcon.TYPE.CHECKBOX} className={styles.selectIcon}/>
            <span>{props.$i18n.transl('core.order.statusDesc.all.tab')}</span>
          </div>
          <div>
            <div className={styles.subTotalBox}>
              <span className={styles.title}>{props.$i18n.transl('core.checkout.total')}</span>
              <span className={cx(styles.price, styles.total)}>
                <NumChanger
                  price={props.totalPrice}
                  displayPrice={props.displayTotalFee}
                  disabled={numberChangeDisabled}
                  onFinish={handleNumChangeFinish}
                />
              </span>
            </div>
            {
              !!props.totalSaveFee && props.totalSaveFee > 0 && <div className={styles.saveTotal}>{props.$i18n.transl('core.cart.youSave')} {props.displayTotalSaveFee}</div>
            }
          </div>
        </div>

        <div className={styles.btnBox}>
          {
            props.showPaypal && props.checkoutItemCount !== 0 &&
            <BraintreePaypalButton
              className={styles.payBtn}
              onPay={props.onPayWithPaypal}
              style={{
                height: 45
              }}
              onTrack={props.onTrack}
              totalFee={props.paypalTotalFee}
              {...props}
            ></BraintreePaypalButton>
          }
          <Button className={cx(styles.checkoutBtn, props.showPaypal && props.checkoutItemCount !== 0 && styles.fitButton)} onClick={onCheckout}>{props.$i18n.transl('core.cart.checkout')}{`(${props.checkoutItemCount})`}</Button>
        </div>
      </div>
    </div>
  )
}

export default withPlugin(CartPageMobileContent)
