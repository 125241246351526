const PENDING_TRACKINGS = [{
  status: "Arrived at Flamingo's Sort Facility",
  details: ""
}, {
  status: "Departed from Flamingo's Sort Facility",
  details: ""
}, {
  status: "Arrived at Airport of Destination, Custom Clearance in processing",
  details: ""
}]

const TWO_DAYS_MILLISECONDS = 1000 * 60 * 60 * 24 * 2

/*
  As for USPS, tracking status is under 'Shipping Label Created' for 8 - 10 days
  To relieve customers' worries, we prepare mock tracking information every 2 days
*/
export const prepareOrderTrackingDetails = trackInfo => {
  const { trackCompany, trackDetails = [] } = trackInfo
  let preparedTrackDetail = trackDetails

  if (trackCompany === 'USPS') {
    const latestTrackDetail = trackDetails[0]

    if (latestTrackDetail && (latestTrackDetail.status || '').toLowerCase().indexOf('shipping label created') > -1) {
      const latestTrackDate = new Date(latestTrackDetail.displayTime)

      if (!isNaN(latestTrackDate.valueOf())) {
        preparedTrackDetail = [...getPendingTrackingsByDate(latestTrackDate), ...trackDetails]
      }
    }
  }

  return preparedTrackDetail
}


const getPendingTrackingsByDate = trackDate => {
  const exceedTimeStamp = new Date() - trackDate

  let pendingTrackings = []

  if (exceedTimeStamp > 0) {
    const trackingsLength = exceedTimeStamp / TWO_DAYS_MILLISECONDS

    pendingTrackings = PENDING_TRACKINGS.slice(0, trackingsLength).map((tracking, index) => ({
      ...tracking,
      displayTime: getFormattedDisplayTime(new Date(trackDate.getTime() + TWO_DAYS_MILLISECONDS * (index + 1)))
    })).reverse()
  }

  return pendingTrackings
}


const getFormattedDisplayTime = date => {
  const month = date.getMonth()
  const day = date.getDate()
  const year = date.getFullYear()

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"]

  return `${monthNames[month]} ${day}, ${year}`
}