import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './DrawBuoy.module.css'

const DrawBuoy = withPlugin(props => {

  return (
    <div className={styles.couponBuoy} id="draw-buoy-element">
      <div className={styles.content} onClick={() => props.onConfirm(true)}>
        <span className={styles.chances}>x{props.chances}</span>
      </div>
      <div className={styles.close} onClick={() => props.onClose()}>&#xe60e;</div>
    </div>
  )
})



export default DrawBuoy
