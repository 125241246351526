import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Link from '../../common/Link/Link'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import { getCartLineItemCount } from '../../../utils/cartUtils'

import styles from './CartEntry.module.css'

const CartEntry = props => {
  const plugin = useContext(PluginContext)

  const cart = props.cart
  const cartCount = (cart && getCartLineItemCount(cart) !== 0) ? getCartLineItemCount(cart): undefined

  const handleClick = ev => {
    ev.stopPropagation()
    plugin.$track.event('CartEntrance', 'enter_from_Entry')
  }

  return (
    <>
      <Link
        to="Cart"
        className={styles.entranceIcon}
        onClick={handleClick}
      >
      {
        cartCount &&
        <span className={styles.count}>{cartCount}</span>
      }
      </Link>

    </>

  )
}

export default withCartAndPlugins(CartEntry, { withClient: false })