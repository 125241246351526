import React, { PureComponent } from 'react'

import withUserAndPlugins from '../../hooks/withUserAndPlugins'
import withAppState from '../../hooks/withAppState'

import Shell from '../Shell/PageShellContainer'

import ProfilePage from '../../components/User/ProfilePage'

import OrderService from '../../services/OrderService'
import UserService from '../../services/UserService'

const ORDER_SECTION = {
  title: 'core.user.myOrders',
  link: 'https://m.flamingo.shop/store/order',
  items: [{
    statusKey: 'pending',
    image: 'https://img.flamingo.shop/p/show/ce11836d5a0d489db8c47c55b7f30a4e.png',
    title: 'core.order.statusDesc.pending.tab',
    link: 'https://m.flamingo.shop/store/order?orderStatus=10'
  }, {
    statusKey: 'processing',
    image: 'https://img.flamingo.shop/p/show/6e12ec097f9a4893ae385c5cebf71453.png',
    title: 'core.order.statusDesc.confirmed.tab',
    link: 'https://m.flamingo.shop/store/order?orderStatus=20'
  }, {
    statusKey: 'shipped',
    image: 'https://img.flamingo.shop/p/show/fb8550e64fab4f2e9aeaeb863179a0a3.png',
    title: 'core.order.statusDesc.shipped.tab',
    link: 'https://m.flamingo.shop/store/order?orderStatus=30'
  }, {
    statusKey: 'review',
    image: 'https://img.flamingo.shop/p/show/4264d2b7e89d4858a47930c9a92c57d4.png',
    title: 'core.order.statusDesc.review.tab',
    link: 'https://m.flamingo.shop/store/order?orderStatus=40'
  }]
}


export const FEEDBACK_MENU_KEY = 'Support'

const MENU_ITEMS = [
  {
    key: 'AddressEdit',
    image: 'https://img.flamingo.shop/p/files/icon_me_address.png?105404',
    title: 'core.user.shippingAddress',
    link: { name: 'AddressEdit' },
    needLogin: true
  },
  {
    key: FEEDBACK_MENU_KEY,
    image: 'https://img.flamingo.shop/p/files/icon_me_feedback.png?105404',
    title: 'core.user.support'
  },
  {
    key: 'AboutUs',
    image: 'https://img.flamingo.shop/p/files/icon_me_about_us.png?105404',
    title: 'core.user.aboutUs',
    link: 'https://m.flamingo.shop/store/events/about-us'
  },
  {
    key: 'ShippingInformation',
    image: 'https://img.flamingo.shop/p/files/icon_me_shipping.png?105404',
    title: 'core.user.shippingInfo',
    link: 'https://m.flamingo.shop/store/event/shipping'
  },
  {
    key: 'Frequently Asked Questions',
    image: 'https://img.flamingo.shop/p/files/icon_me_faq.png?105404',
    title: 'core.user.faq',
    link: 'https://m.flamingo.shop/store/event/faq'
  },
  {
    KEY: 'Settings',
    image: 'https://img.flamingo.shop/p/files/icon_me_settings.png?105404',
    title: 'core.user.settings',
    link: { name: 'Setting' },
    needLogin: true
  }
]

/* --------------------------------------------------------- */

const SUPPORT_ELEMENT = {
  id: 'JSY_IM_PLUGIN_FRAME',
  readyClass: 'icon-only',
  closeClass: 'minmize'
}

class _PlainProfileContainer extends PureComponent {
  _isMounted = false

  state = {
    orderCountInfo: {},
    vipIcon: null
  }

  componentDidMount() {
    this._isMounted = true

    const isDesktop = this.props.$detector.isDesktop()

    this.props.$bridge && this.props.$bridge.installIm()
    .then(() => {

      window.addEventListener('jsy_plugin_ready', evt => {
        // jsy_plugin_api - IM插件对外暴露的接口对象，也可以不用侦听事件，直接通过 window.JSY_PLUGIN_API 获取接口对象，但该属性只会在插件初始化完成后才会被赋值
        this.props.setRuntimeData('jsy_plugin_api', evt.detail)
        evt.detail.setOptions({
          closeButtonVisible: true, // 显示右上角关闭按钮
          launcherButtonVisible: isDesktop // 默认启动按钮
        })
      })
    })
  }

  handleSupport = () => {
    if (document.getElementById(SUPPORT_ELEMENT.id)) {
      let currentClassName = document.getElementById(SUPPORT_ELEMENT.id).className

      if (currentClassName.indexOf(SUPPORT_ELEMENT.readyClass) > -1) {
        this.props.runtimeData && this.props.runtimeData.jsy_plugin_api && this.props.runtimeData.jsy_plugin_api.open()

        if (!this.supportObserver) {
          this.supportObserver = new MutationObserver(() => {

            const isClosed = document.getElementById(SUPPORT_ELEMENT.id).className.indexOf(SUPPORT_ELEMENT.closeClass) > -1

            if (isClosed) {
              document.getElementById(SUPPORT_ELEMENT.id).removeAttribute('style')
              this.supportObserver = null
            } else {
              document.getElementById(SUPPORT_ELEMENT.id).setAttribute('style', 'width: 100%!important;z-index: 999999;transform: unset;')
            }

          })

          this.supportObserver.observe(document.getElementById(SUPPORT_ELEMENT.id), {
            attributeFilter: ['class']
          })
        }
      }
    }
  }

  getOrderCount = () => {
    new OrderService(this.props.$http).getOrderCount().then(data => {
      if (this._isMounted) {
        this.setState({
          orderCountInfo: data
        })
      }
    }).catch(err => {
      this.props.$logger.error(`[Profile] failed to getOrderCount, caused by ${err.message}`)
    })
  }

  getUserVipLevel = () => {
    if (this.props.user) {
      new UserService(this.props.$http).getUserVipLevel().then(data => {
        if (this._isMounted) {
          this.setState({
            vipIcon: data.vipIcon
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    const { accessToken: accessToken1 } = this.props.user || {}
    const { accessToken: accessToken2 } = prevProps.user || {}

    if (accessToken1 !== accessToken2) {
      this.getOrderCount()
      this.getUserVipLevel()
    }
  }

  componentWillUnmount() {
    this._isMounted = false
    this.supportObserver = null
  }

  getPlainItems() {
    return MENU_ITEMS.map(item => {
      if (item.key === FEEDBACK_MENU_KEY) {
        return {
          ...item,
          title: this.props.$i18n.transl(item.title),
          onClick: ev => {
            ev.preventDefault()
            this.props.$router.navigateToMessengerBot('customerService', 'fromMy', () => {
              this.props.$router.navigate(item.link)
            })
          }
        }
      }
      return {
        ...item,
        title: this.props.$i18n.transl(item.title),
      }
    })
  }

  getDealItems() {
    if (this.props.$detector.isApp()) {
      return {
        title: 'My Deals',
        items : [{
          image: 'https://img.flamingo.shop/p/files/mycouponicon_3x_ce57862f-180e-4acd-8cb1-56a91a8a09a9.png?v=1587536908',
          title: 'My Coins',
          link: 'https://m.flamingo.shop/store/activity/piggy_bank'
        },
        {
          image: 'https://img.flamingo.shop/p/files/my_wish.png?v=1578655027',
          title: 'Wishslist',
          link: 'https://m.flamingo.shop/store/wish_list',
          onClick: (e) => {
            // APP 6.2版本后支持跳转到原生页面
            if (this.props.$detector.compareAppVersion({ targetVersion: '6.2.0' })) {
              e.preventDefault()
              this.props.$router.navigateToApp({
                scheme: '/wishlist'
              })
            }
          }
        }
        ]
      }
    } else {

      return {
        title: this.props.$i18n.transl('core.user.myDeals.title'),
        items : [
        {
          image: 'https://img.flamingo.shop/p/show/146815f2242847de9414066524e8c2f3.jpg',
          title: this.props.$i18n.transl('core.wishlist.title'),
          link: 'https://m.flamingo.shop/store/wish_list',
          onClick: (e) => {
            // APP 6.2版本后支持跳转到原生页面
            if (this.props.$detector.compareAppVersion({ targetVersion: '6.2.0' })) {
              e.preventDefault()
              this.props.$router.navigateToApp({
                scheme: '/wishlist'
              })
            }
          }
        }
        ]
      }
    }
  }

  render() {
    const plainItems = this.getPlainItems()
    const isCountExist = this.props.user && this.state.orderCountInfo

    const orderSection = {
      ...ORDER_SECTION,
      title: this.props.$i18n.transl(ORDER_SECTION.title),
      items: ORDER_SECTION.items.map(item => ({
        ...item,
        title: this.props.$i18n.transl(item.title),
        count: isCountExist ? (this.state.orderCountInfo[item.statusKey] || 0) : 0
      }))
    }

    let sections = [orderSection, this.getDealItems()]


    return (
      <ProfilePage
        {...this.props}
        vipIcon={this.state.vipIcon}
        sections={sections}
        plainItems={plainItems}
        onSupport={this.handleSupport}
      />
    )
  }
}

/* --------------------------------------------------------- */

const PlainProfileContainer = withAppState(withUserAndPlugins(_PlainProfileContainer))

class ProfileContainer extends PureComponent {
  static Shell = Shell
  static shellProps = {
    promotion: true,
  }

  static fetchOpenGraphInfo() {
    return {
      title: 'My Profile - Flamingals by Flamingo Shop',
      private: true // not index on search engine
    }
  }

  render() {
    return (
      <PlainProfileContainer></PlainProfileContainer>
    )
  }
}

/* --------------------------------------------------------- */

export {
  PlainProfileContainer
}

export default ProfileContainer
