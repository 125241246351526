import React from 'react'
import Button from '../../common/Button/Button'
import { withPlugin } from '@flamingo_tech/funkgo'
import NumChanger from '../../common/NumChanger/NumChanger'
import SelectIcon from '../CheckOut/SelectIcon'
import styles from './DesktopCartPreviewFooter.module.css'
import cx from '../../../utils/className'

const DesktopCartPreviewFooter = props => {

  const { wrapperClassName } = props
  const { currencySymbol, isCurrencySymbolPrefix } = props.$site.getSiteInfo()
  const defaultPrice = '0.00'
  const defaultDisplayPrice = isCurrencySymbolPrefix ? `${currencySymbol}${defaultPrice}` : `${defaultPrice}${currencySymbol}`

  const handleViewBag = () => {
    props.$track.event('Cart', 'click_view_bag')

    props.$router.navigate({
      name: 'Cart'
    })
  }
  const {
    lineItems,
    selectCart,
  } = props

  const saleableLineItems = []
  const unsaleableLineItems = []

  lineItems.forEach(lineItem => {
    if (lineItem.saleable) {
      saleableLineItems.push(lineItem)
    } else {
      unsaleableLineItems.push(lineItem)
    }
  })
  
  const isSelectAll = saleableLineItems.filter(i => i.selectedFlag).length === saleableLineItems.length

  return (
    <footer className={cx(styles.wrapper, wrapperClassName)}>
      {
        props.renderHeader && (
          <div className={styles.fixedHeader}>
            {props.renderHeader()}
          </div>
        )
      }
      <div className={styles.dataRow}>
        <div className={styles.selectAll}>
          <SelectIcon selected={isSelectAll} onClick={() => selectCart('', !isSelectAll)} type={SelectIcon.TYPE.CHECKBOX} className={styles.selectIcon} />
          <span>{props.$i18n.transl('core.order.statusDesc.all.tab')}</span>
        </div>
        <div className={styles.counts} onClick={props.onClickCounts}>
        <span className={styles.price}>
          <>
            {props.$i18n.transl('core.checkout.total') + ' '}
            <NumChanger
              price={props.id ? props.totalPrice : defaultPrice}
              displayPrice={props.id ? props.displayTotalFee : defaultDisplayPrice}
              disabled={props.numberChangeDisabled}
              onFinish={props.onNumChangeFinish}
            />
          </>
          {props.availableItemCount > 0 && (
            <span className={cx(styles.sizePickerIcon, props.active && styles.active)}>&#xe7b2;</span>
          )}
        </span>
        {
          !!props.totalSaveFee && props.totalSaveFee > 0 && <span className={styles.saveFee}>{props.$i18n.transl('core.cart.youSave')} {props.displayTotalSaveFee}</span>
        }
      </div>
      </div>
      <Button className={styles.bagBtn} onClick={handleViewBag}>{props.$i18n.transl('core.cart.viewBag', {
        count: props.cartLineItemCount
      })}</Button>

      <Button className={styles.checkout} onClick={props.onCheckout}>{props.$i18n.transl('core.cart.checkout')}</Button>
    </footer>
  )
}

export default withPlugin(DesktopCartPreviewFooter)