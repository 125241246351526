import React, { PureComponent } from 'react'
import queryString from 'query-string'

import CollectionSkeleton from '../../components/Skeleton/CollectionSkeleton'

import CollectionPage from '../../components/Store/CollectionPage/CollectionPage'
import withAppState from '../../hooks/withAppState'
import Shell from '../Shell/GuidePageShellContainer'
import { TRACK_CHANNEL, getProductDetailTrackChannel } from '../../utils/Store/productUtils'
import { animateScroll } from 'react-scroll'
import { attachFilterQuery } from '../../utils/Store/sortUtils'
import { getShippingInfo } from '../../utils/seoUtils'

import { debounce } from 'debounce'
import * as seoUtils from '../../utils/seoUtils'
import { makeSearch } from '../../utils/breadcrumbUtils'

import ProductService from '../../services/ProductService'
import CollectionProductCard from '../../components/Store/ProductCard/CollectionProductCard'
import FlexList from '../../components/common/List/FlexList'

import styles from './CollectionContainer.module.css'
import cx from '../../utils/className'
import { createStorage } from '@flamingo_tech/funkgo'


const FILTER_KEY = {
  size: 'size',
  color: 'color',
  price: 'price'
}

class CollectionContainer extends PureComponent {
  // for local render once sort changed
  state = {
    loading: false,
    productQueue: null,
    queryOptions: null,
    filterQuery: null,
    collectionTags: [],
    isCollectionFiltersFetched: false
  }

  static Shell = Shell
  static shellProps = () => {
    return {
      promotion: true,
      supportDesktop: true,
      showShippingBar: true,
      showDownloadBanner: true,
      downloadPage: 'collection',
      footHintHeight: 0
    }
  }


  static LoadingComponent = CollectionSkeleton


  static fetchQuery({ location }) {
    return queryString.parse(location.search)
  }

  // 通过~分割handle后面的filter属性
  static getCollectionHandle({ match }) {
    return match.params.handle.split('+')[0]
  }

  static getCollectionQuery({ match }) {
    return match.params.handle.split('+')[1]
  }

  static fetchCollection({ $http}, collectionHandle, collectionQuery) {
    return new ProductService($http).fetchCollection(collectionHandle, { pageSize: 20, ...collectionQuery } || undefined)
  }

  static fetchCollectionSEOContent({ $http}, collectionHandle) {
    return new ProductService($http).fetchCollectionSEOContent(collectionHandle, { isESCollection: false })
  }

  static getQueryData = (collectionQueryString) => {
    const params = {}
    const data = queryString.parse(collectionQueryString && collectionQueryString.replace(/\./g, '%2C'))
    const keys = Object.keys(data)
    keys.forEach(key => {
      const value = data[key]
      if (key in FILTER_KEY) {
        if (value.indexOf(',') > -1) {
          params[key] = value.split(',')
        } else {
          params[key] = [value]
        }
      } else {
        params[key] = value
      }
    })
    return params
  }

  static isEmptyFilterOption = filterOptions => {
    return Object.values(filterOptions).filter(i => i).length === 0
  }

  static fetchPageData(pluginProps) {
    const collectionHandle = CollectionContainer.getCollectionHandle(pluginProps)

    const { tph, mip } = CollectionContainer.fetchQuery(pluginProps)
    const filterQuery = CollectionContainer.getQueryData(CollectionContainer.getCollectionQuery(pluginProps))
    const tphQueryOption = tph ? { tph } : null
    const mipQueryOption = mip ? { mip } : null

    const queryOptions = {...tphQueryOption, ...mipQueryOption, ...filterQuery}

    const fetchCollectionSEOContentReq = CollectionContainer.fetchCollectionSEOContent(pluginProps, collectionHandle).catch(() => ({}))
    const productQueueReq = CollectionContainer.fetchCollection(pluginProps, collectionHandle, { ...queryOptions })

    return Promise.all([productQueueReq, fetchCollectionSEOContentReq])
      .then(([productQueue, collectionSEOInfo]) => {

        return {
          collectionHandle,
          collectionTitle: productQueue.title,
          collectionSEOInfo,
          productQueue,
          queryOptions
        }
      })
  }

  static fetchOpenGraphInfo({ data }, { $router, $site, $i18n }) {
    const { productQueue = {}, collectionTitle, collectionSEOInfo } = data
    const { products } = productQueue

    const { seoTitle, seoDescription } = collectionSEOInfo || {}

    const productLinks = (products || []).map(productQueueItem => {
      const link = {
        name: 'Product',
        params: {
          handle: productQueueItem.product.handle
        }
      }
      return $router.getFinalPath(link)
    })

    const jsonLD = [
      seoUtils.forBreadcrumb(),
    ]

    if (productLinks) {
      jsonLD.push(
        seoUtils.forItemListSummary(productLinks)
      )
    }

    const firstProduct = products[0]
    const image = firstProduct && firstProduct.product ? firstProduct.product.mainImage : undefined

    const filterString = $router.location.pathname.split('+')[1]

    const site = $site.getSiteInfo()

    return {
      title: `${collectionTitle} | ${site.fullSiteName}`,
      seoTitle: seoTitle ? `${seoTitle} | ${site.fullSiteName}` : undefined,
      description: seoDescription ? `${seoDescription} ${getShippingInfo({ $site, $i18n })}` : undefined,
      image,
      jsonLD,
      url: `${$router.location.origin + $router.location.pathname.split('+')[0]}`,
      noIndex: Boolean(filterString)
    }

  }

  productService = new ProductService(this.props.$http)

  fetchCollectionFilter(collectionHandle) {
    return this.productService.fetchCollectionFilter(collectionHandle)
      .then(collectionFilters => {
        this.setState({
          isCollectionFiltersFetched: true,
          collectionFilters
        })
      })
  }

  fetchCollectionTags(collectionHandle) {
    return this.productService.fetchCollectionTabs(collectionHandle)
      .then(collectionTags => {
        this.setState({
          collectionTags
        })
      })
  }

  // 使用上一次浏览当前 collection 停留的位置
  scrollToPreviousPosition = () => {
    // 禁用 dom scroll 动画效果
    const currentQueryOptions = JSON.stringify(this.getProductQueue().queryOptions)

    document.getElementsByTagName('html')[0].setAttribute('class', styles.stay)

    if (this.props.runtimeData && this.props.runtimeData['productList'] && this.props.runtimeData['productList'][this.props.data.collectionHandle]) {
      const { productQueue, queryOptions } = this.props.runtimeData['productList'][this.props.data.collectionHandle]

      if (currentQueryOptions !== JSON.stringify(queryOptions)) {
        return
      }

      this.setState({
        productQueue,
        queryOptions
      })

      setTimeout(() => {
        const scrollTopStorage = createStorage('collection_scroll_top', { strategy: 'SESSION' })
        if (scrollTopStorage.getItem()) {
          animateScroll.scrollTo(scrollTopStorage.getItem(0), {
            duration: 0,
            smooth: 'linear'
          })

        }
      }, 200)
      this.props.$track.event('CollectionBackExperiment', 'open')

    } else {
      animateScroll.scrollTo(0, {
        duration: 0,
        smooth: 'linear'
      })
    }
  }

  // 记住当前 collection scroll 位置，并记入到 runtime
  setPreviousScrollPosition = () => {
    document.getElementsByTagName('html')[0].removeAttribute('class')

    const scrollTopStorage = createStorage('collection_scroll_top', { strategy: 'SESSION'})
    scrollTopStorage.setItem(document.documentElement.scrollTop)

    this.props.setRuntimeData('productList', {
      [this.props.data.collectionHandle]: this.getProductQueue()
    })
  }

  componentDidMount() {
    // scroll to pre scroll position
    this.scrollToPreviousPosition()
    this.fetchCollectionFilter(this.props.data.collectionHandle)
    this.fetchCollectionTags(this.props.data.collectionHandle)
  }

  componentWillUnmount() {
    // remember current position and data
    this.setPreviousScrollPosition()
  }

  handleSort = debounce((filterOption) => {
    this.setState({
      loading: true
    })

    attachFilterQuery(filterOption, queryString, this.props.data.collectionHandle)

    const isEmptyFilterOption = CollectionContainer.isEmptyFilterOption(filterOption)

    const { tph, mip } = CollectionContainer.fetchQuery(this.props)
    const tphQueryOption = tph ? { tph } : null
    const mipQueryOption = mip ? { mip } : null

    const queryOptions = isEmptyFilterOption ? {...tphQueryOption, ...mipQueryOption, ...filterOption} : {...filterOption}


    CollectionContainer.fetchCollection(
      this.props,
      this.props.data.collectionHandle,
      queryOptions
    ).then(productQueue => {
      this.hasNextPage = productQueue.pageInfo.hasNextPage
      this.setState({
        loading: false,
        productQueue,
        queryOptions
      })
    }).catch(() => {
      this.setState({
        loading: false,
      })
    })
  }, 1000)

  /* ----------------------------------------- */
  getProductQueue() {
    let productQueue, queryOptions

    // render from sort changed
    if (this.state.productQueue) {
      productQueue = this.state.productQueue
      queryOptions = this.state.queryOptions

    // render from initial
    } else {
      productQueue = this.props.data.productQueue
      queryOptions = this.props.data.queryOptions
    }

    return {
      productQueue,
      queryOptions,
    }
  }

  renderPromotion = (promotionTag) => {
    const isDesktop = this.props.$detector.isDesktop()

    if (promotionTag) {
      return (
        <div className={cx(styles.collectionTag, isDesktop && styles.isDesktop)}>{promotionTag}</div>
      )
    }

    return null
  }


  hasNextPage = this.props.data.productQueue.pageInfo.hasNextPage

  onReachEnd = () => {
    let hasNextPage = this.hasNextPage

    if (this.isFetching || !hasNextPage) {
      return
    }

    this.isFetching = true

    const { queryOptions, productQueue: { products = [] } = {} } = this.getProductQueue()

    CollectionContainer.fetchCollection(
      this.props,
      this.props.data.collectionHandle,
      {
        ...queryOptions,
        fromCursor: products.length > 0 ? products[products.length - 1].cursor : null
      },
    ).then(productQueue => {
      this.isFetching = false
      this.hasNextPage = productQueue.pageInfo.hasNextPage
      this.setState({
        productQueue: {
          ...productQueue,
          products: [...products, ...productQueue.products]
        },
        queryOptions
      })
    })
    .catch(() => {
      this.isFetching = false
    })
  }

  makeToExtraInfo() {
    const { collectionTitle, collectionHandle } = this.props.data
    const trackChannel = getProductDetailTrackChannel(this.props.location)

    const toExtraInfo = {
      search: {
        ...makeSearch({
          title: collectionTitle,
          routeName: 'Collection',
          routeHandle: collectionHandle,
          }),
        [TRACK_CHANNEL]: trackChannel
      }
    }

    return toExtraInfo
  }

  getProductListProps() {
    const { productQueue } = this.getProductQueue()
    const { pageInfo = {} } = productQueue || {}

    return {
      ...productQueue,
      ...pageInfo,
      childIs: CollectionProductCard,
      ListComponent: FlexList,
      childOptions: {
        renderPromotion: this.renderPromotion,
        toExtraInfo: this.makeToExtraInfo()
      },
      onReachEnd: this.onReachEnd,
      pageSize: 20
    }
  }

  /* ----------------------------------------- */

  render() {
    const productList = this.getProductListProps()

    const props = {
      loading: this.state.loading,
      handleSort: this.handleSort,
      collectionFilters: this.state.collectionFilters || [],
      isCollectionFiltersFetched: this.state.isCollectionFiltersFetched,
      collectionTags: this.state.collectionTags || [],
      collectionSEOInfo: this.props.data.collectionSEOInfo || {},
      productList,
      queryOptions: this.state.queryOptions || CollectionContainer.getQueryData(CollectionContainer.getCollectionQuery(this.props)),
      collectionTitle: productList.title || ''
    }

    return <CollectionPage {...props} />
  }
}

export default withAppState(CollectionContainer)
