import UserModel from '../common/UserModel'

const MEDIA_TYPE = {
  1: 'IMAGE',
  2: 'IFRAME VIDEO',
  3: 'VIDEO'
}

export class LinkedProductModel {
  constructor(data) {
    this.id = data.id
    this.handle = data.handle
    this.mainImage = data.mainPicUrl || data.mainImage
    this.price = data.price
    this.originalPrice = data.originalPrice
    this.displayPrice = data.displayPrice
    this.displayMsrp = data.displayMsrp
    this.title = data.name
    this.skuTitle = data.skuTitle

  }
}

export class PostModel {
  constructor(data) {
    this.id = data.id

    if (data.user) {
      this.userInfo = new UserModel({
        id: data.user.id,
        name: data.user.userName,
        image: data.user.avatar
      })
    }

    this.mediaType = MEDIA_TYPE[data.mediaType]
    this.updateTime = data.updateTime * 1000

    this.mainImage = data.headImage

    this.desc = data.content

    this.linkedProducts = (data.products || []).map(product => new LinkedProductModel(product))
    this.skuTitle = data.skuTitle
    this.resources = data.resources || []
    this.tags = data.tags || []

    this.likeNum = data.star || 0
    this.commentNum = data.comment || 0
    this.viewNum = data.viewCount || 0
    this.hasLiked = data.isStar
    this.width = data.width
    this.height = data.height
  }
}

export class UserPostSummary {
  constructor(data) {
    this.user = new UserModel({
      id: data.id,
      name: data.userName,
      image: data.avatar
    })

    this.likeNum = data.starCount || 0
    this.postNum = data.postCount || 0
  }
}
