import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './ModalBodyWrapper.module.css'
import cx from '../../../utils/className'

const ModalBodyWrapper = (props) => {
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.body, isDesktop && styles.isDesktop)}>
        <div className={styles.closeIcon} onClick={props.onClose}>&#xe60e;</div>
        {props.data && props.children}
      </div>
    </div>
  )
}

export default withPlugin(ModalBodyWrapper)