import queryString from 'query-string'

export const LEGACY_LOCALE = ['en_MX', 'en_FR']

const COUNTRY_META = {
  US: {
    country: 'US',
    countryCode: 'US',
    countryName: 'United States',
    countryFlag: 'https://img.flamingo.shop/p/files/us.png?v=1613893564',
    currency: 'USD',
    displayCurrency: '$ - USD - U.S. Dollar',
    isCurrencySymbolPrefix: true,
    shippingFeeForSEO: 69,
    currencySymbol: '$',
    languages: [{
      locale: 'en_US',
      name: 'English',
      lang: 'en'
    }]
  },
  UK: {
    country: 'UK',
    countryCode: 'GB',
    countryName: 'United Kingdom',
    countryFlag: 'https://img.flamingo.shop/p/files/en.png?v=1613893564',
    currency: 'GBP',
    displayCurrency: '£ - GBP - Pounds',
    isCurrencySymbolPrefix: true,
    shippingFeeForSEO: 59,
    currencySymbol: '£',
    languages: [{
      locale: 'en_GB',
      name: 'English',
      lang: 'en'
    }]
  },
  FR: {
    country: 'FR',
    countryCode: 'FR',
    countryName: 'France',
    countryFlag: 'https://img.flamingo.shop/p/files/2021512-3755.png?v=1620803301',
    currency: 'EUR',
    displayCurrency: '€ - EUR - Euro',
    isCurrencySymbolPrefix: false,
    shippingFeeForSEO: 59,
    currencySymbol: '€',
    languages: [{
      locale: 'fr_FR',
      name: 'Français',
      lang: 'fr'
    }]
  },
  BR: {
    country: 'BR',
    countryCode: 'BR',
    countryName: 'Brazil',
    countryFlag: 'https://img.flamingo.shop/p/files/brazil_3x_b0ea262e-dc17-4dd7-bf15-26d787808c2f.png?v=1626405105',
    currency: 'BRL',
    displayCurrency: 'R$ - BRL',
    isCurrencySymbolPrefix: true,
    shippingFeeForSEO: 69,
    currencySymbol: 'R$',
    languages: [{
      locale: 'en_BR',
      name: 'Brasil',
      lang: 'en'
    }]
  },
  MX: {
    country: 'MX',
    countryCode: 'MX',
    countryName: 'Mexico',
    countryFlag: 'https://img.flamingo.shop/p/files/mexico_3x_8aeaed0a-ca08-4d2a-91ee-301c6747358b.png?v=1626405105',
    currency: 'MXN',
    displayCurrency: '$MXN - MXN',
    isCurrencySymbolPrefix: true,
    shippingFeeForSEO: 69,
    currencySymbol: '$MXN',
    languages: [{
      locale: 'es_MX',
      name: 'Español',
      lang: 'es'
    }]
  },
  CA: {
    country: 'CA',
    countryCode: 'CA',
    countryName: 'Canada',
    countryFlag: 'https://img.flamingo.shop/p/files/202198-01211.png?v=1631074363',
    currency: 'CAD',
    displayCurrency: 'CA$ - CAD',
    isCurrencySymbolPrefix: true,
    shippingFeeForSEO: 69,
    currencySymbol: 'CA$',
    languages: [{
      locale: 'en_CA',
      name: 'English',
      lang: 'en'
    }]
  },
  ES: { // 其实是美国站，用西语而已；目前架构不支持，所以黑化了
    country: 'US',
    countryCode: 'US',
    countryName: 'United States',
    countryFlag: 'https://img.flamingo.shop/p/files/us.png?v=1613893564',
    currency: 'USD',
    displayCurrency: '$ - USD - U.S. Dollar',
    isCurrencySymbolPrefix: true,
    shippingFeeForSEO: 69,
    currencySymbol: '$',
    languages: [{
      locale: 'es_ES',
      name: 'Español',
      lang: 'es'
    }],
    paymentLocale: 'es_US',
    skipInList: true
  },
  DE: {
    country: 'DE',
    countryCode: 'DE',
    countryName: 'Germany',
    countryFlag: 'https://img.flamingo.shop/p/files/20211028-223417.png?v=1635476998',
    currency: 'EUR',
    displayCurrency: '€ - EUR - Euro',
    isCurrencySymbolPrefix: false,
    shippingFeeForSEO: 69,
    currencySymbol: '€',
    languages: [{
      locale: 'de_DE',
      name: 'Deutsch',
      lang: 'de'
    }]
  }
}


export const SITES = {
  FLAMINGO: {
    id: 'FLAMINGO',
    siteReg: /flamingo.shop/,
    name: 'Flamingals',
    fullSiteName: 'Flamingals by Flamingo Shop',
    internationalStations: [{
      id: 'FLAMINGO', // FLAMINGO/FLAMINGO_US
      origin: 'https://flamingo.shop',
      hostname: 'flamingo.shop',
      ...COUNTRY_META.US
    },
    {
      id: 'FLAMINGO_UK',
      origin: 'https://uk.flamingo.shop',
      hostname: 'uk.flamingo.shop',
      ...COUNTRY_META.UK
    },
    {
      id: "FLAMINGO_FR",
      origin: 'https://fr.flamingo.shop',
      hostname: 'fr.flamingo.shop',
      ...COUNTRY_META.FR
    },
    {
      id: "FLAMINGO_BR",
      origin: 'https://br.flamingo.shop',
      hostname: 'br.flamingo.shop',
      ...COUNTRY_META.BR
    },
    {
      id: "FLAMINGO_MX",
      origin: 'https://mex.flamingo.shop',
      hostname: 'mex.flamingo.shop',
      ...COUNTRY_META.MX
    },
    {
      id: "FLAMINGO_CA",
      origin: 'https://ca.flamingo.shop',
      hostname: 'ca.flamingo.shop',
      ...COUNTRY_META.CA
    },
    {
      id: "FLAMINGO_ES",
      origin: 'https://es.flamingo.shop',
      hostname: 'es.flamingo.shop',
      ...COUNTRY_META.ES
    },{
      id: "FLAMINGO_DE",
      origin: 'https://de.flamingo.shop',
      hostname: 'de.flamingo.shop',
      ...COUNTRY_META.DE
    }]
  },
  IPHOENIX: {
    id: 'IPHOENIX',
    siteReg: /iphoenix.shop/,
    name: 'iPhoenix',
    fullSiteName: 'iPhoenix',
    theme: '#232323',
    logo: 'https://img.flamingo.shop/p/files/iPhoenix.png?v=1624616031',
    internationalStations: [{
      id: 'IPHOENIX',
      origin: 'https://m.iphoenix.shop',
      ...COUNTRY_META.US
    }]
  },
  KIWIFAIR: {
    id: 'KIWIFAIR',
    siteReg: /kiwifair.com/,
    name: 'Kiwifair',
    fullSiteName: 'Kiwifair',
    theme: '#232323',
    logo: 'https://img.flamingo.shop/p/files/2021625-63721.png?v=1624617451',
    internationalStations: [{
      id: 'KIWIFAIR',
      origin: 'https://kiwifair.com',
      ...COUNTRY_META.US
    }, {
      id: 'KIWIFAIR_UK',
      origin: 'https://uk.kiwifair.com',
      ...COUNTRY_META.UK
    }, {
      id: 'KIWIFAIR_FR',
      origin: 'https://fr.kiwifair.com',
      ...COUNTRY_META.FR
    }]
  }
}

export const getSite = () => {
  let site = SITES.FLAMINGO

  if (typeof window !== 'undefined') {

    const siteKeys = Object.keys(SITES)

    for (let i = 0; i < siteKeys.length; i++) {
      if (SITES[siteKeys[i]].siteReg.test(window.location.host)) {
        site = SITES[siteKeys[i]]
        break
      }
    }
  }

  return site
}


export const getSiteStation = (hostname) => {
  const { internationalStations, ...basicSiteInfo } = getSite()

  let siteStation = internationalStations[0]

  if (typeof window !== 'undefined') {
    internationalStations.forEach(station => {
      if (station.origin === window.location.origin) {
        siteStation = station
      }
    })
  } else {
    internationalStations.forEach(station => {
      if (station.hostname === hostname) {
        siteStation = station
      }
    })
  }

  return {
    ...basicSiteInfo,
    ...siteStation
  }

}

export const getAppLocale = () => {
  const { internationalStations } = getSite()

  let locale = null

  if (typeof window !== 'undefined') {
    const query = queryString.parse(window.location.search)
    if (query && query.locale) {
      internationalStations.forEach(station => {
        if (station.languages[0].locale === query.locale) {
          locale = query.locale
        }
      })
    }
  }

  return locale

}