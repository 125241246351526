import React, { PureComponent } from 'react'
import withCartAndPlugins from './withCartAndPlugins'

import CartService from '../plugins/StorePlugin/services/CartService'

const withUnpaidOrdersEnabled = (Component) => {
  const displayName = `withUnpaidOrdersEnabled(${Component.displayName || Component.name})`

  class WrappedComponent extends PureComponent {
    cartService = new CartService(this.props.$http)
    unpaidInfoStorage = this.props.$storage.create('unpaid_info', { strategy: 'SESSION' })

    state = {
      loaded: false
    }

    componentDidMount() {
      const unpaidInfo = this.unpaidInfoStorage.getItem({})
      const { userToken = this.props.$user.getAccessToken() } = unpaidInfo.pendingPaymentInfo || {}

      if (userToken && !unpaidInfo.pendingPaymentInfo) {
        this.cartService.getLastPendingPay()
          .then(res => {
            this.unpaidInfoStorage.setItem({
              ...unpaidInfo,
              pendingPaymentInfo: {
                ...res,
                userToken
              }
            })
            this.setState({ loaded: true })
          })
          .catch(error => {
            this.setState({ loaded: true })

            const { errorCode } = error

            if (errorCode === 401) {
              this.props.$user.clearUser()
              this.props.$track.event('LastPendingPayError', this.props.$user.getUserEmail(), this.props.$user.getAccessToken())
            }
          })

      } else {
        this.setState({ loaded: true })
      }
    }

    updateUnpaidInfo = data => {
      this.unpaidInfoStorage.setItem({
        ...this.unpaidInfoStorage.getItem({}),
        ...data
      })
    }

    static getDerivedStateFromProps(props, state) {
      if (!state.cart && props.cart) {
        return {
          cart: props.cart
        }
      }

      return null
    }

    render() {
      if (!this.state.loaded) {
        return null
      }

      return (
        <Component
          {...this.props}
          cart={this.state.cart}
          unpaidInfo={this.unpaidInfoStorage.getItem({})}
          onUpdateUnpaidInfo={this.updateUnpaidInfo}
        />
      )
    }
  }

  WrappedComponent.displayName = displayName
  return withCartAndPlugins(WrappedComponent)
}

export default withUnpaidOrdersEnabled
