import React, { useCallback, useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import Tab, { getDefaultTab } from '../../common/Tab/Tab'

import cx from '../../../utils/className'
import styles from './AppFooter.module.css'

const TabItem = props => {
  const plugin = useContext(PluginContext)

  const {
    tab,
    selected,
    tabsRibbon,
    tabsRedPoint
  } = props

  const {
    key,
    image,
    title
  } = tab

  return (
    <div className={cx(styles.imageWrapper, selected && styles.selected)}>
      <i className={styles.icon}>{image}</i>
      {
        typeof tabsRibbon[key] !== 'undefined' && (
          <span className={styles.count}>{tabsRibbon[key]}</span>
        )
      }
      {
        tabsRedPoint && tabsRedPoint[key] && (
          <span className={styles.redPoint}></span>
        )
      }
      <h4 className={styles.title}>{plugin.$i18n.transl(title)}</h4>
    </div>
  )
}

const AppFooter = props => {
  const {
    tabs,
    currentTab,
    tabParamName,
    tabsRibbon,
    tabsRedPoint,
    onTabChange,
  } = props

  const renderItem = useCallback(props => (
    <TabItem
      {...props}
      tabsRibbon={tabsRibbon}
      tabsRedPoint={tabsRedPoint}
    ></TabItem>
  ), [tabsRibbon, tabsRedPoint])

  return (
    <Tab
      onTabChange={onTabChange}
      className={styles.wrapper}
      renderItem={renderItem}
      tabs={tabs}
      tabParamName={tabParamName}
      currentTab={currentTab}
      needTabKeyToHash={false}
    ></Tab>
  )
}

export {
  getDefaultTab
}

export default AppFooter
