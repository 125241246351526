import React from 'react'

import Image from '../Image/Image'

import styles from './ShareDialog.module.css'

const EVENT_CATEGORY = 'Share'

const Share = props => {
  const eventCategory = props.eventCategory || EVENT_CATEGORY

  const handleClick = key => {
    switch (key) {
      case 'fb':
        props.onFbShare(props.shareInfo, eventCategory)
        break

      case 'messenger':
        props.onMessengerShare(props.shareInfo, eventCategory)
        break

      case 'twitter':
        props.onTwitterShare(props.shareInfo, eventCategory)
        break

      case 'whatsApp':
        props.onWhatsAppShare(props.shareInfo, eventCategory)
        break

      case 'pinterest':
        props.onPinterestShare(props.shareInfo, eventCategory)
        break

      case 'link':
        props.onCopy(props.shareInfo, eventCategory)
        break

      case 'more':
        props.onShareByNavigator(props.shareInfo, eventCategory)
        break

      default:
        break
    }
  }

  return (
    <div className={styles.wrapper}>
        <div className={styles.listWrapper}>
        {
          props.shareList.map(item => {
            if (item.type === 'link') {
              return (
                <a href={`sms: &body=${props.shareInfo.title} via ${props.shareInfo.url}`} id="smsSend" className={styles.shareItem} key={item.key}>
                  <Image className={styles.icon} placeholder={{ width: 1, height: 1 }} src={item.icon} />
                  <div className={styles.name}>{item.name}</div>
                </a>
              )
            }

            return (
              <div className={styles.shareItem} key={item.key} onClick={() => handleClick(item.key)}>
                <Image className={styles.icon} placeholder={{ width: 1, height: 1 }} src={item.icon} />
                <div className={styles.name}>{item.name}</div>
              </div>
            )
          })
        }
        </div>

        <div className={styles.listWrapper}>
          <div className={styles.shareItem} key={props.shareLink.key} onClick={() => handleClick(props.shareLink.key)}>
            <Image className={styles.icon} placeholder={{ width: 1, height: 1 }} src={props.shareLink.icon} />
            <div className={styles.name}>{props.shareLink.name}</div>
          </div>
          {
            navigator && !!navigator.share &&
            <div className={styles.shareItem} key={props.shareMore.key} onClick={() => handleClick(props.shareMore.key)}>
              <Image className={styles.icon} placeholder={{ width: 1, height: 1 }} src={props.shareMore.icon} />
              <div className={styles.name}>{props.shareMore.name}</div>
            </div>
          }
        </div>
    </div>
  )
}

const ShareDialog = props => {
  return (
    <aside>
      <div className={styles.cover} onClick={props.onClose}></div>
      <div className={styles.body} position={props.position}>
        <div className={styles.content}>
          <div>{props.headerTitle}</div>
          <Share {...props} />
          <div className={styles.closeBtn} onClick={props.onClose}></div>
        </div>
      </div>
    </aside>
  )
}


export default ShareDialog