import React, { useState, useEffect, useRef, useContext }  from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import cx from '../../utils/className'
import styles from './LikeButton.module.css'

const LikeButton = props => {
  const plugin = useContext(PluginContext)

  const [hasLiked, setHasLiked] = useState(props.hasLiked)
  const [likeNum, setLikeNum] = useState(props.likeNum)
  const [playAnimation, setPlayAnimation] = useState(false)

  const selfTrigger = useRef(false)

  useEffect(() => {
    if (!selfTrigger.current && props.hasLiked !== undefined && props.hasLiked !== hasLiked) {
      setHasLiked(props.hasLiked)
      setLikeNum(props.likeNum)
    } else {
      selfTrigger.current = false
    }
  }, [props.hasLiked, props.likeNum, hasLiked, selfTrigger])

  const handleClick = () => {
    const currentHasLiked = !hasLiked
    props.onClick(currentHasLiked).then(res => {
      if (res !== 'fail') {
        selfTrigger.current = true
        setHasLiked(currentHasLiked)
        setLikeNum(currentHasLiked ? likeNum + 1 : likeNum - 1)

        if (currentHasLiked) {
          setPlayAnimation(true)
          plugin.$bridge.vibrate({
            soundId: 1519
          })

          setTimeout(() => {
            setPlayAnimation(false)
          }, 600)
        }
      }
    })
  }

  return (
    <div
      className={cx(styles.wrapper, hasLiked && styles.highlighted, playAnimation && styles.withAnimation, props.className)}
      onClick={handleClick}
    >{likeNum}</div>
  )
}

export default LikeButton
