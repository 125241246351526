import React from 'react'

import cx from '../../../utils/className'
import { getDiscountText } from '../../../utils/priceUtils'

import styles from './FlashDiscount.module.css'

export const FlashIcon = (props) => {
  const { isDesktop, className } = props
  if (isDesktop) {
    return <span className={cx(styles.flashIcon, isDesktop && styles.inDesktop, className)} >&#xe65b;</span> 
  }  
  return <span className={cx(styles.flashIcon, isDesktop && styles.inDesktop, className)}>&#xe659;</span> 
}

export const FlashDiscountRow = ({ displaySaveFee, price, msrp, $i18n, isDesktop, className }) => {
  return (
    <div className={cx(styles.flashDiscountRowWrapper, isDesktop && styles.inDesktop, className)}>
      <div className={styles.desc}>{`${$i18n.transl('core.product.saved')}: ${displaySaveFee}`}</div>
      <div className={styles.discount}><span>{getDiscountText(price, msrp)}</span></div>
    </div>
  )
}

const FlashDiscount = (props) => {
  const { 
    discountStyle = {}, 
    isDesktop, 
    price, 
    msrp, 
    discountTriangleStyle = {}, 
    small
  } = props
  
  if (msrp && msrp <= price) {
    return null
  }

  return (
    <div className={cx(styles.discountWrapper, small && styles.small, isDesktop && styles.isDesktop)}>
      <div className={cx(styles.discount)} style={discountStyle}>
        <div className={styles.text}>{getDiscountText(price, msrp)}</div>
        { isDesktop ? <div className={styles.icon}>&#xe65b;</div> : <div className={styles.icon}>&#xe659;</div> }
      </div>
      <div className={styles.triangle} style={discountTriangleStyle}></div>
    </div>
  )
}
  
export default FlashDiscount