import ESProductQueueModel from '../../npm/FunkGoModel/ESProductQueueModel'

class LayoutModel {
  constructor(data, collections){
    return data.map(i => {
      let { products } = collections[i.option.collectionHandle] || {}

      if(products) {
        products = products.map(i => i.product)
      }

      let items = products ? products : i.items

      if (i.is === 'FlamingDealsPanel') {
        items[0].collectionHandle = 'flash-activity'
      }

      return {
        ...i,
        options: {
          ...i.option,
          items,
        }
      }
    })
  }
}

class AthenaModel {
  constructor(data) {
    this.handle = data.handle
    this.title = data.title
    this.description = data.description || data.desc
    this.meta = data.meta || {}
    this.root = data.root

    // compat old promotion config
    this.selectedPromotion = (data.promotions && data.promotions.length > 0 && data.promotions[0]) || data.promotion
    this.promotions = (data.option && data.option.promotions ) || []
    this.collections = {}

    if (data.collections) {
      Object.keys(data.collections).forEach(key => {
        const collection = new ESProductQueueModel(data.collections[key])
        if(Object.keys(collection).length > 0) {
          this.collections[key] = collection
        }
      })
    }
    this.layout = new LayoutModel(data.layout, this.collections)
  }
}

export default AthenaModel
