import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../Image/Image'

import styles from './Grid.module.css'

const DEFAULT_COLUMN_COUNT = 4

const getWrap = props => {
  if (props.wrap) {
    return props.wrap
  }

  const itemLength = props.items.length

  if (itemLength <= 4) {
    return itemLength
  }

  if (itemLength <= 10) {
    return Math.ceil(itemLength / 2)
  }

  return DEFAULT_COLUMN_COUNT
}

const getImagePlaceholder = props => {
  // true means default placeholder 1:1
  let imagePlaceholder = true

  if (props.placeholder) {
    imagePlaceholder = props.placeholder
  } else if (props.width && props.height) {
    imagePlaceholder = {
      width: props.width,
      height: props.height
    }
  }

  return imagePlaceholder
}

const Grid = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const wrap = getWrap(props)
  const columnWidth = `${100 / wrap}%`
  let imageSize = isDesktop ? { large: true } : wrap < 6
  ? { middle: true }
  : { small: true }

  // Grid 模拟 Banner 取原图
  if (wrap === 1) {
    imageSize = { original: true }
  }

  const onGridItemClick = index => {
    if (props.sourceLocator && props.trackName) {
      props.trackName && plugin.$track.clickMainResourceLocator(`${props.sourceLocator}:${props.trackName}_${index}`)
    }
  }

  const elements = props.items.map((item, i) => {
    const style = {
      width: columnWidth
    }

    const placeholder = getImagePlaceholder(item)

    return (
      <div className={styles.item} style={style} key={i} onClick={() => onGridItemClick(i)}>
        {item && item.title && <span className={styles.hiddenTitle}>{item.title}</span> /* for seo */}
        <Image
          {...item}
          {...imageSize}
          title={item.title || `Banner - ${i + 1}`}
          placeholder={placeholder}></Image>
      </div>
    )
  })

  return (
    <div className={styles.grid}>
      {elements}
    </div>
  )
}

export default Grid