export const formatTitle = title => {
  if (!title) {
    return 'Collection'
  }

  let formattedTitle = title.replace(/[-_]/g, ' ')

  if (/^Navi /.test(formattedTitle)) {
    formattedTitle = formattedTitle.substr(5)
  }

  return formattedTitle
}

export const getLink = collection => ({
  name: 'Collection',
  params: {
    handle: collection.handle
  }
})

export const convertSearch = (searchObj = {}) => {
  return {
    handle: searchObj._td
  }
}