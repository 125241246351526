import React from 'react'

import styles from './QuantityChooser.module.css'
import cx from '../../../utils/className'

const [SUBTRACT, ADD] = ['SUBTRACT', 'ADD']

const QuantityChooser = props => {

  const maxQuantity = props.maxQuantity ? props.maxQuantity : Infinity
  const minQuantity = props.minQuantity !== undefined ? props.minQuantity : 1

  const handleQuantityChange = (ev, operator) => {
    ev.preventDefault()
    const prevQuantity = props.quantity
    const currentQuantity = operator === SUBTRACT ? (prevQuantity > minQuantity ? prevQuantity - 1 : minQuantity) : (prevQuantity < maxQuantity ? prevQuantity + 1 : prevQuantity)

    props.onChange && props.onChange(currentQuantity)
  }

  return (
    <div className={cx(styles.wrapper, props.isDesktop && styles.isDesktop)}>
      <div className={styles.leftBtn} onClick={ev => handleQuantityChange(ev, SUBTRACT)}><span>-</span></div>
      <div className={styles.quantity}><span>{props.quantity}</span></div>
      <div className={styles.rightBtn} onClick={ev => handleQuantityChange(ev, ADD)}><span>+</span></div>
    </div>
  )
}

export default QuantityChooser
