import React, { useEffect, useRef, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'
import Button from '../../common/Button/Button'
import useFn from '../../../hooks/useFn'
import EventsModal from '../../common/EventsModal/EventsModal'
import { STATIC_PAGE_HANDLES } from '../../../containers/Common/StaticModalContainer'

import styles from './FreeGiftMobileHint.module.css'
import { withDrawer } from '@components/common/Drawer/Drawer'


const FreeGiftWithEmail = props=> {
  const { Trans } = props.$i18n
  const inputRef = useRef()
  const [email, setEmail] = useState('')

  const handleSubmit = () => {
    if (email && email.lastIndexOf('@') > -1) {
      return props.onSubmit(email)
    } else {
      props.$toast(props.$i18n.transl('core.promotion.checkEmail'))
    }
  }


  return (
    <div className={styles.emailWrapper}>
      <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
      <div className={styles.freeGiftImage}>
        <Image src={props.productImage} tiny />
      </div>
      <div className={styles.rightWrapper}>
        <div className={styles.title}>{ props.$i18n.transl('core.promotion.freeGift.freeGiftWithCoupon', {
          couponName: props.couponName
        })}</div>
        <div className={styles.inputRow}>
          <input
            ref={inputRef}
            type="email"
            className={styles.mainInput}
            value={email}

            placeholder={props.$i18n.transl('core.promotion.inputEmail')}
            onChange={e => setEmail(e.target.value)}
          />
          <Button ref={props.buttonRef} className={styles.btn} onClick={handleSubmit}>
            {props.$i18n.transl('core.promotion.freeGift.claimNow')}
          </Button>
      </div>
      <div className={styles.privacyWrapper}>
          <Trans i18nKey='terms.agreeWithPrivacy'>
            By registering, you agree to our<EventsModal className={styles.underline} handle={STATIC_PAGE_HANDLES.PRIVACY_POLICY}> Privacy Policy </EventsModal>
          </Trans>
        </div>
      </div>
    </div>
  )
}

const FreeGiftWithEmailDrawer = withDrawer(FreeGiftWithEmail)


const FreeGiftMobileHint = withPlugin(props => {
  const hasEmail = !!props.$user.getAnonymousLoginEmail()
  const takenButtonRef = useRef()
  const drawerButtonRef = useRef()
  const [showEmailInput, setShowEmailInput] = useState(false)


  const trackView = useFn(() => {
    props.$track.event({ category: 'Wheel', action: `view_free_gift`, label: !hasEmail ? 'emailRequired' : '' })
  })


  useEffect(() => {
    trackView()
  }, [trackView])

  const handleClick = () => {
    if (props.link) {
      props.$router.navigate(props.link)
    }
  }

  const handleClickClaimIt = () => {
    if (hasEmail) {
      return props.onTake({
        triggerRef: takenButtonRef,
        skuId: props.skuId
      })
    } else {
      setShowEmailInput(true)
    }
  }

  const handleEmailSubmit = (email) => {
    props.$track.trackEmail(email, 'freeGift')
    props.$user.saveAnonymousLoginEmail(email)

    props.$store.couponHub.take({ id: props.couponId })

    return props.onTake({
      triggerRef: drawerButtonRef,
      skuId: props.skuId,
      trackLabel: 'emailRequired'
    }).then(() => {
      props.$toastSuccess(props.$i18n.transl('core.promotion.freeGift.claimSuccessfully'))
    })
  }

  return (
    <>
      <div className={styles.wrapper} style={props.style}>
        <div className={styles.card}>
          <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
          <div className={styles.freeGiftImage} onClick={handleClick}>
            <Image src={props.productImage} tiny />
          </div>
          <div className={styles.title}>{ hasEmail ? props.$i18n.transl('core.promotion.freeGift.youHaveAFreeGift') : props.$i18n.transl('core.promotion.freeGift.freeGiftWithCoupon', {
            couponName: props.couponName
          })}</div>
          <Button ref={takenButtonRef} className={styles.btn} onClick={handleClickClaimIt}>
            {props.$i18n.transl('core.promotion.freeGift.claimIt')}
          </Button>
        </div>
      </div>
      {
        showEmailInput &&
        <FreeGiftWithEmailDrawer
          buttonRef={drawerButtonRef}
          {...props}
          onClose={() => setShowEmailInput(false)}
          onSubmit={handleEmailSubmit}
        />
      }
    </>
  )
})

export default FreeGiftMobileHint