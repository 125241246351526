import { BaseService } from '@flamingo_tech/funkgo'
import ActivityModel from '../models/common/ActivityModel'
import AthenaModel from '../models/common/AthenaModel'

import {
  retryIfTimeout
} from '../utils/requestUtils'

class LayoutService extends BaseService {
  _fetchActivity(handle, withProducts) {
    const params = {
      lft: 'web'
      // specific link format as web; since if run on app webview, the client-id may be "ios" or "android"
      // , then it will receive the link with app scheme format
    }

    if (withProducts) {
      params.spu = 1
    }

    return this.get(`/client/api/v3/activities/${handle}`, {
      params,
    }).then(
      data => new ActivityModel(data)
    )
  }

  _fetchAthena(handle, isDesktop) {
    const headers = {}

    if (isDesktop) {
      headers['X-client-id'] = 'pc'
    }

    return this.get(`/marketing/api/v2/lce/makeup`,
    {
      headers,
      params: {
        handle
      },
    })
      .then(
        data => {
          return new AthenaModel(data)
        }
      )
  }

  fetchActivity(...args) {
    return retryIfTimeout(
      () => this._fetchActivity(...args)
    )
  }

  fetchAthena(...args) {
    return retryIfTimeout(
      () => this._fetchAthena(...args)
    )
  }
}

export default LayoutService