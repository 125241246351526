import queryString from 'query-string'

/* --------------------------------------------------- */

export const attachParamsToUrl = (location, args) => {
  const originalSearch = location && location.search
    ? queryString.parse(location.search)
    : {}

  return {
    search: {
      ...originalSearch,
      ...args,
    }
  }
}

export const attachParamsToHash = args => {
  if (typeof window !== 'undefined') {
    const existsHash = queryString.parse(window.location.hash || '')
    window.location.hash = queryString.stringify({
      ...existsHash,
      ...args
    })
  }
}

export const attachParamsToSearch = args => {
  if (typeof window !== 'undefined') {
    const location = window.location
    const origin = location.origin
    const pathname = location.pathname.replace(/\/$/g, '')
    const existsQuery = queryString.parse(window.location.search || '')
    const search = '?' + queryString.stringify({
      ...existsQuery,
      ...args
    })
    const hash = location.hash
    const url = origin + pathname + search + hash
    window.history.replaceState(null, null, url)
  }
}

export const extractParamsFromUrl = (location, keys) => {
  const params = {}

  if (!location) {
    return params
  }

  const hash = queryString.parse(location.hash)

  const query = queryString.parse(location.search)

  if (Array.isArray(keys) && keys.length) {
    keys.forEach(key => {
      if (hash[key]) {
        params[key] = hash[key]
      } else if (query[key]) {
        params[key] = query[key]
      }
    })
  }

  return params
}

/* --------------------------------------------------- */

export const isKeyAssociateToTab = (tab, tabKey) => {
  if (!tab || typeof tabKey === 'undefined') {
    return false
  }

  return String(tab.key) === String(tabKey)
}

export const findTabIndexByKey = (tabs, tabKey) => {
  let index = -1

  if (Array.isArray(tabs)) {
    tabs.every((tab, i) => {
      if (isKeyAssociateToTab(tab, tabKey)) {
        index = i
        return false
      }
      return true
    })
  }

  return index
}

export const findTabByKey = (tabs, tabKey) => {
  let index = findTabIndexByKey(tabs, tabKey)
  return index > -1 ? tabs[index] : undefined
}

/* ----------------------------------------- */

export const TAB_PARAM_NAME = 'tab'

export const getDefaultTab = props => {
  if (props.currentTab) {
    return props.currentTab
  }

  const tabs = props.tabs

  if (!Array.isArray(tabs) || !tabs.length) {
    return undefined
  }

  let expectedTab
  if (props.defaultTabKey) {
    expectedTab = findTabByKey(tabs, props.defaultTabKey)
  }

  if (!expectedTab) {
    expectedTab = tabs[0]
  }

  const location = props.$router
    ? props.$router.location
    : props.location

  if (location && props.autoTab !== false) {
    const paramNames = [TAB_PARAM_NAME, props.tabParamName].filter(Boolean)
    const params = extractParamsFromUrl(location, paramNames)
    const tabKey = props.tabParamName
      ? params[props.tabParamName] || params[TAB_PARAM_NAME]
      : params[TAB_PARAM_NAME]

    if (tabKey) {
      const tabFromUrl = findTabByKey(tabs, tabKey)

      if (tabFromUrl) {
        expectedTab = tabFromUrl
      }
    }
  }
  return expectedTab
}

export const getDefaultTabKey = (...args) => {
  const tab = getDefaultTab(...args)
  return tab.key
}

export const attachTabKeyToHash = (tab, tabParamName = TAB_PARAM_NAME) => {
  if (tab && typeof tab.key !== 'undefined') {
    attachParamsToHash({ [tabParamName]: tab.key })
  }
}

export const extractTabKeyFromHash = (tabParamName = TAB_PARAM_NAME) => {
  if (typeof window === 'undefined') {
    return undefined
  }

  const hash = queryString.parse(window.location.hash)
  return hash[tabParamName]
}

export const mixinTabKeyToLink = (tab, location, tabParamName = TAB_PARAM_NAME) => {
  if (tab && typeof tab.key !== 'undefined') {
    return attachParamsToUrl(location, { [tabParamName]: tab.key })
  }
  return location
}

export const attachTabKeyToSearch = (tab, tabParamName = TAB_PARAM_NAME) => {
  if (tab && typeof tab.key !== 'undefined') {
    attachParamsToSearch({ [tabParamName]: tab.key })
  }
}

export const extractTabKeyFromQuery = (tabParamName = TAB_PARAM_NAME) => {
  if (typeof window === 'undefined') {
    return undefined
  }

  const search = queryString.parse(window.location.search)
  return search[tabParamName]
}