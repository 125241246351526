import React, { PureComponent } from 'react'
import { animateScroll } from 'react-scroll'
import Shell from '../Shell/PageShellContainer'
import withGlobalSettingEnabled from '../../hooks/withGlobalSettingEnabled'

import CartRecommendationsPage from '../../components/Store/Product/CartRecommendationsPage'


class CartRecommendationsContainer extends PureComponent {
  static Shell = Shell

  state = {
    productsState: {},
    displayProducts: [],
    currentTabIndex: 0,
    canShow: false,
    loading: false
  }

  cartRecommendationRef = React.createRef()

  componentDidMount(){
    const { tabs } = this.props
    if (tabs && tabs.length > 0) {
      const productsState = this.state.productsState
      tabs.forEach((tab, index) => {
        productsState[index] = {
          products: [],
          hasNextPage: true,
          page: 1,
        }
      })
      this.setState({ productsState, canShow: true }, () => {
        if (this.props.isDesktop) {
          this.fetchCollection(this.state.currentTabIndex)
        }
      })
    }
  }

  fetchCollection = (tabIndex) => {
    const collectionHandle = this.props.tabs[tabIndex].collectionHandle
    if (this.state.loading || !collectionHandle) {
      return
    }
    this.setState({
      loading: true
    })

    const productsState = this.state.productsState
    const productsData = productsState[tabIndex]
    const { products: concatProducts, page, extraCursor } = productsData
    this.props.productService.fetchESCollection(
      collectionHandle,
      {
        pageSize: this.props.isDesktop ? 30 : 15,
        page: page,
        extraCursor
      }
    )
    .then(res => {
      const { products, pageInfo: { hasNextPage }, extraCursor } = res
      const nextProducts = concatProducts.concat(products)
      productsData.hasNextPage = hasNextPage
      productsData.products = nextProducts
      productsData.page++
      productsData.extraCursor = extraCursor
      productsState[tabIndex] = productsData
      this.setState({
        productsState: productsState,
        displayProducts: nextProducts,
        loading: false
      })
    })
    .catch(() => {
      this.setState({
        loading: false
      })
    })
  }

  handleChangeTabIndex = index => {
    if (!this.props.isDesktop) {
      this.scrollToYAML()
    }
    setTimeout(() => {
      const { productsState } = this.state
      const { products = [] } = productsState[index]
      if (products.length === 0) {
        this.fetchCollection(index)
      } else {
        this.setState({
          displayProducts: products,
        })
      }
      this.setState({
        currentTabIndex: index,
      })
    }, 0)
  }

  onReachEnd = () => {
    this.fetchCollection(this.state.currentTabIndex)
  }

  scrollToYAML = () => {
    if (this.cartRecommendationRef && this.cartRecommendationRef.current) {
      animateScroll.scrollTo(this.cartRecommendationRef.current.offsetTop - 50, {
        duration: 0,
        smooth: 'linear'
      })
    }
  }

  render() {
    if (this.state.canShow) {
      const { productsState, currentTabIndex, displayProducts } = this.state
      const { hasNextPage } = productsState[currentTabIndex]

      return (
        <div ref={this.cartRecommendationRef}>
          <CartRecommendationsPage
            loading={this.state.loading}
            tabs={this.props.tabs}
            currentTabIndex={this.state.currentTabIndex}
            handleChangeTabIndex={this.handleChangeTabIndex}
            products={displayProducts}
            onReachEnd={this.onReachEnd}
            hasNextPage={hasNextPage}
            {...this.props}
          />
        </div>
      )
    }
    return null
  }
}

export default withGlobalSettingEnabled(CartRecommendationsContainer, 'cartYMAL')
