import React, { PureComponent } from 'react'

import Shell from '../Shell/PageShellContainer'

import { withGlobalSetting } from '../../hooks/withGlobalSettingEnabled'

import EmptyCartRecommendationsPage from '../../components/Store/Product/EmptyCartRecommendationsPage'


class EmptyCartRecommendationsContainer extends PureComponent {
  static Shell = Shell

  render() {
    return (
      <EmptyCartRecommendationsPage
        {...this.props}
      />
    )
  }
}

export default withGlobalSetting(EmptyCartRecommendationsContainer, 'defaultRecommendations')
