import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import ShoppingSecurityModal from '../ShoppingSecurityModal/ShoppingSecurityModal'
import Image from '../Image/Image'
import { getPaymentMethods, SECURITY_CERTIFICATION } from '../../Store/Cart/Acceptions'
import EventsModal from '../EventsModal/EventsModal'
import { STATIC_PAGE_HANDLES } from '../../../containers/Common/StaticModalContainer'
import PurchaseProtectionModal from '../PurchaseProtectionModal/PurchaseProtectionModal'

import styles from './ShoppingSecurity.module.css'
import cx from '../../../utils/className'

// 商详 - 总模块
export const ShoppingSecurity = withPlugin(props => {
  const [show, setShow] = useState(false)
  const isDesktop = props.$detector.isDesktop()

  return (
    <>
      <div className={cx(styles.card, styles.shield, styles.arrow, isDesktop && styles.isDesktop, props.className)} onClick={() => setShow(true)}>
        <div className={cx(styles.title, styles.green, props.titleClassName)}>
          {props.$i18n.transl('core.security.shoppingSecurity')}
          {
            isDesktop && <span className={styles.questionMark}>&#xe641;</span>
          }
        </div>
        <div className={styles.points}>
          <li>{props.$i18n.transl('core.security.safePayments.title')}</li>
          <li>{props.$i18n.transl('core.security.secureLogistics.title')}</li>
          <li>{props.$i18n.transl('core.security.securePrivacy.title')}</li>
          <li>{props.$i18n.transl('core.security.purchaseProtection.title')}</li>
        </div>
      </div>
      {
        show && (
          <ShoppingSecurityModal
            headerTitle={props.$i18n.transl('core.security.shoppingSecurity')}
            onClose={() => setShow(false)}
          />
        )
      }
    </>
  )
})

// 弹窗 - Safe payments
export const SafePayments = withPlugin(props => {
  const isDesktop = props.$detector.isDesktop()

  const handleNavigate = () => {
    props.$router.navigate('/store/event/payment-method')
  }

  return (
    <div className={cx(styles.card, styles.shield, isDesktop && styles.isDesktop, props.className)}>
      <div className={cx(styles.title, styles.green)}>{props.$i18n.transl('core.security.safePayments.title')}</div>
      <div className={styles.desc}>{props.$i18n.transl('core.security.safePayments.safePaymentsDesc')}</div>
      <div className={styles.subTitle}>{props.$i18n.transl('core.security.safePayments.paymentMethods')}</div>
      <div className={cx(styles.payMethodCardGroup, props.groupClassName)}>
        {
          getPaymentMethods(props.$site.getSiteInfo().countryCode).map(iconUrl => {
            return (
              <div className={styles.payMethodCard} key={iconUrl.key}>
                <Image src={iconUrl.imageUrl} placeholder={{ width: 40, height: 25 }} objectFit='contain' title='bank' small/>
              </div>
            )
          })
        }
      </div>
      <div className={styles.subTitle}>{props.$i18n.transl('core.security.safePayments.securityCertification')}</div>
      <div className={styles.securityCardGroup}>
        {
          SECURITY_CERTIFICATION.map(iconUrl => {
            return (
              <div className={styles.securityCard} style={{ width: iconUrl.width, height: iconUrl.height }} key={iconUrl.key}>
                <Image placeholder={{ width: iconUrl.width, height: iconUrl.height }} src={iconUrl.imageUrl} objectFit='contain' title='bank' small/>
              </div>
            )
          })
        }
      </div>
      <div className={styles.learnMore} onClick={handleNavigate}>{props.$i18n.transl('core.security.safePayments.learnMore')}</div>
    </div>
  )
})

// 弹窗 - Secure logistics
export const SecureLogistics = withPlugin(props => {
  const isDesktop = props.$detector.isDesktop()

  const handleNavigate = path => {
    props.$router.navigate(path)
  }

  return (
    <div className={cx(styles.card, styles.shoppingLogistics, isDesktop && styles.isDesktop, props.className)}>
      <div className={cx(styles.title, styles.green)}>{props.$i18n.transl('core.security.secureLogistics.title')}</div>
      <div className={styles.section}>
        <div className={styles.subTitle}>{props.$i18n.transl('core.security.secureLogistics.packageSafety')}</div>
        <div className={styles.desc}>{props.$i18n.transl('core.security.secureLogistics.packageSafetyDesc')}</div>
        <div className={styles.learnMore} onClick={() => handleNavigate('/store/event/returns-and-refunds')}>{props.$i18n.transl('core.security.safePayments.learnMore')}</div>
      </div>
      <div className={styles.section}>
        <div className={styles.subTitle}>{props.$i18n.transl('core.security.secureLogistics.deliveryGuaranteed')}</div>
        <div className={styles.desc}>{props.$i18n.transl('core.security.secureLogistics.deliveryGuaranteedDesc')}</div>
        <div className={styles.learnMore} onClick={() => handleNavigate('/store/order')}>{props.$i18n.transl('core.security.secureLogistics.checkYourOrder')}</div>
      </div>
    </div>
  )
})

// 弹窗 - Secure privacy
export const SecurePrivacy = withPlugin(props => {
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={cx(styles.card, styles.securePrivacy, isDesktop && styles.isDesktop, props.className)}>
      {
         !props.hideTitle && <div className={cx(styles.title, styles.green, props.titleClassName)}>{props.$i18n.transl('core.security.securePrivacy.title')}</div>
      }
      <div className={cx(styles.desc, props.descClassName)}>{props.$i18n.transl('core.security.securePrivacy.securePrivacyDesc')}</div>
      <EventsModal handle={STATIC_PAGE_HANDLES.PRIVACY_POLICY}><div className={styles.learnMore}>{props.$i18n.transl('core.security.safePayments.learnMore')}</div></EventsModal>
    </div>
  )
})

// 弹窗 - Purchase protection
export const PurchaseProtection = withPlugin(props => {
  const [show, setShow] = useState(false)
  const isDesktop = props.$detector.isDesktop()

  const handleNavigate = () => {
    if (isDesktop) {
      props.$router.navigate('/store/purchase-protection')
    } else {
      setShow(true)
    }
  }

  return (
    <>
      <div className={cx(styles.card, isDesktop && styles.isDesktop, styles.purchaseProtection, props.className)}>
        {
          !props.hideTitle && <div className={cx(styles.title, styles.green, props.titleClassName)}>{props.$i18n.transl('core.security.purchaseProtection.title')}</div>
        }
        <div className={cx(styles.desc, props.descClassName)}>{props.$i18n.transl('core.security.purchaseProtection.purchaseProtectionDesc')}</div>
        <div className={styles.learnMore} onClick={handleNavigate}>{props.$i18n.transl('core.security.safePayments.learnMore')}</div>
      </div>
      {
        show && <PurchaseProtectionModal onClose={() => setShow(false)} headerTitle={props.$i18n.transl('core.security.purchaseProtection.title')} />
      }
    </>
  )
})

// 购物车 - Secure options in checkout
export const CheckoutSecure = withPlugin(props => {
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={cx(styles.card, isDesktop && styles.isDesktop, styles.shield, props.className)}>
      <div className={cx(styles.title, styles.green, props.titleClassName)}>{props.$i18n.transl('core.security.secureInCheckout')}</div>
      <div className={cx(styles.payMethodCardGroup, props.groupClassName)}>
        {
          getPaymentMethods(props.$site.getSiteInfo().countryCode).map(iconUrl => {
            return <Image src={iconUrl.imageUrl} objectFit='contain' title='bank' className={styles.payMethodCard} key={iconUrl.key} small/>
          })
        }
      </div>
    </div>
  )
})

// 结算页 - Security certification
export const SecurityCertification = withPlugin(props => {
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={cx(styles.card, isDesktop && styles.isDesktop, styles.shield, props.className)}>
      {
        !props.hideTitle && <div className={cx(styles.title, styles.green, props.titleClassName)}>{props.$i18n.transl('core.security.safePayments.securityCertification')}</div>
      }
      <div className={cx(styles.desc, props.descClassName)}>{props.$i18n.transl('core.security.safePayments.safePaymentsDesc')}</div>
      <div className={cx(styles.securityCardGroup, props.groupClassName)}>
        {
          SECURITY_CERTIFICATION.map(iconUrl => {
            return (
              <div className={styles.securityCard} style={{ width: iconUrl.width, height: iconUrl.height }} key={iconUrl.key}>
                <Image placeholder={{ width: iconUrl.width, height: iconUrl.height }} src={iconUrl.imageUrl} objectFit='contain' title='bank' small/>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
)
