export const getCartLineItemCount = (cart) => {
  return (cart && cart.itemQuantity) || 0
}

export const getCartCheckoutItemCount = (cart) => {
  let quantity = 0

  if (!cart) {
    return quantity
  }
  
  cart.lineItems.forEach(item => {
    if (item.saleable && item.selectedFlag) {
      quantity += item.quantity
    }
  })
  return quantity
}

export const SELECTED_STATUS = { // ALL(1, "全选"),PARTIAL(2, "部分选中"), NONE(3, "全部不选中");
  ALL: 1,
  PARTIAL: 2,
  NONE: 3
}