import React, { PureComponent } from 'react'

import LoginFallbackPage from '../components/User/LoginFallbackPage'
import LoadingPage from '../components/common/LoadingPage/LoadingPage'
import { hoistHocStatic, withPlugin } from '@flamingo_tech/funkgo'

/*
  set needUser = true which means component rendered until user logined, pls input FallbackComponent when user refuse to login;
  set needUser = false which means component rendered without login info, provide injectLoginMethods to business side;
  no matter what, user props can be accessible
*/
const withUserAndPlugins = (
  Component,
  {
    needUser = false,
    FallbackComponent = LoginFallbackPage,
    LoadingComponent = LoadingPage,
    eventCategory = Component.name.split('Container')[0]
  } = {},
  staticProps
) => {
  const displayName = `withUserAndPlugins(${Component.displayName || Component.name})`
  const WrappedComponent = class extends PureComponent {
    _isMounted = false

    state = {
      user: undefined,
      isFetchingUser: true
    }

    $track = (action, label) => {
      this.props.$track.event({
        category: eventCategory,
        action,
        label,
        nonInteraction: true
      })
    }

    login = (...args) => {
      const isApp = this.props.$detector && this.props.$detector.isApp()
      const login = isApp ? this.props.$user.appLogin(...args) : this.props.$user.login(...args)
      return login.then(
        user => {
          this.$track('login_success')
          this.updateUser(user)
        },
        err => {
          this.updateUser(null)
          throw err
        }
      )
    }

    refreshUser = () => {
      return this.props.$user.refresh().then(
        user => {
          this.updateUser(user)
        },
        err => {
          this.updateUser(null)
        }
      )
    }

    componentDidMount() {
      this._isMounted = true
      this.props.$user.getUserInfo().then(
        user => {
          this.updateUser(user)
        },
        () => {
          this.updateUser(null)
          const isDesktop = this.props.$detector.isDesktop()
          if (this._isMounted && needUser && !isDesktop) {
            this.login().catch(() => undefined)
          }
        }
      )

      this.props.$user.observeUser(this.handleUserChange)
    }

    componentWillUnmount() {
      this._isMounted = false
      this.props.$user.unobserveUser(this.handleUserChange)
    }

    handleUserChange = user => {
      this.updateUser(user)
    }

    updateUser(user) {
      if (this._isMounted && user !== this.state.user) {
        this.setState({
          user,
          isFetchingUser: false
        })
      }
    }

    composeLogin = (onLoginSuccess, onLoginError) => (...args) => {
      if (this.state.user) {
        return Promise.resolve(onLoginSuccess(...args))
      } else {
        return this.login().then(
          () => onLoginSuccess(...args),
          err => {
            if (typeof onLoginError === 'function') {
              onLoginError(err)
            }
          }
        )
      }
    }

    handleSkipLogin = () => {
      this.props.$router.navigateToHomePage()
    }

    handleLogin = () => this.login()

    render() {
      const isDesktop = this.props.$detector.isDesktop()

      if (!needUser || this.state.user) {
        return (
          <Component
            {...this.props}
            $login={this.login}
            $refreshUser={this.refreshUser}
            $ensureLoginCompose={this.composeLogin}
            user={this.state.user}
            isFetchingUser={this.state.isFetchingUser}
          ></Component>
        )
      } else if (this.state.isFetchingUser) {
        return (
          <LoadingComponent />
        )
      } else if (needUser && isDesktop) {
        const LoginPanel = this.props.$user.getLoginPanel({ isPage: true })
        return <LoginPanel />
      } else {
        return (
          <FallbackComponent onLogin={this.handleLogin} onSkipLogin={this.handleSkipLogin}/>
        )
      }
    }
  }

  hoistHocStatic(WrappedComponent, Component, staticProps)
  WrappedComponent.displayName = displayName
  return withPlugin(WrappedComponent)
}

export default withUserAndPlugins
