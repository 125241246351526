import Product, { ProductVariant } from '../../npm/FunkGoModel/ProductModel'

class TrackLineItemModel {
  constructor(data) {
    const mainImage = {
      src: data.mainImage,
      title: null
    }

    const productVariantMeta = {
      id: data.variantId,
      title: data.variantTitle,
      sku: data.sku,
      msrp: data.msrp,
      price: data.price
    }

    const productMeta = {
      id: data.id,
      handle: data.handle,

      variations: [
        productVariantMeta
      ],

      images: [
        mainImage
      ],

      // TODO
      options: [],

      title: data.productTitle,
      description: '',


      vendor: data.vendor,
      category: data.categoryName,
    }


    this.productVariant = new ProductVariant(productVariantMeta)
    this.product = new Product(productMeta)
    this.quantity = data.quantity
    this.totalPrice = data.itemTotalFee
    this.promotionActivities = data.promotionActivities
  }
}

class OrderDetailModel {
  constructor(data) {
    Object.assign(this, {
      id: data.id,
      acquisitionId: data.acquisitionId,
      status: data.orderStatus,
      orderStatusDesc: data.orderStatusDesc,
      orderNo: data.orderNo,
      typeName: data.typeName,
      email: data.email,
      shippingAddress: data.shippingAddress,
      currencyCode: data.currencyCode,
      currencySymbol: data.currencySymbol,
      products: data.items.map(item => ({
        imageSrc: item.skuImage,
        count: item.quantity,
        title: item.productTitle,
        desc: item.variantTitle,
        msrp: item.msrp,
        price: item.price,
        handle: item.handle,
        displayPrice: item.displayPrice,
        promotionActivities: item.promotionActivities
      })),
      checkOutInfo: {
        subtotal: data.totalProductPrice,
        discount: data.totalDiscounts,
        shipping: data.totalFreight,
        total: data.totalPrice,
        coinsFee: data.coinsFee,
        deductCoin: data.deductCoin,
        couponDiscount: data.couponDiscounts,
        displayTotalProductPrice: data.displayTotalProductPrice,
        displayCouponDiscounts: data.displayCouponDiscounts,
        displayCoinsFee: data.displayCoinsFee,
        displayTotalFreight: data.displayTotalFreight,
        displayTotalPrice: data.displayTotalPrice,
        checkoutItemCount: data.totalQuantity,
        couponId: data.couponId,
        couponName: data.couponName
      },
      obtainCoins: {
        coins: data.obtainCoins,
        coinsValue: data.obtainCoinsValue,
        displayObtainCoinsValue: data.displayObtainCoinsValue
      },
      trackModel: {
        totalPrice: data.totalPrice,

        // 	The sum of all the taxes applied to the line items and shipping lines in the checkout.
        totalTax: 0,

        // Price of the checkout before shipping and taxes.
        subTotalPrice: data.totalProductPrice,

        // The sum of all the prices of all the items in the checkout.
        // Taxes, shipping and discounts excluded.
        lineItemsSubtotalPrice: data.totalProductPrice,


        lineItems: data.items.map(item => new TrackLineItemModel(item)),
        // E: same as shopify order model


        // B: our own fields
        id: data.id,
        totalDiscount: data.couponDiscounts,
        totalDiscounts: data.totalDiscounts, // coupon, coins included
        totalShipping: data.totalFreight,

        couponId: data.couponId,
        trackCouponName: data.trackCouponName
        }
    })
  }
}

export default OrderDetailModel
