import React, { useMemo, useState } from 'react'
import cx from '../../../utils/className'
import styles from './ProductDealsPanel.module.css'
import { makeSearch } from '../../../utils/breadcrumbUtils'
import NullComponent from '../../Layout/NullComponent'
import ProductList from '../ProductList/ProductList'
import ProductDealsCard from '../ProductCard/ProductDealsCard'
import Countdown from '../../Widget/Countdown/Countdown'
import Image from '../../common/Image/Image'
import { withPlugin } from '@flamingo_tech/funkgo'

const productListComponent = {
  ProductList
}

const mapItem = is => {
  let Component
  if (!is) {
    Component = ProductList
  } else if (productListComponent[is]) {
    Component = productListComponent[is]
  } else {
    Component = NullComponent
    this.props.$logger(`[ProductDealsPanel] non-supported component type: "${is}"`)
  }

  return Component
}

const ProductDealsPanel = props => {
  const [updateDate, setDate] = useState(Date.now())
  const isDesktop = props.$detector.isDesktop()
  const { columnsNum, displayNum } = props

  const timestamp = useMemo(() => {
    if (!props.showCountdown) {
      return 0
    }
    const now = new Date(updateDate)
    const tomorrow = new Date(updateDate)
    tomorrow.setDate(now.getDate() + 1)
    tomorrow.setHours(0)
    tomorrow.setMinutes(0)
    tomorrow.setSeconds(0)

    return tomorrow.getTime() - now.getTime()
  }, [props.showCountdown, updateDate])

  const renderCountDown = () => {
    const { countdownTheme: { color = '#fff', background = '#000' } = {}, } = props

    if (props.showCountdown) {
      return (
        <div className={cx(styles.countDown, isDesktop && styles.inDesktop)}>
          <span style={{ color }}>{props.$i18n.transl('core.promotion.endsIn')}</span>
          <Countdown
            color={color}
            background={background}
            timestamp={timestamp}
            onFinished={() => setDate(Date.now())}
            className={styles.counts}
          ></Countdown>
        </div>
      )
    }
  }

  const generateToExtraInfo = () => {
    const childOptions = props.childOptions || {}
    const { definition } = childOptions
    const productCardOptions = childOptions.childOptions || {}
    const toExtraInfo = productCardOptions.toExtraInfo || {}

    const searchOptions = (definition && definition.collectionSource && definition.collectionSource.handle)
      ?
      makeSearch({
        title: childOptions.title,
        routeName: 'Collection',
        routeHandle: definition.collectionSource.handle
      })
      : {}

    return {
      ...toExtraInfo,
      search: {
        ...(toExtraInfo.search || {}),
        ...searchOptions
      }
    }
  }

  const ProductListComponent = mapItem(props.childIs)
  const childOptions = {
    ...(props.childOptions || {}),
    childOptions: {
      ...((props.childOptions || {}).childOptions || {}),
      toExtraInfo: generateToExtraInfo(),
    },
    style: {
      textColor: (props.childOptions || {}).textColor,
      childImage: (props.childOptions || {}).childImage,
    },
    pageInfo: { // not fetch data any more
      hasNextPage: false
    }
  }

  const products = props.products || childOptions.products

  const { backgroundColor = '#fff', headerImage, headerImageWidth, headerImageHeight } = props

  const wrapperStyle = { backgroundColor }
  const cardOptions = {
    routerName: 'Product',
    toExtraInfo: generateToExtraInfo()
  }

  return (
    <>
      <div className={styles.header}>
        {
          headerImage &&
          <>
            <Image
              src={headerImage}
              objectFit='cover'
              className={styles.panelImage}
              placeholder={{
                width: headerImageWidth,
                height: headerImageHeight
              }}
            />
            {renderCountDown()}
          </>
        }
      </div>
      <div className={cx(styles.body, isDesktop && styles.inDesktop)} style={wrapperStyle}>
        <div>
          <ProductListComponent
            {...childOptions}
            cardIs={ProductDealsCard}
            columns={columnsNum}
            cardOptions={cardOptions}
            products={products.slice(0, displayNum)}
            className={styles.productSection}
          />
        </div>
      </div>
    </>
  )
}

export default withPlugin(ProductDealsPanel)
