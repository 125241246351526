import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import AppStateContext from '../../hooks/AppStateContext'
import { OutApp } from '../../hooks/InApp'
import DownloadBanner from '../../components/common/DownloadBanner/DownloadBanner'

const DownloadBannerContainer = props => {
  const showDownloadBanner = props.showDownloadBanner
  const appState = useContext(AppStateContext)
  const isReturningUser = appState.userInfo && appState.userInfo.isReturning
  const plugin = useContext(PluginContext)
  const isDesktop = plugin && plugin.$detector.isDesktop()

  const isFromAd = () => {
    const sourceLocationStorage = plugin && plugin.$storage.create('source_location', { strategy: 'SESSION'})
    const sourceLocation = sourceLocationStorage.getItem(window.location.href)
    const utmSourceReg = /utm_source=(facebookads|googleads|tiktokads|Facebook|Google|TikTok|affiliate)/
    const adReg = /(adgroupid|campaignid)/
    const isFromAd = utmSourceReg.test(sourceLocation) || adReg.test(sourceLocation)
    return isFromAd
  }

  return (
    <>
      {
        showDownloadBanner && isReturningUser && !isFromAd() && !isDesktop && (
          <OutApp>
            <DownloadBanner page={props.downloadPage}/>
          </OutApp>
        )
      }
    </>
  )
}

export default DownloadBannerContainer 