import React, { useContext } from 'react'

import { withGlobalSetting } from '../../../hooks/withGlobalSettingEnabled'
import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '@components/common/Image/Image'
import FreeShippingBanner from '../FreeShippingBanner/FreeShippingBanner'

import styles from './PromotionBanner.module.css'


const PromotionBanner = ({ mobile, pc }) => {

  const plugin = useContext(PluginContext)

  const data = plugin.$detector.isDesktop() ? pc : mobile

  if (!data || !data.src) {
    return  <FreeShippingBanner />
  }

  const { width, height, src, link } = data

  const handleImageClick = () => {
    plugin.$track.event('PromotionBanner', 'click')

    if (link) {
      plugin.$router.navigate(link)
    }
  }

  return (
    <div className={styles.wrapper}>
      <Image src={src} placeholder={{ width, height}} original onClick={handleImageClick}/>
    </div>
  )
}

export default withGlobalSetting(PromotionBanner, 'promotionBanner')
