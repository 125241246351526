import React, { useEffect, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './CartBuoy.module.css'
import cx from '../../../utils/className'


const AddCartTip = props => {
  const [show, setShow] = useState(false)
  const [num, setNum] = useState(0)

  useEffect(() => {
    setNum(props.itemQuantity)
  }, [props.itemQuantity])

  useEffect(() => {
    if (props.hasAddCart && num !== props.itemQuantity) {
      setTimeout(() => {
        setShow(true)
        setTimeout(() => {
          setShow(false)
        }, 800)
      }, 300)
    }
  }, [props, num])

  if (!show) {
    return null
  }

  return <div className={cx(styles.addCart)} >+1</div>
}

class CartBuoy extends React.Component {
  render() {
    const { cartLineItemCount, onClick = () => {} } = this.props
    const cart = this.props.cart || {}
    const cartOperation = this.props.cartOperation || {}

    return (
      <div className={cx(styles.buoyWrapper)} onClick={onClick} id="atc_parabola_destination_main">
        <AddCartTip itemQuantity={cart.itemQuantity} hasAddCart={cart.itemQuantity > 0 && cartOperation.add} />
        <div className={styles.icon}>&#xe61c;</div>
        {
          cartLineItemCount > 0 &&
          <div className={styles.count}>{cartLineItemCount > 99 ? '99+': cartLineItemCount}</div>
        }
      </div>
    )
  }
}

export default withPlugin(CartBuoy)
