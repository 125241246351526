import React, { useContext, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'


import ProductSizeChartSection from './ProductSizeChartSection'
import ProductDescription from './ProductDescription'
import { ProductModelSection } from './ProductModel'

import ProductSection from './ProductSection'

const DescriptionSection = props => {
  const plugin = useContext(PluginContext)

  const {
    models
  } = props.product

  useEffect(() => {
    if (!plugin.$i18n) {
      props.$track.event('PluginError', 'plugin $i18n is undefined')
    }
  })

  return (
    <ProductSection>
      <ProductDescription
        $i18n={props.$i18n}
        onClick={props.showDescModal}
      />

      <ProductSizeChartSection
        $i18n={props.$i18n}
        onClick={props.showSizeChartModal}
      />

      <ProductModelSection models={models} />
    </ProductSection>
  )
}

export default DescriptionSection
