import React from 'react'

import CategorySegment from '../ProductCategory/CategorySegment'

import styles from './CategorySegmentsColumn.module.css'
import cx from '../../../utils/className'
import { withPlugin } from '@flamingo_tech/funkgo'

const CategorySegmentsColumn = props => {
  const categoryListSegments = props.items || []

  if (categoryListSegments.length <= 0) {
    return null
  }

  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
      {
        categoryListSegments.length > 0 &&
        <div className={styles.content}>
          {
            categoryListSegments.map((segment, index) => (
              <div key={index}>
                <CategorySegment {...segment.options} parentCategoryTitle={props.parentCategoryTitle} handleHoverMask={props.handleHoverMask} />
              </div>
            ))
          }
        </div>
      }
    </div>
  )
}

export default withPlugin(CategorySegmentsColumn)