import React from 'react'
import cx from '../../../../utils/className'
import styles from './ProductCommentSectionHeaderConfig.module.css'
import { SELECT_TYPE } from '../../../common/Select/Select'
import { CATEGORY } from '../../../common/SpecList/SpecList'

const FILTER_TYPE = {
  COLOR_SIZE: 'COLOR_SIZE', // 颜色和尺寸
  SORTBY: 'SORTBY', // sort
  PC_STAR: 'STAR', // PC-评分
  PC_PICTURE: 'PC_PICTURE', // PC-图片
  MOBILE_FILTER: 'MOBILE_FILTER' // H5-filter
}
const pcTabOptionList = () => [
  {
    name: 'core.product.allReviews',
    code: false,
    selected: true
  },
  {
    name: 'core.product.image',
    code: true,
    selected: false
  }
]
const getSortByOptionList = ($i18n) => [
  {
    name: $i18n.transl('core.product.recommended'), // 默认值
    code: 1
  },
  {
    name: $i18n.transl('core.product.mostRecent'),
    code: 2
  }
]
const getStarOptionList = (isDesktop) => [
  {
    name: (
      <span
        className={cx(styles.starOptionName, isDesktop && styles.inDesktop)}
      >
        5<i className={styles.starIcon}>&#xe607;</i>
      </span>
    ),
    code: 5
  },
  {
    name: (
      <span
        className={cx(styles.starOptionName, isDesktop && styles.inDesktop)}
      >
        4<i className={styles.starIcon}>&#xe607;</i>
      </span>
    ),
    code: 4
  },
  {
    name: (
      <span
        className={cx(styles.starOptionName, isDesktop && styles.inDesktop)}
      >
        3<i className={styles.starIcon}>&#xe607;</i>
      </span>
    ),
    code: 3
  },
  {
    name: (
      <span
        className={cx(styles.starOptionName, isDesktop && styles.inDesktop)}
      >
        2<i className={styles.starIcon}>&#xe607;</i>
      </span>
    ),
    code: 2
  },
  {
    name: (
      <span
        className={cx(styles.starOptionName, isDesktop && styles.inDesktop)}
      >
        1<i className={styles.starIcon}>&#xe607;</i>
      </span>
    ),
    code: 1
  }
]
const getFilerOptionList = (isDesktop, $i18n) => [
  {
    name: CATEGORY.PICTURE.name,
    displayName: CATEGORY.PICTURE.displayName,
    values: [
      {
        name: $i18n.transl('core.product.withPicture'),
        code: true
      }
    ]
  },
  {
    name: CATEGORY.RATING.name,
    displayName: CATEGORY.RATING.displayName,
    values: getStarOptionList(isDesktop)
  }
]
const getMobileTabList = (props) => {
  const { colorOrSizeOptionList, $i18n } = props
  const isDesktop = props.$detector.isDesktop()

  return [
    {
      name: $i18n.transl('core.product.colorOrSize'),
      type: FILTER_TYPE.COLOR_SIZE,
      optionData: colorOrSizeOptionList,
      value: null
    },
    {
      name: $i18n.transl('core.product.sort'),
      type: FILTER_TYPE.SORTBY,
      optionData: getSortByOptionList($i18n),
      value: null
    },
    {
      name: $i18n.transl('core.product.filter'),
      type: FILTER_TYPE.MOBILE_FILTER,
      optionData: getFilerOptionList(isDesktop, $i18n),
      value: null
    }
  ]
}

export {
  CATEGORY,
  FILTER_TYPE,
  SELECT_TYPE,
  pcTabOptionList,
  getSortByOptionList,
  getStarOptionList,
  getMobileTabList
}
