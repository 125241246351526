import UserModel from '../common/UserModel'
import { toDecimal } from '../../utils/numberUtils'

class ProductCommentModel {
  constructor(data) {
    this.id = data.commentId
    this.user = new UserModel({
      name: data.userName,
      image: data.avatar
    }) || {}
    this.star = toDecimal(data.star)
    this.title = data.title
    this.content = data.content
    this.publishTime = data.createTime *  1000
    this.images = data.pictures || []
    this.overallFit = data.overallFit
    this.sku = data.specifications
    this.circumferences = data.circumferences || []
  }
}

export default ProductCommentModel
