import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import ProductSection from './ProductSection'

import withGlobalSettingEnabled from '../../../hooks/withGlobalSettingEnabled'
import withShippingInfo from '../../../hooks/withShippingInfo'
import ShippingInfoPage from './ShippingInfoPage'
import { ShoppingSecurity } from '../../common/ShoppingSecurity/ShoppingSecurity'

import cx from '../../../utils/className'
import styles from './CommitmentSection.module.css'
import { withDrawer } from '../../common/Drawer/Drawer'

const I18N_NAMESPACE = 'core.product.commitment'

const COMMITMENT_MODAL_CONTENT = [{
  title: `${I18N_NAMESPACE}.returnPolicy.content.0.title`,
  desc: `${I18N_NAMESPACE}.returnPolicy.content.0.desc`,
  subDesc: `${I18N_NAMESPACE}.returnPolicy.content.0.subDesc`,
}]

const commitmentPopupContent = props => (
  <div className={cx(styles.popupContent, props.isDesktop && styles.isDesktop)}>
    {
      COMMITMENT_MODAL_CONTENT.map((item, i) =>
        <div className={styles.sections} key={i}>
          <div className={styles.title}>{props.$i18n.transl(item.title)}</div>
          <div className={styles.desc}>{props.$i18n.transl(item.desc)}</div>
          {
            item.subDesc &&
            <div className={styles.subDesc}>{props.$i18n.transl(item.subDesc)}</div>
          }
        </div>
      )
    }
  </div>
)

const CommitmentPopup = withDrawer(commitmentPopupContent)

// 计算日期
const getRenderDays = ({ $site, shippingInfo }) => {

  if (shippingInfo.length === 0) {
    return ''
  }

  const now = new Date()
  const options = { weekday: 'short', month: 'short', day: 'numeric' }
  const { locale } = $site.getSiteInfo()
  let index
  let lastIndex
  let currentIndex

  shippingInfo.forEach((item, i) => {
    if (item.locales && item.locales.indexOf(locale) > -1) {
      index = i
    }
    lastIndex = i
  })

  currentIndex = typeof index === 'number' ? index : lastIndex

  const {
    ProcessingMinTime,
    ProcessingMaxtTime,
    transitMinTime,
    transitMaxTime
  } = shippingInfo[currentIndex] || {}

  now.setDate(now.getDate() + parseInt(Math.ceil((parseInt(ProcessingMinTime) + parseInt(transitMinTime) + parseInt(ProcessingMaxtTime) + parseInt(transitMaxTime)) / 2)))

  return {
    renderDays: now.toLocaleDateString(locale.replace('_', '-'), options).split(' ').slice(0,3).join(' '),
    currentIndex
  }
}

const ShippingInfoDrawer = withShippingInfo(withDrawer(ShippingInfoPage))

const ShippingCommitmentSetting = withShippingInfo(withGlobalSettingEnabled(({
  displayFreeShippingFee,
  isDesktop,
  $i18n,
  $site,
  shippingInfo = [],
  showShippingInfo,
  onShippingShow,
  onShippingHide
}) => {
  const { Trans } = $i18n
  const { renderDays, currentIndex } = getRenderDays({ $site, shippingInfo })

  return (
    <>
      <div className={cx(styles.guideInfo, isDesktop && styles.isDesktop)} onClick={onShippingShow}>
        <div className={styles.guideContent}>
          <div className={styles.guideTitle}>
            <Trans i18nKey={`${I18N_NAMESPACE}.freeShipping.title`}></Trans>{`(${displayFreeShippingFee}+)`}
            {
              isDesktop && <span className={styles.questionMark}>&#xe641;</span>
            }
          </div>
          <div className={styles.guideDesc}>{$i18n.transl('core.shippingAddress.estimatedDelivery')} : { renderDays } </div>
        </div>
      </div>
      {
        showShippingInfo && <ShippingInfoDrawer headerTitle={$i18n.transl(`core.shippingAddress.shippingDetails`)} onClose={onShippingHide} currentIndex={currentIndex} />
      }
    </>
  )
}, 'shipping'))

const ReturnPolicy = props => {
  const { Trans } = props.$i18n

  return (
    <div className={cx(styles.guideInfo, styles.returnPolicy, props.isDesktop && styles.isDesktop)} onClick={props.onClick}>
      <div className={styles.guideContent}>
        <div className={styles.guideTitle}>
          <Trans i18nKey={`${I18N_NAMESPACE}.returnPolicy.title`}></Trans>
          {
            props.isDesktop && <span className={styles.questionMark}>&#xe641;</span>
          }
        </div>
      </div>
    </div>
  )
}
class CommitmentSection extends PureComponent {
  state = {
    showCommitmentPopup: false,
    showShippingInfo: false
  }

  openCommitmentPopup = () => {
    this.setState({
      showCommitmentPopup: true
    })
  }

  closeCommitmentPopup = () => {
    this.setState({
      showCommitmentPopup: false
    })
  }

  onShippingShow = () => {
    this.setState({
      showShippingInfo: true
    })
  }

  onShippingHide = () => {
    this.setState({
      showShippingInfo: false
    })
  }

  render() {
    const isDesktop = this.props.$detector.isDesktop()
    const SectionWrapperComponent = isDesktop ?  props => (<>{props.children}</>) : ProductSection

    return (
      <SectionWrapperComponent>
        <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
          {
            !this.props.hideShippingStrategy &&
            <ShippingCommitmentSetting
              isDesktop={isDesktop}
              $i18n={this.props.$i18n}
              showShippingInfo={this.state.showShippingInfo}
              onShippingShow={this.onShippingShow}
              onShippingHide={this.onShippingHide}
            />
          }
          <ReturnPolicy onClick={this.openCommitmentPopup} isDesktop={isDesktop} $i18n={this.props.$i18n} />
          <ShoppingSecurity titleClassName={styles.shoppingSecurityTitle} className={cx(styles.shoppingSecurity, isDesktop && styles.isDesktop)} />
          {
            this.state.showCommitmentPopup && <CommitmentPopup headerTitle={this.props.$i18n.transl(`${I18N_NAMESPACE}.returnPolicy.title`)} onClose={this.closeCommitmentPopup} isDesktop={isDesktop} $i18n={this.props.$i18n}/>
          }
        </div>
      </SectionWrapperComponent>
    )
  }
}

export default withPlugin(CommitmentSection)
