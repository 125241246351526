import React, { PureComponent } from 'react'
import Shell from '../Shell/PageShellContainer'

import withAppState from '../../hooks/withAppState'
import withPostLike from '../../hooks/withPostLike'
import GirlsShowHomePage from '../../components/GirlsShow/GirlsShowHomePage'
import LoadingPage from '../../components/common/LoadingPage/LoadingPage'
import { animateScroll } from 'react-scroll'
import { createStorage } from '@flamingo_tech/funkgo'


import PostService from '../../services/PostService'
import styles from './GirlsShowHomeContainer.module.css'

import * as seoUtils from '../../utils/seoUtils'

const EVENT_CATEGORY = 'GirlsShowHome'

class PlainGirlsShowHome extends PureComponent {

  state = {
    posts: [],
    isLoading: false
  }

  pageNum = 1
  isFetching = false
  hasNextPage = true
  _isMounted = false

  postService = new PostService(this.props.$http)


  fetchPosts() {
    if (!this.isFetching && this.hasNextPage) {
      this.isFetching = true

      this.setState({
        isLoading: true
      })

      this.postService.fetchRecommendPosts({ pageNum: this.pageNum }).then((postList) => {
      this.isFetching = false
      this.pageNum += 1
      this.hasNextPage = postList.pageInfo.hasNextPage

      this.setState({
        posts: [...this.state.posts, ...postList.posts],
        isLoading: false
      })
    }).catch(() => {
      this.isFetching = false
      this.setState({
        isLoading: false
      })
    })
    }
  }

  handleReachEnd = () => {
    this.fetchPosts(this.state.currentTab)
  }

  handleUploadSuccess = images => {
    // store images then navigate to girlshow publish page
    this.props.$router.navigate({
      name: 'GirlsShowPublish',
      search: this.props.$router.location.search
    })
  }

  handleLogin = () => {
    this.props.$login()
  }


   // 使用上一次浏览当前 collection 停留的位置
   scrollToPreviousPosition = () => {
    // 禁用 dom scroll 动画效果
    document.getElementsByTagName('html')[0].setAttribute('class', styles.stay)
    if (this.props.runtimeData && this.props.runtimeData['girlsShowList']) {
      const { pageNum, hasNextPage, posts } = this.props.runtimeData['girlsShowList']

      this.pageNum = pageNum
      this.hasNextPage = hasNextPage

      this.setState({
        posts
      })

      setTimeout(() => {
        const scrollTopStorage = createStorage('girls_show_scroll_top', { strategy: 'SESSION' })
        if (scrollTopStorage.getItem()) {
          animateScroll.scrollTo(scrollTopStorage.getItem(0), {
            duration: 0,
            smooth: 'linear'
          })

        }
      }, 200)
    } else {
      animateScroll.scrollTo(0, {
        duration: 0,
        smooth: 'linear'
      })
    }
  }

   // 记住当前 collection scroll 位置，并记入到 runtime
  setPreviousScrollPosition = () => {
    document.getElementsByTagName('html')[0].removeAttribute('class')


    const scrollTopStorage = createStorage('girls_show_scroll_top', { strategy: 'SESSION'})
    scrollTopStorage.setItem(document.documentElement.scrollTop)

    this.props.setRuntimeData('girlsShowList', {
      pageNum: this.pageNum,
      hasNextPage: this.hasNextPage,
      posts: this.state.posts
    })
  }


  componentDidMount() {
    this.props.$track.event(EVENT_CATEGORY, 'enter_channel')
    // scroll to pre scroll position
    this.scrollToPreviousPosition()
    this.fetchPosts()
  }

  componentWillUnmount() {
    // remember current position and data
    this.setPreviousScrollPosition()
  }

  render() {
    return (
      <>
        <GirlsShowHomePage
          posts={this.state.posts}
          user={this.props.user}
          onLogin={this.handleLogin}
          onReachEnd={this.handleReachEnd}
          onToggleLike={this.props.$togglePostLike}
          onUploadSuccess={this.handleUploadSuccess}
          $i18n={this.props.$i18n}
        />
        {
          this.state.isLoading && <LoadingPage mode={LoadingPage.MODE.AUTO}/>
        }
      </>
    )
  }
}

const PlainGirlsShowHomeContainer =  withAppState(withPostLike(PlainGirlsShowHome, { eventCategory: EVENT_CATEGORY }))

class GirlsShowHomeContainer extends PureComponent {
  static Shell = Shell
  static shellProps = {
    supportDesktop: true,
    promotion: false,
    hideGlobalCart: true
  }

  static fetchOpenGraphInfo({ data }, { $router }) {
    const { posts = [] } = data

    const jsonLD = [
      seoUtils.forItemListSummary(
        posts.map(({ id }) => $router.getFinalPath({
          name: 'GirlsShowDetail',
          params: {
            handle: id
          }
        }))
      )
    ]

    return {
      title: 'Explore Flamingals',
      jsonLD
    }
  }

  render() {
    return (
      <PlainGirlsShowHomeContainer />
    )
  }
}

export {
  PlainGirlsShowHomeContainer
}

export default GirlsShowHomeContainer