import React, { Component } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'

import ProductService from '../../../services/ProductService'

import FlexList from '../../common/List/FlexList'
import ProductCard from '../ProductCard/ProductCard'
import ViewMoreButton from '../Product/ViewMoreButton'
import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'
import createStorage from '../../../utils/cacheUtils'

import styles from './ProductRecommend.module.css'
import cx from '../../../utils/className'


const YMALProductCard = props => {
  const { impressionConfig = {} } = props

  const { eventParams = {} } = impressionConfig

  return (
    <ImpressionObserver eventParams={{ index: props.index, handle: props.handle, ...eventParams  }} eventName='Product:YouMayAlsoLike' eventId={props.id}>
      <ProductCard {...props} ></ProductCard>
    </ImpressionObserver>
  )
}


class ProductRecommend extends Component {
  state = {
    collectionProductList: [],
    allowCollectionsMore: true,
    collectionHasNextPage: false
  }

  tabIndexStorage = createStorage('detail_tab_index', { strategy: 'SESSION' })
  productService = new ProductService(this.props.$http)
  isFetchingCollection = false
  collectionHasNextPage = true
  collectionPageNo = 1
  _fullCollectionProductList = []
  _sourceHandle = '' // configured collection handle

  fetchCollectionData = () => {
    if (!this.isFetchingCollection && this.collectionHasNextPage) {
      const { collectionHandle, productHandle, isESCollection } = this.props
      this.isFetchingCollection = true

      const fetchCollectionReq = this.productService.fetchProductYouMayAlsoLike(productHandle, {
        pageSize: 20,
        page: this.collectionPageNo,
        esFlag: !!isESCollection,
        collectionHandle
      })

      fetchCollectionReq.then(data => {
        this.isFetchingCollection = false
        this.collectionHasNextPage = data.pageInfo.hasNextPage
        this._sourceHandle = data.handle

        // get first 10 items
        const first10Items = data.products.slice(0, 10)
        this._fullCollectionProductList = [...this.state.collectionProductList, ...data.products]

        this.setState({
          collectionProductList: [...this.state.collectionProductList, ...first10Items],
          collectionHasNextPage: data.pageInfo.hasNextPage
        })
        this.collectionPageNo++
      })
        .catch(() => this.isFetchingCollection = false)
    }
    return Promise.resolve()
  }

  handleClickViewMore = () => {
    this.setState({
      allowCollectionsMore: false,
      collectionProductList: this._fullCollectionProductList
     })
  }

  componentDidMount() {
    this.fetchCollectionData()
  }

  render() {
    const {
      collectionProductList,
      collectionHasNextPage,
      allowCollectionsMore
    } = this.state

    const isDesktop = this.props.$detector.isDesktop()

    return (
      <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
        {
          collectionProductList.length > 0 &&
          <>
             <div className={cx(styles.selectableTabs, isDesktop && styles.isDesktop)}>
              <div>{this.props.$i18n.transl('core.product.youMayAlsoLike')}</div>
            </div>
            <FlexList
              columns={isDesktop ? 5 : 2}
              childIs={YMALProductCard}
              childItems={collectionProductList.map(i => i.product)}
              childOptions={{
                lazy: true,
                onClick: (product, _, index) => {
                  this.props.$router.navigateToProductPage(product)
                  this.props.$track.clickSubResourceLocator(`Product:YouMayAlsoLike_${product.handle}`, {
                    category: this.props.productCategory,
                    handle: product.handle,
                    id: product.id,
                    index,
                    sourceHandle: this._sourceHandle
                  })
                },
                eventCategory: 'YouMayAlsoLike',
                productCategory: this.props.productCategory,
                impressionConfig: {
                  eventParams: {
                    sourceHandle: this._sourceHandle,
                    category: this.props.productCategory
                  }
                },
                onClickQuickAdd: (product, index) => {
                  this.props.$track.clickSubResourceLocator(`Product:YouMayAlsoLike_${product.handle}`, {
                    category: this.props.productCategory,
                    handle: product.handle,
                    id: product.id,
                    index,
                    sourceHandle: this._sourceHandle
                  })
                }
              }
              }
              className={cx(styles.list, isDesktop && styles.isDesktop, collectionProductList.length === 0 && styles.empty)}
            />
            {
              collectionHasNextPage && allowCollectionsMore && (
                isDesktop
                  ? <ViewMoreButton onClick={this.handleClickViewMore} />
                  : (
                    <div onClick={this.handleClickViewMore} className={styles.viewMore}>
                      {this.props.$i18n.transl('common.viewMore')}<span>&#xe7b2;</span>
                    </div>
                  )
              )
            }
          </>
        }
      </div>
    )
  }
}

export default withPlugin(ProductRecommend)
