import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import InView from '../InView/InView'

import cx from '../../../utils/className'
import styles from './MultiColumns.module.css'

const COLUMNS_CONFIG = {
  DESKTOP: 4,
  DEFAULT: 2
}

const getDefaultColumns = ($detector, mobileColumns) => {
  return $detector && $detector.isDesktop() ? COLUMNS_CONFIG.DESKTOP : (mobileColumns || COLUMNS_CONFIG.DEFAULT)
}

class MultiColumns extends PureComponent {
  handleReachEnd = inView => {
    if (inView) {
      if (typeof this.props.onReachEnd === 'function') {
        this.props.onReachEnd()
      }
    }
  }

  /* --------------------------------------- */

  renderElements() {
    const { childIs, childItems, childOptions } = this.props

    const ChildComponent = childIs

    const isNeedTriggerReachEnd = i => i === childItems.length - 1

    if (childItems) {
      const elements = childItems.map((item, i) => {
        const element = (
          <ChildComponent
            key={i}
            index={i}
            {...item}
            {...childOptions}
          ></ChildComponent>
        )

        if (isNeedTriggerReachEnd(i)) {
          return (
            <InView key={i} onChange={this.handleReachEnd} triggerOnce={true}>
              {element}
            </InView>
          )
        }

        return element
      })

      return elements
    } else {
      return []
    }
  }

  componentDidMount() {
    const noChild = !this.props.childItems || !this.props.childItems.length
    if (noChild && typeof this.props.onReachEnd === 'function') {
      this.props.onReachEnd()
    }
  }

  render() {
    const elements = this.renderElements()
    const columns = this.props.columns || getDefaultColumns(this.props.$detector, this.props.mobileColumns)

    const columnElements = []

    for ( let i = 0; i < columns; i++) {
      columnElements.push(elements.filter((o, index) => index % columns === i))
    }

    const style = {
      width: `${100 / columns}%`
    }

    return (
      <div className={cx(styles.list, this.props.className, columns === COLUMNS_CONFIG.DEFAULT && styles.default)} style={this.props.style}>
        {
          columnElements.map((element, index) => (
            <div className={cx(styles.column, this.props.columnClassName)} style={style} key={index}>{element}</div>
          ))
        }
      </div>
    )
  }
}

export default withPlugin(MultiColumns)
