import CouponModel from './CouponModel'


class CouponCenterModel {
  constructor(data) {
    this.id = data.couponCenterId

    this.coupons = data.couponList.map(coupon => CouponModel.transferBE2FECouponModel(coupon))
  }
}


export default CouponCenterModel
