import { getAppLocale, getSite, getSiteStation, LEGACY_LOCALE } from '../utils/siteUtils'
import createListener from '@flamingo_tech/funkgo/src/utils/createListener'
import BasePlugin from '../plugins/base/BasePlugin'

/* --------------------------------- */
const LOCALE_STORAGE = 'locale'
const APP_LOCALE_STORAGE = 'app_locale'

export default class SitePlugin extends BasePlugin {
  displayName = '$Site'

  sessionStorage = null
  appLocalStorage = null

  _locale = this.options._hostname ? getSiteStation(this.options._hostname).languages[0].locale : 'en_US'

  start() {
    this.updateLocale()
    this.initListener()
  }

  updateLocale(options) {
    if (typeof window !== 'undefined') {
      const $detector = (this.pluginHub || (options && options.pluginHub)).getDetector()
      const $storage = (this.pluginHub || (options && options.pluginHub)).getStorage()

      if ($detector && $detector.isApp()) {

        this.appLocalStorage = $storage.create(APP_LOCALE_STORAGE)
        const locale = getAppLocale() || this.appLocalStorage.getItem('en_US')
        this.appLocalStorage.setItem(locale)
        this._locale = locale

      } else {
        
        this.sessionStorage = $storage.create(LOCALE_STORAGE, { strategy: 'SESSION' })

        if(LEGACY_LOCALE.indexOf(this.sessionStorage.getItem(getSiteStation().languages[0].locale)) > -1) {
          // reset sessionStorage
          this.sessionStorage.setItem(getSiteStation().languages[0].locale)
        }

        this._locale = this.sessionStorage.getItem()
      }
    }
  }


  initListener() {
    const {
      subscribe,
      unsubscribe,
      notify
    } = createListener()

    this.subscribe = subscribe
    this.unsubscribe = unsubscribe
    this.notify = notify
  }

  publishUpdate() {
    this.notify({
      site: this.getSiteInfo()
    })
  }

  /* ----------------------------------------------------------------- */

  getSiteInfo = options => {
    this.updateLocale(options)

    return this.getSiteInfoByLocale(this._locale)
  }


  getSiteInfoByLocale(locale) {
    let site = null

    const { internationalStations, ...basicSiteInfo } = getSite()

    internationalStations.forEach(station => {
      const { languages } = station

      languages.forEach(language => {
        if(language.locale === locale) {
          site = {
            ...basicSiteInfo,
            ...station,
            locale
          }
        }
      })
    })

    return site
  }

  getLocale = () => {
    return this._locale
  }

  setLocale = locale => {
    if (typeof window !== 'undefined') {
      this.sessionStorage.setItem(locale)
    }
    this._locale = locale

    this.publishUpdate()
  }

  refresh = () => {
    const previousLocale = this._locale

    this.updateLocale()

    if (previousLocale !== this._locale) {
      this.publishUpdate()
      if (typeof window !== undefined) {
        window.location.reload() // reload site to init all content
      }
    }
  }

  /* ----------------------------------------------------------------- */

  subscribe = (fn, options) => {
    this.subscribe(fn, options)
  }

  unsubscribe = (fn, options) => {
    this.unsubscribe(fn, options)
  }

  /* ----------------------------------------------------------------- */
  injectProps = {
    $site: {
      getSiteInfo: this.getSiteInfo,

      setLocale: this.setLocale,
      getLocale: this.getLocale,

      subscribe: this.subscribe,
      unsubscribe: this.unsubscribe,

      refresh: this.refresh

    }
  }
}
