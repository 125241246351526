import React, { forwardRef, useEffect } from 'react'
import ReactDOM from 'react-dom'
import ReactSlider from "react-slick"
import './Slick.css'

const defaultSlideSettings = {
  infinite: false,
  arrows: false,
  slidesToShow: 1,
  touchThreshold: 20
}

const Slider = forwardRef((props, forwardedRef) => {
  const sliderSettings = Object.assign({}, defaultSlideSettings, props)

  const wrapperRef = React.createRef()

  useEffect(() => {
    const slider = wrapperRef.current

    let firstClientX
    let firstClientY
    let clientX
    let clientY

    const touchStart = e => {
      firstClientX = e.touches[0].clientX
      firstClientY = e.touches[0].clientY
    }

    const preventTouch = e => {
      const minValue = 5

      clientX = e.touches[0].clientX - firstClientX
      clientY = e.touches[0].clientY - firstClientY

      if( Math.abs(clientX) > minValue && Math.abs(clientY) > minValue && e.cancelable) {
        e.preventDefault()
        e.returnValue = false
        return false
      }
    }

    ReactDOM.findDOMNode(slider).addEventListener('touchstart', touchStart, { passive: true })
    ReactDOM.findDOMNode(slider).addEventListener('touchmove', preventTouch, { passive: false })
    return () => {
      ReactDOM.findDOMNode(slider).removeEventListener('touchstart', touchStart, { passive: true })
      ReactDOM.findDOMNode(slider).removeEventListener('touchmove', preventTouch, { passive: false })
    }
  }, [wrapperRef])

  return (
    <div ref={wrapperRef}>
      <ReactSlider {...sliderSettings} ref={forwardedRef}>
        {props.children}
      </ReactSlider>
    </div>
  )
})

export default Slider
