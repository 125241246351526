import React, { useContext } from 'react'
import { hoistHocStatic } from '@flamingo_tech/funkgo'
import AppStateContext from './AppStateContext'

const withGlobalSettingEnabled = (Component, settingKey, { alwaysShow = false } = {}) => {
  const WrappedComponent = props => {
    const appState = useContext(AppStateContext)

    const { globalSettingMeta } = appState

    if ((!globalSettingMeta || !globalSettingMeta[settingKey]) && !alwaysShow) {
      return null
    } else {
      const setting = (globalSettingMeta || {})[settingKey]

      return <Component {...setting} {...props} />
    }
  }

  WrappedComponent.displayName = `withGlobalSettingEnabled(${Component.displayName || Component.name})`
  hoistHocStatic(WrappedComponent, Component)
  return WrappedComponent
}

export const withGlobalSetting = (Component, settingKey) => {
  const WrappedComponent = props => {
    const appState = useContext(AppStateContext)

    const { globalSettingMeta } = appState

    const settingParams = (globalSettingMeta || {})[settingKey]

    return (
      <Component
        {...settingParams}
        {...props}
      />
    )
  }

  WrappedComponent.displayName = `withGlobalSetting(${Component.displayName || Component.name})`
  hoistHocStatic(WrappedComponent, Component)
  return WrappedComponent
}

export default withGlobalSettingEnabled


