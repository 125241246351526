import ClientOnlyPlugin from '@flamingo_tech/funkgo/src/base/ClientOnlyPlugin'

import createListener from '@flamingo_tech/funkgo/src/utils/createListener'
import * as fbSdk from '@flamingo_tech/funkgo/src/sdk/fbSdk'
import * as googleLoginSdk from '@flamingo_tech/funkgo/src/sdk/googleLoginSdk'
import * as appSdk from '@flamingo_tech/funkgo/src/sdk/appSdk'
import { install as installBraintreeSdk, installHostedFields, installPaypalCheckout, installGooglePay, installApplePay } from '@flamingo_tech/funkgo/src/sdk/braintreeSdk'
import { install as installStripeSdk } from '@flamingo_tech/funkgo/src/sdk/stripeSdk'
import { install as installCheckoutSdk } from '@flamingo_tech/funkgo/src/sdk/checkoutSdk'
import { install as installPayPalSdk } from '@flamingo_tech/funkgo/src/sdk/payPalSdk'
import { install as installKlarnaSdk } from '@flamingo_tech/funkgo/src/sdk/klarnaSdk'
import { install as installKlarnaOneSiteMessageSdk } from '@flamingo_tech/funkgo/src/sdk/klarnaOneSiteMessageSdk'
import { install as installImSdk } from '@flamingo_tech/funkgo/src/sdk/imSdk'


import Sharer from '../plugins/BridgePlugin/Sharer'

/* ----------------------------------------- */

const silentFail = () => undefined
const IOS_DOWNLOAD_URI = 'https://apps.apple.com/app/apple-store/id1436939490'
const ANDROID_DOWNLOAD_URI = 'https://play.google.com/store/apps/details?id=com.flamingo.shop'
const MESSENGER_URI = 'https://m.me/official.flamingo.shop'
const SUPPORT_EMAIL = 'support@flamingo.shop'

export default class BridgePlugin extends ClientOnlyPlugin {
  displayName = '$Bridge'

  appSdk = appSdk
  fbSdk = fbSdk
  googleLoginSdk = googleLoginSdk

  $sharer = new Sharer({ context: this })
  $errorListener = createListener()
  /* ----------------------------------------- */

  start() {
    const $detector = this.pluginHub.getDetector()
    const appInfo = this.pluginHub.getAppInfo()

    // add clientId at beginning, and install once needed
    this.fbSdk.init({
      clientId: appInfo.fbAppId
    })

    // this.googleLoginSdk.init()

    this.appSdk.init({
      ios: $detector.isIOS(),
      android: $detector.isAndroid(),
    })

    if (this.isAppBridgesEnabled()) {
      this.appSdk.install().catch(err => {
        this.notifyError(this.formatError(err, 'install', 'app'))
      })
    }
  }

  /* ----------------------------------------- */
  formatError = (err, methodName, bridgeName) => {
    err.bridgeLabel = `${bridgeName}.${methodName}`
    return err
  }

  notifyError = err => this.$errorListener.notify(err)
  useErrorInterceptor = fn => this.$errorListener.subscribe(fn)

  /* ----------------------------------------- */

  isAppBridgesEnabled = () => {
    const $detector = this.pluginHub.getDetector()

    if (!$detector.isApp()) {
      return false
    }

    // for local debug, we may test look & feel like app, but not use app bridge
    // then mock ua with "FlamingoApp/x.x.x FlamingoFeatures/bridgeDisabled"
    if (process.env.NODE_ENV === 'development') {
      if ($detector.getUA().indexOf('bridgesDisabled') > -1) {
        return false
      }
    }

    return true
  }

  /* ----------------------------------------- */
  withSdkObserver = (bridgeName, fn) => (methodName, ...args) => {
    return fn(methodName, ...args).catch(err => {
      const formattedError = this.formatError(err, methodName, bridgeName)
      this.notifyError(formattedError)

      throw formattedError
    })
  }

  /* ----------------------------------------- */
  callFbSdk = this.withSdkObserver('fb', (methodName, ...args) => {
    const bridges = this.fbSdk.bridges
    if (typeof bridges[methodName] === 'function') {
      return bridges[methodName](...args)
    }
    return Promise.reject(new Error(`${methodName} is not a valid fb bridge`))
  })
  /* ----------------------------------------- */

  callAppSdk = this.withSdkObserver('app', (methodName, ...args) => {
    const $detector = this.pluginHub.getDetector()

    if ($detector.isApp()) {
      const bridges = this.appSdk.bridges
      if (typeof bridges[methodName] === 'function') {
        return bridges[methodName](...args)
      }
      return Promise.reject(new Error(`${methodName} is not a valid app bridge`))
    }
    return Promise.reject(new Error(`failed to call app sdk: ${methodName}, not at app env`))
  })

  /* ----------------------------------------- */

  addEventListenerToApp = (name, callback) => {
    // the common error for subscribe failed is app install timeout
    //  , since its no way to fallback for subscribe, so catch here and log via `withSdkObserver`
    return this.callAppSdk('subscribe', name, callback).catch(() => undefined)
  }

  removeEventListenerFromApp = (name, callback) => {
    // same as subscribe
    return this.callAppSdk('unsubscribe', name, callback).catch(() => undefined)
  }

  wrapCallAppSdk = name => payload => this.callAppSdk(name, payload)

  /* ----------------------------------------- */
  callAppStoreRate = this.wrapCallAppSdk('callAppStoreRate')
  requestNotifyAuth = this.wrapCallAppSdk('requestNotifyAuth')
  getNotificationInfo = this.wrapCallAppSdk('getNotificationInfo')
  openApplicationSetting = this.wrapCallAppSdk('openApplicationSetting')
  requestAppStoreReview = this.wrapCallAppSdk('requestAppStoreReview')
  closeWindow = this.wrapCallAppSdk('closeWindow')

  getDeviceInfo = this.wrapCallAppSdk('getDeviceInfo')
  getPushToken = this.wrapCallAppSdk('getPushToken')

  getCartId = this.wrapCallAppSdk('getCartId')
  getCouponCenterId = this.wrapCallAppSdk('getCouponCenterId')
  refreshCart = this.wrapCallAppSdk('refreshCart')
  refreshCouponCenter = this.wrapCallAppSdk('refreshCouponCenter')
  shareImage = this.wrapCallAppSdk('shareImage')

  vibrate = ({ soundId = 1521 } = {}) => {
    const $detector = this.pluginHub.getDetector()

    if ($detector.isApp() && $detector.isIOS()) {
      return this.callAppSdk('vibrate', { soundId }).catch(() => {})
    }

    if (typeof window !== 'undefined' && window.navigator.vibrate) {
      window.navigator.vibrate(100)
    }
    return Promise.resolve()
  }

  /* ----------------------------------------- */

  navigateToMessengerBot = (ref, payload, fallback) => {
    const $detector = this.pluginHub.getDetector()

    if ($detector.isApp()) {
      const $dialog = this.pluginHub.getPlugin('dialog')
      if ($dialog) {
        // use general confirm dialog by default
        $dialog.alert({
          title: 'Contact Us',
          content: `Please email us at ${SUPPORT_EMAIL} for any help.`
        })
      }
      return Promise.resolve()
    } else if (ref) {
      window.open(`${MESSENGER_URI}?ref=${ref}--${payload}`)
      return Promise.resolve()
    } else {
      window.open(MESSENGER_URI)
      return Promise.resolve()
    }
  }

  // this method should be called via routerPlugin.navigateToApp()
  //  , router plugin will handle scheme generate, so do not export to developer directly
  navigateToApp = ({ schemePath = '/Home' } = {}) => {
    const scheme = `flamingo://flamingo.shop${schemePath}`

    const $detector = this.pluginHub.getDetector()

    if ($detector.isApp()) {
      this.callAppSdk('navigate', { scheme })
        .catch(silentFail) // for legacy app and android, which does not respond result
    }
  }

  downloadApp = ({ cta = true, trackChannel } = {}) => {
    const $tracker = this.pluginHub.getPlugin('tracker')
    const $dialog = this.pluginHub.getPlugin('dialog')
    const $detector = this.pluginHub.getDetector()

    if ($tracker) {
      $tracker.event('Download', 'downloadApp', trackChannel)
    }

    const changePage = url => {
      if (!cta) {
        document.location.href = url
      } else {
        window.open(url)
      }
    }

    if ($detector.isIOS()) {
      changePage(IOS_DOWNLOAD_URI)
    } else if ($detector.isAndroid() && !$detector.isMessenger()) {
      changePage(ANDROID_DOWNLOAD_URI)
    } else {
      if ($dialog) {
        $dialog.alert('Search "Flamingo Shop" from App Store')
      }
    }
  }

  /* ----------------------------------------- */
  share = shareInfo => this.$sharer.share(shareInfo).catch(err => console.log(err))

  multiShare = shareInfo => this.$sharer.multiShare(shareInfo)

  /*
   ----------------------------------------- */
  getPayPalClientId = () => {
    return this.options.payPalId
  }

  getStripeClient = () => {
    const stripeId = this.options.stripeId

    if (!stripeId) {
      throw new Error('stripe id is not provided')
    }

    if (!window.Stripe) {
      throw new Error(`stripe is not installed`)
    }

    return Promise.resolve(window.Stripe(stripeId))
  }

  getCheckoutClient = props => {
    const checkoutId = this.options.checkoutId

    if (!checkoutId) {
      throw new Error('checkout id is not provided')
    }

    if (!window.Frames) {
      throw new Error(`checkout is not installed`)
    }

    const $site = this.pluginHub.getPlugin('site')

    const { locale } = $site.getSiteInfo() || {}

    if (locale === 'en_US' ) {
      // checkout sdk 不支持美国
      return Promise.reject()
    }

    window.Frames.init({
      publicKey: checkoutId,
      localization: locale.replace('_', '-').toUpperCase(),
      ...props
    })

    return Promise.resolve(window.Frames)
  }

  getKlarnaClient = () => {
    if (!window.Klarna) {
      throw new Error(`klarna is not installed`)
    }

    return Promise.resolve(window.Klarna)
  }

  installPayPal = () => {
    const payPalId = this.options.payPalId

    if (!payPalId) {
      throw new Error('paypal id is not provided')
    }

    if (window.paypal) {
      return Promise.resolve(true)
    }

    const $site = this.pluginHub.getPlugin('site')

    const { currency, locale } = $site.getSiteInfo() || {}

    return installPayPalSdk({ clientId: payPalId, currency, locale })
  }

  installBraintree = () => {
    const braintreeId = this.options.braintreeId

    if (!braintreeId) {
      throw new Error('braintree id is not provided')
    }

    if (window.braintree) {
      return Promise.resolve(braintreeId)
    }


    return installBraintreeSdk().then(() => braintreeId)
  }

  installBraintreeHostedFields = () => {
    return this.installBraintree().then(braintreeId => {

      if (window.braintree && window.braintree.hostedFields) {
        return Promise.resolve(braintreeId)
      }

      return installHostedFields().then(() => braintreeId)
    })
  }


  installBraintreePaypal = () => {
    return this.installBraintree().then(braintreeId => {

      if (window.braintree && window.braintree.paypalCheckout) {
        return Promise.resolve(braintreeId)
      }

      return installPaypalCheckout().then(() => braintreeId)
    })
  }

  installBraintreeGoogle = () => {
    return this.installBraintree().then(braintreeId => {

      if (window.braintree && window.braintree.googlePayment) {
        return Promise.resolve(braintreeId)
      }

      return installGooglePay().then(() => braintreeId)
    })
  }


  installBraintreeApple = () => {
    return this.installBraintree().then(braintreeId => {

      if (window.braintree && window.braintree.applePay) {
        return Promise.resolve(braintreeId)
      }

      return installApplePay().then(() => braintreeId)
    })
  }

  installIm = () => {
    if (typeof window === 'undefined') {
      return Promise.resolve(true)
    }

    if (window.im) {
      return Promise.resolve(true)
    }

    return installImSdk()
  }


  installStripe = () => {
    if (window.Stripe) {
      return this.getStripeClient()
    }

    return installStripeSdk().then(this.getStripeClient)
  }

  installCheckout = (props) => {
    if (window.Frames) {
      return this.getCheckoutClient()
    }

    return installCheckoutSdk().then(() => this.getCheckoutClient(props))
  }

  installKlarna = () => {
    if (window.Klarna && window.Klarna.Payments) {
      return this.getKlarnaClient()
    }

    return installKlarnaSdk().then(this.getKlarnaClient)
  }

  installKlarnaOneSiteMessage = () => {
    const klarnaOneSiteMessageIds = this.options.klarnaOneSiteMessageIds

    if (!klarnaOneSiteMessageIds) {
      throw new Error('klarna one site message id is not provided')
    }

    if (window.klarnaOneSiteInstalled) {
      return Promise.resolve(true)
    }

    const $site = this.pluginHub.getPlugin('site')

    const { locale, paymentLocale } = $site.getSiteInfo() || {}

    return installKlarnaOneSiteMessageSdk({
      klarnaOneSiteMessageIds,
      locale: paymentLocale || locale,
      env: process.env.NODE_ENV
    })
  }

  refreshKlarnaOneSiteMessage = () => {
    if (window.Klarna && window.Klarna.OnsiteMessaging) {
      window.Klarna.OnsiteMessaging.refresh()
    } else {
      const oneSiteMessage = window.KlarnaOnsiteService || []
      oneSiteMessage.push({ eventName: 'refresh-placements' })
    }
  }

  /* ----------------------------------------- */

  injectProps = {
    $bridge: {
      share: this.share,
      multiShare: this.multiShare,
      installBraintreePaypal: this.installBraintreePaypal,
      installBraintreeApple: this.installBraintreeApple,
      installBraintreeGoogle: this.installBraintreeGoogle,
      installBraintreeHostedFields: this.installBraintreeHostedFields,
      installStripe: this.installStripe,
      installPayPal: this.installPayPal,
      installKlarna: this.installKlarna,
      installCheckout: this.installCheckout,
      installKlarnaOneSiteMessage: this.installKlarnaOneSiteMessage,
      refreshKlarnaOneSiteMessage: this.refreshKlarnaOneSiteMessage,
      getPayPalClientId: this.getPayPalClientId,

      installIm: this.installIm,

      downloadApp: this.downloadApp,

      // setPageInfo: this.setPageInfo,
      closeWindow: this.closeWindow,
      vibrate: this.vibrate,
      callAppStoreRate: this.callAppStoreRate,
      requestNotifyAuth: this.requestNotifyAuth,
      getNotificationInfo: this.getNotificationInfo,
      openApplicationSetting: this.openApplicationSetting,
      requestAppStoreReview: this.requestAppStoreReview,
      getCartId: this.getCartId,
      getCouponCenterId: this.getCouponCenterId,
      refreshCart: this.refreshCart,
      refreshCouponCenter: this.refreshCouponCenter,
      shareImage: this.shareImage,

      addEventListenerToApp: this.addEventListenerToApp,
      removeEventListenerFromApp: this.removeEventListenerFromApp,

      isAppBridgesEnabled: this.isAppBridgesEnabled
    }
  }
}
