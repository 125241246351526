import React, { useState, useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Countdown from '../../Widget/Countdown/Countdown'

import { getCountdownInfo } from '../../../utils/dateUtils'

import { rem as px2rem } from '../../../utils/remUtils'
import styles from './ProductPromotionBar.module.css'

const DEFAULT_COUNTDOWN_THEME = {
  background: "#FFF",
  color: "#190808"
}

const DEFAULT_PROMOTION_SIZE = {
  width: 60,
  height: 375
}

const CountdownBar = ({ countdownInfo, countdownTheme, $i18n, onFinish }) => {
  if (!countdownInfo) {
    return null
  }

  const countdownTextStyle = {
    color: countdownTheme.background
  }

  return (
    <div className={styles.countdownContainer}>
      {
        countdownInfo.days > 0 ?
        <div className={styles.countdownText} style={countdownTextStyle}>
          {$i18n.transl('core.promotion.endsIn')} {(countdownInfo.days + (countdownInfo.days > 1 ?  ` ${$i18n.transl('core.promotion.days')}` : ` ${$i18n.transl('core.promotion.day')}`))}
        </div>
        :
        <div />
      }
      <Countdown
        timestamp={countdownInfo.time}
        type={Countdown.TYPE.CLOCK}
        className={styles.countdown}
        {...countdownTheme}
        onFinish={onFinish}
      />
    </div>
  )
}

const getEndDate= () => {
  const endDate = new Date(new Date().toLocaleDateString())

  endDate.setTime(endDate.getTime() +  1000 * 3600 * 24 - 1000)

  return endDate
}

const ProductPromotionBar = props => {
  const [endDate, setEndDate] = useState(getEndDate())
  const plugin = useContext(PluginContext)

  const { width, height, image } = props

  if (!image) { // 没有配置商详促销标的均不展示
    return null
  }

  const style = {
    width: px2rem(width || DEFAULT_PROMOTION_SIZE.width),
    height: px2rem(height || DEFAULT_PROMOTION_SIZE.height),
    lineHeight: px2rem(height || DEFAULT_PROMOTION_SIZE.height),
  }

  if (image) {
    style.backgroundImage = `url(${image})`
    style.backgroundSize = '100%'
    style.backgroundColor = 'transparent'
  }

  const handleFinish = () => {
    setEndDate(getEndDate())
  }

  return (
    <section className={styles.wrapper} style={style}>
      {
        props.showCountdown &&
        <CountdownBar
          $i18n={plugin.$i18n}
          countdownInfo={getCountdownInfo(endDate)}
          countdownTheme={DEFAULT_COUNTDOWN_THEME}
          onFinish={handleFinish}
        />
      }
      {
        props.renderRibbon && (
          <div className={styles.ribbon}>{props.renderRibbon()}</div>
        )
      }
    </section>
  )
}

export default ProductPromotionBar
