import React, { useContext } from 'react'
import AppStateContext from '../../../hooks/AppStateContext'
import { useRouterHistory } from '@flamingo_tech/funkgo'

import Link from '../../common/Link/Link'
import Button from '../../common/Button/PlainButton'

import styles from './StoreHeader.module.css'

const StoreHeader = props => {
  const { historyLength } = useRouterHistory()

  const appState = useContext(AppStateContext)
  const { site } = appState
  const {
    displaySearch = false,
    displayBack = true,
    displayCart = true,
    displayShare = false,
    cartLineItemCount = 0,
    onCartClick,
    // onWishClick,
    onShareClick,
    onNaviClick,
  } = props

  const handleBack = () => {
    props.$track.event('StoreHeader', 'click_back')
    props.$router.goBack()
  }

  const leftElements = []
  const rightElements = []


  const renderTitle = () => {
    if (props.headerTitle) {
      return (
        <Link
          block to='Home'
          className={styles.title}
        >
          {props.headerTitle}
        </Link>
      )
    }

    return (
      <Link
        key='logo'
        block to='Home'
        className={styles.logoBar}
        style={logoStyle}
      >
      </Link>
    )
  }

  if (historyLength > 1 && displayBack) {
    leftElements.push(
      <i key='back' className={styles.backIcon} onClick={handleBack}></i>
    )
  }

  leftElements.push(
    <Button
      key='navi'
      className={styles.naviBtn}
      onClick={onNaviClick}
    ><i className={styles.naviIcon}>&#xe606;</i></Button>
  )

  if (displaySearch) {
    leftElements.push(
      <Link
        key='search'
        to='SearchPage'
        className={styles.search}
      >
        <div
          id='header_search'
        >
          <i className={styles.searchIcon}>&#xe60b;</i>
        </div>
      </Link>
    )
  }

  // if (displayCart !== false) {
  //   rightElements.push(
  //     <div
  //       id='header_wish'
  //       key='wish'
  //       className={styles.wish}
  //       onClick={onWishClick}
  //     >
  //       <i className={styles.wishIcon}>&#xe617;</i>
  //     </div>
  //   )
  // }

  if (displayCart !== false) {
    rightElements.push(
      <div
        id="atc_parabola_destination_with_shake"
        key='cart'
        className={styles.cart}
        onClick={onCartClick}
      >
        <i className={styles.cartIcon}>&#xe61c;</i>
        {
          cartLineItemCount > 0 && (
            <div className={styles.cartCount}>
              <span>{cartLineItemCount > 99 ? '99+': cartLineItemCount}</span>
            </div>
          )
        }
      </div>
    )
  }

  if (displayShare === true) {
    rightElements.push(
      <div
        key='share'
        className={styles.share} onClick={onShareClick}>
      </div>
    )
  }

  const logoStyle = {}
  const headerStyle = {}

  if (site.logo) {
    logoStyle["backgroundImage"] = `url(${site.logo})`
  }

  if (props.headerTheme) {
    headerStyle.backgroundColor = props.headerTheme
  }

  const header = (
    <header className={styles.header} style={headerStyle} key="header" id="store-header">
      <aside onClick={e => e.preventDefault()}>
        {leftElements}
      </aside>
      {renderTitle()}
      <aside>
        {rightElements}
      </aside>
    </header>
  )

  return header
}

export default StoreHeader
