import React, { useState, useContext, forwardRef, useImperativeHandle } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './SortDropDown.module.css'
import { SORT_DATA, getCurrentSortTitle, getFilterOptions } from '../../../utils/Store/sortUtils'

import cx from '../../../utils/className'

const SortDropDown = forwardRef((props, ref) => {
  const sortItems = props.NEW_SORT_DATA || SORT_DATA.filters
  const [selectedSort, setSelectedSort] = useState(sortItems[0])
  const [sortListOpen, setSortListOpen] = useState(false)
  const plugin = useContext(PluginContext)
  const { Trans } = plugin.$i18n
  const isDesktop = plugin.$detector.isDesktop()
  const queryOptions = getFilterOptions(props.queryOptions)

  useImperativeHandle(ref, () => ({
    openList: () => setSortListOpen(false)
  }))

  const toggleSortList = () => {
    setSortListOpen(!sortListOpen)
  }

  const handleSortItemClick = sortItem => {
    setSortListOpen(false)
    setSelectedSort(sortItem)
    plugin.$track.event(props.eventCategoryName, 'click_sort', `${sortItem.value}`)
    props.handleSort({ ...queryOptions, [SORT_DATA.filterKey]: sortItem.value })
  }

  if (isDesktop) {
    return (
      <div className={cx(styles.rightInfo, styles.inDesktop)}>
        <h6 className={styles.sortTitle}>{plugin.$i18n.transl('core.collection.sortBy')}</h6>
        <div className={cx(styles.sortSelector, props.dark && styles.dark)}>
          <div className={cx(styles.currentSort, sortListOpen && styles.active)} onClick={toggleSortList}>
            <span className={styles.title}>{plugin.$i18n.transl(getCurrentSortTitle(queryOptions))}</span>
            {
              sortListOpen && (
                <ul className={styles.sortWrapper}>
                  {
                    sortItems.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className={cx(styles.sortItem, getCurrentSortTitle(queryOptions) === item.i18nDisplayKey && styles.active)}
                          onClick={() => handleSortItemClick(item)}
                        >
                          <Trans i18nKey={item.i18nDisplayKey}></Trans>
                        </li>
                      )
                    })
                  }
                </ul>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={cx(styles.mobile, props.className || '')}>
      <div className={cx(styles.sortBy, styles.sortCategory, sortItems.indexOf(selectedSort) > -1 && styles.highlighted, sortListOpen && styles.withSortListOpen)}
        onClick={toggleSortList}>
          <Trans i18nKey={getCurrentSortTitle(queryOptions)}></Trans>
      </div>
      <div className={styles.sortList}>
        {
          sortListOpen &&
          sortItems.map(item => (
            <div
              onClick={() => handleSortItemClick(item)}
              className={cx(styles.sortItem, getCurrentSortTitle(queryOptions) === item.i18nDisplayKey && styles.selected)}
              key={item.displayKey}>
              <Trans i18nKey={item.i18nDisplayKey}></Trans>
            </div>
          ))
        }
      </div>
    </div>
  )
})

export default SortDropDown