import React, { useState } from 'react'
import styles from './CartPageSoldOutList.module.css'
import cx from '../../../utils/className'
import ProductSliderList from './ProductSliderList'


const CartPageSoldOutList = props => {
  const [offSaleVisible, setOffSaleVisible] = useState(false)

  if (!props.unsaleableLineItems.length) {
    return null
  }

  const renderList = (items, isDesktop) => {
    if (!offSaleVisible) {
      return null
    }

    return (
      <ProductSliderList
        items={items}
        isDesktop={isDesktop}
      />
    )
  }

  return (
    <div className={cx(styles.wrapper, props.noBorder && styles.noBorder, props.isDesktop && styles.isDesktop)}>
      <div className={styles.unsaleableLineItems}>
        <div className={styles.header} onClick={() => setOffSaleVisible(x => !x)}>
          <div>
            <span>{props.$i18n.transl('core.cart.outOfStockItem', { count: props.unsaleableLineItems.length })}{`(${props.unsaleableLineItems.length})`}</span>
            <span className={cx(styles.unsaleIcon, offSaleVisible && styles.active)}>&#xe7b2;</span>
          </div>
          {offSaleVisible && <div className={styles.clearBtn} onClick={props.onClearExpireItems}>{props.$i18n.transl('common.clearAll')}</div>}
        </div>
        {renderList(props.unsaleableLineItems, props.isDesktop)}
      </div>
    </div>
  )
}

export default CartPageSoldOutList