class UserAddressModel {
  constructor(data) {
    this.id = data.id
    this.hasDefault = data.hasDefault
    this.lastName = data.lastName
    this.firstName = data.firstName
    this.countryCode = data.countryCode
    this.countryName = data.countryName
    this.stateCode = data.stateCode
    this.stateName = data.stateName
    this.cityName = data.cityName
    this.addressLine1 = data.addressLine1
    this.addressLine2 = data.addressLine2 || ''
    this.zipCode = data.zipCode
    this.telNumber = data.telNumber
    this.taxNo = data.taxNo
    this.houseNo = data.houseNo || ''
  }
}

UserAddressModel.createList = list =>　list.map(item => new UserAddressModel(item))

export default UserAddressModel