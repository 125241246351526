import React, { useEffect, useState, useRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import Link from '../../common/Link/Link'
import Grid from '../../common/Grid/Grid'
import CategorySegmentsColumn from '../../Store/ProductCategory/CategorySegmentsColumn'
import SearchContainer from '../../../containers/Store/SearchContainer'
import SearchSelect from '../../Store/Search/SearchSelect'
import useFn from '../../../hooks/useFn'

import cx from '../../../utils/className'
import styles from './DesktopNav.module.css'

const getComponents = (layout, componentName) => {
  return layout.filter(item => item.is === componentName)
}

const NavItemDetailPanel = props => {
  const { layout = [], open } = props

  const grids = getComponents(layout, 'HorizontalImageText')
  const categoryListSegments = getComponents(layout, 'CategorySegmentColumn')
  const gridsColumns = grids.map(grid => grid.options)

  if (gridsColumns.length <= 0 && categoryListSegments.length <= 0) {
    return null
  }

  return (
    <div className={cx(styles.modalPanel, open && styles.openPanel)} onMouseOver={props.onHoverMask}>
      <div className={styles.navItemDetailPanel} onMouseOver={e => e.stopPropagation()}>
        <div className={styles.detail}>
          {
            gridsColumns && gridsColumns.length > 0 && (
              gridsColumns.map((grid, index) => {
                const { item } = grid
                return (
                  <div className={styles.grids} key={index}>
                    <Grid
                      items={[item]}
                      wrap={1}
                    />
                  </div>
                )
              })
            )
          }
          {
            categoryListSegments.length > 0 &&
            <div className={styles.categories}>
              {
                categoryListSegments.map((segment, index) => (
                  <div key={index}>
                    <CategorySegmentsColumn {...segment.options} parentCategoryTitle={props.title} handleHoverMask={props.onHoverMask} />
                  </div>
                ))
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const DesktopNav = props => {
  const [sliderRef, setSliderRef] = useState(null)
  const [current, setCurrent] = useState(1)
  const [pages, setPages] = useState()
  const timerRef = useRef()
  let navHoverTimer

  const [curNav, setCurNav] = useState(null)
  const items = props.items || []

  const handleNavHover = item => {
    if (item) {
      clearTimeout(navHoverTimer)
      navHoverTimer = setTimeout(() => {
        setCurNav(item)
      }, 300)
    } else {
      clearTimeout(navHoverTimer)
      setCurNav(null)
    }
  }

  const handleNavClick = (item, ev) => {
    if (!item.link) {
      ev.preventDefault()
    } else {
      props.$track.clickMainResourceLocator(`Category:${item.title}`)
    }
  }

  const resetPages = useFn(() => {
    let pages = 1
    if (typeof window !== 'undefined') {
      const size = (sliderRef && sliderRef.clientHeight) || 0
      const navItems = document.getElementsByClassName(styles.navItem)[0]
      const navItemHeight = navItems && navItems.clientHeight + 10
      if (navItemHeight) {
        pages = parseInt(size / navItemHeight)
      } else {
        pages = 1
      }
    }

    setPages(pages)
  })

  const resizeHandler = useFn(() => {
    clearTimeout(timerRef.current)
    timerRef.current = setTimeout(() => {
      setCurrent(1)
      resetPages()
    }, 300)
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', resizeHandler)
      }
    }
  }, [resetPages, resizeHandler])

  useEffect(() => {
    if (props.items && props.items.length > 0) {
      resetPages()
    }
  }, [sliderRef, props.items, resetPages])

  const toNext = () => {
    if (current !== pages) {
      setCurrent(x => x + 1)
    }
  }

  const toPrev = () => {
    if (current !== 1) {
      setCurrent(x => x - 1)
    }
  }

  return (
    <div
      className={styles.wrapper}
      onMouseLeave={() => handleNavHover(null)}>
      <div className={styles.sliderBox}>
        <div ref={setSliderRef} className={styles.navigation} style={{ transform: `translateY(${(current - 1) * -0.64}rem)` }}>
          {
            items.map(item => item.title && (
              <div
                key={item.title}
                onMouseEnter={() => handleNavHover(item)}
              >
                <Link to={item.link || 'Home'} onClick={ev => handleNavClick(item, ev)}>
                  <div className={cx(styles.navItem, item.highlight && styles.highlight, item.layout && styles.withDetailPanel)}>
                    {item.title}
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
        <span className={cx(styles.arrow, styles.left, current === 1 && styles.disabled)} onClick={toPrev}>&#xe608;</span>
        <span className={cx(styles.arrow, current === pages && styles.disabled)} onClick={toNext}>&#xe608;</span>
      </div>
      <div className={styles.searchBox}>
        <SearchContainer
          searchPageComponent={SearchSelect}
        />
      </div>
      {
        items.map(i => {
          return <NavItemDetailPanel {...i} open={curNav === i} onHoverMask={() => handleNavHover(null)} />
        })
      }
    </div>
  )
}

export default withPlugin(DesktopNav)
