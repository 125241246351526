import React, { PureComponent } from 'react'
import debounce from 'debounce'

import withCartAndPlugins from '../../hooks/withCartAndPlugins'

import UserReviewContainer from './UserReviewContainer'
import EmptyCartRecommendationsContainer from './EmptyCartRecommendationsContainer'
import CartRecommendationsContainer from './CartRecommendationsContainer'
import CouponCenterList from '../../components/Store/Coupon/CouponCenterList'

import ProductDrawer from '../../components/Store/Cart/ProductDrawer'
import ProductModal from '../../components/Store/Cart/ProductModal'
import DesktopCartPage from '../../components/Store/Cart/DesktopCartPage'
import CartPage from '../../components/Store/Cart/CartPage'
import ProductService from '../../services/ProductService'
import OrderService from '../../services/OrderService'
import UserService from '../../services/UserService'

import CartPageSkeleton from '../../components/Skeleton/CartPageSkeleton'
import { getTrackCouponName, PAYMENT_METHOD } from '../../utils/Checkout/checkoutUtils'
import { withGlobalSetting } from '../../hooks/withGlobalSettingEnabled'


import { v4 as uuidv4 } from 'uuid'

const CART_MODE = {
  PAGE: 'page',
  DRAWER: 'drawer'
}

class CoverCartContainer extends PureComponent {
  static getInitState({ cart }) {
    return {
      originalCart: cart, // store the reference, so that we can compare the reference change to update state
      lineItems: cart ? [...cart.lineItems] : [],
      updatePriceTime: 0
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Cart.js will only update cart reference when cart content changed
    if (nextProps.cart && nextProps.cart !== prevState.originalCart) {
      return CoverCartContainer.getInitState(nextProps)
    }

    return null
  }

  orderService = new OrderService(this.props.$http)
  userService = new UserService(this.props.$http)

  state = {
    ...CoverCartContainer.getInitState(this.props),
    paypalReady: false,
    paypalCheckoutInstance: null,
    isFetchingData: true,
    showCouponList: false
  }

  /* -------------------------------------- */

  _cartUpdating = false
  expressOrderId = null

  withCartUpdate = fn => (...args) => {
    this._cartUpdating = true
    return fn(...args).then(() => {
      this._cartUpdating = false
    }, err => {
      this._cartUpdating = false
      if (err.errorCode === 300003) {  // only for new customer
        const { skuId } = err.data.data || {}
        this.props.$alert(err.message).then(() => {
          const updateVariant = this.state.lineItems.filter(item => item.id === skuId)[0]

          if (updateVariant) {
            this.props.$updateVariant({
              ...updateVariant,
              quantity: 0
            })
          }
        })
      } else if (err.errorCode === 300007) { // Limited to 1 piece per purchase
        const { skuId } = err.data.data || {}
        this.props.$alert(err.message).then(() => {
          const updateVariant = this.state.lineItems.filter(item => item.id === skuId)[0]

          if (updateVariant) {
            this.props.$updateVariant({
              ...updateVariant,
              quantity: 1
            })
          }
        })
      } else {
        this.props.$toastError(err.message)
      }
    })
  }

  initPaypal = () => {
    if (this.props.mode === CART_MODE.DRAWER) {
      return
    }

    this.props.$bridge.installBraintreePaypal().then(token => {

      const { currency, locale } = this.props.$site.getSiteInfo()

      window.braintree.client.create({
        authorization: token
      }).then(instance => {
        window.braintree.paypalCheckout.create({ // init paypal
          client: instance
        }).then(paypalCheckoutInstance => {
          paypalCheckoutInstance.loadPayPalSDK({
            currency,
            intent: 'capture',
            locale
          }).then(() => {
            this.props.$track.event('Cart', 'paypal_ready')

            this.setState({
              paypalReady: true,
              paypalCheckoutInstance
            })
          })
        })
      })
    })
  }

  fetchFullCart = () => {
    this.props.$fetchFullCart().then(() => {
      this.setState({
        isFetchingData: false
      })
    }).catch(() => {
      this.setState({
        isFetchingData: false
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.enableCartPPE !== this.props.enableCartPPE && this.props.enableCartPPE) {
      this.initPaypal()
    }
  }

  componentDidMount() {
    this.fetchFullCart()

    if (this.props.mode === CART_MODE.DRAWER) {
      return
    }

    if (this.props.enableCartPPE) {
      this.initPaypal()
    }
  }

  /* -------------------------------------- */

  handleCheckout = () => {
    if (this.props.mode !== CART_MODE.DRAWER) {
      this.props.$track.event('Cart', 'click_checkout', 'default')
    }
    const saleableLineItems = this.state.lineItems.filter(i => (i.saleable && i.selectedFlag))
    if (saleableLineItems.length === 0) {
      this.props.$toast(this.props.$i18n.transl('core.cart.emptySelect'))
      return Promise.resolve()
    }

    return this.withCartUpdate(() => {
      return this.props.$checkout()
    })()
  }

  /* -------------------------------------- */

  handleCartCouponChange = this.withCartUpdate(coupon => {
    const appliedCoupon = this.props.cart.appliedCoupon

    if (!this.props.$store.couponHub.isCouponEqual(coupon, appliedCoupon)) {
      return this.props.$applyCoupon(coupon)
    }

    return Promise.resolve()
  })

  /* -------------------------------------- */
  removeVariants = this.withCartUpdate(lineItems => this.props.$removeVariants(lineItems))
  removeVariantsDebounce = debounce(this.removeVariants, 300)

  updateVariant = this.withCartUpdate(lineItem => this.props.$updateVariant(lineItem))
  updateVariantsDebounce = debounce(this.updateVariant, 300)



  mapLineItemsWithQuantity = ({ lineItem, quantity }) => {
    return this.state.lineItems
      .map(originalLineItem => {
        if (originalLineItem.id === lineItem.id) {
          return {
            ...originalLineItem,
            quantity,
          }
        }
        return originalLineItem
      })
      .filter(
        ({ quantity }) => quantity > 0
      )
  }

  handleCartItemQuantityChange = ({ lineItem, quantity }) => {
    if (!this._cartUpdating) {
      const lineItems = this.mapLineItemsWithQuantity({ lineItem, quantity })
      this.setState({
        lineItems
      })

      this.updateVariantsDebounce({
        ...lineItem,
        quantity
      })
    }
  }

  handleClearExpireItems = () => {
    const saleableLineItems = this.state.lineItems.filter(lineItem => lineItem.saleable)
    const unsaleableLineItems = this.state.lineItems.filter(lineItem => !lineItem.saleable)

    if (!this._cartUpdating) {
      this.setState({
        lineItems: saleableLineItems
      })

      this.removeVariantsDebounce(unsaleableLineItems)
    }
  }


  renderEmptyCartRecommendationsComponent = () => {
    const productService = new ProductService(this.props.$http)

    return (
      <EmptyCartRecommendationsContainer productService={productService}/>
    )
  }

  renderCartRecommendationsComponent = () => {
    const productService = new ProductService(this.props.$http)
    const isDesktop = this.props.$detector.isDesktop()
    const CartRenderComponent = isDesktop ? ProductModal : ProductDrawer

    return (
      <CartRecommendationsContainer
        isDesktop={isDesktop}
        updatePrice={this.updatePrice}
        updatePriceTime={this.state.updatePriceTime}
        productService={productService}
        CartRenderComponent={CartRenderComponent}
      />
    )
  }

  handleHistoryBack = () => {
    this.props.$track.event('CartPage', 'goBack')

    if (this.props.$router.history.length > 1) {
      this.props.$router.goBack()
    } else {
      this.props.$router.navigateToHomePage()
    }
  }

  updatePrice = () => {
    this.setState({
      updatePriceTime: Date.now()
    })
  }


  withLoading = (promiseFunc, loadingProps) => {
    if (!promiseFunc || typeof promiseFunc !== 'function') {
      throw new Error('withLoading need a function')
    }
    let closeLoading = this.props.$createLoading({
      ...loadingProps
    })
    return () => promiseFunc().then(data => {
      closeLoading && closeLoading()
      return data
    }, (err) => {
      closeLoading && closeLoading()
      throw err
    })
  }

  handleClickPaypalButton = () => {
    this.props.$track.event('Cart', 'click_checkout', 'paypal')
  }

  handlePayWithPaypal = (braintreeNonce, shippingAddress) => {
    return this.withLoading(() => {
      const eventID = uuidv4()

      // save shipping and email to localStorage when anonymous
      const hasLogin = this.props.$user.hasLogin()
      if (!hasLogin) {
        const shippingAddressStorage = this.props.$storage.create('an_address')
        shippingAddressStorage.setItem(shippingAddress)

        this.props.$user.saveAnonymousLoginEmail(shippingAddress.email)
      }
      this.props.$track.event('Email', shippingAddress.email)

      return this.props.$ensureCouponCenter().then(() => {
        const { id } = this.props.$store.couponHub.getCouponCenter()

        return this.orderService.createExpressOrder({
          orderInfo: {
            ...this.props.cart,
            itemInfoList: this.state.lineItems.filter(lineItem => lineItem.saleable && lineItem.selectedFlag),
            shippingAddress
          },
          cartId: this.props.cart.id,
          couponCenterId: id,
          eventID
        }).then(data => {
          this.props.$track.event('Cart', 'create_paypal_order_success')

          this.props.$user.saveAnonymousLoginToken(data.accessToken)

          const appliedCoupon = this.props.$store.couponHub.getAvailableCoupon(this.props.cart.appliedCouponId) // store coupon before local coupon center sync

          if (!hasLogin) {
            this.userService.postUserAddress(shippingAddress, data.accessToken)
          }

          return this.orderService.payWithBraintree({
            orderId: data.orderId,
            braintreeNonce,
            subPayMethod: PAYMENT_METHOD.BRAINTREE.subPayments.PAYPAL.name,
            couponCenterId: id,
            shoppingCartId: this.props.cart.id,
            eventID
          }).then(() => {
            this.props.$track.event('Cart', 'pay_with_paypal_success')

            this.props.$toastSuccess(this.props.$i18n.transl('core.checkout.paySuccess'))

            this.props.$track.purchase(
              {
                ...this.props.cart.model,
                id: data.orderId,
                shippingAddress,
                email: shippingAddress.email
              },
              getTrackCouponName(appliedCoupon),
              null,
              eventID
            )

            const { locale } = this.props.$site.getSiteInfo()
            const isUS = locale === 'en_US'
            this.props.$router.replace({
              name: isUS ? 'PaymentSuccess' : 'OrderDetail',
              params: {
                handle: data.orderId
              },
              search: {
                from: 'cart'
              }
            })

            // sync remote coupons to local and refresh cart
            this.props.$store.syncAssets({ syncCartFlag: false })
            this.props.$store.syncUserDeviceKey()

            return Promise.resolve()
          }).catch(err => {
            this.props.$track.event('Cart', 'pay_with_paypal_failed', err.message)

            this.props.$toastError({
              content: err.message,
              duration: 2000
            })

            if (hasLogin) {
              this.props.$router.replace({
                name: 'Checkout',
                search: {
                  id: data.orderId,
                  type: 'draft',
                  _ak: this.props.$user.getAccessToken()
                }
              })
            } else {
              this.props.$router.replace({
                name: 'Checkout',
                search: {
                  id: data.orderId,
                  _ak: data.accessToken,
                  type: 'anonymous'
                }
              })
            }

            // sync remote coupons to local and refresh cart
            this.props.$store.refresh()

            return Promise.resolve()
          })
        })
        .catch(err => {
          this.props.$track.event('Cart', 'create_paypal_order_failed', err.message)

          this.props.$toastError({
            content: err.message,
            duration: 2000
          })
          return Promise.reject()
        })

      })

    })()
  }

  handleClickCouponEntrance = () => {
    this.$track('click_coupon_list')

    this.setState({
      showCouponList: true
    })
  }
  $track= (action, label) => {
    this.props.$track.event('Cart', action, label)
  }
  /* -------------------------------------- */

  render() {
    const isDesktop = this.props.$detector.isDesktop()
    const {
      cart,
      cartIs = isDesktop ? DesktopCartPage : CartPage,
      ...restProps
    } = this.props

    const CartComponent = cartIs


    if (!cart) {
      return null
    } else {
      if (this.state.isFetchingData && this.props.mode !== CART_MODE.DRAWER) {
        return <CartPageSkeleton />
      }
    }

    return (
      <>
         <CartComponent
          {...restProps}
          {...cart}
          lineItems={this.state.lineItems} /* overwrite lineItems with state stored lineItems */
          onCartItemQuantityChange={this.handleCartItemQuantityChange}
          onCartCouponChange={this.handleCartCouponChange}
          onCheckout={this.handleCheckout}
          onClearExpireItems={this.handleClearExpireItems}
          renderCartReview={UserReviewContainer}
          renderEmptyCartRecommendations={this.renderEmptyCartRecommendationsComponent}
          renderCartRecommendations={this.renderCartRecommendationsComponent}
          onHistoryBack={this.handleHistoryBack}
          onCartItemSelect={this.props.$selectCart}
          onPayWithPaypal={this.handlePayWithPaypal}
          onClickPaypalButton={this.handleClickPaypalButton}
          showPaypal={this.state.paypalReady}
          paypalCheckoutInstance={this.state.paypalCheckoutInstance}
          onTrack={this.$track}
          paypalTotalFee={cart.totalPrice}
          refreshCart={this.props.$refreshCart}
          onClickCouponEntrance={this.handleClickCouponEntrance}
        />
        {
          this.state.showCouponList &&
          <CouponCenterList
            availableCouponList={this.props.$store.couponHub.getAvailableCoupons()}
            selectedCoupon={cart && cart.appliedCoupon}
            onClose={() => this.setState({ showCouponList: false})}
            isDesktop={isDesktop}
          ></CouponCenterList>
        }
      </>
    )
  }
}

const WrapperCoverCartContainer = withCartAndPlugins(withGlobalSetting(CoverCartContainer, 'paymentMeta'))

WrapperCoverCartContainer.mode = CART_MODE

export default WrapperCoverCartContainer