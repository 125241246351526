import React, { PureComponent } from 'react'

import Link from '../common/Link/Link'

import withUserAndPlugins from '../../hooks/withUserAndPlugins'

import styles from './DesktopUserPanel.module.css'


const ITEMS = [{
  key: 'order',
  title: 'core.user.myOrders',
  to: 'Order'
}]


class DesktopUserPanel extends PureComponent {
  // handleLogout = () => {
  //   this.props.$user.logout().then(() => {
  //     this.props.$router.navigate('Login')
  //   })
  // }


  render() {
    // const { user } = this.props

    return (
      <div className={styles.wrapper}>
        {/* <div className={styles.section}>
            {
              user
              ? user.name
              : <Link to='Login'>Sign in / Register</Link>
            }

        </div> */}
        <div className={styles.section}>
          {
            ITEMS.map(item => (
              <Link {...item} className={styles.item} key={item.key} block>{this.props.$i18n.transl(item.title)}</Link>
            ))
          }
        </div>
        {/* UNCOMMENT ONCE LOGIN PANEL IS DONE
          {
            user && <div className={styles.section} onClick={this.handleLogout}>Sign Out</div>
          }
        */}
      </div>
    )
  }
}

export default withUserAndPlugins(DesktopUserPanel)
