import React, { useContext } from 'react'
import { hoistHocStatic } from '@flamingo_tech/funkgo'
import AppStateContext from './AppStateContext'

const withRegularCouponEnabled = (Component, settingKey, { alwaysShow = false } = {}) => {
  const WrappedComponent = props => {
    const appState = useContext(AppStateContext)

    const { regularCouponMeta } = appState

    if ((!regularCouponMeta || !regularCouponMeta[settingKey]) && !alwaysShow) {
      return null
    } else {
      const coupon = (regularCouponMeta || {})[settingKey]

      return <Component coupon={coupon} {...props} />
    }
  }

  WrappedComponent.displayName = `withRegularCouponEnabled(${Component.displayName || Component.name})`
  hoistHocStatic(WrappedComponent, Component)
  return WrappedComponent
}

const withRegularCoupon = (Component) => {
  const WrappedComponent = props => {
    const appState = useContext(AppStateContext)

    const { regularCouponMeta } = appState

    if (!Object.keys(regularCouponMeta).length) {
      return null
    } else {
      return <Component regularCouponMeta={regularCouponMeta} {...props} />
    }
  }

  WrappedComponent.displayName = `withRegularCoupon(${Component.displayName || Component.name})`
  hoistHocStatic(WrappedComponent, Component)
  return WrappedComponent
}

export {
  withRegularCoupon
}
export default withRegularCouponEnabled