import React, { PureComponent, createRef } from 'react'
import Slider from '../common/Slider/Slider'
import ClickableBox from '../common/ClickableBox/ClickableBox'
import Image from '../common/Image/Image'
import VideoPlayer from '../common/Video/VideoPlayer'
import IframeVideo from '../common/Video/IframeVideo'
import { withPlugin } from '@flamingo_tech/funkgo'

import cx from '../../utils/className'
import styles from './GirlsShowPlayer.module.css'

class GirlsShowPlayer extends PureComponent {
  state = {
    curIndex: 0
  }

  sliderEl = createRef()

  swipeChangeHandler = index => {
    this.setState({
      curIndex: index
    })
  }

  handleLeftSwipe = () => {
    this.sliderEl.current.slickPrev()
  }

  handleRightSwipe = e => {
    e.stopPropagation()
    this.sliderEl.current.slickNext()
  }


  render() {
    const { mediaType, items, poster, onDoubleClick } = this.props

    if (items.length === 0) {
      return null
    }

    const contents = items.map((item, i) => {
      const imgTitle = item.title || `Girls Show Gallery - ${i + 1}`
      return (
        <div key={i}>
          {
            mediaType === 'VIDEO'
            &&
            <VideoPlayer
              src={item}
              poster={poster}
              className={styles.videoPlayer}
            />
          }
          {
            mediaType === 'IFRAME VIDEO'
            &&
            <IframeVideo src={item}></IframeVideo>
          }
          {
            mediaType === 'IMAGE'
            &&
            <ClickableBox onDoubleClick={onDoubleClick} onClick={() => null}>
              <Image
                src={item}
                className={styles.imagePlayer}
                title={imgTitle}
                large
              />
            </ClickableBox>
          }
        </div>
      )
    })

    const isDesktop = this.props.$detector.isDesktop()

    return (
      <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
        {
          isDesktop &&
          <>
            {
              this.state.curIndex > 0 &&
              <div className={cx(styles.pcSwiperButton, styles.pcLeftButton)} onClick={this.handleLeftSwipe} >
                <div className={styles.pcLeftButtonArrow} />
              </div>
            }
            {
              this.state.curIndex < contents.length - 1 &&
              <div className={cx(styles.pcSwiperButton, styles.pcRightButton)} onClick={this.handleRightSwipe} >
                <div className={styles.pcRightButtonArrow} />
              </div>
            }
          </>
        }
        <Slider afterChange={this.swipeChangeHandler} ref={this.sliderEl}>
          {contents}
        </Slider>
        {
          contents.length > 1 &&
          <div className={styles.pagination}>{this.state.curIndex + 1}/{contents.length}</div>
        }
      </div>
    )
  }
}

export default withPlugin(GirlsShowPlayer)
