import React, { PureComponent } from 'react'
import CoverCartContainer from './CoverCartContainer'
import withPlugin from '../../hooks/withCartAndPlugins'




const PlainCartContainer = CoverCartContainer

class CartContainer extends PureComponent {
  static fetchOpenGraphInfo() {
    return {
      title: 'My Cart - Flamingals by Flamingo Shop',
      private: true // not index on search engine
    }
  }

  render() {
    return (
      <PlainCartContainer {...this.props}></PlainCartContainer>
    )
  }
}

export {
  PlainCartContainer
}

export default withPlugin(CartContainer)
