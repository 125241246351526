import { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

const InApp = (props) => {

  const plugin = useContext(PluginContext)

  if (plugin.$detector.isApp()) {
    return props.children || null
  } else {
    return null
  }
}

export const InStandaloneApp = (props) => {
  const plugin = useContext(PluginContext)

    if (plugin.$detector.isStandaloneApp()) {
      return props.children || null
    } else {
      return null
    }
}


export const OutApp = (props) => {
  const plugin = useContext(PluginContext)

  if (plugin.$detector.isApp()) {
    return null
  } else {
    return props.children || null
  }
}

export default InApp
