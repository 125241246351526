import React, { PureComponent } from 'react'

import Layout from '../../components/Layout/Layout'
import { withDrawer } from '../../components/common/Drawer/Drawer'
import ModalBodyWrapper from '../../components/common/Modal/ModalBodyWrapper'

import LayoutService from '../../services/LayoutService'

export const STATIC_PAGE_HANDLES = {
  PRIVACY_POLICY: 'privacy-policy',
  ABOUT_US: 'about-us',
  CONTACT: 'contact',
  RETURNS_AND_REFUNDS: 'returns-and-refunds',
  SHIPPING: 'shipping',
  TERM_OF_SERVICE: 'term-of-service',
}

class StaticModalContainer extends PureComponent {
  state = {
    data: null
  }

  fetchPageData() {
    if (this.props.handle) {
      new LayoutService(this.props.$http).fetchActivity(this.props.handle).then(data => {
        this.setState({
          data
        })
      }).catch(() => {})
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.handle !== prevProps.handle) {
      this.fetchPageData()
    }
  }

  componentDidMount() {
    this.fetchPageData()
  }

  render() {
    return (
      <div>
        {
          <ModalBodyWrapper {...this.props} data={this.state.data}>
            <Layout
              {...this.state.data}
            ></Layout>
          </ModalBodyWrapper>
        }
      </div>
    )
  }
}

export default withDrawer(StaticModalContainer)