import React, { useState, useEffect } from 'react'

import styles from './CollectionSEOContents.module.css'
import cx from '../../../utils/className'

const CollectionSEOContents = props => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (document.getElementById('seo-collection-div')) {
      document.getElementById('seo-collection-div').innerHTML = props.seoContent || ''
    }
  }, [props.seoContent])

  const handleToggleDesc = () => {
    setShow(x => !x)
  }

  const handleCloseDesc = () => {
    window.scrollTo(0,0)
    setShow(false)
  }

  if (props.isDesktop) {
    return (
      <>
        <div className={cx(styles.SEOCollection, styles.isDesktop, show && styles.show)}>
          <div id="seo-collection-div" />
        </div>
        <div className={styles.pcReadMore} onClick={handleToggleDesc}>{props.$i18n.transl(show ? 'core.collection.readLess' : 'core.collection.readMore')}</div>
      </>
    )
  }

  return (
    <div className={cx(styles.SEOCollection, show && styles.show)}>
      <div onClick={handleToggleDesc} className={styles.icon} />
      <div id="seo-collection-div" />
      {
        show && <span onClick={handleCloseDesc} className={cx(styles.iconDown)} />
      }
    </div>
  )
}

export default CollectionSEOContents