import { BaseService } from '@flamingo_tech/funkgo'

import { PostModel, LinkedProductModel, UserPostSummary } from '../models/Post/PostModel'
import PostListModel from '../models/Post/PostListModel'

class PostService extends BaseService {

  fetchPurchasedProducts({ cursor = null, limit = 20 } = {}) {
    return this.get('/content/api/v2/post/products/me', {
      params: {
        cursor,
        limit
      }
    }).then(data => ({
       pageInfo: {
         ...data.pageInfo,
         totalCount: data.total
       },
       products: (data.list || []).map(product => ({
         cursor: product.cursor,
         ...new LinkedProductModel(product.data)
       }))
    }))
  }

  fetchNoteComments(noteId, needCount, pageNum) {
    return this.get('/content/api/girlsShow/getNoteCommentList', {
      params: {
        noteId,
        needCount,
        pageNum
      }
    }).then(data => data)
  }

  submitNoteComment(targetUserId, noteId, content) {
    return this.post('/content/api/girlsShow/addNoteComment', {
      targetUserId,
      noteId,
      content
    })
  }


  fetchProductById(id) {
    return this.get(`/client/api/v2/product/${id}/detail`).then(
      data => new LinkedProductModel(data)
    )
  }

  // ============================== //
  fetchPost(postId) {
    return this.get('/content/api/girlsShow/getNoteInfo', {
      params: {
        noteId: postId
      }
    }).then(
      data => new PostModel(data)
    )
  }

  publishPost({ desc, resources, linkedProducts = [], tags }) {
    return this.post('/content/api/girlsShow/creatNote', {
      content: desc,
      mediaType: 1,
      resources: resources.map(resource => resource.src),
      productIds: linkedProducts.map(linkedProduct => linkedProduct.id),
      tags
    }).then(data => ({
      id: data.nid
    }))
  }

  fetchRecommendPosts({ pageNum = 1 } = {}) {
    return this.get('/content/api/girlsShow/getFeed', {
      params: {
        pageNum: pageNum
      }
    }).then(data => new PostListModel(data))
  }


  fetchLatestTagPosts(tagId, { pageNum = 1 } = {}) {
    return this.get('/content/api/girlsShow/getLatestNoteList', {
      params: {
        tagId,
        pageNum
      }
    }).then(data => new PostListModel(data))
  }

  fetchHottestTagPosts(tagId, { pageNum = 1 } = {}) {
    return this.get('/content/api/girlsShow/getHottestNotes', {
      params: {
        tagId,
        pageNum
      }
    }).then(data => new PostListModel(data))
  }

  fetchTagInfo(tagId) {
    return this.get('/content/api/girlsShow/getTagInfo', {
      params: {
        tagId
      }
    })
  }

  likeOrUnlikePost(postId, isLike) {
    return this.post('/content/api/girlsShow/clickStar', { noteId: postId, clickType: isLike ? 1 : 2 }) // 1->点赞 2–>取消点赞
  }

  fetchProductRelatedPosts(handle, { pageNum = 1, needCount = false } = {}) {
    return this.get(`/content/api/girlsShow/v1/product/${handle}/notes`, {
      params: {
        pageNum,
        needCount
      }
    }).then(data => {
      // WORKAROUND
      let ret = new PostListModel(data)
      let videos = []
      let images = []
      ret.posts.forEach(post => {
        if (post.mediaType === 'VIDEO' || post.mediaType === 'IFRAME VIDEO') {
          videos.push(post)
        } else {
          images.push(post)
        }
      })
      ret.posts = images
      ret.pageInfo.totalCount = ret.pageInfo.totalCount - videos.length
      return ret
    })
  }

  fetchPostRelatedPosts(postId, { pageNum = 1 } = {}) {
    return this.get('/content/api/girlsShow/getRelatedNotes', {
      params: {
        noteId: postId,
        pageNum: pageNum
      }
    }).then(data => new PostListModel(data))
  }


  fetchUserPostInfo(userId) {
    return this.get('/content/api/girlsShow/user/info', {
      params: {
        userId
      }
    }).then(data => new UserPostSummary(data))
  }


  fetchMyPostInfo() {
    return this.get('/content/api/girlsShow/user/infoMe').then(data => new UserPostSummary(data))
  }


  fetchUserPosts(userId, { pageNum = 1 } = {}) {
    return this.get('/content/api/girlsShow/user/noteList', {
      params: {
        userId,
        pageNum
      }
    }).then(data => new PostListModel(data))
  }

  fetchMyPosts({ pageNum = 1 } = {}) {
    return this.get('/content/api/girlsShow/user/myNotes', {
      params: {
        pageNum
      }
    }).then(data => new PostListModel(data))
  }
}

export default PostService
