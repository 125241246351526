import React, { useEffect } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import Countdown from '../../Widget/Countdown/Countdown'

import styles from './UnpaidCard.module.css'
import cx from '../../../utils/className'

import { withModal } from '../../common/Modal/Modal'

const EVENT_CATEGORY_UNPAID = 'UnpaidOrder'
const endImageIndex = 3

const UnpaidCard = props => {
  const { Trans } = props.$i18n
  const isDesktop = props.$detector.isDesktop()

  const { list, appliedCouponName, freight, abandonCheckoutCoupons, draftFlag } = props

  const isFreeShipping = parseFloat(freight) === 0

  const trans = key => {
    return props.$i18n.transl(`core.checkout.unpaidOrder.${key}`)
  }

  useEffect(() => {
    props.$track.event(EVENT_CATEGORY_UNPAID, 'view_card')
  }, [props.$track])



  const renderTitle = () => {
    if (appliedCouponName && isFreeShipping) {
      return (
        <Trans i18nKey='core.checkout.unpaidOrder.combine'>
          You left an unpaid order, already enjoyed <span className={styles.highlight}>FREE SHIPPING</span> and <span className={styles.highlight}>{{ coupon: appliedCouponName }}</span>!
        </Trans>
      )
    } else if (appliedCouponName) {
      return (
        <Trans i18nKey='core.checkout.unpaidOrder.couponEnd'>
          You left an unpaid order, already enjoyed <span className={styles.highlight}>{{ coupon: appliedCouponName }}</span>
        </Trans>
      )
    } else if (!draftFlag) {
      return (
        <Trans i18nKey='core.checkout.unpaidOrder.defaultWithSubmittedOrder'>
          You left an unpaid order. Pay now for <span className={styles.highlight}>FAST DISPATCH</span>!
        </Trans>
      )
    } else {
      return (
        <>
          {trans('getBeforeExpiredOrder')}
          <div className={styles.couponWrapper}>
              {
                (abandonCheckoutCoupons || []).map(coupon => (
                  <div className={styles.coupon} key={coupon.id}>
                    <div className={styles.leftWrapper}>
                      <div className={styles.name}>{coupon.name}</div>
                      <div className={styles.desc}>{coupon.desc}</div>
                    </div>
                    <Countdown
                      className={styles.countdownClassName}
                      cardClassName={styles.cardClassName}
                      separatorClassName={styles.separatorClassName}
                      timestamp={900000}
                      type={Countdown.TYPE.CLOCK}
                    />
                  </div>
                ))
              }
          </div>
        </>
      )
    }
  }

  const needApplyCoupons = !appliedCouponName

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      <div className={styles.title}>{trans('waitingForPayment')}</div>
      <div className={styles.subtitle}>
        {
          renderTitle()
        }
      </div>
      <div className={styles.cartItems}>
          {
            list.slice(0, endImageIndex + 1).map((item, index) => {
              return (
                <div key={index} className={styles.cartItem}>
                  <Image src={item.mainImage} small crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }}/>
                  {
                    index === endImageIndex && <div className={styles.cover}>&#xe684;</div>
                  }
                </div>
              )
            })
          }
        </div>
      <div className={styles.continue} onClick={props.onContinue}>
        {trans('payNow')}
        <span className={styles.countdownContainer}>
          {
            !needApplyCoupons &&
            <>
              (
                <Countdown
                  className={styles.countdownClassName}
                  cardClassName={styles.cardClassName}
                  timestamp={900000}
                  type={Countdown.TYPE.TEXT}
              />
              )
            </>
          }
        </span>

      </div>
    </div>
  )
}

export default withModal(withPlugin(UnpaidCard), { showCloseButton: true, closeBtnClassName: styles.closeBtnClassName, disableCoverClick: true })