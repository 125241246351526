import React from 'react'

import ShippingFeeProgress from '../Product/ShippingFeeProgress.js'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'

import styles from './CartAddOnAndCoupon.module.css'
import cx from '../../../utils/className'

const CartAddOnAndCoupon = props => {
  const clickable = props.addOnsForFreeShippingAmount !== 0
  const { locale } = props.$site.getSiteInfo()
  const isUS = locale === 'en_US'

  return (
    <div className={cx(styles.wrapper, styles.wrapperContainer)} >
      <div className={cx(styles.shipping, styles.flexible, clickable && styles.clickable, isUS && styles.isUS)}>
        <div className={styles.title}>
          { clickable ? props.$i18n.transl('core.cartAddOn.cart.freeShipping') : <div className={cx(styles.desc)}>{props.$i18n.transl('core.cartAddOn.cart.youGotFreeShipping')}</div> }
        </div>
        { clickable ?
          <>
            <ShippingFeeProgress
              {...props}
              {...props.cart}
              progressWrapperClassName={styles.progressWrapper}
              verticalClassName={styles.vertical}
              verticalProgressChildrenClassName={styles.verticalProgressChildren}
              needProgressTip={false}
              direction={ShippingFeeProgress.DIRECTION.VERTICAL}
              showTipText={false}
              needProgressing
            />
            <div className={styles.addButton} onClick={clickable ? props.showAddOn : () => {}}>{props.$i18n.transl('core.cartAddOn.cart.add')}</div>
          </>
          :
          <ShippingFeeProgress
            {...props}
            {...props.cart}
            progressWrapperClassName={styles.progressWrapper}
            verticalClassName={styles.vertical}
            verticalProgressChildrenClassName={cx(styles.verticalProgressChildren, styles.verticalProgressText)}
            needProgressTip={false}
            direction={ShippingFeeProgress.DIRECTION.VERTICAL}
            verticalProgressText={props.$i18n.transl('core.cartAddOn.cart.congratulations')}
            needProgressing
          />
        }
      </div>
    </div>
  )
}

export default withCartAndPlugins(CartAddOnAndCoupon)