
import React, { useContext } from 'react'

import ProductCard from '../ProductCard/ProductCard'
import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'
import { withGlobalSetting } from '../../../hooks/withGlobalSettingEnabled'
import ProductService from '../../../services/ProductService'

import ESProductList from './ESProductList'
import { PluginContext } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import styles from './OrderRecommendList.module.css'

const WrappedProductCard = props => {
  const { impressionConfig = {} } = props

  const { eventParams = {}, eventName } = impressionConfig

  return (
    <ImpressionObserver eventParams={{ index: props.index, handle: props.handle, ...eventParams  }} eventName={eventName} eventId={props.id}>
      <ProductCard {...props} ></ProductCard>
    </ImpressionObserver>
  )
}

const FEATURED_COLLECTION = 'monthly-trend-es'

const OrderRecommendList = (props) => {
  const collectionHandle = (props.tabs && props.tabs[0].collectionHandle) || FEATURED_COLLECTION
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const productService = new ProductService(plugin.$http)

  return (
    <ESProductList
      pageSize={20}
      columns={isDesktop ? 6 : 2}
      collectionHandle={collectionHandle}
      productService={productService}
      className={cx(styles.list, isDesktop && styles.pcListComponent)}
      columnClassName={styles.listColumn}
      cardIs={WrappedProductCard}
      cardOptions={{
        lazy: true,
        onClick: (product, _, index) => {
          plugin.$router.navigateToProductPage(product)
          plugin.$track.clickSubResourceLocator(`${props.pageName}:YouMayAlsoLike_${product.handle}`, {
            handle: product.handle,
            id: product.id,
            index,
            sourceHandle: collectionHandle
          })
        },
        impressionConfig: {
          eventName: `${props.pageName}:YouMayAlsoLike`,
          eventParams: {
            sourceHandle: collectionHandle
          }
        },
        onClickQuickAdd: (product, index) => {
          plugin.$track.clickSubResourceLocator(`${props.pageName}:YouMayAlsoLike_${product.handle}`, {
            handle: product.handle,
            id: product.id,
            index,
            sourceHandle: collectionHandle
          })
        },
        needFetchFullAfterAddCart: true
      }}
  ></ESProductList>
  )
}

export default withGlobalSetting(OrderRecommendList, 'orderDetailYMAL')