import React from 'react'

import { withModal } from '../../common/Modal/Modal'
import styles from './AppUpdateModal.module.css'

const AppUpdateModal = () => {

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>Try our new version</div>
      <div className={styles.body}>
        <div className={styles.section}>
          <p>Please update <strong>Flamingo Shop App</strong> in the App Store or Google Play.</p>
          <p>The currently installed version is too old and not supported any longer.</p>
        </div>
      </div>
    </div>
  )
}

export default withModal(AppUpdateModal)