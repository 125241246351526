export default class BasePlugin {
  static isClientOnly = false

  displayName = 'Unknown'

  constructor(options = {}, pluginHub) {
    this.options = options
    this.pluginHub = pluginHub

    this.displayName = '$Undefined'
    this.injectProps = undefined
    this.clientOnly = false
  }

  start() {

  }

  throwError(message) {
    throw new Error(`${this.displayName} ${message}`)
  }

  getInjectProps() {
    if (this.injectProps) {
      return this.injectProps
    }

    return {}
  }

  isClientOnly() {
    return this.clientOnly
  }
}