import ProductQueueModel from '../../npm/FunkGoModel/ProductQueueModel'

class Activity {
  constructor(data) {
    this.handle = data.handle
    this.title = data.title
    this.description = data.description || data.desc
    this.meta = data.meta
    this.root = data.root
    this.layout = data.layout
    // compat old promotion config
    this.selectedPromotion = (data.promotions && data.promotions.length > 0 && data.promotions[0]) || data.promotion
    this.promotions = data.promotions || []
    this.collections = {}

    if (data.collections) {
      Object.keys(data.collections).forEach(key => {
        this.collections[key] = new ProductQueueModel(data.collections[key])
      })
    }

  }
}

export default Activity
