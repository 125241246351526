import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import Image from '../common/Image/Image'
import Skeleton from './Skeleton'
import ProductListSkeleton from './ProductListSkeleton'

import cx from '../../utils/className'

import styles from './ProductDetailSkeleton.module.css'

/* ------------------------- */
const ProductDetailSkeleton = props => {
  const isDesktop = props.$detector.isDesktop()

  if (isDesktop) {
    return (
      <div className={cx(styles.wrapper, styles.isDesktop)}>
        <div className={styles.section}>
          <div className={styles.pcMainContent}>
            <div className={styles.smallImages}>
              <Image className={styles.smallImage} />
              <Image className={styles.smallImage} />
              <Image className={styles.smallImage} />
              <Image className={styles.smallImage} />
              <Image className={styles.smallImage} />
              <Image className={styles.smallImage} />
            </div>
            <Image className={styles.image} />
            <div className={cx(styles.section, styles.rightSection)}>
              <Skeleton className={styles.skeleton} />
              <Skeleton className={styles.skeleton} />
              <Skeleton className={styles.skeleton} />
              <div className={styles.flexSection}>
                <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
                <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
                <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
              </div>
            </div>
          </div>
          <div className={styles.sectionWithPadding}>
            <Skeleton type={Skeleton.TYPE.MEDIUM} className={styles.skeleton} />
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
            <Skeleton className={styles.skeleton} />
            <Skeleton className={styles.skeleton} />
          </div>
        </div>
        <div className={cx(styles.section, styles.sectionWithPadding)}>
          <Skeleton className={styles.skeleton} />
          <Skeleton className={styles.skeleton} />
          <Skeleton className={styles.skeleton} />
          <div className={styles.flexSection}>
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          </div>
        </div>
        <div className={cx(styles.section, styles.sectionWithPadding)}>
          <Skeleton type={Skeleton.TYPE.MEDIUM} className={styles.skeleton} />
          <div className={styles.flexSection}>
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
            <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          </div>
        </div>
        <ProductListSkeleton isDesktop={isDesktop} />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <div className={styles.main}>
          <Image className={styles.mainImage} />
          <Image className={styles.mainImage} />
        </div>
        <div className={styles.sectionWithPadding}>
          <Skeleton className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={cx(styles.skeleton, styles.price)} />
          <Skeleton className={styles.skeleton} />
        </div>
      </div>
      <div className={cx(styles.section, styles.specialPadding)}>
        <Skeleton type={Skeleton.TYPE.MEDIUM} className={styles.skeleton} />
        <div className={styles.flexSection}>
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
        </div>
      </div>
      <div className={cx(styles.section, styles.specialPadding)}>
        <Skeleton type={Skeleton.TYPE.MEDIUM} className={styles.skeleton} />
        <div className={styles.flexSection}>
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
        </div>
      </div>
      <ProductListSkeleton isDesktop={isDesktop} />
    </div>
  )
}

export default withPlugin(ProductDetailSkeleton)
