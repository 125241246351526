import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import styles from './LoadingPage.module.css'
import cx from '../../../utils/className'

const MODE = {
  FULL: styles.full,
  AUTO: styles.auto
}

const LoadingPage = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin ? plugin.$detector.isDesktop() : props.isDesktop

  return (
    <div className={cx(styles.wrapper, isDesktop ? styles.inDesktop : props.className, props.mode || MODE.FULL)}>
      <div className={cx(styles.loadingRoller, isDesktop && styles.inDesktop)}>
        <div></div>
      </div>
      {props.children}
    </div>
  )
}

LoadingPage.MODE = MODE

export default LoadingPage


