import React from 'react'

const cancellablePromise = promise => {
  let isCanceled = false

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      error => reject({ isCanceled, error }),
    )
  })

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  }
}


const delay = n => new Promise(resolve => setTimeout(resolve, n))


const useCancellablePromises = () => {
  const pendingPromises = React.useRef([])

  const appendPendingPromise = promise =>
    pendingPromises.current = [...pendingPromises.current, promise]

  const removePendingPromise = promise =>
    pendingPromises.current = pendingPromises.current.filter(p => p !== promise)

  const clearPendingPromises = () => pendingPromises.current.map(p => p.cancel())

  const api = {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  }

  return api
}


const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises()

  const handleClick = () => {
    api.clearPendingPromises()
    const waitForClick = cancellablePromise(delay(300))
    api.appendPendingPromise(waitForClick)

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick)
        onClick()
      })
      .catch(errorInfo => {
        api.removePendingPromise(waitForClick)
        if (!errorInfo.isCanceled) {
          throw errorInfo.error
        }
      })
  }

  const handleDoubleClick = () => {
    api.clearPendingPromises()
    onDoubleClick()
  }

  return [handleClick, handleDoubleClick]
}


const ClickableBox = ({ onClick, onDoubleClick, children, className }) => {
  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(onClick, onDoubleClick)


  return (
    <div className={className} onClick={handleClick} onDoubleClick={handleDoubleClick}>
      {children}
    </div>
  )
}

ClickableBox.displayName = 'ClickableBox'

export default ClickableBox