import React from 'react'
import { withGlobalSetting } from '../../../hooks/withGlobalSettingEnabled'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import NewProgressBar from '../../common/ProgressBar/NewProgressBar'
import cx from '../../../utils/className'

import styles from './ShippingFeeProgress.module.css'

const DIRECTION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal'
}

const ShippingFeeProgress = withGlobalSetting(withCartAndPlugins(props => {
  const {
    checkoutItemCount = 0,
    cart,
    addOnsForFreeShippingAmount,
    direction,
    freeShippingFee,
    displayFreeShippingFee,
    displayAddOnsForFreeShippingAmount,
    onClick = () => {},
    needProgressTip = true,
    progressWrapperClassName,
    verticalClassName,
    verticalProgressChildrenClassName,
    needProgressing = false,
    showTipText = true,
    verticalProgressText,
  } = props
  const { productTotalFeeAfterDiscount, displayProductTotalFeeAfterDiscount } = cart || {}
  const showProcess = freeShippingFee !== undefined
  let progressText = props.$i18n.transl('core.cartAddOn.cart.empty')
  let progressTip = ''
  let process = 0

  const { locale } = props.$site.getSiteInfo()
  const isUS = locale === 'en_US'
  const { Trans } = props.$i18n

  if (checkoutItemCount === 0) {
    progressTip = displayFreeShippingFee
    ? (
      <Trans i18nKey='core.cartAddOn.detail.freeShippingFee'>
        {{ displayFreeShippingFee }} ships free
      </Trans>
    )
    : ''
  } else {
    if (showProcess) {
      process = parseInt(productTotalFeeAfterDiscount) / freeShippingFee
      if (addOnsForFreeShippingAmount === 0) {
        progressText = props.$i18n.transl('core.cartAddOn.detail.freeShipping')
        progressTip = props.$i18n.transl('core.cartAddOn.detail.checkoutNow')
      } else if (showTipText) {
        progressText = isUS ? `Need ${displayAddOnsForFreeShippingAmount} More` : `${displayProductTotalFeeAfterDiscount}/ ${displayFreeShippingFee}`
        progressTip = props.$i18n.transl('core.cartAddOn.detail.freeShipping')
      } else {
        progressText = <Trans i18nKey='core.cartAddOn.cart.needMore'>Need {{ displayAddOnsForFreeShippingAmount }} more</Trans>
        progressTip = props.$i18n.transl('core.cartAddOn.detail.freeShipping')
      }
    }
  }

  if (direction === DIRECTION.VERTICAL) {
    return (
      <div className={cx(styles.vertical, verticalClassName)} onClick={onClick}>
        <div className={cx(styles.progressWrapper, progressWrapperClassName)}>
          <NewProgressBar value={process} needProgressing={needProgressing} >
            <div className={cx(styles.text, verticalProgressChildrenClassName)}>
              {verticalProgressText ? verticalProgressText : progressText}
            </div>
          </NewProgressBar>
        </div>
        { needProgressTip && <div className={cx(styles.text, styles.tip)}>{progressTip}</div> }
      </div>
    )
  } else {

    if (addOnsForFreeShippingAmount !== 0) {
      let horizontalProgressText

      if (showProcess) {
        if (addOnsForFreeShippingAmount === 0) {
          horizontalProgressText = props.$i18n.transl('core.cartAddOn.cart.freeShipping')
        } else {
          horizontalProgressText = (
            <Trans i18nKey='core.cartAddOn.cart.needMore'>Need {{ displayAddOnsForFreeShippingAmount }} more</Trans>
          )
        }
      }

      return (
        <div className={styles.horizontal}>
          <span className={styles.icon}>&#xe63f;</span>
          <span>Get free shipping</span>
          <NewProgressBar value={process} outerBarClassName={styles.bar}>
            <div className={cx(styles.text)}>{horizontalProgressText}</div>
          </NewProgressBar>
        </div>
      )
    }

    return (
      <div className={styles.horizontal}>
        <span className={styles.icon}>&#xe63f;</span>
        <span className={styles.green}>Got free shipping on all orders</span>
      </div>
    )
  }

}), 'shipping')


ShippingFeeProgress.DIRECTION = DIRECTION

export default ShippingFeeProgress


