import React, { useState, useContext, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import withUnpaidOrdersEnabled from '../../../../hooks/withUnpaidOrdersEnabled'
import AppStateContext from '../../../../hooks/AppStateContext'

import UnpaidBuoyComponent from '../../../../components/Store/CheckOut/UnpaidBuoy'
import UnpaidCardComponent from '../../../../components/Store/CheckOut/UnpaidCard'
import OrderService from '../../../../services/OrderService'

const unpaidBuoyTimeStamp = 900000 // 浮标倒计时
const EVENT_CATEGORY_UNPAID = 'UnpaidOrder'

// 未支付订单
const UnpaidOrderContainer = props => {
  const plugin = useContext(PluginContext)
  const {
    pendingPaymentInfo,
    unpaid_card_closed = false, // 弹窗是否关闭
    unpaid_buoy_closed = false, // 浮标是否关闭
    unpaid_buoy_count_down = null // 浮标倒计时
  } = props.unpaidInfo

  const appState = useContext(AppStateContext)

  const { abandonCheckoutCoupons = [] } = appState.regularCouponMeta

  const [isCardClosed, setCardClosed] = useState(unpaid_card_closed)
  const [isBuoyClosed, setBuoyClosed] = useState(unpaid_buoy_closed)
  const [timeStamp, setTimeStamp] = useState(unpaid_buoy_count_down)

  useEffect(() => {
    const { orderId } = props.unpaidInfo.pendingPaymentInfo || {}

    if (!orderId) { // 没查到相关的 未支付订单信息，转到cart
      props.toNext()
    }
  }, [props, props.unpaidInfo.pendingPaymentInfo])

  // continue to pay
  const continueToPay = () => {
    const { orderId = '', userToken = '', appliedCouponName, draftFlag } = pendingPaymentInfo

    const params = {
      name: 'Checkout',
      search: {
        id: orderId,
        type: 'anonymous',
        _ak: userToken,
      }
    }

    if (!appliedCouponName && draftFlag) {
      params.search.couponId = (abandonCheckoutCoupons.filter(coupon => coupon.needApply)[0] || {}).id
    }

    plugin.$router.navigate(params)
  }

  if (pendingPaymentInfo && pendingPaymentInfo.orderId) {

    // 未支付弹窗
    if (!isCardClosed) {
      // 生成浮标
      const initBuoy = () => {
        // 生成倒计时
        if (!timeStamp) {
          const futureTime = Date.now() + unpaidBuoyTimeStamp

          setTimeStamp(futureTime)
          props.onUpdateUnpaidInfo({
            unpaid_buoy_count_down: futureTime
          })
        }

        setCardClosed(true)
        props.onUpdateUnpaidInfo({
          unpaid_card_closed: true
        })
      }

      const lastPendingPayClose = () => {
        const { orderId } = pendingPaymentInfo

        if (orderId) {
          const orderService = new OrderService(plugin.$http)
          orderService.closeLastPendingPay(orderId)
        }
      }

      const handleCloseCard = () => {
        initBuoy()
        plugin.$track.event({
          category: EVENT_CATEGORY_UNPAID,
          action: 'close_card',
          nonInteraction: false
        })
        lastPendingPayClose()
      }

      const handleContinueToPay = () => {
        initBuoy()
        plugin.$track.event({
          category: EVENT_CATEGORY_UNPAID,
          action: 'continue_to_pay',
          nonInteraction: false
        })

        lastPendingPayClose()
        continueToPay()
      }

      return (
        <UnpaidCardComponent
          {...pendingPaymentInfo}
          abandonCheckoutCoupons={abandonCheckoutCoupons}
          onClose={handleCloseCard}
          onContinue={handleContinueToPay}
        />
      )
    }

    // 未支付浮标
    if (!isBuoyClosed) {
      const handleCloseBuoy = () => {
        setBuoyClosed(true)
        props.onUpdateUnpaidInfo({
          unpaid_buoy_closed: true
        })
      }

      const handleStopCountDown = () => {
        setTimeStamp(null)
        props.onUpdateUnpaidInfo({
          unpaid_buoy_count_down: null
        })
      }

      return (
        <UnpaidBuoyComponent
          timeStamp={timeStamp - Date.now()}
          onClose={handleCloseBuoy}
          onContinue={continueToPay}
          onFinishCountDown={handleStopCountDown}
          unpaidInfo={props.unpaidInfo}
          onUpdateUnpaidInfo={props.onUpdateUnpaidInfo}
          pendingPaymentInfo={pendingPaymentInfo}
        />
      )
    }
  }

  return null
}

export default withUnpaidOrdersEnabled(UnpaidOrderContainer)