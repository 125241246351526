import {
  parsePrice,
} from './utils/priceUtils.js'

/* ---------------------------------------------------- */

class ProductImage {
  constructor(data) {
    this.src = data.imageUrl
    this.title = data.title
  }
}

/* ---------------------------------------------------- */

class ProductVariant {
  constructor(data) {
    this.id = data.id
    this.title = data.title

    this.msrp = parsePrice(data.msrp)
    this.price = parsePrice(data.price)
    this.displayPrice = data.displayPrice
    this.displayMsrp = data.displayMsrp

    this.image = data.imageUrl ? new ProductImage(data) : null

    this.selectedOptions = data.selectedOptions

    this.availableForSale = data.availableForSale
  }
}

/* ---------------------------------------------------- */

class Product {
  constructor(data) {
    this.id = data.id
    this.handle = data.handle

    this.mainVariation = new ProductVariant(data)

    this.mainImage = data.imageUrl
      ? new ProductImage(data)
      : null

    this.title = data.title
    this.availableForSale = Boolean(data.availableForSale)
  }
}

export default Product
