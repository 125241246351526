import React, { useContext, useEffect } from 'react'

import Button from '../../common/Button/PlainButton'
import { withModal } from '../../common/Modal/Modal'

import cx from '../../../utils/className'

import { PluginContext } from '@flamingo_tech/funkgo'

import styles from './PushNotificationModal.module.css'


const SCENE = {
  HOME: 'Home',
  CART: 'Cart',
  ORDER: 'Order',
  WISH: 'Wish'
}

const CONTENT = {
  [SCENE.HOME]: ['Exclusive coupons', 'Latest promotions', 'Your order status'],
  [SCENE.CART]: ['Your interesting products','Exclusive coupons', 'Latest promotions'],
  [SCENE.ORDER]: ['Your order status', 'Exclusive coupons', 'Latest promotions'],
  [SCENE.WISH]: ['Updates of products', 'Exclusive coupons', 'Latest promotions']
}


const ModalContent = ({
  content,
  onConfirm,
  onCancel
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <div className={styles.header}>
          <h5 className={styles.title}>Notify Me, Never Miss It!</h5>
          <div className={styles.content}>
            {
              content.map((item, index) => (
                <div
                  key={index}
                  className={styles.item}
                >
                  <div className={styles.icon}/>
                  <div>{item}</div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.buttonGroup}>
          <Button
            className={cx(styles.button, styles.highlighted)}
            onClick={onConfirm}>YES, PLEASE
          </Button>
          <Button
            className={styles.button}
            onClick={onCancel}>LATER
          </Button>
        </div>
      </div>
    </div>
  )
}

const Modal = withModal(ModalContent)


const PushNotificationModal = ({ hide, onClose, scene = SCENE.HOME }) => {
  const { $bridge, $track, $storage, $detector } = useContext(PluginContext)

  const notifyRequestStorage = $storage.create('notify_request')

  const notifyRequestItem = notifyRequestStorage.getItem({})

  // control that every request scene can only be triggered once
  const hasSceneRequested = (notifyRequestItem.requestScene || []).indexOf(scene) > -1

  const modalDisabled = (
    !$detector.isApp() || $detector.compareAppVersion({ targetVersion: '6.1.0' }) || notifyRequestItem.rejectCount === 0 || hasSceneRequested
  )

  /* ------------------------------------------- */
  useEffect(() => {
    // if hide === false, but modal has been disabled, then trigger onClose
    if (modalDisabled && !hide) {
      onClose()
    }
  }, [modalDisabled, onClose, hide])
  /* ------------------------------------------- */

  const trackEvent = label => {
    if ($track) {
      $track.event('Permission', label)
    }
  }

  const handelConfirm = () => {
    trackEvent('request_permission_push')

    notifyRequestStorage.setItem({
      rejectCount: 0
    })

    trackEvent('accept_permission_push')
    $bridge.requestNotifyAuth().catch(err => {
      trackEvent(`error_permission_push_${err.message}`)
    })
    onClose()
  }

  const handleCancel = () => {
    trackEvent('accept_permission_push')

    notifyRequestStorage.setItem(Object.assign({}, notifyRequestItem, {
      rejectCount: (notifyRequestItem.rejectCount || 0) + 1,
      requestScene: [...(notifyRequestItem.requestScene || []), scene]
    }))

    onClose()
  }

  if (modalDisabled) {
    return null
  }

  return (
    <Modal
      hide={hide}
      onClose={handleCancel}
      content={CONTENT[scene]}
      onConfirm={handelConfirm}
      onCancel={handleCancel}
     />
  )
}

PushNotificationModal.SCENE = SCENE

export default PushNotificationModal
