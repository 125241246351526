import React from 'react'

import PageShellContainer from './PageShellContainer'
import GuidePageFloatWidgetContainer from './GlobalWidgets/GuidePageFloatWidgetContainer'

const GuidePageShellContainer = props => {
  return (
    <>
      <PageShellContainer
        {...props}
      />
      <GuidePageFloatWidgetContainer />
    </>
  )
}

export default GuidePageShellContainer
