const makeLegacyProductOptions = ({ variants = [] }) => {
  const sizeOptions = []
  const colorOptions = []

  variants.forEach(variant => {
    sizeOptions.push(variant.size)
    colorOptions.push(variant.color)
  })

  return [{
    name: 'Color',
    values: [...new Set(colorOptions)]
  }, {
    name: 'Size',
    values: [...new Set(sizeOptions)]
  }]
}

const pickMainVariation = ({ variations = [] }) => {
  let firstSalableVariation = null

  variations.some(variation => {
    if (variation.availableForSale) {
      firstSalableVariation = variation
      return true
    }
    return false
  })

  return firstSalableVariation || variations[0] || null
}

export {
  makeLegacyProductOptions,
  pickMainVariation
}