import React, { useEffect, useContext, useRef } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import { useInView } from '../InView/InView'

const ImpressionObserver = ({ children, eventId, eventName, eventParams, containerClassName, supportDesktop = false, impressionConfig = {}, triggerOnce = false, rootMargin = '0px'}) => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector && plugin.$detector.isDesktop()
  const timer = useRef(null)
  const previousInView = useRef(false)

  const [ref, inView]= useInView({
    triggerOnce,
    threshold: 0.6,
    rootMargin,
    ...impressionConfig
  })


  // 元素60%可见且曝光1s即为有效曝光
  useEffect(() => {
    if (inView) {
      if (!previousInView.current) { // each inView from false to true will count
        previousInView.current= true

        clearTimeout(timer.current)

        timer.current = setTimeout(() => {
          plugin.$track && plugin.$track.impression({
            eventId,
            eventName,
            eventParams
          })
          clearTimeout(timer.current)
        }, 1000)
      }

    } else {
      previousInView.current = false

      clearTimeout(timer.current)
    }
  }, [inView, eventId, eventParams, eventName, plugin.$track])

  if (!eventId || (isDesktop && !supportDesktop)) { //不在桌面端进行曝光打点
    return children
  }

  return (
    <div className={containerClassName} ref={ref}>
      {children}
    </div>
  )
}

ImpressionObserver.displayName = 'ImpressionObserver'


export default ImpressionObserver