export const toDecimal = x => {
  const value = parseFloat(x)

  return Math.round(value * 100) / 100;
}


export const parsePrice = value => {
  const price = toDecimal(value)

  if (isNaN(price)) {
    throw new Error(`${value} is not a valid price`)
  }

  return price
}

export const parseNullablePrice = value => {
  if (!value) {
    return null
  }

  return parsePrice(value)
}


const getDiscountValue = (price, msrp) => {
  return Math.round((price / msrp) * 100)
}

export const getDiscountText = (price, msrp) => {
  return `-${100 - getDiscountValue(price, msrp)}%`
}
