import React, { useState } from 'react'

import FilterSortBar from './FilterSortBar'
import CollectionContent from './CollectionContent'
import SortDropDown from './SortDropDown'
import CollectionTags from './CollectionTags'
import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './CollectionPage.module.css'
import cx from '../../../utils/className'
import InView from '../../common/InView/InView'
import withStoreHeader from './withStoreHeader'
import CollectionFilter, { FilterModal } from './CollectionFilter'
import CollectionSEOContents from './CollectionSEOContents'

const eventCategoryName = 'CollectionFilter'

const CollectionPage = withStoreHeader(props => {
  const productList = props.productList
  const queryOptions = props.queryOptions
  const isDesktop = props.$detector.isDesktop()
  const { collectionFilters, isCollectionFiltersFetched } = props
  const { count } = productList
  const showFilter = !isCollectionFiltersFetched || (collectionFilters && collectionFilters.length > 0)
  const [tiny, setTiny] = useState(false)
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const renderSortBar = ({ collectionFilters = {} }) => {
    return (
      <FilterSortBar
        filterData={collectionFilters}
        onSort={props.handleSort}
        onFilter={() => setFilterModalOpen(true)}
        queryOptions={queryOptions}
        eventCategoryName={eventCategoryName}
      ></FilterSortBar>
    )
  }

  const isImageTags = props.collectionTags && props.collectionTags.filter(i => i.img_url).length > 0

  const renderSticky = () => {
    return (
      <>
        {
          isImageTags && <InView onChange={inView => setTiny(!inView)}><div/></InView>

        }
        <div className={cx(styles.stickyBox, isImageTags && tiny && styles.tiny, props.showHeader && styles.showHeader)}>
          {
            isImageTags && <div className={tiny ? styles.white : styles.out}></div>
          }

          {
            props.collectionTags && props.collectionTags.length > 0 && (
              <div className={styles.imageTagsBox}>
                <CollectionTags list={props.collectionTags} tiny={tiny} />
              </div>
            )
          }
          {renderSortBar({ ...productList, collectionFilters })}
        </div>
      </>
    )
  }

  const renderSEOContent = () => {
    if (props.collectionSEOInfo && props.collectionSEOInfo.seoContent) {
      return <CollectionSEOContents seoContent={props.collectionSEOInfo.seoContent} isDesktop={isDesktop} $i18n={props.$i18n} />
    }

    return null
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, isImageTags && styles.isImageTags)}>
      {
        isDesktop
        ?
        <div className={styles.desktopContainer}>
          <div className={styles.headerWrapper}>
            <div className={cx(styles.header, !showFilter && styles.noFilter, isImageTags && styles.isImageTags)}>
              <div className={styles.leftInfo}>
                <h1 className={styles.title}>{props.collectionTitle.split(' - ')[0]}</h1>
                <span className={styles.goodsNum}>{props.$i18n.transl('core.collection.productCount', { count })}</span>
              </div>
              <SortDropDown dark={isImageTags} eventCategoryName={eventCategoryName} handleSort={props.handleSort} queryOptions={queryOptions}/>
            </div>
          </div>
          <div className={styles.collectionTagsBox}>
            <CollectionTags list={props.collectionTags} />
          </div>
          <div className={styles.mainWrapper}>
            {
              showFilter && (
                <div className={cx(styles.leftArea, !showFilter && styles.noFilter)}>
                  <div className={styles.filterContent}>
                    <CollectionFilter filterData={collectionFilters} queryOptions={queryOptions} handleSort={props.handleSort}/>
                  </div>
                </div>
              )
            }
            <div className={cx(styles.nullWrapper, isDesktop && styles.rightArea, !showFilter && styles.noFilter)}>
              <CollectionContent $i18n={props.$i18n} isDesktop={isDesktop} loading={props.loading} productList={productList} ProductListComponent={props.ProductListComponent}/>
              {renderSEOContent()}
            </div>
          </div>
        </div>
        :
          <>
            {renderSticky()}
            <div className={styles.titleBar}>
              <h1 className={styles.title}>{props.collectionTitle.split(' - ')[0]}</h1>
              <span className={styles.goodsNum}>{props.$i18n.transl('core.collection.productCount', { count })}</span>
            </div>
            {renderSEOContent()}
            <CollectionContent $i18n={props.$i18n} loading={props.loading} productList={productList} ProductListComponent={props.ProductListComponent} onClickReselect={() => setFilterModalOpen(true)}/>
          </>
      }
      {
        filterModalOpen &&
        <FilterModal
          onClose={() => setFilterModalOpen(false)}
          filterData={collectionFilters}
          handleSort={props.handleSort}
          queryOptions={queryOptions}
        />
      }
    </div>
  )
})

export default withPlugin(CollectionPage)
