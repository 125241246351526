import React, { useEffect, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import WheelOverlayPcCard from './WheelOverlayPcCard'

import styles from './EmailHideCoupon.module.css'
import cx from '../../../utils/className'

const EmailHideCoupon = props => {
  const [show, setShow] = useState(true)
  
  const [showRightGuideInfo, setRightGuideInfo] = useState(true)
  const isBrowser = typeof window !== 'undefined'
  const sideBar = props.coupon.pcLayout && props.coupon.pcLayout.sideBar
  const { email: userEmail } = props.$storage.create('an_lg_email').getItem({}) || {} // 用户本地邮箱

  useEffect(() => {
    if (userEmail) {
      setShow(false)
    }
  }, [userEmail])

  const handleClick = () => {
    if (isBrowser) {
      setRightGuideInfo(!showRightGuideInfo)
    }
  }

  const sideBarStyle = sideBar ? {
    background: `url(${sideBar}) no-repeat`,
    backgroundSize: '100%'
  } : {}

  if (!show || props.hide) {
    return null
  }

  return (
    <div className={cx(styles.guideWrapper, showRightGuideInfo && styles.guideHide)}>
      <div className={styles.guideBtn} style={sideBarStyle} onClick={() => handleClick()}></div>
      <WheelOverlayPcCard {...props.wheelOverlayProps} />
    </div>
  )
}

export default withPlugin(EmailHideCoupon)