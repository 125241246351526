import {
  PROMOTION_TYPE,
  TARGET_TYPE,

  ensureDate,
  formatPromotionType,
  formatTargetType,
  formatTargetSelection,
  formatDiscount,
} from '../utils/couponModelUtils'

const DEFAULT_LINK = { name: 'Home' }
const DEFAULT_TAKEN_MODE = 'MANUAL'

class CouponMetaModel {
  constructor(data) {
    this.id = data.couponId || data.id
    this.title = data.name || data.title
    this.description = data.description || data.desc

    this.link = data.link || data.linkUrl || DEFAULT_LINK
    this.couponTakenMode = data.couponTakenMode || DEFAULT_TAKEN_MODE

    this.type = formatPromotionType(data)
    this.targetType = formatTargetType(data)
    this.targetSelection = formatTargetSelection(data)

    const { discount, requirement, X, Y, fixedAmount } = formatDiscount(data)

    this.discount = discount
    this.requirement = requirement

    if (this.type === PROMOTION_TYPE.FIXED_AMOUNT_SELECT_Y) {
      this.fixedAmount = fixedAmount
    }

    if (this.type === PROMOTION_TYPE.BUY_X_GET_Y) {
      this.X = X
      this.Y = Y
    }

    this.startDate = ensureDate(data.effectiveStartAt || data.startDate)
    this.endDate = ensureDate(data.effectiveEndAt || data.endDate)

    // fields for display
    const metadata = data.metadata || {}

    this.clientStartDate = data.clientStartDate || metadata.clientStartDate
    this.clientEndDate = data.clientEndDate || metadata.clientEndDate
    this.layout = data.layout || metadata.layout

    this.name =  data.title
    this.subtitle = data.subtitle
    this.displayScope = data.displayScope
    this.maxDiscountAmount = data.maxDiscountAmount
    this.effectiveDay = data.effectiveDay
  }
}

class CouponModel {
  constructor(data) {
    const meta = data.meta || {}

    this.id = data.id || meta.id
    this.meta = new CouponMetaModel(meta)
    this.userOptions = data.userOptions || {}
  }
}

const transferBE2FECouponModel = item => (
  new CouponModel({
    id: item.couponId,
    meta: {
      ...item,
      id: item.couponId
    },
    userOptions: {
      fromServer: true,
      serverId: item.id,
      takenTime: item.receivedAt,
    }
  })
)


CouponModel.Meta = CouponMetaModel
CouponModel.transferBE2FECouponModel = transferBE2FECouponModel
CouponModel.PROMOTION_TYPE = PROMOTION_TYPE
CouponModel.TARGET_TYPE = TARGET_TYPE

export default CouponModel
export {
  transferBE2FECouponModel,
  PROMOTION_TYPE,
  TARGET_TYPE
}