import React, { useEffect, useState } from 'react'
import { hoistHocStatic } from '@flamingo_tech/funkgo'

const usePageBackObserver = () => {
  const [pageBackTime, setPageBackTime] = useState(null)
  useEffect(() => {
    const handleRefresh = () => {
      if (!document.hidden) {
        setPageBackTime(Date.now())
      }
    }
    const isObserverAvailable = window && window.document

    if (isObserverAvailable) {
      document.addEventListener('visibilitychange', handleRefresh)
    }

    return () => {
      if (isObserverAvailable) {
        document.removeEventListener('visibilitychange', handleRefresh)
      }
    }
  })

  return pageBackTime
}

const withPageBackObserver = Component => {
  const WrappedComponent = props => {
    const pageBackTime = usePageBackObserver()

    return (
      <Component {...props} pageBackTime={pageBackTime}></Component>
    )
  }

  WrappedComponent.displayName = `withPageBackObserver(${Component.displayName || Component.name})`
  hoistHocStatic(WrappedComponent, Component)
  return WrappedComponent
}

export {
  usePageBackObserver
}

export default withPageBackObserver


