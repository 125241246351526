import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import MultiColumns from '../common/List/MultiColumns'
import ProductCardSkeleton from './ProductCardSkeleton'

import styles from './ProductListSkeleton.module.css'
import cx from '../../utils/className'

/* ------------------------- */
const PRODUCT_SKELETON_COUNT = 20

const ProductListSkeleton = props => {
  const items = new Array(PRODUCT_SKELETON_COUNT).fill({})
  const isDesktop = props.$detector.isDesktop()

  return (
    <MultiColumns
      columns={isDesktop ? 5 : 2}
      childIs={ProductCardSkeleton}
      childItems={items}
      className={cx(styles.list, isDesktop && styles.isDesktop, props.className)}
    />
  )
}

export default withPlugin(ProductListSkeleton)
