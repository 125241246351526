import React, { useContext } from 'react'

import withGlobalSetting from '../../../hooks/withGlobalSettingEnabled'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './FreeShippingBanner.module.css'
import cx from '../../../utils/className'

const FreeShippingBanner = ({ displayFreeShippingFee, className }) => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, className )}>
      {plugin.$i18n.transl('core.cart.shipping.rule', {
        freeShippingFee: `${displayFreeShippingFee || ''}`
      })}
    </div>
  )
}

export default withGlobalSetting(FreeShippingBanner, 'shipping')
