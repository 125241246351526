import React, { useContext, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Button from '../Button/Button'
import cx from '../../../utils/className'
import styles from './ErrorPage.module.css'

const BACK_BTN = {
  label: 'common.backHome',
  action: props => {
    if (props.type) {
      props.$track.event('PageError', `${props.type}_back_home`)
    }
    props.$router.navigateToHomePage()
  }
}

const RELOAD_BTN = {
  label: 'common.refresh',
  action: props => {
    if (props.type) {
      props.$track.event('PageError', `${props.type}_reload`)
    }

    document.location.reload()
  }
}

const TYPE = {
  NOT_FOUND: 'NOT_FOUND',
  ERROR: 'ERROR',
  NO_SEARCH_RES: 'NO_SEARCH_RES',
}

const TYPE_VALUE = {
  [TYPE.NOT_FOUND]: {
    className: styles.notFound,
    title: 'error.notFound',
    btn: BACK_BTN
  },
  [TYPE.ERROR]: {
    className: styles.error,
    title: 'error.error',
    btn: RELOAD_BTN,
    secondBtn: BACK_BTN
  }
}

const formatError = error => error.message ? error.message : String(error)

const DefaultErrorPage = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  useEffect(() => {
    // some error page which use to display known situation only, e.g. (user has no orders in ordersContainer)
    // , so only track error if error specific
    if (props.type === TYPE.ERROR && props.error) {
      plugin.$track.event('PageError', `${props.type}_view`, formatError(props.error))

    // track every not found view
    } else if (props.type === TYPE.NOT_FOUND) {
      plugin.$track.event('PageError', `${props.type}_view`, formatError(props.error || new Error('Page Not Found')))
    }

  }, [plugin, props.type, props.error])

  const defaultConfigure = TYPE_VALUE[props.type] || {}

  const configure = {
    ...defaultConfigure,
    ...props
  }

  const makeHandleClick = fn => () => fn({
    type: props.type,
    $router: plugin.$router,
    $track: plugin.$track
  })

  const buttons = [configure.btn, configure.secondBtn].filter(btn => btn)

  if (!buttons.length) {
    if (props.reloadBtn) {
      buttons.push(RELOAD_BTN)
    }

    if (props.backBtn) {
      buttons.push(BACK_BTN)
    }
  }

  const buttonElements = buttons.map(({ label, action }, i) => (
    <Button
      key={i}
      onClick={makeHandleClick(action)}
      invert={i > 0}
    >
      {plugin.$i18n.transl(label)}
    </Button>
  ))

  const btnWrapperClassNames = {
    1: styles.withOneButtonInRow,
    2: styles.withTwoButtonInRow,
  }

  return (
    <div className={cx(styles.page, configure.className, isDesktop && styles.isDesktop)}>
      <h1>{plugin.$i18n.transl(configure.title)}</h1>
      {buttons.length > 0 && <div className={cx(styles.btnWrapper, btnWrapperClassNames[buttons.length])}>{buttonElements}</div>}
    </div>
  )
}

DefaultErrorPage.TYPE = TYPE

const ErrorPage = props => <DefaultErrorPage {...props} type={TYPE.ERROR}></DefaultErrorPage>

const NotFoundPage = props => {
  useEffect(() => {
    const hasWindow = typeof window !== 'undefined'
    const handleServiceWorkerUpdate = () => {
      if (hasWindow) {
        window.location.reload()
      }
    }

    if (hasWindow) {
      window.addEventListener('serviceWorkerUpdated', handleServiceWorkerUpdate, false)
    }

    return () => {
      if (hasWindow) {
        window.removeEventListener('serviceWorkerUpdated', handleServiceWorkerUpdate, false)
      }
    }
  })
  return (
    <DefaultErrorPage {...props}  type={props.type ? props.type : TYPE.NOT_FOUND}></DefaultErrorPage>
  )
}

export default DefaultErrorPage
export {
  ErrorPage,
  NotFoundPage
}
