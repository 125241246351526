import React, { useContext }  from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import { withModal } from '../../common/Modal/Modal'

import Image from '../../common/Image/Image'

import cx from '../../../utils/className'

import styles from './CouponModal.module.css'

const getDiscountText = coupon => {
  const { percentage, amount } = coupon
  return percentage ? `${percentage}%` : `$${amount}`
}

const TYPES = {
  welcome: styles.welcome,
  congrats: styles.congrats
}

const renderDefaultDesc = props => (
  <>
    {
      props.target &&
      <>
        <div>ON ORDERS</div>
        <div>OVER ${props.target.value}+</div>
      </>
    }
  </>
)


const GuideImage = props => {
  const { type, coupon, renderDesc } = props

  const descElement = typeof renderDesc === 'function'
    ? renderDesc(props)
    : renderDefaultDesc(props)

  return (
    <div className={cx(styles.guideImage, type || styles.congrats )}>
      <div className={styles.content}>
        {
          props.children ?
          props.children
          :
          <>
            <div className={styles.discount}>
              <span className={styles.highlighted}>{getDiscountText(coupon)}</span>
              <span className={styles.off}>OFF</span>
            </div>
            <div className={styles.target}>
              {descElement}
            </div>
          </>
        }
      </div>
    </div>
  )
}

const CouponModal = props => {
  const plugins = useContext(PluginContext)
  const isDesktop = plugins.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, props.image ? styles.withCustomizedImage : '')}>
      <div className={styles.clickableWrapper} onClick={props.onConfirm}>
        <div className={styles.imageWrapper}>
          {
            props.image ?
            <Image
              src={props.image}
              {...props}
            /> :
            <GuideImage {...props} />
          }

        </div>
        {
          props.showCloseButton &&
          <div className={styles.rect}>
            <div className={styles.btn} onClick={props.onClose}/>
          </div>
        }
      </div>
    </div>
  )
}

export default withModal(CouponModal, { maskClassName: styles.mask })
export {
  TYPES
}
