import React, { PureComponent, useRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './ShopTheStyle.module.css'
import cx from '../../../utils/className'

import Image from '../../common/Image/Image'
import Slider from '../../common/Slider/Slider'
import ProductCard from '../ProductCard/ProductCard'
import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'
import withSliderTouch from '../../../hooks/withSliderTouch'
import withQuickAdd from '../../../hooks/withQuickAdd'

const EVENT_CATEGORY = 'ShopTheStyle'

const ProductCardWithQuickAdd = withQuickAdd(props => {
  const ref = useRef()
  const { product, imageProps } = props
  return (
    <div className={styles.image} onClick={() => {
      props.onQuickAdd({
        product,
        refInfo: {
          triggerRef: ref
        }
      })
      props.$track.clickSubResourceLocator(`Product:${EVENT_CATEGORY}_${props.current}-${product.handle}`, {
        category: props.productCategory
      })
    }}>
      <Image {...imageProps} disableClick={true} />
    </div>
  )
})
class ShopTheStyle extends PureComponent {
  state = {
    current: 0,
    end: false
  }

  renderImage = (product, imageProps) => {
    return <ProductCardWithQuickAdd product={product} current={this.state.current} imageProps={imageProps} productCategory={this.props.productCategory}/>
  }

  render() {
    const { shopTheStyleData } = this.props
    if (!shopTheStyleData || shopTheStyleData.length === 0) {
      return null
    }

    const multiCard = shopTheStyleData.length > 1

    return (
      <ImpressionObserver eventName='shop_the_style' eventId={this.props.productHandle}>
        <div className={cx(styles.wrapper, this.props.isDesktop && styles.isDesktop)}>
          <div className={styles.title}>
            <span>{this.props.$i18n.transl('core.product.shopTheStyle')}</span>
            {
              multiCard && (
                <div className={styles.subTitle}>
                  {
                    shopTheStyleData.map((i, index) => (
                      <span
                        key={index}
                        onClick={() => this.sliderRef.slickGoTo(index)}
                        className={cx(styles.subItem, this.state.current === index && styles.active)}
                      >{i.title}</span>
                    ))
                  }
                </div>
              )
            }
          </div>
          <div className={styles.sliderBox}>
            <Slider
              swipe={false}
              ref={sliderRef => this.sliderRef = sliderRef}
              beforeChange={(oldIndex, newIndex) => {
                this['listRef' + oldIndex].scrollTo({
                  left: 0,
                  behavior: 'smooth'
                })

                this.setState({
                  current: newIndex,
                  end: false
                })
              }}
            >
              {
                shopTheStyleData.map((sliderItem, index) => {
                  return (
                    <div key={index}>
                      <div style={{ position: 'relative' }}>
                        {
                          this.props.isDesktop && sliderItem.products.length > 3 && (
                            <div>
                              <div style={{ display: this.state.end ? 'block' : 'none' }} className={cx(styles.pcSwiperButton, styles.pcLeftButton)} onClick={() => {
                                this.setState({ end: false })
                                this['listRef' + this.state.current].scrollTo({
                                  left: 0,
                                  behavior: 'smooth'
                                })
                              }} />
                              <div style={{ display: this.state.end ? 'none' : 'block' }} className={cx(styles.pcSwiperButton, styles.pcRightButton)} onClick={() => {
                                this.setState({ end: true })
                                this['listRef' + this.state.current].scrollTo({
                                  left: 1000,
                                  behavior: 'smooth'
                                })
                              }} />
                            </div>
                          )
                        }
                        <div
                          ref={listRef => this['listRef' + index] = listRef}
                          className={styles.list}
                          onTouchStart={this.onTouchStart}
                        >
                          <div className={styles.mainImage}>
                            <Image src={sliderItem.imageUrl} placeholder={{ width: 2, height: 3 }} crop={{ width: 2, height: 3 }} />
                          </div>

                          {
                            sliderItem.products.map((item, index) => (
                              <div key={index} className={styles.imageBox}>
                                <ProductCard
                                  showFloatDiscount={true}
                                  {...item}
                                  renderColorSwatch={() => { }}
                                  renderTitle={() => { }}
                                  onClickQuickAdd={product => {
                                    this.props.$track.clickSubResourceLocator(`Product:${EVENT_CATEGORY}_${this.state.current}-${product.handle}`, {
                                      category: this.props.productCategory
                                    })
                                  }}
                                  className={styles.image}
                                  disableClick={this.props.disableClick}
                                  renderImage={!this.props.isDesktop && this.renderImage}
                                />
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </div>
      </ImpressionObserver>
    )
  }
}

export default withPlugin(withSliderTouch(ShopTheStyle))