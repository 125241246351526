import React, { useState, useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import SearchBar from './SearchBar'
import SearchPanel from './SearchPanel'

import cx from '../../../utils/className'
import styles from './SearchPage.module.css'

const SearchPage = props => {
  const plugin = useContext(PluginContext)

  const [searchText, setSearchText] = useState('')
  const { defaultSearch, historySearches, hotSearches } = props

  const handleSearch = (searchText, ev) => {
    setSearchText(searchText)
    props.onSearch(searchText, ev)
  }

  const handleHotSearchClick = (searchItem, ev) => {
    if (searchItem.search) {
      setSearchText(searchItem.search)
    }

    props.onSearchHot(searchItem, ev)
  }

  const isApp = plugin.$detector.isApp()
  return (
    <div className={cx(styles.root, isApp && styles.wrapper)}>
      <SearchBar
        onBack={props.onBack}
        mode={props.mode}
        onCancel={props.onCancel}
        onSearch={props.onSearch}
        placeholder={defaultSearch}
        value={searchText}
      />
      <SearchPanel
        plugin={plugin}
        onClearSearchHistory={props.onClearSearchHistory}
        historySearches={historySearches}
        hotSearches={hotSearches}
        onHistoryItemClick={(text, ev) => {
          handleSearch(text, ev)
        }}
        onHotItemClick={(item, index) => {
          handleHotSearchClick(item, index)
        }}
      />
    </div>
  )
}

export default SearchPage
