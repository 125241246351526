import React, { PureComponent } from 'react'
import NewProgressBar from '../ProgressBar/NewProgressBar'
import { withPlugin } from '@flamingo_tech/funkgo'
import cx from '../../../utils/className'

import styles from './FullScreenVideoPlayer.module.css'

const EVENT_CATEGORY = 'Video'

class FullScreenVideoPlayer extends PureComponent {

  state = {
    currentIndex: this.props.index || 0,
    muted: true,
    progress: 0,
    paused: false
  }

  duration = 0
  lastProgress = -1
  viewTime = 0
  watchTime = 0
  bufferingStartTime = 0
  totalBufferingTime = 0


  video = React.createRef()

  componentDidMount() {
    this.trackView()
  }

  componentWillUnmount() {
    this.trackWatchTimeAndBufferingRatio()
  }

  trackView = () => {
    this.viewTime = new Date()
    this.trackEvent({ action: 'view' })
  }

  trackWatchTimeAndBufferingRatio = () => {
    if (this.watchTime) {
      this.trackEvent({
        action: 'viewTime',
        label: this.watchTime.toFixed(2)
      })
    }

    this.watchTime = 0

    if (this.totalBufferingTime) {
      const ratio = (this.totalBufferingTime / ((this.duration || 1) * 1000)).toFixed(2)

      if (ratio > 0) {
        this.trackEvent({
          action: 'bufferingRatio',
          label: ratio
        })
      }
    }

    this.totalBufferingTime = 0
  }

  handleSwitch = (ev, isNext)=> {
    ev.stopPropagation()
    // clear pause state, always play
    this.setState({
      currentIndex: isNext ? this.state.currentIndex + 1 : this.state.currentIndex - 1
    })
    this.clearVideoStatus()
  }

  handleVideoFinish = () => {
    const nextIndex = this.state.currentIndex === this.props.list.length - 1 ? 0 : this.state.currentIndex + 1

    if (this.state.currentIndex === nextIndex) { // only one video, need replay
      this.clearVideoStatus()

      this.video.current.play()

    } else {
      this.setState({
        currentIndex: nextIndex
      })
      this.clearVideoStatus()

    }
  }


  clearVideoStatus = () => {
    this.trackWatchTimeAndBufferingRatio()

    this.setState({
      paused: false,
      progress: 0
    })

    this.lastProgress = -1

    setTimeout(() => {
      this.trackView()
    }, 100)

  }

  trackEvent = ({
    action,
    label
  }) => {
    const { id } = this.props.list[this.state.currentIndex]

    this.props.$track.event(this.props.eventCategory ||  EVENT_CATEGORY, action, label, undefined, {
      id,
      index: this.state.currentIndex
    })
  }

  handleToggleMute = ev => {
    ev.stopPropagation()

    this.setState({
      muted: !this.state.muted
    })

    const video = this.video.current

    video.muted = !this.state.muted

  }

  handleTimeUpdate = () => {
    const video = this.video.current

    const { currentTime, duration } = video

    const progress = currentTime / (isNaN(duration) ? 1 : duration)

    if (!isNaN(duration) && duration > 0) {
      if (progress >= 0.50 && progress < 0.75 && !(this.lastProgress >= 0.50 && this.lastProgress < 0.75)) {
        this.trackEvent({ action: 'midpoint' })
      } else if (progress >= 0.90 && !(this.lastProgress >= 0.90)) {
        this.trackEvent({ action: 'complete' })
      }

      this.lastProgress = progress
      this.watchTime = currentTime
      this.duration =  duration
    }

    this.setState({
      progress
    })
  }


  handleCanPlay = () => {
    this.trackEvent({
      action: 'timeToFirstFrame',
      label: new Date() - this.viewTime
    })
  }


  handleError = (ev) => {
    const errorMessage = ev.target.error ? ev.target.error.message : 'load_error'

    this.trackEvent({
      action: 'error',
      label: errorMessage
    })
  }

  handleTogglePlay = () => {
    const video = this.video.current

    const readyToOperate = video.currentTime > 0 && !video.ended

    if (readyToOperate) {
      if (video.paused) {
        video.play()
        this.setState({
          paused: false
        })
      } else {
        video.pause()
        this.setState({
          paused: true
        })
      }
    }
  }

  handleWaiting = () => {
    this.bufferingStartTime = new Date()
  }

  handlePlaying = () => {
    if (this.bufferingStartTime > 0) {
      this.totalBufferingTime += new Date() - this.bufferingStartTime
      this.bufferingStartTime = 0
    }
  }

  handleClose = ev => {
    ev.stopPropagation()
    this.props.onClose()
  }

  render() {
    const { list = []} = this.props

    const { video } = list[this.state.currentIndex] || {}

    return (
      <div className={styles.wrapper}>
        <video
          className={styles.video}
          src={video}
          ref={this.video}
          controls={false}
          autoPlay={true}
          poster='data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
          playsInline={true}
          muted={this.state.muted}
          onCanPlay={this.handleCanPlay}
          onEnded={this.handleVideoFinish}
          onTimeUpdate={this.handleTimeUpdate}
          onError={this.handleError}
          onWaiting={this.handleWaiting}
          onPlaying={this.handlePlaying}
        />
        <div className={styles.maskWrapper} onClick={this.handleTogglePlay}>
          <div className={styles.header}>
            <div className={styles.pagination}>{this.state.currentIndex + 1}/{this.props.list.length}</div>
            <div className={styles.right}>
              <div className={cx(styles.voice, this.state.muted && styles.muted)} onClick={this.handleToggleMute}></div>
              <div className={styles.close} onClick={this.handleClose}/>
            </div>
          </div>
          <div className={styles.paginationArrow}>
            {
              this.state.currentIndex !== 0 &&
              <div className={styles.left} onClick={ev => this.handleSwitch(ev, false)}/>
            }
            {
              this.state.currentIndex !== this.props.list.length - 1 &&
              <div className={styles.right} onClick={ev => this.handleSwitch(ev, true)}/>
            }
          </div>
            {
              this.state.paused && <div className={styles.paused} />
            }
          <div className={styles.progress}>
            <NewProgressBar
              value={this.state.progress}
              outerBarClassName={styles.outerBar}
              innerBarClassName={styles.innerBar}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withPlugin(FullScreenVideoPlayer)