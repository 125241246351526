import ProductCommentModel from './ProductCommentModel'

class ProductCommentQueue {
  constructor({ comments = [], count, star }) {
    this.comments = comments.map(comment => new ProductCommentModel(comment))
    this.count = count
    this.star = star.toFixed(1)
  }
}


export default ProductCommentQueue
