import React from 'react'
import styles from './SearchPanel.module.css'

import Link from '../../common/Link/Link'
import cx from '../../../utils/className'

const SearchItem = props => {
  const itemProps = {
    className: cx(styles.searchItem, props.hot && styles.hot),
    onClick: ev => props.onClick(ev),
    children: props.value
  }

  const link = props.link

  if (link) {
    return (
      <Link {...itemProps} to={link}></Link>
    )
  }

  return (
    <div {...itemProps}></div>
  )
}

const SearchPanel = props => {
  const { hotSearches, historySearches, plugin, onClearSearchHistory, onHistoryItemClick, onHotItemClick, className } = props
  return (
    <div className={cx(styles.searchRecommendSections, className)}>
      {
        historySearches && historySearches.length > 0 &&
        <section className={cx(styles.history, styles.section)}>
          <h2 className={styles.sectionTitle}>
            <div>{plugin.$i18n.transl('core.search.historySearch')}</div>
            <div className={styles.clearIcon} onClick={onClearSearchHistory} />
          </h2>
          <div className={styles.content}>
            {
              historySearches.map(text => (
                <SearchItem
                  value={text}
                  key={text}
                  onClick={ev => {
                    onHistoryItemClick(text, ev)
                  }}
                />
              ))
            }
          </div>
        </section>
      }
      {
        hotSearches && hotSearches.length > 0 &&
        <section className={cx(styles.hot, styles.section)}>
          <h2 className={styles.sectionTitle}>{plugin.$i18n.transl('core.search.hotSearch')}</h2>
          <div className={styles.content}>
            {
              hotSearches.map((item, index) => (
                <SearchItem
                  value={item.search}
                  link={item.link}
                  hot={item.hot}
                  key={item.search}
                  onClick={ev => {
                    onHotItemClick(item, index)
                  }}
                />
              ))
            }
          </div>
        </section>
      }
    </div>
  )
}

export default SearchPanel