import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'


import Image from '../common/Image/Image'
import GirlsShowCard from './GirlsShowCard'
import MultiColumns from '../common/List/MultiColumns'
import ImpressionObserver from '../common/ImpressionObserver/ImpressionObserver'
import cx from '../../utils/className'

import styles from './GirlsShowHomePage.module.css'


const WrapperGirlsShowCard = props => {
  return (
    <ImpressionObserver eventParams={{ index: props.index }} eventName='GirlsShow:Home' eventId={props.id}>
      <GirlsShowCard {...props} />
    </ImpressionObserver>
  )
}


const GirlsShowHomePage = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  const handlePersonalCardClick = () => {
    if (props.user) {
      plugin.$router.navigate({
        name: 'GirlsShowMyHomePage'
      })
    } else {
      props.onLogin()
    }
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
      <div className={styles.header}>
        <div className={cx(styles.personalCard, props.user && styles.withUser)} onClick={handlePersonalCardClick}>
          <Image
            src={props.user && props.user.image && props.user.image.src}
            className={styles.image}
            small
            objectFit="cover"
          />
          <div className={styles.name}>{props.user ? props.user.name : props.$i18n.transl('core.girlsShow.login')}</div>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>{props.$i18n.transl('core.girlsShow.exploreFlamingals')}</div>
        {
          props.posts.length > 0 &&
          <MultiColumns
            columns={isDesktop ? 6 : 2}
            className={styles.postList}
            columnClassName={styles.listColumn}
            childIs={WrapperGirlsShowCard}
            childItems={props.posts}
            childOptions={{
              onClickLike: props.onToggleLike
            }}
            onReachEnd={props.onReachEnd}
          />
        }

      </div>
    </div>
  )
}

export default GirlsShowHomePage
