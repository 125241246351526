import React , { useState, useEffect, useRef, forwardRef } from 'react'

import Link from '../Link/Link'

import cx from '../../../utils/className'
import styles from './Button.module.css'

const TYPE = {
  NORMAL: styles.normal,
  PRIMARY: styles.primary,
  LITE: styles.lite
}

const Button = forwardRef((props, ref) => {
  const buttonType = props.type || TYPE.NORMAL
  const linkMode = props.url || props.to
  const className = cx(styles.button, props.className, {
    [styles.inline]: props.inline,
    [buttonType]: !props.plain,
    [styles.invert]: !props.plain && props.invert,
    [styles.small]: !props.plain && props.small,
    [styles.beat]: props.beat,
    [styles.beatFast]: props.beatFast,
    [styles.link]: linkMode
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const isMounted = useRef(true)

  useEffect(() => () => {
    isMounted.current = false
  }, [])

  const setIsSubmittingState = value => {
    if (isMounted.current) {
      setIsSubmitting(value)
    }
  }

  const onClick = (ev) => {
    if (isSubmitting) {
      return
    }
    setIsSubmittingState(true)
    const f = props.onClick(ev)
    return Promise.resolve(f).then(() => {
      setIsSubmittingState(false)
    }, () => {
      setIsSubmittingState(false)
    })
  }

  if (!linkMode && typeof onClick !== 'function') {
    throw new Error('must provide onClick() callback or src prop to Button')
  }

  const commonProps = {
    className,
    onClick,
    disabled: props.disabled || isSubmitting
  }

  if (linkMode) {
    return (
      <Link {...commonProps} {...props} ref={ref}>{props.children}</Link>
    )
  }

  if (props.submit) {
    if (typeof props.children !== 'string') {
      throw new Error('type=submit only accept string children')
    }

    if (props.isSubmitting) {
      return (
        <button {...commonProps} disabled ref={ref}>
          <div className={styles.isSubmitting}></div>
        </button>
      )
    }

    return (
      <input type="submit"
        ref={ref}
        {...commonProps}
        value={props.children}/>
    )
  }

  if (props.plain) {
    return (
      <button {...commonProps} ref={ref}>
        <span>{props.children}</span>
      </button>
    )
  }

  return (
    <button {...commonProps} ref={ref}>
      {isSubmitting && <div className={styles.isSubmitting}></div>}
      {/* a workaround to fix crash issue, ref to: https://github.com/facebook/react/issues/11538 */}
      {!isSubmitting && <span>{props.children}</span>}
    </button>
  )
})

Button.TYPE = TYPE

export default Button