import React,{ useContext, useState } from 'react'
import styles from './CollectionFilter.module.css'
import queryString from 'query-string'
import FilterRange from './FilterRange'
import FilterMultiChoice from './FilterMultiChoice'
import { getFilterNumByOptions, composeSearchParams } from '../../../utils/Store/sortUtils'
import Button from '../../common/Button/Button';
import { PluginContext } from '@flamingo_tech/funkgo'
import { withDrawer, DRAWER_POSITION} from '../../common/Drawer/Drawer'
import { getFilterOptions } from  '../../../utils/Store/sortUtils'
import cx from '../../../utils/className'

const filterComponent = {
  multiChoice: FilterMultiChoice,
  range: FilterRange
}

const composeFilterMap = (filterData, selectedOptions) => {
  var map = {}
  const result = filterData.map(section => {
    return {...section, filters: section.filters.map(item => {
      let selected = false
      const selectedFilters = selectedOptions[section.filterKey]
      selected = selectedFilters && selectedFilters.indexOf(item.displayKey) > -1
      return {...item, isSelected: selected}
    })}
  })
  return {...map, list: result}
}

const CollectionFilter = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const queryOptions = getFilterOptions(props.queryOptions)
  const [selectedOptions, setSelectedOptions] = useState({...queryOptions})
  const filterNum = isDesktop ? getFilterNumByOptions(queryOptions) : getFilterNumByOptions(selectedOptions)

  const onFilterChange = (data) => {
    const options = {...queryOptions, ...data}
    const keys = Object.keys(options)

    keys.forEach(i => {
      if (options[i].length === 0) {
        delete options[i]
      }
    })

    setSelectedOptions(options)
    if (isDesktop) {
      plugin.$track.event('CollectionFilter', 'click_filter', getTrackInfo(data))
      props.handleSort(options)
    }
  }

  const getTrackInfo = (trackData) => {
    return queryString.stringify(composeSearchParams(trackData))
  }

  const handleClearAll = () => {
    let map = {}
    Object.keys(selectedOptions).forEach(key => {
      if (key === 'sort') {
        map[key] = selectedOptions[key]
      }
    })
    setSelectedOptions(map)
    if (isDesktop) { // instantly request api only in pc
      props.handleSort(map)
    }
  }

  const handleClickDoneBtn = () => {
    plugin.$track.event('CollectionFilter', 'click_filter', getTrackInfo(selectedOptions))
    props.handleSort(selectedOptions)
    props.onClose()
  }

  const renderFilterItemByKey= (section, index) => {
    const { displayType, filters } = section

    if (!filters && filters.length < 1) {
      return null
    }

    const Component = filterComponent[displayType]

    const props = {
      key: index,
      section: section,
      onFilterChange: onFilterChange,
      selectedOptions: selectedOptions
    }

    return (
      <Component {...props}/>
    )
  }

  const composeData = composeFilterMap(props.filterData, selectedOptions)

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
      {
        !isDesktop
        ?
        (
          <div className={styles.header}>
            <div className={styles.closeBtn} onClick={props.onClose}></div>
            <h5 className={styles.title}>{plugin.$i18n.transl('core.collection.filter')}</h5>
            <div/>
          </div>
        )
        :
        <div>
          {
            filterNum > 0 && (
              <Button className={styles.pcClearBtn} onClick={() => handleClearAll()}>
                {plugin.$i18n.transl('common.clearAll')} {`(${filterNum})`}
              </Button>
            )
          }
        </div>
      }
      <div className={styles.content}>
        {
          composeData && (
            composeData.list.map((section, index) => {
              return renderFilterItemByKey(section, index)
            })
          )
        }
      </div>
      {
        !isDesktop && (
          <div className={styles.buttonGroup}>
            {
              filterNum > 0 &&
              <Button className={styles.clearBtn} onClick={() => handleClearAll()}>
                {plugin.$i18n.transl('common.clear')}
              </Button>
            }
            <Button className={styles.doneBtn} onClick={() => handleClickDoneBtn()}>
              {plugin.$i18n.transl('common.done')}
            </Button>
          </div>
        )
      }
    </div>
  )
}

const FilterModal = withDrawer(CollectionFilter, { position: DRAWER_POSITION.RIGHT })

export default CollectionFilter

export {
  FilterModal
}
