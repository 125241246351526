import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './CollectionTags.module.css'
import cx from '../../../utils/className'

import Image from '../../common/Image/Image'
import withSliderTouch from '../../../hooks/withSliderTouch'
import CollectionPCTags from './CollectionPCTags'

const CollectionImageTags = ({ isDesktop, list, disableClick, onItemClick, tiny }) => {
  const renderImageList = () => {
    return list.map((item, index) => (
      <div key={index} className={cx(styles.tagsImageItem, isDesktop && styles.isDesktop)} onClick={disableClick ? () => {} : () => onItemClick(item, index)}>
          <div className={styles.tagsImageBox}><Image tiny className={styles.image} src={item.img_url} lazy /></div>
          <div className={styles.tagsName}>{item.name}</div>
      </div>
    ))
  }

  if (isDesktop) {
    return <CollectionPCTags list={list} onItemClick={onItemClick} />
  }

  return (
    <div className={styles.CollectionImageTags}>
      <div className={tiny ? styles.tiny : styles.normal}>
        {renderImageList()}
      </div>
    </div>
  )
}

const CollectionTags = ({ list, disableClick, tiny }) => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  if (!list || list.length === 0) {
    return null
  }

  const handleClick = (item, index) => {
    plugin.$track.clickMainResourceLocator(`Collection:Tag_${item.name}`, {
      url: item.link,
      index,
    })
    plugin.$router.navigate(item.link)
  }

  if (list && list[0].img_url) {
    return <CollectionImageTags tiny={tiny} isDesktop={isDesktop} onItemClick={handleClick} list={list} disableClick={disableClick} />
  }

  return (
    <div className={cx(styles.CollectionTags, isDesktop && styles.isDesktop)}>
      {
        list.map((item, index) => {
          return (
            <div key={index} className={styles.tabItem} onClick={() => handleClick(item, index)}>{item.name}</div>
          )
        })
      }
    </div>
  )
}

export default withSliderTouch(CollectionTags)