import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import CartItem from './CartItem'
import SelectIcon from '../CheckOut/SelectIcon'
import Loading from '../../Game/GameList/subComponents/Loading'
import CartPageSoldOutList from './CartPageSoldOutList'
// import withLoginCheck from '../../../hooks/withLoginCheck'

import styles from './DesktopCartContent.module.css'
// const EVENT_CATEGORY = 'Cart'

/* -------------------------------------------- */

const DesktopCartContent = props => {
  const [loading, setLoading] = useState(false)

  const {
    lineItems,
    onCartItemQuantityChange,
    onClearExpireItems,
    cartLineItemCount
  } = props

  const saleableLineItems = []
  const unsaleableLineItems = []

  lineItems.forEach(lineItem => {
    if (lineItem.saleable) {
      saleableLineItems.push(lineItem)
    } else {
      unsaleableLineItems.push(lineItem)
    }
  })

  const selectCart = (lineItem, isSelectAll) => {
    if (loading) {
      return
    }

    setLoading(true)
    props.onCartItemSelect(lineItem, isSelectAll)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }

  // const WithCheckSyncBanner = withLoginCheck(params => params.render(), {
  //   clickLogin: () => props.$track.event(EVENT_CATEGORY, `click_sync_cart`, 'top'),
  //   loginCallback: () =>  props.$track.event(EVENT_CATEGORY, `login_success`, 'top')
  // })

  // const WithCheckSync = withLoginCheck(params => params.render(), {
  //   clickLogin: () => props.$track.event(EVENT_CATEGORY, `click_sync_cart`, 'bottom'),
  //   loginCallback: () =>  props.$track.event(EVENT_CATEGORY, `login_success`, 'bottom')
  // })

  const isSelectAll = saleableLineItems.filter(i => i.selectedFlag).length === saleableLineItems.length
  // const hasLogin = props.$user.hasLogin()

  return (
    <>
      <div className={styles.wrapper}>
        {
          loading && <div className={styles.loading}><Loading /></div>
        }
        {/* {
        !hasLogin && (
          <div className={styles.syncTip}>{props.$i18n.transl('core.cart.notLoseYourItems')}
            <div className={styles.signBtn}><WithCheckSyncBanner render={() => <div>{props.$i18n.transl('core.login.signIn')}</div>} /></div>
          </div>
        )
      } */}
        <div className={styles.topHeader}>
          <SelectIcon selected={isSelectAll} onClick={() => selectCart('', !isSelectAll)} type={SelectIcon.TYPE.CHECKBOX} className={styles.selectIcon} />
          <div>
            {props.$i18n.transl('core.checkout.items')}
            {
              cartLineItemCount && <span>{` (${cartLineItemCount})`}</span>
            }
          </div>
        </div>
        <div className={styles.cartItems}>
          {
            saleableLineItems.map((lineItem, i) =>
              <div className={styles.cartItem} key={i}>
                <div className={styles.selectIconBox}>
                  <SelectIcon className={styles.selectIcon} onClick={() => selectCart(lineItem)} selected={lineItem.selectedFlag} type={SelectIcon.TYPE.CHECKBOX} />
                </div>
                <CartItem
                  {...lineItem}
                  onChange={quantity => onCartItemQuantityChange({ lineItem, quantity })}
                  className={styles.cartInnerItem}
                />
              </div>
            )
          }
        </div>
        {/* {
        !hasLogin && (
          <div className={styles.syncItemTip}>
            <div>{props.$i18n.transl('core.cart.cannotFindYourName')}</div>
            <WithCheckSync render={() => <span className={styles.syncItemTipLink}>{props.$i18n.transl('core.cart.signInToCheckYourCart')}</span>} />
          </div>
        )
      } */}
      </div>
      <CartPageSoldOutList
        isDesktop={true}
        $i18n={props.$i18n}
        onClearExpireItems={onClearExpireItems}
        unsaleableLineItems={unsaleableLineItems}
      />
    </>
  )
}

export default withPlugin(DesktopCartContent)