import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import queryString from 'query-string'

import MobileEntryFlowContainer from './MobileEntryFlowContainer'
import TrafficOfferContainer from './TrafficOfferContainer'
import CartBuoyContainer from './CartBuoyContainer'

const GlobalWidgetsContainer = (props) => {
  const plugin = useContext(PluginContext)

  const { promotion, hideGlobalCart = false } = props

  /* ---------------------------------------------- */

  const elements = []

  const isApp = plugin.$detector.isApp()

  const isDesktop = plugin.$detector.isDesktop()

  const trafficOfferStorage = plugin.$storage.create('_traffic_offer', { strategy: 'SESSION' })

  const query = queryString.parse(plugin.$router.location.search)

  const { utm_promo, promo } = query

  const promoCode = promo || utm_promo

  const showTrafficOffer = !!promoCode || trafficOfferStorage.getItem() !== undefined

  if (promotion === true && !isApp) {
    elements.push(
      (
        showTrafficOffer
          ?
          <TrafficOfferContainer
            key="trafficOffer"
            offerCode={promoCode}
          />
          : (
            <div key="MobileEntryFlowAndCartBuoy">
              <MobileEntryFlowContainer isDesktop={isDesktop} footHintHeight={props.footHintHeight} />
              {
                !isDesktop && (
                  <div>
                    <CartBuoyContainer hideGlobalCart={hideGlobalCart} />
                  </div>
                )
              }
            </div>
          )
      )
    )
  }

  return elements
}

export default GlobalWidgetsContainer
