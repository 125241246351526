import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import AppPageShell from '../../../components/Shell/Page/AppPageShell'
import AppPageShell620 from '../../../components/Shell/Page/AppPageShell6_2_0'

const AppLayoutContainer = props => {

  const plugin = useContext(PluginContext)

  const NewAppPageShell =  plugin && plugin.$detector.compareAppVersion({ targetVersion: '6.2.0' })
  ?
  AppPageShell620
  :
  AppPageShell


  return (
    <NewAppPageShell
      naviItems={props.naviItems}
      {...props}
    >
      {props.children}
    </NewAppPageShell>
  )
}

export default AppLayoutContainer
