import React, { useContext, useState, useEffect } from 'react'
import Button from '../common/Button/PlainButton'

import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './NotificationSettingCard.module.css'
import cx from '../../utils/className'
import createStorage from '../../utils/cacheUtils'

const NotificationSettingCard = props => {
  const plugin = useContext(PluginContext)
  const isApp = plugin.$detector.isApp()
  const $bridge = plugin.$bridge
  const $track = plugin.$track

  const [cardHide, setCardHide] = useState(true)

  useEffect(() => {
    if (isApp) {
      $bridge.getNotificationInfo().then(authInfo => {
        //先判断app的授权信息是否已授权，如果未授权再判断是否已经过了10天
        const nscExpireTimeStorage = createStorage('nsc_expire_time')
        const countDownTime = nscExpireTimeStorage.getItem(Date.now())

        if (authInfo === 'denied' && (Date.now() - countDownTime) > 0) {
          setCardHide(false)
          $track && $track.event('Permission', 'request_permission_reopen_push')

        } else {
          setCardHide(true)
        }
      }).catch(() => undefined)  // for legacy app and android, which does not respond result
    }
  }, [$bridge, $track, isApp])

  const handleClose = () => {
    const nscExpireTimeStorage = createStorage('nsc_expire_time')
    nscExpireTimeStorage.setItem(Date.now() + 1000 * 60 * 60 * 24 * 10)
    setCardHide(true)
  }

  const handleOpenSetting = () => {
    if (isApp) {
      $track.event('Permission', 'accept_permission_reopen_push')
      $bridge.openApplicationSetting()
        .catch(() => undefined)  // for legacy app and android, which does not respond result
    }
  }

  if (!isApp) {
    return null
  }

  if (cardHide) {
    return null
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={handleClose}></div>
      <div className={styles.content} onClick={handleClose}>Please enable notifications from the setting
      menu in order to get your order updates.</div>
      <Button className={cx(styles.button)}
        onClick={handleOpenSetting}>GO TO SETTING</Button>
    </div>
  )
}

export default NotificationSettingCard