import React,{ useContext, useRef, useEffect } from 'react'

import Search from '../../common/Search/Search'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './SearchBar.module.css'
import cx from '../../../utils/className'


const MODE = {
  BACK: 'back',
  CANCEL: 'cancel',
  NONE: 'none'
}

const SearchBar = props => {
  const inputRef = useRef()
  const { value, placeholder, className = '' } = props

  const plugin = useContext(PluginContext)

  let mode = props.mode || MODE.BACK

  const isApp = plugin.$detector.isApp()

  useEffect(() => {
    if(inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  return (
    <div className={cx(styles.wrapper, !isApp && styles.inWeb, className)}>
      {
        mode === MODE.BACK && <div className={cx(styles.back, !isApp && styles.webBack)} onClick={props.onBack}></div>
      }
      <Search placeholder={placeholder} ref={inputRef} value={value} onSearch={props.onSearch} className={styles.searchBar} onFocus={props.onFocus} onBlur={props.onBlur}/>
      {
        mode === MODE.CANCEL && <div className={styles.cancel} onClick={props.onCancel}>Cancel</div>
      }
    </div>
  )
}

SearchBar.MODE = MODE

export default SearchBar
