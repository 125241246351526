import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'

import MultiColumns from '../../common/List/MultiColumns'
import RecommendCard from '../Cart/RecommendCard'
import ProductCard from '../ProductCard/ProductCard'
import ViewMoreButton from '../Product/ViewMoreButton'

import styles from './CartRecommendationsPage.module.css'
import cx from '../../../utils/className'

const CartRecommendationsPage = (props) => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      <ImpressionObserver eventName='cart_ymal' eventId='cart_ymal' triggerOnce={true} rootMargin='0px 0px -128px 0px'>
        <div className={styles.tabslist}>
        {
            props.tabs.map((item, index) => {
              return (
                <div
                key={index}
                onClick={() => props.handleChangeTabIndex(index)}
                className={cx(styles.tabItem, props.currentTabIndex === index && styles.active)}
                >{item.title}</div>
              )
            })
          }
        </div>
      </ImpressionObserver>
      <div className={styles.listBox}>
        <MultiColumns
          columns={isDesktop ? 6 : 3}
          className={styles.list}
          columnClassName={styles.column}
          childIs={isDesktop ? ProductCard : RecommendCard}
          childItems={props.products.map(i => i.product)}
          onReachEnd={!isDesktop && props.hasNextPage && props.onReachEnd}
          childOptions={
            isDesktop
              ? {
                  onClick: (product, _, index) => {
                    plugin.$router.navigateToProductPage(product)
                    plugin.$track.clickSubResourceLocator(`Cart:YouMayAlsoLike_${props.tabs[props.currentTabIndex].title}-${product.handle}`, {
                      index,
                      id: product.id,
                      handle: product.handle,
                      sourceHandle: props.tabs[props.currentTabIndex].collectionHandle
                    })
                  },
                  onClickQuickAdd:  (product, index) => {
                    plugin.$track.clickSubResourceLocator(`Cart:YouMayAlsoLike_${props.tabs[props.currentTabIndex].title}-${product.handle}`, {
                      index,
                      id: product.id,
                      handle: product.handle,
                      sourceHandle: props.tabs[props.currentTabIndex].collectionHandle
                    })
                  }
                }
              : {
                baseInProps: true,
                inRecommend: true,
                CartRenderComponent: props.CartRenderComponent,
                currentTabTitle: props.tabs[props.currentTabIndex].title,
                needFetchFullAfterAddCart: true,
                sourceHandle: props.tabs[props.currentTabIndex].collectionHandle
              }}

        />
      </div>
      <div>{isDesktop && props.hasNextPage && <ViewMoreButton onClick={props.onReachEnd} />}</div>
    </div>
  )
}

export default CartRecommendationsPage
