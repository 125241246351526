import React from 'react'

import styles from './ShareModal.module.css'

const ShareModal = props => {

  return (
    <section className={`${styles.section} ${styles[props.arrowDirection]}`} onClick={props.onClose}>
      <div className={styles.content}>
        Click the share button to share the link with your friends!
      </div>

      <div className={styles.arrow}>
      </div>
    </section>
  )
}

export default ShareModal