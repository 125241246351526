import React from 'react'

const CommonLayout = ({ layoutItems, root }) => {
  const elements = layoutItems.map(({ Component, props }, i) => (
    <Component {...props} key={i}></Component>
  ))

  const style = root && root.background
    ? {
      backgroundColor: root.background,
      overflow: "hidden"
    } : null

  return (
    <div style={style}>{elements}</div>
  )
}

export default CommonLayout