import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../common/Image/Image'
import cx from '../../utils/className'
import { formatTime } from '../../utils/dateUtils'

import styles from './CommentCard.module.css'
import Link from '../common/Link/Link'

const CommentCard = props => {
  const { comment } = props

  if (!comment) {
    return null
  }

  const { user = {} } = comment

  return (
    <div className={cx(styles.wrapper, props.className)}>
      <div className={styles.titleWrapper}>
        { /* user image may be null, Image component will display default image if no specific src */ }
        {
          user &&
          <Link to={{ name: 'GirlsShowPersonalPage', params: { handle: user.id }}}>
            <Image title="avatar" src={user.avatar} className={styles.avatar} small objectFit="cover"/>
          </Link>
        }
        <div className={styles.rightWrapper}>
          {
            user &&
            <div className={styles.header}>
              <div className={styles.nickname}>{user.userName}</div>
              <div className={styles.desc}>{formatTime(comment.createTime * 1000)}</div>
            </div>
          }
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>{comment.content}</div>
      </div>
    </div>
  )
}


export default withPlugin(CommentCard)
