import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'

import cx from '../../../utils/className'
import styles from './Search.module.css'

const EMPTY_SEARCH_VALUE = ''

const Search = forwardRef((props, ref) => {
  const [searchText, setSearchText] = useState(props.value)
  const input = useRef()

  useImperativeHandle(ref, () => ({
    focus: () => {
      input.current.focus()
    }
  }))

  const handleSearch = ev => {
    ev.preventDefault()
    props.onSearch(searchText.trim() || props.placeholder)
  }
  
  const clearSearchText = () => {
    setSearchText(EMPTY_SEARCH_VALUE)
    props.onSearch(EMPTY_SEARCH_VALUE)
    input.current.focus()
  }

  const handleSearchChange = ev => {
    const value = ev.target.value
    setSearchText(value)
  }

  return (
    <div className={cx(styles.wrapper, props.className)}>
      <form className={styles.searchFrom} onSubmit={handleSearch}>
        <i className={styles.searchIcon}>&#xe60b;</i>
        <input type="text"
          ref={input}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={searchText}
          onChange={handleSearchChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        ></input>
        {
          searchText && <div className={styles.clearIcon} onClick={clearSearchText} />
        }
      </form>
    </div>
  )
})

const MyComponent = React.memo(Search);

export default MyComponent
