import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Link from '../Link/Link'

import { adjustImageUrl } from '@flamingo_tech/funkgo-utils/imageUtils'

import cx from '../../../utils/className'
import styles from './Image.module.css'

/* ------------------------------------ */

// const isSupportWebP = (() => {
//   if (process.env.NODE_ENV === 'test') {
//     return false
//   }

//   const canvas = typeof document === 'object' ? document.createElement('canvas') : {};
//   canvas.width = canvas.height = 1;
//   return canvas.toDataURL ? canvas.toDataURL('image/webp').indexOf('image/webp') === 5 : false;
// })()

const isLargeScreen = typeof window === 'object' && window.innerWidth > 560

const SIZE = {
  RIGHT_TINY: 90, // ignore screen
  TINY: isLargeScreen ? 180 : 90,
  SMALL: isLargeScreen ? 480 : 180,
  MIDDLE: isLargeScreen ? 720 : 480,
  MIDDLE_NEW: isLargeScreen ? 480 : 320,
  LARGE: isLargeScreen ? 1600 : 720,
  DESKTOP_DEFAULT: 1600,
  ORIGINAL: 9999
}

const OBJECT_FIT_STYLE = {
  cover: styles.cover,
  contain: styles.contain
}

const EXPECT_FILE_TYPE = /*isSupportWebP ? 'webp' :*/ undefined

/* ------------------------------------ */

const getMaxWidth = props => {
  if (props.maxWidth) {
    return props.size
  } else if (props.small) {
    return SIZE.SMALL
  }  else if (props.tiny) {
    return SIZE.TINY
  } else if (props.large) {
    return SIZE.LARGE
  } else if (props.rightTiny) {
    return SIZE.RIGHT_TINY
  }

  return SIZE.MIDDLE
}

const getImageUrl = props => props.src || props.image

const Image = props => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const [error, setError] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [originalImageSrc, setOriginalImageSrc] = useState(getImageUrl(props))
  const imageSrc = getImageUrl(props)

  const isSrcChanged = originalImageSrc !== imageSrc

  if (isSrcChanged) {
    setOriginalImageSrc(imageSrc)
    setError(false)
    setLoaded(false)
  }

  const imageError = !imageSrc || error

  let image

  let enableLazyLoad
  if (typeof window !== 'undefined') {
    enableLazyLoad = typeof window.IntersectionObserver !== 'undefined' && props.lazy
  } else {
    enableLazyLoad = props.lazy
  }

  if (!imageError && (!enableLazyLoad || inView)) {
    const maxWidth = getMaxWidth(props)
    const options = {
      fileType: props.fileType || EXPECT_FILE_TYPE,
      maxWidth,
      quality: props.quality
    }
    // 图片裁剪
    if (props.crop) {
      const crop = props.crop
      options.crop = {
        width: maxWidth,
        height: maxWidth * (parseInt(crop.height)/parseInt(crop.width))
      }
    }

    if (props.rightCrop) {
      const crop = props.rightCrop
      options.crop = {
        width: crop.width,
        height: crop.height
      }
    }

    const imageUrl = props.original
      ? imageSrc
      : adjustImageUrl(imageSrc, options)


    image = (
      <img
        className={cx(styles.image, OBJECT_FIT_STYLE[props.objectFit])}
        src={imageUrl}
        alt={props.title}
        title={props.displayTitle && props.title}
        onLoad={() => setLoaded(true)}
        onError={() => setError(true)}
      ></img>
    )
  }

  let wrapperClassName = cx(styles.wrapper, props.className, {
    [styles.error]: imageError
  })


  let wrapperStyle = {}

  // placeholder indicator will keep the block, and wait image loaded
  // since our layout will be dynamic via screen, so placeholder use ratio to keep the place
  if (!loaded && props.placeholder) {
    wrapperClassName += ` ${styles.placeholder}`

    const placeholder = props.placeholder
    if (typeof placeholder === 'string') {
      wrapperStyle.paddingTop = placeholder // percentage
    } else if (typeof placeholder === 'number') {
      wrapperStyle.paddingTop = 'unset'
      wrapperStyle.minHeight = `${placeholder}px` // fixed height, not recommended
    } else if (typeof placeholder === 'object') { // give width and height, calculate the ratio
      const { width, height } = props.placeholder
      if (typeof width === 'number' && typeof height === 'number') {
          wrapperStyle.paddingTop = `${100 / (width / height)}%`
        }
    } /* else: default ratio: 1: 1, defined on module.css */
  }

  if (props.style) {
    wrapperStyle = {...props.style, ...wrapperStyle}
  }

  const wrapperCommonProps = {
    style: wrapperStyle,
    className: wrapperClassName,
    onClick:  props.onClick
    ? (e => props.disableClick ? e.preventDefault() : props.onClick(e))
    : (e => props.disableClick && e.preventDefault()),
    ref: enableLazyLoad ? ref : null
  }

  const element = Link.hasLinkProps(props)
    ? <Link {...props} {...wrapperCommonProps}>{image}</Link>
    : <span {...wrapperCommonProps}>{image}</span>

  return element
}

Image.SIZE = SIZE

export default Image
