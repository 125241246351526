export const isNumber = (x) => {
  return typeof x === 'number' && !isNaN(x)
}

const parseFloatWithDigits = (x, digits) => {
  const multiple = digits * 10
  const value = parseFloat(x)

  return Math.round(value * multiple) / multiple
}

export const toDecimal = (x, digits = 2) => {
  if (!Number.isInteger(digits)) {
    throw new Error(`${digits} is not a valid digit`)
  }

  const decimal = parseFloatWithDigits(x, digits)

  return decimal
}

/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
export const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min
}

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export const getRandomInt = (min, max) => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getDiffRandomIntNumber = (notWantInt, min, max) => {
  let result = getRandomInt(min, max)
  if (result !== notWantInt) {
    return result
  } else {
    return getDiffRandomIntNumber(notWantInt, min, max)
  }
}

export const pushZeroToNumber = (number, digits) => {
  let result = ''
  const numberString = `${number}`
  const count = digits - numberString.length

  for (let i = 0; i < count; i++) {
    result += '0'
  }
  result += numberString
  return result
}

export const getSpellIncreasedIntNumber = ({
  spellLength = 1000 * 60 * 60,
  min = 100,
  max = 1000,
} = {}) => {
  const nowProportion = (Date.now() % spellLength) / spellLength
  return Math.floor(nowProportion * (max - min) + min)
}

export const getIntNumberLocaleString = (number) => {
  return typeof number === 'number' ? parseInt(number, 10).toLocaleString() : number
}

export const isDigit = (str) => {
  var reg = /^\d+$/
  return reg.test(str)
}

// 将入参数值分为整数和小数两部分
export const splitDecimal = decimalNumber => {  
  const integerPart = Math.floor(decimalNumber);  
  const decimalPart = decimalNumber - integerPart;  
  return { integerPart, decimalPart };  
}