import React from 'react'
import Image from '../Image/Image'

import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './ColorPicker.module.css'
import cx from '../../../utils/className'

const ColorPicker = (props) => {
  const isDesktop = props.$detector.isDesktop()
  const { colorImageLink, colorImageUrl, color } = props.colorSwatchItem || {}
  const imgProps = {
    rightTiny: true,
    objectFit: 'cover',
    className: cx(styles.image, props.imageClass)
  }

  const renderColorAndImag = () => {
    if (props.src) {
      return <Image key="src_swatch" {...imgProps} src={props.src} />
    } else if (!props.colorSwatchItem) {
      return null
    } else if (colorImageLink) {
      return <Image key="color_link_swatch" {...imgProps} src={colorImageLink} />
    } else if (color) {
      return <div className={cx(styles.bg, props.imageClass)} style={{ background: color }} />
    } else if (colorImageUrl) {
      return <Image key="color_url_swatch" {...imgProps} src={colorImageUrl} />
    }

    return <Image {...imgProps} {...props} />
  }

  return (
    <div onClick={props.onClick || (() => {})} className={cx(styles.imageBox, props.active && styles.active, isDesktop && styles.isDesktop, props.className)}>
      {renderColorAndImag()}
    </div>
  )
}

export default withPlugin(ColorPicker)