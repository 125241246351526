import React, { useEffect } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import Countdown from '../../Widget/Countdown/Countdown'

import styles from './UnpaidCart.module.css'

import { withModal } from '../../common/Modal/Modal'

const EVENT_CATEGORY_UNPAID = 'UnpaidCart'
const endImageIndex = 3

const UnpaidCart = props => {
  const trans = key => {
    return props.$i18n.transl(`core.checkout.${key}`)
  }

  useEffect(() => {
    props.$track.event(EVENT_CATEGORY_UNPAID, 'view')
  }, [props.$track])

  const lineItems = (props.cart && props.cart.lineItems) || []
  const renderContent = () =>{
    return (
      <div onClick={props.onConfirm}>
          <div className={styles.couponWrapper}>
              {
                (props.coupons || []).map(coupon => (
                  <div className={styles.coupon} key={coupon.id}>
                    <div className={styles.leftWrapper}>
                      <div className={styles.name}>{coupon.name}</div>
                      <div className={styles.desc}>{coupon.desc}</div>
                    </div>
                    <Countdown
                      className={styles.countdownClassName}
                      cardClassName={styles.cardClassName}
                      separatorClassName={styles.separatorClassName}
                      timestamp={900000}
                      type={Countdown.TYPE.CLOCK}
                    />
                  </div>
                ))
              }
          </div>
          <div className={styles.cartItems}>
            {
              lineItems.map((item, index) => {
                return (
                  <div key={item.id} className={styles.cartItem}>
                    <Image src={item.image} small crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }}/>
                    {
                      index === endImageIndex && <div className={styles.cover}>&#xe684;</div>
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{trans('limitTimeOffer')}</div>
      <div className={styles.subtitle}>{trans('getBeforeExpired')}</div>
        {
          renderContent()
        }
      <div className={styles.continue} onClick={props.onConfirm}>{trans('unlockOffer')}</div>
    </div>
  )
}

export default withModal(withPlugin(UnpaidCart), { showCloseButton: true, closeBtnClassName: styles.closeBtnClassName, disableCoverClick: true })
