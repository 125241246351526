import React, { useState, useContext, createRef } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import ProductSection from './ProductSection'
import Image from '../../common/Image/Image'
import Slider from '../../common/Slider/Slider'

import { PostImageContentModal } from './ShowAndReviewSection'
import styles from './GirlsShowSection.module.css'
import cx from '../../../utils/className'

const SLIDE_TO_SHOW = 5

const PostImage = props => {
  const title = props.userName ? `@${props.userName}` : null
  const imageTitle = (title && props.productTitle) ? `${title}’s show of ${props.productTitle} - thumbnail` : null

  return (
    <div className={styles.postWrapper}>
      <Image
        src={props.src}
        title={imageTitle}
        small
        lazy
        objectFit='cover'
        placeholder={280}
        className={styles.image}
        onClick={props.onClick}
      />
      <div className={styles.content}>
        {
          title && (
            <h4 className={styles.title}>{title}</h4>
          )
        }
        {
          props.subtitle && (
            <h5 className={styles.subtitle}>{props.subtitle}</h5>
          )
        }
        <h5 className={styles.desc}>{props.desc}</h5>
      </div>
    </div>
  )
}

const GirlsShowSection = props => {
  const sliderEl = createRef()
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  const [showPreviewer, setShowPreviewer] = useState(false)
  const [showIndex, setShowIndex] = useState(0)
  const { totalCount } = props.pageInfo || {}
  const productTitle = props.title

  if (!totalCount) {
    return null
  }

  const handleImageClick = index => {
    setShowPreviewer(true)
    setShowIndex(index)
  }

  const handlePreviewerClose = () => {
    setShowPreviewer(false)
  }

  const shows = props.posts.map((post, index) => {
    const props = {
      src: post.mainImage,
      userName: post.userInfo.name,
      productTitle: productTitle,
      subtitle: post.skuTitle,
      desc: post.desc
    }

    return (
      <div key={post.id}>
        <PostImage {...props} onClick={() => handleImageClick(index)} />
      </div>
    )
  })

  const handleLeftSwipe = () => {
    sliderEl.current.slickPrev()
  }

  const handleRightSwipe = () => {
    props.onReachEnd()

    sliderEl.current.slickNext()
  }

  const sectionTitle = plugin.$i18n.transl('core.product.shows', { count: totalCount })
  return (
    <>
      <ProductSection sectionTitle={isDesktop && sectionTitle}>
        <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
          {
            !isDesktop &&
            <div className={styles.header}>
              <h2 className={styles.title}>{sectionTitle}</h2>
            </div>

          }
          {
            isDesktop ?
              <div className={styles.desktopShowSlider}>
                {
                  totalCount > SLIDE_TO_SHOW &&
                  <>
                    <div className={styles.leftButton} onClick={handleLeftSwipe} />
                    <div className={styles.rightButton} onClick={handleRightSwipe} />
                  </>
                }
                <Slider slidesToShow={SLIDE_TO_SHOW} slidesToScroll={SLIDE_TO_SHOW} ref={sliderEl} className={styles.slider}>
                  {shows}
                </Slider>
              </div>
              :
              <div className={styles.images}>
                {shows}
              </div>
          }
        </div>
      </ProductSection>
      {
        showPreviewer &&
        <PostImageContentModal
          onClose={handlePreviewerClose}
          initPreviewIndex={showIndex}
          headerTitle={sectionTitle}
          productTitle={productTitle}
          posts={props.posts}
          onPostsReachEnd={props.onReachEnd}
        />
      }

    </>
  )
}

export default GirlsShowSection
