import React from 'react'

import InView from '../InView/InView'
import cx from '../../../utils/className'

import styles from './HorizontalColumns.module.css'

const HorizontalColumns = props => {
  const { childIs, childItems = [], childOptions } = props

  const handleReachEnd = inView => {
    if (inView) {
      if (typeof props.onReachEnd === 'function') {
        props.onReachEnd()
      }
    }
  }

  const renderElement = (item, i) => {
    if (typeof props.renderChild === 'function') {
      return props.renderChild(item, i)
    }

    const ChildComponent = childIs
    const isNeedTriggerReachEnd = i => i === childItems.length - 1
    const element = (
      <ChildComponent
        key={i}
        index={i}
        {...item}
        {...childOptions}
      ></ChildComponent>
    )
    if (isNeedTriggerReachEnd(i)) {
      return (
        <InView key={i} onChange={handleReachEnd} triggerOnce={true}>
          {element}
        </InView>
      )
    }

    return element
  }

  const elements = childItems.map(renderElement)

  if (props.LastCard) {
    const LastCard = props.LastCard

    elements.push((
      <LastCard key="last" onClick={props.onLastCardClick}></LastCard>
    ))
  } else if (typeof props.renderLastCard === 'function') {
    elements.push(props.renderLastCard())
  }

  return (
    <div className={cx(styles.list, props.className)}>
      {elements}
    </div>
  )
}


export default HorizontalColumns
