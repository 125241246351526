import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './PriceSummaryList.module.css'
import cx from '../../../utils/className'
import { getPriceList } from '../../../utils/Store/productUtils'

const LIST_MODE = {
  CART: 'cart',
  ORDER: 'order'
}

const SummaryList = props => {
  const priceList = getPriceList({
    $i18n: props.$i18n,
    hideTotal: props.hideTotal
  })({
    ...props,
    totalSaveFee: '',
    discountTag: props.mode === LIST_MODE.CART && props.$i18n.transl('core.cart.bestCouponApplied')
  })

  return (
    <div className={cx(styles.priceCard, props.className)}>
      {
        priceList.map(({ title, subTitle, value, negative, classKey, tag }) => {
          return (
            <div key={title} className={cx(styles.priceLine, styles[classKey])}>
              <div className={styles.priceLabel}>
                <span className={styles.title}>
                  {title}{subTitle && <span className={styles.subTitle}>{subTitle}</span>}
                </span>
                {tag && <span className={styles.tag} onClick={props.onClickCouponEntrance}>
                  {tag} <span className={styles.arrow}>&#xe7b2;</span>
                </span>}
              </div>
              <div className={cx(styles.priceValue, negative && styles.negative)}>{value}</div>
            </div>
          )

        })
      }
    </div>
  )
}

const PriceSummaryList = withPlugin(SummaryList)

PriceSummaryList.MODE = LIST_MODE

export default PriceSummaryList