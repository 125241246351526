class ProductCommentBasicInfoModel {
  constructor(data) {
    Object.assign(this, {
      count: parseInt(data.info.count, 10) || 0,
      star: data.info.newStar.toFixed(1),
      comments: data.comments || []
    })
  }
}

export default ProductCommentBasicInfoModel
