import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'

import styles from './ProductCardPrice.module.css'

const ProductPrice = ({ mainVariation, hideOriginalPrice = false, originalPriceClassName, discountPriceClassName, salePriceClassName, }) => {
  const { price, msrp, displayPrice, displayMsrp } = mainVariation || {}

  const hasOriginalPrice = msrp && msrp > price

  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <span className={cx(styles.price, isDesktop && styles.inDesktop)}>
        <span className={cx(styles.salePrice, hasOriginalPrice && styles.highlighted, salePriceClassName || null)}>{displayPrice}</span>
        {
          !hideOriginalPrice && hasOriginalPrice
            ? <span className={cx(styles.originalPrice, salePriceClassName || null)}>{displayMsrp}</span>
            : null
        }

    </span>
  )
}

export default ProductPrice