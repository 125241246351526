const PROMOTION_TYPE = {
  STANDARD_OFF: 'STANDARD_OFF',
  BUY_X_GET_Y: 'BUY_X_GET_Y',
  FIXED_AMOUNT_SELECT_Y: 'FIXED_AMOUNT_SELECT_Y'
}

export const getApplicableCouponsForCart = (availableCoupons, cart) => {

  if (!cart || !cart.lineItems || cart.lineItems.length === 0) {
    return []
  }

  return availableCoupons.filter(coupon => {
    const { meta } = coupon

    if (meta.type === PROMOTION_TYPE.STANDARD_OFF) {
      const { requirement } = meta

      if (!requirement) {
        return true
      }

      return requirement.type === 'QUANTITY'
       ? (getCouponFittedItemsSummary(coupon, cart).quantity >= requirement.value)
       : (getCouponFittedItemsSummary(coupon, cart).totalPrice >= requirement.value)

    } else if (meta.type === PROMOTION_TYPE.BUY_X_GET_Y) {
      const requiredQuantity = meta.X + meta.Y

      return getCouponFittedItemsSummary(coupon, cart).quantity >= requiredQuantity

    } else {
      return false
    }
  })
}


/*
  summary: {
    quantity,
    totalPrice
  }
*/

const getCouponFittedItemsSummary = (coupon, cart) => {
  let quantity = 0
  let totalPrice = 0

  const lineItems = cart.lineItems || []

  if (coupon.meta.targetType === 'ALL') {
    lineItems.forEach(lineItem => {
      quantity += lineItem.quantity
    })

    totalPrice =  cart.lineItemsSubtotalPrice
  } else {
    const targetCategories = (coupon.meta.targetSelection || []).map(selection => selection.category)

    lineItems.forEach(lineItem => {
      if (targetCategories.indexOf(lineItem.category) > -1) {
        quantity += lineItem.quantity
        totalPrice += lineItem.variantPrice * lineItem.quantity
      }
    })
  }
  return {
    quantity,
    totalPrice
  }
}

/*
a. GET15/10 => 15%/10%
b. Buy 1 Get 1 40% OFF => 40%
c. Get $5 OFF orders $30+ => 16.7%
*/
export const getCouponDiscountPercentage = coupon => {
  const { meta } = coupon

  if (meta.type === PROMOTION_TYPE.STANDARD_OFF) {
    if (!meta.requirement || meta.requirement.type === 'QUANTITY') {
      return meta.discount && meta.discount.value
    } else {
      return ((meta.discount && meta.discount.value) || 0) / (meta.requirement.value || 1) * 100
    }
  } else if (meta.type === PROMOTION_TYPE.BUY_X_GET_Y) {
    return meta.discount && meta.discount.value
  } else {
    return 0
  }
}

// TODO for vivian, if the couponMeta is fully equal, then return true to skip update data reference to avoid re-render or re-take
export const isCouponMetaFullyEqual = (couponMetaA, couponBetaB) => {
  return false
}