import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import cx from '../../../utils/className'
import { getDiscountText } from '../../../utils/priceUtils'

import styles from './ProductDealsCard.module.css'

const Wrapper = props => {
  const product = props.product

  const defaultHandleClick = ev => {
    if (typeof props.onClick === 'function') {
      props.onClick(product, ev)
    }
  }

  if (props.routerName || props.to || props.link) {
    let to = props.to || props.link

    if (!to) {
      to = Object.assign({
        name: props.routerName,
        params: {
          handle: product.handle
        }
      }, props.toExtraInfo)
    }

    const handleClick = ev => {
      ev.stopPropagation()
      defaultHandleClick(ev)
    }

    return (
      <Link to={to} className={props.className} onClick={handleClick}>
        {props.children}
      </Link>
    )
  }

  return (
    <div className={props.className} onClick={defaultHandleClick}>
      {props.children}
    </div>
  )
}

const ProductDealsCard = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const site = plugin.$site.getSiteInfo()

  //  display empty card when there is no data
  if (!props || !props.mainVariation || props.empty) {
    return <div className={styles.wrapper} />
  }

  const {
    empty,
    to, // for direct usage
    link, // for direct usage
    routerName, // for dsl usage
    onClick,
    toExtraInfo,
    style,
    ...product
  } = props

  const interactiveProps = {
    to,
    link,
    routerName,
    onClick,
    toExtraInfo
  }

  const { price, msrp, displayPrice, displayMsrp } = product.mainVariation
  const hasOriginalPrice = msrp && msrp > price
  const imageSize = isDesktop ? { middle: true } : { small: true }
  const { textColor, childImage } = style || {}

  const discountStyle = childImage ? {
    "background": `url(${childImage}) no-repeat`,
    "backgroundSize": "100%"
  } : null
  const descStyle = textColor ? { color: textColor } : null

  const handleCardClick = () => {
    if (props && props.handle) {
      plugin.$track.event('ProductDealsCard', `click_${props.handle}`)
    }
  }

  return (
    <Wrapper
      className={cx(styles.wrapper, isDesktop && styles.inDesktop)}
      {...interactiveProps}
      product={product}
    >
      {
        hasOriginalPrice && (
          <div className={styles.discount} style={discountStyle}>
            <div className={styles.desc} style={descStyle}>{getDiscountText(price, msrp)}</div>
          </div>
        )
      }
      <div className={styles.cardWrapper}>
        <div className={styles.imageWrapper}>
          <Image
            {...imageSize}
            lazy
            objectFit='cover'
            {...product.mainImage}
            className={cx(styles.cardImage, isDesktop && styles.inDesktop)}
            title={product.mainImage.title || `${site.name} ${product.title}`}
            onClick={handleCardClick}
            placeholder={{
              width: 4,
              height: 5
            }}
          />
        </div>
        <div className={styles.price}>
          <div className={styles.salePrice}>{displayPrice}</div>
          {
            hasOriginalPrice && <div className={cx(styles.originalPrice, isDesktop && styles.inDesktop)}>{displayMsrp}</div>
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default ProductDealsCard
