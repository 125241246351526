import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import { getNextNinetyDays } from '../../../utils/dateUtils'

import styles from './CartCouponCard.module.css'
import cx from '../../../utils/className'

const CARD_SIZE = {
  LARGE: 'large',
  SMALL: 'small'
}

const CartCouponCard = props => {
  const renderTopContent = coupon => {
    return (
      <div className={styles.couponInfo}>
        <div className={styles.couponTitle}>{coupon.title}</div>
        <div className={styles.couponSubTitle}>{coupon.subtitle}</div>
      </div>
    )
  }

  const renderBottomContent = () => {
    const { effectiveDay, clientStartDate, clientEndDate, rule}  = props.coupon
    const cartCouponsDate = getNextNinetyDays(Date.now())
    const now = new Date()

    const couponDate =  (
      (clientStartDate || clientEndDate)
      ? `${new Date(clientStartDate || now).toLocaleDateString()} ~ ${new Date(clientEndDate || now).toLocaleDateString()}`
      : (
        effectiveDay > 0
        ? (effectiveDay > 1 ? props.$i18n.transl('core.promotion.validDays', { days: effectiveDay }) : props.$i18n.transl('core.promotion.validDay', { days: effectiveDay }))
        : cartCouponsDate
      )
    )

    return (
      <div className={styles.couponDesc}>
        <div className={cx(styles.couponDate, effectiveDay > 0 && !clientEndDate && styles.highlighted)}>- {couponDate}</div>
        <div className={styles.couponRule}>- {rule || props.$i18n.transl('core.promotion.wheel.applyToAll')}</div>
      </div>
    )
  }

  const barStyle = {
    boxShadow: `0 0 0 375px ${props.backgroundColor}`
  }

  const barBallStyles = {}

  if (props.barColor) {
    barBallStyles.backgroundColor = props.barColor
  }

  if (props.bordered) {
    return (
      <div className={cx(styles.borderedCouponCard, props.className)}>
        <div>{renderTopContent(props.coupon)}</div>
        <div className={styles.bar}>
          <div className={styles.leftRadio} style={barBallStyles} />
          <div className={styles.rightRadio} style={barBallStyles} />
        </div>
        <div>{renderBottomContent()}</div>
      </div>
    )
  }

  return (
    <div className={cx(styles.cutCouponCard, props.className)}>
      <div className={styles.leftSide} style={barStyle} />
      <div className={styles.body} style={{ backgroundColor: props.backgroundColor }}>
        <div>{renderTopContent()}</div>
        <div>{renderBottomContent()}</div>
      </div>
      <div className={styles.rightSide} style={barStyle} />
    </div>
  )
}

CartCouponCard.SIZE = CARD_SIZE

export default withPlugin(CartCouponCard)
