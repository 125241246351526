import React, { useContext, useRef } from 'react'
import { animateScroll } from 'react-scroll'
import { PluginContext } from '@flamingo_tech/funkgo'

import AppStateContext from '../../../hooks/AppStateContext'
import CartPageMobileContent from './CartPageMobileContent'
import EmptyCartContent from './EmptyCartContent'
import PageHeader from '../../common/PageHeader/PageHeader'
import withBackToTop from '../../../hooks/withBackToTop'

import cx from '../../../utils/className'

import styles from './CartPage.module.css'

const renderNull = () => null

const CartPage = props => {
  const appState = useContext(AppStateContext)
  const plugin = useContext(PluginContext)
  const cartRecommendationRef = useRef(null)

  const {
    className,

    appliedCoupon,
    totalPrice,
    discountFee,
    shippingFee,

    lineItems,
    cartLineItemCount,
    checkoutItemCount,

    renderEmptyCartRecommendations = renderNull,
    renderCartRecommendations = renderNull,

    onCartItemQuantityChange,
    onCheckout,
    onClearExpireItems,
    onCartItemSelect,

    displayDiscountFee,
    displayFreight,
    displayProductFee,
    displayTotalFee,

    totalSaveFee,
    displayTotalSaveFee,
    addOnsForFreeShippingAmount,
    // displayAddOnsForFreeShippingAmount
    showPaypal,
    onPayWithPaypal,
    paypalCheckoutInstance,
    paypalTotalFee,
    onTrack,
    onClickPaypalButton,
    onClickCouponEntrance
  } = props


  const handleClickAddOnToYAML = () => {
    plugin.$track.event('Cart', 'click_addon')
    setTimeout(() => {
      if (cartRecommendationRef && cartRecommendationRef.current) {
        const pageYOffset = window.pageYOffset
        const recommendOffset = cartRecommendationRef.current.offsetTop - 40

        if (pageYOffset < recommendOffset) {
          animateScroll.scrollTo(recommendOffset, {
            duration: 0,
            smooth: 'linear'
          })
        }
      }
    }, 0)

  }

  const hasCartItems = lineItems.filter(lineItem => lineItem.saleable).length > 0
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, className, isDesktop && styles.inDesktop)}>
      <PageHeader
        isDesktop={isDesktop}
        title={plugin.$i18n.transl('core.cart.myCart')}
        onHistoryBack={props.onHistoryBack}
        className={!isDesktop && styles.pageHeader}
      />
      <div className={styles.wrapperContent}>
        {
          hasCartItems ?
            <div className={styles.cartContent}>
              <CartPageMobileContent
                refreshCart={props.refreshCart}
                runtimeData={props.runtimeData}
                setRuntimeData={props.setRuntimeData}
                globalPromotionMeta={appState.globalPromotionMeta}
                appliedCoupon={appliedCoupon}
                lineItems={lineItems}
                cartLineItemCount={cartLineItemCount}
                checkoutItemCount={checkoutItemCount}
                totalPrice={totalPrice}
                discountFee={discountFee}
                shippingFee={shippingFee}
                onCartItemQuantityChange={onCartItemQuantityChange}
                onCheckout={onCheckout}
                onClearExpireItems={onClearExpireItems}
                $i18n={plugin.$i18n}
                isDesktop={isDesktop}
                displayDiscountFee={displayDiscountFee}
                displayFreight={displayFreight}
                displayProductFee={displayProductFee}
                renderCartRecommendations={renderCartRecommendations}
                displayTotalFee={displayTotalFee}
                onCartItemSelect={onCartItemSelect}
                totalSaveFee={totalSaveFee}
                displayTotalSaveFee={displayTotalSaveFee}
                onPayWithPaypal={onPayWithPaypal}
                paypalCheckoutInstance={paypalCheckoutInstance}
                onTrack={onTrack}
                showPaypal={showPaypal}
                paypalTotalFee={paypalTotalFee}
                onClickPaypalButton={onClickPaypalButton}
                onClickAddOnCard={handleClickAddOnToYAML}
                addOnsForFreeShippingAmount={addOnsForFreeShippingAmount}
                cartRecommendationRef={cartRecommendationRef}
                onClickCouponEntrance={onClickCouponEntrance}
              >
              </CartPageMobileContent>
            </div> :
            <div>
              <EmptyCartContent $i18n={plugin.$i18n} needLogin={true}>
                {renderEmptyCartRecommendations()}
              </EmptyCartContent>
            </div>
        }
      </div>
    </div>
  )
}

export default withBackToTop(CartPage)