import React, { PureComponent } from 'react'

import { hoistHocStatic, withPlugin } from '@flamingo_tech/funkgo'

import StoreService from '../services/StoreService'
import createStorage from '../utils/cacheUtils'

const withShippingInfo = (Component, staticProps) => {
  const WrappedComponent = class extends PureComponent {
    state = {
      shippingInfo: []
    }

    StoreService = new StoreService(this.props.$http)
    shippingInfoStorage = createStorage('shippingInfo', { strategy: 'SESSION' })

    componentDidMount() {
      this.fetchShippingInfo()
    }

    // 拉取数据
    fetchShippingInfo = () => {
      const shippingInfo = this.shippingInfoStorage.getItem()
      if (shippingInfo) {
        this.setState({
          shippingInfo
        })
        return
      }

      this.StoreService.fetchShippingInfo()
        .then(result => {
          this.setState({
            shippingInfo: result
          })
          this.shippingInfoStorage.setItem(result)
        })
        .catch(err => this.props.$logger.error(`[Product] failed to getShippingInfo, caused by ${err.message}`))
    }

    render() {
      return <Component {...this.props} shippingInfo={this.state.shippingInfo}></Component>
    }
  }

  hoistHocStatic(WrappedComponent, Component, staticProps)
  WrappedComponent.displayName = `withShippingInfo(${Component.displayName || Component.name})`
  return withPlugin(WrappedComponent)
}


export default withShippingInfo
