import React, { useCallback, useContext, useEffect, useState } from 'react'

import { withModal } from '../../components/common/Modal/Modal'
import { PluginContext } from '@flamingo_tech/funkgo'
import { debounce } from 'debounce'

import Image from '../common/Image/Image'
import ProductLink from './ProductLink'
import LikeButton from './LikeButton'
import GirlsShowPlayer from './GirlsShowPlayer'
import CommentCard from './CommentCard'
import SingleColumn from '../common/List/SingleColumn'

import { formatTime } from '../../utils/dateUtils'

import cx from '../../utils/className'
import styles from './GirlsShowDetailModal.module.css'
import PostService from '../../services/PostService'
import withPostLike from '../../hooks/withPostLike'


const EVENT_CATEGORY = 'GirlsShowDetailModal'

const GirlsShowDetail = props => {

  const plugin = useContext(PluginContext)
  const postService = new PostService(plugin.$http)

  const [commentText, setCommentText] = useState('')
  const [playLike, setPlayLike] = useState(false)
  const [likeNum, setLikeNum] = useState(props.likeNum)
  const [hasLiked, setHasLiked] = useState(props.hasLiked)

  const [id, setId] = useState('')
  const [commentInfo, setCommentInfo] = useState({})

  const fetchComment = useCallback(() => {

    if (commentInfo.commentHasNextPage !== false) {
      return postService.fetchNoteComments(props.id, true, commentInfo.commentPageNum || 1 ).then(data => {

        setCommentInfo({
          commentNum: data.count,
          comments: [...commentInfo.comments || [], ...data.items],
          commentHasNextPage: data.nextPage,
          commentPageNum: (commentInfo.commentPageNum || 1) + 1
        })

        return  Promise.resolve()

      }).catch(() => {
        return  Promise.resolve()
      })
    } else {
      return Promise.resolve()
    }

  }, [postService, props.id, commentInfo])


  useEffect(() => {
    if (!id) {
      setId(props.id)
      fetchComment()
    }
  }, [id, props.id, fetchComment])



  const handleCommentChange = ev => {
    const value = ev.target.value
    setCommentText(value)
  }

  const handleSubmitComment = ev => {
    ev.preventDefault()
    const comment = commentText.trim()

    if (comment) {
      submitComment(comment).then(() => {
        setCommentText('')
      })
    }
  }


  const submitComment = debounce((comment) => {
    return new Promise((resolve, reject) => {
      const postId = props.id

      plugin.$user.login().then(() => {

        plugin.$track.event(EVENT_CATEGORY, `comment_publish`, `${postId}_${comment}`)

        postService.submitNoteComment(props.userInfo.id, postId, comment).then(data => {

          setCommentInfo({
            commentNum: commentInfo.commentNum + 1,
            comments: [{ comment: data }, ...commentInfo.comments],
            commentHasNextPage: commentInfo.commentHasNextPage,
            commentPageNum: commentInfo.commentPageNum
          })

          // put the comment at the first place
          plugin.$toastSuccess({
            content: 'Comment Successfully',
            duration: 1500
          })

          resolve()
        })
      }).catch(() => {
        plugin.$track.event(EVENT_CATEGORY, `abandonComment_${postId}`)
        reject()
      })
    })
  }, 1000, true)




  const handleLike = () => {
    setPlayLike(true)
    plugin.$bridge.vibrate({
      soundId: 1519
    })

    if (!hasLiked) {
      onToggleLike(props.id, true)
    }

    setTimeout(() => {
      setPlayLike(false)
    }, 1600)
  }

  const onToggleLike = (postId, like) => {
    return props.$togglePostLike(postId, like).then(res => {
      if (res !== 'fail') {
        setHasLiked(like)
        setLikeNum(like ? likeNum + 1 : likeNum - 1)
      } else {
        return Promise.resolve(res)
      }
    })
  }

  const handleShare = () => {
    props.$track.event(EVENT_CATEGORY, 'share_post', props.id)
    props.$bridge.share()
  }

  const handleUserSectionClick = () => {
    const authorId = props.userInfo.id

    props.$router.navigate({
      name: (props.user && props.user.id === authorId) ? 'GirlsShowMyHomePage': 'GirlsShowPersonalPage',
      params: {
        handle: authorId
      }
    })
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftWrapper}>
        <div
          className={cx(styles.player, playLike && styles.withLikeAnimation, props.mediaType !== 'IMAGE' && styles.videoPlayer)}>
          <GirlsShowPlayer
            poster={props.mainImage}
            items={props.resources}
            mediaType={props.mediaType}
            onDoubleClick={handleLike}
          />
        </div>
      </div>
      <div className={styles.rightWrapper}>
      <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <div className={styles.userSection} onClick={handleUserSectionClick}>
              <Image
                small
                src={props.userInfo.image}
                className={styles.userIcon}
                title={`@${props.userInfo.name}`}
                objectFit="cover"
              />
              <div className={styles.rightContent}>
                <div className={styles.nickname}>{props.userInfo.name}</div>
                <div className={styles.updateTime}>{formatTime(props.updateTime)}</div>
              </div>
            </div>
            {
              !props.hideCloseButton && <div className={styles.closeButton} onClick={props.onClose}>&#xe60e;</div>
            }
          </div>
          {
            props.desc && <div className={styles.content}>{props.desc}</div>
          }
        </div>
        <div className={styles.scrollWrapper}>
          {
            props.linkedProducts && props.linkedProducts.length > 0 &&
            <div className={styles.linkWrapper}>
              {
                props.linkedProducts.map(linkProduct => (
                  <ProductLink {...linkProduct} className={styles.productLink} key={linkProduct.id}/>
                ))
              }
            </div>
          }
          {
            commentInfo.comments && commentInfo.comments.length > 0 &&
            <div className={styles.commentSection}>
              <div className={styles.header}>{plugin.$i18n.transl('core.girlsShow.comments', { count: commentInfo.commentNum })}</div>
              <SingleColumn
                className={styles.commentList}
                childIs={CommentCard}
                childItems={commentInfo.comments}
                childOptions={{
                  className: styles.commentCard
                }}
                onReachEnd={fetchComment}
              ></SingleColumn>
            </div>
          }
        </div>

        <div className={styles.fixedBottomBar}>
          <form className={styles.messageForm} onSubmit={handleSubmitComment}>
            <input
              type="text"
              placeholder={plugin.$i18n.transl('core.girlsShow.writeAMessage')}
              value={commentText}
              onChange={handleCommentChange}
              ></input>
          </form>
          <div className={styles.rightButtonWrapper}>
            <LikeButton
              className={styles.likeButton}
              likeNum={likeNum}
              hasLiked={hasLiked}
              onClick={isLiked => onToggleLike(props.id, isLiked)}
            />
            <div
              className={styles.commentButton}
            >{props.commentNum}</div>
            <div className={styles.share} onClick={handleShare}/>
          </div>
        </div>
      </div>
    </div>
  )
}

const GirlsShowDetailContent = withPostLike(GirlsShowDetail, { eventCategory: EVENT_CATEGORY })

export const GirlsShowDetailModal = withModal(GirlsShowDetailContent, { position: 'center', showCloseButton: false })

export {
  GirlsShowDetailContent
}

