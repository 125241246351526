export const findFromArray = (array, is) => {
  let result = null

  array.every(item => {
    if (is(item)) {
      result = item
      return false
    }
    return true
  })

  return result
}

export const findIndexFromArray = (array, is) => {
  const item = findFromArray(array, is)
  return item ? array.indexOf(item) : -1
}

export const shuffleArray = array => {
  const arr = [...array]
  let i = arr.length;
  while (i) {
    let j = Math.floor(Math.random() * i--);
    [arr[j], arr[i]] = [arr[i], arr[j]];
  }
  return arr
}

export const filterUniqueListFromArray = (array, getKey) => {
  let set = new Set()

  return array.filter((item) => !set.has(getKey(item)) && set.add(getKey(item)))
}

// 在一个list中通过key得到obj或者obj对应的index
export const getValByList = (data, keyVal, type = 'index', key = 'key') => {
  if (type === 'index') {
      let index = data.findIndex((v) => v[key] === keyVal)
      return index !== -1 ? index : false
  } else {
      let findObj = data.find((v) => v[key] === keyVal)
      return findObj ? findObj : false
  }
}