import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import withLoginCheck from '../../../hooks/withLoginCheck'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'

import styles from './EmptyCartContent.module.css'
import cx from '../../../utils/className'
import Button from '../../common/Button/Button'

export const EmptyContentBox = (props) => {

  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const hasLogin = plugin.$user.hasLogin()

  const toHome = () => {
    plugin.$track.event(props.trackCategory, props.trackAction)
    plugin.$router.navigateToHomePage()
  }
  const WithCheckSync = withLoginCheck(params => params.render(), {}, cx(styles.signInOrRegister, isDesktop && styles.isDesktop))
  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop, props.className)}>
      <div className={cx(styles.emptyCartPlay, isDesktop && styles.isDesktop)}>
        <div className={cx(styles.icon, props.iconClass ? props.iconClass : null)}></div>
        {props.emptyContentTitle && <div className={cx(styles.title)}>{props.emptyContentTitle}</div>}     
        {props.emptyContentDesc && ((props.needLogin && !hasLogin)) && (
            <div className={cx(styles.desc,isDesktop && styles.isDesktop)}>
              {props.emptyContentDesc}
            </div>
        )}
        {props.needLogin && !hasLogin && (
          <WithCheckSync
            render={() => <div>{props.emptyContentPrimaryButtonText}</div>}
          />
        )}
        {!props.onlyIcon && (
          <Button className={cx(styles.shopBtn, props.needLogin && !hasLogin ? styles.unLoginPrimary : styles.primary,
            )}
            onClick={toHome}
          >
            {props.emptyContentButtonText}
          </Button>
        )}
      </div>

      {
        !props.onlyIcon && (
          <div className={cx(!isDesktop ? styles.childrenWrapper : '', props.childrenWrapperClass ? props.childrenWrapperClass : null)}>
            {props.children}
          </div>
        )
      }
    </div>
  )
}

const EmptyCartContent = (props) => {
  return (
    <EmptyContentBox 
      {...props}
      trackCategory="Cart"
      trackAction="Cart_back_home"
      iconClass={styles.iconCartClass}
      emptyContentTitle={props.$i18n.transl("core.cart.emptyCart")}
      emptyContentDesc={props.$i18n.transl("core.cart.emptyCartDesc")}
      emptyContentPrimaryButtonText={props.$i18n.transl("core.login.signInAndRegister")}
      emptyContentButtonText={props.$i18n.transl("common.shopNow")}
    />
  )
}

export default withCartAndPlugins(EmptyCartContent)