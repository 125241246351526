import React, { PureComponent } from 'react'
import VideoPlayer from './VideoPlayer'
import styles from './VideoBanner.module.css'

class VideoBanner extends PureComponent {

  render() {
    const props = this.props
    const item = props && props.items && props.items[0]
    const isCard = item && item.isCard
    return (
      <div className={isCard && styles.cardWrapper}>
        <VideoPlayer src={item.src} poster={item.poster} loop={item.loop} muted playsInline></VideoPlayer>
      </div>
    )
  }
}

export default VideoBanner