import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import Link from '../../common/Link/Link'
import { activeLink, navigationTabs } from '../../common/BreadCrumbs/BreadCrumbs'
import { LoginCheckWrapper } from '../../../hooks/withLoginCheck'

import styles from './UserCenterNavigation.module.css'
import cx from '../../../utils/className'

const UserCenterNavigation = props => {
  const [openedAccordionKeys, setOpenedAccordionKeys] = useState(['Orders', 'Policy'])

  const handleAccordionChange = params => {
    setOpenedAccordionKeys(params)
  }

  const renderLink = tab => {
    return tab.link
  }

  const handleLogOut = () => {
    props.$confirm({
      title: props.$i18n.transl('core.login.areYouSureToLogOut'),
      confirmLabel: props.$i18n.transl('common.yes'),
    }).then(yes => {
      if (yes) {
        props.$track.event('Setting', 'logout')

        props.$user.logout().then(() => {
          props.$router.navigateToHomePage()
        }).catch(err => {
          props.$toastError(err.message)
        })
      }
    })
  }

  const renderHeadingItem = accordionItem => {
    if (accordionItem.link) {
      return (
        <AccordionItemButton>
          <Link
            block
            to={renderLink(accordionItem)}
            className={cx(styles.accordionButton, styles[accordionItem.key])}
          >
            <div className={styles.icon}>{accordionItem.icon}</div>
            {props.$i18n.transl(accordionItem.title)}
          </Link>
        </AccordionItemButton>
      )
    }

    return (
      <AccordionItemButton className={cx(styles.accordionButton, openedAccordionKeys.indexOf(accordionItem.key) > -1 && styles.open, styles[accordionItem.key])}>
        <div className={styles.icon}>{accordionItem.icon}</div>
        {props.$i18n.transl(accordionItem.title)}
      </AccordionItemButton>
    )
  }

  const renderPanelItem = tab => {
    return (
      <div key={tab.key} className={cx(styles.accordionPanelItem, activeLink(tab, props.$router) && styles.selected)}>
        <Link
          block
          to={renderLink(tab)}
        >
          {props.$i18n.transl(tab.title)}
        </Link>
      </div>
    )
  }

  const wrapperCallback = tab => {
    if (tab.link) {
      return () => props.$router.navigate(tab.link)
    }

    return () => { }
  }

  const hasLogin = props.$user.hasLogin()

  return (
    <>
      <Accordion onChange={handleAccordionChange} allowZeroExpanded preExpanded={openedAccordionKeys} allowMultipleExpanded>
        {
          navigationTabs.map(accordionItem => {
            return (
              <AccordionItem key={accordionItem.key} uuid={accordionItem.key}>
                <AccordionItemHeading>
                  <AccordionItemButton className={cx(styles.accordionButton, openedAccordionKeys.indexOf(accordionItem.key) > -1 && styles.open, styles[accordionItem.key])}>
                    {
                      accordionItem.needLogin
                        ? <LoginCheckWrapper loginCallback={wrapperCallback(accordionItem)}>{renderHeadingItem(accordionItem)}</LoginCheckWrapper>
                        : renderHeadingItem(accordionItem)
                    }
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.accordionPanel}>
                  {
                    accordionItem.items && accordionItem.items.map(tab => {
                      if (tab.needLogin) {
                        return (
                          <LoginCheckWrapper key={tab.key} loginCallback={wrapperCallback(tab)}>
                            {renderPanelItem(tab)}
                          </LoginCheckWrapper>
                        )
                      }
                      return renderPanelItem(tab)
                    })
                  }
                </AccordionItemPanel>
              </AccordionItem>
            )
          })
        }
        {
          hasLogin && (
            <div className={cx(styles.accordionButton, styles.logOut)} onClick={handleLogOut}>
              <div className={styles.icon}>&#xe64d;</div>
              {props.$i18n.transl('core.login.signOut')}
            </div>
          )
        }
      </Accordion>
    </>
  )
}


export default withPlugin(UserCenterNavigation)