import React from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import Image from '../common/Image/Image'

import styles from './CartPageSkeleton.module.css'

/* ------------------------- */

const CollectionSkeleton = props => {
  const isDesktop = props.$detector.isDesktop()

  if (isDesktop) {
    return (
      <div className={styles.pc}>
        <div className={styles.header}></div>
        <div className={styles.steps}>
          <div className={styles.circle}></div>
          <div className={styles.text}></div>
          <div className={styles.circle}></div>
          <div className={styles.text}></div>
          <div className={styles.circle}></div>
          <div className={styles.text}></div>
        </div>
        <div className={styles.content}>
          <div className={styles.leftContent}>
            <div className={styles.title}></div>
            {
              [1, 2].map(item => (
                <div key={item} className={styles.cartItem}>
                  <div><div className={styles.checkIcon}></div></div>
                  <div className={styles.productCard}>
                    <div className={styles.imageBox}>
                      <Image crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }} />
                    </div>
                    <div className={styles.shortItem}></div>
                    <div className={styles.shortItem}></div>
                    <div className={styles.shortItem}></div>
                    <div className={styles.shortItem}></div>
                    <div className={styles.shortItem}></div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className={styles.rightContent}>
            <div className={styles.top}>
              <div className={styles.title}></div>
              <div className={styles.priceCounts}>
                <div>
                  <div className={styles.longItem}></div>
                  <div className={styles.shortItem}></div>
                </div>
                <div>
                  <div className={styles.shortItem}></div>
                  <div className={styles.shortItem}></div>
                </div>
                <div>
                  <div className={styles.shortItem}></div>
                  <div className={styles.shortItem}></div>
                </div>
              </div>
              <div className={styles.btn}></div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.title}></div>
              <div className={styles.desc}></div>
              <div className={styles.desc}></div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.mobile}>
      <div className={styles.header}>
        <div className={styles.title}>{props.$i18n.transl('core.cart.myCart')}</div>
      </div>
      <div className={styles.imageGroup}>
        <div className={styles.items}></div>
        {
          [1, 2].map(i => {
            return (
              <div key={i} className={styles.cartItem}>
                <div className={styles.itemCheck}></div>
                <div className={styles.imageBox}>
                  <Image crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }} />
                </div>
                <div className={styles.productTitle}></div>
                <div className={styles.productTitle2}></div>
                <div className={styles.adds}></div>
                <div className={styles.productOption}></div>
              </div>
            )
          })
        }
      </div>
      <div className={styles.priceCounts}>
        <div>
          <div className={styles.longItem}></div>
          <div className={styles.shortItem}></div>
        </div>
        <div>
          <div className={styles.shortItem}></div>
          <div className={styles.shortItem}></div>
        </div>
        <div>
          <div className={styles.shortItem}></div>
          <div className={styles.shortItem}></div>
        </div>
      </div>
      <div className={styles.priceCounts}>
        <div className={styles.longItem}></div>
        <div className={styles.shortItem}></div>
      </div>
      <div className={styles.others}></div>
      <div className={styles.btnFix}>
        <div className={styles.all}></div>
        <div className={styles.total}></div>
        <div className={styles.btn}></div>
      </div>
    </div>
  )
}

export default withPlugin(CollectionSkeleton)
