import React, { useContext, useState } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import Button from '../../common/Button/Button'

import {
  getPromotionDiscountText,
  getPromotionExpiryDate,
  getPromotionTarget
} from '../../../utils/Store/promotionUtils'

import CouponModel from '../../../plugins/StorePlugin/models/CouponModel'

import styles from './CouponCard.module.css'
import cx from '../../../utils/className'

const CouponCard = props => {
  const plugin = useContext(PluginContext)
  const showCouponTaken = props.showCouponTaken !== undefined ? props.showCouponTaken : true
  // there is no $store plugin on server side, so handle it
  const [couponTaken, setCouponTaken] = useState(
    plugin.$store && Boolean(plugin.$store.couponHub.getAvailableCoupon(props.id))
  )
  const isDesktop = plugin.$detector.isDesktop()

  if (props.redirectAfterTaken && !props.link) {
    throw new Error(`[CouponImage] redirect link is empty`)
  }

  const handleCouponClick = ev => {
    ev.preventDefault()
    // for coupon choose components use
    if (props.plainCoupon) {
      return props.onClick()
    }
    if (!couponTaken) {
      plugin.$toastSuccess({
        content: plugin.$i18n.transl('core.promotion.takenCouponSuccess'),
        duration: 1500
      })

      plugin.$store.couponHub.take(props)
      setCouponTaken(true)

      setTimeout(() => {
        props.redirectAfterTaken && plugin.$router.navigate(props.link)
      }, 1500)
    } else {
      if (props.showUseButton) {
        plugin.$router.navigate(props.link)
      }
      props.redirectAfterTaken && plugin.$router.navigate(props.link)
    }
  }

  const renderCouponSection = coupon => {
    return (
      <div className={styles.titleSection}>
        <div className={styles.couponTarget}>{coupon.title}</div>
      </div>
    )
  }

  const couponData = new CouponModel.Meta(props)

  const couponElement = props.image
    ? (
      <Image
        src={couponTaken ? (props.couponTakenImage || props.image) : props.image}
      />
    )
    : (
      <div className={styles.couponWrapper}>
        {
          props.selected && (
            <div>
              <div className={styles.bg}></div>
              <span className={styles.checkIcon}>&#xe650;</span>
            </div>
          )
        }
        <div className={styles.leftWrapper}>
          <div className={styles.discountText}>{getPromotionDiscountText(couponData, plugin.$i18n, plugin.$site)}</div>
        </div>
        <div className={styles.rightWrapper}>
          {
            renderCouponSection(couponData)
          }
          <ul className={styles.desc}>
            {
              couponData.targetType === 'ALL'
                ? <li>{plugin.$i18n.transl('core.promotion.allProducts')}</li>
                : <li>{getPromotionTarget(couponData)}</li>
            }
            <li>{getPromotionExpiryDate(couponData)}</li>
          </ul>
          {
            !props.plainCoupon // for coupon choose components use
            &&
            <>
              {
                (!couponTaken || props.showUseButton) &&
                <Button className={styles.btn} onClick={() => null}>{couponTaken ? 'TO USE' : 'GET'}</Button>
              }
              {
                showCouponTaken && couponTaken &&
                <div className={styles.couponTakenIcon}></div>
              }
            </>
          }
        </div>
      </div>
    )

  const renderCouponCardElement = render => {
    if (typeof render === 'function') {
      return render(couponData)
    }

    return couponElement
  }

  if (props.link) {
    return (
      <Link className={cx(styles.wrapper, isDesktop && styles.isDesktop, props.className)} to={props.link} onClick={handleCouponClick}>
        {renderCouponCardElement(props.renderCouponCard)}
      </Link>
    )
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop, props.className)} onClick={handleCouponClick}>
      {renderCouponCardElement(props.renderCouponCard)}
    </div>
  )
}

export default CouponCard
