import React from 'react'

import ProductCard from './ProductCard'

import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'

const CollectionProductCard = props => {

  return (
    <ImpressionObserver eventParams={{ index: props.index, handle: props.handle }} eventName='product_feed'  eventId={props.id}>
      <ProductCard {...props} showFlashDiscount={true} ></ProductCard>
    </ImpressionObserver>

  )
}

export default CollectionProductCard