import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import ExpandableTab from './ExpandableTab'
import { PluginContext } from '@flamingo_tech/funkgo'
import { getDefaultTab, getDefaultTabKey } from './utils/tabUtils'

/* --------------------------------------- */
const EVENT_CATEGORY = 'TabPanel'

const TabPanel = props => {
  const plugin = useContext(PluginContext)

  const [currentTab, setCurrentTab] = useState(getDefaultTab({
    ...props,
    location: plugin.$router.location
  }))

  const TabComponent = props.Tab || ExpandableTab

  const trackTabClick = useCallback((tab) => {
    const trackTabName = props.trackType === 'key' ? tab.key : tab.title

    plugin.$track.event(props.eventCategory || EVENT_CATEGORY, `clickTab_${trackTabName}`)
  }, [props.trackType, props.eventCategory, plugin.$track])

  const tabIndicatorRef = useRef()
  const tabRef = useRef()

  const handleTabChange = tab => {
    setCurrentTab(tab)

    if (typeof props.onTabChange === 'function') {
      props.onTabChange(tab)
    }

    // always scroll to top
    if (tabRef.current && tabIndicatorRef.current) {
      const { top } = tabRef.current.getBoundingClientRect()
      const indicatorOffset = tabIndicatorRef.current.offsetTop

      document.documentElement.scrollTop = indicatorOffset - top
    }
  }

  useEffect(() => {
    if (currentTab) {
      trackTabClick(currentTab, true)
    }
  }, [currentTab, trackTabClick])

  let body

  if (typeof props.renderPanel === 'function') {
    body = props.renderPanel(currentTab)
  } else {
    body = props.children
  }

  return (
    <>
      <div ref={tabIndicatorRef}></div>
      <TabComponent
        ref={tabRef}
        className={props.className}
        containerClassName={props.containerClassName}
        onTabChange={(currentTab) => {
          handleTabChange(currentTab)
        }}
        currentTab={currentTab}
        tabs={props.tabs}
        tabParamName={props.tabParamName}
        withStickyHeader={props.withStickyHeader}
        needTabKeyToHash={props.needTabKeyToHash}
        withAppHeader={props.withAppHeader}
      ></TabComponent>

      {body}

    </>
  )
}

export {
  getDefaultTab,
  getDefaultTabKey,
}

export default TabPanel