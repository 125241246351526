import { ESProductFeed } from './ProductModel.js'

class PageInfo {
  constructor(data) {
    this.hasNextPage = data.hasNextPage
  }
}

class ProductQueue {
  constructor({ props, docs, total, extraCursor }) {
    this.title = props.title
    this.description = props.description
    this.handle = props.handle
    this.count = total
    this.pageInfo = new PageInfo(props)
    this.extraCursor = extraCursor

    this.products = docs.map(product => ({
      product: new ESProductFeed(product)
    }))
    this.hasNextPage = props.hasNextPage
  }

  unshiftProducts(products) {
    products.forEach(item => {
      if (item && item.cursor && item.product) {
        this.products.unshift(item)
      } else if (item) {
        this.products.unshift({
          cursor: null,
          product: item
        })
      }
    })

    return this
  }
}

export default ProductQueue
