import React, { forwardRef, useRef } from 'react'
import styles from './AddToCartIcon.module.css'
import cx from '../../../utils/className'

export const AddToCartBtn = forwardRef((props, ref) => {
  const atcIconRef = useRef()

  const currentRef = ref || atcIconRef

  return (
    <div ref={currentRef} className={cx(styles.AddToCartBtn, props.className)} onClick={() => props.handleClick && props.handleClick({
      triggerRef: currentRef
    })}>
      <span className={cx(styles.AddToCartIcon, props.iconClassName)}></span>
    </div>
  )
})