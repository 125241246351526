import React from 'react'

import Link from '../../common/Link/Link'
import { withPlugin } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import styles from './CategorySegment.module.css'

const SOURCE_LOCATOR = 'Category'

const CategorySegment = props => {

  const items = props.items
  const isDesktop = props.$detector.isDesktop()

  const handleClickCategory = (e, item) => {
    props.parentCategoryTitle && props.$track.clickMainResourceLocator(`${SOURCE_LOCATOR}:${props.parentCategoryTitle}_${item.key ? item.title : props.title}`)
    props.handleHoverMask && props.handleHoverMask()
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
        {
          props.title && props.link
          ?
          <Link
              to={props.link}
              key={props.key}
              onClick={(e) => { handleClickCategory(e, { link: props.link, title: props.title, route: props.route }) }}
          >
            <div className={cx(styles.levelTwoTitle, isDesktop ? styles.inDesktop : styles.mobileLink, props.highlight && styles.highlight)}>{props.title}</div>
          </Link>
          :
          <div className={cx(styles.levelTwoTitle, isDesktop && styles.inDesktop, props.highlight && styles.highlight)}>{props.title}</div>
        }
      {
        isDesktop
          ? items && items.map(child => (
            <Link
              to={child.link}
              key={child.title}
              className={cx(styles.childItem, !isDesktop && styles.mobileLink)}
              onClick={(e) => { handleClickCategory(e, child) }}
            >
              <div className={cx(styles.childTitle, isDesktop && styles.inDesktop, child.highlight && styles.highlight)}>
                {!isDesktop && <img alt="icon" className={styles.itemIcon} src={child.icon}></img>}
                {child.title}
              </div>
            </Link>
          ))
          : items && !props.link && (
            <div className={styles.imgItems}>
              {
                items.map(child => {
                  return (
                    <Link to={child.link} onClick={(e) => { handleClickCategory(e, child) }} key={child.title} className={styles.imgItem}>
                      {!isDesktop && <img alt="icon" className={styles.itemIcon} src={child.icon}></img>}
                      <span>{child.title}</span>
                    </Link>
                  )
                })
              }
            </div>
          )
      }
    </div>
  )
}

export default withPlugin(CategorySegment)