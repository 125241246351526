import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import StaticModalContainer from '../../../containers/Common/StaticModalContainer'


const EventsModal = props => {
  const { children } = props

  const [modal, setModal] = useState({
    hide: true,
    handle: null,
    onClose: () => { }
  })

  const getInitialStaticModalProps = (handle) => {
    const baseProps = {
      hide: false,
      keepAlive: true,
      $http: props.$http,
      handle: handle
    }

    return {
      ...baseProps,
      onClose: () => {
        setModal({
          hide: true,
          handle: null,
          onClose: () => { }
        })
      }
    }
  }

  const handleShowPrivacyModal = () => {
    setModal(getInitialStaticModalProps(props.handle))
  }

  return (
    <>
      <span className={props.className} onClick={handleShowPrivacyModal}>{children}</span>
      {
        !modal.hide && (
          <StaticModalContainer
          {...modal}
        ></StaticModalContainer>
        )
      }
    </>
  )
}


export default withPlugin(EventsModal)