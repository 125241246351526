import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import CouponCardBox from './CouponCardBox'
import Countdown from '../../Widget/Countdown/Countdown'
import SelectIcon from '../CheckOut/SelectIcon'

import { formatTime } from '../../../utils/dateUtils'

import styles from './DefaultCouponCard.module.css'
import cx from '../../../utils/className'

const MODE = {
  EDIT: 'edit',
  VIEW: 'view'
}

const ENABLE_MODE = {
  ENABLE: 'enable',
  DISABLE: 'disable'
}

const DefaultCouponCard = props => {
  const plugin = useContext(PluginContext)

  const mode = props.mode || MODE.VIEW
  const enableMode = props.enableMode || ENABLE_MODE.ENABLE

  const  { id, name, subtitle, maxDiscountAmount, discountInfo, displayScope, startDate, endDate } = props

  const timeDiff = endDate - new Date()

  const renderCouponTopContent = () => {
    return (
      <div className={styles.topWrapper}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            {name}
            {
              mode === MODE.VIEW && props.selected &&
              <span className={styles.thumb} />
            }
          </div>
          <div className={styles.countdownBar}>
            {
              timeDiff <= 24 * 60 * 60 * 1000 &&
              <>
                <span className={styles.expiresIn}>{plugin.$i18n.transl('core.checkout.expiresIn')}</span>
                <Countdown
                cardClassName={styles.cardClassName}
                separatorClassName={styles.separatorClassName}
                timestamp={timeDiff} />
              </>
            }

          </div>
        </div>
        <div className={styles.ruleWrapper}>
          <div className={styles.content}>
            <div className={styles.rule}>{subtitle}</div>
            {
              maxDiscountAmount && <div className={styles.discountLimitRule}>{maxDiscountAmount}</div>
            }
            {
              discountInfo && discountInfo.fit && <div className={styles.saveFee}>{plugin.$i18n.transl('core.checkout.couponRequirementsMet')} {plugin.$i18n.transl('common.save')} <span className={styles.highlighted}>{discountInfo.displayRealDiscountAmt}</span></div>
            }
          </div>
          {
            mode === MODE.EDIT && <SelectIcon onClick={props.onClick} selected={props.selected} type={SelectIcon.TYPE.CHECKBOX} className={styles.selectIcon} disabled={enableMode === ENABLE_MODE.DISABLE}/>
          }
        </div>
      </div>
    )

  }

  const renderCouponBottomContent = () => {
    const { country } = plugin.$site.getSiteInfo()
    const timeFormat = country === 'US' ? 'MM/DD/YYYY HH:mm' : 'YYYY/MM/DD HH:mm'

    return (
      <div className={styles.bottomWrapper}>
        <div className={styles.date}>- {formatTime(startDate, timeFormat)} ~ {formatTime(endDate, timeFormat)}</div>
        <div className={styles.scope}>- {displayScope}</div>
      </div>
    )
  }

  return (
      <CouponCardBox
        key={id}
        renderTopContent={renderCouponTopContent}
        renderBottomContent={renderCouponBottomContent}
        className={cx(styles.wrapper, enableMode === ENABLE_MODE.DISABLE && styles.disabled, props.className)}
        backgroundColor="#FEF6F3"
        borderColor="rgba(255,155,117,.5)"
        borderRadius="6"
      />
  )
}

DefaultCouponCard.MODE = MODE
DefaultCouponCard.ENABLE_MODE = ENABLE_MODE

export default DefaultCouponCard