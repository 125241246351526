import React, { useState, useEffect, useRef, useContext } from 'react'
import { debounce } from 'debounce'

import AppStateContext from './AppStateContext'
import AppChannelCard from '../components/Store/AppDownload/AppChannelCard'

import styles from './withBackWrap.module.css'

const SCROLL_DISTANCE = 1000

const withBackWrap = Component => {

  const WrappedComponent = props => {
    const [showButton, setShowButton] = useState(false)
    const [showAppInfo, setShowAppInfo] = useState(false)
    const isMounted = useRef(true)

    const isBrowser = typeof window !== 'undefined'

    const appState = useContext(AppStateContext)
    const { globalSettingMeta } = appState
    const desktopDownloadApp = (globalSettingMeta || {})['desktopDownloadApp'] || {}
    const { qrCodeImage, priceOff } = desktopDownloadApp

    const handleClick = ev => {
      if (isBrowser) {
        window.scrollTo(0, 2) // if move to top=0, user may easy to close window at instagram webview
        setShowButton(false) // hide button instantly
        showAppInfo && setShowAppInfo(false)
      }
    }

    useEffect(() => {
      isMounted.current = true

      const scrollHandler = debounce(() => {
        if (isMounted.current) {
          const { documentElement = {}, body = {} } = document
          const scrollTop = window.pageYOffset || (documentElement && documentElement.scrollTop) || (body && body.scrollTop)
          if (scrollTop > SCROLL_DISTANCE) {
            !showButton && setShowButton(true)
          } else {
            showButton && setShowButton(false)
          }
        }
      }, 100)

      if (isBrowser) {
        window.addEventListener('scroll', scrollHandler)
      }

      return () => {
        isMounted.current = false

        if (isBrowser) {
          window.removeEventListener('scroll', scrollHandler)
        }
      }
    }, [isBrowser, showButton])


    return (
      <div className={styles.wrapper}>
        <Component {...props}></Component>
        {
          showButton && (
            <div>
              <div
                className={styles.downAppBtn}
                onMouseEnter={()=> { setShowAppInfo(true) }}
              ></div>
              <div className={styles.backToTopBtn} onClick={handleClick}></div>
            </div>
          )
        }
        {
          showAppInfo && (
            <AppChannelCard
              appQrCode={qrCodeImage}
              priceOff={priceOff}
              isToolTip={true}
              handleMouseLeave={ () => { setShowAppInfo(false) } }
            ></AppChannelCard>
          )
        }
      </div>
    )
  }

  WrappedComponent.displayName = `withBackWrap(${Component.displayName || Component.name})`

  return WrappedComponent
}

export default withBackWrap
