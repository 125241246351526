import React, { PureComponent } from 'react'

import InView from '../InView/InView'

import cx from '../../../utils/className'

import styles from './DualColumns.module.css'

class DualColumns extends PureComponent {
  handleReachEnd = inView => {
    if (inView) {
      if (typeof this.props.onReachEnd === 'function') {
        this.props.onReachEnd()
      }
    }
  }

  /* --------------------------------------- */

  renderElements() {
    const { childIs, childItems, childOptions } = this.props

    const ChildComponent = childIs

    const isNeedTriggerReachEnd = i => i === childItems.length - 1

    if (childItems) {
      const elements = childItems.map((item, i) => {
        const element = (
          <ChildComponent
            key={i}
            {...item}
            {...childOptions}
          ></ChildComponent>
        )

        if (isNeedTriggerReachEnd(i)) {
          return (
            <InView key={i} onChange={this.handleReachEnd} triggerOnce={true}>
              {element}
            </InView>
          )
        }

        return element
      })

      return elements
    } else {
      return []
    }
  }

  componentDidMount() {
    const noChild = !this.props.childItems || !this.props.childItems.length
    if (noChild && typeof this.props.onReachEnd === 'function') {
      this.props.onReachEnd()
    }
  }

  render() {
    const elements = this.renderElements()

    const leftElements = elements.filter((o, i) => i % 2 === 0)
    const rightElements = elements.filter((o, i) => i % 2 === 1)

    return (
      <div className={cx(styles.list, this.props.className)}>
        <div className={styles.column}>{leftElements}</div>
        <div className={styles.column}>{rightElements}</div>
      </div>
    )
  }
}

export default DualColumns