import React, { useState, useEffect } from 'react'

import queryString from 'query-string'

import AppIndexPage, { getDefaultTab, FOOTER_HEIGHT } from '../../components/Shell/Page/AppIndexPage'

import StoreHomeContainer, { PlainStoreHomeContainer } from '../Store/StoreHomeContainer'
import { PlainProductCategoryContainer } from '../Store/ProductCategoryContainer'
import { PlainGirlsShowHomeContainer } from '../GirlsShow/GirlsShowHomeContainer'
import { PlainProfileContainer } from '../User/ProfileContainer'
import NewContainer from '../Store/NewContainer'

import withCartAndPlugins from '../../hooks/withCartAndPlugins'
import { usePageBackObserver } from '../../hooks/withPageBackObserver'

import GuidePageFloatWidgetContainer from '../Shell/GlobalWidgets/GuidePageFloatWidgetContainer'
import AppUpdateModal from '../../components/Widget/AppUpdateModal/AppUpdateModal'

import { getCartLineItemCount } from '../../utils/cartUtils'
import createStorage from '../../utils/cacheUtils'

/* ------------------------------------- */

const TAB_KEY = {
  STORE_HOME: 'Home',
  CATEGORY: 'Category',
  NEW: 'New',
  GIRLS: 'Girls',
  MY: 'My',
}

const TABS = [{
  key: TAB_KEY.STORE_HOME,
  image: '\ue625',
  link: 'https://m.flamingo.shop/store/'
}, {
  key: TAB_KEY.CATEGORY,
  image: '\ue624',
  link: 'https://m.flamingo.shop/store/category'
}, {
  key: TAB_KEY.NEW,
  image: '\ue626',
  link: 'https://m.flamingo.shop/store/new'
}, {
  key: TAB_KEY.GIRLS,
  image: '\ue629',
  link: 'https://m.flamingo.shop/store/girls_show'
}, {
  key: TAB_KEY.MY,
  image: '\ue623',
  link: 'https://m.flamingo.shop/store/user'
}]

const TAB_PARAM_NAME = 'landingPage'

/* ------------------------------------- */

const PageComponentMap = {
  [TAB_KEY.STORE_HOME]: PlainStoreHomeContainer,
  [TAB_KEY.CATEGORY]: PlainProductCategoryContainer,
  [TAB_KEY.NEW]: NewContainer,
  [TAB_KEY.GIRLS]: PlainGirlsShowHomeContainer,
  [TAB_KEY.MY]: PlainProfileContainer,
}

/* ------------------------------------- */

const AppEntryContainer = props => {
  const {
    data,
    cart,
    $detector,
    $router,
    $store,
    $user,
    $logger,
    $track,
    $site,
    $storage,
    $bridge
  } = props

  /* -------------------------------------------- */

  const isApp = $detector.isApp()
  const isStandaloneApp = $detector.isStandaloneApp()
  const isUseAppHomeApp = $detector.isUseAppHomeApp()
  const isNeedToEnableOpenNewPage = isApp && isStandaloneApp
  const isNativeApp = $detector.compareAppVersion({ targetVersion: '6.2.0' })

  useEffect(() => {
    // if is standalone app, and is app, then set all navigation to open new page
    //  p.s. pwa will also be standalone app, but it do not need to open new page
    if (isNeedToEnableOpenNewPage) {
      $router.forceSwitchToOpenNewPage()
    }
  }, [isNeedToEnableOpenNewPage, $router])

  /* -------------------------------------------- */

  useEffect(() => {
    if ($detector.isApp()) {
      const landingPageNaviStorage = createStorage('lpn', { strategy: 'SESSION' })

      const { landingPage, landingPageHandle, landingPageSearch } = queryString.parse($router.location.search)

      // landing page navigation
      if (landingPage && Object.values(TAB_KEY).indexOf(landingPage) <= -1 && !landingPageNaviStorage.getItem(false)) {

        landingPageNaviStorage.setItem(true)

        $router.navigate({
          name: landingPage,
          params: {
            handle: landingPageHandle
          },
          search: landingPageSearch
        })
      }
    }
  }, [$detector, $router])

  /* -------------------------------------------- */

  const pageBackTime = usePageBackObserver()
  useEffect(() => {

    if (pageBackTime) {

      $site.refresh()

      $user.refresh().catch(
        err => $logger.error(err, 'pageBack', 'AppEntryContainer')
      )

    }
  }, [pageBackTime, isUseAppHomeApp, $store, $user, $logger, $site, $bridge, $storage, $router])

  /* -------------------------------------------- */

  const [currentTab, setCurrentTab] = useState(getDefaultTab({
    tabs: TABS,
    tabParamName: TAB_PARAM_NAME,
    location: $router.location,
  }))

  const currentTabKey = currentTab.key
  const CurrentPanel = PageComponentMap[currentTabKey]

  const handleTabChange = tab => {
    setCurrentTab(tab)
    $track.event('HomeTabPanel', `clickTab_${tab.key}`)
  }

  /* -------------------------------------------- */
  const cartCount = (cart && getCartLineItemCount(cart) !== 0) ? getCartLineItemCount(cart): undefined

  const tabsRibbon = {
    [TAB_KEY.CART]: cartCount
  }

  const panelProps = currentTabKey === TAB_KEY.STORE_HOME
    ? { data: data }
    : {}

  /* -------------------------------------------- */

  return (
    <>
      <AppIndexPage
        tabs={TABS}
        tabParamName={TAB_PARAM_NAME}
        tabsRibbon={tabsRibbon}
        currentTab={currentTab}
        isUseAppHomeApp={isUseAppHomeApp}
        onTabChange={handleTabChange}
      >
        <CurrentPanel
          {...panelProps}
        ></CurrentPanel>
      </AppIndexPage>
      {
        currentTabKey === TAB_KEY.STORE_HOME && (
          <GuidePageFloatWidgetContainer bottomHeight={FOOTER_HEIGHT}/>
        )
      }
      {
        !isNativeApp && 
        <AppUpdateModal
          onClose={() => {}}
          hide={false}
          withoutClose={true}
        ></AppUpdateModal>
      }
    </>
  )
}

AppEntryContainer.fetchPageData = StoreHomeContainer.fetchPageData
AppEntryContainer.fetchOpenGraphInfo = StoreHomeContainer.fetchOpenGraphInfo

export default withCartAndPlugins(AppEntryContainer, { withClient: false })
