import {
  getNextPageKey,
  fetchProducts,
  uniqueProducts,
  extractProducts
} from './productListUtils'

const ADAPTER = {
  getNextPageKey,
  fetchProducts,
  uniqueProducts,
  extractProducts
}

const callAdapter = (props, name, args) => {
  if (!ADAPTER[name]) {
    throw new Error(`[ProductList] adapter ${name} is not available`)
  }

  if (typeof props[name] === 'function') {
    return props[name](...args)
  }

  return ADAPTER[name](...args)
}

export default callAdapter