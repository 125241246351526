import React from 'react'

import HorizontalImageText from '../../common/ImageText/HorizontalImageText'
import CategorySegmentColumn from './CategorySegmentsColumn'

const staticComponents = {
  HorizontalImageText,
  CategorySegmentColumn
}

const adaptOptions = (options = {}) => {
  if (options.children) { // fallback for legacy options
    options.childItems = options.children
    delete options.children
  }

  return options
}


const adaptStaticComponentProps = options => {
  const props = adaptOptions(options)

  if (props.childOptions) {
    props.childOptions = adaptStaticComponentProps(props.childOptions)
  }

  return props
}

const CategoryDetailSection = _props => {
  const { layout, title } = _props
  const renders = layout.map(element => {
    let Component, props
    Component = staticComponents[element.is]
    props = adaptStaticComponentProps(element.options)


    return {
      Component,
      props
    }
  })

  return renders.map((item, index) => {
    const { Component, props } = item
    return <Component {...props} key={index} parentCategoryTitle={title} />
  })
}

export default CategoryDetailSection
