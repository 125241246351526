import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import AppStateContext from '../../../hooks/AppStateContext'
import CartPageCounts from './CartPageCounts'

import DesktopCartContent from './DesktopCartContent'
import EmptyCartContent from './EmptyCartContent'
import { CheckoutSecure, PurchaseProtection } from '../../common/ShoppingSecurity/ShoppingSecurity'
import SecureCheckoutHeader from '../../common/SecureCheckoutHeader/SecureCheckoutHeader'
import CheckoutStep, { STEP_TYPE } from '../../Store/CheckOut/CheckoutStep'

import cx from '../../../utils/className'

import styles from './DesktopCartPage.module.css'

const renderNull = () => null

const DesktopCartPage = props => {
  const appState = useContext(AppStateContext)
  const plugin = useContext(PluginContext)


  const {
    className,

    appliedCoupon,
    totalPrice,
    discountFee,
    shippingFee,

    lineItems,
    cartLineItemCount,
    checkoutItemCount,

    renderEmptyCartRecommendations = renderNull,
    renderCartRecommendations = renderNull,

    onCartItemQuantityChange,
    onCheckout,
    onClearExpireItems,
    onCartItemSelect,

    displayDiscountFee,
    displayFreight,
    displayProductFee,
    displayTotalFee,
    totalSaveFee,
    displayTotalSaveFee,
    // addOnsForFreeShippingAmount,
    // displayAddOnsForFreeShippingAmount
    showPaypal,
    onPayWithPaypal,
    paypalCheckoutInstance,
    paypalTotalFee,
    onTrack,
    onClickPaypalButton,
    onClickCouponEntrance

  } = props

  const cartRecommendationRef = React.createRef()

  const hasCartItems = lineItems.filter(lineItem => lineItem.saleable).length > 0

  return (
    <div className={cx(styles.wrapper, className, styles.inDesktop)}>
      <SecureCheckoutHeader />
      <div className={styles.body}>
        {
          hasCartItems &&
          <CheckoutStep activeKey={STEP_TYPE.CART} />
        }
        <div className={styles.contentbody} style={{ display: hasCartItems ? 'flex' : 'block' }}>
          <div className={cx(styles.leftContent, !hasCartItems && styles.leftEmpty)}>
            <div style={{ display: hasCartItems ? 'block' : 'none' }}>
              <DesktopCartContent
                cartLineItemCount={cartLineItemCount}
                globalPromotionMeta={appState.globalPromotionMeta}
                lineItems={lineItems}
                onCartItemQuantityChange={onCartItemQuantityChange}
                onClearExpireItems={onClearExpireItems}
                $i18n={plugin.$i18n}
                onCartItemSelect={onCartItemSelect}
              ></DesktopCartContent>
            </div>
          </div>
          <div className={styles.rightContent} style={{ display: hasCartItems ? 'block' : 'none' }}>
            <CartPageCounts
              $i18n={plugin.$i18n}
              discountFee={discountFee}
              shippingFee={shippingFee}
              appliedCoupon={appliedCoupon}
              displayDiscountFee={displayDiscountFee}
              displayFreight={displayFreight}
              displayProductFee={displayProductFee}
              displayTotalFee={displayTotalFee}
              onCheckout={onCheckout}
              cartLineItemCount={cartLineItemCount}
              totalPrice={totalPrice}
              checkoutItemCount={checkoutItemCount}
              totalSaveFee={totalSaveFee}
              displayTotalSaveFee={displayTotalSaveFee}
              onPayWithPaypal={onPayWithPaypal}
              paypalCheckoutInstance={paypalCheckoutInstance}
              onTrack={onTrack}
              showPaypal={showPaypal}
              paypalTotalFee={paypalTotalFee}
              onClickPaypalButton={onClickPaypalButton}
              onClickCouponEntrance={onClickCouponEntrance}
            />
            <CheckoutSecure
              className={styles.securityCertification}
              titleClassName={styles.cardTitle}
              groupClassName={styles.cardGroup}
            />
            <PurchaseProtection
              className={styles.purchaseProtection}
              titleClassName={styles.cardTitle}
              descClassName={styles.safeDesc}
            />
          </div>
          <div style={{ display: hasCartItems ? 'none' : 'block' }} className={styles.emptyContent}>
            <EmptyCartContent $i18n={plugin.$i18n} needLogin={true}>
              {renderEmptyCartRecommendations()}
            </EmptyCartContent>
          </div>

        </div>
        <div style={{ display: hasCartItems ? 'block' : 'none' }} ref={cartRecommendationRef}>{renderCartRecommendations()}</div>
      </div>
    </div>
  )
}

export default DesktopCartPage