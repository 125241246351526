import React, { useContext, useState } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'
import { getCountdownInfo } from '../../utils/dateUtils'
import Countdown from '../Widget/Countdown/Countdown'

import styles from './FixBottomInfoImage.module.css'
import cx from '../../utils/className'

const DEFAULT_COUNTDOWN_THEME = {
  background: "#FFF",
  color: "#190808"
}

const getEndDate= () => {
  const endDate = new Date(new Date().toLocaleDateString())

  endDate.setTime(endDate.getTime() +  1000 * 3600 * 24 - 1000)

  return endDate
}

const MAX_COLOR_SWATCH_COUNT = 3

const ProductPromotionBar = props => {

  const [endDate, setEndDate] = useState(getEndDate())

  const plugin = useContext(PluginContext)

  const { image, pcImage } = props

  if (!image || !pcImage) { // 没有配置商详促销标的均不展示
    return null
  }

  const isDesktop = plugin.$detector.isDesktop()

  const resultImage = isDesktop ? pcImage : image


  const handleFinish = () => {
    setEndDate(getEndDate())
  }

  return (
    <section className={cx(styles.imageWrapper, isDesktop && styles.isDesktop)}>
      <img src={resultImage} className={styles.image} alt='promotion_bar'/>
      {
        props.showCountdown && getCountdownInfo(endDate) &&
        <Countdown
          className={styles.countdownClassName}
          timestamp={getCountdownInfo(endDate).time}
          type={isDesktop ? Countdown.TYPE.CLOCK : Countdown.TYPE.TEXT}
          countdownTheme={DEFAULT_COUNTDOWN_THEME}
          onFinished={handleFinish}
        />
      }

    </section>
  )
}

const ColorSwatch = props => {
  const { colorSwatchList } = props
  const isExceedMax = colorSwatchList.length > MAX_COLOR_SWATCH_COUNT
  const list = isExceedMax ? colorSwatchList.slice(0, 3) : colorSwatchList

  return (
    <div className={styles.colorSwatchWrapper}>
      <div className={styles.colorSwatch}>
        {
          list.map((item, index) => {

            const { colorImageLink, color, colorImageUrl } = item || {}
            const style = {}

            if (colorImageLink) {
              style.background = `url('${colorImageLink}') no-repeat center center`
              style.backgroundSize = 'cover'
            } else if (color) {
              style.background = color
            } else if (colorImageUrl) {
              style.background = `url('${colorImageUrl}') no-repeat center center`
              style.backgroundSize = 'cover'
            }

            return (
              <div key={index} className={styles.colorSwatchBox}>
                <div className={styles.colorSwatchBoxBorder} style={style}></div>
              </div>
            )
          })
        }
        {isExceedMax && <div className={styles.colorSwatchCount}>{colorSwatchList.length}</div>}
      </div>
    </div>
  )
}

const FixBottomInfoImage = props => {
  const { product } = props
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={styles.wrapper}>
      {props.children}
      <div className={styles.fixBottomWrapper}>
        {!isDesktop && product.colorSwatchList && product.colorSwatchList.length && product.colorSwatchList.length > 1 && <ColorSwatch {...product} />}
        {product.promotionBar && <ProductPromotionBar {...props} {...product.promotionBar} />}
      </div>
    </div>
  )
}

export default FixBottomInfoImage
