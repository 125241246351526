import React, { PureComponent } from 'react'
import { hoistHocStatic, withPlugin } from '@flamingo_tech/funkgo'

import StoreService from '../services/StoreService'

const NAVI_ITEMS = []

const withNaviItems = (Component, staticProps) => {
  const WrappedComponent = class extends PureComponent {
    cacheStorage = this.props.$storage.create('_ni7', {
      expire: 60 * 1000 * 10
    })

    state = {
      naviItems: this.props.naviItems || this.cacheStorage.getItem({})[this.props.$site.getSiteInfo().id] || NAVI_ITEMS
    }

    _isMounted = false

    componentDidMount() {
      this._isMounted = true

      const site = this.props.$site.getSiteInfo() || {}
      const siteNaviItems = this.cacheStorage.getItem({})[site.id] || []

      if (!this.state.naviItems.length || this.state.naviItems !== siteNaviItems ) {
        new StoreService(this.props.$http).fetchFeaturedCategories().then((naviItems) => {

          this.cacheStorage.setItem(Object.assign({}, this.cacheStorage.getItem({}), {
            [site.id]: naviItems
          }))

          if (this._isMounted) {
            this.setState({ naviItems })
          }
        }).catch(() => undefined)
      }
    }

    componentWillUnmount() {
      this._isMounted = false
    }

    render() {
      return (
        <Component {...this.props} naviItems={this.state.naviItems}></Component>
      )
    }
  }

  hoistHocStatic(WrappedComponent, Component, staticProps)
  WrappedComponent.displayName = `withNaviItems(${Component.displayName || Component.name})`
  return withPlugin(WrappedComponent)
}

export default withNaviItems
