import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './SecureCheckoutHeader.module.css'

import GREENLOCK from '../../Store/Cart/resources/green_lock.png'
import { getSite } from '../../../utils/siteUtils'

const SecureCheckoutHeader = () => {
  const plugin = useContext(PluginContext)

  const continueShopping = () => {
    if (plugin.$router.history.length > 1) {
      plugin.$router.goBack()
    } else {
      plugin.$router.navigateToHomePage()
    }
  }

  const toHref = () => {
    plugin.$router.navigateToHomePage()
  }

  const { name } = getSite()

  return (
    <div className={styles.topHeader}>
      <div className={styles.content}>
        <div className={styles.headerImage} alt={name} onClick={toHref} />
        <div className={styles.headerTitle}>
          <img src={GREENLOCK} className={styles.headerLockImage} alt="" />
          {plugin.$i18n.transl('core.cart.secureCheckout')}</div>
        <div className={styles.continue} onClick={continueShopping}>{plugin.$i18n.transl('core.cart.continueShopping')}<span>&#xe612;</span></div>
      </div>
    </div>
  )
}

export default SecureCheckoutHeader
