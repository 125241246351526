import React, { PureComponent, useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import Shell from '../Shell/PageShellContainer'
import { InStandaloneApp } from '../../hooks/InApp'
import SearchStickyHeader from '../../components/Store/Search/SearchStickyHeader'
import CartEntry from '../../components/Store/Cart/CartEntry'
import ProductCategoryPage from '../../components/Store/ProductCategory/CategoryPage'

import withNaviItems from '../../hooks/withNaviItems'


const PlainProductCategoryContainer = withNaviItems(props => {
  const plugin = useContext(PluginContext)
  return (
    <>
      {
        !plugin.$detector.isUseAppHomeApp() &&
        <InStandaloneApp>
          <SearchStickyHeader>
            <CartEntry />
          </SearchStickyHeader>
        </InStandaloneApp>
      }
      <ProductCategoryPage items={props.naviItems}></ProductCategoryPage>
    </>
  )
})

class ProductCategoryContainer extends PureComponent {
  static Shell = Shell
  static shellProps = {
    promotion: true,
    displaySearch: true, // only works on web store
  }

  static fetchOpenGraphInfo() {
    return {
      title: 'Categories - Flamingals by Flamingo Shop'
    }
  }

  render() {
    return (
      <PlainProductCategoryContainer></PlainProductCategoryContainer>
    )
  }
}

export {
  PlainProductCategoryContainer
}

export default ProductCategoryContainer
