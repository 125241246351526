import React, { useRef, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import cx from '../../../utils/className'
import styles from './FlamingDealsPanel.module.css'
import { makeSearch } from '../../../utils/breadcrumbUtils'
import FlamingDealsCard from '../ProductCard/FlamingDealsCard'
import HorizontalColumns from '../../common/List/HorizontalColumns'
import Countdown from '../../Widget/Countdown/Countdown'
import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'
import Slider from 'react-slick'
import withSliderTouch from '../../../hooks/withSliderTouch'

const FlamingDealsPanel = props => {
  const isDesktop = props.$detector.isDesktop()
  const [slideIndex, setSlideIndex] = useState(0)
  const sliderRef = useRef()

  if (!props.products || props.products.length === 0) {
    return null
  }


  const {
    // showCountdown,
    // countdownEndDate,
    countdownColor,
    countdownBackground,
    countdownEndsInColor,
    countdownSeparatorColor,
    headerImage,
    headerImageWidth,
    headerImageHeight,
    contentImage,
    contentBackgroundColor,
    discountBackgroundColor,
    // headerImageLink,
  } = props.options
  // const [show, setShow] = useState(true)

  const trackClickFlashSale = () => {
    props.$track.event('FlamingDealsPanel', 'click_banner')
  }

  const trackClickFlashSaleHeader = () => {
    if (props.sourceLocator && props.trackName) {
      props.$track.clickMainResourceLocator(`${props.sourceLocator}:${props.trackName}`)
    }
  }

  const renderCountDown = () => {
    let timestamp = 0

    if (props.items) {
      const { countdownEndTimestamp } = props.items[0]

      timestamp = countdownEndTimestamp - Date.now()
    }


    if (timestamp > 0) {
      const days = Math.floor((timestamp / 86400000))
      return (
          <div className={cx(styles.countDown, isDesktop && styles.inDesktop)}>
            {
              days >= 1
                ? <span className={cx(styles.endsInDays, styles.endsInDaysSpan)}><span>{props.$i18n.transl('core.promotion.endsIn').toLowerCase()}</span> {days} {props.$i18n.transl('core.promotion' + (days === 1 ? '.day' : '.days'))}</span>
                : <span className={styles.endsInDaysSpan} style={{ color: countdownEndsInColor }}><span>{props.$i18n.transl('core.promotion.endsIn').toLowerCase()}</span></span>
            }
            <Countdown
              color={countdownColor}
              background={countdownBackground}
              separatorColor={countdownSeparatorColor}
              timestamp={timestamp}
              className={styles.counts}
            ></Countdown>
            <Link to="FlashSale" onClick={trackClickFlashSale}>
              {
                days >= 1
                ? <div className={cx(styles.endsInDays, styles.rightArrow)}>&#xe608;</div>
                : <div className={cx(styles.rightArrow)} style={{ color: countdownEndsInColor }}>&#xe608;</div>
              }
            </Link>
          </div>
      )
    }
    return null
  }

  const generateToExtraInfo = () => {
    const childOptions = props.childOptions || {}
    const productCardOptions = childOptions.childOptions || {}
    const toExtraInfo = productCardOptions.toExtraInfo || {}

    return {
      ...toExtraInfo,
      search: makeSearch({
        title: 'Flash Sale',
        routeName: 'FlashSale'
      })
    }
  }


  const products = (props.products || []).map(i => i.product)
  const wrapperStyle = {
    backgroundImage: `url('${contentImage}')`,
    backgroundColor: `${ contentBackgroundColor || '#FFFFFF' }`,
  }
  const cardOptions = {
    routerName: 'Product',
    toExtraInfo: generateToExtraInfo(),
    discountBackgroundColor,
    trackName: props.trackName,
    sourceLocator: props.sourceLocator
  }

  const childOptions = {
    ...(props.childOptions || {}),
    childOptions: {
      ...((props.childOptions || {}).childOptions || {}),
      toExtraInfo: generateToExtraInfo(),
    },
    style: {
      textColor: (props.childOptions || {}).textColor,
      childImage: (props.childOptions || {}).childImage,
    },
    pageInfo: { // not fetch data any more
      hasNextPage: false
    }
  }

  const renderList = () => {
    if (isDesktop) {
      const columnCount = 6
      const sliderSettings = {
        infinite: false,
        className: styles.silder,
        slidesToShow: columnCount,
        slidesToScroll: columnCount,
        afterChange: setSlideIndex,
      }
      return (
        <div className={cx(styles.body, isDesktop && styles.inDesktop)} style={wrapperStyle}>
          { slideIndex > 0 && <div className={cx(styles.arrow, styles.left)} onClick={() => sliderRef.current.slickPrev()}>&#xe608;</div>}
          <Slider {...sliderSettings} ref={sliderRef}>
            {
              products.map((i, index) => {
                return (
                  <div className={styles.flamingCardBox} key={index}>
                    <FlamingDealsCard {...i} {...cardOptions} disableClick={props.disableClick} />
                  </div>
                )
              })
            }
          </Slider>
          { (slideIndex + columnCount <  products.length) && <div className={cx(styles.arrow, styles.right)} onClick={() => sliderRef.current.slickNext()}>&#xe608;</div> }
        </div>
      )
    }
    return (
      <div className={cx(styles.body, isDesktop && styles.inDesktop)} style={wrapperStyle}>
        <div>
          <HorizontalColumns
            {...childOptions}
            childIs={FlamingDealsCard}
            childOptions={cardOptions}
            childItems={products}
            className={styles.productSection}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      <div className={styles.header} onClick={trackClickFlashSaleHeader}>
        {
          headerImage &&
          <>
            {
              <Link to='FlashSale' onClick={trackClickFlashSale}>
                <Image
                  src={headerImage}
                  objectFit='cover'
                  className={styles.panelImage}
                  large
                  placeholder={{
                    width: headerImageWidth,
                    height: headerImageHeight
                  }}
                />
              </Link>
            }
           
            {renderCountDown()}
          </>
        }
      </div>
      <div className={styles.listWrapper}>
        {renderList()}
      </div>
    </div>
  )
}

export default withPlugin(withSliderTouch(FlamingDealsPanel))