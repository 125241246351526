import React, { PureComponent } from 'react'

import withUserAndPlugins from './withUserAndPlugins'
import { hoistHocStatic } from '@flamingo_tech/funkgo'

import { debounce } from 'debounce'

import PostService from '../services/PostService'

const withPostLike = (Component, { eventCategory } = {}) => {
  const eventCategoryName = eventCategory || Component.name.split('Container')[0]
  const displayName = `withPostLike(${Component.displayName || Component.name})`
  const WrappedComponent = class extends PureComponent {

    injectMethods() {
      return {
        $togglePostLike: (postId, isLiked) => {
          return new Promise((resolve, reject) => {
            this.props.$login().then(() => {
              resolve()
              debounce(() => {
                this.props.$track.event(eventCategoryName, `${isLiked ? 'like' : 'unlike'}_post`)
                new PostService(this.props.$http).likeOrUnlikePost(postId, isLiked).catch(() => 'fail')
              }, 500)()
            }).catch(() => {
              this.props.$track.event(eventCategoryName, `abandonLike_${postId}`)
              // reject()
              resolve('fail')
            })
          })
        }
      }
    }

    render() {
      return (
        <>
          <Component {...this.props} {...this.injectMethods()}></Component>
        </>
    )}
  }

  hoistHocStatic(WrappedComponent, Component)
  WrappedComponent.displayName = displayName
  return withUserAndPlugins(WrappedComponent)
}

export default withPostLike
