import React, { useState } from 'react'
import cx from '../../../utils/className'
import styles from './FilterMultiChoice.module.css'
import { withPlugin } from '@flamingo_tech/funkgo'
import ColorPicker from '../../common/ColorPicker/ColorPicker'

const FilterMultiChoice = props => {
  const isDesktop = props.$detector.isDesktop()
  const {
    filters,
    filterKey,
    filterName
   } = props.section

  const selectedOptions = props.selectedOptions
  const [showContent, setContentShow] = useState(true)
  const selectedFilter = selectedOptions[filterKey]

  const handleToggleItem =() => {
    setContentShow(!showContent)
  }

  const handleFilterSelected = (filter) => {
    const selectedArr = selectedFilter
    const array = Array.isArray(selectedArr) ? selectedArr : []
    const index = array.indexOf(filter.displayKey)
    if (index > -1) {
      array.splice(index, 1)
    } else {
      array.push(filter.displayKey)
    }
    props.onFilterChange({...selectedOptions, [filterKey]: array})
  }

  const handleClickClean = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    props.onFilterChange({...selectedOptions, [filterKey]: []})
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)}>
      <div className={styles.itemHeader} onClick={() => handleToggleItem()}>
        <span className={styles.title}>{filterName}</span>
        {
          isDesktop && selectedFilter && selectedFilter.length > 0 && <i className={styles.clean} onClick={(ev)=> handleClickClean(ev)}></i>
        }
        <div className={styles.rightWrapper}>
          {
            !isDesktop && selectedFilter && selectedFilter.length > 0 &&
            <div className={cx(styles.displaySelectedFilter, selectedFilter.length > 1 && styles.withCount)}>
              {
                selectedFilter.length === 1 ? filters.filter(item => item.displayKey === selectedFilter[0])[0].displayValue : selectedFilter.length
              }
            </div>
          }
          {
            showContent
            ? (isDesktop ? <i className={styles.arrowIcon}>&#xe60f;</i> : <i className={styles.arrowIcon}>&#xe66e;</i>)
            : (isDesktop ? <i className={styles.arrowIcon}>&#xe609;</i> : <i className={styles.arrowIcon}>&#xe7b2;</i>)
          }
        </div>


      </div>
      {
        showContent && (
          <div className={styles.itemWrapper}>
            {
              filters && (
                filters.map((filter, index) => {
                  const { isSelected, image, displayValue } = filter
                  return (
                    <div key={index} className={cx(image ? styles.filterImageBox : styles.sizeBox)}>
                      {
                        image
                        ?
                        <ColorPicker active={isSelected} src={image} className={cx(styles.colorImg)} onClick={() => handleFilterSelected(filter)} />
                        :
                        (
                          isDesktop ?
                          <div className={styles.optionBtn} onClick={() => handleFilterSelected(filter)}>
                            <div className={cx(styles.checkBtn, isSelected && styles.selected)}>
                              { isDesktop && isSelected && <span className={styles.pcCheckBtn}>&#xe650;</span> }
                            </div>
                            <span className={styles.btnTitle}>{displayValue}</span>
                          </div> :
                          <div className={cx(styles.plainItem, isSelected && styles.selected)} onClick={() => handleFilterSelected(filter)}>{displayValue}</div>
                        )
                      }
                    </div>
                  )
                })
              )
            }
          </div>
        )
      }
    </div>
  )
}

export default withPlugin(FilterMultiChoice)