import React, {
  forwardRef,
  useEffect,
  useCallback,
  useRef
} from 'react'

import TabLink from './TabLink'

import {
  getDefaultTab,
  findTabByKey,
  extractTabKeyFromHash,
} from './utils/tabUtils'

const Tab = forwardRef((props, ref) => {
  const {
    tabs,
    currentTab = {},
    onTabChange,
    tabParamName,
    className,
    itemClassName,
    itemStyle,
    itemSelectedClassName,
    renderItem,
    needTabKeyToHash
  } = props

// keep the original tab, so that hash change can revert it
const originalTab = useRef(currentTab)
const userSelectedTabRef = useRef(currentTab)

  const handleTabChange = useCallback(tab => {
    if (typeof onTabChange === 'function') {
      onTabChange(tab)
    }

    // stash the tab key assigned by user select to so hash changed can ignore
    userSelectedTabRef.current = tab

  }, [onTabChange])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleHashChange = ev => {
        const hashTabKey = extractTabKeyFromHash(tabParamName)
        const currentTabKey = String(currentTab.key)
        const userSelectedTabKey = String(userSelectedTabRef.current.key)

        if (hashTabKey && hashTabKey !== currentTabKey && hashTabKey !== userSelectedTabKey) {
          const tabFromHash = findTabByKey(tabs, hashTabKey)
          if (tabFromHash) {
            handleTabChange(tabFromHash)
          }
        } else if (
          !hashTabKey
          // a workaround to handle login panel to overwrite page hash
          && (
            typeof window !== undefined
            && !/^#(login|register|main-page|reset_password)/.test(window.location.hash)
          )
        ) {
          handleTabChange(originalTab.current)
        }
      }

      window.addEventListener('hashchange', handleHashChange)

      return () => (
        window.removeEventListener('hashchange', handleHashChange)
      )
    }
  }, [currentTab, tabs, tabParamName, originalTab, handleTabChange])

  return (
    <ul className={className} ref={ref}>
        {
          tabs.map(tab => (
            <li key={tab.key} style={itemStyle}>
              <TabLink
                tab={tab}
                tabParamName={tabParamName}
                onTabChange={handleTabChange}
                selected={tab.key === currentTab.key}
                className={itemClassName}
                selectedClassName={itemSelectedClassName}
                render={renderItem}
                needTabKeyToHash={needTabKeyToHash}
              />
            </li>
          ))
        }
    </ul>
  )
})

Tab.displayName = 'Tab'

export {
  getDefaultTab
}

export default Tab