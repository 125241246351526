import React from 'react'
import { withDrawer } from '../../common/Drawer/Drawer'

import styles from './ProductDescription.module.css'

const DescriptionContent = ({ descList = [] }) => {
  return (
    <div className={styles.descContent}>
      {
        descList.map(desc => (
          <div className={styles.descContentWrapper} key={desc.materialName}>
          <div className={styles.descName}>{desc.materialName}:</div>
          <div className={styles.descValue}>{desc.materialDisplayName}</div>
        </div>
        ))
      }
    </div>
  )
}

const Description = ({ descList = [] }) => {
  return (
    <div className={styles.descWrapper}>
      <div>
        <DescriptionContent
          descList={descList}
        />
      </div>
    </div>
  )
}

const DescriptionModalElement = withDrawer(Description)

const DescriptionModal = props => {
  return <DescriptionModalElement headerTitle={props.$i18n.transl('core.product.description')} {...props} />
}

const ProductDescription = ({ onClick, $i18n }) => {
  return (
    <h2 className={styles.section} onClick={onClick}>
      {$i18n.transl('core.product.description')}
    </h2>
  )
}

export default ProductDescription

export {
  DescriptionModal,
  DescriptionContent
}