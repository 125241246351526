import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './ProductModel.module.css'

import Image from '../../common/Image/Image'

const ProductModelSection = withPlugin(props => {
  const { models = [] } = props

  if (!models.length) {
    return null
  }

  const renderCard = item => {
    const { name, avatar, size, measurements = [] } = item

    return (
      <div className={styles.productModelSectionCard}>
        <div className={styles.modelImageBox}><Image rightTiny lazy className={styles.modelImage} objectFit='cover' src={avatar} /></div>
        <div className={styles.modelInfo}>
          <p>{name} {props.$i18n.transl('core.product.sizeGuideInfo.wear')}{`: ${size}`}</p>
          <div className={styles.modelBody}>
            {
              measurements.map(i => <div key={i.name}>{i.name}{`: ${i.value}`}</div>)
            }
          </div>
        </div>
      </div>
    )
  }

  if (models.length === 1) {
    return (
      <div className={styles.singleCardWrapper}>
        {renderCard(models[0])}
      </div>
    )
  }

  return (
    <div className={styles.MultiCardWrapper}>
      {
        models.map((i, index) => (
          <div key={index} className={styles.MultiCard}>
            {renderCard(i, index)}
          </div>
        ))
      }
    </div>
  )
})

const ProductModelContent = withPlugin(props => {
  const { models = [] } = props

  if (!models.length) {
    return null
  }

  return (
    <div className={styles.productModelContent}>
      {
        models.map((item, index) => {
          const { name, avatar, size, measurements = [] } = item

          return (
            <div key={index} className={styles.modelCard}>
              <div className={styles.modelImageBox}><Image lazy className={styles.modelImage} objectFit='cover' src={avatar} tiny/></div>
              <div className={styles.modelInfo}>
                <p>{name} {props.$i18n.transl('core.product.sizeGuideInfo.wear')}{`: ${size}`}</p>
                <div className={styles.modelBody}>
                  {
                   measurements.map(i => <div key={i.name}>{i.name}{`: ${i.value}`}</div>)
                  }
                </div>
              </div>
            </div>
          )
        })
      }
    </div>
  )
})

export {
  ProductModelSection,
  ProductModelContent
}
