import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import TabPanel from '../../common/Tab/TabPanel'
import ESProductList from './ESProductList'
import ProductCard from '../ProductCard/ProductCard'
import styles from './ProductTabList.module.css'

const getProductListTitle = props => (
  props.title || props.childOptions.title || 'Products'
)

const ProductTabList = withPlugin(props => {
  const { items = [], products } = props

  const resourceLocatorConfig = {
    resourceLocator: `${props.sourceLocator}:${props.trackName}`,
    type: 'main',
    params: {
      sourceHandle: props.collectionHandle
    }
  }

  const themeStyle = {}

  if (props.themeColor) {
    themeStyle.background = props.themeColor
  }

  if (items.length === 1) {
    return (
      <ESProductList
        cardOptions={{
          className: styles.card,
          resourceLocatorConfig
        }}
        {...props}
        columns={props.wrap}
        cardIs={ProductCard}
        products={products}
        className={styles.list}
        style={themeStyle}
      />
    )
  }

  const tabs = items.map((item, i) => ({
    key: i,
    title: getProductListTitle(item),
    collectionHandle: item.collectionHandle
  }))



  const renderPanel = currentTab => {
    const { collectionHandle, key } = currentTab

    return tabs.map((_, i) => {
      const products = i === 0 ? props.products : []
      return (
        <ESProductList
          cardOptions={{
            className: styles.card,
            resourceLocatorConfig
          }}
          columns={props.wrap}
          key={i}
          {...props}
          readyToFetch={i === key}
          cardIs={ProductCard}
          products={products}
          collectionHandle={collectionHandle}
          className={styles.list}
          style={themeStyle}
        />
      )
    })
  }

  return <TabPanel tabs={tabs} renderPanel={renderPanel}></TabPanel>
})

export default ProductTabList
