import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import Countdown from '../../Widget/Countdown/Countdown'

import styles from './FlashSaleTag.module.css'
import cx from '../../../utils/className'

const FlashSaleTag = props => {
  const plugin = useContext(PluginContext)

  if (!props.promotionActivities) {
    return null
  }

  const handleToFlashSale = () => {
    if (props.isLink) {
      plugin.$track.event('Cart', 'click_flash_sale')
      plugin.$router.navigate('/store/flash-sale')
    }
  }

  const getCountDownInfo = () => {
    const result = {}
    for (let i of props.promotionActivities) {
      if (i.name === 'FlashSale') {
        result.time = i.endTime - Date.now()
        break
      }
    }
    return result
  }

  const { time: timestamp } = getCountDownInfo()

  return (
    <div className={cx(styles.wrapper, props.isLink && styles.isLink)} onClick={handleToFlashSale}>
      <div className={styles.title}>Flash Sale</div>
      {
        !props.hideCountDown && timestamp > 0 && (
          <div className={styles.countdownBox}>
            <Countdown
              timestamp={timestamp}
              type={Countdown.TYPE.TEXT}
            ></Countdown>
          </div>
        )
      }
    </div>
  )
}

export default FlashSaleTag