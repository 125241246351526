import CouponModel from '../../plugins/StorePlugin/models/CouponModel'

class CouponListModel {
  constructor({ coupons }) {
    this.coupons = coupons.map(coupon => ({
      ...new CouponModel.Meta(coupon)
    }))
  }
}


export default CouponListModel
