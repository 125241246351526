import React from 'react'
import styles from './PageHeader.module.css'
import cx from '../../../utils/className'

const PageHeader = props => {

  if (!props.title || props.hide) {
    return null
  }

  if (props.isDesktop) {
    return <div className={styles.pcTitle}>{props.title}</div>
  }

  const handleClick = () => {
    props.onHistoryBack()
  }


  return (
    <header className={cx(styles.wrapper, props.className)}>
      <i className={styles.iconLeft} onClick={handleClick}></i>
      <div className={styles.title}>{props.title}</div>
      {props.renderIcons && props.renderIcons()}
    </header>
  )
}

export default PageHeader