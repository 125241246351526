import React, { useContext } from 'react'

import {
  InView as InViewOriginal,
  useInView,
} from 'react-intersection-observer'

import { PluginContext } from '@flamingo_tech/funkgo'

const hasIntersectionObserver = () => (
  typeof window !== 'undefined' && typeof window.IntersectionObserver !== 'undefined'
)

const InView = props => {
  const plugin = useContext(PluginContext)


  if (hasIntersectionObserver()) {
    return (
      <InViewOriginal {...props}></InViewOriginal>
    )
  }

  if (typeof window !== 'undefined') {
    plugin.$logger.error('[InView] IntersectionObserver not yet implemented')
  }

  return props.children
}

export default InView
export {
  useInView
}