import React, { useState, useContext } from 'react'
import Link from '../../common/Link/Link'
import Image from '../../common/Image/Image'
import CategoryDetailSection from '../../Store/ProductCategory/CategoryDetailSection'
import SelectedCountrySite from '../SiteSettingPanel/SelectedCountrySite'
import withNaviItems from '../../../hooks/withNaviItems'

import { PluginContext } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import styles from './StoreNavi.module.css'

const EXTRA_ITEMS = [
  {
    image: 'https://img.flamingo.shop/p/files/Lark20200331-004713.png?v=1585630124',
    title: 'core.user.aboutUs',
    link: "https://m.flamingo.shop/store/info"
  }
]

const DOWNLOAD_APP_IMAGE = {
  ios: 'https://img.flamingo.shop/p/files/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917_2x_4035a2f0-47f3-4509-afb7-d4a368a9fa2d.png?v=1577264834',
  android: 'https://img.flamingo.shop/p/files/google-play-badge_2x_985b3574-33cf-4e7a-a9ed-47ba5904305f.png?v=1577264834'
}

const SOURCE_LOCATOR = 'Category'

const DownloadAppSection = () => {
  const plugin = useContext(PluginContext)
  const image = DOWNLOAD_APP_IMAGE[plugin.$detector.isAndroid() ? 'android' : 'ios']

  const handleDownload = () => {
    if (plugin.$bridge) {
      plugin.$bridge.downloadApp({
        trackChannel: 'navi'
      })
    }
  }

  if (plugin.$detector.isDesktop()) {
    return null
  }

  return (
    <div className={styles.downloadAppSection}>
      <Image
        lazy
        className={styles.image}
        small
        src={image}
        onClick={handleDownload}
      />

    </div>
  )
}


const NaviFooterBar = props => {
  const plugin = useContext(PluginContext)


  const FOOTER_BAR_ITEMS = [
    {
      image: '\ue627',
      title: plugin.$i18n.transl('shell.footer.home'),
      link: "https://m.flamingo.shop/store/"
    },
    {
      image: '\ue619',
      title: plugin.$i18n.transl('shell.footer.support'),
      link: {},
      onClick: ev => {
        ev.preventDefault()
        plugin.$router.navigateToMessengerBot('customerService', 'fromNavi', () => {
          this.props.$alert({
            title: plugin.$i18n.transl('terms.contactUs'),
            content: plugin.$i18n.transl('terms.contactUsContent')
          })
        })
      }
    },
    {
      image: '\ue62e',
      title: plugin.$i18n.transl('shell.footer.me'),
      link: "https://m.flamingo.shop/store/user"
    }
  ]

  return (
    <section className={styles.naviFooterBar}>
      {
        FOOTER_BAR_ITEMS.map(item => (
          <Link
            {...item}
            className={styles.footerBarItem}
            key={item.title}
          >
            <i className={styles.footerBarIcon}>{item.image}</i>
            <h4 className={styles.footerBarText}>{item.title}</h4>
          </Link>
        ))
      }
    </section>
  )
}

const NaviItem = props => {
  const { $i18n, ...otherProps } = props

  return (
    <li {...otherProps} className={cx(styles.naviItem, props.className)}>
      <Image
        lazy
        alt={props.title}
        src={props.image}
        className={styles.icon}
        small
      />
      <label>{$i18n.transl(props.title)}</label>
    </li>
  )
}

const NaviItemWithLink = props => {
  return (
    <Link {...props}>
      <NaviItem {...props} />
    </Link>
  )
}

const StoreNavi = props => {
  const [openNavi, setOpenNavi] = useState(false)
  const [curNavi, setCurNavi] = useState(null)
  const plugin = useContext(PluginContext)

  const items = props.naviItems || []

  const handleItemClickFactory = item => ev => {
    const itemTrack = typeof item.link === 'string' ? item.link : item.title
    plugin.$track.event('StoreLayout', 'naviToPage', itemTrack)

    if (typeof item.onClick === 'function') {
      item.onClick(ev)
    }

    if (typeof props.onNaviItemClick === 'function') {
      props.onNaviItemClick(item, ev)
    }
  }

  const handleNaviClick = item => {
    setOpenNavi(true)
    setCurNavi(item)
  }

  const handleImageTextClick = item => {
    if (item.link && !item.layout){
      const childTrack = typeof item.link === 'string' ? item.link : item.title
      plugin.$track.clickMainResourceLocator(`${SOURCE_LOCATOR}:${item.title}`)
      plugin.$track.event('Categories', 'naviToPage', childTrack)
      plugin.$router.navigate(item.link)

      if (typeof props.onNaviItemClick === 'function') {
        props.onNaviItemClick(item)
      }
    } else {
      handleNaviClick(item)
    }
  }

  return (
    <aside className={cx(styles.navigation, openNavi && styles.withNaviDetailOpen, props.className)}>
      <div className={styles.naviWrapper}>
        <Link to="SearchPage" className={styles.search}>{plugin.$i18n.transl('core.search.search')}</Link>
        <div className={styles.naviItems}>
          {
            items.map((item, index) => (
              <div key={index} className={styles.naviLineItem} onClick={() => handleImageTextClick(item)}>
                <img alt={item.title} src={item.image} className={styles.naviLineItemImage} />
                <span>{item.title}</span>
              </div>
            ))
          }
          {
            <ul className={styles.items}>
            {
               EXTRA_ITEMS.map(item => (
                 <NaviItemWithLink
                  $i18n={plugin.$i18n}
                  key={item.title}
                  {...item}
                  onClick={handleItemClickFactory(item)}
                />
               ))
            }
            <SelectedCountrySite
              className={styles.countryIcon}
              liStyle={styles.liStyle}
            ></SelectedCountrySite>
           </ul>
          }
          <DownloadAppSection />
        </div>
        <NaviFooterBar onClick={handleItemClickFactory}/>
      </div>
      <div className={cx(styles.naviDetail, openNavi && styles.open)}>
        {
          curNavi && curNavi.layout &&
          <>
            <div className={styles.header} onClick={() => setOpenNavi(false)}></div>
            <div className={styles.content} onClick={() => props.onNaviItemClick && props.onNaviItemClick()}>
              <CategoryDetailSection
                {...curNavi}
                meta={{}}
              />
            </div>
          </>
        }
      </div>
    </aside>
  )
}

export default withNaviItems(StoreNavi)
