import { useState, useContext, useEffect } from 'react'
import AppStateContext from '../../../../hooks/AppStateContext'
import { getCartLineItemCount } from '../../../../utils/cartUtils'

const silentFail = () => undefined

const useCartVisible = ({
  cart,
  cartOperation,
  $connectCart,
  $detector,
  $storage,
  $track
}) => {
  const [cartVisible, setCartVisible] = useState($storage.create('cart_visible', { strategy: 'SESSION' }).getItem(false))
  const [originalCart, setOriginalCart] = useState(cart)
  const [originalCartOperation, setOriginalCartOperation] = useState(cart)

  const appState = useContext(AppStateContext)
  const { addedToCartRecommend } = appState.globalSettingMeta

  const isCartChanged = cart && cart !== originalCart
  const isCartOperationChanged = cartOperation && cartOperation !== originalCartOperation

  useEffect(() => {
    $storage.create('cart_visible', { strategy: 'SESSION' }).setItem(false)
  }, [$storage])

  // cache original cart, so that we can know the cart has been changed via prop
  if (isCartChanged) {
    setOriginalCart(cart)
  }

  if (isCartOperationChanged) {
    setOriginalCartOperation(cartOperation)
  }


  if (isCartOperationChanged && !cartVisible) {
    if (isCartChanged && cartOperation.add && (!addedToCartRecommend || !$detector.isStandaloneApp()))  {
      // if add cart on web, then display cart panel
      setCartVisible(true)
    } else if (cartOperation.touch) {
      // if just trigger touch (from app product detail footer, also display cart panel)
      setCartVisible(true)
    }
  }

  const showCart = () => {
    $track.event('StoreLayout', 'open_cart')

    setCartVisible(true)
  }

  const hideCart = () => {
    if (cartVisible) {
      $track.event('StoreLayout', 'hide_cart')

      setCartVisible(false)
    }
  }

  const toggleCart = () => {
    if (cartVisible) {
      hideCart()
    } else if (cart) {
      showCart()
    } else {
      $connectCart().then(showCart).catch(silentFail)
    }
  }

  return [
    cartVisible,
    toggleCart,
    hideCart,
  ]
}

const useNaviVisible = () => {
  const [naviVisible, setNaviVisible] = useState(false)
  const toggleNavi = () => setNaviVisible(!naviVisible)
  const hideNavi = () => {
    if (naviVisible) {
      setNaviVisible(false)
    }
  }

  return [
    naviVisible,
    toggleNavi,
    hideNavi,
  ]
}

const useShare = ({
  $track,
  $bridge
}) => {
  const share = shareInfo => {
    $track.event('StoreLayout', 'share', document.location.href)
    $bridge.share(shareInfo).then(() => {
      $track.event('StoreLayout', 'share_finished', document.location.href)
    }).catch(silentFail)
  }

  return share
}

// exclude unnecessary prop for header display
const useHeaderProps = props => {
  const cart = props.cart
  const cartLineItemCount = getCartLineItemCount(cart)

  return {
    cartLineItemCount,
    // store only
    displaySearch: props.displaySearch,

    // both store and app
    displayCart: props.displayCart,
    displayShare: props.displayShare,

    // app only
    displayHeader: props.displayHeader,
    floatingHeader: props.floatingHeader,
    transparentHeader: props.transparentHeader,
    displayBack: props.displayBack,
    displayHome: props.displayHome,
    renderButton: props.renderButton,
    hideHeader: props.hideHeader,
    headerTheme: props.headerTheme,
    headerTitle: props.headerTitle
  }
}

export {
  useCartVisible,
  useNaviVisible,
  useShare,
  useHeaderProps
}