import React, { Component } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import queryString from 'query-string'

import UnpaidOrderContainer from './EntryContainers/UnpaidOrderContainer'
import UnpaidCartContainer from './EntryContainers/UnpaidCartContainer'
import WelcomeModalContainer from './EntryContainers/WelcomeModalContainer' // 大转盘
import BigSaleContainer from './EntryContainers/BigSaleContainer'
import withAppState from '@hooks/withAppState'
import SupplementWheel from '@components/Store/Coupon/SupplementWheel'


class MobileEntryFlowContainer extends Component {
  mobileEntryFlowContainerLoadedStorage = this.props.$storage.create('m_e_f_loaded', { strategy: 'SESSION' }) // MobileEntryFlowContainer 是否初始化
  sourceLocationStorage = this.props.$storage.create('source_location', { strategy: 'SESSION'})

  state = {
    stepIndex: -1,
    hideWheel: false,
    isMounted: this.mobileEntryFlowContainerLoadedStorage.getItem(false),
    isForAds: false,
    ciPopup: ''
  }

  componentDidMount() {
    const sourceLocation = queryString.parseUrl(this.sourceLocationStorage.getItem(window.location.href))

    if (sourceLocation.query) {
      const { hide_wheel, ci, ac_ads } = sourceLocation.query

      const { locale } = this.props.$site.getSiteInfo()

      if (ci && locale === 'en_US') { // ci弹窗
        this.setState({
          ciPopup: ci.split(',')[0],
          hideWheel: true
        })
      } else if (hide_wheel) {
        this.setState({
          hideWheel: true
        })
      }

      if (ac_ads) {
        this.setState({
          isForAds: true
        })
      }
    }
  }

  setMounted = () => {
    this.setState({
      isMounted: true
    })

    this.mobileEntryFlowContainerLoadedStorage.setItem(true)
  }

  handleUnpaidCartNext = () => {
    this.setState(({ stepIndex }) => ({ stepIndex: stepIndex + 1 }))

    const { globalPromotionMeta } = this.props

    if (globalPromotionMeta.promoConfig) {
      this.setMounted()
    }
  }

  render() {
    switch (this.state.stepIndex) {
      case -1: // 大促弹窗
        return (
          <BigSaleContainer
            key='big_sale'
            toNext={() => this.setState(({ stepIndex }) => ({ stepIndex: stepIndex + 1 }))}
          />
        )
      case 0: // 未订单支付弹窗， 随时查询
        return (
          <UnpaidOrderContainer
            key='unpaid_order'
            toNext={() => this.setState(({ stepIndex }) => ({ stepIndex: stepIndex + 1 }))}
          />)
      case 1:
        return (
          <UnpaidCartContainer
            key='unpaid_cart'
            toNext={this.handleUnpaidCartNext}
            mounted={this.state.isMounted}
            entryStorage={this.mobileEntryFlowContainerLoadedStorage}
            isForAds={this.state.isForAds}
          />
        )
      case 2: // 大转盘，初始化查询
        {
          const { globalPromotionMeta } = this.props

          if (!globalPromotionMeta.promoConfig) {
            return (
              <WelcomeModalContainer
                key='welcome_modal'
                hideWheel={this.state.hideWheel}
                footHintHeight={this.props.footHintHeight}
                mounted={this.state.isMounted}
                setMounted={this.setMounted}
                entryStorage={this.mobileEntryFlowContainerLoadedStorage}
                isForAds={this.state.isForAds}
                ciPopup={this.state.ciPopup}
              />
            )
          } else {
            return (
              <SupplementWheel
                setRuntimeData={this.props.setRuntimeData}
                key="supplement_wheel"
                addedToCartCoupons={this.props.regularCouponMeta.addedToCartCoupons || []}
                enableEmailCollect={true}
              />
            )
          }
        }
      default:
        return null
    }
  }
}

export default withAppState(withPlugin(MobileEntryFlowContainer))
