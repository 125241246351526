import { getCouponDiscountPercentage } from '../couponUtils'

export const REGULAR_COUPON_META = {
  NEWCOMER: 'newcomer',
  WHEEL: 'wheel',
  THANK_YOU_GIFT: "thankYouGift",
  WHEELS: 'wheels'
}

const PROMOTION_TYPE = {
  STANDARD_OFF: 'STANDARD_OFF',
  BUY_X_GET_Y: 'BUY_X_GET_Y',
  FIXED_AMOUNT_SELECT_Y: 'FIXED_AMOUNT_SELECT_Y'
}

const PERCENTAGE = 'PERCENTAGE'
const QUANTITY = 'QUANTITY'

export const getPromotionDiscountText = (promotion, $i18n, $site) => {
  const { fixedAmount, discount } = promotion

  if (promotion.type === PROMOTION_TYPE.FIXED_AMOUNT_SELECT_Y) {
    return `$${fixedAmount}`
  } else if (discount) {
    if (discount.type === PERCENTAGE) {
      return discount.value === 100 ? $i18n.transl('core.promotion.forFree') : `${discount.value}% ${$i18n.transl('core.promotion.off')}`
    }

    if ($site) {
      const { currencySymbol, isCurrencySymbolPrefix } = $site.getSiteInfo()
      return `${isCurrencySymbolPrefix ? `${currencySymbol}${discount.value}` : `${discount.value}${currencySymbol}`} ${$i18n.transl('core.promotion.off')}`
    }

    return `$${discount.value} ${$i18n.transl('core.promotion.off')}`
  }
}

export const getPromotionRule = promotion => {
  let rule

  const { type } = promotion

  switch (type) {
    case PROMOTION_TYPE.STANDARD_OFF: {
      const { discount, requirement } = promotion
      const discountText = getPromotionDiscountText(discount)
      const requirementText = requirement ? (
        requirement.type === QUANTITY ?  `Items ${requirement.value}+` : `Orders $${requirement.value}+`
      ) : ''
      rule = `${discountText} ${requirementText}`
      break
    }
    case PROMOTION_TYPE.BUY_X_GET_Y: {
      const { discount, X, Y } = promotion
      const isForFree = discount.type === PERCENTAGE && discount.value === 100
      rule = `Buy ${X} Get ${Y} ${!isForFree ? 'at' : ''} ${getPromotionDiscountText(discount)}`
      break
    }
    case PROMOTION_TYPE.FIXED_AMOUNT_SELECT_Y: {
      const { fixedAmount, Y } = promotion
      rule = `$${fixedAmount} Select ${Y} Items`
      break
    }
    default:
      rule = promotion.title
      break
  }
  return rule
}

export const getPromotionExpiryDate = ({ startDate, endDate }) => {
  if ((!startDate && !endDate) || !(startDate instanceof Date)) {
    return ''
  } else if(startDate && endDate) {
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
  } else if (startDate) {
    // only has startDate
    return `${startDate.toLocaleDateString()} - `
  } else {
    // only has endDate
    return `Expires in ${endDate.toLocaleDateString()}`
  }
}

export const getPromotionTarget = promotion => {
  const { targetType, targetSelection } = promotion

  let targetTypeText

  switch (targetType) {
    case 'ALL':
      targetTypeText = 'All Products'
      break
    default:
      targetTypeText = (targetSelection || []).map(selection => selection.title).filter(Boolean).join('; ')
      break
  }
  return targetTypeText
}

/*
a. all target coupon precede category target coupon
b. coupon with higher discount rate precede the other

return true if couponA is prior to couponB, vice versa.
*/
export const compareCouponPriority = (couponA, couponB) => {
  const metaA = couponA.meta
  const metaB = couponB.meta

  if (metaA.targetType === metaB.targetType) {
    return getCouponDiscountPercentage(couponA) > getCouponDiscountPercentage(couponB)
  } else {
    return metaA.targetType === 'ALL'
  }
}

export const hasPriorCoupons = (couponA, coupons) => {
  let result = false

  if (coupons.length > 0) {
    for (let i = 0; i < coupons.length; i++) {
      if (!compareCouponPriority(couponA, coupons[i])) {
        result = true
        break
      }
    }
  }

  return result
}

export const getCouponCodeByUtmCampaign = utm_campaign => {
  let code

  // sample:
  // klaviyo: utm_campaign cc_CART10 (XuxWnC)
  // athena: utm_campaign cc_CART10-xxxx
   if (utm_campaign && utm_campaign.indexOf('cc_') > -1) {
    const codeStr = utm_campaign.split('cc_')[1] || ''

    if (codeStr.indexOf(' ') > -1) {
      code = codeStr.split(' ')[0]
    } else {
      code = codeStr.split('-')[0]
    }
  }

  return code

}
// || this.props.$site.getSiteInfo().locale !== 'en_US'
export const isWebToAppDisabled = plugin => {
  if (!plugin.$storage || !plugin.$site) {
    throw Error('isWebToAppDisabled must provide the main plugin!')
  }
  const sourceLocationStorage = plugin && plugin.$storage.create('source_location', { strategy: 'SESSION'})
  const sourceLocation = sourceLocationStorage.getItem(window.location.href)
  const utmSourceReg = /utm_source=(facebookads|googleads|tiktokads|Facebook|Google|TikTok|affiliate|Email|SMS)/
  const adReg = /(adgroupid|campaignid)/

  const isWebToAppDisabled = utmSourceReg.test(sourceLocation) || adReg.test(sourceLocation)
  return isWebToAppDisabled || (plugin && plugin.$site.getSiteInfo().locale !== 'en_US')
}