import { PostModel } from './PostModel'

class PostListModel {
  constructor(data) {
    this.pageInfo = {
      hasNextPage: data.nextPage,
      totalCount: data.count
    }

    this.posts = (data.noteList || []).map(note => ({
      ...new PostModel(note)
    }))
  }
}


export default PostListModel
