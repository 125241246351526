class FBUserModel {
  constructor(options, fbToken) {
    Object.assign(this, {
      fbToken,

      fbUserId: options.id,
      email: options.email,
      name: options.name,
      firstName: options.first_name,
      lastName: options.last_name,
      image: `https://graph.facebook.com/${options.id}/picture?type=normal`
    })
  }
}

class GoogleUserModel {
  constructor(basicProfile, authResponse) {
    Object.assign(this, {
      userId: basicProfile.userId,
      name: basicProfile.name,
      firstName: basicProfile.givenName,
      lastName: basicProfile.familyName,
      headImg: basicProfile.picture,
      email: basicProfile.email ,
      googleToken: authResponse.id_token
    })
  }
}

class AppleUserModel {
  constructor(user, basicProfile, appleToken) {
    Object.assign(this, {
      email: user ? user.email : basicProfile.email,
      firstName: user ? user.name.firstName : null,
      lastName: user ? user.name.firstName : null,
      fbUserId: basicProfile.sub,
      fbAccessToken: appleToken
    })
  }
}

class UserModel {
  constructor(options, fbToken) {
    // 兼容api接口返回和APPBridge返回的字段结构
    Object.assign(this, {
      fbToken,
      accessToken: options.accessToken,
      refreshToken: options.refreshToken,

      id: options.userId || options.id,
      fbUserId: options.fbUserId,
      email: options.email,
      name: options.userName || options.name,
      firstName: options.firstName,
      lastName: options.lastName,
      image: {
        src: options.headImg || (options.image && options.image.src)
      },
      verifiedEmail: options.verifiedEmail,
      bindShopify: options.bindShopify,
    })
  }
}

class AnonymousUserModel {
  constructor(options) {
    Object.assign(this, {
      accessToken: options.accessToken,
      id: options.userId || options.id,
      email: options.email,
      name: options.userName || options.name,
      firstName: options.firstName,
      lastName: options.lastName,
      activeFlag: options.activeFlag
    })
  }
}

export {
  FBUserModel,
  GoogleUserModel,
  AppleUserModel,
  UserModel,
  AnonymousUserModel
}
