import { getRandomInt } from './numberUtils'

const HOST = 'flamingo.shop'
const APP_NAME = 'Flamingals by Flamingo Shop'
const HOME_URL   = `https://${HOST}/store/`

const genFullUrl = link => {
  if (/^\/\//.test(link)) {
    return `https:${link}`
  }

  if (/^\/store/.test(link)) {
    return `https://${HOST}${link}`
  }

  return link
}

/* ---------------------------------------------------- */
export const extractBannerFromDSL = activity => {
  let banner

  if (activity && activity.meta && activity.meta.banner) {
    banner = activity.meta.banner.image
  } else if (activity && activity.layout) {
    activity.layout.every(lineItem => {
      if (lineItem.is === 'Banner' && lineItem.options.items.length) {
        banner = lineItem.options.items[0].image
        return false
      }
      return true
    })
  }

  return banner
}

export const extractGridLinksFromDSL = activity => {
  const subLinks = []

  if (activity && activity.layout) {
    activity.layout.forEach(lineItem => {
      if (lineItem.is === 'Grid' && lineItem.options.items.length >= 4) {
        lineItem.options.items.forEach(item => {
          subLinks.push(item.link)
        })
      }
    })
  }

  return subLinks
}

export const extractMainProductsFromDsl = activity => {
  let products = []

  if (activity && activity.layout && activity.collections) {

    const collections = activity.collections

    activity.layout.every(lineItem => {
      if (lineItem.options) {
        if (lineItem.options.productQueue
          && lineItem.options.productQueue.collectionSource
          && lineItem.options.productQueue.collectionSource.handle
          && collections[lineItem.options.productQueue.collectionSource.handle]
        ) {
          const collection = collections[lineItem.options.productQueue.collectionSource.handle]

          if (collection.products) {
            products = collection.products
          }
        }

        if (lineItem.options.childOptions
          && lineItem.options.childOptions.productQueue
          && lineItem.options.childOptions.productQueue.collectionSource
          && lineItem.options.childOptions.productQueue.collectionSource.handle
          && collections[lineItem.options.childOptions.productQueue.collectionSource.handle]
        ) {
          const collection = collections[lineItem.options.childOptions.productQueue.collectionSource.handle]

          if (collection.products) {
            products = collection.products
          }
        }
      }

      return !products.length
    })
  }

  return products
}

export const extractProductCategory = (category = '') => {
  // example: Women Fashion::Jeans => Jeans
  return category.replace(/.*::/, '')
}

/* ---------------------------------------------------- */

export const forOrganization = () => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  url: HOME_URL,
  logo: 'https://static.flamingo.shop/resources/icon-192x192.png',
  email: 'mailto:support@flamingo.shop',
  slogan: 'Flamingals by Flamingo Shop. Shopping for fun.',
  name: APP_NAME,
  sameAs: [
    'https://www.facebook.com/official.flamingo.shop/',
    'https://www.instagram.com/official.flamingo.shop/'
  ]
})

export const forWebsite = () => ({
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  url: HOME_URL,
  potentialAction: {
    '@type': 'SearchAction',
    target: `https://${HOST}/store/search/?q={search_term_string}`,
    'query-input': 'required name=search_term_string'
  }
})

export const forItemListSummary = links => ({
  '@context':'https://schema.org',
  '@type':'ItemList',
  itemListElement: links.map((link, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    url: genFullUrl(link)
  }))
})

export const forProduct = (product,
  {
    description, comment, sku, offer = {}
  } = {}
) => {
  const images = product.images && product.images.length
    ? product.images
    : []

  const mainVariation = product.mainVariation || {}

  const jsonLD = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.title,
    image: images.map(({ src }) => src),
    description: description || product.description || product.title,
    sku: sku || mainVariation.sku,
    brand: 'Flamingals by Flamingo Shop',
    mpn: sku || mainVariation.sku,
  }

  if (comment && comment.count) {
    const bestReview = comment.comments[0]
    const bestStar = bestReview ? bestReview.star : comment.star
    const bestStarUserName = bestReview ? bestReview.user.name : 'Flamingals Customer'

    jsonLD.review = {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: bestStar,
      },
      author: {
        '@type': 'Person',
        name: bestStarUserName
      }
    }

    jsonLD.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: comment.star,
      reviewCount: comment.count
    }
  } else {
    const star = getRandomInt(40, 49) / 10
    const count = getRandomInt(8, 20)
    const userName = 'Flamingals Customer'

    jsonLD.review = {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: star,
      },
      author: {
        '@type': 'Person',
        name: userName
      }
    }

    jsonLD.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: star,
      reviewCount: count
    }
  }

  if (offer.disabled !== true) {
    const priceValidDate = new Date()
    // const priceValidUntil = `${now.getFullYear() + 1}-${now.getMonth() + 1}-${now.getDate()}`
    priceValidDate.setFullYear(priceValidDate.getFullYear() + 1)
    const priceValidUntil = priceValidDate.toISOString()

    let offerPrice = offer.price

    if (typeof offerPrice === 'undefined') {
      if (mainVariation.price) {
        offerPrice = mainVariation.price
      }
    }

    const offerUrl = genFullUrl(offer.url || `/store/product/${product.handle}`)

    jsonLD.offers = {
      '@type': 'Offer',
      url: offerUrl,
      priceCurrency: mainVariation.currencyCode,
      price: offerPrice,
      availability: 'https://schema.org/InStock',
      priceValidUntil
    }
  }

  return jsonLD
}

export const forBreadcrumb = (items = []) => {
  const breadcrumbs = [{
    name: APP_NAME,
    link: HOME_URL
  }].concat(items.map(({ name, title, link }) => {
    return {
      name: name || title,
      link: genFullUrl(link)
    }
  }))

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': breadcrumbs.map(({ name, link }, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      name,
      item: link
    }))
  }
}

export const collectionHandleKeyWordMap = {
  'activewear' : 'Activewear',
  'sports-activewear-top-sellers': 'Sports Leggings',
  'navi-yoga-sets': 'Yoga Sets',
  'active-shorts': 'shorts',
  'sports-bra': `Active Bras`,
  'active-tops': `Active Tops`,
  'joggers': 'Joggers',
  'active-shapewear': 'Active Shapwear',
  'yoga-activewear': 'Activewear for Yoga',
  'gym-activewear': 'Activewear for Gym',
  'running-activewear': 'Activewear for Running',
  'textured-activewear-collection': 'Textured Activewear',
  'print-activewear': 'Print Activewear',
  'basic-activewear': 'Basic Activewear',
  'plus-size-activewear': 'Plus Size Activewear',
  'tie-dye-activewear': 'Tie Dye Activewear',
  'activewear-with-pockets': 'Activewear with Pockets',

  'jeans' : 'Jeans',
  'jeans-highwaist': 'High Waist Jeans',
  'jeans-flare': 'Flare Jeans',
  'jeans-skinny-1127': 'Skinny Jeans',
  'jeans-rip': 'Ripped & Distressed Jeans',
  'denim-raw': 'Raw Hem Denim',
  'denim-booty': 'Booty Shaping Denim',
  'denim-stretch': 'Super Stretch Denim',

  'sets' : 'Two-Piece Sets',
  'short-sets': 'Short Sets',
  'pants-sets': 'Pants Sets',
  'skirt-sets': 'Skirt Sets',
  'tee-sets': 'Tee Sets',
  'tie-dye-sets': 'Tie Dye Sets',
  'graphic-sets': 'Graphic Sets',
  'solid-sets': 'Solid Sets',

  'jumpsuit' : 'Jumpsuits & Rompers',
  'jumpsuits-combination': 'Jumpsuits',
  'casual-jumpsuits': 'Casual Jumpsuits',
  'rompers-combination': 'Rompers',
  'going-out-jumpsuits': 'Going Out Jumpsuits',
  'vacation-jumpsuits': 'Vacation Jumpsuits',

  'loungewear' : 'Loungewear',
  'lounge-sets': 'Lounge Sets',
  'lounge-jumpsuits': 'Lounge Jumpsuits',
  'pajama-sets': 'Pajama Sets',
  'lingerie-sleepwear': 'Sexy Lingerie',
  'letter-print-loungewear': 'Letter Print Loungewear',

  'sexy-10' : 'Lingerie',
  'lingerie-sets-26': 'Lingerie Sets',
  'lingerie-shapewear': 'Shapewear',
  'lingerie-bodysuit': 'Bodysuits',
  'lingerie-babydoll-and-teddies': 'Babydoll And Teddies',
  'lingerie-lace': 'Lace Lingerie',
  'lingerie-satin': 'Satin Lingerie',
  'lingerie-cross': 'Criss Cross Lingerie',
  'red-lingerie': 'Red Sexy Lingerie',
  'black-lingerie': 'Black Sexy Lingerie',

  'swim' : 'Swimwear',
  'bottoms' : 'Bottoms',
  'bottoms-shorts': 'Shorts',
  'bottoms-pants': 'Pants',
  'bottoms-skirts': 'Skirts',
  'bottoms-tiedye': 'Tie Dye Bottoms',
  'bottoms-gray': 'Gray Bottoms',

  'tops-bodysuits' : 'Tops',
  'tees-2': 'T-shirts',
  'bodysuits-1': 'Bodysuits',
  'sweatshirts-and-hoodies': 'Sweatshirts and Hoodies',
  'blouses': 'Blouses',
  'ribbed-tops': 'Ribbed Tops',
  'graphic-tops': 'Graphic Tops',
  'crop-tops': 'Crop Tops',

  'dresses' : 'Dresses',
  'dress-maxi': 'Maxi Dresses',
  'dress-midi': 'Midi Dresses',
  'dress-mini': 'Mini Dresses',
  'dress-print': 'Print Dresses',
  'dress-tiedye': 'Tie Dye Dresses',

  'dress-casual': 'Casual Dresses',
  'dress-vacation': 'Vacation Dresses',
  'dress-party': 'Party Dresses',
  'dress-sequin': 'Sequin Dresses',

  'all-cases-homepage' : 'Cell Phone Cases',
  'phone-cases-homepage': 'iPhone Cases',
  'android-cases-homepage': 'Android Phone Cases'
}

export const longTailVerbFromCollectionFilter = (collectionHandle, queryOptions) => {

  const collectionHandleList = Object.keys(collectionHandleKeyWordMap)
  if (collectionHandleList.indexOf(collectionHandle) < 0 || !queryOptions) {
    return ''
  } else {
    const sizeList = queryOptions.size ? queryOptions.size.split(',') : []
    const colorList = queryOptions.color ? queryOptions.color.split(',') : []

    if (sizeList.length >= 2 || colorList.length >= 2) {
      return ''
    } else {
      return (sizeList.length ? `${sizeList[0]} `: '') +  (colorList.length ? `${colorList[0]} `: '')
    }
  }

}

export const getShippingInfo = ({ $site, $i18n }) => {
  if ($site) {
    const { currencySymbol, isCurrencySymbolPrefix, shippingFeeForSEO } = $site.getSiteInfo()

    return $i18n && $i18n.transl('core.cart.shipping.rule', {
      freeShippingFee: `${isCurrencySymbolPrefix ? `${currencySymbol}${shippingFeeForSEO}` : `${shippingFeeForSEO}${currencySymbol}`}`
    }) + '.'
  }

  return 'Free Shipping Over $69.'
}