
const DEFAULT_COUNTDOWN = {
  finish: true,
  // dd: '00',
  hh: '00',
  mm: '00',
  ss: '00'
}

const parseCountdown = timestamp => {

  const result = {
    ...DEFAULT_COUNTDOWN
  }

  if (timestamp < 1000) {
    return result
  }

  const seconds = timestamp / 1000

  const d = Math.floor(seconds / (60 * 60 * 24))
  let h = Math.floor(seconds / 3600 % 24)
  const m = Math.floor((seconds / 60 % 60))
  const s = Math.floor((seconds % 60))
  const ms = Math.floor((timestamp % 60000))
  const finish = (d <= 0 && h <= 0 && m <= 0 && s <= 0)

  if (d > 0) {
    h += d * 24
  }

  // result.dd = d < 0 ? '00' : d.toString()
  result.hh = h < 0 ? '00' : h >= 10 ? h.toString() : ('0' + h)
  result.mm = m < 0 ? '00' : m >= 10 ? m.toString() : ('0' + m)
  result.ss = s < 0 ? '00' : s >= 10 ? s.toString() : ('0' + s)
  result.ms = ms < 0 ? '0000' : ms.toString()
  result.finish = finish

  return result
}

export {
  parseCountdown,
  DEFAULT_COUNTDOWN
}