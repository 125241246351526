import React from 'react'

import styles from './Paragraph.module.css'

const Paragraph = props => {
  return (
    <>
      {props.title && (
        <h3 className={styles.title}>{props.title}</h3>
      )}
      {
        props.content && (
          <p className={styles.content}>{props.content}</p>
        )
      }
      {
        props.items && Array.isArray(props.items) && props.items.map(({ content }, i) => (
          <p key={i} className={styles.content}>{content}</p>
        ))
      }
    </>
  )
}

export default Paragraph