import React, { PureComponent } from 'react'

import BasePlugin from '../plugins/base/BasePlugin'

import i18next from 'i18next'
import { Trans, initReactI18next } from "react-i18next"


/* --------------------------------- */
class AsyncTrans extends PureComponent {

  state = {
    ready: false
  }

  componentDidMount() {
    this.props.init().then(ready => this.setState({
      ready
    }))
  }

  render() {
    return (
      <>
        {
          this.state.ready && <Trans i18nKey={this.props.formattedKey} {...this.props.otherProps}>{this.props.children}</Trans>
        }
      </>
    )
  }
}
export default class I18nPlugin extends BasePlugin {
  displayName = '$i18n'
  i18n = null
  $site = this.pluginHub.getSite()
  ready = false


  start() {
    this.init()
  }


  init = () => {
    if (this.ready) return Promise.resolve(true)

    const{ locale } = this.$site.getSiteInfo({
      pluginHub: this.pluginHub
    })
    const language = (locale || '').split('_')[0] || 'en'

    if (typeof this.options.resources[language] === 'function') {
      return this.options.resources[language]().then(loaded => {
        i18next
          .use(initReactI18next)
          .init({
            lng: language,
            fallbackLng: 'en',
            resources: {
              [language]: loaded
            }
          })
        this.ready = true
        return this.ready
      })
    } else {
      i18next
          .use(initReactI18next)
          .init({
            lng: language,
            fallbackLng: 'en',
            resources: this.options.resources
          })
      this.ready = true
      return Promise.resolve(true)
    }
  }

  transl = (key, params) => {
    if (!key) {
      return ''
    }

    const formattedKey = key.split('.').join(':')

    return i18next.t(formattedKey, params)
  }

  Trans = props => {
    const { i18nKey, children, ...otherProps } = props

    if (!i18nKey) {
      return children
    }

    const formattedKey = i18nKey.split('.').join(':')

    if (this.ready) {
      return <Trans i18nKey={formattedKey} {...otherProps}>{children}</Trans>
    }
    return <AsyncTrans init={this.init} formattedKey={formattedKey} otherProps={otherProps} children={children}></AsyncTrans>
  }


  /* ----------------------------------------------------------------- */
  injectProps = {
    $i18n: {
      transl: this.transl,
      Trans: this.Trans
    }
  }
}
