import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

const withWishAndPlugin = Component => {
  class WrapperComponent extends PureComponent {
    _isMounted = false

    state = {
      wishTotal: null
    }

    componentDidMount() {
      this._isMounted = true
      this.connectClient()
    }

    componentWillUnmount() {
      this.props.$store.unsubscribeWish(this.updateState)
    }

    updateState = ({
      wishTotal = this.state.wishTotal
    } = {}) => {
      this.setState({
        wishTotal
      })
    }

    connectClient = () => {
      this.props.$store.unsubscribeWish(this.updateState)
      this.props.$store.connectWishList()
        .then(wishModel => {
          this.props.$store.subscribeWish(this.updateState)
          this.updateState(wishModel)
        })
    }

    toggleItem = params => {
      return this.props.$store.toggleWishItem(params)
        .then(wishModel => {
          this.updateState(wishModel)
        })
    }

    render() {
      return <Component {...this.props} wishTotal={this.state.wishTotal} toggleItem={this.toggleItem} />
    }
  }
  return withPlugin(WrapperComponent)
}

export default withWishAndPlugin