const switchMode = {
  isMock: false
}
const domainConf = {
  DEV: 'https://mock.apipost.cn/app/mock/project/17fc29a2-ba84-4f2b-b0dc-a3d094b10b51'
}

const getMockDomain = () => {
  return switchMode.isMock && typeof window !=='undefined' && window.location && window.location.hostname === 'localhost'
    ? domainConf.DEV
    : false
}
const mockDomain = getMockDomain()

export default mockDomain
