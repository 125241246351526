import React, { useRef, useState, useEffect } from 'react'
import Image from '../../common/Image/Image'
import useFn from '../../../hooks/useFn'

import styles from './CollectionPCTags.module.css'
import cx from '../../../utils/className'

const CollectionTagsItemWidthAndMargin = 90

const CollectionPCTags = props => {
  const scrollRef = useRef()
  const [width, setWidth] = useState(null)
  const [size, setSize] = useState(null)
  const [current, setCurrent] = useState(0)
  const timerRef = useRef()

  const {
    list = []
  } = props

  const resizeHandler = useFn(() => {
    clearTimeout(timerRef.current)
    if (scrollRef && scrollRef.current && scrollRef.current.clientWidth) {
      const clientWidth = scrollRef.current.clientWidth
      timerRef.current = setTimeout(() => {
        setCurrent(0)
        setWidth(clientWidth)
        setSize(Math.floor(clientWidth / CollectionTagsItemWidthAndMargin))
      }, 500)
    }
  })

  useEffect(() => {
    resizeHandler()
  }, [resizeHandler])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', resizeHandler)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', resizeHandler)
      }
    }
  }, [resizeHandler])

  const disabledPrevBtn = current === 0
  const disabledNextBtn = (current + 1) * size >= list.length

  const handleNext = () => {
    if (!disabledNextBtn) {
      setCurrent(current + 1)
    }
  }

  const handlePrev = () => {
    if (!disabledPrevBtn) {
      setCurrent(current - 1)
    }
  }

  const singlePage = (list.length * CollectionTagsItemWidthAndMargin) > width

  return (
    <div className={cx(styles.sliderBox, singlePage && styles.singlePage)}>
      <div className={styles.list} ref={scrollRef}>
        {
          list.slice(current * size, (current + 1) * size).map((item, index) => (
            <div key={index} className={cx(styles.item)} onClick={() => props.onItemClick(item, index)}>
              <div className={styles.tagsImageBox}><Image lazy tiny src={item.img_url}></Image></div>
              <div className={styles.tagsName}>{item.name}</div>
            </div>
          ))
        }
      </div>
      {
        singlePage && size > 0 && (
          <div className={styles.arrowBox} style={{ left: `${CollectionTagsItemWidthAndMargin * size}px` }}>
            <div className={cx(styles.prevBtn, disabledPrevBtn && styles.disabled)} onClick={handlePrev}>
              <span>&#xe608;</span>
            </div>
            <div className={cx(styles.nextBtn, disabledNextBtn && styles.disabled)} onClick={handleNext}>
              <span>&#xe608;</span>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default CollectionPCTags