import React from 'react'
import { createPortal } from 'react-dom'

import Image from '../Image/Image'

import styles from './ShareHover.module.css'
import cx from '../../../utils/className'

const EVENT_CATEGORY = 'Share'

const ShareHover = props => {
  const targetRef = props.targetRef
  const eventCategory = props.eventCategory || EVENT_CATEGORY

  if (!targetRef) {
    return null
  }

  const boundingClientRect = targetRef.current && targetRef.current.getBoundingClientRect()
  const { x, y } = boundingClientRect || {}

  let classNames = [styles.wrapper]

  if (window.innerWidth - x < 200) {
    classNames.push(styles.toLeft)
  } else if (x < 200) {
    classNames.push(styles.toRight)
  }

  const handleClick = key => {
    switch (key) {
      case 'fb':
        props.onFbShare(props.shareInfo, eventCategory)
        break

      case 'messenger':
        props.onMessengerShare(props.shareInfo, eventCategory)
        break

      case 'twitter':
        props.onTwitterShare(props.shareInfo, eventCategory)
        break

      case 'whatsApp':
        props.onWhatsAppShare(props.shareInfo, eventCategory)
        break

      case 'pinterest':
        props.onPinterestShare(props.shareInfo, eventCategory)
        break

      case 'link':
        props.onCopy(props.shareInfo, eventCategory)
        break

      case 'more':
        props.onShareByNavigator(props.shareInfo, eventCategory)
        break

      default:
        break
    }
  }

  return (
    createPortal((
      <div className={cx(...classNames)} style={{ top: y + window.scrollY + targetRef.current.offsetHeight, left: x + (targetRef.current.offsetWidth)/2 }} onMouseOver={props.onMouseOver} onMouseLeave={props.onMouseLeave}>
        {
          props.shareList.filter(i => !i.onlyForMobile).map(item => (
            <div key={item.key} onClick={() => handleClick(item.key)}><Image placeholder={{ width: 1, height: 1 }} src={item.icon} /></div>
          ))
        }
        <div onClick={() => handleClick(props.shareLink.key)}><Image placeholder={{ width: 1, height: 1 }} src={props.shareLink.icon} /></div>
      </div>
    ), document.body)
  )
}

export default ShareHover