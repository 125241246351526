import React, { useState, useRef, useEffect } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import ProductService from '../../../services/ProductService'
import ProductSection from '../Product/ProductSection'
import throttle from 'lodash/throttle'
import Slider from '../../common/Slider/Slider'
import ProductCard from '../ProductCard/ProductCard'
import ProductCardPrice from '../ProductCard/ProductCardPrice'

import styles from './ProductGallery.module.css'
import cx from '../../../utils/className'
import QuickAddBtn from '../Product/QuickAddBtn'

const ProductGallery = props => {
  const { eventCategory, fetchFn, galleryTitle } = props
  const [list, setList] = useState([])
  const [page, setPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [sliderIndex, setSliderIndex] = useState(0)
  const [sourceHandle, setSourceHandle] = useState('')

  const sliderRef = useRef()

  const productService = new ProductService(props.$http)
  const isDesktop = props.$detector.isDesktop()
  const sliderSize = isDesktop ? 5 : 3

  useEffect(() => {
    fetchListData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (hasNextPage && (sliderIndex + sliderSize * 3 >= list.length)) {
      fetchListData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sliderIndex])

  const fetchListData = () => {
    if (!isFetching) {
      setIsFetching(true)
      return productService[fetchFn](props.productHandle, {
        pageSize: 20,
        page,
      }).then(data => {
        setIsFetching(false)
        const { pageInfo, products, handle } = data
        setPage(page + 1)
        setList([...list, ...products])
        setHasNextPage(pageInfo.hasNextPage)
        setSourceHandle(handle)
      }).catch(() => {
        setIsFetching(false)
        setList(list)
        setSourceHandle('')
      })
    }
    return Promise.resolve()
  }

  const renderList = () => {
    return list.map((item, index) => {
      let className
      if (isDesktop) {
        className = styles.card
      } else {
        className = styles.mobileCard
      }
      const cardClassName = isDesktop ? styles.productCard : styles.smallCard
      return (
        <div className={cx(styles.initCard, className)} key={item.product.id}>
          <ProductCard
            showFloatDiscount={!isDesktop}
            lazy={true}
            className={cardClassName}
            key={item.product.id}
            onClick={
              product => {
                props.$router.navigateToProductPage(product)
                props.$track.clickSubResourceLocator(`Product:${eventCategory}_${product.handle}`, {
                  category: props.productCategory,
                  index,
                  id: product.id,
                  handle: product.handle,
                  sourceHandle
                })
              }
            }
            {...item.product}
            renderColorSwatch={isDesktop ? null : () => null}
            renderPrice={(product) => {
              return <div className={cx(styles.price, isDesktop && styles.isDesktop)}>
                <ProductCardPrice
                  hideOriginalPrice={!isDesktop}
                  {...product}
                  salePriceClassName={!isDesktop && styles.discountPrice}
                />
                {
                  !isDesktop && <QuickAddBtn
                  product={product}
                  onClickAddCart={product => {
                    props.$track.clickProduct(product, index, true)

                    props.$track.clickSubResourceLocator(`Product:${eventCategory}_${product.handle}`, {
                      category: props.productCategory,
                      index,
                      id: product.id,
                      handle: product.handle,
                      sourceHandle
                    })
                }}/>
                }

              </div>
            }}

          />
        </div>
      )
    })
  }

  const slickPrev = () => {
    if (sliderRef && sliderRef.current && !isFetching) {
      sliderRef.current.slickPrev()
    }
  }

  const slickNext = () => {
    if (sliderRef && sliderRef.current && !isFetching) {
      sliderRef.current.slickNext()
    }
  }

  const onScrollCapture = throttle(() => {
    if (!(sliderRef && sliderRef.current)) {
      return
    }
    const { scrollLeft, clientWidth, scrollWidth } = sliderRef.current;
    // 提前3页加载后面的数据
    if (scrollLeft + clientWidth >= scrollWidth - sliderSize * 3 * 110 && hasNextPage) {
      fetchListData()
    }
  }, 500)

  const renderListComponent = () => {

    if (isDesktop) {
      return (
        <div>
          {
            sliderIndex > 0
            && <div className={cx(styles.pcSwiperButton, styles.pcLeftButton)} onClick={slickPrev} />
          }
          {
            (hasNextPage || (sliderIndex + sliderSize < list.length))
            && <div className={cx(styles.pcSwiperButton, styles.pcRightButton)} onClick={slickNext} />
          }
          <Slider
            slidesToShow={sliderSize}
            slidesToScroll={sliderSize}
            ref={sliderRef}
            className={styles.list}
            afterChange={x => setSliderIndex(x)}
            touchMove={!isDesktop}
          >
            {renderList()}
          </Slider>
        </div>
      )
    }

    return (
      <div className={styles.mobileList} >
        <div className={styles.box} ref={sliderRef} onScrollCapture={onScrollCapture}>
          {renderList()}
        </div>
      </div>
    )
  }

  return (
    list.length > 0
    &&
    <ProductSection sectionTitle={isDesktop && galleryTitle} boxClassName={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      {
        !isDesktop &&
        <div className={styles.header}>
          <h2 className={styles.title}>{galleryTitle}</h2>
        </div>
      }
      <div className={cx(styles.sliderBox, isDesktop && styles.isDesktop)}>
        {renderListComponent()}
      </div>
    </ProductSection>
  )

}

export default withPlugin(ProductGallery)