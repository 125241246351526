import React from 'react'

import FlexList from '../common/List/FlexList'
import MultiColumns from '../common/List/MultiColumns'
import ProductCardSkeleton from './ProductCardSkeleton'
import { withPlugin } from '@flamingo_tech/funkgo'
import Skeleton from './Skeleton'

import styles from './CollectionSkeleton.module.css'
import cx from '../../utils/className'

/* ------------------------- */
const PRODUCT_SKELETON_COUNT = 12

const CollectionSkeleton = props => {
  const items = new Array(PRODUCT_SKELETON_COUNT).fill({})
  const isDesktop = props.$detector.isDesktop()

  if (isDesktop) {
    return (
      <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
        <div className={styles.tagSkeleton} />
        <div className={styles.content}>
          <div className={styles.filterSkeleton} />
          <FlexList
            childIs={ProductCardSkeleton}
            childItems={items}
            className={styles.list}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      <div className={styles.filterSkeleton}></div>
      <div className={styles.header}>
        <div className={styles.tags}>
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
          <Skeleton type={Skeleton.TYPE.SMALL} className={styles.skeleton} />
        </div>
        <Skeleton type={Skeleton.TYPE.MEDIUM} className={cx(styles.skeleton, styles.count)} />
      </div>
      <MultiColumns
        childIs={ProductCardSkeleton}
        childItems={items}
        className={styles.list}
        columnClassName={styles.listColumn}
      />
    </div>
  )
}

export default withPlugin(CollectionSkeleton)
