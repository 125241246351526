import React from 'react'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import CartBuoy from '../../../components/Store/Cart/CartBuoy'

class CartBuoyContainer extends React.Component {
  handleToCartPage = () => {
    this.props.$track.event({ category: 'CartBuoy', action: `open_cart`, nonInteraction: false })
    if (!this.props.$detector.isApp()) {
      this.props.$router.navigate({
        name: 'Cart'
      })
    } else {
      this.props.$router.navigateToApp({
        scheme: '/cart'
      })
    }
  }

  render() {
    const { hideGlobalCart } = this.props
    if (hideGlobalCart) {
      return null
    }

    const {
      cart,
      ...restProps
    } = this.props

    return <CartBuoy {...restProps} cart={cart} onClick={this.handleToCartPage} />
  }
}

export default withCartAndPlugins(CartBuoyContainer)
