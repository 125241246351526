import React, { forwardRef } from 'react'

import { withLink } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import styles from './Link.module.css';

const Link = forwardRef((props, ref) => {
  const className = cx(styles.link, props.className, {
    [styles.block]: props.block
  })

  return (
    <a
      ref={ref}
      className={className}
      onClick={props.onClick}
      href={props.href}
      rel={props.rel}
      title={props.title}
      style={props.style}
    >{props.children}</a>
  )
})

Link.displayName = 'Link'

export default withLink(Link)