
import React from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import EventsModal from '../../common/EventsModal/EventsModal'
import { STATIC_PAGE_HANDLES } from '../../../containers/Common/StaticModalContainer'
import { withGlobalSetting } from '../../../hooks/withGlobalSettingEnabled'
import Button from '../../common/Button/Button'

import styles from './WheelOverlayPcCard.module.css'

const WheelOverlayPcCard = withGlobalSetting(withPlugin(props => {
  const { Trans } = props.$i18n
  const { couponTitle } = props.coupon || {}

  if (!props.coupon) {
    return null
  }

  return (
    <section className={styles.wrapper}>
      <form onSubmit={props.handleSubmit}>
        <div className={styles.header}>{props.$i18n.transl('core.promotion.wheel.wheelTitle')}</div>
        <div className={styles.couponTitle}>{props.$i18n.transl('core.promotion.wheel.subscribeAndGet')}</div>
        <div className={styles.couponName}>{couponTitle}</div>
        <div className={styles.line} />
        <div className={styles.freeShipping}>{props.$i18n.transl('core.product.commitment.freeShipping.title')}</div>
        <div className={styles.freeShippingRule}>
          {props.$i18n.transl('core.promotion.wheel.freeShippingRule', {
            displayFreeShippingFee: props.displayFreeShippingFee
          })}
        </div>
        <input type="email"
          className={styles.mainInput}
          value={props.email}
          placeholder={props.$i18n.transl('core.promotion.inputEmail')}
          onChange={props.onChange}
        />
        <Button
          className={styles.submitBtn}
          disabled={props.disabled}
          onClick={props.handleSubmit}>
          {props.$i18n.transl('core.login.register')}
        </Button>
        <div className={styles.privacyWrapper}>
          <Trans i18nKey='terms.agreeWithPrivacy'>
            By registering, you agree to our<EventsModal className={styles.underline} handle={STATIC_PAGE_HANDLES.PRIVACY_POLICY}> Privacy Policy </EventsModal>
          </Trans>
        </div>
      </form>
      <div className={styles.closeBtnWrapper} onClick={props.onClose}>
        <div className={styles.closeBtn}/>
      </div>
    </section>
  )
}), 'shipping')

export default WheelOverlayPcCard