import React from 'react'

import styles from './FreeGiftBuoy.module.css'

const FreeGiftBuoy = props => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.image} onClick={props.onClick}></div>
      <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
    </div>
  )
}

export default FreeGiftBuoy