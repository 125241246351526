import React, { useEffect, useRef, useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import withRegularCouponEnabled from '../../../hooks/withRegularCouponEnabled'
import { SUPPLEMENT_BUOY } from '../Coupon/SupplementWheel'
import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'
import Button from '../../common/Button/Button'
import withHandGuide from '../../../hooks/withHandGuide'
import useFn from '../../../hooks/useFn'
import HorizonCouponList from './HorizonCouponList'

import cx from '../../../utils/className'
import styles from './AtcCouponList.module.css'

const EVENT_CATEGORY = 'CartPreview'
const WithHandButton = withHandGuide(props => {
  return <Button {...props}>{props.$i18n.transl('core.cart.claimAll')}</Button>
})

const AtcCouponList = withRegularCouponEnabled(withPlugin(props => {
  const { coupon, runtimeData } = props
  const couponHub = props.$store.couponHub

  const collectionCouponStorage = props.$storage.create('collection_coupons', { strategy: 'SESSION' })
  const beginSupplementStorage = props.$storage.create('begin_supplement_wheel', { strategy: 'SESSION' }) // atc_coupon 开关
  const supplementWheelPassedStorage = props.$storage.create('supplement_wheel_passed_arr', { strategy: 'SESSION' }) // atc_coupon 缓存
  const cartAtcGuideStorage = props.$storage.create('cart_atc_guide', { strategy: 'SESSION' })
  const atcTakeFlagStorage = props.$storage.create('atc_take_flag') // atc coupon 领取的flag
  const queryAtcCouponListStorage = props.$storage.create('ads_atc_coupon_list', { strategy: 'SESSION' })

  let { is: SupplyElementType = [] } = supplementWheelPassedStorage.getItem() || {}
  const { email: userEmail } = props.$storage.create('an_lg_email').getItem({}) || {} // 用户本地邮箱

  const [showHand, setShowHand] = useState(cartAtcGuideStorage.getItem(true))
  const timerRef = useRef()

  const eventCategory = props.eventCategory || EVENT_CATEGORY

  const isDesktop = props.$detector.isDesktop()

  const handleCloseShowHand = useFn(() => {
    setShowHand(false)
    cartAtcGuideStorage.setItem(false)
  })

  useEffect(() => {
    timerRef.current = setTimeout(() => {
      handleCloseShowHand(false)
    }, 3000)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [handleCloseShowHand])

  // 获取缓存失败就拿 runtime 的动态值
  if (SupplyElementType.length === 0 && runtimeData.supplement_wheel_passed) {
    SupplyElementType = runtimeData.supplement_wheel_passed.is || []
  }


  const getATCCoupons = () => {
    const adsCouponList = queryAtcCouponListStorage.getItem()
    const coupons = adsCouponList && adsCouponList.length > 0 ? adsCouponList : coupon

    return coupons
  }

  const handleClickCoupon = () => {
    return new Promise(resolve => {
      props.$track.event({
        category: eventCategory,
        action: 'take_atc_coupon',
        nonInteraction: false
      })
      handleCloseShowHand(false)
      props.onTakeCard()
      couponHub.batchTake(getATCCoupons()) // 领券
        .then(() => {
          beginSupplementStorage.setItem(false) // 关闭加车弹弹弹的口子
          // close entry
          props.setRuntimeData('collection_coupons', {})
          collectionCouponStorage.setItem({})

          props.setRuntimeData('supplement_wheel_passed', {})
          supplementWheelPassedStorage.clear()
          atcTakeFlagStorage.setItem(true)
          props.$toast(props.$i18n.transl('core.promotion.wheel.luckyDay'))
          props.refreshCart()
          resolve()
        })
    })
  }

  const cardOptions = {
    className: styles.couponCard
  }

  if (!userEmail && SupplyElementType.includes(SUPPLEMENT_BUOY)) {

    return (
      <ImpressionObserver eventName='atc_coupon' eventId='atc_coupon'>
        <div className={styles.wrapper}>
          {
            showHand && !isDesktop && (
              <div
                className={styles.handCover}
                onClick={() => handleCloseShowHand(false)}
              ></div>
            )
          }
          <div className={cx(styles.body, props.wrapperClass, showHand && styles.coverTopOne)}>
            <div className={cx(styles.header, isDesktop && styles.isDesktop)}>
              <div className={styles.title}>{props.$i18n.transl('core.promotion.wheel.couponToTakeLower')}</div>
              <WithHandButton
                className={styles.btn}
                onClick={handleClickCoupon}
                showHand={showHand}
                handStyle={{ bottom: '-31px' }}
                $i18n={props.$i18n}
                withoutShade={true}
              ></WithHandButton>
            </div>
          <HorizonCouponList cardOptions={cardOptions} coupon={getATCCoupons()} />
          </div>
        </div>
      </ImpressionObserver>
    )
  }

  return null

}), 'addedToCartCoupons')

export default AtcCouponList