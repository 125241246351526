import React, { useContext } from 'react'

import ESProductList from '../ProductList/ESProductList'
import ProductSection from './ProductSection'
import { PluginContext } from '@flamingo_tech/funkgo'
import cx from '../../../utils/className'

import styles from './EmptyCartRecommendationsPage.module.css'

const FEATURED_COLLECTION = 'monthly-trend-es'

const EmptyCartRecommendationsPage = (props) => {
  const collectionHandle = props.ESCollectionHandle || FEATURED_COLLECTION
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  return (
    <ProductSection
      showSeparator
      sectionTitle='core.product.youMayAlsoLike'
      boxClassName={isDesktop && styles.box}
      className={cx(styles.headerSection, isDesktop ? styles.isDesktop : null)}
      separatorClassName={cx(styles.separator, isDesktop ? styles.isDesktop : null)}
    >
      <ESProductList
        pageSize={20}
        columns={isDesktop ? 6 : 2}
        collectionHandle={collectionHandle}
        productService={props.productService}
        className={styles.listWrapper}
        cardOptions={{
          onClickQuickAdd: product => {
            plugin.$track.clickSubResourceLocator(`Cart:EmptyPageYouMayAlsoLike_${product.handle}`)
          },
          onClick: product => {
            plugin.$track.clickSubResourceLocator(`Cart:EmptyPageYouMayAlsoLike_${product.handle}`)
            plugin.$router.navigate({
              name: 'Product',
              params: {
                handle: product.handle
              }
            })
          },
          needFetchFullAfterAddCart: true
        }}
    ></ESProductList>
    </ProductSection>
  )
}

export default EmptyCartRecommendationsPage
