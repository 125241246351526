import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import { parseCountdown } from './countdownUtils'
import cx from '../../../utils/className'

import styles from './Countdown.module.css'

const TYPE = {
  CLOCK: styles.clock,
  TEXT: styles.text
}

const Countdown = forwardRef(({ timestamp, type, children, onFinished, className, cardClassName, separatorClassName, background, color, separatorColor, displayHour, isPaused = false }, ref) => {
  const [countdown, setCountdown] = useState(timestamp)
  const [originalTimestamp, setOriginalTimestamp] = useState(timestamp)

  if (timestamp && originalTimestamp && timestamp !== originalTimestamp) {
    setCountdown(timestamp)
    setOriginalTimestamp(timestamp)
  }

  useImperativeHandle(ref, () => ({
    reset: (time) => {
      setCountdown(time)
    }
  }))

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isPaused) {
        setCountdown(countdown > 1000 ? countdown - 1000 : 0)
        if (countdown <= 0 && typeof onFinished === 'function') {
          onFinished()
        }
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [countdown, onFinished, isPaused])

  const { hh, mm, ss } = parseCountdown(countdown)

  if (typeof children === 'function') {
    return children({
      hh, mm, ss
    })
  }

  const clockSpanStyle = {}
  const clockSeparatorStyle = {}

  if (background) {
    clockSpanStyle.backgroundColor = background
    clockSeparatorStyle.color = background
  }

  if (color) {
    clockSpanStyle.color = color
  }

  if (separatorColor) {
    clockSeparatorStyle.color = separatorColor
  }


  const countdownList = displayHour ===  false ? [mm, ss] : [hh, mm, ss]

  return (
    <div className={cx(type || TYPE.CLOCK, className)}>
      {
        countdownList.map((item, index) => (
          <div className={styles.spanWrapper} key={index}>
            <span
              className={cx(styles.time, cardClassName)}
              style={clockSpanStyle}
            >{item}</span>
            {
              index !== countdownList.length - 1 &&
              <label
                className={cx(styles.separator, separatorClassName)}
                style={clockSeparatorStyle}
              >:</label>
            }
          </div>
        ))
      }
    </div>
  )
})

Countdown.TYPE = TYPE

const CountdownWithMemo = React.memo(Countdown)
CountdownWithMemo.TYPE = Countdown.TYPE

export default CountdownWithMemo
