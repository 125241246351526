import React, { useContext } from 'react'

import AppStateContext from '../../../hooks/AppStateContext'
import { PluginContext } from '@flamingo_tech/funkgo'
import { Link } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'

import styles from './SelectedCountrySite.module.css'
import cx from '../../../utils/className'

const SelectedCountrySite = props => {
  const appState = useContext(AppStateContext)
  const { site } = appState
  const { countryName, countryFlag, country } = site

  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    isDesktop ?
    <div className={cx(styles.countrySiteWrapper, props.className)}>
      <SelectedCountry
        {...props}
        countryName={countryName}
        countryFlag={countryFlag}
        country={country}
      />
    </div>
    :
    <Link to='SiteSetting'>
      <li className={props.liStyle}>
        <SelectedCountry
          {...props}
          countryName={countryName}
          countryFlag={countryFlag}
          country={country}
        />
      </li>
    </Link>
  )
}

const SelectedCountry = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <>
      {
        isDesktop
        ?
        <label>{props.country}</label>
        :
        <>
          <Image src={props.countryFlag} small className={cx(styles.countryIcon, props.className)}/>
          <label>{props.countryName}</label>
        </>
      }
    </>
  )
}

export default SelectedCountrySite