import React from 'react'

import styles from './CartSizePicker.module.css'
import cx from '../../../utils/className'

import Button from '../../common/Button/Button'
import ColorPicker from '../../common/ColorPicker/ColorPicker'

import withQuickAdd from '../../../hooks/withQuickAdd'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'

const isEqual = (options1, options2) => {
  const options1Value1 = options1.map(i => i.value).join('/')
  const options1Value2 = options2.map(i => i.value).join('/')
  return options1Value1 === options1Value2
}

const generateVariantWithoutChosen = (selectedVariants, variants) => {
  return variants.filter(variant => {
    return !selectedVariants[variant.name]
  })[0]
}

const CartSizePicker = props => {
  const { selectedOptions = [] } = props.product || {}
  const originSelectedVariant = {}

  selectedOptions.forEach(item => {
    originSelectedVariant[item.name] = item.value
  })

  const handleSpecSelectionUpdateConfirm = ({ variant, selectedVariantInfo, product }) => {
    return new Promise((resolve, reject) => {

      if (!variant) {
        props.$toastWarning({
          content: props.$i18n.transl('core.product.chooseOption', {
            option: generateVariantWithoutChosen(selectedVariantInfo, product.options).displayName
          }),
          dialogId: 'product-update'
        })
        return reject()
      }

      const isEqualVariant = isEqual(variant.selectedOptions, selectedOptions)

      if (isEqualVariant) {
        props.resetState()
        resolve()
        return
      }
      props.$replaceVariant({ variant: variant, itemId: props.product.itemId })
        .then(() => {
          const { title } = props.product
          props.$track.event('CartSizePicker', 'quick_replace_product', title)
          props.resetState()
          resolve()
        })
        .catch(error => {
          props.$toastError(error.message)
          reject()
        })
    })
  }

  const renderUpdateButton = ({ variant, selectedVariantInfo, product }) => {
    return (
      <Button
        onClick={() => handleSpecSelectionUpdateConfirm({ variant, selectedVariantInfo, product })}
        className={cx(styles.addToCartBtn)}>
        {props.$i18n.transl('common.update')}
      </Button>
    )
  }

  const handleQuickAdd = () => {
    if (props.disabled) {
      return
    }
    props.onQuickAdd({
      product: props.product,
      selectedVariantInfo: originSelectedVariant,
      renderButton: renderUpdateButton,
      scrollToSelectedSize: true
    })
  }

  const { value } = selectedOptions[1]

  return (
    <div
      className={cx(styles.sizePicker, props.isDesktop && styles.pc, props.className)}
      onClick={handleQuickAdd}
    >
      {
        Object.keys(props.colorSwatch).length === 0
          ? <span className={styles.sizeValue}>{props.value}</span>
          : (
            <>
              <ColorPicker active={false} colorSwatchItem={props.colorSwatch} imageClass={cx(styles.imageClass, props.colorPickerImageClass)} className={styles.colorPicker} />
              <span className={styles.sizeValue}>/ {value}</span>
            </>
          )
      }
      {
        !props.disabled && <span className={styles.sizePickerIcon}>&#xe7b2;</span>
      }
    </div>
  )
}

export default withQuickAdd(withCartAndPlugins(CartSizePicker))