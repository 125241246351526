import React, { useContext, useState, forwardRef } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import ProductSection from './ProductSection'
import Link from '../../common/Link/Link'
import { PCProductCommentCard } from './ProductCommentCard'
import Pagination from "react-js-pagination"
import CommentImagePreviewer from './CommentImagePreviewer'
import LoadingModal from '../../common/LoadingPage/LoadingModal'
import ProductCommentSectionHeader from './ProductCommentSectionHeader'
import ErrorPage, { NotFoundPage } from "../../common/ErrorPage/ErrorPage"

import { generateCommentImages } from '../../../utils/Store/commentUtils'

import styles from './ProductCommentSection.module.css'
import cx from '../../../utils/className'

const ProductCommentSection = (props, ref) => {
  const [pageNum, setPageNum] = useState(props.commentPageNo)
  const [showCommentImages, setShowCommentImages] = useState(false)
  const [commentImageIndex, setCommentImageIndex] = useState(0)

  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  const { product, comment } = props

  ref.current = setPageNum

  const commentImages = generateCommentImages(comment.comments)

  const handlePageChange = pageNum => {
    setPageNum(pageNum)
    props.onPageChange(pageNum)
  }

  const handleClickCommentImage = image => {
    let result = 0

    for (let i = 0; i < commentImages.length; i++) {
      if (commentImages[i].src ===  image) {
        result = i
        break
      }
    }
    setShowCommentImages(true)
    setCommentImageIndex(result)
  }

  const showComments = comment.comments.map((item) => {
    return (
      <PCProductCommentCard
        key={item.id}
        showAvaterName
        {...item}
        onClickCommentImage={handleClickCommentImage}
      />
    )
  })

  const handleClickAddToCart = params => {
    plugin.$track.event('Product', 'click_review_cart', product.handle)

    props.onAddToCartClick(params)
  }

  const reviewTitle = plugin.$i18n.transl('core.product.reviews', { count: comment.count})

  return (
    <ProductSection boxClassName={cx(styles.productSectionBox,  isDesktop && styles.inDesktop)}>
      <div className={cx(styles.wrapper, isDesktop && styles.inDesktop)} id={'ProductCommentSection'}>
        <div className={styles.comment}>
          {
            !isDesktop ?
            <Link to={{ name: 'ProductComment', params: { handle: product.handle }}}>
              <div className={styles.header}>
                <h2 className={styles.title}>{ reviewTitle }</h2>
              </div>
            </Link>
            :
            <>
              {
              comment.showCommentSection &&
              <div className={cx(styles.header, isDesktop && styles.inDesktop)}>
              <h2 className={styles.title}>{ plugin.$i18n.transl('core.product.reviewsText') }</h2>
                <ProductCommentSectionHeader {...props} />
              </div>
              }
            </>
          }
          {
            comment.showCommentSection &&
            <>
              {
                 comment.comments.length > 0 ?
                 <div className={styles.commentsWrapper}>
                   <div className={styles.content}>
                     {showComments}
                   </div>
                 </div> :
                 (!props.isFetchingComments ?
                   <NotFoundPage
                     error={null}
                     title={plugin.$i18n.transl('core.product.noResultCommit')}
                     btn={null}
                     type={ErrorPage.TYPE.NO_SEARCH_RES}
                     className={styles.noSearchRes}
                   ></NotFoundPage>
                   :
                   <div className={styles.blank} />
                  )
              }
            </>
          }
          <LoadingModal hide={!props.isFetchingComments}></LoadingModal>
        </div>
        {
          isDesktop && comment.count > 0 &&
          <Pagination
            hideFirstLastPages
            itemsCountPerPage={4}
            totalItemsCount={comment.count}
            activePage={pageNum}
            onChange={handlePageChange}
            innerClass={styles.pagination}
            activeClass={styles.active}
            itemClass={styles.paginationItem}
            itemClassPrev={styles.prevItem}
            itemClassNext={styles.nextItem}
            prevPageText=""
            nextPageText=""
          />
        }
      </div>
      {
        showCommentImages &&
        <CommentImagePreviewer
          total={commentImages.length}
          onClose={() => setShowCommentImages(false)}
          index={commentImageIndex}
          items={commentImages}
          onReachEnd={() => {}}
          product={props.product}
          $i18n={plugin.$i18n}
          isDesktop={true}
          onAddToCartClick={handleClickAddToCart}
        />
      }
    </ProductSection>
  )
}

export default forwardRef(ProductCommentSection)
