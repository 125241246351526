import React from 'react'

import TabPanel from '../common/Tab/TabPanel'

const getProductListTitle = props => (
  props.title || props.childOptions.title || 'Products'
)

const LayoutTab = ({ layoutItems, root, meta }) => {

  const topItems = []
  const panelItems = []

  layoutItems.forEach(item => {
    if (item.meta.isProductList) {
      panelItems.push(item)
    } else {
      topItems.push(item)
    }
  })

  const topElements = topItems.map(({ Component, props }, i) => (
    <Component {...props} key={i}></Component>
  ))

  const tabs = panelItems.map(({ props}, i) => ({
    key: i,
    title: getProductListTitle(props)
  }))

  const renderPanel = ({ key }) => {
    return panelItems.map(({ Component, props }, i) => {
      const ProductListComponent = Component
      return (
        <ProductListComponent {...props} readyToFetch={i === key} key={i}></ProductListComponent>
      )
    })
  }

  const style = root && root.background
    ? {
      backgroundColor: root.background
    } : null

  return (
    <div style={style}>
      {topElements}
      <TabPanel tabs={tabs} renderPanel={renderPanel} {...meta.tabOptions}></TabPanel>
    </div>
  )
}

export default LayoutTab
