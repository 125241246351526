import React from 'react'

import styles from './PageTitle.module.css'

const PageTitle = props => {
  const title = props.title || props.content

  return (
    <>
      {
        title && (
          <h2 className={styles.title}>{title}</h2>
        )
      }
    </>
  )
}

export default PageTitle