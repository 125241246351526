import React from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './HorizontalImageText.module.css'
import Image from '../Image/Image'
import Link from '../Link/Link'
import cx from '../../../utils/className'

const LinkWrapper = props => {

  return (Link.hasLinkProps(props) && !props.layout)
  ? <Link {...props}>{props.children}</Link>
  : <span {...props}>{props.children}</span>
}

const HorizontalImageText = props => {
  const {
    item,
    textStyle
  } = props

  const {
    title,
    link,
    layout,
    onClick,
    ...imageProps
  } = item || {}

  const linkProps = {
    title,
    link,
    layout,
    onClick
  }

  return (
    <LinkWrapper className={styles.wrapper} {...linkProps}>
      {title && <span className={cx(styles.text, textStyle)}>{title}</span>}
      <Image
        className={cx(styles.image, imageProps.height < 300 && styles.imageSmall)}
        {...imageProps}
        title={title || 'Category'}
        lazy
      />
    </LinkWrapper>
  )
}

export default withPlugin(HorizontalImageText)