import React, { useEffect } from 'react'

import Button from '../../common/Button/PlainButton'
import cx from '../../../utils/className'
import styles from './AppHeader.module.css'


const AppHeader = props => {
  const {
    displayHeader = true,
    floatingHeader = false,
    transparentHeader = false,
    displayBack = true,
    displayHome = true,
    displayCart = true,
    displayShare = true,
    renderButton,
    cartLineItemCount = 0,
    onCartClick,
    onShareClick,
    onHomeClick,
    onBackClick,
    headerTitle
  } = props

  useEffect(() => {
    if (displayHeader && transparentHeader) {
      window.addEventListener('scroll', scrollHandler)
    }

    return () => {
      if (displayHeader && transparentHeader) {
        window.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [displayHeader, transparentHeader])

  const scrollHandler = () => {
      const header = document.getElementById('appHeader')
      if (header) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        var alpha = scrollTop > 130 ? 1 : scrollTop / 130
        header.style.setProperty('background-color', `rgba(255, 255, 255, ${alpha})`)
      }
  }

  if (displayHeader === false) {
    return null
  }

  return (
    <div id='appHeader' className={cx(styles.wrapper, floatingHeader && styles.floating, transparentHeader && styles.transparent)}>
      <div className={styles.left}>
        {
          displayBack && (
            <Button className={styles.back} onClick={onBackClick} ></Button>
          )
        }
      </div>
      {
        headerTitle
        &&
        <div className={styles.title}>{headerTitle}</div>
      }
      <div className={styles.right}>
        {
          renderButton && typeof renderButton === 'function' && renderButton()
        }
        {
          displayHome && (
            <Button
              className={styles.home}
              onClick={onHomeClick}
            ></Button>
          )
        }
        {
          displayCart && (
            <Button className={styles.cart} onClick={onCartClick}>
              {
                cartLineItemCount > 0 && (
                  <div className={styles.count}>{cartLineItemCount}</div>
                )
              }
            </Button>
          )
        }
        {
          displayShare && (
            <Button
              className={styles.share}
              onClick={onShareClick}
            ></Button>
          )
        }
      </div>
    </div>
  )
}

export default AppHeader
