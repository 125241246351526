const requestWithFallback = (request, fallback, useFallbackDirectly = false) => {
  if (useFallbackDirectly) {
    return fallback()
  }

  return request().catch(fallback)
}

const doRetry = (action, onError, maxCount, currentCount, errorRetryPattern) => {
  return action().catch(error => {
    if (currentCount < maxCount) {
      if (typeof onError === 'function') {
        onError({
          error,
          isFatal: false,
          maxCount,
          currentCount
        })
      }

      const errorMsg = error.message || JSON.stringify(error)

      // if no specific pattern, or error msg is match given pattern, then retry
      if (!errorRetryPattern || errorRetryPattern.test(errorMsg)) {
        return doRetry(action, onError, maxCount, currentCount + 1, errorRetryPattern)
      }

      throw error
    } else {
      throw error
    }
  })
}

const retry = (action, onError, maxCount = 3, errorRetryPattern) => {
  return doRetry(action, onError, maxCount, 1, errorRetryPattern)
}

const TIMEOUT_ERROR_PATTERN = /(timeout)|(network)/i

const retryIfTimeout = (action, maxCount = 2, onError) => (
  retry(action, onError, maxCount, TIMEOUT_ERROR_PATTERN)
)

const emptyVal = ['', undefined, null]
const getApiPathWithSortedQuery = (basePath, sortedQuery, queryParams) => {

  let sortedQueryStr = ''
  sortedQuery.forEach((queryItem, index) => {
    if (queryParams && !emptyVal.includes(queryParams[queryItem])) {
      if (Array.isArray(queryParams[queryItem])) {
        if (queryParams[queryItem].length) {
          sortedQueryStr += `${index !== 0 ? '&' : ''}${queryItem}=${queryParams[queryItem].join(',')}`
        }
      } else {
        sortedQueryStr += `${index !== 0 ? '&' : ''}${queryItem}=${queryParams[queryItem]}`
      }
    }
  })
  
  return sortedQueryStr ? `${basePath}?${sortedQueryStr}` : basePath
}

export {
  retryIfTimeout,
  requestWithFallback,
  retry,
  getApiPathWithSortedQuery
}
