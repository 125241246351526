import Product, { ProductVariant } from '../../npm/FunkGoModel/ProductModel'

class LineItemModel {
  constructor(data) {
    const mainImage = {
      src: data.mainImage,
      title: null
    }

    const productVariantMeta = {
      id: data.skuId,
      title: data.skuTitle,
      sku: data.sku,
      msrp: data.msrp,
      price: data.price,
      image: mainImage,
      currencyCode: data.currencyCode,
      // TODO
      selectedOptions: [],

      availableForSale: data.saleable
    }

    const productMeta = {
      id: data.productId,
      handle: data.productHandle,


      variations: [
        productVariantMeta
      ],

      images: [
        mainImage
      ],

      // TODO
      options: [],

      title: data.skuName,
      description: '',

      vendor: data.vendor,
      category: data.categoryName,
      availableForSale: data.saleable
    }


    this.productVariant = new ProductVariant(productVariantMeta)
    this.product = new Product(productMeta)
    this.quantity = data.quantity
    this.totalPrice = data.itemTotalFee
  }
}

export default LineItemModel