import React from 'react'

import withNaviItems from '../../../hooks/withNaviItems'

import DesktopPageShell from '../../../components/Shell/Page/DesktopPageShell'

const DesktopLayoutContainer = props => {
  return <DesktopPageShell
    naviItems={props.naviItems}
    {...props}
  >
    {props.children}
  </DesktopPageShell>
}

// since this page will be used as sub container, so it need to add plugin by itself
export default withNaviItems(DesktopLayoutContainer)
