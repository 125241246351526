import React from 'react'

import styles from './LanguageSelector.module.css'

const LanguageSelector = props => {

  const { currentStation } = props

  return (
    <>
      <div className={styles.section}>
        <h4>{props.$i18n.transl('shell.header.siteSetting.language')}</h4>
        <h5>
          <ul className={styles.languageSelect}>
            {
              currentStation.languages.map(language => {
                return (
                  <li key={language.name}>{language.name}</li>
                )
              })
            }
          </ul>
        </h5>
      </div>
      <div className={styles.section}>
        <h4>{props.$i18n.transl('shell.header.siteSetting.currency')}</h4>
        <h5 className={styles.currency}>{currentStation.displayCurrency}</h5>
      </div>
    </>
  )
}

export default LanguageSelector