import ReactDOM from 'react-dom'

/**
 * js抛物线动画
 * @param  {[object]} origin [起点元素]
 * @param  {[object]} target [目标点元素]
 * @param  {[object]} element [要运动的元素]
 * @param  {[number]} radian [抛物线弧度]
 * @param  {[number]} time [动画执行时间]
 * @param  {[function]} callback [抛物线执行完成后回调]
 */



const createRunningElement = (element) => {
  const runningElement = document.getElementById('atc_running_ele')

  if (!runningElement) {
    let root = document.createElement('div')
    root.setAttribute('id', 'atc_running_ele')

    document.body.appendChild(root)
    ReactDOM.render(element, root)

    return document.getElementById('atc_running_ele').firstChild
  } else {
    return runningElement.firstChild
  }
}

 class Parabola {
  constructor(config) {
      this.b = 0
      this.INTERVAL = 15
      this.timer = null
      this.config = config || {}

      this.withShake = this.config.withShake || false
      // 起点
      this.origin = this.config.origin || null
      // 终点
      this.target = this.config.target || null

      if (this.origin && this.target) {
        // 运动的元素
        this.element = createRunningElement(this.config.element) || null
        // 曲线弧度
        this.radian = this.config.radian || 0.004
        // 运动时间(ms)
        this.time = this.config.time || 1000

        const { pageXOffset, pageYOffset } = window

        const originBoundingRect = this.origin.getBoundingClientRect()
        const targetBoundingRect = this.target.getBoundingClientRect()

        this.originX = originBoundingRect.left + originBoundingRect.width / 2 + pageXOffset
        this.originY = originBoundingRect.top + pageYOffset
        this.targetX = targetBoundingRect.left + targetBoundingRect.width / 2 + pageXOffset
        this.targetY = targetBoundingRect.top + pageYOffset

        this.diffx = this.targetX - this.originX
        this.diffy = this.targetY - this.originY
        this.speedx = this.diffx / this.time

        // 已知a, 根据抛物线函数 y = a*x*x + b*x + c 将抛物线起点平移到坐标原点[0, 0]，终点随之平移，那么抛物线经过原点[0, 0] 得出c = 0
        // 终点平移后得出：y2-y1 = a*(x2 - x1)*(x2 - x1) + b*(x2 - x1)
        // 即 diffy = a*diffx*diffx + b*diffx
        // 可求出常数b的值
        this.b =
            (this.diffy - this.radian * this.diffx * this.diffx) / this.diffx

        this.element.style.left = `${this.originX}px`
        this.element.style.top = `${this.originY}px`
      }
  }

  // 确定动画方式
  moveStyle() {
      let moveStyle = 'position',
          testDiv = document.createElement('input')
      if ('placeholder' in testDiv) {
          ['', 'ms', 'moz', 'webkit'].forEach(function(pre) {
              var transform = pre + (pre ? 'T' : 't') + 'ransform'
              if (transform in testDiv.style) {
                  moveStyle = transform
              }
          })
      }
      return moveStyle
  }

  move() {
    if (this.origin && this.target) {
      const targetDom = this.target
      const withShake = this.withShake

      let start = new Date().getTime(),
      moveStyle = this.moveStyle(),
      _this = this

      if (this.timer) return
      this.element.style.display = 'block'
      this.element.style.left = `${this.originX}px`
      this.element.style.top = `${this.originY}px`
      this.element.style[moveStyle] = 'translate(0px,0px)'

      this.timer = setInterval(function() {
          if (new Date().getTime() - start > _this.time) {
              _this.element.style.left = `${_this.targetX}px`
              _this.element.style.top = `${_this.targetY}px`
              _this.element.style.display = 'none'
              typeof _this.config.callback === 'function' &&
                  _this.config.callback()
              clearInterval(_this.timer)
              _this.timer = null

              if (withShake) {
                targetDom.animate([{ transform: "rotate(0)" }, { transform: "rotate(-10deg)" }, { transform: "rotate(10deg)" }, { transform: "rotate(0)" }], {
                    duration: 200,
                    iterations: 2
                })
              }
              return
          }
          let x = _this.speedx * (new Date().getTime() - start)
          let y = _this.radian * x * x + _this.b * x
          if (moveStyle === 'position') {
              _this.element.style.left = `${x + _this.originX}px`
              _this.element.style.top = `${y + _this.originY}px`
          } else {
              if (window.requestAnimationFrame) {
                  window.requestAnimationFrame(() => {
                      _this.element.style[moveStyle] =
                          'translate(' + x + 'px,' + y + 'px)'
                  })
              } else {
                  _this.element.style[moveStyle] =
                      'translate(' + x + 'px,' + y + 'px)'
              }
          }
        }, this.INTERVAL)
      }

      return this
  }
}

export default Parabola