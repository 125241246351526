import React, { useRef } from 'react'
import Button from '../../common/Button/Button'

// import { OutApp } from '../../../hooks/InApp'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
// import ShippingFeeProgress from './ShippingFeeProgress'

import cx from '../../../utils/className'

import styles from './ProductFooterBarWithProgress.module.css'

const ProductFooterBarWithProgress = props => {

  const atcButtonRef = useRef()

  const renderButtonElement = () => {
    if (typeof props.renderButton === 'function') {
      return props.renderButton()
    }

    return (
      <Button
        className={cx(styles.addToCartBtn, !props.availableForSale && styles.reminderStyle)}
        onClick={() => props.onAddToCartClick({ triggerRef: atcButtonRef })}
        ref={atcButtonRef}
      >{props.availableForSale ? props.$i18n.transl('core.product.addToCart') : props.$i18n.transl('core.product.outOfStock')}</Button>
    )
  }

  return (
    <div className={cx(styles.wrapper, props.className)}>
      {
        props.renderWishButton && props.renderWishButton()
      }

      {/* <OutApp>
        <ShippingFeeProgress {...props} {...props.cart} onClick={props.handleToCart} direction={ShippingFeeProgress.DIRECTION.VERTICAL}/>
      </OutApp> */}
      {renderButtonElement()}
    </div>
  )
}

export default withCartAndPlugins(ProductFooterBarWithProgress)
