import React, { useContext } from 'react'

import ProductSection from './ProductSection'
import ProductPhotoSwipe from './ProductPhotoSwipe'
import ProductSpecSection from './ProductSpecSection'
import StarRating from './StarRating'
import { FlashDiscountRow } from '../ProductCard/FlashDiscount'
import Link from '../../common/Link/Link'
import KlarnaBar from '../CheckOut/KlarnaBar'
import CouponTagBar from '../Coupon/CouponTagBar'

import { PluginContext } from '@flamingo_tech/funkgo'
import { getDiscountText } from '../../../utils/priceUtils'

import cx from '../../../utils/className'

import styles from './ProductBasicInfoSection.module.css'

const ProductBasicInfoSection = props => {

  const {
    product,
    comment = {},
    curImages,
    variantInfo = {},
    curImageIndex,
    selectedVariants,
    renderPromotionBar,
    renderPrice,
    minSliderWrapper,
    hideVideo,
  } = props

  const onVariantChanged = (currentVariants) => {
    props.onOptionSelectedChanged(currentVariants)
  }

  const renderSaleElement = (render) => {
    if (typeof render === 'function') {
      return render(product)
    }

    return (
      null
    )
  }

  const plugin = useContext(PluginContext)
  const isApp = plugin.$detector.isApp()

  const renderPriceElement = (render, renderArgs) => {

    if (typeof render === 'function') {
      return render(renderArgs)
    }

    const handleShare = () => {
      const site = plugin.$site.getSiteInfo()

      plugin.$bridge.multiShare({
        shareInfo: {
          title: `${product.title} | ${site.fullSiteName}`,
          image: product.mainImage ? product.mainImage.src : null,
          url: window.location.href
        },
        eventCategory: 'ProductPage'
      })
    }

    return (
      <div className={styles.titleSection}>
         <div className={styles.section}>
          <div className={styles.firstRow}>
            <div className={styles.priceSection}>
              <div className={cx(styles.salePrice, hasOriginalPrice && styles.highlighted)}>{renderArgs.displayPrice}</div>
              {
                hasOriginalPrice
                  ? <div className={styles.originalPriceWrapper}><span className={styles.originalPrice}>{renderArgs.displayMsrp}</span></div>
                  : null
              }
              {
                hasOriginalPrice
                  ? <span className={styles.discount}>{getDiscountText(price, msrp)}</span>
                  : null
              }
            </div>
            {
              !!comment.overallCount &&
              <div className={styles.reviewRating} onClick={() => renderArgs.onOpenReviewsContent(true)}>
                <div className={styles.starRating}><StarRating star={comment.star} /></div>
                <div className={styles.starRatingDesc}>
                  <span>{comment.star}</span>
                  <span>({comment.overallCount})</span>
                </div>
              </div>
            }
          </div>
          {
            product.isFlashSale && (
              <FlashDiscountRow
                displaySaveFee={renderArgs.displaySaveFee}
                price={renderArgs.price}
                msrp={renderArgs.msrp}
                $i18n={plugin.$i18n}
                isDesktop={false}
              />
            )
          }
          {
            !renderArgs.minSliderWrapper && <KlarnaBar total={renderArgs.price} className={styles.klarna} />
          }
          {
            product.meta && product.meta.feature && product.meta.feature.promotionTag && <div className={styles.promotionTag}>{product.meta.feature.promotionTag}</div>
          }
        </div>
        <div className={styles.titleWrapper}>
          <h1 className={styles.title}>
            {product.isNew && <span className={styles.newTag}>{plugin.$i18n.transl('core.product.new')}</span>}
            {renderArgs.product.title}
          </h1>
          <div className={styles.iconBox}>
            {
              !isApp && !renderArgs.minSliderWrapper && <div className={styles.share} onClick={handleShare}></div>
            }
          </div>
        </div>
      </div>
    )
  }

  const renderLinkElement = (renderLink) => {
    if (typeof renderLink === 'function') {
      return renderLink()
    }

    return () => (
      <Link to={props.to || { name: 'Product', params: { handle: props.handle } }}><div>{plugin.$i18n.transl('core.cart.shipping.details')}<span>&#xe608;</span></div></Link>
    )
  }

  const { msrp, price, displayPrice, displayMsrp, displaySaveFee } = variantInfo || {}
  const hasOriginalPrice = msrp && msrp > price
  const imageItems = hideVideo ? curImages.filter(item => item.type !== 'VIDEO') : curImages

  return (
    <ProductSection>
      <div className={styles.wrapper}>
        <ProductPhotoSwipe items={imageItems} selectedVariants={selectedVariants} curImageIndex={curImageIndex} product={product} minSliderWrapper={minSliderWrapper} />
        {renderSaleElement(renderPromotionBar)}
        {
          renderPriceElement(
            renderPrice,
            {
              price: price,
              product: product,
              msrp: msrp,
              displayPrice: displayPrice,
              displayMsrp: displayMsrp,
              displaySaveFee: displaySaveFee,
              hasOriginalPrice: hasOriginalPrice,
              comment: comment,
              minSliderWrapper: minSliderWrapper,
              to: props.to,
              handle: props.handle,
              onOpenReviewsContent: props.onOpenReviewsContent
            }
          )
        }
        {/* {
          product.isFlashSale && <div className={styles.purchaseLimit}>{plugin.$i18n.transl('core.promotion.purchaseLimit')}</div>
        } */}
        <CouponTagBar />
        {
          product.availableForSale && (
            <ProductSpecSection
              renderSizeLink={props.renderSizeLink}
              items={imageItems}
              product={product}
              selectedVariants={selectedVariants}
              onVariantChanged={onVariantChanged}
              showSizeChartModal={props.showSizeChartModal}
              sizeChartTemplate={props.sizeChartTemplate}
              renderLink={minSliderWrapper && renderLinkElement(props.renderLink)}
            />
          )
        }
      </div>
    </ProductSection>
  )
}

export default ProductBasicInfoSection
