import React from 'react'

import Link from '../common/Link/Link'
import Image from '../common/Image/Image'
import { TRACK_CHANNEL } from '../../utils/Store/productUtils'

import cx from '../../utils/className'
import styles from './ProductLink.module.css'


const ProductLink = props => {
  const { disabled } = props

  const handleLinkClick = ev => {
    if (disabled) {
      ev.preventDefault()
      return false
    }
  }

  const hasOriginalPrice = props.originalPrice && props.originalPrice > props.price

  return (
    <Link
      to={{ name: 'Product', params: { handle: props.handle }, search: {[TRACK_CHANNEL]: 'GirlsShow:Detail'}}}
      onClick={handleLinkClick}
      className={cx(styles.wrapper, disabled && styles.disabled, props.className)}
    >
      <>
        <Image small src={props.mainImage} className={styles.image} title={props.title} objectFit="cover"/>
        <div className={styles.rightWrapper}>
          <div className={styles.title}>{props.title}</div>
          {
            props.price && (<div className={styles.priceWrapper}>
              <div className={styles.price}>{props.displayPrice}</div>
              {
                hasOriginalPrice && <div className={styles.originalPrice}>{props.displayMsrp}</div>
              }
              </div>
            )
          }
        </div>
      </>
    </Link>
  )
}

export default ProductLink
