import React, { PureComponent, createRef } from 'react'
import Slider from '../Slider/Slider'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../Image/Image'
import withSliderTouch from '../../../hooks/withSliderTouch'

import cx from '../../../utils/className'
import styles from './Banner.module.css'

// 100 / (width / height ) -> 100 / (375 / 114)
const DEFAULT_PLACEHOLDER = '30.4%'

const BannerIndicator = ({ index, count, handleSwipe }) => {
  if (!Array.prototype.fill) {
    return null
  }

  const items = Array(count).fill(0).map((o, i) => (
    <span key={i}
      onClick={() => handleSwipe(i)}
      className={cx(styles.item, {
        [styles.current]: i === index
      })
    }></span>
  ))

  return (
    <ul className={styles.indicator}>{items}</ul>
  )
}


class Banner extends PureComponent {
  state = {
    currentIndex: 0,
    fullDisplay: false
  }

  sliderEl = createRef()

  handleSwipe = index => {
    this.sliderEl.current.slickGoTo(index)
  }

  handleIndexUpdate = index => {
    const items = this.getImageItems()

    this.setState({
      currentIndex: index,
      // mark full display avoid destroy the image already mounted
      fullDisplay: this.state.fullDisplay
        || index === items.length - 1
    })
  }

  getImageItems() {
    return this.props.items || [this.props]
  }

  getPlaceholder(item) {
    if (item.width && item.height) {
      return {
        width: item.width,
        height: item.height
      }
    } else {
      return item.placeholder || DEFAULT_PLACEHOLDER
    }
  }

  onBannerItemClick = index => {
    if (this.props.sourceLocator && this.props.trackName) {
      this.props.trackName && this.props.$track.clickMainResourceLocator(`${this.props.sourceLocator}:${this.props.trackName}_${index}`)
    }
  }

  render() {
    const props = this.props

    const images = this.getImageItems().map((item, i) => {
      const currentIndex = this.state.currentIndex
      const displayImage = this.state.fullDisplay
        || i <= currentIndex
        || i === currentIndex + 1 // preload next page

      const image = (
        <Image
          {...item}
          {...props.childOptions || {}}
          placeholder={this.getPlaceholder(item)}
          title={item.title || `Banner - ${i + 1}`}
          className={styles.imageWrapper}
          original
          disableClick={this.props.disableClick}
        ></Image>
      )
      return (
        <div className={styles.imageWrapper} key={i} onClick={() => this.onBannerItemClick(i)}>
          {displayImage && image}
        </div>
      )
    })

    const sliderSettings = {
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      afterChange: this.handleIndexUpdate
    }

    if (images.length > 1) {
      return (
        <div className={styles.wrapper}>
          <Slider {...sliderSettings} ref={this.sliderEl}>
            {images}
          </Slider>
          {
            !props.hideIndicator &&
            <BannerIndicator
              index={this.state.currentIndex}
              count={images.length}
              handleSwipe={this.handleSwipe}
            ></BannerIndicator>
          }
        </div>
      )
    }

    return images[0]
  }
}

export default withSliderTouch(withPlugin(Banner))
