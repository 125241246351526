import React, { useState, useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import AppStateContext from '../../../hooks/AppStateContext'

import { getPaymentMethods } from '../../Store/Cart/Acceptions'

import Image from '../../common/Image/Image'
import Link from '../../common/Link/Link'

import styles from './DesktopFooter.module.css'

const ftLeftData = [
  {
    title: 'shell.footer.helpSupport',
    items: [
      {
        title: 'terms.shippingInfo',
        link: 'https://flamingo.shop/store/event/shipping',
      },
      {
        title: 'terms.returnAndRefund',
        link: 'https://flamingo.shop/store/event/returns-and-refunds',
      }
    ]
  },
  {
    title: 'shell.footer.customerCare',
    items: [
      {
        title: 'terms.contactUs',
        link: 'https://flamingo.shop/store/event/contact',
      },
      {
        title: 'terms.howToPay',
        link: 'https://flamingo.shop/store/event/payment-method',
      }
    ]
  }
]

const rightIcon = [{
  image: 'https://img.ltwebstatic.com/images3_pi/2019/10/16/157121065670822a0559edc9a20136a111a4718145.png',
  link: 'https://www.facebook.com/official.flamingo.shop'
},{
  image: 'https://img.ltwebstatic.com/images3_pi/2019/10/16/15712106679a688cf6cea076cff889b0a6b7889997.png',
  link: 'https://www.instagram.com/flamingo_us/'
},{
  image: 'https://img.ltwebstatic.com/images3_pi/2019/10/16/15712107177cd4e76a51fca4069c47f29cdbed4392.png',
  link: 'https://www.youtube.com/watch?v=vskWs-jU3gY&t=86s'
},{
  image: 'https://img.ltwebstatic.com/images3_pi/2019/10/16/15712107291aea047ed98d7d3629a1f66f7ab177fd.png',
  link: 'https://www.pinterest.com/OfficialFlamingoShop/pins/'
},{
  image: 'https://img.flamingo.shop/p/files/2021823-43636.png?v=1629707810',
  link: 'https://www.tiktok.com/@flamingo__us'
}]

const rightAppIcon = [
  {
    image: 'https://img.flamingo.shop/p/files/android.png?v=1599730184',
    link: 'https://play.google.com/store/apps/details?id=com.flamingo.shop'
  },
  {
    image: 'https://img.flamingo.shop/p/files/iphone_94678678-c0fb-46f4-a68d-6a61d504155f.png?v=1599730184',
    link: 'https://apps.apple.com/app/apple-store/id1436939490'
  }
]

const companyInfos = [
  {
    title: 'terms.privacy',
    link: 'https://flamingo.shop/store/event/privacy-policy',
  },
  {
    title: 'terms.termsConditions',
    link: 'https://flamingo.shop/store/event/term-of-service',
  }
]

const RightHeader = ({ data }) => {
  return <>
    <ul>
      {
        data && data.map((icon, index) => {
          return <Link to={icon.link} key={index}>
            <Image lazy src={icon.image} className={styles.socialIcon}></Image>
          </Link>
        })
      }
    </ul>
  </>
}

const DesktopFooter = props => {
  const appState = useContext(AppStateContext)
  const { site } = appState

  const plugin = useContext(PluginContext)
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = ev => {
    ev.preventDefault()
    if (!submitting) {
      if (email && email.lastIndexOf('@') > -1) {
        plugin.$toastSuccess(plugin.$i18n.transl('common.subscribeSuccess'))
        setSubmitting(true)
        setEmail('')
      }
    }
  }


  const companyInfo = {
    title: 'shell.footer.companyInfo',
    items: [
      {
        title: plugin.$i18n.transl('shell.footer.about'),
        link: 'https://flamingo.shop/store/events/about-us'
      }
    ]
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.ftLeftWrapper}>
          <div className={styles.leftTopWrapper}>
            {
              [companyInfo, ...ftLeftData].map(data => {
                return (
                  <div className={styles.leftItem} key={data.title}>
                    <h5 className={styles.itemTitle}>{plugin.$i18n.transl(data.title)}</h5>
                    <ul>
                      {
                        data.items.map((item, index) => (
                          <Link to={item.link} className={styles.itemChildTitle} key={index}>{plugin.$i18n.transl(item.title)}</Link>
                        ))
                      }
                    </ul>
                  </div>
                )
              })
            }
          </div>
          <div className={styles.leftBottomWrapper}>
            <span className={styles.companyTitle}>
              {plugin.$i18n.transl('shell.footer.copyRight', {
                site: site.name
              })}
            </span>
            <ul>
              {
                companyInfos.map((info, index) => {
                  return <Link to={info.link} key={index} className={styles.companyInfoItem}>
                    {plugin.$i18n.transl(info.title)}
                  </Link>
                })
              }
            </ul>
          </div>
        </div>
        <div className={styles.ftRightWrapper}>
          <div className={styles.rightTopWrapper}>
            <div className={styles.rightSocialWrapper}>
              <div className={styles.itemTitle}>
                {plugin.$i18n.transl('shell.footer.findUsOn')}
              </div>
              <RightHeader data={rightIcon}/>
            </div>
            <div className={styles.rightAppWrapper}>
              <div className={styles.itemTitle}>
                {plugin.$i18n.transl('shell.footer.app')}
              </div>
              <RightHeader data={rightAppIcon}/>
            </div>
          </div>
          <div className={styles.rightMiddle}>
            <div className={styles.itemTitle}>{plugin.$i18n.transl('shell.footer.emailSignUp', {
              site: site.name
            })}
            </div>
            <div className={styles.inputEmailWrapper}>
              <form className={styles.emailForm} onSubmit={handleSubmit}>
                <input
                  type='email'
                  placeholder={plugin.$i18n.transl('core.promotion.inputEmail')}
                  value={email}
                  onChange={ev => setEmail(ev.target.value)}
                ></input>
              </form>
              <button className={styles.submitBtn} onClick={handleSubmit} disabled={submitting}>
                {plugin.$i18n.transl('common.subscribe')}
              </button>
            </div>
          </div>
          <div className={styles.rightBottom}>
            <div className={styles.itemTitle}>{plugin.$i18n.transl('shell.footer.weAccept')}</div>
            <ul>
              {
                getPaymentMethods(plugin.$site.getSiteInfo().countryCode).map(iconUrl => {
                  return <Image lazy src={iconUrl.imageUrl} objectFit='contain' title='bank' className={styles.image} key={iconUrl.key} small/>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopFooter
