import BaseService from '@flamingo_tech/funkgo/src/base/BaseService'
import { UserModel, AnonymousUserModel } from './UserModel'

export const FACEBOOK_LOGIN_WAY = 10
export const GOOGLE_LOGIN_WAY = 60
export const APPLE_LOGIN_WAY = 50

const TIME_OUT = 12000

export default class LoginService extends BaseService {
  post(url, data, options = {}) {
    return super.post(url, data, {
      timeout: TIME_OUT,
      ...options,
    })
  }

  get(url, options = {}) {
    return super.get(url, {
      timeout: TIME_OUT,
      ...options,
    })
  }

  formatUser(data, user = {}) {

    const fbToken = user.fbToken

    return new UserModel({
      ...data.userInfo,
      accessToken: data.authInfo.accessToken,
      refreshToken: data.authInfo.refreshToken,
    }, fbToken)
  }

  formatAnonymousUser(data) {
    return new AnonymousUserModel({
      ...data.userInfo,
      accessToken: data.authInfo.accessToken
    })
  }

  // modifyEmail(user, verifiedEmail) {
  //   const payload = {
  //     email: user.email,
  //     verifiedEmail,
  //   }

  //   return this.postWithAccessToken('/client/api/v1/user/modify-email', payload, user.accessToken)
  //     .then(() => user)
  // }

  registerByFb(fbUser, verifiedEmail) {
    return this.post('/client/api/v1/auth/register', {
      fbUserId: fbUser.fbUserId,
      headImg: fbUser.image,
      fbAccessToken: fbUser.fbToken,
      email: fbUser.email,
      firstName: fbUser.firstName,
      lastName: fbUser.lastName,
      verifiedEmail
    }).then(
      data => this.formatUser(data, fbUser)
    )
  }

  loginByFb(fbUser) {
    return this.post('/client/api/v1/auth/login', {
      ...fbUser,
      userName: fbUser.name,
      headImg: fbUser.image,
      fbAccessToken: fbUser.fbToken,
      loginWay: FACEBOOK_LOGIN_WAY
    }).then(
      data => this.formatUser(data, fbUser)
    )
  }

  getUserInfoByFbUser(fbUser) {
    return this.get('/client/api/v1/user/info', {
      params: {
        fbUserId: fbUser.fbUserId
      }
    })
  }

  // TODO: 与后端协调, 将fbUserId改成thirdPartyUserId
  registerByGoogle(googleUser) {
    return this.post('/client/api/v1/auth/register', {
      fbUserId: googleUser.userId,
      headImg: googleUser.headImg,
      fbAccessToken: googleUser.googleToken,
      email: googleUser.email,
      firstName: googleUser.firstName,
      lastName: googleUser.lastName,
      loginWay: GOOGLE_LOGIN_WAY
    }).then(
      data => this.formatUser(data, googleUser)
    )
  }

  loginByGoogle(googleUser) {
    return this.post('/client/api/v1/auth/login', {
      fbUserId: googleUser.userId,
      name: googleUser.name,
      headImg: googleUser.headImg,
      fbAccessToken: googleUser.googleToken,
      email: googleUser.email,
      firstName: googleUser.firstName,
      lastName: googleUser.lastName,
      loginWay: GOOGLE_LOGIN_WAY
    }).then(
      data => this.formatUser(data, googleUser)
    )
  }

  getUserInfoByGoogleUser(googleUser) {
    return this.get('/client/api/v1/user/info', {
      params: {
        fbUserId: googleUser.userId
      }
    })
  }

  registerByApple(appleUser) {
    return this.post('/client/api/v1/auth/register', {
      ...appleUser,
      loginWay: APPLE_LOGIN_WAY
    }).then(
      data => this.formatUser(data, appleUser)
    )
  }

  loginByApple(appleUser) {
    return this.post('/client/api/v1/auth/login', {
      ...appleUser,
      loginWay: APPLE_LOGIN_WAY
    }).then(
      data => this.formatUser(data, appleUser)
    )
  }

  getUserInfoByAppleUser(appleUser) {
    return this.get('/client/api/v1/user/info', {
      params: {
        fbUserId: appleUser.fbUserId
      }
    })
  }

  getUserInfo(userToken) {
    if (!userToken || !userToken.accessToken) {
      return Promise.reject(new Error(`failed to get user info, caused by user token is empty`))
    }

    const { accessToken, fbToken, refreshToken } = userToken

    return this.get('/client/api/v1/user/me', {
      headers: {
        'X-access-token': accessToken,
      }
    }).then(data => {
      return new UserModel({
        accessToken,
        refreshToken,
        ...data.userInfo,
      }, fbToken)
    })
  }

  logout({ accessToken, shoppingCartId }) {
    return this.post('/client/api/v1/logout', {
      shoppingCartId
    }, {
      headers: {
        'X-access-token': accessToken
      }
    })
  }

  // belows are new apis for email login
  postEmailVerifyCode(email) {
    return this.post('/client/api/v2/register/email/code', { email })
  }

  registerByEmail(payload) {
    return this.post('/client/api/v2/register/email', {
      code: payload.code,
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password
    }).then(
      data => this.formatUser(data)
    )
  }

  registerByEmail1(payload) {
    return this.post('/client/api/v2/register/email/notVerifyEmailCode', {
      code: payload.code,
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      password: payload.password
    }).then(
      data => this.formatUser(data)
    )
  }

  registerByOrder(payload) {
    return this.post('/client/api/v2/register/order', {
      email: payload.email,
      password: payload.password,
      shopifyOrderNo: payload.shopifyOrderNo
    }).then(
      data => this.formatUser(data)
    )
  }

  checkShopifyEmail({ email, shopifyOrderNo }) {
    return this.post('/client/api/v2/order/check', {
      email,
      shopifyOrderNo,
    })
  }

  loginByEmail({ email, password }) {
    return this.post('/client/api/v2/login', {
      account: email,
      password
    }).then(
      data => this.formatUser(data)
    )
  }

  resetEmailVerifyCode(email) {
    return this.post('/client/api/v2/user/find/password/email/code', {
      email
    })
  }

  resetPassword(payload) {
    return this.post('/client/api/v2/user/find/password/email', payload)
  }

  refreshAccessToken(refreshToken) {
    return this.post('/client/api/v1/auth/refresh-token', { refreshToken })
  }

  loginByAnonymous({ email, firstName, lastName }) {
    return this.post('/client/api/v2/register/anonymous', {
      email
    }).then(
      data => {
        // 用户姓名存起来 注册时候可以预填充
        if (data.userInfo) {
          data.userInfo.firstName = firstName
          data.userInfo.lastName = lastName
        }
        return this.formatAnonymousUser(data)
      }
    )
  }

  fetchIsRegister = email => {
    return this.get(`/client/api/v1/user/registered?email=${email}`)
  }

  addEmailByThirdParty(userInfo) {
    return this.post('/client/api/v1/third/auth/addEmail', userInfo).then(
      data => this.formatUser(data, userInfo)
    )
  }

  addEmailByFb({ email, ...fbUser }) {
    return this.addEmailByThirdParty({
      email: email, 
      firstName: fbUser.firstName, 
      headImg: fbUser.image,  
      lastName: fbUser.lastName,  
      loginWay: FACEBOOK_LOGIN_WAY, 
      thirdAccessToken: fbUser.fbToken,  
      thirdUserId: fbUser.fbUserId,
      verifiedEmail: false,
    })
  }

  addEmailByGoogle({ email, ...googleUser }) {
    return this.addEmailByThirdParty({
      email: email, 
      firstName: googleUser.firstName, 
      headImg: googleUser.headImg,  
      lastName: googleUser.lastName,  
      loginWay: GOOGLE_LOGIN_WAY, 
      thirdAccessToken: googleUser.googleToken,  
      thirdUserId: googleUser.userId,
      verifiedEmail: false,
    })
  }

  addEmailByApple({ email, ...appleUser }) {
    // AppleUserModel 
    return this.addEmailByThirdParty({
      email: email, 
      firstName: appleUser.firstName, 
      headImg: appleUser.headImg,
      lastName: appleUser.lastName,  
      loginWay: APPLE_LOGIN_WAY, 
      thirdAccessToken: appleUser.fbAccessToken,  
      thirdUserId: appleUser.fbUserId,
      verifiedEmail: false,
    })
  }

  addEmailWithCheckByThirdParty(userInfo) {
    return this.post('/client/api/v1/third/auth/addEmailWithCheck', userInfo).then(
      data => this.formatUser(data, userInfo)
    )
  }

  addEmailWithCheckByFb({ email, password, ...fbUser }) {
    return this.addEmailWithCheckByThirdParty({
      email: email, 
      password: password, 
      loginWay: FACEBOOK_LOGIN_WAY, 
      thirdAccessToken: fbUser.fbToken,  
      thirdUserId: fbUser.fbUserId,
    })
  }

  addEmailWithCheckByGoogle({ email, password, ...googleUser }) {
    return this.addEmailWithCheckByThirdParty({
      email: email, 
      password: password, 
      loginWay: GOOGLE_LOGIN_WAY, 
      thirdAccessToken: googleUser.googleToken,  
      thirdUserId: googleUser.userId,
    })
  }

  addEmailWithCheckByApple({ email, password, ...appleUser }) {
    // AppleUserModel 
    return this.addEmailWithCheckByThirdParty({
      email: email, 
      password: password, 
      loginWay: APPLE_LOGIN_WAY, 
      thirdAccessToken: appleUser.fbAccessToken,  
      thirdUserId: appleUser.fbUserId,
    })
  }

}
