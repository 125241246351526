import React, { useState } from 'react'
import {
  SelectLangModal,
  SelectLang
} from '../../common/SelectLangModal/SelectLangModal'
import CommentService from '../../../services/CommentService'

import { getValByList } from '../../../utils/arrayUtils'

import cx from '../../../utils/className'
import styles from './ProductCommentTranslate.module.css'

const COMMENT_TRANSLATE_LANG_KEY = 'comment_translate_lang'
const getLangList = () => [
  {
    name: 'English',
    code: 'en'
  },
  {
    name: 'Deutsch',
    code: 'de'
  },
  {
    name: 'Français',
    code: 'fr'
  },
  {
    name: 'Español',
    code: 'es'
  }
]

const TranslateSection = (props) => {
  const { $storage, $http, $i18n } = props

  const [translatedContentObj, setTranslatedContentObj] = useState(null)
  const [showSelectLangModal, setShowSelectLangModal] = useState(false)

  const translateLangStorage = $storage.create(COMMENT_TRANSLATE_LANG_KEY)
  const commentService = new CommentService($http)

  const langList = getLangList()

  const [transLateLoading, setTransLateLoading] = useState(false)
  const fetchTranslateData = ({ code: lang }) => {
    setTransLateLoading(true)
    commentService
      .fetchTranslateInfo(props.id)
      .then((data) => {
        const translatedContentObj = {
          lang,
          comment: data[lang]
        }
        if (translatedContentObj) {
          setTranslatedContentObj(translatedContentObj)
        }
      })
      .finally(() => setTransLateLoading(false))
  }

  const handleSelectLang = (langObj) => {
    if (langObj?.code !== translatedContentObj?.lang) {
      translateLangStorage.setItem(langObj)
      setShowSelectLangModal(false)
      fetchTranslateData(langObj)
    }
  }

  const handleTranslate = () => {
    const storageLangObj = translateLangStorage.getItem()
    storageLangObj
      ? fetchTranslateData(storageLangObj)
      : setShowSelectLangModal(true)
  }

  const deleteTranslation = () => {
    setTranslatedContentObj(null)
  }

  const isDesktop = props.$detector.isDesktop()
  const LangModal = isDesktop ? SelectLang : SelectLangModal

  return (
    <div className={styles.translateCardWrapper}>
      {translatedContentObj ? (
        <div className={styles.translateCardContentBox}>
          <div
            className={cx(
              styles.translateCardContent,
              isDesktop && styles.isDesktop
            )}
          >
            <div className={styles.translateCardContentLeft}>
              {translatedContentObj.comment}
            </div>
            <div className={styles.translateCardContentRight}>
              <span className={styles.deleteIcon} onClick={deleteTranslation}>
                &#xe60e;
              </span>
            </div>
          </div>
          <span
            className={cx(
              styles.translateLangBtn,
              isDesktop && styles.isDesktop
            )}
            onClick={() => setShowSelectLangModal(true)}
          >
            <span className={styles.translateLangBtnDesc}>
              {
                getValByList(langList, translatedContentObj.lang, 'obj', 'code')
                  ?.name
              }
            </span>
          </span>
        </div>
      ) : (
        <div className={styles.translateBtnBox} onClick={handleTranslate}>
          {transLateLoading ? (
            <span
              className={cx(styles.translating, isDesktop && styles.isDesktop)}
            >
              {$i18n.transl('core.product.translating')}
            </span>
          ) : (
            <span
              className={cx(styles.translateBtn, isDesktop && styles.isDesktop)}
            >
              {$i18n.transl('core.product.translate')}
            </span>
          )}
        </div>
      )}
      {showSelectLangModal && (
        <LangModal
          {...props}
          langList={langList}
          translatedContentObj={translatedContentObj}
          handleSelectLang={handleSelectLang}
          onClose={() => setShowSelectLangModal(false)}
        />
      )}
    </div>
  )
}

export default TranslateSection
