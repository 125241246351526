import React, { Component } from 'react'
import PurchaseProtection from '../../components/Store/PurchaseProtection/PurchaseProtection'

import Shell from '../Shell/GuidePageShellContainer'

class PurchaseProtectionContainer extends Component {
  
  static Shell = Shell
  static shellProps = () => {
    return {
      promotion: true,
      supportDesktop: true
    }
  }

  render() {
    return (
      <PurchaseProtection />
    )
  }
}

export default PurchaseProtectionContainer