import React, { useContext } from 'react'
import { hoistHocStatic } from '@flamingo_tech/funkgo'
import AppStateContext from './AppStateContext'

const withKlarnaEnabled = (Component) => {
  const WrappedComponent = props => {
    const appState = useContext(AppStateContext)

    const { globalSettingMeta } = appState

    if (!globalSettingMeta.paymentMeta || !globalSettingMeta.paymentMeta.enableKlarna) {
      return null
    } else {

      return <Component {...props} />
    }
  }

  WrappedComponent.displayName = `withKlarnaEnabled(${Component.displayName || Component.name})`
  hoistHocStatic(WrappedComponent, Component)
  return WrappedComponent
}

export default withKlarnaEnabled