import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import CouponCenterList from './CouponCenterList'
import Button from '@components/common/Button/Button'
import cx from '../../../utils/className'

import styles from './CouponTagBar.module.css'

const CouponTagBar = props => {
  const [showCouponList, setShowCouponList] = useState(false)
  const isDesktop = props.$detector.isDesktop()
  const coupons = props.$store.couponHub.getAvailableCoupons()


  if (coupons.length === 0) {
    return null
  }

  const handleCouponClick = () => {
    props.$track.event('CouponTagBar', 'click')
    setShowCouponList(true)
  }

  return (
    <>
      <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)} onClick={handleCouponClick}>
        <div className={styles.titleWrapper}>
          <div className={styles.title}>{props.$i18n.transl('core.checkout.yourCoupons')}</div>
          <Button onClick={() => null} className={styles.viewMore} small>
            {props.$i18n.transl('common.viewMore')}
          </Button>
        </div>

        <div className={styles.couponWrapper}>
          {
            coupons.map(coupon => (
              <span className={styles.coupon}
                key={coupon.id}>{coupon.meta.title}</span>
            ))
          }
        </div>
      </div>
      {
        showCouponList &&
        <CouponCenterList
          availableCouponList={coupons}
          onClose={() => setShowCouponList(false)}
          isDesktop={isDesktop}
        ></CouponCenterList>
      }
    </>

  )
}

export default withPlugin(CouponTagBar)