import React, { useContext } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import Link from '../../common/Link/Link'

import cx from '../../../utils/className'

import styles from './ProductBreadcrumbsNavi.module.css'

const BreadcrumbsNavi = ({ breadcrumbs, product }) => {
  const plugin = useContext(PluginContext)
  
  const HOME = {
    title: plugin.$i18n.transl('common.home'),
    link: {
      name: 'Home'
    }
  }
  const parsedBreadcrumbs = Array.isArray(breadcrumbs)
  ? breadcrumbs
  : []

  const breadcrumbItems = [
    HOME,
    ...parsedBreadcrumbs,
    product && {
      title: product.title
    }
  ].filter(Boolean)



  return (
    <div className={cx(styles.wrapper, plugin.$detector.isDesktop() && styles.inDesktop)}>
      {
        breadcrumbItems.map((breadcrumb, i) =>
          breadcrumb.link
            ? (
              <Link key={i}
                className={styles.link}
                to={breadcrumb.link}
              >
                {breadcrumb.title}</Link>
            )
            : (
              <span key={i} className={styles.nolink}>{breadcrumb.title}</span>
            )
        )
      }
    </div>
  )
}

export default BreadcrumbsNavi
