import React, { Component, createRef } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'

import ProductService from '../../../services/ProductService'

import MultiColumns from '../../common/List/MultiColumns'
import ProductCard from '../ProductCard/ProductCard'
import LoadingPage from '../../common/LoadingPage/LoadingPage'
import ProductTPFU from './ProductTPFU'
import ProductRelatedSearches from '../Product/ProductRelatedSearches'

import ImpressionObserver from '../../common/ImpressionObserver/ImpressionObserver'

import styles from './ProductRecommendTabList.module.css'

const TABS = [{
  key: 'Product:YouMayAlsoLike',
  title: 'core.product.recommended',
  fetchFn: 'fetchProductYouMayAlsoLike',
  eventCategory: 'YouMayAlsoLike'
}, {
  key: 'Product:TopPicksForYou',
  title: 'core.product.topPicksForYou',
  fetchFn: 'fetchPicksData',
  eventCategory: 'TopPicksForYou'

}, {
  key: 'Product:OftenBoughtWith',
  title: 'core.product.oftenBoughtWith',
  fetchFn: 'fetchBoughtData',
  eventCategory: 'OftenBoughtWith'
}]

const MAX_FETCH_PAGE = 3

const WrappedProductCard = props => {
  const { impressionConfig = {} } = props

  const { eventParams = {}, eventName } = impressionConfig

  return (
    <ImpressionObserver eventParams={{ index: props.index, handle: props.handle, ...eventParams  }} eventName={eventName} eventId={props.id}>
      <ProductCard {...props} ></ProductCard>
    </ImpressionObserver>
  )
}

class ProductRecommendTabList extends Component {
  state = {
    collectionProductList: [],
    productsState: {},
    displayProducts: [],
    currentTabIndex: 0,
    loading: false,
    isContinueFetching: false,
    showOtherRecommendSection: false
  }

  productService = new ProductService(this.props.$http)
  _sourceHandle = '' // configured collection handle
  wrapperRef = createRef()
  barWrapperRef = createRef()

  fetchCollection = (tabIndex) => {
    const fetchFn = TABS[tabIndex].fetchFn

    if (this.state.loading) {
      return Promise.resolve()
    }

    this.setState({
      loading: true
    })

    const productsState = this.state.productsState
    const productsData = productsState[tabIndex]

    const { products: concatProducts, page = 1, hasNextPage } = productsData
    const { collectionHandle, productHandle, isESCollection } = this.props

    if (hasNextPage && page <= MAX_FETCH_PAGE) {
      const params =  {
        pageSize: 20,
        page
      }

      if (tabIndex === 0) { // ymal
        params.esFlag = !!isESCollection
        params.collectionHandle = collectionHandle
      }

      return this.productService[fetchFn](productHandle, params)
      .then(res => {
        const { products, handle, pageInfo: { hasNextPage } } = res
        const nextProducts = concatProducts.concat(products)
        productsData.hasNextPage = hasNextPage
        productsData.products = nextProducts
        productsData.page++
        productsData.handle = handle
        productsState[tabIndex].productsData = productsData
        this.setState({
          productsState: productsState,
          loading: false,
        })

        if (page === MAX_FETCH_PAGE) {
          this.setState({
            showOtherRecommendSection: true
          })
        }

        if (this.state.currentTabIndex === tabIndex) { // 快速切换tab时，老tab数据刚回
          this.setState({
            displayProducts: nextProducts
          })
        }
      })
      .catch(() => {
        this.setState({
          loading: false
        })
        return Promise.reject()
      })
    } else {

      this.setState({
        loading: false,
        showOtherRecommendSection: true
      })

      return Promise.resolve()
    }
  }

  handleReachEnd = () => {
    this.setState({
      isContinueFetching: true
    })
    this.fetchCollection(this.state.currentTabIndex)
  }


  componentDidMount() {
    const productsState = this.state.productsState

    TABS.forEach((_, index) => {
      productsState[index] = {
        products: [],
        hasNextPage: true,
        page: 1,
      }
    })

    this.fetchCollection(0)
  }

  getHeaderHeight = () => {
    const storeHeader =  document.getElementById('store-header')

    if (storeHeader) {
      const { top, height } = storeHeader.getBoundingClientRect()
      return top + height
    } else {
      return 0
    }
  }

  getDownloadBannerHeight = () => {
    const downloadBanner =  document.getElementById('download-banner')

    if (downloadBanner) {
      return downloadBanner.getBoundingClientRect().height
    } else {
      return 0
    }
  }


  handleChangeTabIndex = index => {
    if (index === this.state.currentTabIndex) {
      return
    }

    const stickyHeaderHeight = this.getHeaderHeight()
    const barWrapperTop = this.barWrapperRef.current.getBoundingClientRect().top

    const isCurrentSticky = barWrapperTop <= stickyHeaderHeight

    const { productsState } = this.state
    // save previous offsetTop to keep previous status
    productsState[this.state.currentTabIndex].scrollTop = document.documentElement.scrollTop
    productsState[this.state.currentTabIndex].isSticky = isCurrentSticky

    const { products = [], scrollTop, isSticky } = productsState[index]

    const backToTopOffsetTop = this.wrapperRef.current.offsetTop + this.getDownloadBannerHeight() - 8


    if (products.length === 0) { // 无数据
      if (isCurrentSticky) {
        window.scrollTo({
          top: backToTopOffsetTop,
          behavior: "instant"
        })
      }

      this.setState({
        isContinueFetching: false,
        currentTabIndex: index,
        displayProducts: Array.from({ length: 6 }, () => ({ product: {}}))
      })


      this.fetchCollection(index).catch(() => {
        this.setState({
          displayProducts: []
        })
      })

    } else { // 有数据
      this.setState({
        currentTabIndex: index,
        displayProducts: products,
      })

      //总是先滚动头部，避免曝光
      if (isCurrentSticky) {
        window.scrollTo({
          top: backToTopOffsetTop,
          behavior: "instant"
        })
      }

      setTimeout(() => {
        if (isCurrentSticky) {
          if (!isSticky) { // 之前未置顶，只能回到置顶重新来
            window.scrollTo({
              top: backToTopOffsetTop,
              behavior: "instant"
            })
          } else {
            window.scrollTo({
              top: scrollTop,
              behavior: "instant"
            })
          }
        }
      })
    }
  }


  render() {
    if (this.state.currentTabIndex === 0 && this.state.displayProducts.length <= 0) {
      return null
    }

    const currentTab = TABS[this.state.currentTabIndex]
    const currentData = this.state.productsState[this.state.currentTabIndex]

    return (
      <div ref={this.wrapperRef}>
        <div className={styles.wrapper}>
          <div className={styles.title}>{this.props.$i18n.transl('core.product.youMayAlsoLike')}</div>

        {/* <div className={styles.tabs} ref={this.barWrapperRef}>
            {
              TABS.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => this.handleChangeTabIndex(index)}
                    className={cx(styles.tabItem, this.state.currentTabIndex === index && styles.active)}
                    >{this.props.$i18n.transl(item.title)}</div>
                )
              })
            }
          </div> */}
          <MultiColumns
            columns={2}
            childIs={WrappedProductCard}
            childItems={this.state.displayProducts.map(i => i.product)}
            onReachEnd={this.handleReachEnd}
            childOptions={{
              lazy: true,
              onClick: (product, _, index) => {
                this.props.$router.navigateToProductPage(product)
                this.props.$track.clickSubResourceLocator(`${currentTab.key}_${product.handle}`, {
                  category: this.props.productCategory,
                  handle: product.handle,
                  id: product.id,
                  index,
                  sourceHandle: currentData.handle
                })
              },
              eventCategory: currentTab.eventCategory,
              productCategory: this.props.productCategory,
              impressionConfig: {
                eventName: currentTab.key,
                eventParams: {
                  sourceHandle: currentData.handle,
                  category: this.props.productCategory
                }
              },
              onClickQuickAdd: (product, index) => {
                this.props.$track.clickSubResourceLocator(`${currentTab.key}_${product.handle}`, {
                  category: this.props.productCategory,
                  handle: product.handle,
                  id: product.id,
                  index,
                  sourceHandle: currentData.handle
                })
              }
            }
            }
            className={styles.list}
            columnClassName={styles.listColumn}
          />
          {
            this.state.loading && this.state.isContinueFetching && <LoadingPage className={styles.loading}/>
          }
        </div>
        {
          this.state.showOtherRecommendSection &&
          <>
            <ProductTPFU
              galleryTitle={this.props.$i18n.transl('core.product.topPicksForYou')}
              eventCategory='TopPicksForYou'
              fetchFn='fetchPicksData'
              productHandle={this.props.productHandle}
              productCategory={this.props.productCategory}
              className={styles.bottomMargin}
            />
            <ProductTPFU
              galleryTitle={this.props.$i18n.transl('core.product.oftenBoughtWith')}
              eventCategory='OftenBoughtWith' fetchFn='fetchBoughtData'
              productHandle={this.props.productHandle}
              productCategory={this.props.productCategory}
            />
            <ProductRelatedSearches
              productHandle={this.props.productHandle}
              productCategory={this.props.productCategory}/>
          </>
        }

      </div>
    )
  }
}

export default withPlugin(ProductRecommendTabList)