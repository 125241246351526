import React from 'react'
import withUserAndPlugins from '../../../hooks/withUserAndPlugins'

import UserCenterNavigation from '../../Store/Order/UserCenterNavigation'
import BreadCrumbs from '../../common/BreadCrumbs/BreadCrumbs'
import OrderRecommendList from '../../Store/ProductList/OrderRecommendList'

import styles from './DesktopUserCenterShell.module.css'


const DesktopUserCenterShell = props => {
  if (!props.show) {
    return props.children
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.breadCrumbsBox}>
        <BreadCrumbs />
      </div>
      <div className={styles.body}>
        <div className={styles.navigate}>
          <UserCenterNavigation />
        </div>
        <div className={styles.content}>
          {props.children}
        </div>
      </div>
      <OrderRecommendList pageName='OrderDetail'/>
    </div>
  )
}



export default withUserAndPlugins(DesktopUserCenterShell)