import React, { Component } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Countdown from '../../Widget/Countdown/Countdown'

import styles from './UnpaidBuoy.module.css'
import cx from '../../../utils/className'

const EVENT_CATEGORY_UNPAID = 'UnpaidOrder'

class UnpaidBuoy extends Component {
  unpaidInfo = this.props.unpaidInfo || {}

  state = {
    off: this.unpaidInfo.unpaid_buoy_off || false,
    time: this.props.timeStamp
  }

  handleToggleBuoy = () => {
    const preOffStatus = this.state.off

    this.props.$track.event(EVENT_CATEGORY_UNPAID, `turn_${preOffStatus ? 'on' : 'off'}_buoy`)
    if (preOffStatus) {
      document.body.addEventListener('touchstart', this.handleTouchStart)
      document.body.addEventListener('touchmove', this.handleTouchMove)
    }

    this.setState({
      off: !preOffStatus
    })
    this.props.onUpdateUnpaidInfo({
      unpaid_buoy_off: !preOffStatus
    })
  }

  toCheckoutPage = () => {
    this.props.$track.event(EVENT_CATEGORY_UNPAID, 'continue_to_pay', 'buoy')
    this.props.onContinue()
  }

  handleClose = () => {
    this.props.$track.event(EVENT_CATEGORY_UNPAID, 'close_buoy')
    this.props.onClose()
  }

  handleFinishCountDown = () => {
    this.setState({ time: 0 })
    this.props.onFinishCountDown()
  }

  handleTouchStart = ev => {
    this.startY = Math.floor(ev.targetTouches[0].clientY)
  }

  handleTouchMove = ev => {
    if (this.state.off) {
      return
    }

    const { clientY } = ev.targetTouches[0]
    if (Math.abs(clientY - this.startY) >= 50) {
      this.setState({ off: true })
      this.props.onUpdateUnpaidInfo({
        unpaid_buoy_off: true
      })
      document.body.removeEventListener('touchstart', this.handleTouchStart, { passive: true })
      document.body.removeEventListener('touchmove', this.handleTouchMove, { passive: true })
    }
  }

  componentDidMount() {
    document.body.addEventListener('touchstart', this.handleTouchStart, { passive: true })
    document.body.addEventListener('touchmove', this.handleTouchMove, { passive: true })
  }

  componentWillUnmount() {
    document.body.removeEventListener('touchstart', this.handleTouchStart, { passive: true })
    document.body.removeEventListener('touchmove', this.handleTouchMove, { passive: true })

    this.setState({
      off: true
    })
    this.props.onUpdateUnpaidInfo({
      unpaid_buoy_off: true
    })
  }

  render() {
    const isDesktop = this.props.$detector.isDesktop()
    return (
      <aside>
        <div className={cx(styles.card, isDesktop && styles.isDesktop, this.state.off && styles.off)}>
          <div className={styles.iconBox} onClick={this.handleToggleBuoy}></div>
          <div className={styles.title} onClick={this.toCheckoutPage}>
            <span>{this.props.$i18n.transl('core.checkout.unpaidOrder.waitingForPayment')}</span>
            {
              this.state.time > 0 && (
                <Countdown
                  cardClassName={styles.cardClassName}
                  separatorClassName={styles.separatorClassName}
                  onFinished={this.handleFinishCountDown}
                  timestamp={this.state.time}
                  type={Countdown.TYPE.CLOCK}
                  className={styles.countdown}
                />
              )
            }
          </div>
          <span className={styles.closeIcon} onClick={this.handleClose}>&#xe60e;</span>
        </div>
      </aside>
    )
  }
}

export default withPlugin(UnpaidBuoy)
