import ProductModel, { TYPE } from './ProductModel.js'

class ProductDetailModel extends ProductModel {
  constructor(data, type) {
    super(data, type)

    // for product detail case; we will fetch 100 sku at one shot
    // , if there is no availableForSale sku, then mark self as unavailable for sale
    // its mean: all sku is sold out, but spu still on the table
    const availableVariations = this.variations.filter(variation => variation.availableForSale)
    if (this.availableForSale && availableVariations.length === 0) {
      this.availableForSale = false
    }
  }
}

export {
  TYPE
}
export default ProductDetailModel
