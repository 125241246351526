import React, { Component } from 'react'
import PaymentMethod from '../../components/Store/PaymentMethod/PaymentMethod'

import Shell from '../Shell/GuidePageShellContainer'

class PaymentMethodContainer extends Component {
  
  static Shell = Shell
  static shellProps = () => {
    return {
      promotion: true,
      supportDesktop: true
    }
  }

  render() {
    return (
      <PaymentMethod />
    )
  }
}

export default PaymentMethodContainer