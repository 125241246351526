import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import styles from './InventoryTag.module.css'
import cx from '../../../utils/className'

const InventoryTag = props => {
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const { inventory } = props

  if (inventory <= 0) {
    return null
  }

  let inventoryTag = null

  if (inventory <= 20 && inventory > 0) {
    inventoryTag = plugin.$i18n.transl('core.cart.onlyLeftX', {
      count: inventory
    })
  } else if (inventory <= 50 && inventory > 20) {
    inventoryTag = plugin.$i18n.transl('core.cart.lowInStock')
  }

  return (
    inventoryTag &&
    <div className={cx(styles.inventoryTag, isDesktop && styles.isDesktop)}>
      <span>{inventoryTag}</span>
    </div>
  )
}

export default InventoryTag