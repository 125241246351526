import React from 'react'
import Image from '../common/Image/Image'
import Skeleton from './Skeleton'

import styles from './ProductCardSkeleton.module.css'
import { withPlugin } from '@flamingo_tech/funkgo'
import cx from '../../utils/className'

/* ------------------------- */
const ProductCardSkeleton = props => {
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={cx(styles.card, isDesktop && styles.inDesktop)}>
      <div className={styles.image}>
        <Image crop={{ width: 2, height: 3 }} placeholder={{ width: 2, height: 3 }} />
      </div>
      {
        !isDesktop && (
          <div className={styles.info}>
            <Skeleton />
            <div className={styles.flexSection}>
              <Skeleton type={Skeleton.TYPE.SMALL} />
              <Skeleton type={Skeleton.TYPE.SMALL} />
            </div>
          </div>
        )
      }
    </div>
  )
}

export default withPlugin(ProductCardSkeleton)
