import React, { useState, useEffect } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import ProductCommentSectionHeaderSummary from './ProductCommentSectionHeaderSummary'
import Select from '../../common/Select/Select'
import SpecList from '../../common/SpecList/SpecList'
import {
  ColorOrSizeSelectPannelModal,
  SortSelectPannelModal,
  FilterSelectPannelModal
} from './ProductCommentSectionHeaderModal'

import {
  CATEGORY,
  FILTER_TYPE,
  SELECT_TYPE,
  pcTabOptionList,
  getSortByOptionList,
  getStarOptionList,
  getMobileTabList
} from './config/ProductCommentSectionHeaderConfig'

import { deepClone } from '../../../utils/commonUtils'

import cx from '../../../utils/className'
import styles from './ProductCommentSectionHeader.module.css'

const selectPannelModalByFilterType = {
  COLOR_SIZE: ColorOrSizeSelectPannelModal,
  SORTBY: SortSelectPannelModal,
  MOBILE_FILTER: FilterSelectPannelModal
}

// H5筛选
const ProductCommentSectionFilter = (props) => {
  const {
    withPicture: withPictureList,
    starList,
    colorList,
    sizeList,
    sortTypeObj
  } = props.filterData
  const tabList = getMobileTabList(props)
  const { handleFilter, $i18n } = props

  const [selectedTab, setSelectedTab] = useState(null)

  const stateListByCategory = {
    [CATEGORY.COLOR.name]: colorList,
    [CATEGORY.SIZE.name]: sizeList,
    [CATEGORY.RATING.name]: starList,
    [CATEGORY.PICTURE.name]: withPictureList
  }
  const handleTab = (val) => {
    const { type, optionData } = val
    optionData.forEach((v) => {
      if (type === FILTER_TYPE.SORTBY) {
        v.selected = sortTypeObj.code === v.code ? true : false
      } else {
        // 多选
        v.values.forEach((v1) => {
          v1.selected = stateListByCategory[v.name].includes(v1.code)
            ? true
            : false
        })
      }
    })
    setSelectedTab(type === selectedTab?.type ? null : deepClone(val))
  }

  const sortShowValue = sortTypeObj.name
  const mergeFilterArr = [...starList, ...withPictureList]
  const [colorOrSizeShowValue, setColorOrSizeShowValue] = useState()

  useEffect(() => {
    const mergeColorOrSizeArr = [...colorList, ...sizeList]
    setColorOrSizeShowValue(
      mergeColorOrSizeArr.length
        ? `${$i18n.transl('core.product.selected')}：${mergeColorOrSizeArr
            .map((item) => item)
            .join(', ')}`
        : $i18n.transl('core.product.colorOrSize')
    )
  }, [$i18n, colorList, sizeList])

  const closeModal = () => {
    setSelectedTab(null)
  }

  const handleSelectChange = (type, value) => {
    if (type === FILTER_TYPE.SORTBY) {
      if (sortTypeObj?.code === value.code) return // 避免重复点击
      closeModal()
      handleFilter({ type, value })
    }
  }

  const onConfirm = () => {
    closeModal()
    const { type, optionData } = selectedTab
    const selectedState = optionData.map((v) => ({
      ...v,
      values: v.values.filter((v1) => v1.selected)
    }))
    handleFilter({ type, value: selectedState })
  }

  const SelectPannelModal = selectPannelModalByFilterType[selectedTab?.type]
  return (
    <div className={styles.moblieFilterWrapper}>
      <div
        className={cx(
          styles.colorOrSizeTab,
          selectedTab?.type === FILTER_TYPE.COLOR_SIZE && styles.active
        )}
        onClick={() => handleTab(tabList[0])}
      >
        <div
          className={cx(
            styles.colorOrSizeDesc,
            selectedTab?.type === FILTER_TYPE.COLOR_SIZE && styles.active
          )}
        >
          <span className={styles.colorOrSizeDescVal}>
            {colorOrSizeShowValue}
          </span>
        </div>
      </div>
      <div className={styles.navTabBox}>
        <div
          className={cx(
            styles.navTab,
            styles.navTabLeft,
            selectedTab?.type === FILTER_TYPE.SORTBY && styles.active
          )}
          onClick={() => handleTab(tabList[1])}
        >
          {sortShowValue}
        </div>
        <div className={styles.line}></div>
        <div
          className={cx(
            styles.navTab,
            styles.navTabRight,
            selectedTab?.type === FILTER_TYPE.MOBILE_FILTER && styles.active
          )}
          onClick={() => handleTab(tabList[2])}
        >
          {$i18n.transl('core.product.filter')}
          {
            mergeFilterArr?.length ?
              (<span className={styles.filterDesc}>({mergeFilterArr.length})</span>):null
          }
        </div>
      </div>
      {selectedTab?.type && (
        <SelectPannelModal
          selectedTab={selectedTab}
          onConfirm={onConfirm}
          onClose={closeModal}
          onChange={handleSelectChange}
        />
      )}
    </div>
  )
}

// PC筛选
const PCProductCommentSectionFilter = (props) => {
  const isDesktop = props.$detector.isDesktop()
  const { handleFilter, colorOrSizeOptionList, filterData, $i18n } = props

  const [tabSortList, setTabSortList] = useState(pcTabOptionList())

  const handleTabSort = ({ code: value, selected }) => {
    if (selected) return // 避免重复点击
    handleFilter({ type: FILTER_TYPE.PC_PICTURE, value })
    tabSortList.forEach((v) => {
      v.selected = v.code === value ? true : false
    })
    setTabSortList([...tabSortList])
  }

  return (
    <div className={styles.sortWrapper}>
      <div className={styles.sortleft}>
        {tabSortList.map((v, i) => (
          <div
            className={cx(
              styles.tabSort,
              v.selected && styles.selected,
              i === tabSortList.length - 1 && styles.lastTabSort
            )}
            onClick={() => handleTabSort(v)}
            key={v.code}
          >
            {props.$i18n.transl(v.name)}
          </div>
        ))}
      </div>
      <div className={styles.sortRight}>
        <Select
          label={$i18n.transl('core.product.rating')}
          selectLabelClass={styles.starSelectLabelClass}
          selectItemClass={styles.starSelectItemClass}
          selectPannelClass={styles.starSelectPannelClass}
          optionData={getStarOptionList(isDesktop)}
          type={SELECT_TYPE.MULTIPLE}
          onChange={(value) =>
            handleFilter({ type: FILTER_TYPE.PC_STAR, value })
          }
        ></Select>
        <Select
          label={$i18n.transl('core.product.colorOrSize')}
          selectItemClass={styles.colorOrSizeSelectItemClass}
          selectPannelClass={styles.colorOrSizeByPannelClass}
          optionData={colorOrSizeOptionList}
          type={SELECT_TYPE.DIMENSION_MULTIPLE}
          onChange={(value) =>
            handleFilter({ type: FILTER_TYPE.COLOR_SIZE, value })
          }
        >
          {SpecList}
        </Select>
        <Select
          label={$i18n.transl('core.product.sortBy')}
          selectItemClass={styles.sortBySelectItemClass}
          selectPannelClass={styles.sortByPannelClass}
          optionData={getSortByOptionList(props.$i18n)}
          type={SELECT_TYPE.SINGLE}
          initValue={filterData.sortTypeObj}
          onChange={(value) =>
            handleFilter({ type: FILTER_TYPE.SORTBY, value })
          }
        ></Select>
      </div>
    </div>
  )
}
// 评论筛选组件
const ProductCommentSectionHeader = (props) => {
  const { onCommentBySort } = props
  const isDesktop = props.$detector.isDesktop()

  const [withPicture, setWithPicture] = useState([])
  const [starList, setStarList] = useState([])
  const [colorList, setColorList] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [sortTypeObj, setSortTypeObj] = useState(
    getSortByOptionList(props.$i18n)[0]
  )

  // 颜色与尺寸option数据(pc|h5共用)
  const getColorOrSizeOptionList = () => {
    const { options, colorSwatchList } = props.product
    return options.map((category) => {
      let values
      if (category.name === CATEGORY.COLOR.name) {
        values = category.values.map((colorItem) => {
          const shotColor = colorSwatchList.find(
            (filteredColorItem) => filteredColorItem.optionValue === colorItem
          )
          return {
            name: colorItem,
            code: colorItem,
            color: shotColor && shotColor.color,
            colorImageLink: shotColor && shotColor.colorImageLink
          }
        })
      }
      if (category.name === CATEGORY.SIZE.name) {
        values = category.values.map((sizeItem) => ({
          name: sizeItem,
          code: sizeItem
        }))
      }
      return { name: category.name, displayName: category.displayName, values }
    })
  }
  // 多选state
  const stateByCategory = {
    [CATEGORY.COLOR.name]: {
      stateName: 'colorList',
      stateFn: setColorList
    },
    [CATEGORY.SIZE.name]: {
      stateName: 'sizeList',
      stateFn: setSizeList
    },
    [CATEGORY.RATING.name]: {
      stateName: 'starList',
      stateFn: setStarList
    },
    [CATEGORY.PICTURE.name]: {
      stateName: 'withPicture',
      stateFn: setWithPicture
    }
  }
  const handleFilter = ({ type, value }) => {
    // PC筛选
    if (type === FILTER_TYPE.PC_STAR) {
      const starListVal = value.map((v) => v.code)
      setStarList(starListVal)
      reqCommentBySort({ starList: starListVal })
    }
    if (type === FILTER_TYPE.PC_PICTURE) {
      setWithPicture([value])
      reqCommentBySort({ withPicture: [value] })
    }
    // H5筛选
    if (type === FILTER_TYPE.SORTBY) {
      setSortTypeObj(value)
      reqCommentBySort({ sortType: value.code })
    }
    // PC、H5筛选
    if (type === FILTER_TYPE.COLOR_SIZE || type === FILTER_TYPE.MOBILE_FILTER) {
      const stateFn0 = stateByCategory[value[0].name].stateFn
      const stateName0 = stateByCategory[value[0].name].stateName
      const stateValue0 = value[0].values.map((v) => v.code)
      stateFn0(stateValue0)

      const stateFn1 = stateByCategory[value[1].name].stateFn
      const stateName1 = stateByCategory[value[1].name].stateName
      const stateValue1 = value[1].values.map((v) => v.code)
      stateFn1(stateValue1)

      reqCommentBySort({
        [stateName0]: stateValue0,
        [stateName1]: stateValue1
      })
    }
  }

  const reqCommentBySort = (newVal) => {
    onCommentBySort({
      withPicture,
      starList,
      colorList,
      sizeList,
      sortType: sortTypeObj?.code,
      ...newVal
    })
  }

  const ProductCommentSectionFilterPannel = isDesktop
    ? PCProductCommentSectionFilter
    : ProductCommentSectionFilter
  return (
    <div className={!isDesktop ? styles.headerWrapper : null}>
      <ProductCommentSectionHeaderSummary comment={props.comment} />
      <ProductCommentSectionFilterPannel
        {...props}
        handleFilter={handleFilter}
        onConfirmByMobile={reqCommentBySort}
        colorOrSizeOptionList={getColorOrSizeOptionList()}
        filterData={{
          withPicture,
          starList,
          colorList,
          sizeList,
          sortTypeObj
        }}
      />
    </div>
  )
}

export default withPlugin(ProductCommentSectionHeader)
