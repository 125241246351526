import React, { Component } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

const CHANGE_OF_TIMES = 23 // 变化次数
const CHANGE_GAP = 15 // 变化间隔的毫秒

class AnimatePrice extends Component {
  timer = null
  targetRef = null

  data = {
    price: null,
    isPlus: false
  }

  setData = params => {
    this.data = {
      ...this.data,
      ...params
    }
  }

  // 拼接数字和货币符
  getDisplayPrice = price => {
    const { currencySymbol, isCurrencySymbolPrefix } = this.props.$site.getSiteInfo()
    const displayPrice = isCurrencySymbolPrefix ? `${currencySymbol}${price.toFixed(2)}` : `${price.toFixed(2)}${currencySymbol}`

    return displayPrice
  }

  parsePrice = value => {
    if (typeof value === 'string') {
      return parseFloat(parseFloat(value).toFixed(2))
    }

    return value
  }

  writeRef = _price => {
    const price = this.parsePrice(_price)

    this.setData({
      price
    })
    if (this.targetRef) {
      this.targetRef.innerHTML = this.getDisplayPrice(price)
    }
  }

  startAnimate = () => {
    clearInterval(this.timer)

    this.timer = setInterval(() => {
      const diff = this.data.isPlus ? (this.data.price + this.data.amountPerChange) : (this.data.price - this.data.amountPerChange)
      const targetPrice = parseFloat(diff.toFixed(2)) // price 拿最新的，不要收拢到上面
      const isOverPropsPrice = this.data.isPlus ? (targetPrice > this.props.price) : (targetPrice < this.props.price)

      // 变化到等价之后停止变化
      if (isOverPropsPrice) {
        clearInterval(this.timer)
        this.writeRef(this.props.price)
        this.props.onFinish && this.props.onFinish()
        return
      }

      this.writeRef(targetPrice)
    }, CHANGE_GAP)
  }

  componentDidMount() {
    if (this.props.displayPrice) {
      this.writeRef(this.props.price)
    }
  }

  componentDidUpdate() {
    if (this.props.displayPrice) {
      const { price } = this.data

      if (typeof price !== 'number') { // 初始化
        this.writeRef(this.props.price)

      } else if (price !== parseFloat(this.props.price)) {
        this.setData({
          isPlus: this.props.price > price,
          amountPerChange: Math.max(Math.abs(((this.props.price - price) / CHANGE_OF_TIMES)), 0.01)
        })

        if (this.props.disabled) {
          this.writeRef(this.props.price)
        } else {
          this.startAnimate()
        }
      }
    }
  }

  render() {
    return <span ref={ref => this.targetRef = ref} />
  }
}


const NumChanger = withPlugin(props => {
  const { country } = props.$site.getSiteInfo()

  if (country !== 'US') { // 禁用动画以及美国之外的国家，使用 displayPrice
    return (<>{props.displayPrice}</>)
  }

  return (
    <AnimatePrice {...props} />
  )
})

export default withPlugin(NumChanger)