import React from 'react'

import AppFooter, { getDefaultTab } from '../Footer/AppFooter'
import styles from './AppIndexPage.module.css'

const FOOTER_HEIGHT = 47  /* height of AppFooter*/

const AppIndexPage = props => {

  return (
    <>
      <div className={styles.wrapper}>
        {props.children}
      </div>
      {
        !props.isUseAppHomeApp &&
        <AppFooter
          {...props}
        ></AppFooter>
      }
    </>
  )
}

export {
  getDefaultTab,
  FOOTER_HEIGHT
}

export default AppIndexPage
