import React from 'react'

import styles from './List.module.css'

const List = props => {
  return (
    <>
      {
        props.title && (
          <h3 className={styles.title}>{props.title}</h3>
        )
      }
      <ul className={styles.content}>
        {(props.items|| []).map(({ content }, i) => (
          <li key={i}>{content}</li>
        ))}
      </ul>
    </>
  )
}

export default List