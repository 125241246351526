import React from 'react'
import CartCouponCard from '../Coupon/CartCouponCard'
import Image from '../../common/Image/Image'

import styles from './HorizonCouponList.module.css'

const COUPON_CARD_THEME_COLOR = '#FDF1E5'

const HorizonCouponList = props => {
  if (!props.coupon || props.coupon.length === 0) {
    return null
  }

  return (
    <div className={styles.list}>
      {props.coupon.map(coupon => {
        // 兼容老版本
        if (!coupon.title) {
          return <Image
            className={styles.couponImage}
            objectFit='cover'
            key={coupon.id}
            src={coupon.couponImage}
            placeholder={{ width: coupon.couponImageWidth, height: coupon.couponImageHeight }}
          />
        }

        return (
          <div className={styles.couponBox} key={coupon.id}>
            <CartCouponCard
              {...props.cardOptions}
              coupon={coupon}
              bordered={true}
              backgroundColor={COUPON_CARD_THEME_COLOR}
            />
          </div>
        )
      })}
    </div>
  )
}

export default HorizonCouponList