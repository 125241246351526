import React, { useState, useRef, useEffect } from 'react'

import Image from '../../common/Image/Image'
import styles from './PictureDescription.module.css'
import cx from '../../../utils/className'
import { withPlugin } from '@flamingo_tech/funkgo'

const PictureDescription = props => {
  const isDesktop = props.$detector.isDesktop()
  const [expanded, setExpanded] = useState(true)
  const imgRef = useRef()


  useEffect(() => {
    const { height } = (imgRef && imgRef.current && imgRef.current.getBoundingClientRect()) || {}
    if (!isDesktop && height) {
      setExpanded(height < 800)
    }
  }, [isDesktop])

  if (!props.graphicDescription || props.graphicDescription.length <= 0) {
    return null
  }

  return (
    <div className={cx(styles.wrapper, expanded && styles.expanded)} onClick={expanded ? () => {} : () => setExpanded(true)} ref={imgRef}>
      {
        !expanded && <div className={styles.cover}>{props.$i18n.transl('common.viewMore')}<span className={styles.icon}>&#xe7b2;</span></div>
      }
      {
        props.graphicDescription.map((pic, index) => {
          return (
            <div key={index}>
              <Image src={pic.imageUrl} placeholder={{ width: pic.width, height: pic.height }} original lazy />
            </div>
          )
        })
      }
    </div>
  )
}

export default withPlugin(PictureDescription)