import React, { useState, useEffect, useContext } from 'react'
import { Range } from 'rc-slider';
import rangeStyle from './RangeSlider.css'
import styles from './FilterRange.module.css'
import cx from '../../../utils/className'
import { PluginContext } from '@flamingo_tech/funkgo'

const ICON_POSITION = {
  TOP: 'top',
  OUT: 'out'
}

const FilterRange = props => {
  const plugins = useContext(PluginContext)
  const { className = '', listClassName = '', iconPostion = ICON_POSITION.OUT, defaultShow = true, normalTitle = '', headerClassName = '', initRange = [] } = props
  const { filterKey, filters } = props.section
  const isDesktop = plugins.$detector.isDesktop()
  const selectedOptions = props.selectedOptions
  const rangeStart = Math.floor(filters[0].value)
  const rangeEnd = Math.floor(filters[1].value)
  const [showRange, setRangeShow] = useState(defaultShow)
  const range = selectedOptions[filterKey] || []
  const initialRange = [rangeStart, rangeEnd]
  const defaultRange = range.length > 0 ? range : initialRange
  const [currentRange, setCurrentRange] = useState(defaultRange)
  const symbol = filters[0].symbol

  const handleToggleItem = () => {
    setRangeShow(!showRange)
  }

  const onRangeChange = (value) => {
    setCurrentRange(value)
    props.onFilterChange({ ...props.selectedOptions, [filterKey]: value })
  }

  const handleClickClean = (ev) => {
    ev.stopPropagation()
    ev.preventDefault()
    setCurrentRange(initialRange)
    props.onFilterChange({ ...props.selectedOptions, [filterKey]: initRange })
  }

  const isRangeEquals = (rangeA, rangeB) => {
    return rangeA.join(',') !== rangeB.join(',')
  }

  useEffect(() => {
    if ((range.length === 0) && isRangeEquals(currentRange, defaultRange)) {
      setCurrentRange(defaultRange)
    }
  }, [range, currentRange, defaultRange])

  const mapRangePrice = (symbol, value) => {
    const { isCurrencySymbolPrefix } = plugins.$site.getSiteInfo()
    return isCurrencySymbolPrefix ? `${symbol}${value}` : `${value}${symbol}`
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, className)}>
      <div className={cx(styles.itemHeader, headerClassName, isDesktop && styles.inDesktop)} onClick={() => handleToggleItem()}>
        <span className={cx(styles.title, normalTitle)}>{plugins.$i18n.transl('core.cart.priceRange')}</span>
        {
          isDesktop && iconPostion === ICON_POSITION.OUT && isRangeEquals(initialRange, currentRange) && (
            <i className={styles.clean} onClick={(ev) => handleClickClean(ev)}></i>
          )
        }
        {
          showRange
          ? (isDesktop ? <i className={styles.arrowIcon}>&#xe60f;</i> : <i className={styles.arrowIcon}>&#xe66e;</i>)
          : (isDesktop ? <i className={styles.arrowIcon}>&#xe609;</i> : <i className={styles.arrowIcon}>&#xe7b2;</i>)
        }
      </div>
      {
        showRange && (
          <div className={cx(styles.itemWrapper, isDesktop && styles.inDesktop, listClassName)}>
            {
              currentRange && (
                <div className={styles.rangeInfo}>
                  {
                    iconPostion === ICON_POSITION.TOP && (
                      <div className={styles.cleanBox}>
                        <div className={styles.clean} onClick={(ev) => handleClickClean(ev)}></div>
                      </div>
                    )
                  }
                    <div className={styles.priceInfo}>
                    <span className={cx(styles.priceStart, !isDesktop && styles.notDesktop)}>{mapRangePrice(symbol, currentRange[0])}</span>
                    <span className={cx(styles.priceEnd, !isDesktop && styles.notDesktop)}>{mapRangePrice(symbol, currentRange[1])}</span>
                  </div>
                  <Range
                    style={rangeStyle}
                    min={rangeStart}
                    max={rangeEnd}
                    defaultValue={defaultRange}
                    value={currentRange}
                    onChange={value => onRangeChange(value)}
                    tipFormatter={value => `${value}%`}
                  />
                </div>
              )
            }
          </div>
        )
      }
    </div>
  )
}

FilterRange.ICON_POSITION = ICON_POSITION

export default FilterRange