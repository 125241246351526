import { BaseService } from '@flamingo_tech/funkgo'

import ProductService from './ProductService'
import LayoutService from './LayoutService'

import queryString from 'query-string'

class SalesEventService extends BaseService {
  static makePageDataArgs({ $router, handle, $detector }) {
    const { location } = $router
    const query = queryString.parse(location.search)

    return {
      tph: query.tph,
      handle,
      $detector
    }
  }

  constructor($http) {
    super($http)
    this.layoutService = new LayoutService($http)
    this.ProductService = new ProductService($http)
  }

  fetchTopProduct(tph) {
    return tph
      ? this.ProductService.fetchProduct(tph).catch(() => null)
      : Promise.resolve()
  }

  fetchPageData({ handle, tph, $detector }) {
    const isDesktop = $detector.isDesktop()
    const productReq = this.fetchTopProduct(tph)
    const layoutReq = this.layoutService.fetchAthena(handle, isDesktop)

    return Promise.all([productReq, layoutReq]).then(([product, layout]) => {
      return {
        topProduct: product,
        ...layout
      }
    })
  }

}

export default SalesEventService
