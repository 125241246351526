import queryString from 'query-string'

const parseGaEventArgs = (options = {}) => {
  const {
    category,
    action,
    label,
    value,
    params,
  } = options

  const nonInteraction = typeof options.nonInteraction === 'undefined'
    ? true
    : options.nonInteraction === true

  return {
    category,
    action,
    label,
    value,
    params,
    nonInteraction
  }
}

const parseEventPayload = (categoryOrOptions, action, label, value, params) => {
  if (typeof categoryOrOptions === 'string') {
    return parseGaEventArgs({
      category: categoryOrOptions,
      action,
      label,
      value,
      params
    })
  }

  return parseGaEventArgs(categoryOrOptions)
}

const parseEventOptions = categoryOrOptions => {
  if (typeof categoryOrOptions === 'object' && typeof categoryOrOptions.options === 'object') {
    return categoryOrOptions.options
  }
  return {}
}


const getPixelBySourceLocation = $storage => {
  if ($storage) {
    const sourceLocationStorage = $storage.create('source_location', { strategy: 'SESSION' })

    const sourceLocation = queryString.parseUrl(sourceLocationStorage.getItem() || '')

    if (sourceLocation.query && sourceLocation.query.pid) {
      return sourceLocation.query.pid
    }
  }

  return null
}

export {
  parseEventPayload,
  parseEventOptions,
  getPixelBySourceLocation
}