import React, { useContext } from 'react'
import ErrorPage from '../../common/ErrorPage/ErrorPage'
import LoadingPage from '../../common/LoadingPage/LoadingPage'
import FlexList from '../../common/List/FlexList'
import MultiColumns from '../../common/List/MultiColumns'
import Button from '../../common/Button/Button'
import ViewMoreButton from '../Product/ViewMoreButton'
import styles from './CollectionContent.module.css'
import cx from '../../../utils/className'
import { PluginContext } from '@flamingo_tech/funkgo'


const CollectionContent = props => {
  const { products = [] } = props.productList || {}

  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()
  const ListComponent = props.ProductListComponent || (isDesktop ? FlexList : MultiColumns)

  return (
    <>
      {
        props.loading
          ? (
            <LoadingPage></LoadingPage>
          )
          : (
            <>
              {
                products && !products.length && (
                  <div className={styles.errorContainer}>
                    <ErrorPage
                      key='errorPage'
                      title='core.collection.noResultFound'
                      error={null}
                      btn={null}
                      className={cx(styles.noFilterRes ,isDesktop && styles.isDesktop)}
                    />
                     {
                      !isDesktop &&  <Button className={styles.reselectBtn} onClick={props.onClickReselect}>{props.$i18n.transl('common.reselect')}</Button>
                    }
                  </div>
                )
              }
              <ListComponent
                {...props.productList}
                onReachEnd={!props.isDesktop && props.productList.onReachEnd}
                childItems={products.map(i => i.product)}
                className={cx(styles.listComponent, props.isDesktop ? styles.pcRightAreaBox : null)}
                columnClassName={styles.listColumn}
                columns={props.columns}
              />
              <div>{props.isDesktop && props.productList.hasNextPage && <ViewMoreButton onClick={props.productList.onReachEnd} />}</div>
            </>
          )
      }
    </>
  )
}

export default CollectionContent