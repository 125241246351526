import React, { useContext, useEffect } from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import withKlarnaEnabled from '../../../hooks/withKlarnaEnabled'


const KlarnaBar = props => {
  const plugin = useContext(PluginContext)

  const { locale, paymentLocale } = plugin.$site.getSiteInfo()

  const klarnaLocale = paymentLocale || locale

  useEffect(() => {
    if (props.total) {
      plugin.$bridge.refreshKlarnaOneSiteMessage()
    }
  }, [props.total, plugin.$bridge])

  return (
    <div className={props.className}>
      <klarna-placement
        data-key="credit-promotion-auto-size"
        data-locale={klarnaLocale.replace('_', '-')}
        data-purchase-amount={parseInt(props.total * 100)}
      ></klarna-placement>
    </div>
  )
}


export default withKlarnaEnabled(KlarnaBar)