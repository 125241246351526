import React from 'react'

import StorePageShell from '../../../components/Shell/Page/StorePageShell'

const StoreLayoutContainer = props => (
  <StorePageShell
    naviItems={props.naviItems}
    {...props}
  >
    {props.children}
  </StorePageShell>
)

// since this page will be used as sub container, so it need to add plugin by itself
export default StoreLayoutContainer
