import React, { PureComponent } from 'react'
import InView from '../InView/InView'
import { withPlugin } from '@flamingo_tech/funkgo'

import { rem as px2rem } from '../../../utils/remUtils'
import { loadYouTubeIframeAPI, onYouTubeIframeAPIReady } from '../../../utils/youtubeIframeUtils'
import styles from './YouTubeIframeVideo.module.css'

const EVENT_CATEGORY = 'YouTubeVideo'

class YouTubeIframeVideo extends PureComponent {
  hasStartWatched = false
  lastProgress = 0
  watchTime = 0

  playerRef = React.createRef()
  intervalRef = null
  playerId = `youtube-player-${this.props.videoId}`
  componentDidMount() {
    loadYouTubeIframeAPI()
    onYouTubeIframeAPIReady(this.onYouTubeIframeAPIReadyCallback)
  }

  componentWillUnmount() {
    clearInterval(this.intervalRef)
    if (this.playerRef.current) {
      this.playerRef.current.destroy()
    }
  }

  onYouTubeIframeAPIReadyCallback = () => {
    this.playerRef.current = new window.YT.Player(this.playerId, {
      height: '315',
      width: '560',
      videoId: this.props.videoId,
      playerVars: {
        playsinline: 1,
        loop: 1,
        playlist: this.props.videoId,
        enablejsapi: 1,
        origin: window.location.origin
      },
      events: {
        onStateChange: this.onPlayerStateChange,
      },
    })
  }

  trackWatchPercentage = () => {
    const duration = this.playerRef.current.getDuration()

    this.intervalRef = setInterval(() => {
      const currentTime = this.playerRef.current.getCurrentTime()
      const progress = currentTime / duration

      if (!isNaN(duration) && duration > 0) {

        if (progress >= 0.50 && progress < 0.75 && !(this.lastProgress >= 0.50 && this.lastProgress < 0.75)) {
          this.trackEvent({ action: 'midpoint' })
        } else if (progress >= 0.90 && !(this.lastProgress >= 0.90)) {
          this.trackEvent({ action: 'complete' })
          this.trackWatchTime()
        }

        this.lastProgress = progress
        this.watchTime = currentTime

      }
    }, 1000)
  }

  onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      if (!this.hasStartWatched) {
        this.hasStartWatched = true
        this.trackEvent({ action: 'startWatch' })
      }

      this.trackWatchPercentage()
    } else if (event.data === window.YT.PlayerState.PAUSED || event.data === window.YT.PlayerState.ENDED) {
      clearInterval(this.intervalRef)
    }
  }

  trackEvent = ({ action, label }) => {
    this.props.$track.event(EVENT_CATEGORY, action, label, undefined, {
      id: `${this.props.trackName}_${this.props.videoId}`,
    })
  }

  trackWatchTime = () => {
    if (this.watchTime) {
      this.trackEvent({
        action: 'viewTime',
        label: this.watchTime.toFixed(2)
      })
    }

    this.watchTime = 0
  }

  handleInViewChange = (inView) => {
    if (inView) {
      this.trackEvent({ action: 'view' })
    } else {
      clearInterval(this.intervalRef)
      if (this.playerRef.current && typeof this.playerRef.current.pauseVideo === 'function') { // pause video
        this.playerRef.current.pauseVideo()
      }

      this.trackWatchTime()
    }
  }

  render() {
    const { padding = 15, backgroundColor = 'transparent', borderRadius = 6 } = this.props

    const wrapperStyle = {
      padding: `${px2rem(padding)}`,
      backgroundColor
    }

    const bodyStyle = {
      borderRadius: `${px2rem(borderRadius)}`
    }

    return (
      <InView onChange={this.handleInViewChange} threshold={0.8}>
        <div className={styles.wrapper} style={wrapperStyle}>
          <div className={styles.bodyWrapper}>
            <div className={styles.body} id={this.playerId} style={bodyStyle}></div>
          </div>
        </div>
      </InView>
    )
  }
}

export default withPlugin(YouTubeIframeVideo)
