import React from 'react'
import ReactDOM from 'react-dom'
import copy from 'copy-to-clipboard'

import ShareModal from './components/ShareModal'
import ShareDialog from '../../components/common/Share/ShareDialog'
import ShareHover from '../../components/common/ShareHover/ShareHover'
import { isServer } from '@flamingo_tech/funkgo/src/utils/isServer'

import {
  getCurrentOrigin,
  makeUrl
} from '@flamingo_tech/funkgo/src/utils/url'

import {
  createTimeoutPromise
} from '@flamingo_tech/funkgo-utils/promiseUtils'

import {
  navigateToSchemeWithFallback
} from '@flamingo_tech/funkgo-utils/visibilityChangeUtils'

/* ----------------------------------------- */

const EVENT_CATEGORY = 'Share'
const DEFAULT_UTM_SOURCE = 'FlamingoShare'
const DEFAULT_UTM_MEDIUM = ''
const SHARE_LIST = [{
  key: 'messenger',
  icon: 'https://img.flamingo.shop/p/show/67d67e892e7c4297938cd12851a0ff78.png',
  name: 'Messenger'
}, {
  key: 'whatsApp',
  icon: 'https://img.flamingo.shop/p/show/88abc4741ddb44ae871138df777253e1.png',
  name: 'WhatsApp'
}, {
  key: 'Message',
  icon: 'https://img.flamingo.shop/p/show/a0e17fb93b3d44db9fc01f683dbd2c0a.png',
  name: 'Message',
  type: 'link',
  onlyForMobile: true
},{
  key: 'fb',
  icon: 'https://img.flamingo.shop/p/show/b6f00104009145a4af25236d722a9f76.png',
  name: 'Facebook'
}, {
  key: 'twitter',
  icon: 'https://img.flamingo.shop/p/show/a9dfe32431e04277b949f27ffc12fe61.png',
  name: 'Twitter'
}, {
  key: 'pinterest',
  icon: 'https://img.flamingo.shop/p/show/365ec05df82245e29cddb923be880711.png',
  name: 'Pinterest'
}]
const DEFAULT_SHARE = {
  key: 'link',
  icon: 'https://img.flamingo.shop/p/show/0d998e2bed32417f93d10ea91031f951.png',
  name: 'Copy Link'
}
const MORE_SHARE = {
  key: 'more',
  icon: 'https://img.flamingo.shop/p/show/1c678c37d8254fb38178f8d553a83bd0.png',
  name: 'More'
}

class SharerBridge {
  constructor({ context }) {
    this.context = context // BridgePluginInstance

    this.removeShade = this.removeShade.bind(this)
    this.handleFbShare = this.handleFbShare.bind(this)
    this.handleMessengerShare = this.handleMessengerShare.bind(this)
    this.handleTwitterShare = this.handleTwitterShare.bind(this)
    this.handleWhatsAppShare = this.handleWhatsAppShare.bind(this)
    this.handlePinterestShare = this.handlePinterestShare.bind(this)
    this.handleCopy = this.handleCopy.bind(this)

  }

  getOpenGraphData(prop) {
    const meta = document.querySelector(`meta[property="${prop}"]`)
    return meta ? meta.getAttribute('content') : ''
  }

  getCurrentPlainUrl() {
    const pluginHub = this.context.pluginHub
    const appInfo = pluginHub.getAppInfo()

    const currentOrigin = getCurrentOrigin() || `https://${appInfo.domain}`
    return `${currentOrigin}${document.location.pathname}`
  }

  parseShareInfo({ url, title, subTitle, desc, image, btnTitle, utmSource, utmMedium } = {}) {
    const shareUrl = url || this.getOpenGraphData('og:url') || this.getCurrentPlainUrl()
    const finalUrl = makeUrl(shareUrl, {
      utm_source: utmSource || DEFAULT_UTM_SOURCE,
      utm_medium: utmMedium || DEFAULT_UTM_MEDIUM
    })

    return {
      url: finalUrl,
      title: title || this.getOpenGraphData('ot:title') || document.title,
      subTitle: subTitle || desc || '',
      desc,
      image: image || this.getOpenGraphData('og:image'),
      btnTitle: btnTitle,
      text: title || this.getOpenGraphData('ot:title') || document.title
    }
  }

  /* ----------------------------------------- */

  withVisibilityChange = (func, name) => (...args) => {
    return createTimeoutPromise((resolve, reject) => {
      // in case we have no ability to receive share success callback,
      //   , so trigger success once window hide and show again
      const handleVisibilityChange = () => {
        if (!document.hidden) {
          resolve()
          document.removeEventListener('visibilitychange', handleVisibilityChange)
        }
      }

      document.addEventListener('visibilitychange', handleVisibilityChange)

      // exec share method
      const result = func(...args)
      Promise.resolve(result).catch(reject) // if call failed, then reject it immediately

    }, 30000, `share:${name}`).catch(err => {
      console.error(err);
    })
  }

  /* ----------------------------------------- */

  shareByMessengerScheme = this.withVisibilityChange((shareInfo) => {
    const pluginHub = this.context.pluginHub
    const appInfo = pluginHub.getAppInfo()
    const fbAppId = appInfo.fbAppId

    if (!fbAppId) {
      return Promise.reject(`invalid fb app id for share: ${fbAppId}`)
    }

    const messengerScheme = makeUrl(
      'fb-messenger://share',
      {
        link: shareInfo.url,
        app_id: fbAppId,
      }
    )

    navigateToSchemeWithFallback(
      messengerScheme,
      () => this.shareByModalTip(shareInfo)
    )
  }, 'messengerScheme')

  shareByWhatsAppScheme = this.withVisibilityChange((shareInfo) => {
    const messengerScheme = makeUrl(
      'https://api.whatsapp.com/send',
      {
        text: shareInfo.title + ' ' + shareInfo.url//标题
      }
    )

    navigateToSchemeWithFallback(
      messengerScheme,
      () => this.shareByModalTip(shareInfo)
    )
  }, 'messengerScheme')

  // copy link
  shareByModalTip = this.withVisibilityChange((shareInfo, arrowDirection) => {
    // show modal if no arrowDirection show alert dialog
    if (arrowDirection) {
      let root = document.createElement('div')
      document.body.appendChild(root)

      const unmountComponent = () => {
        if (root) {
          ReactDOM.unmountComponentAtNode(root)
          root.parentNode.removeChild(root)
          root = null
        }
      }

      ReactDOM.render(
        <ShareModal
          arrowDirection={arrowDirection}
          onClose={unmountComponent}
        ></ShareModal>
      , root)
    } else {
      // Click me to copy the share link to clipboard. Copied!
      const pluginHub = this.context.pluginHub
      const $dialog = pluginHub.getPlugin('dialog')
      if ($dialog) {
        $dialog.alert({
          content: 'Share the link with your friends over text message, Messenger, Whatsapp, Snap, Email or any other chat programs. ',
          confirmLabel: 'Click Me to Copy'
        }).then(() => {
          copy(shareInfo.url)
          $dialog.toast('Copied!')
        })
      }
    }
  }, 'modalTip')

  /* ----------------------------------------- */

  // shareByFbDialogForTimeline(shareInfo) {
  //   return this.context.callFbSdk('ui', {
  //     method: 'share',
  //     href: shareInfo.url
  //   })
  // }

  shareByFbDialogForFriend(shareInfo) {
    return this.context.callFbSdk('ui', {
      method: 'send',
      link: shareInfo.url
    })
  }

  /* ----------------------------------------- */

  // no longer available after 5.4.2
  // shareByIOS(shareInfo) {
  //   this.callAppSdk('share', {
  //     shareType: 'Messenger',
  //     ...shareInfo,
  //     shareUrl: shareInfo.url,
  //     shareImageUrl: shareInfo.image,
  //   }).catch(() => {})
  //   return Promise.resolve()
  // }

  shareByAndroid(shareInfo) {
    this.context.callAppSdk('share', {
      shareType: 'Messenger',
      ...shareInfo,
      shareUrl: shareInfo.url,
      shareImageUrl: shareInfo.image,
    }).catch(() => {})
    return Promise.resolve()
  }

  /* ----------------------------------------- */

  isSupportWebShare() {
    return !!navigator.share
  }

  shareByNavigator(shareInfo) {
    return navigator.share(shareInfo).catch((err) => {
      // messenger 分享成功还是会提示 Abort due to cancellation of share
      // const $logger = this.context.pluginHub.getLogger().createLogger('Share')
      // $logger.error(err, 'shareByNavigator')
      return Promise.resolve()
    })
  }

  getCurrentOrigin = () => {
    if (!isServer()) {
      if (document.location.origin) {
        return document.location.origin
      } else {
        return `${document.location.protocol}//${document.location.host}`
      }
    }
    return ''
  }

  handleFacebookShare = shareInfo => {
    return this.context.callFbSdk('ui', {
      method: 'share',
      href: shareInfo.url
    })
  }

  /* ----------------------------------------- */
  removeShade() {
    if (this.shade) {
      ReactDOM.unmountComponentAtNode(this.shade)
      this.shade.parentNode.removeChild(this.shade)
      this.shade = null
    }
  }

  // facebook
  handleFbShare(shareInfo, eventCategory) {
    this.$track.event(eventCategory, 'click_share', 'fb')
    this.handleFacebookShare(shareInfo)
  }

  // Messenger
  handleMessengerShare = (shareInfo, eventCategory) => {
    this.$track.event(eventCategory, 'click_share', 'messenger')
    this.shareByMessengerScheme(shareInfo)
  }

  // Twitter
  handleTwitterShare = (shareInfo, eventCategory) => {
    this.$track.event(eventCategory, 'click_share', 'twitter')
    window.open(`https://twitter.com/share?text=${shareInfo.url}&url=%20`)
  }

  // WhatsApp
  handleWhatsAppShare = (shareInfo, eventCategory) => {
    this.$track.event(eventCategory, 'click_share', 'whatsApp')
    this.shareByWhatsAppScheme(shareInfo)
  }

  // Pinterest
  handlePinterestShare = (shareInfo, eventCategory) => {
    this.$track.event(eventCategory, 'click_share', 'pinterest')

    const messengerScheme = "http://pinterest.com/pin/create/button/?url=" + encodeURIComponent(shareInfo.url) + '&media=' + shareInfo.image + "&description=" + shareInfo.title

    navigateToSchemeWithFallback(
      messengerScheme,
      () => {}
    )
  }

  // link copy
  handleCopy = (shareInfo, eventCategory) => {
    this.$track.event(eventCategory, 'click_share', 'link')
    copy(shareInfo.url)
    this.$dialog.toast(this.$i18n.transl('core.order.orderReward.linkCopied'))
    this.removeShade()
  }

  allowCloseShare = true

  closeShare = () => {
    if (this.allowCloseShare) {
      this.removeShade()
    }
    return Promise.resolve()
  }

  initPlugin = () => {
    this.$i18n = this.context.pluginHub.getPlugin('i18n')
    this.$dialog = this.context.pluginHub.getPlugin('dialog')
    this.$track = this.context.pluginHub.getPlugin('tracker')
    this.$bridge = this.context.pluginHub.getPlugin('bridge')
    this.isDesktop = this.context.pluginHub.getDetector().isDesktop()
  }

  share(params = {}) {
    const $detector = this.context.pluginHub.getDetector()
    const shareInfo = this.parseShareInfo(params)

    // share 优先级
    // 1.navigator.share
    // 2.蒙层+箭头
    // 3.messenger schema(webview 不支持、没装messenger) fallback:蒙层+箭头
    if (this.isSupportWebShare()) {
      return this.shareByNavigator(shareInfo)
    } else if ($detector.isApp()) {
      if ($detector.isIOS()) {
        return this.shareByModalTip(shareInfo)
      } else {
        return this.shareByAndroid(shareInfo)
      }
    } else if ($detector.isInstagram()) {
      if ($detector.isIOS()) {
        return this.shareByModalTip(shareInfo, 'bottom')
      } else {
        return this.shareByModalTip(shareInfo, 'top')
      }
    } else if ($detector.isMessenger() || $detector.isFacebook()) {
      return this.shareByModalTip(shareInfo, 'top')
    } else if ($detector.isMobile() || $detector.isTablet()) {
      return this.shareByMessengerScheme(shareInfo)
    } else {
      return this.shareByFbDialogForFriend(shareInfo)
    }
  }

  multiShare({ shareInfo, eventCategory = EVENT_CATEGORY, targetRef } = {}){
    // shareInfo required: url、title、image

    if (!shareInfo) {
      throw Error('shareInfo is required!')
    }
    this.removeShade()
    this.initPlugin()

    const renderComponentSettings = {}
    if (this.isDesktop) {
      renderComponentSettings.onMouseOver = () => {
        this.allowCloseShare = false
      }
      renderComponentSettings.onMouseLeave = () => {
        this.allowCloseShare = true
        this.closeShare()
      }
    }

    this.shade = document.createElement('div')
    document.body.appendChild(this.shade)

    const RenderComponent = this.isDesktop ? ShareHover : ShareDialog

    ReactDOM.render(
      (
        <RenderComponent
          shareList={SHARE_LIST}
          shareLink={DEFAULT_SHARE}
          shareMore={MORE_SHARE}
          {...renderComponentSettings}
          shareInfo={shareInfo}
          eventCategory={eventCategory}
          headerTitle={this.$i18n.transl.bind(this)('common.shareTo')}

          onClose={this.removeShade}
          onFbShare={this.handleFbShare}
          onMessengerShare={this.handleMessengerShare}
          onTwitterShare={this.handleTwitterShare}
          onWhatsAppShare={this.handleWhatsAppShare}
          onPinterestShare={this.handlePinterestShare}
          onCopy={this.handleCopy}
          onShareByNavigator={this.shareByNavigator}
          targetRef={targetRef}
        />
      ),
      this.shade
    )

    return {
      close: this.closeShare
    }
  }
}

export default SharerBridge