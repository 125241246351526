import React, { useState, useContext, useEffect } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import AppStateContext from '../../../../hooks/AppStateContext'

import BigSaleModal from '@components/Store/Coupon/BigSaleModal'

const BigSaleContainer = props => {
  const plugin = useContext(PluginContext)
  const _storage = plugin.$storage.create('big_sale_operated', { strategy: 'SESSION' })
  const beginSupplementStorage = plugin.$storage.create('begin_supplement_wheel', { strategy: 'SESSION' }) // 加车弹弹弹


  const appState = useContext(AppStateContext)

  const showPopup = useState(!_storage.getItem())

  useEffect(() => {
    if ((appState.isMetaFetched && !appState.globalPromotionMeta.promoConfig) || _storage.getItem()) { // 没有大促配置或者已经交互过，直接流转到下面流程
      props.toNext()
    }
  }, [props, appState, _storage])


  if (appState.isMetaFetched && appState.globalPromotionMeta && appState.globalPromotionMeta.promoConfig) {

    if (showPopup) {
      const handleCloseCard = () => {
        const closeLoading = plugin.$createLoading()

        beginSupplementStorage.setItem(true) // 开启加车弹弹弹flow

        plugin.$track.event({
          category: 'BigSaleModal',
          action: 'close',
          nonInteraction: false
        })

        const { couponIds = [] } = appState.globalPromotionMeta.promoConfig

        plugin.$store.couponHub.batchTake(couponIds.map(id => ({
          id
        }))).then(() => {
          plugin.$track.event({
            category: 'BigSaleModal',
            action: 'claim_success',
            nonInteraction: false
          })

          closeLoading && closeLoading()
          plugin.$toastSuccess(plugin.$i18n.transl('core.promotion.bigSale.claimSuccessfully'))

          _storage.setItem(true)
          // 继续进去其余flow
          props.toNext()
        }).catch(() => {
          closeLoading && closeLoading()

          _storage.setItem(true) // 领券失败仍关闭，不阻碍后续流程
          // 继续进去其余flow
          props.toNext()
        })
      }

      return (
        <BigSaleModal
          {...appState.globalPromotionMeta.promoConfig}
          onClose={handleCloseCard}
        />
      )
    }

    return null
  }

  return null
}

export default BigSaleContainer