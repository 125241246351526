import BaseService from '@flamingo_tech/funkgo/src/base/BaseService'
import CouponCenterModel from '../models/CouponCenterModel'
import CouponModel from '../models/CouponModel'

import {
  retryIfTimeout,
} from '../../../utils/requestUtils'

export default class CouponService extends BaseService {

  applyCouponCenter(coupons) {
    return retryIfTimeout(
      () => this.post( '/client/api/v2/coupon/applyCouponCenter', {
        couponList: coupons.map(coupon => ({
          couponId: coupon.id,
          highlightFlag: (coupon.userOptions && coupon.userOptions.highlight) || false
        }))
      }).then(data => new CouponCenterModel(data))
    )
  }

  getCouponCenter(id) {
    return retryIfTimeout(
      () => this.get('/client/api/v3/coupon/couponCenter', {
        params: {
          couponCenterId: id,
        }
      }).then(data => new CouponCenterModel(data))
    )
  }

  takeCoupons(id, coupons, { shoppingCartId }) {
    return retryIfTimeout(
      () => this.post('/client/api/v2/coupon/takeCoupon', {
        couponCenterId: id,
        couponList: coupons.map(coupon => ({
          couponId: coupon.id,
          highlightFlag: coupon.highlight || false
        })),
        shoppingCartId
      }).then(data => new CouponCenterModel(data))
    )
  }

  takeCouponCodes(id, coupons, { shoppingCartId }) {
    return retryIfTimeout(
      () => this.post('/client/api/v2/coupon/takeCouponByCode', {
        couponCenterId: id,
        couponList: coupons.map(coupon => ({
          couponCode: coupon.code,
          highlightFlag: coupon.highlight || false
        })),
        shoppingCartId
      }).then(data => new CouponCenterModel(data))
    )
  }

  fetchCoupons(couponIds) {
    return this.post('/client/api/v2/coupon/fetchCoupon', {
      couponIdList: couponIds
    }).then(data => data.map(coupon => CouponModel.transferBE2FECouponModel(coupon)))
  }

  syncCouponCenter(id, { shoppingCartId }) {
    return retryIfTimeout(
      () => this.put('/client/api/v3/coupon/syncCoupon', {
        couponCenterId: id,
        shoppingCartId
      }).then(data => new CouponCenterModel(data))
    )
  }

}