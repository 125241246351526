import { BaseService } from '@flamingo_tech/funkgo'
import createStorage from '../utils/cacheUtils'
const RECENT_VIEW_LIMITED = 30

class RecentViewService extends BaseService {

  constructor($http) {
    super($http)
    if (!$http) {
      throw new Error('must provide http to init RecentViewService')
    }
  }

  localRecentViewStorage = createStorage('recent_view')

  updateRecentView(data) {
    this.localRecentViewStorage.setItem(data)
  }

  fetchRecentViewList() {
    return Promise.resolve(this.getLocalReview())
  }

  getLocalReview() {
    return this.localRecentViewStorage.getItem({ products: [] })
  }

  composeProducts(data, currentProduct) {
    const newProducts = [currentProduct, ...data.products]

    //为了考虑性能和体验 足迹本地存储不超过30个
    if (newProducts.length > RECENT_VIEW_LIMITED) {
      return newProducts.slice(0, RECENT_VIEW_LIMITED)
    } else {
      return newProducts
    }
  }

  putNewProduct(currentProduct) {
    return this.fetchRecentViewList().then(data => {
      const handle = data.products.map(item => item.handle)
      if (handle.indexOf(currentProduct.handle) > -1) {
        return data
      } else {
        data.products = this.composeProducts(data, currentProduct)
        return this.updateRecentView(data)
      }
    })
  }
}

export default RecentViewService
