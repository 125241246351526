import React from 'react'

import OriginalProductList from '../Store/ProductList/ProductList'

import OriginalDualColumns from '../common/List/DualColumns'
import OriginalHorizontalColumns from '../common/List/HorizontalColumns'
import OriginalMultiColumns from '../common/List/MultiColumns'

export const isProductList = (is, options) => (
  (options && options.isProductList) || (is === 'DualColumns' && options && options.childIs === 'ProductCard')
)

const withFlattenProps = Component => {
    // 此处设计失误。ProductList的prop应该是放在options下，并且直接透传，如
  // options: {
  //    childIs: "productCard",
  //    productQueueId: xxxx
  // }
  // 但误放在childOptions下，变成
  // options: {
  //    childIs: "ProductCard"
  //    childOptions: { productQueueId: xxxx  }
  // }
  // 并且这套协议已经被客户端使用，因此在这边兼容
  const WrappedComponent = props => (
    <Component {...props} {...props.childOptions}></Component>
  )

  WrappedComponent.displayName = `withFlattenProps(${Component.displayName || Component.name})`
  return WrappedComponent
}

export const ProductList = withFlattenProps(OriginalProductList)
export const DualColumns = withFlattenProps(OriginalDualColumns)
export const HorizontalColumns = withFlattenProps(OriginalHorizontalColumns)
export const MultiColumns = withFlattenProps(OriginalMultiColumns)
