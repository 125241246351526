import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import styles from './CheckoutStep.module.css'
import cx from '../../../utils/className'

export const STEP_TYPE = {
  CART: 1,
  PAYMENT: 2,
  ORDER: 3,
}

const CheckoutStep = ({ activeKey }) => {
  const plugin = useContext(PluginContext)

  const setps = [
    {
      key: STEP_TYPE.CART,
      content: plugin.$i18n.transl('core.cart.shoppingCart'),
    },
    {
      key: STEP_TYPE.PAYMENT,
      content: plugin.$i18n.transl('core.checkout.step.payment'),
    },
    {
      key: STEP_TYPE.ORDER,
      content: plugin.$i18n.transl('core.checkout.step.order'),
    },
  ]

  return (
    <div className={styles.contentHeader}>
      {
        setps.map(item => {
          const { key, content} = item
          return <div className={cx(styles.step, activeKey >= key ? styles.highlighted : null)} key={key}>
          <span className={styles.title}>{key}</span>
          <span className={styles.content}>{content}</span>
        </div>
        })
      }
    </div>
  )
}

export default CheckoutStep
