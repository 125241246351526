import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import Link from '../../common/Link/Link'
import Image from '../../common/Image/Image'

import styles from './AppChannelCard.module.css'
import cx from '../../../utils/className'

const DEFAULT_APP_QR_CODE = 'https://img.flamingo.shop/p/files/qrcode.png?v=1600158331'

const AppChannelCard = props => {
  const { isToolTip, showTip = true } = props
  const plugin = useContext(PluginContext)

  const handleMouseLeave = () => {
    if (props.handleMouseLeave) {
      props.handleMouseLeave()
    }
  }

  const qrCode = props.appQrCode || DEFAULT_APP_QR_CODE
  const priceOff = props.priceOff || plugin.$i18n.transl('app.channelCard.discount')

  return (
    <div className={cx(styles.wrapper, isToolTip && styles.toolTip)} onMouseLeave={handleMouseLeave}>
      <div className={styles.tipTitle}>{plugin.$i18n.transl('app.channelCard.app')}</div>
      {
        showTip &&
        <div className={styles.tipContent}>
          <p className={styles.desc1}>{plugin.$i18n.transl('app.channelCard.downloadTip')}</p>
          <p className={styles.priceOff}>{priceOff}</p>
        </div>
      }
      <div className={styles.tipApp}>
        <Image className={styles.appQrCode} src={qrCode}/>
        <p className={styles.line}></p>
        <div className={styles.downApp}>
          <div className={styles.downAndroid}>
            <Link to={'https://play.google.com/store/apps/details?id=com.flamingo.shop'}>Android</Link>
          </div>
          <div className={styles.downIphone}>
            <Link to={'https://apps.apple.com/app/apple-store/id1436939490'}>iPhone</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppChannelCard