import React, {
  useCallback,
  useContext,
} from 'react'

import { PluginContext } from '@flamingo_tech/funkgo'

import Link from '../Link/Link'

import {
  attachTabKeyToHash,
  mixinTabKeyToLink,
} from './utils/tabUtils'

import cx from '../../../utils/className'

/* ----------------------------------------------- */

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

const TabLink = props => {
  const {
    onTabChange,
    tab,
    tabParamName,
    selected,
    className,
    selectedClassName,
    render,
    needTabKeyToHash = true
  } = props

  const plugin = useContext(PluginContext)

  /* ----------------------- */
  const handleTabChange = useCallback(ev => {
    if (!isModifiedEvent(ev)) {
      ev.stopPropagation()
      ev.preventDefault()
      onTabChange(tab)

      if (needTabKeyToHash) {
        attachTabKeyToHash(tab, tabParamName) // attach hash to url, so that if user back, can restore correctly
      }
    }
  }, [tab, onTabChange, tabParamName, needTabKeyToHash])

  /* ----------------------- */

  let content = tab.title

  if (typeof render === 'function') {
    content = render({
      tab,
      selected
    })
  }
  /* ----------------------- */

  return (
    <Link
      block
      className={cx(className, selected && selectedClassName)}
      to={mixinTabKeyToLink(tab, plugin.$router.location, tabParamName)}
      onClick={handleTabChange}
    >
      {content}
    </Link>
  )
}

export default TabLink