import React, { useState, useEffect, useRef } from 'react'
import { debounce } from 'debounce'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './withBackToTop.module.css'

const SCROLL_DISTANCE = 1000

const withBackToTop = Component => {
  const WrappedComponent = props => {
    const [showButton, setShowButton] = useState(false)
    const isMounted = useRef(true)
    const isDesktop = props.$detector.isDesktop()

    const isBrowser = typeof window !== 'undefined'

    const handleClick = ev => {
      if (isBrowser) {
        window.scrollTo(0, 2) // if move to top=0, user may easy to close window at instagram webview
        setShowButton(false) // hide button instantly
      }
    }

    useEffect(() => {
      isMounted.current = true
      const scrollHandler = debounce(() => {
        if (isMounted.current) {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
          if (scrollTop > SCROLL_DISTANCE) {
            !showButton && setShowButton(true)
          } else {
            showButton && setShowButton(false)
          }
        }
      }, 100)

      if (isBrowser) {
        window.addEventListener('scroll', scrollHandler)
      }

      return () => {
        isMounted.current = false

        if (isBrowser) {
          window.removeEventListener('scroll', scrollHandler)
        }
      }
    }, [isBrowser, showButton])


    return (
      <div className={styles.wrapper}>
        <Component {...props}></Component>
        {
          showButton && !isDesktop &&
          <div className={styles.backToTopBtn} onClick={handleClick}>&#xe658;</div>
        }
      </div>
    )
  }

  WrappedComponent.displayName = `withBackToTop(${Component.displayName || Component.name})`

  return withPlugin(WrappedComponent)
}

export default withBackToTop
