import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import styles from './CategoryPage.module.css'
import cx from '../../../utils/className'

import CategoryDetailSection from './CategoryDetailSection'
import Link from '../../common/Link/Link'

// import categories_v1 from './categories_v1.json'
class ProductCategoryPage extends PureComponent {
  state = {
    currentIndex: 0
  }

  componentDidMount() {
    document.body.classList.add(styles.noScroll)
  }

  componentWillUnmount() {
    document.body.classList.remove(styles.noScroll)
  }

  render() {
    const currentItem = this.props.items[this.state.currentIndex]

    return (
      <div className={styles.categoryPage}>
        <div className={styles.navigateGroup}>
          {
            this.props.items.map((i, index) => {
              if (i.layout) {
                return (
                  <div
                    key={index}
                    className={cx(styles.navigateItem, index === this.state.currentIndex && styles.active)}
                    onClick={() => {
                      const childTrack = typeof i.link === 'string' ? i.link : i.title
                      this.props.$track.event('Categories', 'naviToPage', childTrack)
                      this.setState({ currentIndex: index })
                    }}
                  >{i.title}</div>
                )
              }
              return (
                <Link
                  to={i.link}
                  key={i.key}
                  className={styles.navigateItem}
                >
                  <span className={styles.navigateLink}>{i.title}</span>
                </Link>
              )
            })
          }
        </div>

        {
          currentItem && (
            <div key={this.state.currentIndex} className={styles.LayoutContent}>
              <CategoryDetailSection {...currentItem} meta={{}} />
            </div>
          )
        }
      </div>
    )
  }
}

export default withPlugin(ProductCategoryPage)
