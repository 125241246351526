import React from 'react'

const withSliderTouch = (Component) => {
  return class extends React.Component {
    state = {
      disableClick: false
    }

    render() {
      return (
        <div 
        onMouseDown={() => {
          this.setState({
            disableClick: false
          })
        }}
        onMouseMove={() => {
          if(!this.state.disableClick) {
            this.setState({
              disableClick: true
            })
          }
        }}
        onMouseLeave={() => {
          this.setState({
            disableClick: false
          })
        }}
        >
          <Component {...this.props} disableClick={this.state.disableClick}/>
        </div>
      )
    }
  }
}

export default withSliderTouch