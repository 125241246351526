import React, { useState, useContext } from 'react'

import Search from '../../common/Search/Search'
import SearchBar from '../../../components/Store/Search/SearchBar'
import SearchPage from '../../../components/Store/Search/SearchPage'
import { PluginContext } from '@flamingo_tech/funkgo'

import styles from './SearchStickyHeader.module.css'
import SearchContainer from '../../../containers/Store/SearchContainer'
import Link from '../../common/Link/Link'

const SearchStickyHeader = props => {

  const plugin = useContext(PluginContext)
  const $detector = plugin.$detector

  const [isDisplay, setIsDisplay] = useState(false)

  const handleDisplay = () => {
    if (!isDisplay) {
      setIsDisplay(true)
    }
  }

  const handleCancel = () => {
    if (isDisplay) {
      setIsDisplay(false)
    }
  }


  //直接在首页上面显示搜索页面
  const getAppSearchHeader = () => {
    return <>
      <div className={styles.searchBar} onClick={handleDisplay}>
        <Search
          placeholder='Search'
          disabled={true}
          className={styles.search}
        />
        { props.children }
      </div>
      {
        isDisplay && (
          <SearchContainer
            mode={SearchBar.MODE.CANCEL}
            handleCancel={handleCancel}
            searchPageComponent={SearchPage}
          />
        )
      }
    </>
  }

  //通过Link跳转到新的搜索页面
  const getWebSearchHeader = () => {
    return (
      <Link to='SearchPage' block className={styles.searchBar}>
        <Search
          placeholder='Search'
          disabled={true}
          className={styles.search}
        />
      </Link>
    )
  }

  const ANDROID_VERSION = '5.3.3' //android 5.3.3以下的老版本会出现软键盘把页面整体顶起的bug，这里做兼容处理

  const renderSearchHeader = () => {
    const isBiggerThan = $detector.compareAppVersion({
      targetVersion: ANDROID_VERSION
    })
    const isAndroid = $detector.isAndroid()
    const isApp = $detector.isApp()
    //如果不是App env 或者是Android版本号低于5.3.3就通过Link跳转搜索，否则就直接在首页显示
    return (!isApp || (isAndroid && !isBiggerThan)) ? getWebSearchHeader() : getAppSearchHeader()
  }

  return (
    renderSearchHeader()
  )
}

export default SearchStickyHeader