import React, { useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import Link from '../../common/Link/Link'
import LanguageSelector from './LanguageSelector'

import AppStateContext from '../../../hooks/AppStateContext'
import { getSite } from '../../../utils/siteUtils'


import styles from './SiteSettingPanel.module.css'

const SiteSettingPanel = () => {

  const appState = useContext(AppStateContext)
  const plugin = useContext(PluginContext)
  const { site } = appState
  const { internationalStations } = getSite()

  return (
    <div className={styles.wrapper}>
      <LanguageSelector
        $i18n={plugin.$i18n}
        currentStation={site}
      ></LanguageSelector>
      {
        internationalStations.length > 1 &&
        <Link className={styles.link} block to='SiteSetting'>{plugin.$i18n.transl('shell.header.siteSetting.changeCountry')}</Link>
      }
    </div>
  )
}

export default SiteSettingPanel