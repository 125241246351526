const ONE_DAY_EQUALS_MILL_SECONDS = 24 * 3600 * 1000

export const formatDate = (date, format = 'MM/DD/YYYY') => {
  const dateObj = new Date(date)

  return format
          .replace(/MM/, dateObj.getMonth() + 1)
          .replace(/DD/, dateObj.getDate())
          .replace(/YYYY/, dateObj.getFullYear())
}

export const formatTime = (time, format = 'MM/DD/YYYY HH:mm') => {
  const timeObj = new Date(time)

  const year = timeObj.getFullYear()
  const month = timeObj.getMonth() + 1
  const day = timeObj.getDate()
  const hour = timeObj.getHours()
  const minute = timeObj.getMinutes()

   return format
   .replace(/MM/, month < 10 ? `0${month}`: month)
   .replace(/DD/, day < 10 ? `0${day}`: day)
   .replace(/YYYY/, year)
   .replace(/HH/,  hour < 10 ? `0${hour}`: hour)
   .replace(/mm/,  minute < 10 ? `0${minute}`: minute)
}


export const getTodayEnd = () => {
  const dateTime = new Date(new Date().toLocaleDateString()).getTime() + ONE_DAY_EQUALS_MILL_SECONDS - 1000
  const info = getCountdownInfo(new Date(dateTime))
  return info
}

export const getCountdownInfo = endDate => {
  if (!endDate) {
    return null
  }

  const leftTime = endDate - new Date()

  if (leftTime < 0) {
    return null
  } else {
    const leftDays = Math.floor(leftTime / ONE_DAY_EQUALS_MILL_SECONDS)
    const leftTimeExcludeDays = leftTime - leftDays * ONE_DAY_EQUALS_MILL_SECONDS

    return {
      days: leftDays,
      time: leftTimeExcludeDays
    }
  }
}

export const isValidDate = val => !isNaN(new Date(val).valueOf())

export const getUTCTimestamp = () => {
  const date = new Date()
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  ).getTime()
}

export const getNextNinetyDays = timeStamp => {
  if (!timeStamp || timeStamp === 0) {
    return ''
  }

  return `${formatDate(timeStamp)} ~ ${formatDate(timeStamp + 90*24*60*60*1000)}`
}

export const getNextSevenDays = timeStamp => {
  if (!timeStamp || timeStamp === 0) {
    return ''
  }

  return `${formatDate(timeStamp)} ~ ${formatDate(timeStamp + 7*24*60*60*1000)}`
}