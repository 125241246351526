import React from 'react'
import { SafePayments, SecureLogistics, SecurePrivacy, PurchaseProtection } from '../ShoppingSecurity/ShoppingSecurity'
import styles from './ShoppingSecurityModal.module.css'
import cx from '../../../utils/className'
import { withDrawer } from '../Drawer/Drawer'

const ShoppingSecurityModal = withDrawer(props => {
  const isDesktop = props.$detector.isDesktop()

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop)}>
      <SafePayments className={styles.bordered} groupClassName={cx(styles.cardGroup, isDesktop && styles.isDesktop)} />
      <SecureLogistics className={styles.bordered} />
      <SecurePrivacy className={styles.bordered} />
      <PurchaseProtection className={styles.bordered} />
    </div>
  )
})

export default ShoppingSecurityModal