import React from 'react'

import cx from '../../../utils/className'

import styles from './Tooltip.module.css'

const DIRECTION = {
  UP: styles.up,
  DOWN: styles.down,
  LEFT: styles.left,
  RIGHT: styles.right
}

const POINTER = {
  HAND: 'hand',
  ARROW: 'arrow',
  NONE: 'none'
}

const BODY_STYLE = {
  WHITE: styles.white,
  BLACK: styles.black,
  GRAY: styles.gray
}

const Tooltip = props => {
  const direction = props.direction || DIRECTION.UP
  let pointer
  if (props.pointer === POINTER.HAND) {
    pointer = (<div className={cx(styles.rect, props.pointerClassName)}>
                <div className={cx(styles.hand, direction)} />
              </div>)
  } else if (props.pointer === POINTER.NONE) {
    pointer = null
  } else {
    pointer = (<div className={cx(styles.rect, props.pointerClassName)}>
                <div className={cx(styles.triangle, direction)} />
              </div>)
  }

  return (
    <div className={cx(styles.wrapper, props.className, direction, props.bodyStyle)} style={props.style}>
      <div className={cx(styles.content, props.contentClassName)}>
        {props.children}
      </div>
      {pointer}
    </div>
  )
}

Tooltip.DIRECTION = DIRECTION
Tooltip.POINTER = POINTER
Tooltip.BODY_STYLE = BODY_STYLE

export default Tooltip
