const FIRST_SLICE_KEY = 'INITIAL'

const getProductFetchFlag = props => {
  if (props.collectionHandle) {
    let flag = `collection_${props.collectionHandle}`

    if (props.queryOptions) {
      const { sortKey, reverse } = props.queryOptions
      if (sortKey) {
        flag = `${flag}_${sortKey}`
      }
      if (reverse) {
        flag = `${flag}_${reverse}`
      }
    }
    return flag
  } else if (props.productsQuery) {
    return `search_${props.productsQuery}`
  }
  return undefined
}

const fetchProducts = (nextPageKey, props) => {
  const pagination = {
    fromCursor: nextPageKey === FIRST_SLICE_KEY ? undefined : nextPageKey,
    pageSize: props.pageSize
  }

  const productService = props.productService
  if (props.collectionHandle) {
    return productService.fetchCollection(
      props.collectionHandle,
      {
        ...pagination,
        ...props.queryOptions
      }
    )
  } else if (props.productsQuery) {
    return productService.searchProducts(
      props.productsQuery,
      pagination
    )
  } else {
    throw new Error('must provide collection handle or product queue handle to fetch products')
  }
}

const getNextPageKey = products => {
  const lastItem = products[products.length - 1]
  return lastItem ? lastItem.cursor : null
}


const extractProducts = items => {
  if (!items || !items.length) {
    return []
  }

  return items.map(item => {
    if (item && item.product) {
      return item.product // for list data from shopify collection or product queue, the obj looks like { cursor, product }
    }
    return item // for list data from specific product object
  })
}

const uniqueProducts = products => {

  const indexOfId = {}

  // if item is not contains shopify id, give it an unique key
  const getKey = (_, i) => i

  products.forEach((item, i) => {
    const key = getKey(item, i)
    if (indexOfId[key] === undefined) {
      indexOfId[key] = i
    }
  })

  return products.filter((item, i) => indexOfId[getKey(item, i)] === i)
}

export {
  FIRST_SLICE_KEY,
  getNextPageKey,
  extractProducts,
  uniqueProducts,
  fetchProducts,
  getProductFetchFlag
}
