import React, { useState, useContext } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import { SizeChartModal } from '../Product/ProductSizeChartSection'

import DesktopProductBasicInfoSection from '../Product/DesktopProductBasicInfoSection'
import Link from '../../common/Link/Link'
import ProductImage from '../../common/ProductImage/ProductImage'

import { withDrawer } from '../../common/Drawer/Drawer'
import styles from './ProductModal.module.css'
import cx from '../../../utils/className'

const ComponentProduct = props => {
  const plugin = useContext(PluginContext)
  const [showSizeChart, setShowSizeChart] = useState()
  const showSizeChartModal = () => {
    plugin.$track.event('ProductPage', 'click_size_chart', props.handle)
    setShowSizeChart(true)
  }

  const renderProductImages = (curImages) => {
    const displayImages = props.selectedVariants.Color ? curImages.filter(i => i.tag.optionValue === props.selectedVariants.Color) : curImages

    return (
      <div className={styles.imageGallery}>
        {
          displayImages.map((item, index) => {
            return (
              <div key={index}>
                <ProductImage {...item} className={styles.productImage} />
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderLink = () => {
    return (
      <Link to={props.to || { name: 'Product', params: { handle: props.handle } }} className={styles.toDetail}>{plugin.$i18n.transl('core.cart.seeFullDetails')}
      </Link>
    )
  }

  const closeSizeChartModal = () => setShowSizeChart(false)
  const {
    sizeChart = {},
    feature = {}
  } = props.meta || {}

  const { sizeChartTemplate } = props.product || {}

  return (
    <div className={styles.wrapper}>
      <DesktopProductBasicInfoSection
        {...props}
        className={styles.basicInfo}
        minSliderWrapper={true}
        showSizeChartModal={showSizeChartModal}
        renderSizeLink={true}
        renderProductImages={renderProductImages}
        renderLink={renderLink}
        hideVideo={true}
      />
      {
        showSizeChart &&
        <SizeChartModal
          $i18n={plugin.$i18n}
          productTitle={props.title}
          sizeChart={sizeChart}
          sizeChartTemplate={sizeChartTemplate}
          feature={feature}
          onClose={closeSizeChartModal}
          className={cx(styles.sizeChartPadding, styles.isDesktop)}
        />
      }
    </div>
  )
}

export default withDrawer(ComponentProduct, { needScroll: false })
