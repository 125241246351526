import React from 'react'
import Slider from '../../common/Slider/Slider'
import Image from '../../common/Image/Image'
import StarRating from './StarRating'

import styles from './UserReviewPage.module.css'

const ReviewCard = props => {
  const { avatar, name, review, rating } = props

  return (
    <div className={styles.card}>
      <Image
        src={avatar}
        className={styles.image}
        small
        lazy
      />
      <h4 className={styles.name}>{name}</h4>
      <h5 className={styles.review}>{review}</h5>
      <div className={styles.rating}>
        <StarRating star={rating} />
      </div>
    </div>
  )
}

const UserReviewPage = ({ reviews }) => {
  if (!reviews || reviews.length === 0) {
    return null
  }

  return (
    <Slider dots={true}>
      {
        reviews.map(review => (
          <ReviewCard key={review.name} {...review} />
        ))
      }
    </Slider>
  )
}

export default UserReviewPage
