import React, { PureComponent } from 'react'

import queryString from 'query-string'

import SearchPage from '../../components/Store/Search/SearchPage'
import Shell from '../Shell/PageShellContainer'

import StoreService from '../../services/StoreService'
import { withPlugin } from '@flamingo_tech/funkgo'

const EVENT_CATEGORY = 'Search'

const getSearchLink = query => ({
  name: 'Search',
  search: {
    q: query
  }
})

class SearchContainer extends PureComponent {
  static Shell = Shell
  static shellProps = {
    displayHeader: false,
    supportDesktop: true
  }

  historySearchStorage = this.props.$storage.create('search_history')

  hotSearchesStorage = this.props.$storage.create('hot_searches', { strategy: 'SESSION' })

  state = {
    defaultSearch: '',
    hotSearches: [],
    historySearches: this.historySearchStorage.getItem([])
  }

  static getFromPath(props) {
    return queryString.parse(props.location.search).from
  }

  static fetchOpenGraphInfo() {

    return {
      title: 'Search - Flamingals by Flamingo Shop',
    }
  }

  handleSearch = searchText => {
    const search = searchText && searchText.trim()

    if (search) {
      this.updateHistorySearches(search)

      this.props.$router.navigate(
        getSearchLink(search)
      )
    }
  }

  handleHotSearchClick = (searchItem, index) => {
    const { link, search } = searchItem

    if (link && typeof link === 'string') {
      this.props.$track.event(EVENT_CATEGORY, `${search}_withLink`, link)
    }

    // navigate via native link
    if (search) {
      this.updateHistorySearches(search)
    }

    this.props.$track.clickMainResourceLocator(`${EVENT_CATEGORY}:HotSearch_${search}`, {
      index,
      url: link || ''
    })
  }

  updateHistorySearches = searchText => {
    let historySearches = this.state.historySearches.filter(search => search !== searchText)
    historySearches.unshift(searchText)

    if (historySearches.length >= 20) {
      historySearches = historySearches.slice(0, 20)
    }

    this.writeHistorySearch(historySearches)
  }

  clearSearchHistory = () => {
    this.writeHistorySearch([])
  }

  writeHistorySearch(historySearches) {
    this.setState({
      historySearches
    })

    this.historySearchStorage.setItem(historySearches)
  }

  handleNavBack = () => {
    const fromPath = SearchContainer.getFromPath(this.props)

    if (fromPath) {
      this.props.$router.navigate(fromPath)
    } else {
      this.props.$router.goBack()
    }
  }

  componentDidMount() {

    const localHotSearches = this.hotSearchesStorage.getItem()

    if (!localHotSearches) {
      const storeService = new StoreService(this.props.$http)

      storeService.fetchSearchMeta().then(data => {
        this.hotSearchesStorage.setItem(data)
        if (data && data.hotSearches) {
          this.setState({
            hotSearches: this.composeHotSearches(data),
            defaultSearch: data.defaultSearch
          })
        }
      }).catch(() => {})
    } else {
      this.setState({
        hotSearches: this.composeHotSearches(localHotSearches),
        defaultSearch: localHotSearches.defaultSearch
      })
    }
  }

  composeHotSearches = (data) => {
    return (data.hotSearches || []).map(item => {
      if (!item.link && item.search) {
        item.link = getSearchLink(item.search)
      }
      return item
    })
  }

  render() {
    const SearchPageComponent = this.props.searchPageComponent || SearchPage
    return (
      <SearchPageComponent
        onBack={this.handleNavBack}
        onCancel={this.props.handleCancel}
        onSearch={this.handleSearch}
        mode={this.props.mode}
        onSearchHot={this.handleHotSearchClick}
        onClearSearchHistory={this.clearSearchHistory}
        {...this.state}
        {...this.props.data}
      />
    )
  }
}

export default withPlugin(SearchContainer)
