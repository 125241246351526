import createListener from '@flamingo_tech/funkgo/src/utils/createListener'

export default class EmailCoupon {
  constructor({ pluginHub }) {
    this.pluginHub = pluginHub
    this.emailCouponModeStorage = pluginHub.getStorage().create('_ecm', {
      expire: 1000 * 60 * 60 * 24 * 5 // 5 day
    })
    this.initListener()
  }

  setEmailCouponMode = (bool) => {
    this.emailCouponModeStorage.setItem(bool)
    this.notify(bool)
  }

  getEmailCouponMode = () => {
    return this.emailCouponModeStorage.getItem(false)
  }

  initListener() {
    const {
      subscribe,
      unsubscribe,
      notify
    } = createListener()

    this.subscribe = subscribe
    this.unsubscribe = unsubscribe
    this.notify = notify
  }


}