import React, { useRef } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './FilterSortBar.module.css'
import { getFilterNumByOptions } from '../../../utils/Store/sortUtils'
import SortDropDown from './SortDropDown'
import cx from '../../../utils/className'

const FilterSortBar = props => {
  const sortRef = useRef()
  const collectionFilter = props.filterData
  const queryOptions = props.queryOptions
  const filterNum = getFilterNumByOptions(queryOptions)

  const { Trans } = props.$i18n

  return (
    <div className={styles.wrapper}>
      <SortDropDown
        className={styles.sortdown}
        queryOptions={props.queryOptions}
        ref={sortRef}
        handleSort={props.onSort}
        eventCategoryName={props.eventCategoryName}
      />
      <div className={styles.lineBar}></div>
      {
        collectionFilter.length > 0 && (
          <div
            onClick={props.onFilter}
            className={cx(styles.filter, filterNum > 0 && styles.highlighted)}
            key={'filter'}>
            <Trans i18nKey={'core.collection.filter'}></Trans>
            {
              filterNum > 0 && <span>{`(${filterNum})`}</span>
            }
          </div>
        )
      }
    </div>
  )
}

export default withPlugin(FilterSortBar)