import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'

import styles from './ProductSizeGuide.module.css'
import cx from '../../../utils/className'

const SwitchBtn = props => {
  return (
    <div className={cx(styles.switchBtn, props.currentIndex === 0 ? styles.left : styles.right)} onClick={props.onClick}>
      <div className={styles.selected}>{props.currentIndex === 0 ? 'in.' : 'cm'}</div>
      <div className={styles.bg}>
        <span>in.</span>
        <span>cm</span>
      </div>
    </div>
  )
}

const ProductSizeGuide = props => {
  const { countryCode } = props.$site.getSiteInfo()
  const isDesktop = props.$detector.isDesktop()
  const [currentIndex, setCurrentIndex] = useState(countryCode === 'US' ? 0 : 1)

  const [trSub, setTrSub] = useState(-1)
  const [tdSub, setTdSub] = useState(-1)

  const handleOnClick = () => {
    setCurrentIndex(currentIndex === 0 ? 1 : 0)
  }

  const transl = key => {
    return props.$i18n.transl(`core.product.sizeGuideInfo.${key}`)
  }

  const handleItemClick = (trIndex, tdIndex) => {
    setTrSub(trIndex)
    setTdSub(tdIndex)
  }

  const sizeGuideInfo = [
    {
      title: 'yourBust',
      content: 'yourBustContent'
    },
    {
      title: 'yourWaist',
      content: 'yourWaistContent'
    },
    {
      title: 'yourHips',
      content: 'yourHipsContent'
    }
  ]

  const sizeList = props.sizeChartTemplate[currentIndex === 0 ? 'sizeInchInfo' : 'sizeCmInfo'] || []
  const isSmallSize = sizeList[0] && sizeList[0].length < 6

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.isDesktop, isSmallSize && styles.isSmallSize, props.className)}>
      <div><SwitchBtn currentIndex={currentIndex} onClick={handleOnClick} /></div>
      <div className={styles.tableBox}>
        <table className={styles.table}>
          <tbody>
            {
              sizeList.map((tr, trIndex) => {
                const activeTr = trSub === trIndex // 当前行是否高亮

                return (
                  <tr key={trIndex} className={cx(styles.trElement, activeTr && styles.active)}>
                    {
                      tr.map((td, tdIndex) => {
                        const activeTd = tdSub === tdIndex // 当前列是否高亮

                        if (isDesktop) {
                          return (
                            <td
                              key={tdIndex}
                              onMouseOver={trIndex !== 0 && tdIndex !== 0 ? (() => handleItemClick(trIndex, tdIndex)) : (() => {})}
                              onMouseLeave={() => handleItemClick(-1, -1)}
                              className={cx(styles.tdElement, tdSub === tdIndex && styles.active, activeTr && activeTd && styles.highlight)}
                            ><div><span>{td}</span></div></td>
                          )
                        }

                        return (
                          <td
                            key={tdIndex}
                            onClick={trIndex !== 0 && tdIndex !== 0 ? (() => handleItemClick(trIndex, tdIndex)) : (() => {})}
                            className={cx(styles.tdElement, tdSub === tdIndex && styles.active, activeTr && activeTd && styles.highlight)}
                          ><div><span>{td}</span></div></td>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>
      <div className={styles.guideTips}>{transl('sizeOffset')}</div>
      <div className={styles.guideInfo}>
        <h3 className={styles.infoHeader}>{transl('howToMeasure')}</h3>
        {
          sizeGuideInfo.map((item, index) => (
            <span key={index}>
              <h3>{index + 1}. {transl(item.title)}</h3>
              <p>{transl(item.content)}</p>
            </span>
          ))
        }
        <Image lazy className={styles.guideImage} src="https://img.flamingo.shop/p/show/2dc20e588ec7437d8396205b3bee81b1.png" />
      </div>
    </div>
  )
}

export default withPlugin(ProductSizeGuide)