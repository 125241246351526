import React, { PureComponent } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'
import { splitDecimal } from '../../../utils/numberUtils'
import styles from './StarRating.module.css'

const STAR_NUM = 5

const MODE = {
  VIEW: 'VIEW',
  EDIT: 'EDIT'
}

class Wrapper extends PureComponent {
  constructor(props) {
    super(props)
    this.mode = props.mode || MODE.VIEW
    this.state = {
      starStyle: {}
    }
    this.starRating = React.createRef()
  }

  handleStarClick = i => {
    if (this.mode === MODE.EDIT) {
      this.props.onGradeStar && this.props.onGradeStar( i + 1 )
    }
  }

  computedSolidStarWidth = i => {
    let { star } = this.props
    let { width = '0px' } = this.state.starStyle
    let solidStarWidth = 0

    let { decimalPart } = splitDecimal(star)

    if (star >= i+1) {
      solidStarWidth = width
    } else if (star > i && star < i+1) {
      solidStarWidth =  `${width.split('px')[0] * decimalPart}px`
    } else {
      solidStarWidth = '0px'
    }
    
    return solidStarWidth
  }

  render() {
    const isDesktop = this.props.$detector.isDesktop()

    return (
      <div className={cx(styles.wrapper, this.mode === MODE.EDIT ? styles.isEdit : (isDesktop && styles.isDesktop))}>
        {
          Array(STAR_NUM).fill({}).map((_, i) => {
            const handleClick = this.mode === MODE.EDIT
              ? () => this.handleStarClick(i)
              : null
            return (
              <div className={styles.starContainer}
                key={i}
                style={this.state.starStyle}
                onClick={handleClick}
              >
                <i className={cx(styles.starIcon, styles.starDarkIcon)} style={{...this.state.starStyle}}>&#xe607;</i>
                <div className={styles.solidStarBox} style={{ ...this.state.starStyle, width: this.computedSolidStarWidth(i) }}>
                    <i className={cx(styles.starIcon, this.props.starIconClass)} style={{...this.state.starStyle}}>&#xe607;</i>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }


  componentDidMount() {
    const isDesktop = this.props.$detector.isDesktop()
    let starSize = isDesktop ? 18 : 15
    if(this.mode === MODE.EDIT) starSize = 22

    this.setState({
      starStyle: {
        width: `${starSize}px`,
        height: `${starSize}px`,
      }
    })
  }
}

const StarRating = withPlugin(Wrapper)

StarRating.MODE = MODE

export default StarRating
