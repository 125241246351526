import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import { InStandaloneApp } from '../../hooks/InApp'
import SearchStickyHeader from '../../components/Store/Search/SearchStickyHeader'
import FreeShippingBanner from '../../components/Widget/FreeShippingBanner/FreeShippingBanner'
import CartEntry from '../../components/Store/Cart/CartEntry'

import Layout from '../../components/Layout/Layout'
import PageShellContainer from '../Shell/PageShellContainer'

import SalesEventsService from '../../services/SalesEventsService'
import ProductService from '../../services/ProductService'

import * as seoUtils from '../../utils/seoUtils'

const HOME_HANDLE = 'new-home'
const SOURCE_LOCATOR = 'Shop'
// const FOR_RETURNING_USER = 'old-store-home'

/* --------------------------------------------------------- */
const fetchOpenGraphInfo = ({ data }, { $router }) => {
  const jsonLD = [
    seoUtils.forOrganization(),
    seoUtils.forWebsite(),
  ]

  const subLinks = seoUtils.extractGridLinksFromDSL(data)

  jsonLD.push(seoUtils.forItemListSummary([
    ...subLinks,
  ]))

  return {
    jsonLD,
    url: `${$router.location.origin}/store/` // for SEO
  }
}

const fetchPageData = ({ $http, $router, $detector }, handle = HOME_HANDLE) => {
  const service = new SalesEventsService($http)

  return service.fetchPageData(
    SalesEventsService.makePageDataArgs({ $router, handle, $detector })
  )
}

const PlainStoreHomeContainer = withPlugin(props => {

  const productService = new ProductService(props.$http)

  return (
    <>
      {
        !props.$detector.isUseAppHomeApp() &&
        <InStandaloneApp>
          <SearchStickyHeader>
            <CartEntry />
          </SearchStickyHeader>
          <FreeShippingBanner />
        </InStandaloneApp>
      }
      <Layout {...props.data} productService={productService} commonProps={{ sourceLocator: SOURCE_LOCATOR }}></Layout>
    </>
  )
})

/* --------------------------------------------------------- */

class StoreHomeContainer extends PureComponent {

  static fetchOpenGraphInfo = fetchOpenGraphInfo
  static fetchPageData = fetchPageData
  static fetchStaticShellProps = PageShellContainer.fetchStaticShellProps

  render() {
    // for ssr, need to pass data to sub component
    return (
      <PlainStoreHomeContainer {...this.props}></PlainStoreHomeContainer>
    )
  }
}

export {
  PlainStoreHomeContainer,
}

export default StoreHomeContainer
