import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

const withTouchListen = (Component) => {
  const WrappedComponent = props => {
    let scrollAreaRef = React.createRef()

    useEffect(() => {
      const scrollArea = scrollAreaRef.current

      if (!scrollArea) {
        return
      }

      let startY = null

      const handleTouchStart = ev => {
        startY = Math.floor(ev.targetTouches[0].clientY)
      }

      const handleTouchMove = ev => {
        const { clientY } = ev.targetTouches[0]

        const { scrollTop, scrollHeight, clientHeight } = scrollArea

        if (ev.cancelable && (
          (scrollTop <= 0 && clientY - startY > 0) // 向下滑到底
          || (scrollTop >= scrollHeight - clientHeight && clientY - startY < 0) // 向上滑到顶
        )
        ) {
          ev.preventDefault()
        }
      }

      ReactDOM.findDOMNode(scrollArea).addEventListener('touchstart', handleTouchStart, { passive: true })
      ReactDOM.findDOMNode(scrollArea).addEventListener('touchmove', handleTouchMove, { passive: false })

      return () => {
        ReactDOM.findDOMNode(scrollArea).removeEventListener('touchstart', handleTouchStart, { passive: true })
        ReactDOM.findDOMNode(scrollArea).removeEventListener('touchmove', handleTouchMove, { passive: false })
      }
    }, [scrollAreaRef])

    return <Component {...props} scrollAreaRef={scrollAreaRef} />
  }

  return props => <WrappedComponent {...props} />
}

export default withTouchListen
