import React, { useState, useRef } from 'react'
import Image from '../../common/Image/Image'
import Slider from '../../common/Slider/Slider'
import styles from './ProductSliderList.module.css'

import cx from '../../../utils/className'

const SLIDER_SIZE = 5

const ProductSliderList = props => {
  const [sliderIndex, setSliderIndex] = useState(0)
  const sliderRef = useRef()

  const slickPrev = () => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const slickNext = () => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }


  const renderSamplePrevArrow = () => {
    if (sliderIndex > 0) {
      return (
        <div className={styles.prevBtn} onClick={slickPrev} />
      )
    }

    return null
  }

  const renderSampleNextArrow = () => {
    if (sliderIndex + SLIDER_SIZE < props.items.length) {
      return (
        <div className={styles.nextBtn} onClick={slickNext} />
      )
    }

    return null
  }

  const renderCard = (render, item, index) => {
    if (typeof render === 'function') {
      return render(item, index)
    }

    return (
      <div className={cx(styles.defaultCardItem, props.isDesktop && styles.isDesktop)} key={index}>
        <Image src={item.image} small crop={{ width: 1, height: 1 }} placeholder={{ width: 1, height: 1 }} className={styles.image} objectFit="cover" />
      </div>
    )
  }

  if (props.isDesktop) {
    return (
      <div className={styles.sliderBox}>
        {renderSamplePrevArrow()}
        <Slider
          draggable={false}
          slidesToScroll={SLIDER_SIZE}
          variableWidth={true}
          ref={sliderRef}
          afterChange={x => setSliderIndex(x)}
        >
          {
            props.items.map((lineItem, i) => renderCard(props.renderCardComponent, lineItem, i))
          }
        </Slider>
        {renderSampleNextArrow()}
      </div>
    )
  }

  return (
    <div className={styles.scrollList}>
      {
        props.items.map((lineItem, i) => renderCard(props.renderCardComponent, lineItem, i))
      }
    </div>
  )
}

export default ProductSliderList