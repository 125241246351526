import React, { useState } from 'react'
import { withGlobalSetting } from '../../../hooks/withGlobalSettingEnabled'
import withCartAndPlugins from '../../../hooks/withCartAndPlugins'
import CoverCartContainer from '../../../containers/Store/CoverCartContainer'
import AtcCouponList from './AtcCouponList'
import EmptyCartContent from './EmptyCartContent'
import PriceSummaryList from './PriceSummaryList'
import CartItem from './CartItem'
import SelectIcon from '../CheckOut/SelectIcon'
import DesktopCartPreviewFooter from './DesktopCartPreviewFooter'
import CartPageSoldOutList from './CartPageSoldOutList'
import cx from '../../../utils/className'

import styles from './DesktopCartPreviewDrawer.module.css'

const EVENT_CATEGORY = 'DesktopCartPreviewDrawer'


const DesktopCartPreviewDrawer = withCartAndPlugins(withGlobalSetting(props => {
  const {
    checkoutItemCount,
    lineItems = [],
    displayFreeShippingFee,
    addOnsForFreeShippingAmount,
    displayAddOnsForFreeShippingAmount
  } = props.cart || {}

  const saleableLineItems = []
  const unSaleableLineItems = []
  const [showPriceCounts, setShowPriceCounts] = useState(false)
  const [loading, setLoading] = useState(false)
  const [numberChangeDisabled, setNumberChangeDisabled] = useState(true)

  lineItems.forEach(item => {
    if (item.saleable) {
      saleableLineItems.push(item)
    } else {
      unSaleableLineItems.push(item)
    }
  })
  const availableItemCount = saleableLineItems.filter(item => item.selectedFlag).length

  // 包邮凑单提示
  const renderShippingHint = () => {
    const { Trans } = props.$i18n
    let hintText = ''

    if (checkoutItemCount === 0) {
      hintText = <Trans i18nKey='core.cartAddOn.cart.needMoreGetShipping'>Need <span className={styles.highlight}>{{ displayAddOnsForFreeShippingAmount: displayFreeShippingFee }}</span> more to get Free Shipping</Trans>
    } else if (addOnsForFreeShippingAmount === 0) {
      hintText = <span className={styles.freeShipping}>{props.$i18n.transl('core.cartAddOn.cart.youGotFreeShipping') + '!'}</span>
    } else {
      hintText = <Trans i18nKey='core.cartAddOn.cart.needMoreGetShipping'>Need <span className={styles.highlight}>{{ displayAddOnsForFreeShippingAmount }}</span> more to get Free Shipping</Trans>
    }

    return (
      <div className={styles.tips}><div className={styles.container}>{hintText}</div></div>
    )
  }

  const handleClickCounts = () => {
    if (availableItemCount === 0) {
      return
    }
    if (showPriceCounts) {
      props.$track.event(EVENT_CATEGORY, 'close_cart_counts')
      setShowPriceCounts(false)
    } else {
      props.$track.event(EVENT_CATEGORY, 'view_cart_counts')
      setShowPriceCounts(true)
    }
  }

  const selectCart = (lineItem, isSelectAll) => {
    if (loading) {
      return
    }

    setLoading(true)
    props.onCartItemSelect(lineItem, isSelectAll)
      .then(() => {
        setLoading(false)
        const currentIsSelectAll = saleableLineItems.filter(i => i.selectedFlag).length === saleableLineItems.length
        if (!isSelectAll && currentIsSelectAll) {
          setShowPriceCounts(false)
        }
      })
      .catch(() => setLoading(false))
  }

  const onClearExpireItems = () => {
    props.onClearExpireItems()
  }

  const renderSaleableItems = () => {
    return (
      saleableLineItems.length > 0 ? (
        <>
          <div className={styles.selectedItemsTitle}>{props.$i18n.transl('core.checkout.items')} {`(${props.cartLineItemCount})`}</div>
          <div className={styles.selectedItems}>
            {
              saleableLineItems.map((lineItem) => (
                <div className={styles.cartItem} key={lineItem.id}>
                  <SelectIcon onClick={() => selectCart(lineItem)} selected={lineItem.selectedFlag} type={SelectIcon.TYPE.CHECKBOX} className={styles.selectIcon}/>
                  <CartItem
                    forceMobile={true}
                    {...lineItem}
                    needConfirmQuantity={false}
                    onChange={quantity => props.onCartItemQuantityChange({ lineItem, quantity })}
                    nameClass={styles.name}
                    className={styles.cartInnerItem}
                    colorPickerImageClass={styles.colorPicker}
                  />
                </div>
              ))
            }
          </div>
        </>
      ) : null
    )
  }

  const renderUnSaleableItems = () => {
    return (
      <CartPageSoldOutList
        noBorder
        $i18n={props.$i18n}
        onClearExpireItems={onClearExpireItems}
        unsaleableLineItems={unSaleableLineItems}
      />
    )
  }

  const handleCheckout = () => {
    props.$track.event('Cart', 'click_checkout', 'drawer')
    return props.onCheckout()
  }

  const handleTakeCoupon = () => {
    setNumberChangeDisabled(false)
  }

  const footerSettings = {
    availableItemCount,
    onCheckout: handleCheckout,
    wrapperClassName: styles.footer,
    selectCart: selectCart,
    numberChangeDisabled,
    onNumChangeFinish: () => setNumberChangeDisabled(true)
  }
  return (
    <div className={styles.wrapper}>
      {
        props.cartLineItemCount > 0
        ? (
          <>
            <div className={cx(styles.itemsWrapper, props.totalSaveFee && props.totalSaveFee > 0 && styles.hasTotalSaveFee)}>
              <AtcCouponList {...props} onTakeCard={handleTakeCoupon} wrapperClass={styles.atcCoupon} />
              <main className={styles.items}>
                {renderSaleableItems()}
              </main>
              {
                unSaleableLineItems.length > 0 &&  <main className={styles.items}>
                  {renderUnSaleableItems()}
                </main>
              }
            </div>
            <div className={styles.footerWrapper}>
              <div className={styles.content}>
              <DesktopCartPreviewFooter {...props} active={showPriceCounts} {...footerSettings} onClickCounts={handleClickCounts} renderHeader={renderShippingHint} />
                {
                  showPriceCounts && (
                    <div className={cx(styles.priceSummaryWrapper, props.totalSaveFee && props.totalSaveFee > 0 && styles.hasTotalSaveFee)}>
                      <div className={styles.content}>
                        <div className={styles.closeButtonRow}><span onClick={handleClickCounts}>&#xe60e;</span></div>
                        <PriceSummaryList
                          {...props}
                          mode={PriceSummaryList.MODE.CART}
                          freight={props.shippingFee}
                          $i18n={props.$i18n}
                          showDiscountSelect={true}
                          hideTotal={true}
                          className={styles.priceList}
                          onClickCounts={handleClickCounts}
                          onClose={handleClickCounts}
                        />
                      </div>
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
        :
        (
          <EmptyCartContent
            {...props}
            onlyIcon={true}
            className={styles.empty}
            emptyCartPlayClass={styles.emptyCartPlay}
            iconClass={styles.icon}
            titleClass={styles.title}
            descClass={styles.desc}
          />
        )
      }
    </div>
  )
}, 'shipping'))

export default props => <CoverCartContainer {...props} cartIs={DesktopCartPreviewDrawer} mode={CoverCartContainer.mode.DRAWER} />