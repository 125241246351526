import React, { useState } from 'react'
import { withPlugin } from '@flamingo_tech/funkgo'
import FreeGiftPCModal from './FreeGiftPCModal'
import FreeGiftBuoy from './FreeGiftBuoy'

const second_num = 10

const FreeGiftPCHint = withPlugin(props => {
  const freeGiftModalLoadedStorage = props.$storage.create('free_gift_modal_loaded', { strategy: 'SESSION' })
  const loaded = freeGiftModalLoadedStorage.getItem(false)

  const [showBuoy, setShowBuoy] = useState(loaded)
  const [showModal, setShowModal] = useState(!loaded)

  const handleCloseModal = () => {
    setShowModal(false)
    setShowBuoy(true)
    freeGiftModalLoadedStorage.setItem(true)
  }

  const handleClickBuoy = () => {
    props.$track.event({ category: 'Wheel', action: `click_free_gift_buoy`, nonInteraction: false })
    setShowModal(true)
  }

  return (
    <>
      {
        showBuoy && <FreeGiftBuoy {...props} onClick={handleClickBuoy} />
      }
      {
        showModal && <FreeGiftPCModal {...props} onClose={handleCloseModal} seconds={!loaded && second_num} />
      }
    </>
  )
})

export default FreeGiftPCHint