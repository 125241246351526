import React, { useContext } from 'react'

import AppStateContext from './AppStateContext'
import { hoistHocStatic } from '@flamingo_tech/funkgo'

const withAppState = (Component, options = {}, staticMethods) => {
  const displayName = `withAppState(${Component.displayName || Component.name})`
  const WrappedComponent = props => {
    const appState = useContext(AppStateContext)

    return (
      <Component {...appState} {...props}></Component>
    )
  }

  hoistHocStatic(WrappedComponent, Component, staticMethods)
  WrappedComponent.displayName = displayName
  return WrappedComponent
}

export default withAppState
