import React, {
  forwardRef,
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'
import TabLink from './TabLink'
import Tab from './Tab'

import styles from './ExpandableTab.module.css'
import cx from '../../../utils/className'

import {
  findTabIndexByKey,
} from './utils/tabUtils'

/* ----------------------------------------------- */

const CategoryPanel = props => {
  return (
    <ul className={styles.categoryPanel}>
      {
        props.tabs.map(tab => (
          <li key={tab.key}>
            <TabLink
              tab={tab}
              tabParamName={props.tabParamName}
              onTabChange={props.onTabChange}
              className={styles.category}
            ></TabLink>
          </li>
        ))
      }
    </ul>
  )
}

/* ----------------------------------------------- */

const useTabCentral = (props, tabRef, tabWrapperRef) => {
  const {
    currentTab,
    tabs,
  } = props

  useEffect(() => {
    const makeTabCentral = tab => {
      if (typeof window !== 'undefined') {
        const tabIndex = findTabIndexByKey(tabs, tab.key)
        const length = tabs.length
        let moveToTabIndex = 0

        if (tabIndex >= length - 2) {
          // as for last two tabs need always right aligned
          moveToTabIndex = length - 1
        } else if ( tabIndex > 1) {
          moveToTabIndex = tabIndex - 1
        }

        const tabNode = tabRef.current.childNodes[moveToTabIndex]

        tabWrapperRef.current.scrollLeft = tabNode.offsetLeft
      }
    }

    if (currentTab) {
      makeTabCentral(currentTab)
    }

  }, [currentTab, tabs, tabRef, tabWrapperRef])
}

/* ----------------------------------------------- */

// equal to the height of store header
const ExpandableTab = forwardRef((props, ref) => {
  const{
    currentTab,
    tabs,
    onTabChange,
    className,
    tabParamName,
    containerClassName,
    needTabKeyToHash = true,
    withAppHeader = false
  } = props

  const plugin = useContext(PluginContext)

  // keep the original tab, so that hash change can revert it
  const [enableCategoryPanel, setEnableCategoryPanel] = useState(false)
  const [categoryPanelOpen, setCategoryPanelOpen] = useState(false)

  /* ------------------------------------------- */
  const tabWrapperRef = useRef() // for ul wrapper
  const tabRef = ref || useRef()

  /* ------------------------------------------- */
  useTabCentral(props, tabRef, tabWrapperRef)

  /* ------------------------------------------- */
  const handleTabChange = useCallback(tab => {
    if (typeof onTabChange === 'function') {
      onTabChange(tab)
    }

  }, [onTabChange])

  /* ------------------------------------------- */

  useEffect(() => {
    const tab = tabRef.current

    if (tab) {
      let tabsWidth = 0

      for(let i = 0; i < tab.childNodes.length; i++) {
        const childNode = tab.childNodes[i]

        tabsWidth += childNode.getBoundingClientRect().width
      }

      setEnableCategoryPanel(tabsWidth > tab.getBoundingClientRect().width)
    }

  }, [tabRef])

/* ------------------------------------------- */

  const handleToggleCategoryPanel = () => {
    setCategoryPanelOpen(!categoryPanelOpen)
  }

  const handleTabClick = tab => {
    setCategoryPanelOpen(false)
    handleTabChange(tab)
  }

  const handleCategoryClick = tab => {
    setCategoryPanelOpen(!categoryPanelOpen)
    handleTabChange(tab)
  }

  const isDesktop = plugin.$detector.isDesktop()
  const isApp = plugin.$detector.isApp()

  const itemStyle = isDesktop ? {
    width: `${100 / tabs.length}%`
  } : null

  return (
    <div
      className={cx(styles.tabContainer, isDesktop && styles.inDesktop, isApp && withAppHeader && styles.inApp , containerClassName)}
    >
      <div className={styles.outerScrollTabWrapper}>
        <div
          className={styles.innerScrollTabWrapper}
          ref={tabWrapperRef}
        >
          <Tab
            className={cx(styles.tab, className, !enableCategoryPanel && styles.spread)}
            itemClassName={cx(styles.tabItem, props.itemClassName, isDesktop && styles.inDesktop)}
            itemSelectedClassName={cx(styles.selected, isDesktop && styles.inDesktop)}
            onTabChange={handleTabClick}
            currentTab={currentTab}
            itemStyle={itemStyle}
            tabs={tabs}
            tabParamName={tabParamName}
            ref={tabRef}
            needTabKeyToHash={needTabKeyToHash}
          ></Tab>
        </div>
      </div>
      {
        enableCategoryPanel && (
          <div
            className={cx(styles.rightButton, categoryPanelOpen ? styles.categoryPanelOpen : '')}
            onClick={handleToggleCategoryPanel}
          >
            <div className={styles.arrowDownIcon}></div>
          </div>
        )
      }
      {
        categoryPanelOpen && (
          <CategoryPanel
            tabs={tabs}
            tabParamName={tabParamName}
            onTabChange={handleCategoryClick} />
        )
      }
    </div>
  )
})



export default ExpandableTab
