import React from 'react'
import withQuickAdd from '../../../hooks/withQuickAdd'

import { AddToCartBtn } from '../../common/AddToCartIcon/AddToCartIcon'

const QuickAddBtn = withQuickAdd(props => {
  const handleQuickAdd = ({ triggerRef }) => {
    props.onQuickAdd({
      product: props.product,
      refInfo: { triggerRef }
    })
    props.onClickAddCart(props.product)
  }

  return (
    <AddToCartBtn handleClick={handleQuickAdd} />
  )
})

export default QuickAddBtn