import queryString from 'query-string'
import BasePlugin from '@flamingo_tech/funkgo/src/base/BasePlugin'
import { createLocation as createLocationFromRelativePath } from "history"

import {
  getCurrentOrigin,
  makeUrl,
  parseSearch,
  parseHash,
  isAbsolutePath,
} from '@flamingo_tech/funkgo/src/utils/url'

import { createTimeoutPromise } from '@flamingo_tech/funkgo-utils/promiseUtils'


/* ------------------------------------------- */
const silentFail = () => undefined
const REUSE_WEBVIEW_KEY = '__reuse_webview'

/* ------------------------------------------- */

const mixArrayParamsToPath = (path, params) => {
  let index = 0

  return path.replace(/:([\w\d_]+)/g, (match, group) => {
    const value = params[index++]

    if (!value) {
      return match
    }
    return value
  })
}

const mixParamsToPath = (path, params) => {
  if (!params) {
    return path
  }

  if (Array.isArray(params)) {
    return mixArrayParamsToPath(path, params)
  }

  return path.replace(/:([\w\d_]+)/g, (match, group) => {
    const value = params[group]
    if (!value) {
      return match
    }
    return value
  })

}

const appendParamsToPath = (path, params) => {
  if (!params) {
    return path
  }

  return `${path}?${queryString.stringify(params)}`
}

const generateFinalPath = (pathname, { params, search, hash }) => {
  let path = pathname || '/'

  if (search && search !== '?') {
    path += search.charAt(0) === '?' ? search : `?${search}`
  }

  if (hash && hash !== '#') {
    path += hash.charAt(0) === '#' ? hash : `#${hash}`
  }

  return params ? mixParamsToPath(path, params) : path
}

/* ------------------------------------------- */

const NAVIGATE_TYPE = {
  DEFAULT: 'DEFAULT',
  FORCE_OPEN_NEW_PAGE: 'FORCE_OPEN_NEW_PAGE', // for activity page on app, force to open new webview on every navigation
  FORCE_RELOAD_PAGE: 'FORCE_RELOAD_PAGE',
}

export default class RouterPlugin extends BasePlugin {
  displayName = '$Router'

  constructor(options, pluginHub) {
    super(options, pluginHub)

    this.appRoutes = options.routes || []
    this.appRoutesMapping = this.parseRoutes(this.appRoutes)

    this.globalRoutes = options.globalRoutes || []
    this.globalRoutesMapping = this.parseRoutes(this.globalRoutes, true)

    const $storage = pluginHub.getStorage()
    this.reuseWebviewStorage = $storage.create(REUSE_WEBVIEW_KEY, {
      strategy: 'SESSION'
    })
    this.$storage = pluginHub.getStorage()

    this.underReuseWebview = false
    this.reuseWebviewLength = 0
  }

  start() {
    const pluginHub = this.pluginHub
    const $detector = pluginHub.getDetector()
    const $bridge = pluginHub.getPlugin('bridge')

    if ($detector.isApp() && $bridge && $bridge.isAppBridgesEnabled()) {
      const reuseWebviewLength = this.reuseWebviewStorage.getItem()
      const underReuseWebview = typeof reuseWebviewLength === 'number'
      this.underReuseWebview = underReuseWebview
      this.reuseWebviewLength = underReuseWebview ? reuseWebviewLength : 0
    }
  }

  parseRoutes(routes, strict) {
    const mapping = {}

    routes.forEach(route => {
      const { name, ...rest } = route
      if (!name && strict) {
        throw new Error('route must specific name prop')
      }

      mapping[name] = rest
    })

    return mapping
  }

  /* ---------------------------------------- */
  getRouteByName(name) {
    let route

    if (this.appRoutesMapping[name]) {
      route = {
        ...this.appRoutesMapping[name],
        isGlobal: false
      }
    } else if (this.globalRoutesMapping[name]) {
      route = {
        ...this.globalRoutesMapping[name],
        isGlobal: true
      }
    }

    if (route) {
      if (Array.isArray(route.path)) {
        route = {
          ...route,
          path: route.path[0]
        }
      }
    }

    if (route && route.path) {
      return route
    }
    throw new Error(`${name} is not defined on routes`)
  }

  getRouteByPages(pages) {
    return pages.map(page => ({
      ...page,
      ...this.getRouteByName(page.name)
    }))
  }

  getRouteName(to) {
    if (typeof to === 'object') {
      return to.name
    } else if (typeof to === 'string' && /^[A-Z]/.test(to)) {
      return to
    }

    return ''
  }

  /* ------------------------------------------- */
  getCurrentOrigin() {
    const appInfo = this.pluginHub.getAppInfo()

    return getCurrentOrigin() || `https://${appInfo.domain}`
  }

  getCurrentPlainUrl() {
    const $detector = this.pluginHub.getDetector()
    const currentOrigin = this.getCurrentOrigin()

    if ($detector.isServer()) {
      return currentOrigin
    }

    return `${currentOrigin}${document.location.pathname}`
  }

  /* ------------------------------------------- */
  getKnownDomainReg() {
    const appInfo = this.pluginHub.getAppInfo()
    return appInfo.knownDomainReg
  }

  isKnownDomain(path) {
    return this.getKnownDomainReg().test(path)
  }

  getRelativePathFromKnownDomain(path) {
    const result = this.getKnownDomainReg().exec(path)
    return result[result.length - 1]
  }

  /* ------------------------------------------- */
  createLocationFromRelativePath = (path, ...args) => {
    const currentOrigin = this.getCurrentOrigin()

    // if path is undefined, the compute with current location
    if (!path) {
      return createLocationFromRelativePath(path, ...args)

    // if path is relative, then format it
    } else if (!isAbsolutePath(path)) {
      return createLocationFromRelativePath(path, ...args)

    // if path is defined as absolute, and under main host, then extract the pathname
    //   , for case the url is defined on storefront api data
    } else if (this.isKnownDomain(path)) {
      const relativePath = this.getRelativePathFromKnownDomain(path)
      return createLocationFromRelativePath(relativePath, ...args)

    // if path is defined as absolute, and relative from current origin, then extract the pathname
    //   , for case the url is generate from runtime code (e.g. game center in app)
    } else if (path.startsWith(currentOrigin)) {
      const relativePath = path.slice(currentOrigin.length)
      return createLocationFromRelativePath(relativePath, ...args)

    // if the path is absolute, the return it directly, it will be mark as absolute
    //    , and skip the spa navigation
    } else {
      return {
        pathname: path,
      }
    }
  }

  /* ------------------------------------------- */
  createLocationByName(context, name) {
    const route = this.getRouteByName(name)
    const location = this.createLocationFromRelativePath(route.path, undefined, undefined, context.location)

    return {
      ...location,
      isGlobal: route.isGlobal
    }
  }

  createLocationByObject(context, obj) {
    const location = this.createLocationFromRelativePath(obj.pathname, undefined, undefined, context.location)

    return {
      ...obj,

      // if not provided pathname, then keep original pathname and search
      // , the new search will be mixin to this object at next steps
      search: obj.pathname ? '' : context.location.search,
      pathname: location.pathname,
      isGlobal: isAbsolutePath(location.pathname)
    }
  }

  createLocationByPath(context, path) {
    const location = this.createLocationFromRelativePath(path, undefined, undefined, context.location)

    return {
      ...location,
      isGlobal: isAbsolutePath(location.pathname)
    }
  }

  /* ------------------------------------------- */

  computeLocation(location, options = {}) {
    const params = options.params
    const search = parseSearch(
      typeof options === 'object'
      ? options.search || location.search
      : location.search
    )

    const hash = parseHash(
      typeof options === 'object'
      ? options.hash || location.hash
      : location.hash
    )

    const state = options.state || undefined

    const finalPath = generateFinalPath(location.pathname, { params, search, hash })
    const pathname = mixParamsToPath(location.pathname, params)

    return {
      ...location,
      pathname,
      finalPath,
      params,
      search,
      hash,
      state,
    }
  }

  isAppScheme(to) {
    const appInfo = this.pluginHub.getAppInfo()

    return typeof to === 'string' && to.startsWith(appInfo.scheme)
  }

  createLocation(context, _to) {
    let location
    let to = _to

    if (this.isAppScheme(_to)) {
      to = `/store/not_found?scheme=${encodeURIComponent(_to)}`
      const $logger = this.pluginHub.getLogger()
      $logger.error(`[RouterPlugin] receive invalid to: ${_to}`)
    }

    const routeName = this.getRouteName(to)

    if (routeName) {
      location = this.createLocationByName(context, routeName)
    } else if (typeof to === 'string') {
      location = this.createLocationByPath(context, to)
    } else if (typeof to === 'object') {
      location = this.createLocationByObject(context, to)
    } else {
      throw new Error(`non-supported navigate "to" = ${typeof to}`)
    }

    return this.computeLocation(location, to)

  }

  /* ---------------------------------------- */
  navigateType = NAVIGATE_TYPE.DEFAULT

  forceSwitchToOpenNewPage() {
    if (this.isUnderReuseWebview()) {
      this.navigateType = NAVIGATE_TYPE.DEFAULT
    } else {
      this.navigateType = NAVIGATE_TYPE.FORCE_OPEN_NEW_PAGE
    }
  }

  forceSwitchToReloadPage() {
    this.navigateType = NAVIGATE_TYPE.FORCE_RELOAD_PAGE
  }

  /* ---------------------------------------- */
  isUnderAppWithBridges() {
    const $detector = this.pluginHub.getDetector()
    const $bridge = this.pluginHub.getPlugin('bridge')
    return $detector.isApp() && $bridge && $bridge.isAppBridgesEnabled()
  }

  isUnderAppWithoutH5Home() {
    const $detector = this.pluginHub.getDetector()
    return $detector.isApp() && $detector.compareAppVersion({ targetVersion: '6.2.0' })
  }

  /* ---------------------------------------- */

  navigateToHomePage(context, options) {
    const $detector = this.pluginHub.getDetector()
    if (this.isUnderAppWithBridges() && this.isUnderReuseWebview()) {
      const $bridge = this.pluginHub.getPlugin('bridge')
      if ($detector.compareAppVersion({ targetVersion: '6.1.0' })) {
        this.navigateToApp(context, { scheme: '/shop' }, options)
      } else {
        $bridge.closeWindow()
        .catch(silentFail) // for legacy app and android, which does not respond result
      }
    } else {
      if($detector.isUseAppHomeApp()) {
        this.navigateToApp(context, { name: 'Home'}, options)
      } else {
        this.navigate(context, { name: 'Home'}, options)
      }
    }
  }

  /* ---------------------------------------- */

  navigateToProductPage(context, product, toExtraInfo = {}) {
    const to = {
      name: 'Product',
      params: {
        handle: product.handle
      },
      ...toExtraInfo
    }

    this.navigate(context, to)
  }

  /* ---------------------------------------- */

  navigate(context, to, { replace, mode } = {}) {

    const navigateType = mode || this.navigateType
    let navigatedToApp = false

    // for app lower than 5.0.0' navigate to app if route defined preferNative
    // , e.g. Home / My / ProductDetail / GameCenter, etc.
    if (this.isUnderAppWithoutH5Home()) {
      const routeName = this.getRouteName(to)

      if (routeName) {
        const route = this.getRouteByName(routeName)
        if (route.scheme && route.preferNative) {
          this.navigateToApp(context, to)
          navigatedToApp = true
        }
      }
    }

    // otherwise, we still use web navigation
    if (!navigatedToApp) {
      const location = this.createLocation(context, to)
      if (navigateType === NAVIGATE_TYPE.FORCE_OPEN_NEW_PAGE) {
        if (this.isUnderAppWithBridges()) {
          this.navigateToAppWebview(context, location.finalPath)
        } else {
          window.open(location.finalPath)
        }
      } else if (navigateType === NAVIGATE_TYPE.FORCE_RELOAD_PAGE) {
        document.location.href = location.finalPath
      } else if (location.isGlobal) {
        document.location.href = location.finalPath
      } else if (context && context.history) {
        if (replace) {
          context.history.replace(location, { from: context.location })
        } else {
          context.history.push(location, { from: context.location })
        }
      } else {
        document.location.href = location.finalPath
      }
    }

    if (this.isUnderReuseWebview()) {
      this.pushReuseWebviewHistory(to)
    }
  }

  validLink(context, to) {
    try {
      this.createLocation(context, to)
      return true
    } catch (ex) {
      return false
    }
  }

  getFinalPath(context, to) {
    const location = this.createLocation(context, to)
    return location.finalPath
  }

  replace(context, to, options) {
    return this.navigate(context, to, { replace: true, ...options })
  }

  reload(context, { force } = {}) {
    if (force && typeof window !== 'undefined') {
      window.location.reload()
    } else {
      context.history.replace(context.location)
    }
  }

  /* ---------------------------------------- */

  isUnderReuseWebview() {
    return this.underReuseWebview
  }

  hasReuseWebviewLength() {
    return this.reuseWebviewLength > 0
  }

  popReuseWebviewHistory() {
    this.reuseWebviewLength -= 1
    this.reuseWebviewStorage.setItem(this.reuseWebviewLength)
  }

  pushReuseWebviewHistory(to) {
    this.reuseWebviewLength += 1
    this.reuseWebviewStorage.setItem(this.reuseWebviewLength)
  }

  startReuseWebview(context, to) {
    this.reuseWebviewLength = -1 // since navigate above will trigger pushReuseWebviewHistory, so starts from -1
    this.underReuseWebview = true

    this.navigate(context, to, {
      replace: true,
      mode: NAVIGATE_TYPE.DEFAULT,
    })
  }

  goBack(context) {
    const { history } = context
    const $detector = this.pluginHub.getDetector()
    const $bridge = this.pluginHub.getPlugin('bridge')

    if ($detector.isApp() && this.isUnderReuseWebview()) {
      if (this.hasReuseWebviewLength()) {
        this.popReuseWebviewHistory()
        history.goBack()
      } else {
        $bridge.closeWindow()
          .catch(silentFail) // for legacy app and android, which does not respond result
      }

    } else {
      if (history) {
        history.goBack()
      } else if (window && window.history) {
        window.history.back()
      }
    }
  }

  /* ---------------------------------------- */
  makeTrackingUrl(targetUrl) {
    return createTimeoutPromise(resolve => {
      const $tracker = this.pluginHub.getPlugin('tracker')
      if ($tracker && $tracker.hasGa()) {
        $tracker.ga(tracker => {
          const linkerParam = tracker.get('linkerParam')
          const parsedUrl = targetUrl.indexOf('?') > -1
            ? `${targetUrl}&${linkerParam}`
            : `${targetUrl}?${linkerParam}`

          resolve(parsedUrl)
        })
      } else {
        resolve(targetUrl)
      }
    }, 2000)
  }

  navigateToApp(context, to = {}) {
    let toObj

    if (typeof to === 'object') {
      toObj = to
    } else if (typeof to === 'string') {
      toObj = {
        name: to
      }
    } else {
      throw new Error('[RouterPlugin] invalid parameter')
    }

    const {
      name,
      params,
      scheme,
      ...restOptions
    } = toObj

    let schemePath

    if (name) {
      const route = this.getRouteByName(name)

      if (!route) {
        throw new Error(`[RouterPlugin] "${name}" is not a valid route name`)
      } else if (!route.scheme) {
        throw new Error(`[RouterPlugin] "${name}" has not defined app scheme`)
      }

      schemePath = route.scheme
    } else if (scheme) {
      schemePath = scheme
    }

    if (!schemePath) {
      schemePath = '/'
    }

    schemePath = appendParamsToPath(schemePath, params)

    const $bridge = this.pluginHub.getPlugin('bridge')
    $bridge.navigateToApp({
      ...restOptions,
      schemePath
    })
  }

  navigateToAppWebview(
    context,
    baseUri = this.getCurrentPlainUrl(),
    { reuse = true } = {}
  ) {
    let reuseFlag

    if (reuse) {
      const $detector = this.pluginHub.getDetector()

      // only hide navigation bar if it is running at standalone mode
      if ($detector.isApp() && $detector.isStandaloneApp()) {
        reuseFlag = 'standalone' // open reuse webview without navigation bar
      } else {
        reuseFlag = 1 // open reuse webview with navigation bar
      }
    } else {
      reuseFlag = undefined // open new webview with navigation bar
    }

    const absoluteBaseUri = isAbsolutePath(baseUri)
      ? baseUri
      : `${this.getCurrentOrigin()}${baseUri}`


    const urlString = makeUrl(absoluteBaseUri, {
      '_reuse': reuseFlag, // TODO, use standalone once our navigation ui implemented
      'utm_source': 'FlamingoApp',
    })

    return this.navigateToApp(context, {
      scheme: '/BaseWebView',
      params: {
        urlString
      }
    })
  }

  navigateToMessengerBot(context, ref, payload, fallback) {
    const $bridge = this.pluginHub.getPlugin('bridge')
    return $bridge.navigateToMessengerBot(ref, payload, fallback)
  }


  /* ---------------------------------------- */

  getInjectProps(context) {
    const { location, history, match } = context

    return {
      $router: {
        // transport from react-router
        location,
        history,
        match,

        replace: this.replace.bind(this, context),
        reload: this.reload.bind(this, context),
        navigate: this.navigate.bind(this, context),
        goBack: this.goBack.bind(this, context),

        navigateToProductPage: this.navigateToProductPage.bind(this, context),
        navigateToHomePage: this.navigateToHomePage.bind(this, context),

        navigateToApp: this.navigateToApp.bind(this, context),
        navigateToAppWebview: this.navigateToAppWebview.bind(this, context),
        navigateToMessengerBot: this.navigateToMessengerBot.bind(this, context),

        getFinalPath: this.getFinalPath.bind(this, context),
        validLink: this.validLink.bind(this, context),

        forceSwitchToOpenNewPage: this.forceSwitchToOpenNewPage.bind(this, context),
        forceSwitchToReloadPage: this.forceSwitchToReloadPage.bind(this, context),

        startReuseWebview: this.startReuseWebview.bind(this, context),

        getRouteByPages: this.getRouteByPages.bind(this),
        getRouteByName: this.getRouteByName.bind(this)
      }
    }
  }
}
