import React, { PureComponent } from 'react'

import styles from './withStoreHeader.module.css'

const DISTANCE = 50
const ORIGIN_POSITION = 200

const withStoreHeader = Component => {
  return class extends PureComponent {
    state = {
      showHeader: true
    }

    _scrollY = 0

    handleScroll = () => {
      if (Math.abs(window.scrollY - this._scrollY) >= DISTANCE) {
        const storeHeader =  document.getElementById('store-header')

        if (this._scrollY && storeHeader && this._scrollY > ORIGIN_POSITION) {
          if (this._scrollY > window.scrollY) {
            storeHeader.classList.add(styles.show)
            storeHeader.classList.remove(styles.hide)
            this.setState({
              showHeader: true
            })
          } else {
            storeHeader.classList.add(styles.hide)
            storeHeader.classList.remove(styles.show)
            this.setState({
              showHeader: false
            })
          }
        }
        this._scrollY = window.scrollY

      }
    }

    componentDidMount() {
      document.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount() {
      document.removeEventListener('scroll', this.handleScroll)
      this.resetStoreHeader()
    }

    resetStoreHeader() {
      const storeHeader = document.getElementById('store-header')

      if (storeHeader) {
        storeHeader.classList.add(styles.show)
        storeHeader.classList.remove(styles.hide)
      }
    }

    render() {
      return <Component {...this.props} showHeader={this.state.showHeader} />
    }
  }
}

export default withStoreHeader