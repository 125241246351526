import React, { PureComponent } from 'react'

import { withPlugin } from '@flamingo_tech/funkgo'
import ProductCard from '../ProductCard/ProductCard'
import BriefProductCard from '../ProductCard/BriefProductCard'

import HorizontalColumns from '../../common/List/HorizontalColumns'

import {
  FIRST_SLICE_KEY,
  fetchProducts,
  extractProducts,
  getProductFetchFlag
} from './utils/productListUtils'

const DISPLAY_ITEM_COUNT = 16

const productCardComponents = {
  BriefProductCard,
  ProductCard
}

class HorizontalProductList extends PureComponent {
  state = HorizontalProductList.initState(this.props)

  static initState(props) {
    return {
      products: props.products || new Array(DISPLAY_ITEM_COUNT).fill({
        empty: true
      }),
      initProductFetchFlag: getProductFetchFlag(props)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.products && nextProps.products !== prevState.products) {
      return HorizontalProductList.initState(nextProps)
    } else if (nextProps.products === undefined) {
      const nextProductFetchFlag = getProductFetchFlag(nextProps)

      // if has no init products, and fetch flag changed, als return init state to re-fetch
      if (nextProductFetchFlag !== prevState.initProductFetchFlag) {
        return HorizontalProductList.initState(nextProps)
      }
    }
    // No state update necessary
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.initProductFetchFlag !== prevState.initProductFetchFlag) {
      this.fetchProducts()
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.fetchProducts()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  fetchProducts = () => {
    if (this.props.products) {
      return
    }

    const displayItemCount = this.props.displayItemCount || DISPLAY_ITEM_COUNT

    fetchProducts(FIRST_SLICE_KEY, this.props).then(
      ({ products }) => {
        if (this._isMounted) {
          this.setState({
            products: products.slice(0, displayItemCount)
          })
        }
      }
    )
  }

  mapCardIs() {
    let childIs

    if (this.props.childIs) {
      if (typeof this.props.childIs === 'string'
      && productCardComponents[this.props.childIs]) {
        childIs = productCardComponents[this.props.childIs]
      } else if (typeof this.props.childIs === 'function') {
        childIs = this.props.childIs
      }
    }

    return childIs || BriefProductCard
  }

  render() {
    const props = this.props

    const options = {
      className: props.className,
      renderLastCard: props.renderLastCard,
      childIs: this.mapCardIs(),
      childItems: extractProducts(this.state.products),
      childOptions: this.props.childOptions
    }

    return (
      <HorizontalColumns {...options}/>
    )
  }
}

export default withPlugin(HorizontalProductList)
